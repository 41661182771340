import instance from '..';
import { convertObjToQueryString } from '../../utils/url-helpers';

export default class TutorsAPI {
  static async getTutors(token, params) {
    return instance.get(`/api/tutors_for_product_and_language/?${convertObjToQueryString(params)}`, { headers: { Authorization: `Token ${token}` } });
  }
  static async getTutorsWithoutToken(securityParams, params) {
    return instance.get(`/api/tutors_for_product_and_language/?${convertObjToQueryString(params)}`, { params: securityParams });
  }
  static async getTutorsWithAppointmentType(token, appointmentTypeId) {
    return instance.get(`/api/appointment_types/${appointmentTypeId}/tutors/`, {
      headers: { Authorization: `Token ${token}` }
    });
  }
  static async getTutorProfile({ token, tutorId }) {
    return instance.get(`/api/tutors/${tutorId}/profile/`, { headers: { Authorization: `Token ${token}` } });
  }
}
