import React from 'react';
import PropTypes from 'prop-types';

import styles from './CardLoader.module.scss';

const CardLoader = ({ rows, rowsColor }) => (
  <div className={styles.rowsWrapper} data-testid="card-loader">
    {[...new Array(rows)].map((arr, index) => (
      <div className={[styles.loading, styles[rowsColor]].join(' ')} key={index} />
    ))}
  </div>
);

CardLoader.propTypes = {
  rows: PropTypes.number,
  rowsColor: PropTypes.string
};

CardLoader.defaultProps = {
  rows: 3,
  rowsColor: ''
};

export default CardLoader;
