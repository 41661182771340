import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MessageList, Input, Button as ChatButton } from 'react-chat-elements';

import { nextPage, prevPage, resetPage } from '../../actions/test-page';
import { getFormattedMessage } from '../../utils/chat-helpers';
import Button from '../design-system/button/Button';
import styles from '../../style/containers/TestPage.module.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import { TwilioConversation } from '../../services/chat/twilio';

const MESSAGES_TO_LOAD = 6;
const TECHNICAL_TEST_CHANNEL = 'technical-test-conversation';

const TestChat = ({ nextPageAction, prevPageAction, resetPageAction }) => {
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState('');
  const [conversation, setConversation] = useState(undefined);

  const technicalTestUserId = process.env.REACT_APP_TECHNICAL_TEST_USER_ID;
  const technicalTestUser = {
    id: technicalTestUserId,
    token: 'ignore-token'
  };

  let twilioChat = TwilioConversation.getInstance(technicalTestUser);
  let client;

  const populateMessages = async (items) => {
    const fetchedMessages = [];
    for (let i = 0; i < items.length; i += 1) {
      const item = items[i];
      const { message } = await getFormattedMessage(item, technicalTestUser, undefined, () => {});
      fetchedMessages.push(message);
    }
    setMessages(fetchedMessages);
  };

  const sendMessage = (inputValue) => {
    if (inputValue === undefined || inputValue === null || inputValue === '') {
      return;
    }
    if (inputValue && inputValue.replace(/\n/g, '') === '') {
      return;
    }
    // this.inputTextField.current.clear();
    if (conversation) conversation.sendMessage(inputValue);
  };

  const appendMessage = async (item) => {
    const { message } = await getFormattedMessage(item, technicalTestUser, undefined, () => {});
    const updatedMessages = [...messages];

    updatedMessages.push(message);

    setMessages(updatedMessages);
  };

  useEffect(() => {
    twilioChat.getClient().then((returnedClient) => {
      client = returnedClient;
      client
        .getConversationByUniqueName(TECHNICAL_TEST_CHANNEL)
        .then((response) => {
          setConversation(response);
          response.removeAllListeners();
          response.on('messageAdded', (item) => {
            appendMessage(item);
          });
          conversation.getMessages(MESSAGES_TO_LOAD).then((channelMessages) => {
            populateMessages(channelMessages.items);
          });
        })
        .catch((e) => {
          if (e.message === 'Not Found') {
            client
              .createConversation({
                attributes: {},
                friendlyName: 'Technical Test Conversation',
                uniqueName: TECHNICAL_TEST_CHANNEL
              })
              .then(async (response) => {
                setConversation(response);
                response.removeAllListeners();
                response.on('messageAdded', (item) => {
                  appendMessage(item);
                });
                await conversation.add(technicalTestUserId);
                conversation.getMessages(MESSAGES_TO_LOAD).then(async (channelMessages) => {
                  populateMessages(channelMessages.items);
                });
              });
          }
        });
    });
  }, []);

  return (
    <div className={styles.testChatWrapper}>
      <div id="messages" className={styles.messageListContainer}>
        <MessageList className={[styles.messageList, 'message-list'].join(' ')} toBottomHeight={'100%'} dataSource={messages} />
        <Input
          autoHeight
          maxHeight={9999}
          placeholder="Type here..."
          onChange={(data) => {
            setText(data.target.value);
          }}
          multiline
          minHeight={43}
          rightButtons={
            <div className={styles.chatButtonsWrapper}>
              <ChatButton
                color="#000"
                text="Send"
                onClick={() => {
                  sendMessage(text);
                }}
              />
            </div>
          }
        />
      </div>
      <div>
        <p className={[styles.introParagraph, styles.youtubeParagraph].join(' ')}>
          Please try to send a message to the Chat. If the message appear, then click on the &ldquo;Next&ldquo; button below.
        </p>
      </div>
      <div className={styles.buttonContainer}>
        <Button outfit="secondaryButton" className={styles.nextButton} onClick={prevPageAction}>
          Back
        </Button>
        <Button outfit="regularButton" className={styles.nextButton} onClick={nextPageAction}>
          Next
        </Button>
        <Button outfit="secondaryButton" className={styles.nextButton} onClick={resetPageAction}>
          Restart
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

TestChat.propTypes = {
  nextPageAction: PropTypes.func.isRequired,
  prevPageAction: PropTypes.func.isRequired,
  resetPageAction: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {
  nextPageAction: nextPage,
  prevPageAction: prevPage,
  resetPageAction: resetPage
})(TestChat);
