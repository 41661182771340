import { combineReducers } from 'redux';
import { LoginActionTypes } from '../actions/new-login';
import { USER_LOGOUT } from '../actions/types';

const loginInitialState = {
  hasPostLogInError: false,
  postLogInErrorMessage: '',
  isLoadingPostLogIn: false,
  isLoggedIn: false
};

function logInReducer(state = loginInitialState, action) {
  switch (action.type) {
    case LoginActionTypes.POST_LOG_IN:
      return { ...state, isLoadingPostLogIn: true, hasPostLogInError: false };
    case LoginActionTypes.POST_LOG_IN_SUCCESS:
      return { ...state, isLoadingPostLogIn: false, hasPostLogInError: false, isLoggedIn: true };
    case LoginActionTypes.POST_LOG_IN_FAIL:
      return {
        ...state,
        isLoadingPostLogIn: false,
        hasPostLogInError: true,
        postLogInErrorMessage: action.payload
      };
    case LoginActionTypes.CLEAR_POST_LOG_IN_ERROR:
      return loginInitialState;
    case USER_LOGOUT:
      return loginInitialState;
    default:
      return state;
  }
}

const ssoLoginInitialState = {
  hasPostLogInError: false,
  postLogInErrorMessage: '',
  isLoadingPostLogIn: false,
  isLoggedIn: false,
  provider: '',
  redirectUrl: '/'
};

function ssoLogInReducer(state = ssoLoginInitialState, action) {
  switch (action.type) {
    case LoginActionTypes.GET_SSO_PROVIDER:
    case LoginActionTypes.POST_SSO_LOG_IN:
      return { ...state, isLoadingPostLogIn: true, hasPostLogInError: false };
    case LoginActionTypes.GET_SSO_PROVIDER_SUCCESS:
      return { ...state, provider: action.payload };
    case LoginActionTypes.POST_SSO_LOG_IN_SUCCESS:
      return { ...state, isLoadingPostLogIn: false, hasPostLogInError: false, isLoggedIn: true };
    case LoginActionTypes.GET_SSO_PROVIDER_FAIL:
    case LoginActionTypes.POST_SSO_LOG_IN_FAIL:
      return {
        ...state,
        isLoadingPostLogIn: false,
        hasPostLogInError: true,
        postLogInErrorMessage: action.payload
      };
    case LoginActionTypes.SET_SSO_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.payload
      };
    case LoginActionTypes.CLEAR_POST_LOG_IN_ERROR:
      return ssoLoginInitialState;
    case USER_LOGOUT:
      return ssoLoginInitialState;
    default:
      return state;
  }
}

const signInReducerInitialState = {
  errorMessage: [],
  hasPostSignUpError: false,
  isLoadingPostSignUp: false,
  signInData: '',
  isSignedIn: false
};

function signInReducer(state = signInReducerInitialState, action) {
  switch (action.type) {
    case LoginActionTypes.POST_SIGN_IN:
      return { ...state, isLoadingPostSignUp: true, hasPostSignUpError: false };
    case LoginActionTypes.POST_SIGN_IN_SUCCESS:
      return { ...state, isSignedIn: true, isLoadingPostSignUp: false, hasPostSignUpError: false };
    case LoginActionTypes.POST_SIGN_IN_FAIL:
      return { ...state, isLoadingPostSignUp: false, hasPostSignUpError: true, errorMessage: action.payload };
    case LoginActionTypes.RESET_SIGN_IN:
      return signInReducerInitialState;
    default:
      return state;
  }
}

const beforeSignInInitialState = {
  isAuthorizingUser: true,
  isUserAuthorized: false,
  hasUserAuthorizationError: false,
  userAuthorizationErrorMessage: ''
};

function beforeSignInReducer(state = beforeSignInInitialState, action) {
  switch (action.type) {
    case LoginActionTypes.BEFORE_SIGN_IN:
      return {
        ...state,
        isUserAuthorized: false,
        isAuthorizingUser: true,
        hasUserAuthorizationError: false
      };
    case LoginActionTypes.BEFORE_SIGN_IN_SUCCESS:
      return {
        ...state,
        isUserAuthorized: true,
        isAuthorizingUser: false,
        hasUserAuthorizationError: false
      };
    case LoginActionTypes.BEFORE_SIGN_IN_FAIL:
      return {
        ...state,
        isUserAuthorized: false,
        isAuthorizingUser: false,
        hasUserAuthorizationError: true,
        userAuthorizationErrorMessage: action.payload
      };
    case LoginActionTypes.CLEAR_BEFORE_SIGN_IN_ERROR:
      return {
        ...state,
        isUserAuthorized: false,
        isAuthorizingUser: false,
        hasUserAuthorizationError: false,
        userAuthorizationErrorMessage: ''
      };
    default:
      return state;
  }
}

const allSignInReducers = combineReducers({
  beforeUserInteraction: beforeSignInReducer,
  afterUserInteraction: signInReducer
});

export default combineReducers({
  signIn: allSignInReducers,
  logIn: logInReducer,
  ssoLogIn: ssoLogInReducer
});
