import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Link from '../../../design-system/link/Link';

const UNIT_TYPES = Object.freeze({
  MID_COURSE: {
    label: 'Mid-course test',
    faqLink: 'https://chatterboxlanguages.freshdesk.com/support/solutions/articles/103000154466-what-are-the-mid-course-tests-'
  },
  PRACTICE: {
    label: 'Practice Exam',
    faqLink: 'https://chatterboxlanguages.freshdesk.com/support/solutions/articles/103000154468-the-practice-exam-and-final-exam'
  },
  FINAL: {
    label: 'Final Exam',
    faqLink: 'https://chatterboxlanguages.freshdesk.com/support/solutions/articles/103000154468-the-practice-exam-and-final-exam'
  }
});

const AssesmentFaqRelatedLinks = ({ title }) => {
  const [type, setType] = useState(null);

  const getUnitType = () => {
    if (!title) return;
    const normalizedTitle = title.toLowerCase();
    if (normalizedTitle.includes('mid-course')) return UNIT_TYPES.MID_COURSE;
    if (normalizedTitle.includes('practice exam')) return UNIT_TYPES.PRACTICE;
    if (normalizedTitle.includes('final exam')) return UNIT_TYPES.FINAL;
  };

  useEffect(() => {
    const unitType = getUnitType();
    if (unitType) setType(unitType);
  }, [title]);

  if (!type || !title) return null;

  return <Link href={type.faqLink} testId="assessment-faq">{`More about the ${type.label}`}</Link>;
};

AssesmentFaqRelatedLinks.propTypes = {
  title: PropTypes.string.isRequired
};

export default AssesmentFaqRelatedLinks;
