import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import styles from '../style/components/NotFound.module.scss';
import { unsubscribeFromEmails } from '../actions/user.js';

class Unsubscribed extends Component {
  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    if (params.userID) {
      this.props.unsubscribeFromEmails(params.userID);
    }
  }

  render() {
    return (
      <div className={styles.message_container}>
        <div className={styles.message}>
          <h1>Thank you.</h1>
          <p>You have been succesfully removed from this subscriber list.</p>
          <p>
            If you need assistance, please contact us at <a href="mailto:support@chatterbox.io">support@chatterbox.io</a>.
          </p>
          <Link to="/">Go back to the website</Link>
        </div>
      </div>
    );
  }
}

Unsubscribed.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired,
  unsubscribeFromEmails: PropTypes.func.isRequired
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, { unsubscribeFromEmails })(Unsubscribed);
