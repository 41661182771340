import React from 'react';
import PropTypes from 'prop-types';

import mixpanelHelper from '../../../../utils/mixpanel-helper';

import styles from './Lesson.module.scss';
import { BOOKING_STATES } from '../../../../common/bookingStates';
import { convertStringsOfDateAndTimeToDate, convertToLocaleAndFormat } from '../../../../utils/time-helpers';
import ProgressLineSection from './ProgressLineSection';
import ProgressRing from '../../../progress-ring/ProgressRing';

const Lesson = ({ first, index, isEnabled, isOptional, isSelected, last, nextLessonToStudyNumber, onSelectUnit, timezone, unit }) => {
  const { number: unitNumber, name: unitName } = unit;

  const getLessonCompletionLabel = () => {
    if (!isEnabled) return 'Locked';
    const progress = unit?.progress ? Math.ceil(unit.progress * 100) : 0;
    return (
      <span>
        <span id="dont-translate">{progress}%</span> completed
      </span>
    );
  };

  const getLessonCompletion = () => {
    if (!isEnabled) return 0;
    return unit?.progress ? Math.ceil(unit.progress * 100) : 0;
  };

  const getBookingState = (state) => {
    if (!state) return BOOKING_STATES['not-booked'];
    if (BOOKING_STATES[state] === BOOKING_STATES.booked) {
      return (
        <span>
          <span>Session @ </span>
          <span id="dont-translate">
            {convertToLocaleAndFormat(convertStringsOfDateAndTimeToDate(unit.appointment?.date, unit.appointment?.start_time), timezone)}
          </span>
        </span>
      );
    }
    return BOOKING_STATES[state];
  };

  return (
    <div className={styles['lesson-wrapper']} data-testid="lesson-wrapper">
      <ProgressLineSection
        isDone={nextLessonToStudyNumber > unitNumber}
        isFirst={first}
        isLast={last}
        isCurrent={nextLessonToStudyNumber === unitNumber}
        isOptional={isOptional}
      />
      <ProgressRing completion={getLessonCompletion()} isBlocked={!isEnabled} isSelected={isSelected} />
      <button
        className={[
          styles.container,
          isEnabled ? styles['container--past'] : styles['container--inactive'],
          isSelected ? styles['container--selected'] : undefined
        ].join(' ')}
        data-testid={`${unitNumber}.${unitName}-button`}
        onClick={() => {
          if (!isEnabled) return;
          mixpanelHelper.track('Click', { course_unit: unitName });
          onSelectUnit(index, unit);
        }}>
        <div className={styles['lesson']}>
          <p className={styles['lesson__number']}>{unitNumber}.</p>
          <p id="dont-translate" className={styles['lesson__title']}>
            {unitName}
          </p>
          <span className={styles['lesson__subdata']}>Lesson | {getLessonCompletionLabel()}</span>
          <span className={styles['lesson__subdata']}>Live Practice: {getBookingState(unit?.booking_state)}</span>
        </div>
      </button>
    </div>
  );
};

Lesson.propTypes = {
  first: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  isOptional: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  last: PropTypes.bool.isRequired,
  nextLessonToStudyNumber: PropTypes.number.isRequired,
  onSelectUnit: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  unit: PropTypes.shape({
    number: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    booking_state: PropTypes.string.isRequired,
    progress: PropTypes.number,
    appointment: PropTypes.shape({
      date: PropTypes.string,
      start_time: PropTypes.string
    })
  })
};

export default Lesson;
