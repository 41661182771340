import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import platform from 'platform';
import jstz from 'jstimezonedetect';

import styles from '../style/containers/TutorDashboard.module.scss';

import { sendSentryError } from '../setup/sentry';
import { joinAllConversations } from '../actions/chat';
import mixpanelHelper from '../utils/mixpanel-helper';
import TutorProfile from '../components/tutor-dashboard/TutorProfile';
import AppointmentsToday from '../components/tutor-dashboard/AppointmentsToday/AppointmentsToday';
import { convertToLocaleAndFormat } from '../utils/time-helpers';
import UserPossibilities from '../components/tutor-dashboard/UserPossibilities';

const TutorDashboard = ({ joinAllConversationsAction, timezone, user }) => {
  useEffect(() => {
    handleMixPanelStuff();
    trackUserGuidingTutor();
    joinAllConversationsAction(user);
  }, [user]);

  const handleMixPanelStuff = () => {
    mixpanelHelper.identifyUser({ id: user.id, email: user.email });
    mixpanelHelper.visitedPage('Tutor Home Page');
  };

  const trackUserGuidingTutor = () => {
    const os = platform.os.toString();
    const browser = platform.name;

    const tz = jstz.determine().name();

    if (!window?.userGuiding?.identify) return;
    if (!user) return;

    try {
      window.userGuiding.identify(user.id, {
        user_type: user.user_type,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        time_zone: tz,
        browser,
        os
      });
    } catch (error) {
      sendSentryError({ error, context: 'trackUserGuidingTutor' });
    }
  };

  return (
    <main className={styles.container}>
      <div className={styles.aside}>
        <TutorProfile />
        <UserPossibilities />
      </div>
      <div className={styles.content}>
        <h1>Welcome back, {user?.first_name}!</h1>
        <h2>
          Here are your sessions for <span>today</span>, {convertToLocaleAndFormat(new Date(), timezone, 'EEEE do MMMM')}:
        </h2>
        <AppointmentsToday />
      </div>
    </main>
  );
};

const mapStateToProps = (state) => ({
  timezone: state.time.timezone,
  user: state.user
});

TutorDashboard.propTypes = {
  joinAllConversationsAction: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

export default connect(mapStateToProps, {
  joinAllConversationsAction: joinAllConversations
})(TutorDashboard);
