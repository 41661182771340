import React, { Component } from 'react';
import { injectStripe, StripeProvider, Elements } from 'react-stripe-elements';
import PropTypes from 'prop-types';
import _ChargeUserCard from './charge-user-card';
import Mixpanel from '../utils/mixpanel-helper.js';

const ChargeUserCard = injectStripe(_ChargeUserCard, { withRef: true });

class ChargeUserModal extends Component {
  componentDidMount() {
    Mixpanel.track('Payment modal opened');
  }

  handleAction = (response) => {
    this.chargeUserCard.wrappedInstance.handleAction(response);
  };

  render() {
    const { handleResult, product, amount, cost, currency, trainingType, deliveryType, closeBuyModal } = this.props;
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <Elements>
          <ChargeUserCard
            ref={(chargeUserCard) => {
              this.chargeUserCard = chargeUserCard;
            }}
            handleResult={handleResult}
            product={product}
            amount={amount}
            cost={cost}
            currency={currency}
            trainingType={trainingType}
            deliveryType={deliveryType}
            closeBuyModal={closeBuyModal}
          />
        </Elements>
      </StripeProvider>
    );
  }
}

ChargeUserModal.propTypes = {
  deliveryType: PropTypes.string.isRequired,
  closeBuyModal: PropTypes.func.isRequired,
  trainingType: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  cost: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired,
  handleResult: PropTypes.func.isRequired
};

ChargeUserModal.defaultProps = {};

export default ChargeUserModal;
