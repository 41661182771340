import React from 'react';
import PropTypes from 'prop-types';
import styles from '../ScrollableTable.module.scss'; // Adjust the path if needed
import Header from './Header';
import Row from './Row';

const ScrollableTable = ({ columns, data, scrollable }) => {
  return (
    <div className={scrollable ? styles['scrollable-wrapper'] : ''}>
      <table className={`${styles['table']} ${styles['scrollable-table-specific']}`}>
        <thead>
          <Header columns={columns} stickyColumnClass={styles['sticky-column']} />
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((item, index) => <Row key={index} data={item} columns={columns} stickyColumnClass={styles['sticky-column']} />)
          ) : (
            <tr>
              <td colSpan={Object.keys(columns).length} className={styles['empty-row']}>
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

ScrollableTable.propTypes = {
  columns: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  scrollable: PropTypes.bool
};

export default ScrollableTable;
