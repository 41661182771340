import instance from '..';

export default class CoursesAPI {
  static async getEnrolledCourses(token) {
    return instance.get('/api/courses/', { headers: { Authorization: `Token ${token}` } });
  }
  static async getCourseDetails({ token, courseId }) {
    return instance.get(`/api/courses/${courseId}`, {
      headers: { Authorization: `Token ${token}` }
    });
  }
  static async getOverallCourseStatus({ token, courseId }) {
    return instance.get(`/api/student_tracker/?course_id=${courseId}`, {
      headers: { Authorization: `Token ${token}` }
    });
  }
}
