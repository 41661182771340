import React from 'react';
import PropTypes from 'prop-types';

import styles from './Button.module.scss';
import LoadingSpinner from '../loading/spinner/LoadingSpinner';

const Button = ({ bold, className, children, type, outfit, onClick, disabled, loading, nowrap, id, testId }) => {
  const buttonOutfit = outfit ? styles[outfit] : styles.regularButton;

  return (
    <button
      className={[`${styles.button} ${buttonOutfit}`, loading && styles.loading, nowrap && styles.nowrap, bold && styles.bold, className].join(' ')}
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      data-testid={testId}
      id={id}>
      {loading ? <LoadingSpinner /> : children}
    </button>
  );
};

Button.propTypes = {
  bold: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.any,
  type: PropTypes.string,
  outfit: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  nowrap: PropTypes.bool,
  id: PropTypes.string,
  testId: PropTypes.string
};

Button.defaultProps = {
  bold: false,
  loading: false,
  children: '',
  id: '',
  type: 'button',
  outfit: 'regularButton',
  onClick: () => {},
  disabled: false,
  nowrap: false
};

export default Button;
