import NewOnboardingAPI from '../services/new-onboarding';
import { actionCreatorsFactory, asyncActionType, createActionTypes } from '../utils/action-helpers';
import { transformObjectUnderscoreToCamelCase } from '../utils/string-helpers';
import { getEnrolledCourses, getEnrolledCoursesActions } from './courses';
import { setSelectedTutor } from './selected-tutor';
import { decodeJWTToken } from '../utils/jwt-helpers';
import { sendSentryError } from '../setup/sentry';

export const NewOnboardingActionTypes = createActionTypes('NewOnboarding', [
  'STORE_DECODED_TOKEN',
  'UPDATE_INITIAL_INFORMATION',
  'HAS_FINISHED_PLACEMENT_TEST',
  asyncActionType('POST_INTEREST'),
  asyncActionType('GET_ONBOARDING_DATA'),
  asyncActionType('RETRIEVE_PLACEMENT_TEST_RESULTS'),
  asyncActionType('POST_SELECTED_COACH'),
  asyncActionType('POST_SIGN_IN_ON_ONBOARDING')
]);

export const storeDecodedToken = ({ invitationToken, email }) => {
  if (!invitationToken) return {};
  const decoded = decodeJWTToken(invitationToken);
  const transformedToCamelCase = transformObjectUnderscoreToCamelCase(decoded);
  return {
    type: NewOnboardingActionTypes.STORE_DECODED_TOKEN,
    payload: { invitationToken, email, ...transformedToCamelCase }
  };
};

export const updateInitialInformation = (payload) => ({
  type: NewOnboardingActionTypes.UPDATE_INITIAL_INFORMATION,
  payload
});

const personalInterestActions = actionCreatorsFactory(
  [NewOnboardingActionTypes.POST_INTEREST, NewOnboardingActionTypes.POST_INTEREST_SUCCESS, NewOnboardingActionTypes.POST_INTEREST_FAIL],
  'postPersonalInterest'
);

export const postPersonalInterest = ({ motivation, successCallback }, securityParams = {}) => {
  return async (dispatch) => {
    try {
      const params = {
        email: securityParams?.email,
        password_token: securityParams?.invitationToken
      };
      dispatch(personalInterestActions.postPersonalInterest());
      await NewOnboardingAPI.postOnboarding({ onboardingData: { motivation } }, params);
      dispatch(personalInterestActions.postPersonalInterestSuccess(motivation));
      if (successCallback) successCallback();
    } catch (err) {
      sendSentryError({ err, context: 'postPersonalInterest' });
      dispatch(personalInterestActions.postPersonalInterestFail(err));
    }
  };
};

const pickCoachActions = actionCreatorsFactory(
  [
    NewOnboardingActionTypes.POST_SELECTED_COACH,
    NewOnboardingActionTypes.POST_SELECTED_COACH_SUCCESS,
    NewOnboardingActionTypes.POST_SELECTED_COACH_FAIL
  ],
  'postSelectedCoach'
);

export const postPickCoach = ({ coachData, successCallback }, securityParams = {}) => {
  return async (dispatch) => {
    try {
      const params = { email: securityParams?.email, password_token: securityParams?.token };
      dispatch(pickCoachActions.postSelectedCoach());
      await NewOnboardingAPI.postOnboarding({ onboardingData: { coachId: coachData.id } }, params);
      dispatch(setSelectedTutor(coachData));
      dispatch(pickCoachActions.postSelectedCoachSuccess(coachData));
      if (successCallback) successCallback();
    } catch (err) {
      sendSentryError({ err, context: 'postPickCoach' });
      dispatch(pickCoachActions.postSelectedCoachFail(err));
    }
  };
};

const postSignUpOnOnbardingActions = actionCreatorsFactory(
  [
    NewOnboardingActionTypes.POST_SIGN_IN_ON_ONBOARDING,
    NewOnboardingActionTypes.POST_SIGN_IN_ON_ONBOARDING_SUCCESS,
    NewOnboardingActionTypes.POST_SIGN_IN_ON_ONBOARDING_FAIL
  ],
  'postSignUpOnOnboarding'
);

export const postSignUpOnOnboarding = (securityParams = {}) => {
  return async (dispatch) => {
    try {
      const params = { email: securityParams?.email, password_token: securityParams?.token };
      dispatch(postSignUpOnOnbardingActions.postSignUpOnOnboarding());
      await NewOnboardingAPI.postOnboarding({ onboardingData: { has_created_password: true } }, params);
      dispatch(postSignUpOnOnbardingActions.postSignUpOnOnboardingSuccess());
    } catch (err) {
      sendSentryError({ err, context: 'postSignUpOnOnboarding' });
      dispatch(postSignUpOnOnbardingActions.postSignUpOnOnboardingFail());
    }
  };
};

export const setHasFinishedPlacementTest = (payload) => ({
  type: NewOnboardingActionTypes.HAS_FINISHED_PLACEMENT_TEST,
  payload
});

const placementTestActions = actionCreatorsFactory(
  [
    NewOnboardingActionTypes.RETRIEVE_PLACEMENT_TEST_RESULTS,
    NewOnboardingActionTypes.RETRIEVE_PLACEMENT_TEST_RESULTS_SUCCESS,
    NewOnboardingActionTypes.RETRIEVE_PLACEMENT_TEST_RESULTS_FAIL
  ],
  'retrievePlacementTestResults'
);

export const retrievePlacementTestResults = ({ placement_test_id, response_id, token, successCallback }) => {
  return async (dispatch) => {
    try {
      dispatch(placementTestActions.retrievePlacementTestResults());
      const { data: enrolledCourses } = await NewOnboardingAPI.retrievePlacementTestScore(token, {
        placement_test_id,
        response_id
      });
      dispatch(getEnrolledCoursesActions.getEnrolledCoursesSuccess([enrolledCourses]));
      dispatch(placementTestActions.retrievePlacementTestResultsSuccess(enrolledCourses));
      dispatch(getEnrolledCourses(token));
      if (successCallback) successCallback();
    } catch (err) {
      console.log('err', err);
      sendSentryError({ err, context: 'retrievePlacementTestResults' });
      const errorMessage =
        (err && err.response && err.response.data && err.response.data.error_message) ||
        'Something went wrong while retrieving your placement test results.';
      dispatch(placementTestActions.retrievePlacementTestResultsFail(errorMessage));
    }
  };
};

const getOnboardingDataActions = actionCreatorsFactory(
  [
    NewOnboardingActionTypes.GET_ONBOARDING_DATA,
    NewOnboardingActionTypes.GET_ONBOARDING_DATA_SUCCESS,
    NewOnboardingActionTypes.GET_ONBOARDING_DATA_FAIL
  ],
  'getOnboardingData'
);

export const getOnboardingData = ({ email, password_token, token, successCallback }) => {
  return async (dispatch) => {
    try {
      dispatch(getOnboardingDataActions.getOnboardingData());
      const { data } = await NewOnboardingAPI.getOnboardingData({ token, password_token, email });
      dispatch(getOnboardingDataActions.getOnboardingDataSuccess(data.previous_onboarding));
      if (data?.previous_onboarding?.selected_coach)
        dispatch(
          setSelectedTutor({
            id: data?.previous_onboarding?.selected_coach.id,
            name: data?.previous_onboarding?.selected_coach.first_name,
            ...data?.previous_onboarding?.selected_coach
          })
        );
      if (successCallback) successCallback();
    } catch (err) {
      sendSentryError({ err, context: 'getOnboardingData' });
      const errorMsg = err.message || 'Something went wrong while getting your onboarding data';
      dispatch(getOnboardingDataActions.getOnboardingDataFail(errorMsg));
    }
  };
};
