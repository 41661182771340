import { addMonths, endOfMonth, getDate, getMonth, getYear, isSameDay, startOfMonth, subMonths } from 'date-fns';
import { buildMonthAndYearStr } from '../reducers/bookable';

export function compareByDate(a, b) {
  if (a.date < b.date) {
    return -1;
  }
  if (a.date > b.date) {
    return 1;
  }
  return 0;
}

export function getNextTwelveMonths() {
  const monthNames = [];

  monthNames[0] = 'January';
  monthNames[1] = 'February';
  monthNames[2] = 'March';
  monthNames[3] = 'April';
  monthNames[4] = 'May';
  monthNames[5] = 'June';
  monthNames[6] = 'July';
  monthNames[7] = 'August';
  monthNames[8] = 'September';
  monthNames[9] = 'October';
  monthNames[10] = 'November';
  monthNames[11] = 'December';

  const months = [];
  const tmpDate = new Date();
  let tmpYear = tmpDate.getFullYear();
  let tmpMonth = tmpDate.getMonth() + 1;
  let monthLiteral;

  for (let i = 0; i < 12; i += 1) {
    tmpDate.setMonth(tmpMonth + i);
    tmpDate.setFullYear(tmpYear);
    monthLiteral = monthNames[tmpMonth];

    months.push(`${monthLiteral} ${tmpYear}`);

    tmpYear = tmpMonth === 11 ? tmpYear + 1 : tmpYear;
    tmpMonth = tmpMonth === 11 ? 0 : tmpMonth + 1;
  }

  return months;
}

export function getMonthName(number) {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  return monthNames[number];
}

export function dateToIndex(date, months) {
  const dateSplit = date?.split('-');
  const monthNumber = parseInt(dateSplit[1], 10);
  const yearNumber = parseInt(dateSplit[0], 10);
  const monthIndex = months.indexOf(`${getMonthName(monthNumber)} ${yearNumber}`);
  if (monthIndex === -1) {
    return undefined;
  }
  return monthIndex;
}

export const getRegularMonth = (date) => {
  return adaptToRegularMonth(getMonth(date));
};

export const adaptToRegularMonth = (month) => Number(month) + 1;

export const adaptToJavascriptMonth = (month) => Number(month) - 1;

export const organizeDatesIntoYearsAndMonths = (rawDates) => {
  if (!rawDates) return [];
  return rawDates.reduce((acc, date) => {
    const year = getYear(new Date(date));
    const month = getRegularMonth(new Date(date));
    const day = getDate(new Date(date));

    if (!acc[year]) {
      acc[year] = {
        [month]: {
          [day]: [date]
        }
      };
      return acc;
    }

    const monthsOfYear = acc[year];
    if (!monthsOfYear[month]) {
      monthsOfYear[month] = {
        [day]: [date]
      };
      return acc;
    }

    const daysOfMonth = monthsOfYear[month];
    if (!daysOfMonth[day]) {
      daysOfMonth[day] = [date];
      return acc;
    }

    daysOfMonth[day] = [...daysOfMonth[day], date];
    return acc;
  }, {});
};

export const organizeDatesIntoYearsAndMonthsMap = (rawTimestamps) => {
  const map = new Map();
  if (!rawTimestamps) return map;
  rawTimestamps.forEach((ts) => {
    const key = buildMonthAndYearStr(ts);
    const prev = map.get(key) || [];
    map.set(key, [...prev, ts]);
  });
  return Object.fromEntries(map);
};

export const isTimestampAvailable = (timestamp, available) => {
  return available.some((ts) => ts === timestamp);
};

export const getDateAvailableSlotsFromTimestamp = (timestamp, datesObj) => {
  const month = getMonthAvailableDays(timestamp, datesObj);
  return month.filter((ts) => isSameDay(new Date(ts), new Date(timestamp)));
};

export const getMonthAvailableDays = (timestamp, datesObj) => {
  const key = buildMonthAndYearStr(timestamp);
  const source = new Map(Object.entries(datesObj || {}));
  return source.get(key) || [];
};

export const setAvailableScheduleInterval = (referenceDate, start, end) => ({
  from: subMonths(startOfMonth(referenceDate), start).toISOString(),
  to: addMonths(endOfMonth(referenceDate), end).toISOString()
});
