import React from 'react';
import PropTypes from 'prop-types';

import styles from './Arrow.module.scss';
import IconLeftArrow from '../../../assets/icons/icon_left-arrow.svg';

const Arrow = ({ position }) => {
  return (
    <div className={[styles.arrow, styles[position]].join(' ')}>
      <img src={IconLeftArrow} alt="arrow" />
    </div>
  );
};

Arrow.propTypes = {
  position: PropTypes.string
};

Arrow.defaultProps = {
  position: 'left'
};

export default Arrow;
