import instance from '..';

export default class OrdersAPI {
  static async getAllOrders(token) {
    return instance.get('/api/get_all_orders/', { headers: { Authorization: `Token ${token}` } });
  }
  static async getOrder(token) {
    return instance.get('/api/orders/', { headers: { Authorization: `Token ${token}` } });
  }
}
