export default function adaptUserData(data) {
  const adapted = {
    first_name: data?.firstName,
    last_name: data?.lastName,
    email: data?.email,
    phone_number: data?.phoneNumber,
    preferred_language: data?.preferredLanguage,
    time_zone_name: data?.timezone,
    news_events_promotions_email: false,
    token: data?.token
  };

  return Object.fromEntries(Object.entries(adapted).filter(([, v]) => v !== undefined));
}
