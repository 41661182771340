import { TutorsActionTypes } from '../actions/tutors';
import { USER_LOGOUT } from '../actions/types';

const tutorsInitialState = {
  tutors: [],
  hasGetTutorsError: false,
  getTutorsErrorMessage: '',
  isGettingTutors: false
};

const parseAvailabilityMatrix = (tutors) => {
  return tutors.map((tutor) => ({
    ...tutor,
    availability_matrix: JSON.parse(tutor.availability_matrix)
  }));
};

export default function tutorsReducer(state = tutorsInitialState, action) {
  switch (action.type) {
    case TutorsActionTypes.GET_TUTORS:
      return { ...state, isGettingTutors: true, hasGetTutorsError: false };
    case TutorsActionTypes.GET_TUTORS_SUCCESS:
      return {
        ...state,
        tutors: parseAvailabilityMatrix(action.payload),
        isGettingTutors: false,
        hasGetTutorsError: false
      };
    case TutorsActionTypes.GET_TUTORS_FAIL:
      return {
        ...state,
        tutors: [],
        isGettingTutors: false,
        hasGetTutorsError: true,
        getTutorsErrorMessage: action.payload
      };
    case USER_LOGOUT:
      return tutorsInitialState;
    default:
      return state;
  }
}
