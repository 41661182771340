import { combineReducers } from 'redux';
import { ChatActionTypes } from '../actions/chat';
import { USER_LOGOUT } from '../actions/types';

const conversationsInitialState = {
  token: '',
  user: {},
  isJoiningChats: false,
  errorJoiningChats: false,
  isLoadingConversations: false,
  errorGettingConversations: false,
  SelectedConversation: {},
  selectedConversationData: {},
  Conversations: [],
  isGettingParticipants: false,
  isGettingUsers: false,
  errorGettingParticipants: false,
  errorGettingUsers: false,
  isSupport: false
};

function ConversationsReducer(state = conversationsInitialState, action) {
  switch (action.type) {
    case ChatActionTypes.GET_CHAT_TOKEN_SUCCESS:
      return { ...state, token: action.payload };
    case ChatActionTypes.SET_USER:
      return { ...state, user: action.payload };
    case ChatActionTypes.GET_CONVERSATION_PARTICIPANTS:
      return { ...state, errorGettingParticipants: false, isGettingParticipants: true };
    case ChatActionTypes.GET_USERS_FROM_PARTICIPANTS:
      return { ...state, errorGettingUsers: false, isGettingUsers: true };
    case ChatActionTypes.GET_CONVERSATION_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        selectedConversationData: { ...state.selectedConversationData, currentParticipants: action.payload },
        isGettingParticipants: false,
        errorGettingParticipants: false
      };
    case ChatActionTypes.GET_USERS_FROM_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        selectedConversationData: { ...state.selectedConversationData, users: action.payload },
        isGettingUsers: false,
        errorGettingUsers: false
      };
    case ChatActionTypes.GET_CONVERSATION_PARTICIPANTS_FAIL:
      return {
        ...state,
        errorGettingParticipants: true,
        isGettingParticipants: false,
        selectedConversationData: { ...state.selectedConversationData, participants: [] }
      };
    case ChatActionTypes.GET_USERS_FROM_PARTICIPANTS_FAIL:
      return { ...state, errorGettingUsers: true, isGettingUsers: false, selectedConversationData: { ...state.selectedConversationData, users: [] } };
    case ChatActionTypes.JOIN_CONVERSATION:
      return { ...state, errorJoiningChats: false, isJoiningChats: true };
    case ChatActionTypes.JOIN_CONVERSATION_SUCCESS:
      return { ...state, isJoiningChats: false };
    case ChatActionTypes.JOIN_CONVERSATION_FAIL:
      return { ...state, errorJoiningChats: true, isJoiningChats: false };
    case ChatActionTypes.GET_CHAT_CONVERSATIONS:
      return { ...state, errorGettingConversations: false, isLoadingConversations: true };
    case ChatActionTypes.GET_CHAT_CONVERSATIONS_SUCCESS:
      return { ...state, Conversations: action.payload, isLoadingConversations: false };
    case ChatActionTypes.GET_CHAT_CONVERSATIONS_FAIL:
      return { ...state, errorGettingConversations: true, isLoadingConversations: false };
    case ChatActionTypes.SET_SELECTED_CONVERSATION:
      return { ...state, SelectedConversation: action.payload };
    case ChatActionTypes.CLEAR_CONVERSATION:
      return conversationsInitialState;
    case ChatActionTypes.SET_IS_SUPPORT:
      return { ...state, isSupport: action.payload };
    default:
      return state;
  }
}

const isMessageAlreadySet = (msg, currentMsgs) => currentMsgs?.some((m) => m?.sid === msg?.sid);

const setNewMessage = (newMessage, currentMessages) => {
  const isAlreadyThere = isMessageAlreadySet(newMessage, currentMessages);
  if (isAlreadyThere) return currentMessages;
  return [...currentMessages, newMessage];
};

const messagesInitialState = {
  unreadMessages: 0,
  unreadLangHelperChat: 0,
  isLoadingMessages: false,
  errorGettingMessages: false,
  isUpdatingMessages: false,
  errorUpdatingMessages: false,
  messages: [],
  isSendingMessage: false,
  errorSendingMessage: false,
  userTyping: null
};

function MessagessReducer(state = messagesInitialState, action) {
  switch (action.type) {
    case ChatActionTypes.GET_MESSAGES:
      return { ...state, errorGettingMessages: false, isLoadingMessages: true };
    case ChatActionTypes.GET_MESSAGES_SUCCESS:
      return { ...state, errorGettingMessages: false, messages: action.payload, isLoadingMessages: false };
    case ChatActionTypes.GET_MESSAGES_FAIL:
      return { ...state, errorGettingMessages: true, isLoadingMessages: false };
    case ChatActionTypes.SEND_MESSAGE_TO_BACKEND:
      return { ...state, errorSendingMessage: false, isSendingMessage: true };
    case ChatActionTypes.SEND_MESSAGE_TO_BACKEND_SUCCESS:
      return { ...state, isSendingMessage: false };
    case ChatActionTypes.SEND_MESSAGE_TO_BACKEND_FAIL:
      return { ...state, errorSendingMessage: true, isSendingMessage: false };
    case ChatActionTypes.SEND_MESSAGE_TO_TWILIO:
      return { ...state, errorSendingMessage: false, isSendingMessage: true };
    case ChatActionTypes.SEND_MESSAGE_TO_TWILIO_SUCCESS:
      return { ...state, isSendingMessage: false };
    case ChatActionTypes.SEND_MESSAGE_TO_TWILIO_FAIL:
      return { ...state, errorSendingMessage: true, isSendingMessage: false };
    case ChatActionTypes.ADD_MESSAGE:
      return { ...state, messages: setNewMessage(action.payload, state.messages) };
    case ChatActionTypes.USER_TYPING:
      return { ...state, userTyping: action.payload };
    case ChatActionTypes.CLEAR_MESSAGES:
      return messagesInitialState;

    case ChatActionTypes.CLEAN_SENDING_MESSAGE_ERROR:
      return { ...state, errorSendingMessage: false };
    case ChatActionTypes.CLEAN_GETTING_MESSAGES_ERROR:
      return { ...state, errorGettingMessages: false };

    case USER_LOGOUT:
      return {};
    case ChatActionTypes.CHECK_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        unreadMessages: action.payload.unreadMessages,
        unreadLangHelperChat: action.payload.unreadLangHelperChat
      };
    default:
      return state;
  }
}

export default combineReducers({
  conversations: ConversationsReducer,
  messages: MessagessReducer
});
