import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../style/components/uikit/NumberIcon.module.scss';

const NumberIcon = ({ number, offsetLeft }) => {
  const isOffset = offsetLeft && offsetLeft !== 'false';
  const className = `${styles.container} ${isOffset ? styles.offsetLeft : ''}`;

  return (
    <span className={className}>
      <span className={styles.number}>{number}</span>
    </span>
  );
};

NumberIcon.propTypes = {
  number: PropTypes.number.isRequired,
  offsetLeft: PropTypes.string
};

NumberIcon.defaultProps = {
  offsetLeft: 'false'
};

export default NumberIcon;
