import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input } from 'react-chat-elements';
import AttachButton from '../../../../components/design-system/attachButton';
import 'react-chat-elements/dist/main.css';

import styles from './InputMessage.module.scss';
import { ReactComponent as IconImage } from '../../../../assets/icons/icon_image.svg';
import Button from '../../../design-system/button/Button';
import { sendMessageToBackend, sendMessageToTwilio } from '../../../../actions/chat';

const InputMessage = ({ SelectedConversation, disabled, isSendingMessage, sendMessageToBackendAction, sendMessageToTwilioAction, user }) => {
  const inputRef = useRef('');
  const [value, setValue] = useState('');

  const handleInputChange = (evt) => {
    const { target } = evt;
    if (!target) return;
    setValue(target?.value);
  };

  const onAttachFile = (file, type) => {
    const formData = new FormData();
    formData.append(type, file[0]);
    sendMessageToTwilioAction({
      SelectedConversation,
      message: formData,
      callback: () => {
        setValue('');
        inputRef?.current?.clear();
      }
    });
    sendMessageToBackendAction({
      token: user?.token,
      message: {
        userId: user?.id,
        messageContent: file[0]?.name,
        type
      },
      channelId: SelectedConversation?.channelState?.uniqueName,
      callback: () => setValue('')
    });
  };

  const sendMessage = () => {
    if (!value) return;

    const message = value.trim();
    // sendMessageToTwilioAction({
    //   SelectedConversation,
    //   message,
    //   callback: () => {
    //     setValue('');
    //     inputRef?.current?.clear();
    //   }
    // });
    sendMessageToBackendAction({
      token: user?.token,
      message: {
        userId: user?.id,
        messageContent: message,
        type: 'message'
      },
      channelId: SelectedConversation?.channelState?.uniqueName,
      callback: () => {
        setValue('');
        inputRef?.current?.clear();
      }
    });
  };

  return (
    <Input
      autoHeight
      maxHeight={80}
      placeholder="Type your message here..."
      multiline
      onChange={handleInputChange}
      ref={inputRef}
      minHeight={43}
      className={styles.input}
      rightButtons={
        <div className={styles.chatButtonsWrapper}>
          <div className={styles.attachButtons}>
            <AttachButton disabled={disabled} onAttachFile={(files) => onAttachFile(files, 'file')} />
            <AttachButton
              disabled={disabled}
              accept={{ 'image/*': [] }}
              icon={<IconImage />}
              onAttachFile={(files) => onAttachFile(files, 'image')}
            />
          </div>
          <Button
            disabled={!value.length || disabled}
            onClick={sendMessage}
            className={styles.sendButton}
            outfit="greenButton"
            loading={isSendingMessage}>
            Send
          </Button>
        </div>
      }
    />
  );
};

InputMessage.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isSendingMessage: PropTypes.bool.isRequired,
  SelectedConversation: PropTypes.object.isRequired,
  sendMessageToBackendAction: PropTypes.func.isRequired,
  sendMessageToTwilioAction: PropTypes.func.isRequired,
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  SelectedConversation: state.chat.conversations?.SelectedConversation,
  isSendingMessage: state.chat.messages.isSendingMessage,
  user: state.user
});

export default connect(mapStateToProps, {
  sendMessageToBackendAction: sendMessageToBackend,
  sendMessageToTwilioAction: sendMessageToTwilio
})(InputMessage);
