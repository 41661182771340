import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, ControlLabel, Form, FormControl, FormGroup } from 'react-bootstrap';
import TimePicker from 'rc-time-picker';

import * as moment from 'moment-timezone';

import 'rc-time-picker/assets/index.css';
import appStyles from '../../style/containers/App.module.scss';
import styles from '../../style/components/calendar/TimeOffForm.module.scss';
import { getMultipleErrorMessage } from '../../utils/error-helpers';
import ErrorToast from '../design-system/toast/errorToast/ErrorToast';
import Button from '../design-system/button/Button';

class TimeOffForm extends Component {
  constructor(props) {
    super();

    if (props.start && props.end) {
      const start = props.start.clone();
      start.tz(props.tutor.time_zone_name);

      const end = props.end.clone();
      end.tz(props.tutor.time_zone_name);

      this.state.startDay = props.start.format('YYYY-MM-DD');
      this.state.endDay = props.end.format('YYYY-MM-DD');

      this.state.timeOffIsOneDay = this.state.startDay === this.state.endDay;

      this.state.startTime = props.start.format('HH:mm');
      this.state.endTime = props.end.format('HH:mm');

      if (this.state.timeOffIsOneDay) {
        if (this.state.startTime !== '00:00' || this.state.endTime !== '23:59') {
          this.state.startIsAllDay = false;
        } else {
          this.state.startTime = null;
          this.state.endTime = null;
        }
      } else {
        if (this.state.startTime !== '00:00') {
          this.state.startIsAllDay = false;
        } else {
          this.state.startTime = null;
        }

        if (this.state.endTime !== '23:59') {
          this.state.endIsAllDay = false;
        } else {
          this.state.endTime = null;
        }
      }
    }
  }

  state = {
    endDay: null,
    endIsAllDay: true,
    endTime: null,
    errors: [],
    loading: false,
    startDay: null,
    startIsAllDay: true,
    startTime: null,
    timeOffIsOneDay: true
  };

  onConfirm = () => {
    this.setState({ loading: true });

    const { id, token } = this.props;

    if (!this.validate(this.state.startDay, /^\d\d\d\d-\d\d-\d\d$/, 'start date')) return;

    const startDateString = this.state.startDay;

    let endDateString;
    if (this.state.timeOffIsOneDay) {
      endDateString = startDateString;
    } else {
      if (!this.validate(this.state.endDay, /^\d\d\d\d-\d\d-\d\d$/, 'end date')) return;
      endDateString = this.state.endDay;
    }

    let startTimeString;
    if (this.state.startIsAllDay) {
      startTimeString = '00:00';
    } else {
      if (!this.validate(this.state.startTime, /^\d+:\d\d$/, 'start time')) return;
      startTimeString = this.state.startTime;
    }

    let endTimeString;
    if ((this.state.timeOffIsOneDay && this.state.startIsAllDay) || (!this.state.timeOffIsOneDay && this.state.endIsAllDay)) {
      endTimeString = '23:59';
    } else {
      if (!this.validate(this.state.endTime, /^\d+:\d\d$/, 'end time')) return;
      endTimeString = this.state.endTime;
    }

    const start = moment.tz(`${startDateString} ${startTimeString}`, this.props.timezone);
    start.utc();

    const end = moment.tz(`${endDateString} ${endTimeString}`, this.props.timezone);
    end.utc();

    const startString = start.format();
    const endString = end.format();

    if (id) {
      this.props.updateAbsencePeriod(token, id, startString, endString, this.handleError, () => this.props.closeModal());
    } else {
      this.props.createAbsencePeriod(token, startString, endString, this.handleError, () => this.props.closeModal());
    }
  };

  onDelete = () => {
    this.setState({ loading: true });

    const { token, id } = this.props;

    this.props.deleteAbsencePeriod(token, id, this.handleError, () => this.props.closeModal());
  };

  getInclusive = (endTimeString) => {
    const datetime = moment.utc(`2000-01-01T${endTimeString}`);
    datetime.add(1, 'minute');
    return datetime.format('HH:mm');
  };

  getNonInclusive = (endTimeString) => {
    const datetime = moment.utc(`2000-01-01T${endTimeString}`);
    datetime.subtract(1, 'minute');
    return datetime.format('HH:mm');
  };

  handleError = (error) =>
    this.setState({
      errors: (error.response && error.response.data) || [],
      loading: false
    });

  handleEndTimeChange = (value) => {
    let endTime = value ? value.format('HH:mm') : null;
    endTime = this.getNonInclusive(endTime);
    this.setState({ endTime, errors: [] });
  };

  handleStartTimeChange = (value) => {
    const startTime = value ? value.format('HH:mm') : null;
    this.setState({ startTime, errors: [] });
  };

  handleChange = (event) => {
    const id = event.target.id;
    let value = event.target.value;

    if (id === 'endTime') {
      value = this.getNonInclusive(value);
    }

    this.setState({ [id]: value, errors: [] });
  };

  fail = (message) => this.setState({ errors: [message], loading: false });

  validate = (value, pattern, name, { allowNull = false, allowUndefined = false } = {}) => {
    const fail = () => {
      this.fail(`Invalid ${name}`);
      return false;
    };

    if (!allowNull && value === null) {
      return fail();
    }

    if (!allowUndefined && value === undefined) {
      return fail();
    }

    const string = String(value);
    const result = pattern.test(string);

    if (result !== true) {
      return fail();
    }

    return true;
  };

  toggle = (stateKey) => {
    const value = this.state[stateKey];
    this.setState({ [stateKey]: !value });
  };

  renderCheckbox = (label, stateKey) => (
    <Checkbox className={styles.checkbox} checked={this.state[stateKey]} onChange={() => this.toggle(stateKey)}>
      {label}
    </Checkbox>
  );

  renderErrors = () => {
    const errState = this.state.errors;
    const errMsgs = getMultipleErrorMessage(errState);
    if (!errMsgs.length) return;

    return (
      <div>
        {errMsgs.map((msg, index) => (
          <ErrorToast key={index} errorMsg={msg} show />
        ))}
      </div>
    );
  };

  render() {
    window.state = this.state;
    const { id, title } = this.props;
    const { endTime, startTime } = this.state;

    const startTimeDisplayMoment = startTime ? moment(`2000-01-01 ${startTime}`) : null;

    const endTimeString = endTime ? this.getInclusive(endTime) : '';
    const endTimeDisplayMoment = endTime ? moment(`2000-01-01 ${endTimeString}`) : null;

    return (
      <div className={this.state.loading ? appStyles.loadingModal : null}>
        <div className={styles.container}>
          <h4 className={styles.heading}>{title}</h4>

          <Form className={styles.formWrapper}>
            {this.renderCheckbox('One day', 'timeOffIsOneDay')}
            {/* {this.state.timeOffIsOneDay} */}
            {this.state.timeOffIsOneDay ? (
              <FormGroup className={styles.formGroup}>
                <FormGroup className={styles.secundaryFormGroup}>
                  <ControlLabel className={styles.label}>Date</ControlLabel>
                  <FormControl
                    className={styles.input}
                    componentClass="input"
                    id="startDay"
                    onChange={this.handleChange}
                    pattern="\d\d\d\d-\d\d-\d\d"
                    placeholder="YYYY-MM-DD"
                    required
                    type="date"
                    value={this.state.startDay || ''}
                  />
                </FormGroup>

                {this.renderCheckbox('All day', 'startIsAllDay')}
                <FormGroup className={styles.secundaryFormGroup}>
                  <ControlLabel className={styles.fromlabel}>From</ControlLabel>
                  <TimePicker
                    className={styles.input}
                    defaultValue={startTimeDisplayMoment}
                    onChange={this.handleStartTimeChange}
                    showSecond={false}
                    minuteStep={15}
                    disabled={this.state.startIsAllDay}
                    popupClassName={styles.timeModal}
                  />
                </FormGroup>
                <FormGroup className={styles.secundaryFormGroup}>
                  <ControlLabel className={styles.tolabel}>To</ControlLabel>
                  <TimePicker
                    className={styles.input}
                    defaultValue={endTimeDisplayMoment}
                    onChange={this.handleEndTimeChange}
                    showSecond={false}
                    minuteStep={15}
                    disabled={this.state.startIsAllDay}
                    popupClassName={styles.timeModal}
                  />
                </FormGroup>
              </FormGroup>
            ) : (
              <>
                <FormGroup className={styles.formGroup}>
                  <FormGroup className={styles.secundaryFormGroup}>
                    <ControlLabel className={styles.label}>Start Date</ControlLabel>
                    <FormControl
                      className={styles.input}
                      componentClass="input"
                      id="startDay"
                      onChange={this.handleChange}
                      pattern="\d\d\d\d-\d\d-\d\d"
                      placeholder="YYYY-MM-DD"
                      required
                      type="date"
                      value={this.state.startDay || ''}
                    />
                  </FormGroup>

                  {this.renderCheckbox('All day', 'startIsAllDay')}
                  <FormGroup className={styles.secundaryFormGroup}>
                    <ControlLabel className={styles.fromlabel}>From</ControlLabel>
                    <TimePicker
                      className={styles.input}
                      defaultValue={startTimeDisplayMoment}
                      onChange={this.handleStartTimeChange}
                      showSecond={false}
                      minuteStep={15}
                      disabled={this.state.startIsAllDay}
                      popupClassName={styles.timeModal}
                    />
                  </FormGroup>
                </FormGroup>
                <FormGroup className={styles.formGroup}>
                  <FormGroup className={styles.secundaryFormGroup}>
                    <ControlLabel className={styles.label}>End Date</ControlLabel>
                    <FormControl
                      className={styles.input}
                      componentClass="input"
                      id="endDay"
                      onChange={this.handleChange}
                      pattern="\d\d\d\d-\d\d-\d\d"
                      placeholder="YYYY-MM-DD"
                      required
                      type="date"
                      value={this.state.endDay || ''}
                    />
                  </FormGroup>

                  {this.renderCheckbox('All day', 'endIsAllDay')}
                  <FormGroup className={styles.secundaryFormGroup}>
                    <ControlLabel className={styles.tolabel}>To</ControlLabel>
                    <TimePicker
                      className={styles.input}
                      defaultValue={endTimeDisplayMoment}
                      onChange={this.handleEndTimeChange}
                      showSecond={false}
                      minuteStep={15}
                      disabled={this.state.endIsAllDay}
                      popupClassName={styles.timeModal}
                    />
                  </FormGroup>
                </FormGroup>
              </>
            )}
          </Form>

          {(!this.state.startIsAllDay || !this.state.endIsAllDay) && <p className={styles.instruction}>Please set times in 24-hour format.</p>}

          {this.renderErrors()}

          <div className={styles.buttonsWrapper}>
            <Button className={styles.button} outfit="greenButton" onClick={this.onConfirm} disabled={!this.state.startDay}>
              Confirm
            </Button>

            {id && (
              <Button className={styles.button} bsStyle="danger" onClick={this.onDelete}>
                Delete
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

TimeOffForm.propTypes = {
  createAbsencePeriod: PropTypes.func,
  deleteAbsencePeriod: PropTypes.func,
  end: PropTypes.object,
  closeModal: PropTypes.func,
  id: PropTypes.number,
  start: PropTypes.object,
  title: PropTypes.string,
  token: PropTypes.string.isRequired,
  tutor: PropTypes.object.isRequired,
  updateAbsencePeriod: PropTypes.func,
  timezone: PropTypes.string.isRequired
};

TimeOffForm.defaultProps = {
  createAbsencePeriod: () => {},
  deleteAbsencePeriod: () => {},
  end: null,
  closeModal: () => {},
  id: null,
  start: null,
  title: 'Book time off',
  updateAbsencePeriod: () => {}
};

export default TimeOffForm;
