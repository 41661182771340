import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import { ReactComponent as IconAttachment } from '../../../assets/icons/icon_attachment.svg';

import styles from './AttachButton.module.scss';

const AttachButton = ({ accept, disabled, icon, onAttachFile, maxSize, ...rest }) => {
  const onDrop = useCallback((acceptedFiles) => {
    onAttachFile(acceptedFiles);
  });

  const onError = useCallback((error) => {
    console.log(error);
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    disabled,
    maxSize,
    multiple: false,
    noDrag: true,
    onDrop,
    onError,
    rest
  });

  return (
    <div {...getRootProps()} disabled={disabled} className={styles.wrapper}>
      <input {...getInputProps()} />
      <button disabled={disabled} className={styles.attachButton}>
        {icon}
      </button>
    </div>
  );
};

AttachButton.propTypes = {
  accept: PropTypes.object,
  disabled: PropTypes.bool,
  icon: PropTypes.element.isRequired,
  maxSize: PropTypes.number,
  onAttachFile: PropTypes.func.isRequired
};

AttachButton.defaultProps = {
  disabled: false,
  icon: <IconAttachment />
};

export default AttachButton;
