import React from 'react';
import PropTypes from 'prop-types';

import styles from '../Header.module.scss';

const Header = ({ columns }) => {
  return (
    <tr className={styles.header}>
      {Object.keys(columns).map((col, index) => (
        <th key={`header-${index}`} className={styles.cell}>
          {columns[col]}
        </th>
      ))}
    </tr>
  );
};

Header.propTypes = {
  columns: PropTypes.object.isRequired
};

export default Header;
