import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from '../../style/containers/TuitionExplanation.module.scss';

const ExplanationStep = ({ title, subtitle, bulletPoints, img, leftImg, whiteBg, extra }) => {
  const backgroundClass = whiteBg ? styles.wrapperWhite : styles.wrapperBeige;
  return (
    <div className={[styles.explanationRow, backgroundClass, !leftImg ? styles.explanationRowInv : undefined].join(' ')}>
      {leftImg && <img src={img} className={[styles.explanationImg, styles.imgLeft].join(' ')} alt="screenshot_example" />}
      <div className={styles.explanationWrapper}>
        <h2 className={styles.explanationContent}>{title}</h2>
        <p className={styles.explanationContent}>{subtitle}</p>
        <ul className={styles.explanationList}>
          {bulletPoints.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
        {extra !== undefined && <p className={styles.explanationContent}>{extra}</p>}
      </div>
      {!leftImg && <img src={img} className={[styles.explanationImg, styles.imgRight].join(' ')} alt="screenshot_example" />}
    </div>
  );
};

function mapStateToProps() {
  return {};
}

ExplanationStep.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  bulletPoints: PropTypes.array.isRequired,
  img: PropTypes.string.isRequired,
  leftImg: PropTypes.bool,
  whiteBg: PropTypes.bool,
  extra: PropTypes.string
};

ExplanationStep.defaultProps = {
  leftImg: false,
  whiteBg: false,
  extra: undefined
};

export default connect(mapStateToProps, {})(ExplanationStep);
