import React from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const { search } = useLocation();

  const withoutMark = search.replace('?', '');
  const splitted = withoutMark.split('&');

  const parsed = splitted.reduce((acc, cv) => {
    const [key, value] = cv.split('=');
    return { ...acc, [key]: value };
  }, {});

  return React.useMemo(() => parsed, [search]);
};
