import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './ErrorMessage.module.scss';

const ErrorMessage = ({ errorMsg, onClose, show }) => {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    setShowToast(show);
  }, [show]);

  const handleClose = () => {
    if (onClose) onClose();
    setShowToast(false);
  };

  if (!showToast) return null;

  if (Array.isArray(errorMsg))
    return (
      <>
        {errorMsg.map((error, index) => (
          <div key={index} className={styles.errorWrapper}>
            <p>{error}</p>
            <button type="button" onClick={handleClose}>
              X
            </button>
          </div>
        ))}
      </>
    );

  return (
    <div className={styles.errorWrapper}>
      <p>{errorMsg}</p>
      <button type="button" onClick={handleClose}>
        X
      </button>
    </div>
  );
};

ErrorMessage.propTypes = {
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]).isRequired,
  onClose: PropTypes.func,
  show: PropTypes.bool
};

ErrorMessage.defaultProps = {
  onClose: undefined,
  show: false
};

export default ErrorMessage;
