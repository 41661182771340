import axios from 'axios';

export default function submitBookingFeedback(token, moreAvailabilities, dialectsOrLanguage, moreTutors, feedbackDetails, tutorEmail, studentEmail) {
  const data = {
    moreAvailabilities,
    dialectsOrLanguage,
    moreTutors,
    feedbackDetails,
    tutorEmail,
    studentEmail
  };
  const headers = { Authorization: `Token ${token}` };
  return async () => {
    await axios.post('/api/booking_feedback/', data, { headers });
  };
}
