import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import LoadingSpinner from '../design-system/loading/spinner/LoadingSpinner';

import resourceStyles from '../../style/containers/AssignmentDeliveryFile.module.scss';

const AssignmentDeliveryFile = (props) => {
  const { key, url, name, allowDelete, deleteAssignmentDeliveryFile } = props;

  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={resourceStyles.resourceWrapper} key={key}>
      <div className={resourceStyles.circle} />
      <a href={url} target="_blank" rel="noreferrer">
        {name}
      </a>
      {allowDelete && (
        <Button
          bsStyle="link"
          className={resourceStyles.removeBtn}
          onClick={() => {
            deleteAssignmentDeliveryFile(
              () => {
                setIsLoading(false);
              },
              () => {
                setIsLoading(false);
                toast.error('There has been an error in deleting the file, please try again.');
              }
            );
            setIsLoading(true);
          }}>
          <strong>X</strong>
        </Button>
      )}

      <div className={resourceStyles.spinnerWrapper}>{isLoading && <LoadingSpinner />}</div>
    </div>
  );
};

AssignmentDeliveryFile.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  key: PropTypes.string,
  allowDelete: PropTypes.bool,
  deleteAssignmentDeliveryFile: PropTypes.func.isRequired
};

AssignmentDeliveryFile.defaultProps = {};

export default AssignmentDeliveryFile;
