import { TUTOR_STUDENTS_REQUEST, TUTOR_STUDENTS_SUCCESS, TUTOR_STUDENTS_FAILURE, RESET_TUTOR_STUDENTS } from '../actions/types';

const initialTutorStudentsState = {
  isLoading: false,
  error: null,
  data: []
};

export default function tutorStudentsReducer(state = initialTutorStudentsState, action) {
  switch (action.type) {
    case TUTOR_STUDENTS_REQUEST:
      return { ...state, isLoading: true, error: null };
    case TUTOR_STUDENTS_SUCCESS:
      return { ...state, isLoading: false, data: action.payload, error: null };
    case TUTOR_STUDENTS_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    case RESET_TUTOR_STUDENTS:
      return initialTutorStudentsState;
    default:
      return state;
  }
}
