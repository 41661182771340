export const getMonthTranslated = (lang) => {
  switch (lang) {
    case 'it':
      return monthsItalian;
    case 'pt':
      return monthsPortuguese;
    default:
      return months;
  }
};

const months = Object.freeze({
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
});

const monthsItalian = Object.freeze({
  1: 'Gennaio',
  2: 'Febbraio',
  3: 'Marzo',
  4: 'Aprile',
  5: 'Maggio',
  6: 'Giugno',
  7: 'Luglio',
  8: 'Agosto',
  9: 'Settembre',
  10: 'Ottobre',
  11: 'Novembre',
  12: 'Dicembre'
});

const monthsPortuguese = Object.freeze({
  1: 'Janeiro',
  2: 'Fevereiro',
  3: 'Março',
  4: 'Abril',
  5: 'Maio',
  6: 'Junho',
  7: 'Julho',
  8: 'Agosto',
  9: 'Setembro',
  10: 'Outubro',
  11: 'Novembro',
  12: 'Dezembro'
});

export const getDaysOfWeekTranslated = (lang) => {
  switch (lang) {
    case 'it':
      return daysOfWeekItalian;
    case 'pt':
      return daysOfWeekPortuguese;
    default:
      return daysOfWeek;
  }
};

export const daysOfWeek = Object.freeze({
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday'
});

export const daysOfWeekItalian = Object.freeze({
  0: 'domenica',
  1: 'lunedì',
  2: 'martedì',
  3: 'mercoledì',
  4: 'giovedì',
  5: 'venerdì',
  6: 'sabato'
});

export const daysOfWeekPortuguese = Object.freeze({
  0: 'domingo',
  1: 'segunda-feira',
  2: 'terça-feira',
  3: 'quarta-feira',
  4: 'quinta-feira',
  5: 'sexta-feira',
  6: 'sabado'
});
