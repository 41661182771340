import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../dropdown/Dropdown';

import styles from './DropdownField.module.scss';
import formStyles from '../../../style/utils/_form.module.scss';

const DropdownField = ({ id, initialValue, label, onChange, validation, value, availableValues }) => {
  const [valid] = useState(true);
  const fieldValue = value || initialValue || '';

  return (
    <div className={[styles['field-wrapper'], !valid && styles.invalidField].join(' ')}>
      <label className={formStyles.label} htmlFor={id}>
        {label}
      </label>
      <div className={styles['mobile-wrapper']}>
        <Dropdown
          defaultValue={fieldValue}
          styleDropdown={styles.dropdown}
          styleOptions={styles.dropdownOptions}
          styleSelected={styles.dropdownSelected}
          onSelect={onChange}
          options={availableValues}
        />
      </div>

      {!valid && <p className={styles.invalidMessage}>{validation.message}</p>}
    </div>
  );
};

DropdownField.propTypes = {
  id: PropTypes.string,
  initialValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func,
  validation: PropTypes.object,
  value: PropTypes.string,
  availableValues: PropTypes.array.isRequired
};

DropdownField.defaultProps = {
  type: 'text',
  id: '',
  onChange: () => {}
};

export default DropdownField;
