import { differenceInDays, differenceInHours, differenceInMinutes, format, parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import moment from 'moment-timezone';

export const NAIVE_TIME_DISPLAY_FORMAT = 'HH:mm';
export const DATE_DISPLAY_FORMAT = 'dddd Do MMM';
export const DATE_FNS_DISPLAY_FORMAT = 'EEEE do MMM';
export const DATE_DISPLAY_FORMAT_SHORT = 'ddd Do MMM';
export const DATE_FNS_DISPLAY_FORMAT_SHORT = 'EEE do MMM';
export const DATE_DISPLAY_NUMERIC = 'D MMMM YYYY';
export const TIME_DISPLAY_FORMAT = `${NAIVE_TIME_DISPLAY_FORMAT} z`;
export const MONTH_DISPLAY_FORMAT = 'MMM';
export const DAY_DISPLAY_FORMAT = 'Do';
export const ENDPOINT_DATE_FORMAT = 'YYYY-MM-DD';

function addMinute(timeString) {
  const time = moment(`2000-01-01 ${timeString}`);
  time.add(1, 'minute');
  return time.format(NAIVE_TIME_DISPLAY_FORMAT);
}

export const getDateFromIsoString = (isoString) => isoString.slice(0, 10);
export const getTimeFromIsoString = (isoString) => isoString.slice(11, 16);

/** Try to get the name of the time zone (e.g. 'Europe/London'), defaulting to 'UTC'. */
export function getBrowserTimeZone() {
  try {
    const dateTimeFormat = Intl.DateTimeFormat();
    const timeOptions = dateTimeFormat.resolvedOptions();

    if (timeOptions.timeZone) {
      return timeOptions.timeZone;
    }
  } catch (error) {
    return 'UTC';
  }
}

export const formatDateForCalendr = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const convertStringsOfDateAndTimeToDate = (date, time) => {
  if (!date || !time) return;
  return new Date(`${date}T${time}:00.000Z`);
};
export const convertToLocaleAndFormat = (dateToBeConverted, timezone, timeFormat = 'EEE do MMM, hh:mm aa') => {
  if (!dateToBeConverted) return '';
  return format(utcToZonedTime(dateToBeConverted, timezone), timeFormat);
};

export const parseToFormat = (dateStr, timeFormat = 'EEE do MMM') => {
  const parsed = parse(dateStr, 'yyyy-MM-dd', new Date());
  return format(parsed, timeFormat);
};

export function getMoment(dateString, timeString, timeZone = 'UTC') {
  const dateTime = moment.tz(`${dateString} ${timeString}`, 'UTC');
  return dateTime.tz(timeZone);
}

export function getDateDisplay(dateString, timeString, timeZone) {
  const date = convertStringsOfDateAndTimeToDate(dateString, timeString);
  const tmz = timeZone ? timeZone : DEFAULT_TIMEZONE;
  return convertToLocaleAndFormat(date, tmz, DATE_FNS_DISPLAY_FORMAT);
}

export function getDateDisplayNumeric(dateString, timeString, timeZone = 'UTC') {
  const dateTimeLocal = getMoment(dateString, timeString, timeZone);
  return dateTimeLocal.format(DATE_DISPLAY_NUMERIC);
}

export function getDateDisplayShort(dateString, timeString, timeZone) {
  const date = convertStringsOfDateAndTimeToDate(dateString, timeString);
  const tmz = timeZone ? timeZone : DEFAULT_TIMEZONE;
  return convertToLocaleAndFormat(date, tmz, DATE_FNS_DISPLAY_FORMAT_SHORT);
}

export function getMonthDisplay(dateString, timeString, timeZone = 'UTC') {
  const dateTimeLocal = getMoment(dateString, timeString, timeZone);
  return dateTimeLocal.format(MONTH_DISPLAY_FORMAT);
}

export function getDayDisplay(dateString, timeString, timeZone = 'UTC') {
  const dateTimeLocal = getMoment(dateString, timeString, timeZone);
  return dateTimeLocal.format(DAY_DISPLAY_FORMAT);
}

/** Get a `moment` with the time zone swapped to a new one. */
export function swapTimeZone(moment_, timeZone = 'UTC') {
  const string = moment_.format('YYYY-MM-DDTHH:mm');
  return moment.tz(string, timeZone);
}

export function getTimeDisplay(dateString, timeString, timeZone = 'UTC') {
  const dateTimeLocal = getMoment(dateString, timeString, timeZone);
  return dateTimeLocal.format(TIME_DISPLAY_FORMAT);
}

export function getTimeDisplayNoTimezone(dateString, timeString, timeZone = 'UTC') {
  const dateTimeLocal = getMoment(dateString, timeString, timeZone);
  return dateTimeLocal.format(NAIVE_TIME_DISPLAY_FORMAT);
}

export function getMinutesLeftToStart(dateString, timeString1) {
  const dateTimeMoment = getMoment(dateString, timeString1, 'UTC');
  const currentMoment = moment().utc();
  const duration = moment.duration(dateTimeMoment.diff(currentMoment));
  const minutes = duration.asMinutes();
  return minutes;
}

export function getDiffDaysBetweenNowAndDate(date) {
  if (!date) return;

  return differenceInDays(date, new Date());
}

export function getDiffHoursBetweenNowAndDate(date) {
  if (!date) return;
  return differenceInHours(date, new Date());
}

export function getDiffMinutesBetweenNowAndDate(date) {
  if (!date) return;

  return differenceInMinutes(date, new Date());
}

export function getTimeRangeDisplayNoTimezone(dateString, timeString1, timeString2, timeZone = 'UTC', { extraMinute = true } = {}) {
  const dateTimeLocal1 = getMoment(dateString, timeString1, timeZone);
  const timeDisplay1 = dateTimeLocal1.format(NAIVE_TIME_DISPLAY_FORMAT);

  let timeString2Copy = timeString2;

  if (extraMinute) {
    timeString2Copy = addMinute(timeString2);
  }

  const timeDisplay2 = getTimeDisplayNoTimezone(dateString, timeString2Copy, timeZone);

  return `${timeDisplay1} – ${timeDisplay2}`;
}

export function getTimeRangeDisplay(dateString, timeString1, timeString2, timeZone = 'UTC', { extraMinute = true } = {}) {
  const dateTimeLocal1 = getMoment(dateString, timeString1, timeZone);
  const timeDisplay1 = dateTimeLocal1.format(NAIVE_TIME_DISPLAY_FORMAT);

  let timeString2Copy = timeString2;

  if (extraMinute) {
    timeString2Copy = addMinute(timeString2);
  }

  const timeDisplay2 = getTimeDisplay(dateString, timeString2Copy, timeZone);

  return `${timeDisplay1} – ${timeDisplay2}`;
}

export function getHoursLeftToStart(dateString, timeString1) {
  const dateTimeMoment = getMoment(dateString, timeString1, 'UTC');
  const currentMoment = moment().utc();
  const duration = moment.duration(dateTimeMoment.diff(currentMoment));
  const hours = duration.asHours();
  return hours;
}

export const getCityTimezone = (timezone) => TIMEZONES_MAP.get(timezone) || timezone || TIMEZONES_MAP.get('UTC');

export const TIMEZONES = Object.freeze({
  'Africa/Abidjan': 'Abidjan',
  'Africa/Accra': 'Accra',
  'Africa/Algiers': 'Algiers',
  'Africa/Bissau': 'Bissau',
  'Africa/Cairo': 'Cairo',
  'Africa/Casablanca': 'Casablanca',
  'Africa/Ceuta': 'Ceuta',
  'Africa/El_Aaiun': 'El Aaiun',
  'Africa/Johannesburg': 'Johannesburg',
  'Africa/Khartoum': 'Khartoum',
  'Africa/Lagos': 'Lagos',
  'Africa/Maputo': 'Maputo',
  'Africa/Monrovia': 'Monrovia',
  'Africa/Nairobi': 'Nairobi',
  'Africa/Ndjamena': 'Ndjamena',
  'Africa/Tripoli': 'Tripoli',
  'Africa/Tunis': 'Tunis',
  'Africa/Windhoek': 'Windhoek',
  'America/Anchorage': 'Alaska Time',
  'America/Araguaina': 'Araguaina',
  'America/Argentina/Buenos_Aires': 'Buenos Aires',
  'America/Asuncion': 'Asuncion',
  'America/Bahia': 'Salvador',
  'America/Barbados': 'Barbados',
  'America/Belem': 'Belem',
  'America/Belize': 'Belize',
  'America/Boa_Vista': 'Boa Vista',
  'America/Bogota': 'Bogota',
  'America/Campo_Grande': 'Campo Grande',
  'America/Cancun': 'America Cancun',
  'America/Caracas': 'Caracas',
  'America/Cayenne': 'Cayenne',
  'America/Chicago': 'Central Time',
  'America/Costa_Rica': 'Costa Rica',
  'America/Cuiaba': 'Cuiaba',
  'America/Curacao': 'Curacao',
  'America/Danmarkshavn': 'Danmarkshavn',
  'America/Dawson_Creek': 'Mountain Time - Dawson Creek',
  'America/Denver': 'Mountain Time',
  'America/Edmonton': 'Mountain Time - Edmonton',
  'America/El_Salvador': 'El Salvador',
  'America/Fortaleza': 'Fortaleza',
  'America/Godthab': 'Godthab',
  'America/Grand_Turk': 'Grand Turk',
  'America/Guatemala': 'Guatemala',
  'America/Guayaquil': 'Guayaquil',
  'America/Guyana': 'Guyana',
  'America/Halifax': 'Atlantic Time - Halifax',
  'America/Havana': 'Havana',
  'America/Hermosillo': 'Mountain Time - Hermosillo',
  'America/Iqaluit': 'Eastern Time - Iqaluit',
  'America/Jamaica': 'Jamaica',
  'America/La_Paz': 'La Paz',
  'America/Lima': 'Lima',
  'America/Los_Angeles': 'Pacific Time',
  'America/Maceio': 'Maceio',
  'America/Managua': 'Managua',
  'America/Manaus': 'Manaus',
  'America/Martinique': 'Martinique',
  'America/Mazatlan': 'Mountain Time - Chihuahua, Mazatlan',
  'America/Mexico_City': 'Central Time - Mexico City',
  'America/Miquelon': 'Miquelon',
  'America/Montevideo': 'Montevideo',
  'America/Nassau': 'Nassau',
  'America/New_York': 'Eastern Time',
  'America/Noronha': 'Noronha',
  'America/Panama': 'Panama',
  'America/Paramaribo': 'Paramaribo',
  'America/Phoenix': 'Mountain Time - Arizona',
  'America/Port_of_Spain': 'Port of Spain',
  'America/Port-au-Prince': 'Port-au-Prince',
  'America/Porto_Velho': 'Porto Velho',
  'America/Puerto_Rico': 'Puerto Rico',
  'America/Punta_Arenas': 'Punta Arenas',
  'America/Recife': 'Recife',
  'America/Regina': 'Central Time - Regina',
  'America/Rio_Branco': 'Rio Branco',
  'America/Santiago': 'Santiago',
  'America/Santo_Domingo': 'Santo Domingo',
  'America/Sao_Paulo': 'Sao Paulo',
  'America/Scoresbysund': 'Scoresbysund',
  'America/St_Johns': 'Newfoundland Time - St. Johns',
  'America/Tegucigalpa': 'Central Time - Tegucigalpa',
  'America/Thule': 'Thule',
  'America/Tijuana': 'Pacific Time - Tijuana',
  'America/Toronto': 'Eastern Time - Toronto',
  'America/Vancouver': 'Pacific Time - Vancouver',
  'America/Whitehorse': 'Pacific Time - Whitehorse',
  'America/Winnipeg': 'Central Time - Winnipeg',
  'America/Yellowknife': 'Mountain Time - Yellowknife',
  'Antarctica/Casey': 'Casey',
  'Antarctica/Davis': 'Davis',
  'Antarctica/DumontDUrville': 'Dumont D&apos;Urville',
  'Antarctica/Mawson': 'Mawson',
  'Antarctica/Palmer': 'Palmer',
  'Antarctica/Rothera': 'Rothera',
  'Antarctica/Syowa': 'Syowa',
  'Antarctica/Vostok': 'Vostok',
  'Asia/Almaty': 'Almaty',
  'Asia/Amman': 'Amman',
  'Asia/Aqtau': 'Aqtau',
  'Asia/Aqtobe': 'Aqtobe',
  'Asia/Ashgabat': 'Ashgabat',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Baku': 'Baku',
  'Asia/Bangkok': 'Bangkok',
  'Asia/Beirut': 'Beirut',
  'Asia/Bishkek': 'Bishkek',
  'Asia/Brunei': 'Brunei',
  'Asia/Calcutta': 'India Standard Time',
  'Asia/Kolkata': 'Kolkata',
  'Asia/Choibalsan': 'Choibalsan',
  'Asia/Colombo': 'Colombo',
  'Asia/Damascus': 'Damascus',
  'Asia/Dhaka': 'Dhaka',
  'Asia/Dili': 'Dili',
  'Asia/Dubai': 'Dubai',
  'Asia/Dushanbe': 'Dushanbe',
  'Asia/Gaza': 'Gaza',
  'Asia/Hong_Kong': 'Hong Kong',
  'Asia/Hovd': 'Hovd',
  'Asia/Irkutsk': 'Moscow+05 - Irkutsk',
  'Asia/Jakarta': 'Jakarta',
  'Asia/Jayapura': 'Jayapura',
  'Asia/Jerusalem': 'Jerusalem',
  'Asia/Kabul': 'Kabul',
  'Asia/Kamchatka': 'Moscow+09 - Petropavlovsk-Kamchatskiy',
  'Asia/Karachi': 'Karachi',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Krasnoyarsk': 'Moscow+04 - Krasnoyarsk',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur',
  'Asia/Macau': 'Macau',
  'Asia/Magadan': 'Moscow+08 - Magadan',
  'Asia/Makassar': 'Makassar',
  'Asia/Manila': 'Manila',
  'Asia/Nicosia': 'Nicosia',
  'Asia/Omsk': 'Moscow+03 - Omsk',
  'Asia/Pyongyang': 'Pyongyang',
  'Asia/Qatar': 'Qatar',
  'Asia/Riyadh': 'Riyadh',
  'Asia/Saigon': 'Hanoi',
  'Asia/Seoul': 'Seoul',
  'Asia/Shanghai': 'China Time - Beijing',
  'Asia/Singapore': 'Singapore',
  'Asia/Taipei': 'Taipei',
  'Asia/Tashkent': 'Tashkent',
  'Asia/Tbilisi': 'Tbilisi',
  'Asia/Tehran': 'Tehran',
  'Asia/Thimphu': 'Thimphu',
  'Asia/Tokyo': 'Tokyo',
  'Asia/Ulaanbaatar': 'Ulaanbaatar',
  'Asia/Vladivostok': 'Moscow+07 - Vladivostok',
  'Asia/Yakutsk': 'Moscow+06 - Yakutsk',
  'Asia/Yangon': 'Rangoon',
  'Asia/Yekaterinburg': 'Moscow+02 - Yekaterinburg',
  'Asia/Yerevan': 'Yerevan',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Bermuda': 'Bermuda',
  'Atlantic/Canary': 'Canary Islands',
  'Atlantic/Cape_Verde': 'Cape Verde',
  'Atlantic/Faroe': 'Faeroe',
  'Atlantic/Reykjavik': 'Reykjavik',
  'Atlantic/South_Georgia': 'South Georgia',
  'Atlantic/Stanley': 'Stanley',
  'Australia/Adelaide': 'Central Time - Adelaide',
  'Australia/Brisbane': 'Eastern Time - Brisbane',
  'Australia/Darwin': 'Central Time - Darwin',
  'Australia/Hobart': 'Eastern Time - Hobart',
  'Australia/Perth': 'Western Time - Perth',
  'Australia/Sydney': 'Eastern Time - Melbourne, Sydney',
  'Etc/GMT': 'GMT (no daylight saving)',
  'Europe/Amsterdam': 'Amsterdam',
  'Europe/Andorra': 'Andorra',
  'Europe/Athens': 'Athens',
  'Europe/Belgrade': 'Central European Time - Belgrade',
  'Europe/Berlin': 'Berlin',
  'Europe/Brussels': 'Brussels',
  'Europe/Bucharest': 'Bucharest',
  'Europe/Budapest': 'Budapest',
  'Europe/Chisinau': 'Chisinau',
  'Europe/Copenhagen': 'Copenhagen',
  'Europe/Dublin': 'Dublin',
  'Europe/Gibraltar': 'Gibraltar',
  'Europe/Helsinki': 'Helsinki',
  'Europe/Istanbul': 'Istanbul',
  'Europe/Kaliningrad': 'Moscow-01 - Kaliningrad',
  'Europe/Kyiv': 'Kyiv',
  'Europe/Kiev': 'Kyiv',
  'Europe/Lisbon': 'Lisbon',
  'Europe/London': 'London',
  'Europe/Luxembourg': 'Luxembourg',
  'Europe/Madrid': 'Madrid',
  'Europe/Malta': 'Malta',
  'Europe/Minsk': 'Minsk',
  'Europe/Monaco': 'Monaco',
  'Europe/Moscow': 'Moscow+00 - Moscow',
  'Europe/Oslo': 'Oslo',
  'Europe/Paris': 'Paris',
  'Europe/Prague': 'Central European Time - Prague',
  'Europe/Riga': 'Riga',
  'Europe/Rome': 'Rome',
  'Europe/Samara': 'Moscow+01 - Samara',
  'Europe/Sofia': 'Sofia',
  'Europe/Stockholm': 'Stockholm',
  'Europe/Tallinn': 'Tallinn',
  'Europe/Tirane': 'Tirane',
  'Europe/Vienna': 'Vienna',
  'Europe/Vilnius': 'Vilnius',
  'Europe/Warsaw': 'Warsaw',
  'Europe/Zurich': 'Zurich',
  'Indian/Chagos': 'Chagos',
  'Indian/Christmas': 'Christmas',
  'Indian/Cocos': 'Cocos',
  'Indian/Kerguelen': 'Kerguelen',
  'Indian/Mahe': 'Mahe',
  'Indian/Maldives': 'Maldives',
  'Indian/Mauritius': 'Mauritius',
  'Indian/Reunion': 'Reunion',
  'Pacific/Apia': 'Apia',
  'Pacific/Auckland': 'Auckland',
  'Pacific/Chuuk': 'Truk',
  'Pacific/Easter': 'Easter Island',
  'Pacific/Efate': 'Efate',
  'Pacific/Enderbury': 'Enderbury',
  'Pacific/Fakaofo': 'Fakaofo',
  'Pacific/Fiji': 'Fiji',
  'Pacific/Funafuti': 'Funafuti',
  'Pacific/Galapagos': 'Galapagos',
  'Pacific/Gambier': 'Gambier',
  'Pacific/Guadalcanal': 'Guadalcanal',
  'Pacific/Guam': 'Guam',
  'Pacific/Honolulu': 'Hawaii Time',
  'Pacific/Kiritimati': 'Kiritimati',
  'Pacific/Kosrae': 'Kosrae',
  'Pacific/Kwajalein': 'Kwajalein',
  'Pacific/Majuro': 'Majuro',
  'Pacific/Marquesas': 'Marquesas',
  'Pacific/Nauru': 'Nauru',
  'Pacific/Niue': 'Niue',
  'Pacific/Norfolk': 'Norfolk',
  'Pacific/Noumea': 'Noumea',
  'Pacific/Pago_Pago': 'Pago Pago',
  'Pacific/Palau': 'Palau',
  'Pacific/Pitcairn': 'Pitcairn',
  'Pacific/Pohnpei': 'Ponape',
  'Pacific/Port_Moresby': 'Port Moresby',
  'Pacific/Rarotonga': 'Rarotonga',
  'Pacific/Tahiti': 'Tahiti',
  'Pacific/Tarawa': 'Tarawa',
  'Pacific/Tongatapu': 'Tongatapu',
  'Pacific/Wake': 'Wake',
  'Pacific/Wallis': 'Wallis',
  UTC: 'UTC'
});

export const TIMEZONES_MAP = new Map(Object.entries(TIMEZONES));

export const DEFAULT_TIMEZONE = TIMEZONES_MAP.get('Europe/London');

/**
 * Get an array sorted by UTC offset of time-zone info objects for letting a user choose a time
 * zone. The objects contain keys `displayName`, `isDST`, `offsetMinutes`, and `timeZone`.
 */
export function getTimeZoneChoices() {
  const tzEntries = Object.entries(TIMEZONES);
  const array = [];

  let name;
  let timeZone;

  for ([timeZone, name] of tzEntries) {
    const momentClone = moment();
    momentClone.tz(timeZone);

    const offsetMinutes = momentClone.utcOffset();

    let displayName = name;
    let dst;

    if (timeZone !== 'UTC') {
      const offsetIsPositive = offsetMinutes >= 0;
      const operator = offsetMinutes >= 0 ? '+' : '';

      const absHour = Math.abs(offsetMinutes / 60);
      const hour = Math.floor(absHour) * (offsetIsPositive ? 1 : -1);
      let hourStr = String(hour);
      hourStr = hourStr.padStart(2, '0');

      const minute = Math.abs(offsetMinutes % 60);
      let minuteStr = String(minute);
      minuteStr = minuteStr.padStart(2, '0');

      dst = momentClone.isDST();

      displayName = `(GMT${operator}${hourStr}:${minuteStr}${dst ? ' DST' : ''}) ${name}`;
    }

    array.push({
      displayName,
      dst,
      offsetMinutes,
      timeZone
    });
  }

  array.sort((info1, info2) => {
    /* Sort primarily by UTC offset. */

    if (info1.offsetMinutes < info2.offsetMinutes) {
      return -1;
    }

    if (info1.offsetMinutes > info2.offsetMinutes) {
      return 1;
    }

    /*
     * If the UTC offsets are the same, but one is a daylight-saving time, but the daylight-saving
     * time closer to its standard-time offset.
     */

    if (info1.dst && !info2.dst) {
      return -1;
    }

    if (!info1.dst && info2.dst) {
      return 1;
    }

    /* If the UTC offsets and daylight-saving statuses are the same, sort by name. */

    if (info1.displayName < info2.displayName) {
      return -1;
    }

    if (info1.displayName > info2.displayName) {
      return 1;
    }

    return 0;
  });

  return array;
}

export const getUTCTime = (date, pattern) => format(utcToZonedTime(date, 'UTC'), pattern, { timezone: 'UTC' });

export const convertDaysToMinutes = (days) => days * 24 * 60;
