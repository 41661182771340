import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../design-system/modal/Modal';
import Button from '../../design-system/button/Button';

import styles from './join-teams.module.scss';

import { ReactComponent as HiLady } from '../../../assets/people/hi-lady.svg';

const JoinTeams = ({ onClose, teamsEmail }) => {
  return (
    <Modal
      className={styles.container}
      withCloseButton={false}
      onModalClose={() => {
        this.setState({ renderTeamsModal: false });
      }}
      content={
        <div className={styles.container}>
          <div className={styles['person-container']}>
            <HiLady />
          </div>
          <h1>Join Microsoft Teams</h1>
          <p>
            Send a meeting link to your learner through Teams at the scheduled start time of your session. You can find your learner by searching for{' '}
            <b>{teamsEmail}</b>.
          </p>
          <br />
          <p>Contact them directly through Teams platform and start your class from there.</p>
          <div className={styles.buttonWrapper}>
            <Button onClick={onClose} className={styles.closeButton}>
              Ok
            </Button>
          </div>
        </div>
      }
    />
  );
};

JoinTeams.propTypes = {
  onClose: PropTypes.func.isRequired,
  teamsEmail: PropTypes.string.isRequired
};

export default JoinTeams;
