import { createActionTypes } from '../utils/action-helpers';

export const SelectedProductTypeTypes = createActionTypes('SelectedProductType', ['SET_SELECTED_PRODUCT_TYPE']);

export function setProductType({
  deliveryType,
  trainingType,
  orderType,
  productTypeId,
  duration,
  remainingCredits,
  prices,
  isGroupSession = false,
  invitations = [],
  organization = null,
  bookingFrequency = null
}) {
  return {
    type: SelectedProductTypeTypes.SET_SELECTED_PRODUCT_TYPE,
    payload: {
      deliveryType,
      trainingType,
      orderType,
      productTypeId,
      duration,
      isGroupSession,
      remainingCredits,
      prices,
      invitations,
      organization,
      bookingFrequency
    }
  };
}
