import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-phone-input-2/dist/style.css';

// import { validate, email } from './validate.js';
import Mixpanel from '../../utils/mixpanel-helper.js';
import Button from '../design-system/button/Button';

import { resetUpdateAccount, updateAccount } from '../../actions/user';

import styles from '../../style/components/account/AccountForm.module.scss';
import formStyles from '../../style/utils/_form.module.scss';
import SuccessToast from '../design-system/toast/successToast/SuccessToast.jsx';
import ErrorToast from '../design-system/toast/errorToast/ErrorToast.jsx';
import { getAvailableTimezones } from '../../actions/time.js';
import Input from '../design-system/input/Input';
import DropdownAsync from '../design-system/dropdown-async/DropdownAsync.jsx';
import FormFieldLanguageSwitcher from '../weglot-language-switcher/FormFieldLanguageSwitcher.jsx';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const AccountForm = ({
  availableTimezones,
  getAvailableTimezonesAction,
  hasError,
  isLoading,
  isLoadingAvailableTimezones,
  isSuccess,
  logUserOut,
  pristine,
  resetUpdateAccountAction,
  timezone,
  updateAccountAction,
  user
}) => {
  const [formValues, setFormValues] = useState({
    firstName: user?.first_name,
    lastName: user?.last_name,
    email: user?.email
  });

  const updateAccount = () => {
    Mixpanel.track('Click', { button: 'update_account' });
    updateAccountAction({ ...formValues, token: user?.token });
  };

  const getTimezones = () => {
    getAvailableTimezonesAction(user?.token);
  };

  const handleValueChange = (field, value) => {
    setFormValues((prevState) => ({ ...prevState, [field]: value }));
  };

  const enabled = useFeatureIsOn('test-feature');
  console.log('enabled', enabled);

  return (
    <form className={formStyles.form} onSubmit={updateAccount}>
      <div className={styles['account-details__header']}>
        {enabled && <h2>Account details:</h2>}
        {!enabled && <h2>Account details</h2>}
        <Button outfit="transparentButton" onClick={logUserOut}>
          Log Out
        </Button>
      </div>
      <div className={formStyles.row}>
        <Input label="First Name" initialValue={formValues?.firstName} onChange={(value) => handleValueChange('firstName', value)} />
        <Input label="Last Name" initialValue={formValues?.lastName} onChange={(value) => handleValueChange('lastName', value)} />
      </div>
      <div className={formStyles.row}>
        <Input disabled label="Email" initialValue={formValues?.email} onChange={(value) => handleValueChange('email', value)} />
        <FormFieldLanguageSwitcher user={user} shouldUpdateUser={false} onChange={(value) => handleValueChange('preferredLanguage', value)} />
        <DropdownAsync
          label="Your timezone"
          onChange={(value) => handleValueChange('timezone', value)}
          defaultValue={timezone}
          options={availableTimezones}
          getOptions={getTimezones}
          isLoading={isLoadingAvailableTimezones}
        />
      </div>
      <SuccessToast msg="Your account details were changed." onClose={resetUpdateAccountAction} show={isSuccess} />
      <ErrorToast errorMsg="Something went wrong while updating your account details." onClose={resetUpdateAccountAction} show={hasError} />
      <Button type="button" onClick={updateAccount} loading={isLoading} disabled={isLoading || pristine}>
        Save detail changes
      </Button>
    </form>
  );
};

AccountForm.propTypes = {
  availableTimezones: PropTypes.array.isRequired,
  getAvailableTimezonesAction: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingAvailableTimezones: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  logUserOut: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  updateAccountAction: PropTypes.func.isRequired,
  user: PropTypes.object,
  resetUpdateAccountAction: PropTypes.func,
  timezone: PropTypes.string.isRequired
};

AccountForm.defaultProps = {
  initialValues: {},
  isLoading: false,
  isSuccess: false,
  hasError: false
};

const mapStateToProps = (state) => ({
  availableTimezones: state.time.availableTimezones,
  isLoading: state.user.isLoadingUpdate,
  isLoadingAvailableTimezones: state.time.isLoadingAvailableTimezones,
  hasError: state.user.hasErrorUpdateAccount,
  isSuccess: state.user.isAccountUpdateSuccess,
  timezone: state.time.timezone,
  user: state.user
});

export default connect(mapStateToProps, {
  getAvailableTimezonesAction: getAvailableTimezones,
  updateAccountAction: updateAccount,
  resetUpdateAccountAction: resetUpdateAccount
})(AccountForm);
