import { OrdersActionTypes } from '../actions/order-info';
import { USER_LOGOUT } from '../actions/types';
import { CLASS_TYPES } from '../components/new-booking/Enum';

export const selectBookingFrequencyBasedOnOrder = (ordersInfo) => {
  if (ordersInfo && ordersInfo.all_orders) {
    return ordersInfo.all_orders[0].booking_frequency;
  }
};

export const selectProductType = (ordersInfo, type = CLASS_TYPES.tuition) => {
  if (!ordersInfo?.all_orders?.length) return {};
  const order = ordersInfo?.all_orders[0];
  const [appointmentInfo] = order.order_appointment_types.filter((appt) => appt?.training_type?.toLowerCase() === type?.toLowerCase());
  if (!appointmentInfo) return {};
  return {
    deliveryType: appointmentInfo.delivery_type,
    duration: appointmentInfo.duration,
    trainingType: appointmentInfo.training_type,
    orderType: order.order_type,
    productTypeId: appointmentInfo.product_type,
    remainingCredits: order.remaining,
    prices: [{ quantity: '1.00', price: appointmentInfo.cost, currency: 'gbp' }],
    isGroupSession: appointmentInfo.is_for_group_sessions,
    invitations: [],
    organization: order.organization
  };
};

const ordersInitialState = {
  all_orders: [],
  active_orders: [],
  expired_organizations: [],
  isGettingOrders: false
};

export default function orderInfoReducer(state = ordersInitialState, action) {
  switch (action.type) {
    case OrdersActionTypes.GET_ALL_ORDERS:
    case OrdersActionTypes.GET_ORDER:
      return { ...state, isGettingOrders: true };
    case OrdersActionTypes.GET_ALL_ORDERS_SUCCESS:
    case OrdersActionTypes.GET_ORDER_SUCCESS:
      return { ...state, isGettingOrders: false, ...action.payload };
    case OrdersActionTypes.GET_ORDER_FAIL:
      return { ...state, isGettingOrders: false };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
}
