import {
  SET_ONBOARDING_CHOICES,
  SET_TUTOR_ONBOARDING_CHOICES,
  USER_LOGOUT,
  INITIALIZE_ONBOARDING_DATA,
  STORE_ONBOARDING_SELECTION,
  RESET_ONBOARDING
} from '../actions/types';

const defaultState = {
  personalInterests: [],
  languages: [],
  professionalInterests: [],
  motivations: [],
  bio: '',
  countries: [],
  selectedTutorLanguages: [],
  selectedTutorDialects: [],
  selectedMotivations: [],
  selectedMotivationsIds: [],
  selectedLevel: 0,
  selectedDialect: undefined,
  selectedLessonType: undefined,
  selectedCountry: undefined,
  textMotivation: '',
  textOtherProfessional: '',
  otherProfessional: false,
  otherPersonal: false,
  textOtherPersonal: '',
  productTypesWithCredits: [],
  personalLimitReached: false,
  professionalLimitReached: false,
  isLoading: false,
  remainingCredits: 0,
  allDays: false
};

export default function OnboardingReducer(state = defaultState, action) {
  let newState;
  switch (action.type) {
    case SET_ONBOARDING_CHOICES: {
      const {
        language,
        dialect,
        level,
        calendarMatrix,
        motivations,
        textMotivation,
        sector,
        selectedProfessionalInterests,
        textOtherProfessional,
        selectedPersonalInterests,
        textOtherPersonal
      } = action.payload;

      newState = {
        ...state,
        language,
        dialect,
        level,
        calendarMatrix,
        motivations,
        textMotivation,
        sector,
        selectedProfessionalInterests,
        textOtherProfessional,
        selectedPersonalInterests,
        textOtherPersonal
      };

      return newState;
    }
    case SET_TUTOR_ONBOARDING_CHOICES:
      newState = {
        ...state,
        matchedTutor: action.payload
      };
      return newState;

    case USER_LOGOUT:
      return defaultState;

    case INITIALIZE_ONBOARDING_DATA:
      newState = {
        ...state,
        ...action.payload
      };
      return newState;

    case STORE_ONBOARDING_SELECTION:
      newState = {
        ...state,
        ...action.payload
      };
      return newState;

    case RESET_ONBOARDING:
      newState = {
        ...state,
        currentStep: state.initialStep
      };
      return newState;

    default:
      return state;
  }
}
