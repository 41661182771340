import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell } from 'recharts';

import styles from './ProgressRing.module.scss';

const ProgressRing = ({ completion, isBlocked, isSelected }) => {
  const data = [
    { name: 'missing', value: 100 - completion },
    { name: 'completion', value: completion }
  ];

  const COLORS = {
    completion: '#40DBA6',
    missing: '#DAFBF0'
  };

  return (
    <div className={isSelected ? styles['bigger-wrapper'] : styles['regular-wrapper']} data-testid="progress-ring">
      {isBlocked ? (
        <div className={styles['blocked-lesson']} data-testid="progress-ring-blocked" />
      ) : (
        <PieChart width={isSelected ? 36 : 30} height={isSelected ? 36 : 30}>
          <Pie data={data} stroke="none" cx="50%" cy="50%" labelLine={false} outerRadius={isSelected ? 18 : 15} dataKey="value">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[entry?.name]} />
            ))}
          </Pie>
        </PieChart>
      )}
    </div>
  );
};

ProgressRing.propTypes = {
  completion: PropTypes.number.isRequired,
  isBlocked: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired
};

export default ProgressRing;
