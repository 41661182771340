import { UPDATE_TUTOR_INFO, USER_LOGOUT } from '../actions/types';

export default function TutorInfoReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_TUTOR_INFO:
      return { ...state, profilePreview: action.payload.data };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
}
