import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import UnitsList from '../UnitsList';
import styles from './CourseDetails.module.scss';
import LoadingSpinner from '../../design-system/loading/spinner/LoadingSpinner';
import { setSelectedUnit } from '../../../actions/lessons';
import CurrentUnit from './components/CurrentUnit';
import CurrentAssessment from './components/CurrentAssessment';
import { UNIT_TYPE } from '../../../enums/unit';
import HowWasYourPractice from './components/HowWasYourPractice';

const CourseDetails = ({
  componentRef,
  isGettingCourseDetails,
  isGettingSelectedUnitProgress,
  isSettingSelectedUnit,
  selectedCourse,
  selectedUnit,
  setSelectedUnitAction,
  user
}) => {
  const [selectedUnitIndex, setSelectedUnitIndex] = useState(0);
  const history = useHistory();

  const handleUnitSelection = (index, unit) => {
    if (typeof index !== 'number' || !unit) return;
    setSelectedUnitIndex(index);
    setSelectedUnitAction({ token: user.token, unit });

    if (!componentRef.current) return;
    componentRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    setSelectedUnitIndex(getSelectedUnitIndex());
  }, [selectedUnit]);

  const getSelectedUnitIndex = () => {
    if (!selectedCourse || selectedCourse.length) return 0;
    if (!Object.keys(selectedUnit).length) return 0;
    return (
      selectedCourse?.units?.findIndex((unit) => {
        if (unit.type.toLowerCase() === UNIT_TYPE.lesson) return unit?.name === selectedUnit?.name;
        if (unit.type.toLowerCase() === UNIT_TYPE.assessment) return unit?.title === selectedUnit?.title;
      }) || 0
    );
  };

  if (isGettingCourseDetails || !selectedCourse?.units?.length)
    return (
      <div className={styles['course-details-wrapper']} data-testid="course-details-wrapper">
        <div className={styles.loadingWrapper}>
          <div className={styles.loader}>
            <LoadingSpinner />
          </div>
        </div>
      </div>
    );

  return (
    <div ref={componentRef} className={styles['course-details-wrapper']} data-testid="course-details-wrapper">
      <div className={styles.alertsWrapper}>
        <HowWasYourPractice />
      </div>
      <div ref={componentRef} className={styles['course-details']}>
        {selectedUnit.type === 'Assessment' ? (
          <CurrentAssessment
            selectedUnit={selectedUnit}
            status={selectedUnit.status}
            history={history}
            disabled={
              (selectedCourse?.units[selectedUnitIndex - 1]?.type === 'Lesson' &&
                selectedCourse?.units[selectedUnitIndex - 1]?.booking_state !== 'happened') ||
              (selectedCourse?.units[selectedUnitIndex - 1]?.type === 'Assessment' &&
                selectedCourse?.units[selectedUnitIndex - 2]?.booking_state !== 'happened')
            }
          />
        ) : (
          <CurrentUnit
            selectedUnit={selectedUnit}
            status={selectedUnit.progress < 1 ? 'in-progress' : 'completed'}
            history={history}
            isLoadingUnit={isSettingSelectedUnit}
            isLoadingProgress={isGettingSelectedUnitProgress}
          />
        )}
        <UnitsList onSelectUnit={handleUnitSelection} selected={selectedUnitIndex} />
      </div>
    </div>
  );
};

CourseDetails.propTypes = {
  componentRef: PropTypes.object,
  isGettingCourseDetails: PropTypes.bool.isRequired,
  isGettingSelectedUnitProgress: PropTypes.bool.isRequired,
  isSettingSelectedUnit: PropTypes.bool.isRequired,
  selectedCourse: PropTypes.object.isRequired,
  selectedUnit: PropTypes.object.isRequired,
  setSelectedUnitAction: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isGettingCourseDetails: state.courses.selectedCourseDetails.isGettingCourseDetails,
  isSettingSelectedUnit: state.selectedUnit.isSettingSelectedUnit,
  isGettingSelectedUnitProgress: state.selectedUnit.isGettingSelectedUnitProgress,
  selectedCourse: state.courses?.selectedCourseDetails?.courseDetails,
  selectedUnit: state.selectedUnit.selectedUnit,
  user: state.user
});

export default connect(mapStateToProps, {
  setSelectedUnitAction: setSelectedUnit
})(CourseDetails);
