import React, { Component } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { Glyphicon } from 'react-bootstrap';

import appStyles from '../../style/containers/App.module.scss';
import styles from '../../style/components/uikit/Modal.module.scss';

export default class Modal extends Component {
  state = { showModal: true };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  showModal = () => {
    this.setState({ showModal: true });
  };

  close = () => {
    if (this.mounted) {
      this.setState({ showModal: false });
    }

    if (this.props.onModalClose) {
      this.props.onModalClose();
    }
  };

  render() {
    const childrenWithProps = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {
        close: this.close
      })
    );
    return (
      <ReactModal className={this.props.className} isOpen={this.state.showModal} style={{ overlay: { zIndex: 900 } }} id="modal">
        <div className={`${styles.container} ${this.props.loading ? appStyles.loadingModal : ''}`}>
          {this.props.withCloseButton && (
            <span className={styles.closeButton} onClick={this.close} onKeyDown={this.close} role="button" tabIndex={0}>
              <Glyphicon glyph="remove" />
            </span>
          )}

          {childrenWithProps}
        </div>
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  onModalClose: PropTypes.func,
  withCloseButton: PropTypes.bool
};

Modal.defaultProps = {
  className: '',
  loading: false,
  onModalClose: null,
  withCloseButton: true
};
