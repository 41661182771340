import axios from 'axios';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/browser';

import Mixpanel from '../utils/mixpanel-helper.js';
import { ENDPOINT_DATE_FORMAT } from '../utils/time-helpers.js';

import { isTokenExpired } from './common';
import { GET_AVAILABILTIES, GET_ALL_AVAILABILTIES, DELETE_AVAILABILITY, NEW_AVAILABILITY, UPDATE_AVAILABILITY } from './types';

function newAvailabilitySuccess(response) {
  return {
    type: NEW_AVAILABILITY,
    payload: response
  };
}

function updateAvailabilitySuccess(response) {
  return {
    type: UPDATE_AVAILABILITY,
    payload: response
  };
}

function deleteAvailabilitySuccess(availabilityId) {
  return {
    type: DELETE_AVAILABILITY,
    payload: availabilityId
  };
}

function getUserAvailabilitiesSuccess(response) {
  return {
    type: GET_AVAILABILTIES,
    payload: response
  };
}

function getAllUserAvailabilitiesSuccess(response) {
  return {
    type: GET_ALL_AVAILABILTIES,
    payload: response
  };
}

export function createNewAvailability(token, date, from, to, recurWeekly, errorCallback, successCallback) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        '/api/availabilities/',
        {
          start_date: moment(date).format(ENDPOINT_DATE_FORMAT),
          start_time: from,
          end_time: to,
          recur_weekly: recurWeekly
        },
        { headers: { Authorization: `Token ${token}` } }
      );
      dispatch(newAvailabilitySuccess(response));
      if (successCallback) {
        successCallback();
      }
      Mixpanel.track('Availability successfully created');
    } catch (err) {
      Mixpanel.track('Error', { error: 'create_avaibility_error' });
      if (!isTokenExpired(dispatch, err)) {
        if (err && err.response && err.response.data) {
          errorCallback(err.response.data);
        }
      }
    }
  };
}

export function updateAvailability(token, date, from, to, availabilityId, recurWeekly, errorCallback, successCallback) {
  return async (dispatch) => {
    try {
      const response = await axios.put(
        `/api/availabilities/${availabilityId}/`,
        {
          start_date: moment(date).format(ENDPOINT_DATE_FORMAT),
          start_time: from,
          end_time: to,
          recur_weekly: recurWeekly
        },
        { headers: { Authorization: `Token ${token}` } }
      );
      Mixpanel.track('Availability successfully updated');
      dispatch(updateAvailabilitySuccess(response));
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      Sentry.captureException(err);
      Mixpanel.track('Error', { error: 'update_avaibility_error' });
      if (!isTokenExpired(dispatch, err)) {
        if (errorCallback && err !== undefined && err.response) {
          errorCallback(err.response.data);
        }
      }
    }
  };
}

export function deleteAvailability(token, availabilityId, errorCallback, successCallback) {
  return async (dispatch) => {
    try {
      await axios.delete(`/api/availabilities/${availabilityId}/`, {
        headers: { Authorization: `Token ${token}` }
      });
      Mixpanel.track('Availability successfully deleted');
      dispatch(deleteAvailabilitySuccess(availabilityId));
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      Mixpanel.track('Error', { error: 'delete_avaibility_error' });
      if (!isTokenExpired(dispatch, err)) {
        if (err !== undefined) {
          errorCallback(err.response.data);
        }
      }
    }
  };
}

export function getUserAvailabilities(token, userId, from, to, errorCallback) {
  const fromStr = moment(from).format(ENDPOINT_DATE_FORMAT);
  const toStr = moment(to).format(ENDPOINT_DATE_FORMAT);

  return async (dispatch) => {
    try {
      const response = await axios.get(`/api/tutors/${userId}/availabilities/?from=${fromStr}&to=${toStr}`, {
        headers: { Authorization: `Token ${token}` }
      });
      dispatch(getUserAvailabilitiesSuccess(response));
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        if (errorCallback) errorCallback("Couldn't retrieve your availabilities list");
      }
    }
  };
}

export function getAllUserAvailabilities(token, userId, from, to, errorCallback) {
  const fromStr = moment(from).format(ENDPOINT_DATE_FORMAT);
  const toStr = moment(to).format(ENDPOINT_DATE_FORMAT);

  return async (dispatch) => {
    try {
      const response = await axios.get(`/api/tutors/${userId}/availabilities/?from=${fromStr}&to=${toStr}`, {
        headers: { Authorization: `Token ${token}` }
      });
      dispatch(getAllUserAvailabilitiesSuccess(response));
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        errorCallback();
      }
    }
  };
}
