import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { clearLogInError, getSSOProvider, setSSORedirectUrl } from '../../../actions/new-login.js';

import formStyles from '../../../style/utils/_form.module.scss';
import styles from './SsoLogin.module.scss';
import ErrorMessage from '../../design-system/helper-messages/error-message/ErrorMessage.jsx';
import RegularFormField from '../../design-system/regular-field/RegularFormField';
import { amplifyConfig } from '../../../setup/aws/amplify';
import useSso from '../../../hooks/useSso';
import Button from '../../design-system/button/Button';
import { ReactComponent as IconLeftArrow } from '../../../assets/icons/icon_left-arrow.svg';

const SsoLoginForm = ({
  clearLogInErrorAction,
  getSSOProviderAction,
  hasLoginError,
  isLoadingPostLogIn,
  loginErrorMsg,
  setIsSSO,
  setSSORedirectUrlAction
}) => {
  const [ssoEmail, setSsoEmail] = useState('');
  const history = useHistory();
  const { signIn } = useSso(amplifyConfig);

  useEffect(() => {
    return () => clearLogInErrorAction();
  }, []);

  const resetError = () => {
    if (hasLoginError) {
      clearLogInErrorAction();
    }
  };

  const handleSsoLogin = () => {
    setSSORedirectUrlAction('/');
    getSSOProviderAction({ email: ssoEmail, successCallback: signIn });
  };

  const handleGoBack = () => {
    setIsSSO(false);
    history.push('/');
  };

  return (
    <form className={formStyles.form} data-testid="sso-login-form">
      <ErrorMessage errorMsg={loginErrorMsg} onClose={resetError} show={hasLoginError} />
      <RegularFormField
        onChange={(evt) => setSsoEmail(evt.target.value)}
        id="corporate-email"
        label="Your corporate email"
        type="email"
        invalidMessage="Invalid email"
        value={ssoEmail}
      />
      <div className={styles.submitContainer}>
        <Button type="button" outfit="greenButton" loading={isLoadingPostLogIn} onClick={handleSsoLogin}>
          Confirm email
        </Button>
      </div>
      <div className={styles.goBack} role="button" onClick={handleGoBack}>
        <IconLeftArrow />
        <p>Go back to regular login</p>
      </div>
    </form>
  );
};

SsoLoginForm.propTypes = {
  accessToken: PropTypes.string,
  clearLogInErrorAction: PropTypes.func.isRequired,
  getSSOProviderAction: PropTypes.func.isRequired,
  hasLoginError: PropTypes.bool.isRequired,
  isLoadingPostLogIn: PropTypes.bool.isRequired,
  loginErrorMsg: PropTypes.string.isRequired,
  postSsoLogInAction: PropTypes.func.isRequired,
  setIsSSO: PropTypes.func.isRequired,
  setSSORedirectUrlAction: PropTypes.func.isRequired
};

SsoLoginForm.defaultProps = {
  isLoadingPostLogIn: false,
  hasLoginError: false,
  loginErrorMsg: ''
};

const mapStateToProps = (state) => ({
  isLoadingPostLogIn: state.newLogin.ssoLogIn.isLoadingPostLogIn,
  hasLoginError: state.newLogin.ssoLogIn.hasPostLogInError,
  loginErrorMsg: state.newLogin.ssoLogIn.postLogInErrorMessage
});

export default connect(mapStateToProps, {
  clearLogInErrorAction: clearLogInError,
  getSSOProviderAction: getSSOProvider,
  setSSORedirectUrlAction: setSSORedirectUrl
})(SsoLoginForm);
