import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Radio, FormGroup } from 'react-bootstrap';

import styles from '../../style/components/store/LastPurchase.module.scss';

const currencyDic = {
  gbp: '£'
};

class LastPurchase extends Component {
  state = {
    selectedRadio: 0,
    tagColor: ''
  };

  componentDidMount() {
    this.setTagColor();
  }

  onClick = () => {
    const { deliveryType, trainingType, buy, cost } = this.props;
    let { prices } = this.props;
    if (prices.length <= 0) {
      prices = [{ quantity: '1.00', price: cost, currency: 'gbp' }];
    }
    const i = this.state.selectedRadio;
    const amount = prices[i].quantity;
    const price = prices[i].price;
    const currency = prices[i].currency;
    buy(amount, price, currency, trainingType, deliveryType);
  };

  onSelectRadio = (key) => {
    this.setState({ selectedRadio: key });
  };

  setTagColor = () => {
    const { deliveryType, trainingType } = this.props;
    if (deliveryType === 'online') {
      if (trainingType === 'conversation') {
        this.setState({ tagColor: '#ef9349' });
      } else {
        this.setState({ tagColor: '#E6D255' });
      }
    } else if (trainingType === 'conversation') {
      this.setState({ tagColor: '#e7ce9b' });
    } else {
      this.setState({ tagColor: '#397988' });
    }
  };

  render() {
    const {
      deliveryType,
      trainingType,
      image,
      cost
      // language,
    } = this.props;
    let { prices } = this.props;
    const { tagColor } = this.state;
    if (prices.length <= 0) {
      prices = [{ quantity: '1.00', price: cost, currency: 'gbp' }];
    }
    const unitaryPrice = prices.find((p) => p.quantity === '1.00');
    prices.sort((a, b) => a.price - b.price);

    return (
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <p style={{ borderColor: tagColor }} className={styles?.name}>
            {deliveryType} {trainingType}
          </p>
        </div>

        <div className={styles.imageContainer}>
          <img className={styles.image} src={image} alt="text" />
        </div>
        <div className={styles.prices}>
          <FormGroup className={styles.priceFormGroup}>
            {prices.map((p, i) => (
              <div key={i} className={styles.priceContainer}>
                <div className={styles.priceRow}>
                  <Radio className={styles.radioButton} onClick={this.onSelectRadio.bind(this, i)} checked={this.state.selectedRadio === i} />
                  <p className={styles.amountCol}>
                    {`${parseFloat(p.quantity, 10)} session${parseInt(p.quantity, 10) > 1 ? 's' : ''} `}
                    {`- ${parseFloat(p.quantity, 10) >= 1 ? '60' : '30'} minutes`}
                  </p>
                  <p className={styles.priceCol}>{`${currencyDic[p.currency]}${p.price}`}</p>
                </div>
                <p className={styles.discountText}>
                  {i > 0 &&
                    parseInt(p.quantity, 10) * unitaryPrice.price - p.price > 0 &&
                    `(save ${currencyDic[p.currency]}${parseInt(p.quantity, 10) * unitaryPrice.price - p.price})`}
                </p>
              </div>
            ))}
          </FormGroup>
          <div className={styles.btnContainer}>
            <button
              className={[styles.customBtn, styles.createBtn, 'btn btn-primary'].join(' ')}
              onClick={() => {
                this.onClick();
              }}>
              Buy
            </button>
          </div>
        </div>
      </div>
    );
  }
}

LastPurchase.propTypes = {
  buy: PropTypes.func.isRequired,
  deliveryType: PropTypes.string.isRequired,
  trainingType: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  prices: PropTypes.array,
  cost: PropTypes.string.isRequired
  // language: PropTypes.string.isRequired,
};

LastPurchase.defaultProps = {
  prices: []
};

export default connect(null)(LastPurchase);
