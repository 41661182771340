import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compareAsc } from 'date-fns';

import TuitionLessonAppointmentCard from '../TuitionLessonAppointmentCard';

import styles from './PastAppointmentCards.module.scss';
import HowWasYourPractice from './components/HowWasYourPractice';
import RatingModal from '../../../../study-area/RatingModal';
import { CLASS_TYPES } from '../../../Enum';
import ConvoLessonAppointmentCard from '../ConvoLessonAppointmentCard';
import { getSearchParam } from '../../../../../utils/url-helpers';
import { convertBooleanString } from '../../../../../utils/string-helpers';

const PastAppointmentCards = ({ conversationBookings, isLoading, tuitionBookings }) => {
  const isFeedbackModalOpen = convertBooleanString(getSearchParam('feedback-modal'));
  const appointmentToRateId = getSearchParam('appointment-to-rate');
  const [orderedAppointmentsByDate, setOrderedAppointmentsByDate] = useState([]);
  const [showRagingModal, setShowRagingModal] = useState(isFeedbackModalOpen);
  const [isBlockedHowWasYourClass, setIsBlockedHowWasYourClass] = useState(false);

  const handleShowRatingModal = () => {
    setShowRagingModal(true);
  };

  const orderCardsByDate = (cardsList) => {
    if (!cardsList.length) {
      setOrderedAppointmentsByDate([]);
      return;
    }
    const orderedList = cardsList.sort(({ appointment_object: firstEl }, { appointment_object: secondEl }) => {
      const firstDateOcurr = `${firstEl.date}T${firstEl.start_time}:00.000Z`;
      const secondDateOcurr = `${secondEl.date}T${secondEl.start_time}:00.000Z`;
      return compareAsc(new Date(firstDateOcurr), new Date(secondDateOcurr));
    });
    setOrderedAppointmentsByDate(orderedList);
  };

  useEffect(() => {
    orderCardsByDate([...conversationBookings, ...tuitionBookings]);
  }, [conversationBookings, tuitionBookings]);

  if (isLoading)
    return (
      <div className={styles['cards-container']}>
        {[...new Array(2)].map((arr, index) => (
          <TuitionLessonAppointmentCard isLoading={isLoading} key={index} />
        ))}
      </div>
    );

  if (!orderedAppointmentsByDate.length)
    return (
      <div className={styles['empty-state']}>
        <h3>No past sessions yet</h3>
      </div>
    );

  return (
    <div className={styles['cards-container']}>
      <div className={styles['banner--wrapper']}>
        <HowWasYourPractice handleShowRatingModal={handleShowRatingModal} isBlocked={isBlockedHowWasYourClass} />
      </div>
      {orderedAppointmentsByDate.map((lesson) => {
        if (lesson?.type === CLASS_TYPES.conversation)
          return <ConvoLessonAppointmentCard isPast lesson={lesson} isLoading={isLoading} key={lesson?.appointment_object?.id} />;

        return <TuitionLessonAppointmentCard isPast lesson={lesson} isLoading={isLoading} key={lesson?.appointment_object?.id} />;
      })}
      {showRagingModal && (
        <RatingModal
          appointmentToRateId={appointmentToRateId}
          onCloseModal={() => setShowRagingModal(false)}
          setIsBlockedHowWasYourClass={setIsBlockedHowWasYourClass}
        />
      )}
    </div>
  );
};

PastAppointmentCards.propTypes = {
  conversationBookings: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  tuitionBookings: PropTypes.array.isRequired
};

PastAppointmentCards.defaultProps = {
  isLoading: false
};

export default PastAppointmentCards;
