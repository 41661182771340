import { combineReducers } from 'redux';
import { NewBookingActionTypes } from '../actions/new-booking';
import { CLEAN_PREVIOUS_STATE } from '../actions/types';
import { SessionsActionTypes } from '../actions/sessions';
import { buildMonthAndYearStr } from './bookable';

const buildPrevValueMapAndKey = (timestamp, prevState) => {
  const prev = prevState || {};
  const map = new Map(Object.entries(prev));
  if (!timestamp) return { map };
  const key = buildMonthAndYearStr(new Date(timestamp));
  return { map, key };
};

export const addToMap = (timestamp, prevState) => {
  const { map, key } = buildPrevValueMapAndKey(timestamp, prevState);
  if (!timestamp) return Object.fromEntries(map);
  const prevValue = map.get(key) || [];
  map.set(key, [...prevValue, timestamp]);
  return Object.fromEntries(map);
};

export const removeFromMap = (timestamp, prevState) => {
  const { map, key } = buildPrevValueMapAndKey(timestamp, prevState);
  if (!timestamp) return Object.fromEntries(map);
  const prevValue = map.get(key) || [];
  map.set(key, [...prevValue, timestamp]);
  return Object.fromEntries(map);
};

const removeFromTimestampList = (timestamp, prevState) => {
  const { map, key } = buildPrevValueMapAndKey(timestamp, prevState);
  const prevValue = map.get(key) || [];
  const filtered = prevValue.filter((ts) => ts !== timestamp);
  map.set(key, filtered);
  return Object.fromEntries(map);
};

const handleUserBookingSelection = (selectedBooking, previousBookings) => {
  const alreadyAdded = previousBookings.some((pastBooking) => pastBooking?.appointment_object?.id === selectedBooking?.appointment_object?.id);
  if (alreadyAdded) return previousBookings.filter((pastBooking) => pastBooking?.appointment_object?.id !== selectedBooking?.appointment_object?.id);
  return [...previousBookings, selectedBooking];
};

export const isCardAlreadySelectedSelected = (list, card) => {
  if (!card?.appointment_object) return;
  return list.some(({ appointment_object }) => appointment_object.id === card?.appointment_object.id);
};

export const selecAppointmentsToBeCanceledOrRescheduled = (state, action) => {
  if (!action) return [];
  if (action.toLowerCase().includes('cancel'))
    return state.newBooking.canceling.appointmentsToBeCanceled.sort((firstEl, secondEl) => firstEl.number - secondEl.number);
  if (action.toLowerCase().includes('reschedule'))
    return state.newBooking.rescheduling.appointmentsToBeRescheduled.sort((firstEl, secondEl) => firstEl.number - secondEl.number);
  return [];
};

const bookingLessonsInitialState = {
  comingFromOnboarding: false,
  openBookingModal: false,
  appointmentsToBeBooked: [],
  hasErrorOnBooking: false,
  isCreatingAppointment: false,
  isCreatingAppointmentSuccess: false,
  isFirstBooking: false,
  error: null,
  shouldOpenBookingIntroClassModal: false,
  hasErrorOnBookingIntroClass: false,
  hasCreatedIntroClassAppointment: false,
  isCreatingIntroClassAppointment: false,
  selectedTimestamps: {}
};

const resetBookingLessonsErrors = () => ({
  isCreatingAppointmentSuccess: false,
  hasCreatedIntroClassAppointment: false,
  hasErrorOnBooking: false,
  hasErrorOnBookingIntroClass: false,
  error: null
});

function bookingLessonsReducer(state = bookingLessonsInitialState, action) {
  switch (action.type) {
    case NewBookingActionTypes.ADD_TO_BOOKING_SELECTED_TIMESTAMP:
      return {
        ...state,
        selectedTimestamps: addToMap(action.payload, state.selectedTimestamps)
      };
    case NewBookingActionTypes.REMOVE_FROM_BOOKING_SELECTED_TIMESTAMP:
      return {
        ...state,
        selectedTimestamps: removeFromTimestampList(action.payload, state.selectedTimestamps)
      };
    case SessionsActionTypes.GET_SESSIONS_STATUS_SUCCESS:
      return {
        ...state,
        appointmentsToBeBooked: action.payload?.tuitionSessionsStatus?.notBooked
      };
    case NewBookingActionTypes.OPEN_BOOKING_MODAL:
      return {
        ...state,
        ...resetBookingLessonsErrors(),
        openBookingModal: true
      };
    case NewBookingActionTypes.CLOSE_BOOKING_MODAL:
      return {
        ...state,
        ...resetBookingLessonsErrors(),
        openBookingModal: false
      };
    case NewBookingActionTypes.OPEN_BOOKING_INTRO_CLASS_MODAL:
      return { ...state, shouldOpenBookingIntroClassModal: action.payload };
    case NewBookingActionTypes.CREATING_NEW_APPOINTMENT:
      return { ...state, isCreatingAppointment: true };
    case NewBookingActionTypes.CREATING_NEW_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointmentsToBeBooked: [],
        openBookingModal: false,
        isCreatingAppointment: false,
        isCreatingAppointmentSuccess: true,
        hasErrorOnBooking: false,
        error: null
      };

    case NewBookingActionTypes.CREATING_NEW_APPOINTMENT_FAIL:
      return {
        ...state,
        isCreatingAppointment: false,
        hasErrorOnBooking: true,
        error: action.payload
      };
    case NewBookingActionTypes.SET_IS_FIRST_BOOKING:
      return { ...state, isFirstBooking: action.payload };
    case NewBookingActionTypes.BOOK_INTRO_CLASS:
      return {
        ...state,
        isCreatingIntroClassAppointment: true,
        hasErrorOnBookingIntroClass: false
      };
    case NewBookingActionTypes.BOOK_INTRO_CLASS_SUCCESS:
      return {
        ...state,
        appointmentsToBeBooked: [],
        openBookingModal: false,
        isCreatingIntroClassAppointment: false,
        hasCreatedIntroClassAppointment: true,
        hasErrorOnBookingIntroClass: false
      };
    case NewBookingActionTypes.BOOK_INTRO_CLASS_FAIL:
      return {
        ...state,
        isCreatingIntroClassAppointment: false,
        hasCreatedIntroClassAppointment: false,
        hasErrorOnBookingIntroClass: true,
        error: action.payload
      };
    case NewBookingActionTypes.CLEAN_SUCCESS:
      return {
        ...state,
        hasCreatedIntroClassAppointment: false,
        isCreatingAppointmentSuccess: false
      };
    case CLEAN_PREVIOUS_STATE:
      return {
        ...bookingLessonsInitialState,
        appointmentsToBeBooked: state.appointmentsToBeBooked,
        hasCreatedIntroClassAppointment: state.hasCreatedIntroClassAppointment,
        isCreatingAppointmentSuccess: state.isCreatingAppointmentSuccess
      };
    case NewBookingActionTypes.CLEAR_ERROR_STATE:
      return { ...state, ...resetBookingLessonsErrors() };
    default:
      return state;
  }
}

const createConvoAppointments = (conversationsLeft) => {
  if (!conversationsLeft) return [];
  return [
    {
      number: 0,
      appointment_object: null
    }
  ];
};

const bookingConversationInitialState = {
  openBookingModal: false,
  appointmentsToBeBooked: [],
  hasErrorOnBooking: false,
  isCreatingAppointment: false,
  isCreatingAppointmentSuccess: false,
  error: null
};

const resetConversationErrors = () => ({
  error: null,
  hasErrorOnBooking: false,
  isCreatingAppointmentSuccess: false
});

function bookingConversationReducer(state = bookingConversationInitialState, action) {
  switch (action.type) {
    case NewBookingActionTypes.ADD_TO_BOOKING_CONVO_SELECTED_TIMESTAMP:
      return {
        ...state,
        selectedTimestamps: addToMap(action.payload, state.selectedTimestamps)
      };
    case NewBookingActionTypes.REMOVE_FROM_BOOKING_CONVO_SELECTED_TIMESTAMP:
      return {
        ...state,
        selectedTimestamps: removeFromTimestampList(action.payload, state.selectedTimestamps)
      };
    case NewBookingActionTypes.OPEN_BOOKING_CONVERSATION_MODAL:
      return { ...state, ...resetConversationErrors(), openBookingModal: true };
    case NewBookingActionTypes.CLOSE_BOOKING_CONVERSATION_MODAL:
      return { ...state, ...resetConversationErrors(), openBookingModal: false };
    case NewBookingActionTypes.BOOK_CONVERSATION_CLASS:
      return { ...state, isCreatingAppointment: true, ...resetConversationErrors() };
    case NewBookingActionTypes.BOOK_CONVERSATION_CLASS_SUCCESS:
      return {
        ...state,
        appointmentsToBeBooked: [],
        isCreatingAppointment: false,
        openBookingModal: false,
        isCreatingAppointmentSuccess: true,
        hasErrorOnBooking: false,
        error: null
      };
    case NewBookingActionTypes.BOOK_CONVERSATION_CLASS_FAIL:
      return {
        ...state,
        error: action.payload,
        hasErrorOnBooking: true,
        isCreatingAppointment: false,
        isCreatingAppointmentSuccess: false
      };
    case SessionsActionTypes.GET_SESSIONS_STATUS_SUCCESS:
      return {
        ...state,
        appointmentsToBeBooked: createConvoAppointments(1)
      };
    case NewBookingActionTypes.CLEAN_SUCCESS:
      return { ...state, isCreatingAppointmentSuccess: false };
    case CLEAN_PREVIOUS_STATE:
      return {
        ...bookingConversationInitialState,
        appointmentsToBeBooked: state.appointmentsToBeBooked,
        isCreatingAppointmentSuccess: state.isCreatingAppointmentSuccess
      };
    case NewBookingActionTypes.CLEAR_ERROR_STATE:
      return { ...state, appointmentsToBeBooked: state.appointmentsToBeBooked, ...resetConversationErrors() };
    default:
      return state;
  }
}

export const getSessionsToRescheduleId = (state) => {
  const scheduled = state.newBooking.rescheduling?.appointmentsToBeRescheduled || [];
  if (!scheduled?.length) return [];
  return scheduled.map(({ appointment_object }) => appointment_object?.id || null).filter((id) => id);
};

const reschedulingInitialState = {
  shouldOpenReschedulingModal: false,
  sessionType: '',
  appointmentsToBeRescheduled: [],
  hasErrorOnRescheduling: false,
  isReschedulingAppointment: false,
  isReschedulingAppointmentSuccess: false,
  error: null,
  hasErrorOnReschedulingIntroClass: false,
  hasRescheduledIntroClassAppointment: false,
  isReschedulingIntroClassAppointment: false
};

function reschedulingReducer(state = reschedulingInitialState, action) {
  switch (action.type) {
    case NewBookingActionTypes.OPEN_RESCHEDULING_MODAL:
      return {
        ...state,
        shouldOpenReschedulingModal: true,
        hasErrorOnRescheduling: false,
        error: null
      };
    case NewBookingActionTypes.CLOSE_RESCHEDULING_MODAL:
      return {
        ...reschedulingInitialState,
        shouldOpenReschedulingModal: false,
        hasErrorOnRescheduling: false,
        error: null
      };
    case NewBookingActionTypes.ADD_TO_RESCHEDULING_LIST: {
      const appointmentsToBeRescheduled = handleUserBookingSelection(action.payload, state.appointmentsToBeRescheduled);
      return {
        ...state,
        appointmentsToBeRescheduled,
        sessionType: appointmentsToBeRescheduled?.length ? action.payload?.type : ''
      };
    }
    case NewBookingActionTypes.RESCHEDULING_APPOINTMENT:
      return { ...state, isReschedulingAppointment: true, hasErrorOnRescheduling: false, error: null };
    case NewBookingActionTypes.RESCHEDULING_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointmentsToBeRescheduled: [],
        shouldOpenReschedulingModal: false,
        isReschedulingAppointment: false,
        isReschedulingAppointmentSuccess: true,
        hasErrorOnRescheduling: true,
        error: action.payload
      };
    case NewBookingActionTypes.RESCHEDULING_APPOINTMENT_FAIL:
      return {
        ...state,
        appointmentsToBeRescheduled: [],
        shouldOpenReschedulingModal: false,
        isReschedulingAppointment: false,
        isReschedulingAppointmentSuccess: false,
        hasErrorOnRescheduling: false,
        error: null
      };
    case NewBookingActionTypes.CANCELING_APPOINTMENT_SUCCESS:
      return { ...state, appointmentsToBeRescheduled: [] };
    case NewBookingActionTypes.RESCHEDULE_INTRO_CLASS:
      return {
        ...state,
        isReschedulingIntroClassAppointment: true,
        hasErrorOnReschedulingIntroClass: false
      };
    case NewBookingActionTypes.RESCHEDULE_INTRO_CLASS_SUCCESS:
      return {
        ...state,
        appointmentsToBeRescheduled: [],
        shouldOpenReschedulingModal: false,
        isReschedulingIntroClassAppointment: false,
        hasRescheduledIntroClassAppointment: true,
        hasErrorOnReschedulingIntroClass: false
      };
    case NewBookingActionTypes.RESCHEDULE_INTRO_CLASS_FAIL:
      return {
        ...state,
        isReschedulingIntroClassAppointment: false,
        hasRescheduledIntroClassAppointment: false,
        hasErrorOnReschedulingIntroClass: true,
        error: action.payload
      };

    case NewBookingActionTypes.CLEAN_SUCCESS:
      return {
        ...state,
        hasRescheduledIntroClassAppointment: false,
        isReschedulingAppointmentSuccess: false
      };
    case NewBookingActionTypes.CLEAN_RESCHEDULING_MODAL: {
      return reschedulingInitialState;
    }
    case CLEAN_PREVIOUS_STATE:
      return {
        ...reschedulingInitialState,
        hasRescheduledIntroClassAppointment: state.hasRescheduledIntroClassAppointment,
        isReschedulingAppointmentSuccess: state.isReschedulingAppointmentSuccess
      };
    case NewBookingActionTypes.CLEAR_ERROR_STATE:
      return {
        ...state,
        error: null,
        hasErrorOnRescheduling: false,
        hasErrorOnReschedulingIntroClass: false
      };
    default:
      return state;
  }
}

const cancelingInitialState = {
  shouldOpenCancelingModal: false,
  hasErrorOnCanceling: false,
  appointmentsToBeCanceled: [],
  isCancelingAppointment: false,
  isCancelingAppointmentSuccess: false,
  error: null,
  hasErrorOnCancelingIntroClass: false,
  hasCanceledIntroClassAppointment: false,
  isCancelingIntroClassAppointment: false
};

function cancelingReducer(state = cancelingInitialState, action) {
  switch (action.type) {
    case NewBookingActionTypes.OPEN_CANCELING_MODAL:
      return {
        ...state,
        shouldOpenCancelingModal: true,
        hasErrorOnCanceling: false,
        error: null
      };
    case NewBookingActionTypes.CLOSE_CANCELING_MODAL:
      return {
        ...state,
        shouldOpenCancelingModal: false,
        hasErrorOnCanceling: false,
        error: null
      };
    case NewBookingActionTypes.ADD_TO_CANCELING_LIST:
      return {
        ...state,
        appointmentsToBeCanceled: handleUserBookingSelection(action.payload, state.appointmentsToBeCanceled)
      };
    case NewBookingActionTypes.CANCELING_APPOINTMENT:
      return { ...state, isCancelingAppointment: true, hasErrorOnCanceling: false, error: null };
    case NewBookingActionTypes.CANCELING_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointmentsToBeCanceled: [],
        shouldOpenCancelingModal: false,
        isCancelingAppointment: false,
        isCancelingAppointmentSuccess: true,
        hasErrorOnCanceling: false,
        error: null
      };
    case NewBookingActionTypes.CANCELING_APPOINTMENT_FAIL:
      return { ...state, isCancelingAppointment: false, isCancelingAppointmentSuccess: false, hasErrorOnCanceling: true, error: action.payload };
    case NewBookingActionTypes.RESCHEDULING_APPOINTMENT_SUCCESS:
      return { ...state, appointmentsToBeCanceled: [] };
    case NewBookingActionTypes.CANCEL_INTRO_CLASS:
      return {
        ...state,
        isCancelingIntroClassAppointment: true,
        hasErrorOnCancelingIntroClass: false,
        hasCanceledIntroClassAppointment: false
      };
    case NewBookingActionTypes.CANCEL_INTRO_CLASS_SUCCESS:
      return {
        ...state,
        appointmentsToBeCanceled: [],
        shouldOpenCancelingModal: false,
        isCancelingIntroClassAppointment: false,
        hasCanceledIntroClassAppointment: true,
        hasErrorOnCancelingIntroClass: false
      };
    case NewBookingActionTypes.CANCEL_INTRO_CLASS_FAIL:
      return {
        ...state,
        isCancelingIntroClassAppointment: false,
        hasCanceledIntroClassAppointment: false,
        hasErrorOnCancelingIntroClass: true,
        error: action.payload
      };

    case NewBookingActionTypes.CLEAN_SUCCESS:
      return {
        ...state,
        hasCanceledIntroClassAppointment: false,
        isCancelingAppointmentSuccess: false
      };
    case CLEAN_PREVIOUS_STATE:
      return {
        ...cancelingInitialState,
        hasCanceledIntroClassAppointment: state.hasCanceledIntroClassAppointment,
        isCancelingAppointmentSuccess: state.isCancelingAppointmentSuccess
      };
    case NewBookingActionTypes.CLEAR_ERROR_STATE:
      return {
        ...state,
        error: null,
        hasErrorOnCanceling: false,
        hasErrorOnCancelingIntroClass: false
      };
    default:
      return state;
  }
}

const genericBookingInitialState = {
  isBulkingState: false,
  showOptions: false,
  selectedAction: ''
};

function genericBookingsReducer(state = genericBookingInitialState, action = {}) {
  switch (action.type) {
    case NewBookingActionTypes.SET_BULKING_STATE:
      return { ...state, isBulkingState: action.payload };
    case NewBookingActionTypes.SET_SHOW_OPTIONS:
      return { ...state, showOptions: action.payload };
    case NewBookingActionTypes.SET_SELECTED_ACTION:
      return { ...state, selectedAction: action.payload };
    case CLEAN_PREVIOUS_STATE:
      return { ...state };
    default:
      return state;
  }
}

export default combineReducers({
  bookingLessons: bookingLessonsReducer,
  bookingConversation: bookingConversationReducer,
  rescheduling: reschedulingReducer,
  canceling: cancelingReducer,
  generic: genericBookingsReducer
});
