import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import ErrorAlert from '../components/uikit/ErrorAlert';
import { capitalize } from '../utils/string-helpers';
import getOrderInfo from '../actions/order-info';
import getProductTypes from '../actions/product-types';
import LastPurchase from '../components/store/LastPurchase';
import getProductTypesFromOrders from '../utils/order-helpers';
import Header from '../components/uikit/Header';
import Mixpanel from '../utils/mixpanel-helper.js';

import ChargeUserModal from '../utils/charge-user-modal';

import styles from '../style/containers/Store.module.scss';

const ORDER_SOURCE = 'store_top_up';

class Store extends Component {
  constructor() {
    super();
    this.state = {
      error: false,
      languageSelected: false,
      trainingTypeSelected: false,
      deliveryTypeSelected: false,
      isOpen: false,
      product: {},
      amount: 0,
      cost: 0,
      currency: '',
      trainingType: '',
      deliveryType: ''
    };
    this.chargeUserModal = React.createRef();
  }

  componentDidMount() {
    const { user } = this.props;
    Mixpanel.identify(user.id);
    Mixpanel.track('Visited Store');
    this.props.getOrderInfo(user.token, this.showError);
    this.props.getProductTypes(user.token, this.showError);
  }

  onSelect = (index, value, selectionType) => {
    const filterKey = `${selectionType}Filter`;
    const selectedKey = `${selectionType}Selected`;
    const valueKey = `${selectionType}Value`;
    const newState = {
      [filterKey]: index,
      [selectedKey]: true,
      [valueKey]: value
    };
    if (selectionType === 'language' || selectionType === 'trainingType') {
      newState.deliveryTypeSelected = false;
      newState.deliveryTypeFilter = undefined;
    }
    if (selectionType === 'language') {
      newState.trainingTypeSelected = false;
      newState.trainingTypeFilter = undefined;
    }
    this.setState(newState);
  };

  join = (paymentId, data, errorMsg, confirm, history) => {
    const apt = this.state.selectedForRepurchase;
    if (apt.trainingType === undefined) {
      apt.trainingType = apt.training_type;
    }
    if (apt.deliveryType === undefined) {
      apt.deliveryType = apt.delivery_type;
    }
    if (apt.isForGroupSessions === undefined) {
      apt.isForGroupSessions = apt.is_for_group_sessions;
    }
    const user = data.user;

    let params;
    if (confirm) {
      params = {
        student: user,
        appt_type: apt,
        payment_intent_id: paymentId,
        amount: data.amount,
        cost: data.cost,
        currency: data.currency,
        source: ORDER_SOURCE
      };
    } else {
      params = {
        student: user,
        appt_type: apt,
        payment_method_id: paymentId,
        amount: data.amount,
        cost: data.cost,
        currency: data.currency,
        source: ORDER_SOURCE
      };
    }

    const headers = {};
    if (user && user.token) {
      headers.Authorization = `Token ${user.token}`;
    }

    axios
      .post('/api/repurchase/', params, { headers })
      .then((response) => {
        if (response.data.requires_action) {
          this.chargeUserModal.current.handleAction(response);
        } else {
          this.closeBuyModal();
          const amount = data.amount;
          const description = `${amount} x ${capitalize(apt.trainingType)} (${capitalize(apt.deliveryType)})`;
          this.setState({ isOpen: false });
          toast.success(`You succesfully purchased ${description}`);
          this.props.getOrderInfo(user.token, this.showError);
          Mixpanel.track('Purchase Completed', { product: description });
          Mixpanel.people.track_charge(data.cost);
          history.push('/?fromStudyArea=true');
        }
      })
      .catch((error) => {
        if (errorMsg.length === 0) {
          toast.error(`${error.response.data.detail} If you need assistance, please contact us at support@chatterbox.io`);
          Mixpanel.track('Purchase Failed', {
            error: error.message,
            responseData: error.response.data.detail
          });
        }
      });
  };

  stripe = null;

  closeBuyModal = () => {
    this.setState({ isOpen: false });
  };

  handleResult = (token, amount, cost, currency, errorMsg, confirm = false) => {
    const { user, history } = this.props;
    const data = {
      amount,
      cost,
      currency,
      user
    };
    if (errorMsg.length === 0) {
      this.join(token, data, errorMsg, confirm, history);
    }
  };

  openPaymentModal = () => (
    <ChargeUserModal
      ref={this.chargeUserModal}
      handleResult={this.handleResult}
      product={this.state.product}
      amount={this.state.amount}
      cost={this.state.cost}
      currency={this.state.currency}
      trainingType={this.state.trainingType}
      deliveryType={this.state.deliveryType}
      closeBuyModal={this.closeBuyModal}
    />
  );

  showError = (error) => {
    let errorMgs;
    if (error && error.response) {
      errorMgs = error.response.data;
    } else {
      errorMgs = error;
    }
    this.setState({
      error: true,
      errorMgs
    });
  };

  sortOrders = (a, b) => b.id - a.id;

  render() {
    let orders = [];

    if (this.props.orderInfo) {
      orders = this.props.orderInfo.all_orders;
    }
    // sort orders based on their ids
    orders.sort(this.sortOrders);
    const defaultProductTypes = this.props.productTypes;
    let languages = [];
    const productsPerLanguage = {};

    // Get all the avilable languages and product types per language
    if (defaultProductTypes) {
      defaultProductTypes.forEach((p) => {
        p.languages.forEach((l) => {
          languages.push(l.language);
          if (productsPerLanguage[l.language] === undefined) {
            productsPerLanguage[l.language] = {};
          }
          if (productsPerLanguage[l.language][p.training_type] === undefined) {
            productsPerLanguage[l.language][p.training_type] = new Set();
          }
          if (p.delivery_type !== 'in-person') {
            productsPerLanguage[l.language][p.training_type].add(p.delivery_type);
          }
        });
      });
    }

    // Make surelanguages are unique and alphabetically sorted
    languages = Array.from(new Set(languages));
    languages.sort();

    const productTypes = orders ? getProductTypesFromOrders(orders, defaultProductTypes, true) : [];
    const headerText = 'Our Products';
    let productSelected = {};
    if (this.state.languageSelected && this.state.trainingTypeSelected && this.state.deliveryTypeSelected) {
      productSelected = defaultProductTypes.find(
        (p) =>
          p.training_type === this.state.trainingTypeValue &&
          p.delivery_type === this.state.deliveryTypeValue &&
          // in the future we can have an option for group session produc types
          p.is_for_group_sessions === false
      );
    }

    return (
      <div style={{ minHeight: '70vh' }}>
        <Header text={headerText} className={styles.header} />
        {this.state.error && <ErrorAlert errorMsg={this.state.errorMgs} />}
        {this.state.isOpen && this.openPaymentModal()}
        {productTypes.length > 0 && (
          <div>
            <h3 className={styles.sectionHeading}>Previously purchased:</h3>
            <h5 className={styles.sectionSubHeading}>
              One individual session of 60 min costs 1 credit
              <br />
              Group Live Practices of 60 min costs 0.5 credits
            </h5>
            <div className={[styles.sectionInnerContainer, styles.apptTypesCardContainer].join(' ')}>
              {productTypes.slice(0, 3).map((product) => (
                <>
                  <LastPurchase
                    history={this.props.history}
                    deliveryType={product.deliveryType}
                    trainingType={product.trainingType}
                    orderType={product.orderType}
                    image={product.image}
                    key={product.id}
                    duration={product.duration}
                    prices={product.prices}
                    cost={product.cost}
                    language={product.lastLanguage}
                    buy={(amount, cost, currency, trainingType, deliveryType) => {
                      this.setState({
                        selectedForRepurchase: product,
                        isOpen: !this.state.isOpen,
                        product,
                        amount,
                        cost,
                        currency,
                        trainingType,
                        deliveryType
                      });
                    }}
                  />
                </>
              ))}
            </div>
          </div>
        )}
        {productTypes.length > 0 && <h3 className={styles.sectionHeading}>Other products:</h3>}
        <h5 className={styles.sectionSubHeading}>
          One individual session of 60 min costs 1 credit
          <br />
          Group sessions of 60 min costs 0.5 credits
        </h5>
        {languages.length > 0 && (
          <div className={styles.languageFilterContainer}>
            <h4 className={styles.sectionSubHeading}>What other languages do you want to learn?</h4>
            <div className={styles.filters}>
              {languages.map((language, i) => (
                <Button
                  key={language}
                  className={[styles.filterButton, this.state.languageFilter === i ? styles.filterSelected : null].join(' ')}
                  onClick={() => this.onSelect(i, language, 'language')}>
                  {language}
                </Button>
              ))}
            </div>
          </div>
        )}
        {this.state.languageSelected &&
          productsPerLanguage[this.state.languageValue] &&
          Object.keys(productsPerLanguage[this.state.languageValue]).length > 0 && (
            <div className={styles.languageFilterContainer}>
              <h4 className={styles.sectionSubHeading}>How do you want to practice?</h4>
              <div className={styles.filters}>
                {Object.keys(productsPerLanguage[this.state.languageValue]).map((tt, i) => (
                  <Button
                    key={tt}
                    className={[styles.filterButton, this.state.trainingTypeFilter === i ? styles.filterSelected : null].join(' ')}
                    onClick={() => {
                      this.onSelect(i, tt, 'trainingType');
                      this.onSelect(i, 'online', 'deliveryType');
                    }}>
                    {tt}
                  </Button>
                ))}
              </div>
            </div>
          )}
        {productSelected && this.state.languageSelected && this.state.trainingTypeSelected && this.state.deliveryTypeSelected && (
          <div className={styles.foundProductType}>
            <LastPurchase
              history={this.props.history}
              deliveryType={productSelected.delivery_type}
              trainingType={productSelected.training_type}
              image={productSelected.image}
              key={productSelected.id}
              duration={productSelected.duration}
              prices={productSelected.prices}
              cost={productSelected.cost}
              language={this.state.languageValue}
              buy={(amount, cost, currency, trainingType, deliveryType) => {
                this.setState({
                  selectedForRepurchase: productSelected,
                  isOpen: !this.state.isOpen,
                  product: productSelected,
                  amount,
                  cost,
                  currency,
                  trainingType,
                  deliveryType
                });
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    orderInfo: state.orderInfo,
    productTypes: state.selectedProductTypes
  };
}

Store.propTypes = {
  getProductTypes: PropTypes.func.isRequired,
  productTypes: PropTypes.array.isRequired,
  getOrderInfo: PropTypes.func.isRequired,
  orderInfo: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default connect(mapStateToProps, { getOrderInfo, getProductTypes })(Store);
