import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import mixpanelHelper from '../../../../../../utils/mixpanel-helper';
import { convertStringsOfDateAndTimeToDate, convertToLocaleAndFormat } from '../../../../../../utils/time-helpers';

import styles from './HowWasYourPractice.module.scss';

import { ReactComponent as IconWarning } from '../../../../../../assets/icons/icon_chat-warning.svg';
import Button from '../../../../../design-system/button/Button';

const HowWasYourPractice = ({ handleShowRatingModal, isBlocked, selectedCourse, timezone }) => {
  const shouldShowCard = selectedCourse?.lastUnratedUnit;
  if (!shouldShowCard || isBlocked) return;

  const handleClick = () => {
    handleShowRatingModal();
    mixpanelHelper.track('Click', {
      button: 'Last Class Rating',
      appointment_id: selectedCourse?.lastUnratedUnit.appointment.id
    });
  };

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.contentContainer}>
        <div className={styles['icon--wrapper']}>
          <IconWarning />
        </div>
        <div className={styles['card-data--wrapper']}>
          <Button onClick={handleClick} outfit="greenOpaqueButton">
            <div className={styles['text--wrapper']}>
              <h2>How was it?</h2>
              <p>
                <span role="img" aria-label="friendly emoji">
                  🙋{' '}
                </span>
                <span>Tell us how your Practice with {selectedCourse?.lastUnratedUnit.appointment.tutor_name}</span>
                <span>
                  {' '}
                  on{' '}
                  {convertToLocaleAndFormat(
                    convertStringsOfDateAndTimeToDate(
                      selectedCourse?.lastUnratedUnit.appointment.start_date,
                      selectedCourse?.lastUnratedUnit.appointment.start_time
                    ),
                    timezone,
                    'do MMM'
                  )}
                </span>
                <span> was.</span>
              </p>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedCourse: state.courses.selectedCourseDetails.courseDetails,
  timezone: state.time.timezone
});

HowWasYourPractice.propTypes = {
  handleShowRatingModal: PropTypes.func,
  isBlocked: PropTypes.bool,
  selectedCourse: PropTypes.object,
  timezone: PropTypes.string
};

export default connect(mapStateToProps)(HowWasYourPractice);
