import instance from '..';

export default class NewBookingAPI {
  static async createStudentBooking(token, data) {
    return instance.post('/api/appointments/', data, {
      headers: { Authorization: `Token ${token}` }
    });
  }

  static async getOnboardingResults(token) {
    return instance.get('/api/onboarding_result/', {
      headers: { Authorization: `Token ${token}` }
    });
  }

  static async getTutorInfo(token, tutorId) {
    return instance.get(`/api/tutors/${tutorId}/profile/`, {
      headers: { Authorization: `Token ${token}` }
    });
  }

  static async updateStudentBooking(token, data) {
    return instance.patch(`/api/appointments/${data.id}/`, data, {
      headers: { Authorization: `Token ${token}` }
    });
  }

  static async updateMultipleStudentBooking(token, data) {
    return instance.patch(`/api/appointments/${data[0].id}/`, data, {
      headers: { Authorization: `Token ${token}` }
    });
  }

  static async cancelBooking(token, bookingId, cancellationMotive) {
    return instance.post(
      `/api/appointments/${bookingId}/cancel/`,
      { cancelationDetails: cancellationMotive },
      { headers: { Authorization: `Token ${token}` } }
    );
  }

  static async cancelMultipleBookings(token, bookingIds, cancellationMotive) {
    return instance.post(
      '/api/appointments/cancel/',
      { appointmentIds: bookingIds, cancelationDetails: cancellationMotive },
      { headers: { Authorization: `Token ${token}` } }
    );
  }
}
