import React from 'react';
import PropTypes from 'prop-types';

import styles from './Tip.module.scss';
import { capitalize } from '../../../../utils/string-helpers';

const Tip = ({ label }) => (
  <div className={styles['tip-wrapper']}>
    <p>{capitalize(label)}</p>
  </div>
);

Tip.propTypes = {
  label: PropTypes.string.isRequired
};

export default Tip;
