import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './TutorProfile.module.scss';
import LoadingSpinner from '../design-system/loading/spinner/LoadingSpinner';
import Avatar from '../design-system/avatar/Avatar';

import { ReactComponent as IconCameraFilm } from '../../assets/icons/icon_camera-film.svg';
import { cutStringAndAddEllipsis } from '../../utils/string-helpers';

const TutorProfile = ({ isGettingTutorProfile, user }) => {
  if (isGettingTutorProfile)
    return (
      <div className={styles['loading-wrapper']}>
        <LoadingSpinner />
      </div>
    );

  return (
    <Link to={'/tutor-profile-preview'}>
      <aside className={styles['aside-container']}>
        <section className={styles['avatar-and-name']}>
          <Avatar src={user?.profile_picture} userName={`${user?.first_name} ${user?.last_name}`} />
          <div className={styles['name']}>
            <h1>
              {user?.first_name} {user?.last_name}
            </h1>
            <h2>{cutStringAndAddEllipsis(user?.email, 30)}</h2>
            <p className={styles['edit-profile']}>Edit profile</p>
          </div>
        </section>
        <section className={styles['personal-data']}>
          {user?.zoom_id ? (
            <div className={styles['data']}>
              <IconCameraFilm />
              <p>{user?.zoom_id}</p>
            </div>
          ) : null}
        </section>
      </aside>
    </Link>
  );
};

TutorProfile.propTypes = {
  isGettingTutorProfile: PropTypes.bool,
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  isGettingTutorProfile: state.tutorProfile.isGettingTutorProfile,
  user: state.user
});

export default connect(mapStateToProps, {})(TutorProfile);
