import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactStars from 'react-stars';
import Mixpanel from '../../utils/mixpanel-helper.js';
import Button from '../design-system/button/Button';

import styles from '../../style/components/tutor-list/TutorCard.module.scss';
import { setSelectedTutor } from '../../actions/selected-tutor.js';

import ProfilePlaceholder from '../../assets/people/profile-placeholder.png';

class TutorSummary extends Component {
  getLocation = () => {
    const { location } = this.props;

    if (location.length === 0) {
      return 'Unknown';
    }

    return `${location}`;
  };

  select = () => {
    Mixpanel.track('Click', { button: 'tutor_card', tutor: this.props.name });
    this.props.history.push(`/tutor-preview/${this.props.id}`);
    this.props.setSelectedTutorAction({ id: this.props.id, name: this.props.name });
  };

  render() {
    const { name, photoUrl, interests, availabilityMatching, country, rating, bio, reviews, languages } = this.props;
    const imgSrc = photoUrl.length > 0 ? photoUrl : ProfilePlaceholder;
    return (
      <div className={styles.tutorCardWrapper} onClick={this.select} role="button" onKeyUp={this.select} tabIndex={0}>
        <div className={styles.tutorCtaContainer}>
          <img className={styles.tutorProfilePic} src={imgSrc} alt="tutor profile" />
          <Button onClick={this.select}>View Profile</Button>
        </div>
        <div className={styles.tutorInfoWrapper}>
          <div className={styles.tutorInfoTop}>
            <div className={styles.tutorInfoLeft}>
              <div className={[styles.tutorInfo1, styles.tutorName].join(' ')} onClick={this.select} role="button" onKeyUp={this.select} tabIndex={0}>
                <strong>{name}</strong>
              </div>
              <p className={styles.tutorInfo1}>
                <strong>SPEAKS:</strong>{' '}
                {`${languages.map((lg) => `${lg.language}`).join(', ')}${
                  languages.some((l) => l.language.toLowerCase() === 'english') ? '' : ', English'
                }`}
              </p>
              {country !== undefined && country !== '' && (
                <p className={styles.tutorInfo1}>
                  <strong>FROM:</strong> {country}
                </p>
              )}
              {interests !== undefined && interests.length > 0 && (
                <p className={styles.tutorInfo1}>
                  <strong>INTEREST{interests.length > 1 && 'S'}:</strong> {interests.join(', ')}
                </p>
              )}
            </div>
            <div className={styles.tutorInfoRight}>
              {!availabilityMatching && <div className={styles.tutorInfoTag}>Availability not matching</div>}
              {rating && reviews !== '0 reviews' && <ReactStars count={5} size={30} edit={false} value={rating} color1="#e7ce9b" color2="#ef9349" />}
              {rating && reviews !== '0 reviews' && <p className={styles.tutorInfo1}>{`${rating} (${reviews})`}</p>}
            </div>
          </div>
          <p className={styles.tutorBio}>{bio.length > 170 ? `${bio.slice(0, 170)}...` : bio}</p>
        </div>
      </div>
    );
  }
}

TutorSummary.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  photoUrl: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  availabilityMatching: PropTypes.bool.isRequired,
  country: PropTypes.string.isRequired,
  interests: PropTypes.array.isRequired,
  rating: PropTypes.number.isRequired,
  bio: PropTypes.string.isRequired,
  reviews: PropTypes.string.isRequired,
  languages: PropTypes.array.isRequired,
  setSelectedTutorAction: PropTypes.func.isRequired
};

export default connect(null, {
  setSelectedTutorAction: setSelectedTutor
})(TutorSummary);
