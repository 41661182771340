import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { intervalToDuration } from 'date-fns';

import Mixpanel from '../utils/mixpanel-helper.js';
import styles from '../style/containers/Onboarding.module.scss';
import StepLanguageTutor from '../components/onboarding/StepLanguageTutor.js';
import StepCalendar from '../components/onboarding/StepCalendar.js';
import StepPersonalInterest from '../components/onboarding/StepPersonalInterest.js';
import StepCountry from '../components/onboarding/StepCountry.js';
import StepTutorBio from '../components/onboarding/StepTutorBio.js';
import StepTutorFinal from '../components/onboarding/StepTutorFinal.js';
import StudentTutorMatching from '../components/onboarding/StudentTutorMatching';
import StepStudentExtra from '../components/onboarding/StepStudentExtra.js';
import StepStudentFinal from '../components/onboarding/StepStudentFinal.js';
import PlacementTest from '../components/onboarding/PlacementTest.js';
import getProductTypesFromOrders from '../utils/order-helpers';
import {
  setOnboardingAnswers,
  setTutorOnboardingAnswers,
  initializeOnboardingData,
  storeOnboardingSelection,
  resetOnboarding
} from '../actions/onboarding';
import { setOnboardingSubmitted } from '../actions/user';
import { setMatchedTutor } from '../actions/selected-tutor.js';
import { setProductType } from '../actions/selected-product-type';
import setBookingFrequency from '../actions/booking-frequency';
import getProductTypes from '../actions/product-types';
import { setSelectedLanguage } from '../actions/selected-language';
import getOrderInfo from '../actions/order-info';
import { getQueryParamValue } from '../utils/url-helpers.js';

const LESSON_TYPES = [
  {
    name: 'Full Tuition',
    icon: 'book',
    description: (
      <>
        Suitable for learners looking to level up their language skills in all four areas: reading, writing, listening and speaking.
        <br />
        <br />
        <span role="img" className={styles.pointSpan}>
          ✔
        </span>
        <p className={styles.pointText}>️Each level (A1, A2, etc) is made of 25 units.</p>
        <br />
        <span role="img" className={styles.pointSpan}>
          ✔
        </span>
        <p className={styles.pointText}>️Each unit takes approximately 1 week to complete.</p>
        <br />
        <span role="img" className={styles.pointSpan}>
          ✔
        </span>
        <p className={styles.pointText}>️ For each unit, we prescribe 2-3 hours of study & 30-60 minutes of session with a native trainer.</p>
      </>
    ),
    subclasses: ['Online Tutoring', 'In-person Tutoring']
  },
  {
    name: 'Conversation Practice',
    icon: 'comment',
    description: (
      <>
        More suitable for people who already have an intermediate or advanced knowledge of the language, conversation allows you to level up your
        speaking skills (pronunciation, fluency and cultural awareness).
        <br />
        <br />
        <span role="img" className={styles.pointSpan}>
          ✔
        </span>
        <p className={styles.pointText}>30 minutes or 60 minute sessions</p>
        <br />
        <span role="img" className={styles.pointSpan}>
          ✔
        </span>
        <p className={styles.pointText}>Book Live Practices according to your own schedule</p>
        <br />
        <span role="img" className={styles.pointSpan}>
          ✔
        </span>
        <p className={styles.pointText}>Bring your own material to the session and discuss any topic you would like to cover.</p>
      </>
    ),
    subclasses: ['Online Conversation', 'In-person Conversation']
  }
];

const STEP_STUDENT_LESSON_TYPE = 5;
const STUDENT_STEPS = [0, 1, 2, 3, 4, 5, 6];
const TUTOR_STEPS = [100, 101, 102, 103, 104, 105];

const LEVEL_COPY = [
  'COMPLETE BEGINNER: I have never tried to learn this language before.',
  'BEGINNER (A Level): I can have a basic conversation.',
  'INTERMEDIATE (B Level): I can have a complex conversation.',
  'PROFICIENT (C Level): I speak fluently, I just need to perfect some aspects of the language.'
];

class Onboarding extends Component {
  constructor(props) {
    super(props);
    this.startTimeRef = React.createRef();
    this.startTimeRef.current = new Date();
  }

  state = {
    readyToShowTutorMatched: false,
    tutorId: null
  };

  UNSAFE_componentWillMount() {
    const { user, storeSelection, currentStep } = this.props;

    if (currentStep === undefined) {
      let initialStep;
      if (user.user_type === 'student') {
        if (user.needs_placement_test) {
          initialStep = STUDENT_STEPS[0];
        } else {
          initialStep = STUDENT_STEPS[1];
        }
      } else {
        initialStep = TUTOR_STEPS[0];
      }
      storeSelection({
        currentStep: initialStep,
        initialStep
      });
    } else if (currentStep === STUDENT_STEPS[4]) {
      this.stepBackward();
    } else if (currentStep === STUDENT_STEPS[0]) {
      if (user.user_type === 'student' && !user.needs_placement_test) {
        const initialStep = STUDENT_STEPS[1];
        storeSelection({
          currentStep: initialStep,
          initialStep
        });
      }
    }

    if (user.is_banco_unico_special_rules) {
      storeSelection({
        currentStep: STUDENT_STEPS[4],
        initialStep: STUDENT_STEPS[4]
      });
    }
  }

  componentDidMount() {
    this.wrapperRef = React.createRef();
    window.addEventListener('popstate', () => {
      // move to the previous step
      this.stepBackward();
      // prevent user to go back to a different page
      this.props.history.goForward();
    });
    const { user, productTypes, retrieveProductTypes } = this.props;

    if (user.is_banco_unico_special_rules) {
      this.props.storeSelection({ isLoading: true });
      const headers = { Authorization: `Token ${user.token}` };
      axios
        .post('/api/get_typeform_data/', { placement_test_id: '' }, { headers })
        .then((response) => {
          this.setCourse(response.data);
        })
        .catch(() => {
          // Raven.captureException(e);
        });
    }

    if (productTypes === undefined || productTypes === null) {
      retrieveProductTypes(user.token, () => {});
    }

    Mixpanel.identifyUser({ id: user.id, email: user.email, company: user.organization });
    if (this.props.currentStep === STUDENT_STEPS[0]) Mixpanel.track('Started the Onboarding');
    const headers = { Authorization: `Token ${user.token}` };
    axios
      .get('/api/onboarding/', { headers })
      .then((response) => {
        const unselectedPersonalInterests = response.data.personal_interests.map((pi) => {
          const unselectedPi = pi;
          unselectedPi.selected = false;
          return unselectedPi;
        });

        const sortedLanguages = response.data.languages.sort((a, b) => {
          if (a.language <= b.language) {
            return -1;
          }
          return 1;
        });

        // Find typeform related to language
        let language = getQueryParamValue('language');
        let typeformCode = '';
        let waitingListUrl;
        let questionId;
        if (language === undefined && response && response.data && response.data.previous_onboarding && response.data.previous_onboarding.languages) {
          const languageObject = response.data.languages.find((l) => l.id === response.data.previous_onboarding.languages[0]);
          language = languageObject.language;
        } else if (language === undefined && this.props.selectedLanguage !== undefined) {
          if (this.props.languages && this.props.languages.length > this.props.selectedLanguage) {
            language = this.props.languages[this.props.selectedLanguage].language;
          }
        } else if (language === undefined && this.props.initialLanguage !== undefined) {
          language = this.props.initialLanguage;
        } else if (language === undefined && user.placement_test_info !== undefined && user.placement_test_info !== null) {
          language = user.placement_test_info.language;
        }
        if (language) {
          const languageObject = sortedLanguages.find((l) => l.language.toLowerCase() === language.toLowerCase());
          if (languageObject.placement_test) {
            typeformCode = languageObject.placement_test;
            waitingListUrl = languageObject.waiting_list_link;
            questionId = languageObject.waiting_list_question_id;
          } else {
            switch (language.toLowerCase()) {
              case 'arabic':
                typeformCode += 'HiHqRGrv';
                break;
              case 'french':
                typeformCode += 'o38CUu';
                break;
              case 'english':
                typeformCode += 'rjmFdSiX';
                break;
              case 'spanish':
                typeformCode += 'lhagdfHM';
                break;
              default:
                typeformCode += 'o38CUu';
                break;
            }
          }
        }

        this.props
          .initializeOnboarding({
            languages: sortedLanguages,
            professionalInterests: response.data.professional_interests,
            personalInterests: unselectedPersonalInterests,
            bio: response.data.tutor_bio,
            countries: response.data.countries,
            typeformCode,
            waitingListUrl,
            questionId
          })
          .then(() => {
            this.props.queryOrderInfo(user.token, null, this.getProductTypeWithCredits.bind(this));
          });

        const pastOnboarding = response.data.previous_onboarding;

        // fill onboarding with previous options filled by the user
        if (pastOnboarding && user.user_type === 'student') {
          let pastLangIndex = -1;
          if (pastOnboarding.languages !== undefined && pastOnboarding.languages.length > 0) {
            pastLangIndex = sortedLanguages.findIndex((l) => l.id === pastOnboarding.languages[0]);
          }
          if (pastLangIndex !== -1) {
            this.props.storeSelection({ selectedLanguage: pastLangIndex });
            if (this.stepLanguageRef) {
              this.stepLanguageRef.onSelectLanguageDropdown(pastLangIndex);
            }
            if (pastOnboarding.dialects !== undefined && pastOnboarding.dialects.length > 0) {
              const pastDialectIndex = sortedLanguages[pastLangIndex].dialects.findIndex((d) => d === pastOnboarding.dialects[0]);
              if (pastDialectIndex !== -1) {
                this.props.storeSelection({ selectedDialect: pastDialectIndex });
              }
            }
          }
          if (pastOnboarding.tags !== undefined) {
            // using the data retrieved from response, fill in the three different
            // tag categories
            response.data.professional_interests
              .filter((pi) => pastOnboarding.tags.includes(pi.id))
              .forEach((pi) => {
                this.onSelectProfessionalInterest(pi);
              });
            unselectedPersonalInterests
              .filter((pi) => pastOnboarding.tags.includes(pi.id))
              .forEach((pi) => {
                this.onSelectPersonalInterest(pi);
              });
          }

          if (pastOnboarding.text_other_professional !== undefined && pastOnboarding.text_other_professional !== '') {
            this.props.storeSelection({
              textOtherProfessional: pastOnboarding.text_other_professional,
              otherProfessional: true
            });
          }
          if (pastOnboarding.text_other_personal !== undefined && pastOnboarding.text_other_personal !== '') {
            this.props.storeSelection({
              textOtherPersonal: pastOnboarding.text_other_personal,
              otherPersonal: true
            });
          }
          this.props.storeSelection({
            calendarMatrix: JSON.parse(pastOnboarding.selected_timings),
            assignedCourse: response.data.previous_enrolment
          });
        }

        if (user.user_type === 'student' && !user.needs_placement_test) {
          this.setCourse(response.data.previous_enrolment);
        }

        if (user.is_banco_unico_special_rules) {
          this.finalizeOnboarding();
        }
      })
      .catch(() => {
        // Raven.captureException(e);
      });
  }

  onSelectLanguage(index) {
    Mixpanel.track('Onboarding', { step: 'language_picked' });
    this.props.storeSelection({ selectedLanguage: index });
  }

  onSelectTutorLanguage(index, languageIndex) {
    Mixpanel.track('Onboarding', { step: 'tutor_language_picked' });
    const { selectedTutorLanguages } = this.props;
    if (selectedTutorLanguages[languageIndex] === undefined) {
      selectedTutorLanguages.push(index);
    } else {
      selectedTutorLanguages[languageIndex] = index;
    }
    this.props.storeSelection({ selectedTutorLanguages });
  }

  onSelectCountry(country) {
    this.props.storeSelection({ selectedCountry: country });
  }

  onSelectDialect(index) {
    Mixpanel.track('Onboarding', { step: 'dialect_picked' });
    this.props.storeSelection({ selectedDialect: index });
  }

  onSelectTutorDialect(index, dialectIndex) {
    const { selectedTutorDialects } = this.props;
    if (selectedTutorDialects[dialectIndex] === undefined) {
      selectedTutorDialects.push(index);
    } else {
      selectedTutorDialects[dialectIndex] = index;
    }
    this.props.storeSelection({ selectedTutorDialects });
  }

  onSelectLevel(index) {
    Mixpanel.track('Click', { position: 'StepLevel', level: index });
    this.props.storeSelection({
      selectedLevel: index
    });
  }

  onSelectTimeSlot(day, moment) {
    const { calendarMatrix } = this.props;
    calendarMatrix[moment][day] = !calendarMatrix[moment][day];
    this.props.storeSelection({ calendarMatrix, allDays: false });

    let userPickedAllDays = true;
    calendarMatrix.forEach((moments) => {
      moments.forEach((weekDay) => {
        if (weekDay === false) {
          userPickedAllDays = false;
        }
      });
    });
    if (userPickedAllDays) {
      this.props.storeSelection({
        allDays: true
      });
    }
  }

  onSelectOtherPersonal() {
    this.props.storeSelection({ otherPersonal: !this.props.otherPersonal });
  }

  onTextBioChange(event) {
    this.props.storeSelection({ bio: event.target.value });
  }

  onSelectLessonType(index, subIndex, remainingCredits, lessonType, updatedCallback) {
    Mixpanel.track('Click', { position: 'StepLessonType', button: lessonType });
    this.props.storeSelection(
      {
        selectedLessonType: [index, subIndex]
      },
      () => {
        if (updatedCallback !== undefined) {
          updatedCallback();
        }
      }
    );
    if (remainingCredits !== undefined) {
      this.props.storeSelection({ remainingCredits });
    }
  }

  onSelectProfessionalInterest(item) {
    const { professionalInterests } = this.props;
    const selectedProfessionalInterests = professionalInterests.filter((interest) => interest.selected === true);
    if (item.selected || selectedProfessionalInterests.length < 5) {
      const index = professionalInterests.findIndex((pi) => pi.id === item.id);
      professionalInterests[index].selected = !professionalInterests[index].selected;
      this.props.storeSelection({ professionalInterests });
      const updatedSelectedProfessionalInterests = professionalInterests.filter((interest) => interest.selected === true);
      if (updatedSelectedProfessionalInterests.length === 5) {
        this.props.storeSelection({ professionalLimitReached: true });
      } else {
        this.props.storeSelection({ professionalLimitReached: false });
      }
    } else {
      this.props.storeSelection({ professionalLimitReached: true });
    }
  }

  onSelectPersonalInterest(item) {
    const { personalInterests } = this.props;
    const selectedPersonalInterests = personalInterests.filter((interest) => interest.selected === true);

    if (item.selected || selectedPersonalInterests.length < 5) {
      const index = personalInterests.findIndex((pi) => pi.id === item.id);
      personalInterests[index].selected = !personalInterests[index].selected;
      this.props.storeSelection({ personalInterests });
      const updatedSelectedPersonalInterests = personalInterests.filter((interest) => interest.selected === true);
      if (updatedSelectedPersonalInterests.length === 5) {
        this.props.storeSelection({ personalLimitReached: true });
      } else {
        this.props.storeSelection({ personalLimitReached: false });
      }
    } else {
      this.props.storeSelection({ personalLimitReached: true });
    }

    this.wrapperRef.current.focus();
  }

  onTextOtherProfessionalChange(event) {
    this.props.storeSelection({ textOtherProfessional: event.target.value });
  }

  onTextOtherPersonalChange(event) {
    this.props.storeSelection({ textOtherPersonal: event.target.value });
  }

  onSelectOtherOccupation(input) {
    this.props.storeSelection({ textOtherProfessional: input });
  }

  getProductTypeWithCredits() {
    const { orderInfo, languages } = this.props;
    const defaultProductTypes = this.props.productTypes;
    let productTypesWithCredits = orderInfo ? getProductTypesFromOrders(orderInfo.all_orders, defaultProductTypes) : [];

    productTypesWithCredits = productTypesWithCredits.filter((ptwc) => ptwc.remainingCredits > 0);

    this.props.storeSelection({ productTypesWithCredits });

    // check if order is light order
    let sortedOrders = [];
    if (orderInfo.all_orders === undefined || orderInfo.all_orders === null || orderInfo.all_orders.length <= 0) {
      this.props.setBookingFrequency('normal');
    } else {
      sortedOrders = orderInfo.all_orders.sort((a, b) => {
        const dateA = new Date(a.created_datetime);
        const dateB = new Date(b.created_datetime);
        return dateB - dateA;
      });
      this.props.setBookingFrequency(sortedOrders[0].booking_frequency);
    }

    // pre assign language and selected lesson type if coming from MailChimp
    if (sortedOrders.length > 0 && sortedOrders[0].order_appointment_types.length > 0) {
      const apptType = sortedOrders[0].order_appointment_types[0];
      const selectedLanguage = languages.findIndex((l) => l.id === apptType.language_id);

      let firstIndex = 0;
      let secondIndex = 0;
      if (!apptType.training_type.includes('tuition')) {
        firstIndex = 1;
      }
      if (!apptType.delivery_type.includes('online')) {
        secondIndex = 1;
      }

      this.props.storeSelection({ selectedLanguage });

      const remainingCredits = productTypesWithCredits.find(
        (ptwc) => ptwc.deliveryType === apptType.delivery_type && ptwc.lessonType === apptType.lesson_type
      ).remainingCredits;

      this.onSelectLessonType(firstIndex, secondIndex, remainingCredits, LESSON_TYPES[firstIndex]);
    }
  }

  setCourse(courseData) {
    this.props.storeSelection({
      assignedCourse: courseData
    });
  }

  getDialect = () => {
    const language = this.getLanguage();
    if (this.props.selectedDialect !== undefined) return language.dialects.sort((a, b) => (a <= b ? -1 : 1))[this.props.selectedDialect];
  };

  getLanguage = () => {
    if (this.props.selectedLanguage !== undefined && this.props.languages[this.props.selectedLanguage] !== undefined)
      return this.props.languages[this.props.selectedLanguage];
  };

  jumpToStep(nextStep) {
    this.props.storeSelection({ currentStep: nextStep });
  }

  stepBackward() {
    const stepDelta = 1;
    const userType = this.props.user.user_type;
    let stepBackward = '';
    if (this.props.user.user_type === 'student') {
      switch (this.props.currentStep) {
        case 0: {
          stepBackward = 'setup language';
          break;
        }
        case 1: {
          stepBackward = 'current level';
          break;
        }
        case 2: {
          stepBackward = 'schedule';
          break;
        }
        case 3: {
          stepBackward = 'motivation';
          break;
        }
        case 4: {
          stepBackward = 'professional interest';
          break;
        }
        case 5: {
          stepBackward = 'lesson type';
          break;
        }
        default:
      }
      Mixpanel.track('Onboarding step backward', {
        userType,
        stepBackward
      });
    } else if (this.props.user.user_type === 'tutor') {
      switch (this.props.currentStep) {
        case 0: {
          stepBackward = 'country';
          break;
        }
        case 1: {
          stepBackward = 'language skills';
          break;
        }
        case 2: {
          stepBackward = 'professional interest';
          break;
        }
        case 3: {
          stepBackward = 'personal interest';
          break;
        }
        case 4: {
          stepBackward = 'bio';
          break;
        }
        case 5: {
          stepBackward = 'final ';
          break;
        }
        default:
      }
      Mixpanel.track('Onboarding step back ward', {
        userType,
        stepBackward
      });
    }
    if (this.props.currentStep !== TUTOR_STEPS[0] && this.props.currentStep !== STUDENT_STEPS[0]) {
      this.props.storeSelection({ currentStep: this.props.currentStep - stepDelta });
    }
    this.updateOnboardingBackup(false);
  }

  stepForward() {
    const stepDelta = 1;
    const userType = this.props.user.user_type;
    let stepForward = '';
    if (this.props.user.user_type === 'student') {
      switch (this.props.currentStep) {
        case 0: {
          stepForward = 'setup language';
          break;
        }
        case 1: {
          stepForward = 'current level';
          break;
        }
        case 2: {
          stepForward = 'schedule';
          break;
        }
        case 3: {
          stepForward = 'motivation';
          break;
        }
        case 4: {
          stepForward = 'professional interest';
          break;
        }
        case 5: {
          stepForward = 'lesson type';
          break;
        }
        default:
      }
      Mixpanel.track('Onboarding step foward', {
        userType,
        stepForward
      });
    } else if (this.props.user.user_type === 'tutor') {
      switch (this.props.currentStep) {
        case 0: {
          stepForward = 'country';
          break;
        }
        case 1: {
          stepForward = 'language skills';
          break;
        }
        case 2: {
          stepForward = 'professional interest';
          break;
        }
        case 3: {
          stepForward = 'personal interest';
          break;
        }
        case 4: {
          stepForward = 'bio';
          break;
        }
        case 5: {
          stepForward = 'final ';
          break;
        }
        default:
      }
      Mixpanel.track('Onboarding step foward', {
        userType,
        stepForward
      });
    }

    this.props.storeSelection({ currentStep: this.props.currentStep + stepDelta });
    this.updateOnboardingBackup(false);
  }

  selectAllDaysCalendarSelectionMatrix = (days, moments) => {
    const calendarMatrix = Array(moments)
      .fill()
      .map(() => Array(days).fill(true));
    this.props.storeSelection({ calendarMatrix, allDays: true });
  };

  initializeCalendarSelectionMatrix(days, moments) {
    const calendarMatrix = Array(moments)
      .fill()
      .map(() => Array(days).fill(false));
    this.props.storeSelection({ calendarMatrix, allDays: false });
  }

  completeStepLanguage(language, dialect) {
    this.props.storeSelection({
      selectedDialect: dialect,
      language
    });
    this.stepForward();
  }

  tutorProfilePreview = () => {
    this.props.history.push('/tutor-profile-preview/');
    this.props.restartOnboarding();
  };

  navigateToDashboard = () => {
    this.props.history.push('/');
    this.props.restartOnboarding();
  };

  errorCallback = () => {};

  // Function that gets called when tutor finished onboarding
  finalizeTutorOnboarding() {
    const {
      user,
      storeTutorOnboardingAnswers,
      languages,
      professionalInterests,
      personalInterests,
      textBio,
      selectedTutorLanguages,
      selectedTutorDialects
    } = this.props;
    const { textOtherProfessional, otherProfessional, otherPersonal, textOtherPersonal, selectedCountry } = this.props;
    const headers = { Authorization: `Token ${user.token}` };

    const selectedPersonalInterests = personalInterests.filter((pi) => pi.selected);
    const selectedPersonalInterestIds = selectedPersonalInterests.map((spi) => spi.id);
    const selectedProfessionalInterests = professionalInterests.filter((pi) => pi.selected);
    const selectedProfessionalInterestIds = selectedProfessionalInterests.map((spi) => spi.id);

    const tutorLanguages = [];
    const tutorDialects = [];
    selectedTutorLanguages.forEach((tutorLanguage, index) => {
      const language = tutorLanguage !== undefined ? languages[tutorLanguage] : undefined;
      const dialect =
        tutorLanguage !== undefined && selectedTutorDialects[index] !== -1 && selectedTutorDialects[index] !== undefined
          ? languages[tutorLanguage].dialects.sort((a, b) => {
              if (a <= b) {
                return -1;
              }
              return 1;
            })[selectedTutorDialects[index]]
          : undefined;
      tutorLanguages.push(language);
      tutorDialects.push(dialect);
    });

    const selectedCountryId = selectedCountry.id;

    const onboardingData = {
      textBio,
      selectedProfessionalInterestIds,
      selectedPersonalInterestIds,
      selectedCountryId,
      tutorLanguages,
      tutorDialects
    };

    if (otherPersonal) {
      onboardingData.textOtherPersonal = textOtherPersonal;
    } else {
      onboardingData.textOtherPersonal = '';
    }
    if (otherProfessional) {
      onboardingData.textOtherProfessional = textOtherProfessional;
    } else {
      onboardingData.textOtherProfessional = '';
    }

    storeTutorOnboardingAnswers({
      bio: textBio,
      country: selectedCountry,
      languages: tutorLanguages,
      dialects: tutorDialects,
      professionalInterests: selectedProfessionalInterests,
      textOtherProfessional,
      personalInterests: selectedPersonalInterests,
      textOtherPersonal
    });

    axios.post('/api/store_tutor_onboarding/', onboardingData, { headers }).then(() => {
      this.props.onboardingSubmitted();
      this.stepForward();
    });
  }

  showTutorMatched = (tutorId) => {
    this.props.storeSelection({ isLoading: false });
    this.setState({ readyToShowTutorMatched: true, tutorId });
  };

  sendUserToTutorsPage(tutorId) {
    const dialect = this.getDialect();
    if (this.props.selectedDialect !== undefined) {
      this.props.history.push(`/coach/${tutorId}/${dialect}/#match`);
      this.props.restartOnboarding();
    } else {
      this.props.history.push(`/coach/${tutorId}#match`);
      this.props.restartOnboarding();
    }
    this.recordElapsedTime();
  }

  recordElapsedTime() {
    const end = new Date();
    const timeSpent = intervalToDuration({ start: this.startTimeRef.current, end });
    Mixpanel.recordElapsedTime('Onboarding flow', timeSpent);
  }

  retrieveOnboardingData() {
    const {
      user,
      languages,
      professionalInterests,
      personalInterests,
      selectedLanguage,
      selectedDialect,
      selectedLevel,
      selectedLessonType,
      textOtherProfessional,
      otherProfessional,
      otherPersonal,
      textOtherPersonal,
      calendarMatrix,
      timezone
    } = this.props;

    // Retrieve personal and professional interests
    const selectedPersonalInterests = personalInterests.filter((pi) => pi.selected);
    const selectedPersonalInterestIds = selectedPersonalInterests.map((spi) => spi.id);
    const selectedProfessionalInterests = professionalInterests.filter((pi) => pi.selected);
    const selectedProfessionalInterestIds = selectedProfessionalInterests.map((spi) => spi.id);

    // Retrieve language ID
    const langaugeId = selectedLanguage !== undefined && languages[selectedLanguage] !== undefined ? languages[selectedLanguage].id : undefined;

    // Retrieve selected dialect
    const dialect =
      selectedDialect !== undefined
        ? languages[selectedLanguage].dialects.sort((a, b) => {
            if (a <= b) {
              return -1;
            }
            return 1;
          })[selectedDialect]
        : undefined;

    const lessonType = selectedLessonType !== undefined ? LESSON_TYPES[selectedLessonType[0]]?.name : undefined;
    const lessonTypeSubclass = selectedLessonType !== undefined ? LESSON_TYPES[selectedLessonType[0]].subclasses[selectedLessonType[1]] : undefined;

    const onboardingData = {
      studentId: user.id,
      langaugeId,
      dialect,
      level: selectedLevel,
      lessonType,
      lessonTypeSubclass,
      selectedTimings: [],
      selectedProfessionalInterestIds,
      selectedPersonalInterestIds,
      textOtherProfessional,
      textOtherPersonal,
      calendarMatrix,
      timezone,
      match: true
    };
    if (otherPersonal) {
      onboardingData.textOtherPersonal = textOtherPersonal;
    } else {
      onboardingData.textOtherPersonal = '';
    }
    if (otherProfessional) {
      onboardingData.textOtherProfessional = textOtherProfessional;
    } else {
      onboardingData.textOtherProfessional = '';
    }

    return onboardingData;
  }

  updateOnboardingBackup(match = false) {
    const { user } = this.props;
    const headers = { Authorization: `Token ${user.token}` };
    const onboardingData = this.retrieveOnboardingData();
    onboardingData.match = match;

    axios.post('/api/store_onboarding/', onboardingData, { headers });
  }

  // Function called when student finishes onboarding
  finalizeOnboarding() {
    const progressBarElement = document.getElementById('onboardingProgressBar');
    progressBarElement.style.width = '100%';
    this.props.storeSelection({ isLoading: true });
    const {
      user,
      matchedTutorReturned,
      storeOnboardingAnswers,
      orderInfo,
      languages,
      professionalInterests,
      personalInterests,
      selectedLanguage,
      selectedLevel,
      selectedLessonType,
      textOtherProfessional,
      otherProfessional,
      otherPersonal,
      textOtherPersonal,
      calendarMatrix,
      remainingCredits,
      timezone
    } = this.props;

    const headers = { Authorization: `Token ${user.token}` };

    const selectedPersonalInterests = personalInterests.filter((pi) => pi.selected);
    const selectedPersonalInterestIds = selectedPersonalInterests.map((spi) => spi.id);
    const selectedProfessionalInterests = professionalInterests.filter((pi) => pi.selected);
    const selectedProfessionalInterestIds = selectedProfessionalInterests.map((spi) => spi.id);

    let langaugeId = selectedLanguage !== undefined && languages[selectedLanguage] !== undefined ? languages[selectedLanguage].id : undefined;
    let language = this.getLanguage();

    if (
      langaugeId === undefined &&
      orderInfo.active_orders &&
      orderInfo.active_orders.length > 0 &&
      orderInfo.active_orders[0].order_appointment_types &&
      orderInfo.active_orders[0].order_appointment_types.length > 0
    ) {
      langaugeId = languages.find((l) => l.id === orderInfo.active_orders[0].order_appointment_types[0].language_id).id;
      language = languages.find((l) => l.id === orderInfo.active_orders[0].order_appointment_types[0].language_id);
    }
    const dialect = this.getDialect();
    let lessonType = selectedLessonType !== undefined ? LESSON_TYPES[selectedLessonType[0]]?.name : undefined;
    let lessonTypeSubclass = selectedLessonType !== undefined ? LESSON_TYPES[selectedLessonType[0]].subclasses[selectedLessonType[1]] : undefined;

    let defaultProductTypes = this.props.productTypes;

    let searchedDuration;
    let productTypesWithCredits = this.props.productTypesWithCredits;
    if (productTypesWithCredits === undefined || productTypesWithCredits === null) {
      defaultProductTypes = this.props.productTypes;

      productTypesWithCredits = orderInfo ? getProductTypesFromOrders(orderInfo.all_orders, defaultProductTypes) : [];
    }

    // Add classroom Preference
    // Microsoft and BNP will use Teams
    const selectTeams = this.props.user.organization
      ? this.props.user.organization.some((o) => o.name === 'BNP Paribas' || o.name === 'Microsoft' || o.name === 'Skyguide')
      : false;

    user.preferred_class = selectTeams ? 'teams' : 'zoom';

    axios.put('/api/user/', user, { headers });
    const productTypeFromOrder = productTypesWithCredits.find(
      (pt) => lessonType.toLowerCase().includes(pt.trainingType.split(' ')[0]) && lessonTypeSubclass.toLowerCase().includes(pt.deliveryType)
    );
    if (productTypeFromOrder) {
      if (productTypeFromOrder.prioritaryDuration) {
        searchedDuration = productTypeFromOrder.prioritaryDuration;
      } else {
        const lookForDuration = productTypeFromOrder.durations && productTypeFromOrder.durations.length > 0;
        searchedDuration = lookForDuration ? productTypeFromOrder.durations[0] : 60;
      }
    } else {
      let filteredOrders = [];
      if (orderInfo && orderInfo.active_orders) {
        filteredOrders = orderInfo.active_orders.filter(
          (oif) => oif && oif.organization && (oif.organization === 'BNP Paribas' || oif.organization === 'McKinsey')
        );
      }
      // Fix to default bnp and McKinsey users to 30 min clss
      searchedDuration = filteredOrders.length > 0 ? 30 : 60;
    }

    const isUserTraining = this.checkIfUserIsTraining();
    let selectedProductType;

    if (defaultProductTypes) {
      let productTypesToSearch = defaultProductTypes;
      if (isUserTraining) {
        productTypesToSearch = productTypesToSearch.filter((pt) => pt.training_type.includes('training'));
      } else {
        productTypesToSearch = productTypesToSearch.filter((pt) => !pt.training_type.includes('training'));
      }

      selectedProductType =
        lessonType && lessonTypeSubclass
          ? productTypesToSearch.find(
              (pt) =>
                lessonType.toLowerCase().includes(pt.training_type.split(' ')[0]) &&
                lessonTypeSubclass.toLowerCase().includes(pt.delivery_type) &&
                searchedDuration === pt.duration
            )
          : undefined;

      if (selectedProductType === undefined) {
        selectedProductType =
          lessonType && lessonTypeSubclass
            ? productTypesToSearch.find(
                (pt) =>
                  lessonType.toLowerCase().includes(pt.training_type.split(' ')[0]) && lessonTypeSubclass.toLowerCase().includes(pt.delivery_type)
              )
            : undefined;
      }
    }

    if (lessonType === undefined) {
      lessonType = 'Full Tuition';
    }
    if (lessonTypeSubclass === undefined) {
      lessonTypeSubclass = 'Online Tutoring';
    }

    const onboardingData = {
      studentId: user.id,
      langaugeId,
      dialect,
      level: selectedLevel,
      lessonType,
      lessonTypeSubclass,
      selectedTimings: [],
      selectedProfessionalInterestIds,
      selectedPersonalInterestIds,
      textOtherProfessional,
      textOtherPersonal,
      calendarMatrix,
      timezone,
      match: true
    };
    if (otherPersonal) {
      onboardingData.textOtherPersonal = textOtherPersonal;
    } else {
      onboardingData.textOtherPersonal = '';
    }
    if (otherProfessional) {
      onboardingData.textOtherProfessional = textOtherProfessional;
    } else {
      onboardingData.textOtherProfessional = '';
    }
    // Store answers to onboarding
    storeOnboardingAnswers({
      language,
      dialect,
      level: selectedLevel,
      calendarMatrix,
      selectedProfessionalInterests,
      textOtherProfessional,
      selectedPersonalInterests,
      textOtherPersonal
    });

    if (selectedProductType) {
      const product = selectedProductType;
      const isGroupSession = false;
      const deliveryType = product.delivery_type;
      const trainingType = product.training_type;
      const orderType = product.orderType;
      const productTypeId = product.id;
      let prices = product.prices;
      const duration = product.duration;
      const cost = product.cost;
      if (prices.length <= 0) {
        prices = [{ quantity: '1.00', price: cost, currency: 'gbp' }];
      }

      this.props.storeProductType(deliveryType, trainingType, orderType, productTypeId, duration, remainingCredits, prices, isGroupSession);
    }

    if (languages) {
      const selectedLanguageObj = {
        id: langaugeId,
        language: language ? language.language : undefined
      };

      this.props.storeSelectedLanguage(selectedLanguageObj);
    }

    axios
      .post('/api/store_onboarding/', onboardingData, { headers })
      .then((response) => {
        this.props.onboardingSubmitted();
        const tutor = response.data.tutor;
        const issue = response.data.issue;
        const secondChoice = response.data.secondChoice;
        if (!issue || issue === 'no_available_tutor' || issue === 'better_match') {
          matchedTutorReturned(tutor, issue, secondChoice);
          this.showTutorMatched(tutor.id);
        } else {
          let nextStep;
          switch (issue) {
            case 'no_tutor_language':
              nextStep = STUDENT_STEPS[0];
              break;
            case 'no_tutor_dialect':
              nextStep = STUDENT_STEPS[0];
              break;
            case 'no_product_type':
              nextStep = STUDENT_STEPS[3];
              break;
            default:
              nextStep = STUDENT_STEPS[0];
          }
          this.props.storeSelection({ issue, nextStep, isLoading: false });
          this.stepForward();
        }
      })
      .catch(() => {
        // Raven.captureException(e);
      });
  }

  checkIfUserIsTraining() {
    const { orderInfo, languages, selectedLanguage } = this.props;

    let languageId;
    if (selectedLanguage) {
      languageId = languages[selectedLanguage].id;
    } else if (
      orderInfo.active_orders &&
      orderInfo.active_orders.length > 0 &&
      orderInfo.active_orders[0].order_appointment_types &&
      orderInfo.active_orders[0].order_appointment_types.length > 0
    ) {
      languageId = languages.find((l) => l.id === orderInfo.active_orders[0].order_appointment_types[0].language_id).id;
    }

    let hasTraining = false;
    // eslint-disable-next-line no-unused-vars
    for (const order of orderInfo.all_orders) {
      // eslint-disable-next-line no-unused-vars
      for (const appointmentType of order.order_appointment_types) {
        if (appointmentType.language_id === languageId && appointmentType.training_type.includes('training')) {
          hasTraining = true;
          break;
        }
      }
      if (hasTraining) {
        break;
      }
    }
    return hasTraining;
  }

  storeOnboardingNotification() {
    const { user } = this.props;
    const headers = { Authorization: `Token ${user.token}` };
    const data = { issue: this.props.issue };

    axios.post('/api/store_onboarding_notification/', data, { headers });
    this.stepForward();
  }

  otherProfessionalChecked() {
    this.props.storeSelection({ otherProfessional: !this.props.otherProfessional });
  }

  render() {
    const {
      selectedLanguage,
      selectedDialect,
      selectedLevel,
      calendarMatrix,
      textBio,
      selectedCountry,
      issue,
      nextStep,
      allDays,
      user,
      languages,
      professionalInterests,
      personalInterests,
      countries,
      selectedTutorLanguages,
      selectedTutorDialects,
      assignedCourse,
      initialLanguage,
      currentStep,
      typeformCode,
      questionId,
      waitingListUrl,
      timezone
    } = this.props;

    const backgroundImage = user.organization ? user.organization[0].background_image : undefined;

    const isStudent = user.user_type === 'student';

    // VERIFY THIS PIECE OF CODE
    const stepsNumber = STUDENT_STEPS.length - 3;

    let currentStepIndex = STUDENT_STEPS.indexOf(currentStep);
    currentStepIndex = Math.max(currentStepIndex, 0);

    let onboardingProgress = isStudent ? (currentStepIndex / stepsNumber) * 100 : currentStep ? (currentStep.toString().slice(2) / 5) * 100 : 0;
    onboardingProgress = Math.min(onboardingProgress, 100);

    if (currentStep === STUDENT_STEPS[STEP_STUDENT_LESSON_TYPE]) {
      onboardingProgress = 100;
    }

    return (
      <div
        className={styles.onboardingExternal}
        ref={this.wrapperRef}
        style={
          backgroundImage !== undefined
            ? {
                backgroundImage: `url(${backgroundImage})`
              }
            : undefined
        }>
        <div className={[styles.onboardingContainer, currentStep === STUDENT_STEPS[0] ? styles.onboardingFull : undefined].join(' ')}>
          {currentStep !== STUDENT_STEPS[0] && (
            <div style={{ backgroundColor: '#DCDCDC', width: '100%' }}>
              <div id="onboardingProgressBar" className={styles.progressBar} style={{ width: `${onboardingProgress}%` }} />
            </div>
          )}
          {currentStep === STUDENT_STEPS[0] && (
            <PlacementTest
              nextFunction={this.stepForward.bind(this)}
              setCourse={this.setCourse.bind(this)}
              enrolment={assignedCourse}
              initialLanguage={initialLanguage}
              typeformCode={typeformCode ? typeformCode.replace('https://chatterboxio.typeform.com/to/', '').replace('/', '') : undefined}
              waitingListUrl={waitingListUrl}
              questionId={questionId}
            />
          )}
          {currentStep === STUDENT_STEPS[1] && (
            <StepCalendar
              timezone={timezone}
              selectedLanguage={selectedLanguage}
              selectedDialect={selectedDialect}
              nextFunction={this.stepForward.bind(this)}
              backFunction={this.stepBackward.bind(this)}
              initializeCalendarSelectionMatrix={this.initializeCalendarSelectionMatrix.bind(this)}
              calendarMatrix={calendarMatrix}
              onSelectTimeSlot={this.onSelectTimeSlot.bind(this)}
              selectAllDaysCalendarSelectionMatrix={this.selectAllDaysCalendarSelectionMatrix}
              allDays={allDays}
            />
          )}
          {currentStep === STUDENT_STEPS[2] && (
            <StepPersonalInterest
              interestsList={professionalInterests}
              interestsLimit={5}
              onSelectOtherPersonal={this.onSelectOtherOccupation.bind(this)}
              nextFunction={this.stepForward.bind(this)}
              backFunction={this.stepBackward.bind(this)}
              onSelectPersonalInterest={this.onSelectProfessionalInterest.bind(this)}
              showTypingOption
              type="professional"
              title="What’s your job?"
              copy="We’ll find you a coach who you can click with professionally (as well as personally!)"
            />
          )}
          {currentStep === STUDENT_STEPS[3] && (
            <StepPersonalInterest
              interestsList={personalInterests}
              interestsLimit={5}
              nextFunction={() => {
                this.stepForward();
                this.props.storeSelection({ isLoading: true });
                this.finalizeOnboarding();
              }}
              backFunction={this.stepBackward.bind(this)}
              onSelectPersonalInterest={this.onSelectPersonalInterest.bind(this)}
              showMaxSelectionsWarning
              type="personal"
              title="Tell us what you’re interested in."
              copy="Whatever you like to do with your free time, we’ll team you up with a coach who sees things the same way."
            />
          )}
          {currentStep === STUDENT_STEPS[4] && (
            <StudentTutorMatching
              readyToShowTutorMatched={this.state.readyToShowTutorMatched}
              sendUserToTutorsPage={this.sendUserToTutorsPage.bind(this, this.state.tutorId)}
            />
          )}
          {currentStep === STUDENT_STEPS[5] && (
            <StepStudentExtra
              jumpToStep={this.jumpToStep.bind(this)}
              selectedLanguage={languages[selectedLanguage] ? languages[selectedLanguage].language : undefined}
              selectedDialect={
                languages[selectedLanguage] && languages[selectedLanguage].dialects !== undefined
                  ? languages[selectedLanguage].dialects.sort((a, b) => {
                      if (a <= b) {
                        return -1;
                      }
                      return 1;
                    })[selectedDialect]
                  : undefined
              }
              selectedLevel={LEVEL_COPY[selectedLevel].substring(0, LEVEL_COPY[selectedLevel].indexOf(':')).toLowerCase()}
              issue={issue}
              nextStep={nextStep}
              storeOnboardingNotification={this.storeOnboardingNotification.bind(this)}
            />
          )}
          {currentStep === STUDENT_STEPS[6] && <StepStudentFinal navigateToDashboard={this.navigateToDashboard.bind(this)} />}
          {currentStep === TUTOR_STEPS[0] && (
            <StepCountry
              selectedCountry={selectedCountry}
              countries={countries}
              nextFunction={this.stepForward.bind(this)}
              onSelectCountry={this.onSelectCountry.bind(this)}
            />
          )}
          {currentStep === TUTOR_STEPS[1] && (
            <StepLanguageTutor
              selectedLanguages={selectedTutorLanguages}
              selectedDialects={selectedTutorDialects}
              languages={languages}
              nextFunction={this.stepForward.bind(this)}
              backFunction={this.stepBackward.bind(this)}
              onSelectLanguage={this.onSelectTutorLanguage.bind(this)}
              onSelectDialect={this.onSelectTutorDialect.bind(this)}
            />
          )}
          {currentStep === TUTOR_STEPS[2] && (
            <StepPersonalInterest
              interestsLimit={5}
              interestsList={professionalInterests}
              onSelectOtherPersonal={this.otherProfessionalChecked.bind(this)}
              nextFunction={this.stepForward.bind(this)}
              backFunction={this.stepBackward.bind(this)}
              onSelectPersonalInterest={this.onSelectProfessionalInterest.bind(this)}
              type="professional"
              title="Professional Background"
              copy={
                'We’re interested in learning\
                about your professional experience. Please enter below all positions\
                you have held, up until today.'
              }
            />
          )}
          {currentStep === TUTOR_STEPS[3] && (
            <StepPersonalInterest
              interestsLimit={5}
              interestsList={personalInterests}
              onSelectOtherPersonal={this.onSelectOtherPersonal.bind(this)}
              nextFunction={this.stepForward.bind(this)}
              backFunction={this.stepBackward.bind(this)}
              onSelectPersonalInterest={this.onSelectPersonalInterest.bind(this)}
              showMaxSelectionsWarning
              type="personal"
              title="Personal Interests"
              copy={'We’re interested to know more about you and what are \
              your hobbies.\nWhat do you like to do in your free time?'}
            />
          )}
          {currentStep === TUTOR_STEPS[4] && (
            <StepTutorBio
              textBio={textBio}
              onTextBioChange={this.onTextBioChange.bind(this)}
              nextFunction={this.finalizeTutorOnboarding.bind(this)}
              backFunction={this.stepBackward.bind(this)}
            />
          )}
          {currentStep === TUTOR_STEPS[5] && <StepTutorFinal tutorProfilePreview={this.tutorProfilePreview} />}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    productTypes: state.productTypes,
    productType: state.selectedProductType,
    orderInfo: state.orderInfo,
    pastOnboarding: state.onboardingMatch,
    currentStep: state.onboardingMatch.currentStep,
    languages: state.onboardingMatch.languages,
    typeformCode: state.onboardingMatch.typeformCode,
    questionId: state.onboardingMatch.questionId,
    waitingListUrl: state.onboardingMatch.waitingListUrl,
    professionalInterests: state.onboardingMatch.professionalInterests,
    personalInterests: state.onboardingMatch.personalInterests,
    textBio: state.onboardingMatch.bio,
    countries: state.onboardingMatch.countries,
    selectedTutorDialects: state.onboardingMatch.selectedTutorDialects,
    selectedTutorLanguages: state.onboardingMatch.selectedTutorLanguages,
    language: state.onboardingMatch.language,
    selectedLanguage: state.onboardingMatch.selectedLanguage,
    calendarMatrix: state.onboardingMatch.calendarMatrix,
    selectedDialect: state.onboardingMatch.selectedDialect,
    selectedLevel: state.onboardingMatch.selectedLevel,
    textOtherProfessional: state.onboardingMatch.textOtherProfessional,
    otherProfessional: state.onboardingMatch.otherProfessional,
    otherPersonal: state.onboardingMatch.otherPersonal,
    textOtherPersonal: state.onboardingMatch.textOtherPersonal,
    selectedLessonType: state.onboardingMatch.selectedLessonType,
    selectedCountry: state.onboardingMatch.selectedCountry,
    issue: state.onboardingMatch.issue,
    nextStep: state.onboardingMatch.nextStep,
    professionalLimitReached: state.onboardingMatch.professionalLimitReached,
    personalLimitReached: state.onboardingMatch.personalLimitReached,
    allDays: state.onboardingMatch.allDays,
    assignedCourse: state.onboardingMatch.assignedCourse,
    remainingCredits: state.onboardingMatch.remainingCredits,
    initialLanguage: state.onboardingMatch.initialLanguage,
    timezone: state.time.timezone
  };
}

function mapDispatchToProps(dispatch) {
  return {
    retrieveProductTypes: (token, errorCallback) => dispatch(getProductTypes(token, errorCallback)),
    matchedTutorReturned: (tutor, issue, secondChoice) => dispatch(setMatchedTutor(tutor, issue, secondChoice)),
    storeOnboardingAnswers: (data) => dispatch(setOnboardingAnswers(data)),
    storeTutorOnboardingAnswers: (data) => dispatch(setTutorOnboardingAnswers(data)),
    storeProductType: (deliveryType, trainingType, orderType, productTypeId, duration, remainingCredits, prices, isGroupSession, bookingFrequency) =>
      dispatch(
        setProductType({
          deliveryType,
          trainingType,
          orderType,
          productTypeId,
          duration,
          remainingCredits,
          prices,
          isGroupSession,
          invitations: [],
          organization: null,
          bookingFrequency
        })
      ),
    setBookingFrequency: (frequency) => dispatch(setBookingFrequency(frequency)),
    storeSelectedLanguage: (selectedLanguageObj) => dispatch(setSelectedLanguage(selectedLanguageObj)),
    queryOrderInfo: (token, errorCallback, callback) => {
      dispatch(getOrderInfo(token, () => {}, callback));
    },
    initializeOnboarding: (data) => {
      dispatch(initializeOnboardingData(data));
      return Promise.resolve();
    },
    storeSelection: (data) => dispatch(storeOnboardingSelection(data)),
    restartOnboarding: () => dispatch(resetOnboarding()),
    onboardingSubmitted: () => dispatch(setOnboardingSubmitted())
  };
}

Onboarding.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    goForward: PropTypes.func
  }).isRequired,
  matchedTutorReturned: PropTypes.func.isRequired,
  orderInfo: PropTypes.object.isRequired,
  productTypes: PropTypes.object.isRequired,
  storeOnboardingAnswers: PropTypes.func.isRequired,
  storeTutorOnboardingAnswers: PropTypes.func.isRequired,
  retrieveProductTypes: PropTypes.func.isRequired,
  storeProductType: PropTypes.func.isRequired,
  storeSelectedLanguage: PropTypes.func.isRequired,
  queryOrderInfo: PropTypes.func.isRequired,
  setBookingFrequency: PropTypes.func.isRequired,
  storeSelection: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  initializeOnboarding: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
  selectedTutorLanguages: PropTypes.array.isRequired,
  selectedTutorDialects: PropTypes.array.isRequired,
  calendarMatrix: PropTypes.array.isRequired,
  professionalInterests: PropTypes.array.isRequired,
  personalInterests: PropTypes.array.isRequired,
  otherPersonal: PropTypes.bool.isRequired,
  restartOnboarding: PropTypes.func.isRequired,
  textBio: PropTypes.string.isRequired,
  textOtherProfessional: PropTypes.string.isRequired,
  otherProfessional: PropTypes.bool.isRequired,
  textOtherPersonal: PropTypes.string.isRequired,
  selectedCountry: PropTypes.number.isRequired,
  onboardingSubmitted: PropTypes.bool.isRequired,
  selectedDialect: PropTypes.number.isRequired,
  selectedLanguage: PropTypes.number.isRequired,
  selectedLevel: PropTypes.number.isRequired,
  selectedLessonType: PropTypes.number.isRequired,
  remainingCredits: PropTypes.number.isRequired,
  productTypesWithCredits: PropTypes.array.isRequired,
  issue: PropTypes.string.isRequired,
  nextStep: PropTypes.number.isRequired,
  allDays: PropTypes.bool.isRequired,
  countries: PropTypes.array.isRequired,
  assignedCourse: PropTypes.object.isRequired,
  initialLanguage: PropTypes.string.isRequired,
  typeformCode: PropTypes.string.isRequired,
  waitingListUrl: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
