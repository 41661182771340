import { SET_LANGUAGE, USER_LOGOUT } from '../actions/types';

export default function selectedLanguageReducer(state = {}, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.payload;
    case USER_LOGOUT:
      return {};
    case 'persist/REHYDRATE':
      return action?.payload?.selectedLanguage || {};
    default:
      return state;
  }
}
