import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './FilterButton.module.scss';

const FilterButton = ({ buttonText, icon, filterComponent, filterRef }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    listenToClick();

    return () => document.removeEventListener('mousedown', closeWhenClickOutside);
  }, []);

  const listenToClick = () => {
    document.addEventListener('mousedown', closeWhenClickOutside);
  };

  const closeWhenClickOutside = (evt) => {
    if (!filterRef.current || filterRef.current.contains(evt.target)) return;
    setIsFilterOpen(false);
  };

  return (
    <div className={styles.wrapper}>
      <button onClick={() => setIsFilterOpen((prevState) => !prevState)}>
        {icon && <img className={styles['button-icon']} src={icon} alt="icon" />}
        <p>{buttonText}</p>
      </button>
      {isFilterOpen && <div className={styles.filter}>{filterComponent}</div>}
    </div>
  );
};

FilterButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  icon: PropTypes.string,
  filterComponent: PropTypes.element.isRequired,
  filterRef: PropTypes.object.isRequired
};

FilterButton.defaultProps = {
  icon: ''
};

export default FilterButton;
