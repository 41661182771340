import OutOfLicenceAPI from '../services/out-of-licence';
import { sendSentryError } from '../setup/sentry';

export const notifyOutOfLicenceRequest = (studentToken, successCallback, errorCallback) => async () => {
  try {
    await OutOfLicenceAPI.notifyOutOfLicence(studentToken);
    if (successCallback) successCallback();
  } catch (err) {
    sendSentryError({ err, context: 'cancelMultipleBookingsAndGetUpdatedStudentsBooking' });
    const errorMessage = 'Something went wrong while notifying us about your request. Please try again.';
    if (errorCallback) errorCallback(errorMessage);
  }
};
