import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'react-bootstrap';
import ErrorAlert from '../uikit/ErrorAlert';

const FormStatus = (props) => {
  if (!props.status) {
    return null;
  }
  const formType = props.formType ? props.formType : 'Your account';
  const message = props.message;
  const statusMessage = props.status === 'success' ? `${formType} changes were successfully saved.` : `Could not save ${formType}. ${message}`;
  if (props.status === 'danger') {
    return <ErrorAlert errorMsg={statusMessage} />;
  }
  return (
    <Alert className={props.className} bsStyle={props.status}>
      {statusMessage}
    </Alert>
  );
};

FormStatus.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  formType: PropTypes.string.isRequired,
  className: PropTypes.string
};

FormStatus.defaultProps = {
  status: '',
  className: '',
  message: ''
};

export default FormStatus;
