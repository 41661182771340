import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { differenceInMinutes } from 'date-fns';

import { Checkbox } from 'react-bootstrap';

import Button from './design-system/button/Button';

import { cancelAppointment, updateAppointment, markStudentsAsNoShow } from '../actions/appointments';
import { getAppointmentName } from '../utils/appointment-helpers';
import { getTimeDisplay } from '../utils/time-helpers';
import Mixpanel from '../utils/mixpanel-helper.js';
import Modal from './uikit/Modal';

import styles from '../style/components/TutorAppointment.module.scss';
import appStyles from '../style/containers/App.module.scss';
import uiStyles from '../style/components/uikit/ButtonModal.module.scss';
import sendEvent from '../actions/events';

const MINUTES_RANGE_TO_DISPLAY_JOIN = 15;

class TutorAppointment extends Component {
  state = {
    cancelRequested: false,
    noShowRequested: false,
    loading: false,
    textValue: '',
    noText: false,
    copied: new Array(this.props.appointment.students.length),
    studentNoShowCheckboxes: []
  };

  componentDidMount() {
    this.initializeCheckboxes();
  }

  onCancel = () => {
    if (this.state.textValue === '') {
      this.setState({ noText: true });
      return;
    }

    this.setState({
      loading: true
    });
    const { appointment, handleCloseModal, token, errorCallback, successCallback } = this.props;

    const closeLoading = () => {
      this.setState({
        loading: false
      });
      if (handleCloseModal) {
        handleCloseModal();
      }
    };
    const success = () => {
      closeLoading();
      successCallback();
      Mixpanel.track('Click', { button: 'cancel_appointment' });
    };
    const error = () => {
      closeLoading();
      if (errorCallback) errorCallback();
      Mixpanel.track('Error', { error: 'cancel_appointment_error' });
    };
    this.props.cancelAppointment(token, appointment.id, error, success, this.state.textValue);
  };

  onUpdate = (status) => {
    this.setState({
      loading: true
    });
    const { appointment, handleCloseModal, token, successCallback } = this.props;

    const { tutor } = appointment;

    const { studentNoShowCheckboxes } = this.state;

    const updatedAppointment = {
      ...appointment,
      tutor_id: tutor.id,
      status: status
    };

    const closeLoading = () => {
      this.setState({
        loading: false
      });
      if (handleCloseModal) {
        handleCloseModal();
      }
    };
    const success = () => {
      const studentsNoShow = appointment.students.filter((_, index) => studentNoShowCheckboxes[index]).map((student) => student.id);
      this.props.markStudentsAsNoShow(token, appointment.id, studentsNoShow);
      closeLoading();
      successCallback();
      if (status === 'student_noshow') {
        Mixpanel.track('Report', { type: 'student_no_show' });
      }
    };

    this.props.updateAppointment({ token, appointmnetId: appointment.id, payload: updatedAppointment, successCallback: success });
  };

  initializeCheckboxes() {
    const { appointment } = this.props;
    const { students } = appointment;
    let initializedCheckboxes = [true];
    if (appointment.is_group_session) {
      initializedCheckboxes = students.map(() => false);
    }
    this.setState({ studentNoShowCheckboxes: initializedCheckboxes });
  }

  studentNoShowCheckboxHandler(index) {
    const updatedCheckboxes = this.state.studentNoShowCheckboxes;
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    this.setState({ studentNoShowCheckboxes: updatedCheckboxes });
  }

  requestNoShow = () => {
    this.setState({
      noShowRequested: true
    });
  };

  requestCancel = () => {
    this.setState({
      cancelRequested: true
    });
  };

  handleTextChange = (event) => {
    this.setState({ textValue: event.target.value });
  };

  joinVideo = (videoPlatform) => {
    const { tutor, appointment, token } = this.props;
    let url = `${tutor.zoom_id}`;
    if (videoPlatform === 'webex') url = `${tutor.webex_link}`;

    Mixpanel.track('Click', { button: 'join_video' });
    this.props.sendEventAction(token, {
      eventType: 'joined_video',
      appointmentId: appointment.id
    });

    const platform = window.open(url, '_blank');
    platform.focus();
  };

  checkWheterClassIsReadyToStart = () => {
    const { appointment } = this.props;
    if (!appointment) return;
    const appointmentISODate = `${appointment.date}T${appointment.start_time}:00Z`;
    const minutesUntilAppointment = differenceInMinutes(new Date(appointmentISODate), new Date());
    return Math.abs(minutesUntilAppointment) <= MINUTES_RANGE_TO_DISPLAY_JOIN;
  };

  joinChat = () => {
    const { appointment } = this.props;
    const url = `/chat/${appointment.chat}`;
    const chatPage = window.open(url, '_blank');
    chatPage.focus();
  };

  checkResources = () => {
    const { appointment } = this.props;
    const url = `/resources/${appointment.course_id}/${appointment.lesson_number}`;
    this.props.history.push(url);
  };

  sameDay = (d1, d2) => d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();

  renderSkypeUsername = (s, index) => (
    <>
      <div className={styles.copySkypeContainer}>
        <p className={styles.usernameText}>
          Username:
          <span className={styles.username}>{` ${s.skype_id}`}</span>
        </p>
        <CopyToClipboard
          text={s.skype_id}
          onCopy={() => {
            const newCopied = this.state.copied;
            newCopied[index] = true;
            this.setState({ copied: newCopied });
          }}>
          <Button>Copy to clipboard</Button>
        </CopyToClipboard>
      </div>
      {this.state.copied[index] ? <span className={styles.copied}>Copied.</span> : <span className={styles.copied} />}
    </>
  );

  render() {
    const { appointment, tutor } = this.props;
    const { studentNoShowCheckboxes } = this.state;
    const { students } = appointment;

    const now = new Date();
    const start = new Date(`${appointment.date}T${appointment.start_time}Z`);

    const timeString = getTimeDisplay(appointment.date, appointment.start_time, tutor.time_zone_name);

    const appointmentName = getAppointmentName(appointment.language, appointment.training_type, appointment.delivery_type, appointment.dialect);
    const isOnline = appointment.delivery_type.toLowerCase() === 'online';
    const isInPerson = appointment.delivery_type.toLowerCase() === 'in-person';

    const sampleStudent = appointment.students[0];
    // const sameDay = this.sameDay(new Date(), new Date(appointment.date));
    return (
      <div className={[this.props.className, this.state.loading ? [appStyles.loading, appStyles.loadingModal].join(' ') : null].join(' ')}>
        <div className={styles.heading}>Appointment Info</div>
        <p>
          <b>Appointment Type:</b> {appointmentName}
        </p>
        <p>
          <b>Appointment Duration:</b> {appointment.duration} minutes
        </p>
        <p>
          <b>Date:</b> {appointment.date}
        </p>
        <p>
          <b>Time:</b> {timeString}
        </p>
        {appointment.lesson_name && (
          <p>
            <b>Lesson:</b> {appointment.lesson_number} - {appointment.lesson_name}
          </p>
        )}
        <p>
          <b>Status:</b> {appointment.status.toUpperCase()}
        </p>
        {appointment.student_comment && !!appointment.student_comment.length && (
          <p>
            <b>Requested Topics:</b> {appointment.student_comment}
          </p>
        )}

        {!!students.length && (
          <div>
            <div className={styles.heading}>Student Info</div>

            {students.map((student, index) => (
              <div key={student.email}>
                <p className={styles.studentNameLine}>
                  <b>Name:</b> {` ${student.first_name} ${student.last_name || ''}`}
                  {appointment.is_group_session && (
                    <Checkbox
                      name="noShowCheckbox"
                      className={styles.noShowCheckbox}
                      checked={studentNoShowCheckboxes.length === students.length ? studentNoShowCheckboxes[index] : false}
                      onChange={this.studentNoShowCheckboxHandler.bind(this, index)}
                      disabled={start > now}
                    />
                  )}
                </p>

                {sampleStudent.preferred_class === 'zoom' && (
                  <p>
                    <b>Classroom:</b> Zoom
                  </p>
                )}
                {sampleStudent.preferred_class === 'webex' && (
                  <p>
                    <b>Classroom:</b> Webex
                  </p>
                )}
                {sampleStudent.preferred_class === 'teams' && (
                  <>
                    <p>
                      <b>Classroom:</b> Microsoft Teams
                    </p>
                    <p>
                      Send a meeting link to your learner through Teams at the scheduled start time of your session. You can find your learner by
                      searching for {student.email}.
                    </p>
                  </>
                )}
                {sampleStudent.preferred_class === 'meetme' && (
                  <div>
                    <p>
                      Your learner preferes to use MeetMe as classroom. <br />
                      Please find below the necessary connection details <br />
                      <br />
                      <b>Join here: </b> <a href={sampleStudent.meetme_details.url}>{sampleStudent.meetme_details.url}</a>
                      <br />
                      <b>Conference id: </b> {sampleStudent.meetme_details.conference_id} <br />
                      <b>Guest code: </b> {sampleStudent.meetme_details.guest_code} <br />
                    </p>
                  </div>
                )}

                {isInPerson && (
                  <p>
                    <b>Location:</b> The meeting place will be the Gallery Cafe next to the University of Westminster W1B 2HW, London.
                  </p>
                )}

                {index !== students.length - 1 && <hr />}
              </div>
            ))}
          </div>
        )}

        {!this.state.cancelRequested && !this.state.noShowRequested && appointment.status === 'booked' && (
          <>
            <div className={styles.buttonContainer}>
              {sampleStudent.preferred_class === 'zoom' && (
                <Button outfit="greenButton" onClick={this.joinVideo.bind(this, 'zoom')} disabled={this.checkWheterClassIsReadyToStart()}>
                  Join Zoom
                </Button>
              )}
              {sampleStudent.preferred_class === 'webex' && (
                <Button outfit="greenButton" onClick={this.joinVideo.bind(this, 'webex')} disabled={this.checkWheterClassIsReadyToStart()}>
                  Join Webex
                </Button>
              )}
              <Button outfit="greenButton" onClick={this.joinChat}>
                Go to Chat
              </Button>
              {(appointment.training_type === 'tuition' || appointment.training_type === 'introductory class') && (
                <Button outfit="greenButton" onClick={this.checkResources}>
                  Resources
                </Button>
              )}
              {start < now && !isOnline ? (
                <Button outfit="lightGrayButton" onClick={this.requestNoShow}>
                  Report No Show
                </Button>
              ) : (
                <Button outfit="lightGrayButton" disabled={start > now} onClick={this.requestNoShow}>
                  Report No Show
                </Button>
              )}
              <Button outfit="cancelButton" onClick={this.requestCancel}>
                I&apos;m unable to attend
              </Button>
            </div>
          </>
        )}
        {this.state.cancelRequested && (
          <div className={styles.cancelConfirm}>
            <p>
              Are you sure you want to cancel this appointment? The learner will be sent an email informingthem of the cancellation, and you will not
              be paid for this class.
            </p>
            <p>Please tell your learner why you’re unable to attend the class:</p>
            <textarea className={styles.textarea} value={this.state.textValue} onChange={this.handleTextChange} />
            {this.state.noText && <p>required</p>}
            <p>&nbsp;</p>
            <p>Reminder: it is not acceptable to cancel on the day of the class except in an emergency.</p>
            <div className={styles.buttonContainer}>
              <Button
                bsStyle="danger"
                style={{ marginRight: '15px' }}
                onClick={() => {
                  this.onCancel();
                }}>
                Yes, I am unable to attend
              </Button>
              <Button bsStyle="info" onClick={this.props.handleCloseModal}>
                Go back
              </Button>
            </div>
          </div>
        )}
        {this.state.noShowRequested && (
          <div className={styles.cancelConfirm}>
            <p>
              Report this learner as a no-show? This will trigger an email to them informing them that they will still be charged for this
              appointment. <b>Note you can only do this after the appointment start time.</b>
            </p>
            <div className={styles.buttonContainer}>
              <Button
                bsStyle="warning"
                style={{ marginRight: '15px' }}
                onClick={() => {
                  this.onUpdate('student_noshow');
                }}>
                Confirm
              </Button>
              <Button bsStyle="info" onClick={this.props.handleCloseModal}>
                Cancel
              </Button>
            </div>
          </div>
        )}
        {this.state.renderSkypeModal && (
          <Modal
            className={uiStyles.modal}
            onModalClose={() => {
              this.setState({
                renderSkypeModal: false,
                copied: new Array(this.props.appointment.students.length)
              });
            }}>
            <div className={styles.skypeModalContent}>
              <p>
                Add your student{appointment.students.length > 1 ? 's ' : ' '}
                on skype and start the session from there.
              </p>
              {appointment.students.map((s, i) => this.renderSkypeUsername(s, i))}
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

TutorAppointment.propTypes = {
  appointment: PropTypes.object.isRequired,
  cancelAppointment: PropTypes.func.isRequired,
  markStudentsAsNoShow: PropTypes.func.isRequired,
  className: PropTypes.string,
  errorCallback: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func,
  successCallback: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  tutor: PropTypes.object.isRequired,
  updateAppointment: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  sendEventAction: PropTypes.func.isRequired
};

TutorAppointment.defaultProps = {
  className: '',
  handleCloseModal: null
};

export default connect(null, {
  cancelAppointment,
  updateAppointment,
  markStudentsAsNoShow,
  sendEventAction: sendEvent
})(TutorAppointment);
