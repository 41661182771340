import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { GrowthBookProvider, GrowthBook } from '@growthbook/growthbook-react';

import App from './App';
import ErrorBoundary from './containers/ErrorBoundary';
import storageUtils from './utils/storage-helpers';
import './index.scss';
import './setup/axios';
import './setup/mixpanel';
import './setup/sentry';

// Load environment variables
// const apiKey = process.env.REACT_APP_GROWTHBOOK_API_KEY;
// const hostUrl = process.env.REACT_APP_GROWTHBOOK_HOST_URL;

// Initialize the GrowthBook instance
const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: 'sdk-POIhroIscTZL1O8Y',
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key
    });
  }
});

console.log('executing this code');
growthbook.init({ streaming: true });

// Clear the local data storage if there was possibly a breaking app change.
storageUtils.handleVersionChange();
class AppProvider extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GrowthBookProvider growthbook={growthbook}>
            <BrowserRouter>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </BrowserRouter>
          </GrowthBookProvider>
        </PersistGate>
      </Provider>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppProvider />);
