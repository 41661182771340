import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SimpleCard from '../../../../design-system/card/simple-card/SimpleCard';
import Tooltip from '../../../../design-system/tooltip/Tooltip';
import ProgressBar from '../../../../design-system/progress-bar/ProgressBar';
import styles from './StudentProgressContainer.module.scss';

const StudentProgressContainer = ({ isGettingCourseDetails, overallCourseProgress }) => {
  const { isLoadingOverralSelfStudyProgress, overallSelfStudyProgress, totalStudyTime } = overallCourseProgress;

  return (
    <div className={styles.cardsContainer}>
      <SimpleCard
        isLoading={false}
        cardContent={
          <div className={styles.progressCardWrapper}>
            <p className={styles.overallCourseProgress}>Average Self-Study Progress:</p>
            <div className={styles.progressWrapper}>
              <ProgressBar progress={overallSelfStudyProgress} isLoading={isGettingCourseDetails || isLoadingOverralSelfStudyProgress} />
            </div>
            {Boolean(totalStudyTime) && (
              <p className={styles.studyTime}>
                Total study time: <span id="dont-translate">{totalStudyTime}</span>
              </p>
            )}
            <div className={styles['updated-daily']}>
              {' '}
              <Tooltip
                tooltipLabel="This information is updated once a day, so don't worry if your progress isn't displayed exactly as you might expect."
                backgroundColor="white"
                position="bottom-right"
              />
            </div>{' '}
          </div>
        }
      />
    </div>
  );
};

StudentProgressContainer.propTypes = {
  isGettingCourseDetails: PropTypes.bool,
  licenseExpiryDate: PropTypes.string,
  overallCourseProgress: PropTypes.object,
  selectedCourse: PropTypes.object,
  timezone: PropTypes.string
};

const mapStateToProps = (state) => ({
  isGettingCourseDetails: state.courses.selectedCourseDetails.isGettingCourseDetails,
  licenseExpiryDate: state.user?.license_expiration_date,
  overallCourseProgress: state.courses.overallCourseProgress,
  selectedCourse: state.courses.selectedCourseDetails.courseDetails,
  timezone: state.time.timezone
});

export default connect(mapStateToProps)(StudentProgressContainer);
