import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../design-system/button/Button';

import Mixpanel from '../../utils/mixpanel-helper.js';
import styles from '../../style/containers/Onboarding.module.scss';
import SelectButton from '../uikit/SelectButton.js';
import TypeInterest from '../uikit/TypeInterest.js';
import useInitRecording from '../../hooks/useInitRecording';

const StepPersonalInterest = ({
  backFunction,
  copy,
  interestsLimit,
  interestsList,
  nextFunction,
  onSelectPersonalInterest,
  showMaxSelectionsWarning,
  showTypingOption,
  title,
  type
}) => {
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [filteredInterestsList, setFilteredInterestsList] = useState([...interestsList]);
  const [userInput, setUserInput] = useState('');
  const [reachedInterestsLimit, setReachedInterestsLimit] = useState(false);
  const pageTitle = type === 'personal' ? 'Onboarding Step Personal Interests' : 'Onboarding Step Professional Interests';
  const [endRecording] = useInitRecording((endTime) => Mixpanel.recordElapsedTime(`${pageTitle} page`, endTime));

  const checkIfReachedInterestsLimit = (selectedInterestsList) => {
    setReachedInterestsLimit(selectedInterestsList.length >= interestsLimit);
  };

  const checkIfInterestIsAlreadySelected = (interest) => {
    if (!selectedInterests.length) return false;

    selectedInterests.some(({ id }) => id === interest.id);
    return selectedInterests.some(({ id }) => id === interest.id);
  };

  const updateLocalStateWithPreviouslySelectedItems = () => {
    const filteredSelectedInterests = filteredInterestsList.filter((item) => item.selected);
    setSelectedInterests(filteredSelectedInterests);
    checkIfReachedInterestsLimit(filteredSelectedInterests);
  };

  const filterInterestFromTheState = (interest) => {
    const filteredInterestList = selectedInterests.filter(({ id }) => id !== interest.id);
    checkIfReachedInterestsLimit(filteredInterestList);
    setSelectedInterests(filteredInterestList);
    setFilteredInterestsList([...interestsList]);
  };

  const addInterestToTheState = (interest) => {
    const newSelectedInterestsList = [...selectedInterests, interest];
    checkIfReachedInterestsLimit(newSelectedInterestsList);
    setSelectedInterests(newSelectedInterestsList);
  };

  const toggleSelectedInterest = (interest) => {
    if (checkIfInterestIsAlreadySelected(interest)) return filterInterestFromTheState(interest);
    addInterestToTheState(interest);
  };

  useEffect(() => {
    Mixpanel.visitedPage(pageTitle);
    updateLocalStateWithPreviouslySelectedItems();

    return () => endRecording();
  }, []);

  const onUserSelection = (interest) => {
    onSelectPersonalInterest(interest);
    toggleSelectedInterest(interest);
    setUserInput('');
  };

  const onUserTyping = (newList) => {
    setFilteredInterestsList(newList);
  };

  const orderList = (list) =>
    list.sort((a, b) => {
      const suggestionA = a?.name?.toLowerCase();
      const suggestionB = b?.name?.toLowerCase();
      return suggestionA < suggestionB ? -1 : suggestionA > suggestionB ? 1 : 0;
    });

  const orderedInterestList = orderList(filteredInterestsList);
  const interestsAvailable = interestsLimit - selectedInterests.length;

  return (
    <div className={styles.stepWrapper}>
      <div className={styles['onboarding__title-wrapper']}>
        <h1 className={styles.onboardingTitle}>{title}</h1>
        <h2 className={[styles.onboardingHeader, styles.onboardingText, styles['text--italic']].join(' ')}>{copy}</h2>
      </div>
      <div className={styles.motivationButtonContainer}>
        {showTypingOption && (
          <div className={styles.interestTypingContainer}>
            <TypeInterest
              allSuggestions={interestsList}
              userInput={userInput}
              onUserInput={(input) => setUserInput(input)}
              instructionText="Start typing here or select from the list"
              disabled={reachedInterestsLimit}
              disabledText="You reached the limit of tags"
              onFilter={onUserTyping}
            />
          </div>
        )}
        <div className={styles.interestButtonsContainer}>
          {orderedInterestList.map((interest) => (
            <SelectButton
              key={interest.id}
              buttonContent={interest?.name}
              selected={interest.selected}
              onSelect={onUserSelection.bind(this, interest)}
              disabled={reachedInterestsLimit && !interest.selected}
            />
          ))}
        </div>
        {showMaxSelectionsWarning && (
          <div className={styles.selectionWarning}>
            {interestsAvailable ? (
              <p>
                You can select <strong>{interestsLimit - selectedInterests.length}</strong> more interest(s).
              </p>
            ) : (
              <p>You have reached the max number of selected interests.</p>
            )}
          </div>
        )}
      </div>
      <div className={styles.buttonContainer}>
        <Button onClick={backFunction} outfit="secondaryButton">
          Back
        </Button>
        <Button disabled={selectedInterests.length === 0} onClick={nextFunction} outfit="mainButton">
          Next
        </Button>
      </div>
    </div>
  );
};

StepPersonalInterest.propTypes = {
  interestsList: PropTypes.array.isRequired,
  backFunction: PropTypes.func.isRequired,
  nextFunction: PropTypes.func.isRequired,
  onSelectPersonalInterest: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  interestsLimit: PropTypes.number.isRequired,
  showTypingOption: PropTypes.bool.isRequired,
  showMaxSelectionsWarning: PropTypes.bool
};

StepPersonalInterest.defaultProps = {
  showMaxSelectionsWarning: false
};

export default StepPersonalInterest;
