import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  cleanErrorState,
  bookConversationClass,
  addToBookingConvoSelectedTimestamp,
  removeFromBookingConvoSelectedTimestamp,
  closeBookingConversationModal
} from '../../../../actions/new-booking';
import { calculateBookings } from './domain/calculateBookings';

import { selectProductType } from '../../../../reducers/order-info';

import { getDateFromIsoString, getTimeFromIsoString } from '../../../../utils/time-helpers';
import SessionsManagementModal from '../SessionsManagementModal';
import BookingSummaryItem from '../BookingSummaryItem';
import mixpanel from '../../../../utils/mixpanel-helper';

import { CLASS_TYPES } from '../../Enum';
import {
  cleanSelectedTimestamp,
  preBookDate,
  preBookWholeMonthDate,
  removeFromPreBook,
  removeMonthFromPreBook,
  storeMonthSessionsAvailability
} from '../../../../actions/bookable';
import ErrorToast from '../../../design-system/toast/errorToast/ErrorToast';
import SessionsAlert from '../sessions-alert/sessions-alert';
import { getMonthTranslated } from '../../../design-system/calendar/TimeRelated.enum';
import { buildMonthAndYearStr } from '../../../../reducers/bookable';
import useInitRecording from '../../../../hooks/useInitRecording';
import mixpanelHelper from '../../../../utils/mixpanel-helper';

const BookingConvoClassModal = ({
  addToBookingConvoSelectedTimestampAction,
  availableSessionsThisMonth,
  bookConversationClassAction,
  calendar,
  cleanErrorStateAction,
  cleanSelectedTimestampAction,
  currentLang,
  errorMessage,
  hasErrorOnBooking,
  isCreatingAppointment,
  isLoadingFutureScheduleAvailability,
  isLoadingLessonsStatus,
  matchedTutor,
  pendingToBeScheduled,
  preBookDateAction,
  preBookWholeMonthDateAction,
  productType,
  removeFromBookingConvoSelectedTimestampAction,
  removeFromPreBookAction,
  removeMonthFromPreBookAction,
  selectedCourse,
  selectedTimestamp,
  selectedTutor,
  sessionsPerMonth,
  storeMonthSessionsAvailabilityAction,
  closeBookingConversationModalAction,
  student,
  timezone
}) => {
  const [activeAppointmentIndex, setActiveAppointmentIndex] = useState(0);
  const [newSchedule, setNewSchedule] = useState([]);
  const [updatedLesson, setUpdatedLesson] = useState(null);
  const [endRecordingWholePage] = useInitRecording((timeSpent) =>
    mixpanelHelper.lessonAction('Booked Convo', {
      timeSpent,
      type: 'convo'
    })
  );

  const defineNewAppointment = (appointmentIndex, newTimestamp) => {
    const newAppointment = {
      ...pendingToBeScheduled[appointmentIndex],
      newDateAndTime: newTimestamp
    };
    setUpdatedLesson(newAppointment);
  };

  useEffect(() => {
    if (selectedTimestamp) defineNewAppointment(activeAppointmentIndex, selectedTimestamp);
  }, [activeAppointmentIndex, selectedTimestamp]);

  const handleSettingNewActiveLesson = (index) => {
    setActiveAppointmentIndex(index);
  };

  const blockSchedule = (timestamp) => {
    preBookDateAction(timestamp);
  };

  const unblockSchedule = (timestamp) => {
    removeFromPreBookAction(timestamp);
  };

  const addNewScheduleToFinalList = () => {
    if (!updatedLesson?.newDateAndTime) return;
    setNewSchedule([...newSchedule, updatedLesson]);
    addToBookingConvoSelectedTimestampAction(updatedLesson.newDateAndTime);
    blockSchedule(updatedLesson.newDateAndTime);
    calculateSessions([...newSchedule, updatedLesson]);
    handleSettingNewActiveLesson(activeAppointmentIndex + 1);
    cleanSelectedTimestampAction();
  };

  const removeNewScheduleFromFinalList = (item) => {
    const filteredElement = newSchedule.find((lesson) => lesson?.id === item?.id);
    const filteredList = newSchedule.filter((lesson) => lesson?.id !== item?.id);
    setNewSchedule(filteredList);
    removeFromBookingConvoSelectedTimestampAction(filteredElement.newDateAndTime);
    recalculateSessionsAfterExclusion(filteredList, filteredElement?.newDateAndTime);
    unblockSchedule(filteredElement.newDateAndTime);
    cleanSelectedTimestampAction();
  };

  const shouldBlockMonth = (response) => {
    const canBook = response?.canBookThisMonth;
    return typeof canBook === 'boolean' && !canBook;
  };

  const transformIntoMap = (timestamps) => {
    const map = new Map();
    timestamps.forEach((ts) => {
      const key = buildMonthAndYearStr(ts);
      const prev = map.get(key) || [];
      map.set(key, [...prev, ts]);
    });
    return Object.fromEntries(map);
  };

  const calculateSessions = (booked, timestamp) => {
    const selectedTimestamps = transformIntoMap(booked.map(({ newDateAndTime }) => newDateAndTime));
    const selectedTimestamp = timestamp || calendar?.date;
    const response = calculateBookings({
      selectedTimestamp,
      selectedTimestamps,
      sessionsPerMonth
    });

    storeMonthSessionsAvailabilityAction({ timestamp: selectedTimestamp, response });
    if (shouldBlockMonth(response)) {
      preBookWholeMonthDateAction(selectedTimestamp);
    }
  };

  const recalculateSessionsAfterExclusion = (booked, timestamp) => {
    const selectedTimestamps = transformIntoMap(booked.map(({ newDateAndTime }) => newDateAndTime));
    const selectedTimestamp = timestamp || calendar?.date;
    const response = calculateBookings({
      selectedTimestamp,
      selectedTimestamps,
      sessionsPerMonth
    });

    storeMonthSessionsAvailabilityAction({ timestamp: selectedTimestamp, response });
    if (!shouldBlockMonth(response)) {
      removeMonthFromPreBookAction(selectedTimestamp);
    }
  };

  const executeAppointmentCreation = (appointmentData) => {
    bookConversationClassAction({
      appointmentData,
      courseId: selectedCourse?.id,
      token: student.token,
      successCallback: () => {
        endRecordingWholePage();
      }
    });
  };

  const handleCloseModal = () => {
    closeBookingConversationModalAction(false);
  };

  const handleAppointmentCreation = () => {
    const firstLessonToBeScheduled = newSchedule[0];
    const appointmentData = {
      auto_accept: student.is_staff, // TO-DO: missing group session
      courseId: selectedCourse?.id,
      date: getDateFromIsoString(firstLessonToBeScheduled.newDateAndTime),
      duration: productType.duration,
      // invitees: undefined, // TO-DO: missing group session
      // inviteeNames: undefined, // TO-DO: missing group session
      is_for_group_sessions: false, // TO-DO: missing group session
      is_student_beta_tester: student.is_beta_tester,
      language: selectedCourse?.language.language,
      // lesson_number: 0,
      phone_number: '', // TO-DO: is it always an empty string?
      product_type_id: productType.productTypeId,
      start_time: getTimeFromIsoString(firstLessonToBeScheduled.newDateAndTime),
      status: 'booked',
      student_comment: '', // TO-DO: is it always an empty string?
      time_zone: timezone,
      tutor_id: selectedTutor.id || matchedTutor.id
    };

    executeAppointmentCreation(appointmentData);
  };

  const getNewAppointmentTime = (currentLessonId) => {
    const futureAppointment = newSchedule.find((l) => l?.id === currentLessonId);
    if (!futureAppointment) return;
    return futureAppointment.newDateAndTime;
  };

  const getSelectedTimestamps = useMemo(() => {
    return newSchedule.map(({ newDateAndTime }) => newDateAndTime);
  }, [newSchedule]);

  const isThisBookingDone = (lesson) => {
    return newSchedule.some((l) => l?.id === lesson?.id);
  };

  const isPrevBookingDone = (lesson) => {
    if (!lesson) return true;
    return newSchedule.some((l) => l?.id === lesson?.id);
  };

  const Alert = <SessionsAlert availableSessionsPerMonth={availableSessionsThisMonth} monthName={getMonthTranslated(currentLang)[calendar?.month]} />;

  return (
    <>
      <SessionsManagementModal
        calendarSubtitle=""
        disabledActionButton={!newSchedule.length}
        futureSchedule={pendingToBeScheduled}
        handleExecution={handleAppointmentCreation}
        isExecuting={isCreatingAppointment}
        isLoading={isLoadingFutureScheduleAvailability || isLoadingLessonsStatus}
        modalMainColor="salmon"
        modalTitle="Booking Convo Practice"
        onCloseModal={() => {
          handleCloseModal();
          mixpanel.closedModal('Booking');
        }}
        productType={productType}
        type={CLASS_TYPES.conversation}
        progressBarColor="salmon"
        renderAlert={() => Alert}
        renderAppointmentItem={(lesson, index) => (
          <BookingSummaryItem
            active={index === activeAppointmentIndex}
            borderColor="salmon"
            key={index}
            setNewActiveAppointment={handleSettingNewActiveLesson.bind(this, index)}
            disabled={!isThisBookingDone(lesson) && !isPrevBookingDone(pendingToBeScheduled[index - 1])}
            done={isThisBookingDone(lesson)}
            doneScheduling={addNewScheduleToFinalList}
            removeNewScheduleFromFinalList={removeNewScheduleFromFinalList}
            lesson={lesson}
            interimTimestamp={selectedTimestamp}
            finalTimestamp={getNewAppointmentTime(lesson?.id)}
            testId="schedule-session"
            timezone={timezone}
          />
        )}
        selectedTimestamp={selectedTimestamp}
        selectedTimestamps={getSelectedTimestamps}
        testId="booking-convo-session-modal"
      />
      <ErrorToast errorMsg={errorMessage} show={hasErrorOnBooking} onClose={cleanErrorStateAction} />
    </>
  );
};

const mapStateToProps = (state) => ({
  availableSessionsThisMonth: state.bookable.available.availableSessionsThisMonth,
  calendar: state.bookable.available.calendar,
  currentLang: state.appLanguage.currentLanguage,
  errorMessage: state.newBooking.bookingConversation.error,
  hasErrorOnBooking: state.newBooking.bookingConversation.hasErrorOnBooking,
  isLoadingLessonsStatus: state.sessions.conversationSessions.isLoadingLessonsStatus,
  isCreatingAppointment: state.newBooking.bookingConversation.isCreatingAppointment,
  isLoadingFutureScheduleAvailability: state.bookable.futureSchedule.isLoadingFutureSchedule,
  matchedTutor: state.selectedTutor.matchedTutor,
  pendingToBeScheduled: state.newBooking.bookingConversation.appointmentsToBeBooked,
  productType: selectProductType(state.orderInfo, CLASS_TYPES.conversation),
  selectedCourse: state.courses?.selectedCourseDetails?.courseDetails,
  selectedTimestamp: state.bookable.available.calendar?.selectedTimestamp,
  selectedTutor: state.selectedTutor.selectedTutor,
  sessionsPerMonth: state.bookable.available.sessionsPerMonth,
  student: state.user,
  timezone: state.time.timezone
});

BookingConvoClassModal.propTypes = {
  addToBookingConvoSelectedTimestampAction: PropTypes.func.isRequired,
  availableSessionsThisMonth: PropTypes.object.isRequired,
  bookConversationClassAction: PropTypes.func.isRequired,
  calendar: PropTypes.object.isRequired,
  cleanErrorStateAction: PropTypes.func.isRequired,
  cleanSelectedTimestampAction: PropTypes.func.isRequired,
  closeBookingConversationModalAction: PropTypes.func.isRequired,
  currentLang: PropTypes.string.isRequired,
  currentTutor: PropTypes.object,
  errorMessage: PropTypes.string,
  hasErrorOnBooking: PropTypes.bool.isRequired,
  isCreatingAppointment: PropTypes.bool,
  isLoadingFutureScheduleAvailability: PropTypes.bool,
  isLoadingLessonsStatus: PropTypes.bool,
  matchedTutor: PropTypes.object.isRequired,
  pendingToBeScheduled: PropTypes.array.isRequired,
  preBookDateAction: PropTypes.func.isRequired,
  preBookWholeMonthDateAction: PropTypes.func.isRequired,
  productType: PropTypes.object.isRequired,
  removeFromBookingConvoSelectedTimestampAction: PropTypes.func.isRequired,
  removeFromPreBookAction: PropTypes.func.isRequired,
  removeMonthFromPreBookAction: PropTypes.func.isRequired,
  selectedCourse: PropTypes.object.isRequired,
  selectedTimestamp: PropTypes.string,
  selectedTutor: PropTypes.object.isRequired,
  sessionsPerMonth: PropTypes.object,
  storeMonthSessionsAvailabilityAction: PropTypes.func.isRequired,
  student: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired
};

BookingConvoClassModal.defaultProps = {
  isCreatingAppointment: false,
  isLoadingFutureScheduleAvailability: false,
  isLoadingLessonsStatus: false
};

export default connect(mapStateToProps, {
  addToBookingConvoSelectedTimestampAction: addToBookingConvoSelectedTimestamp,
  bookConversationClassAction: bookConversationClass,
  cleanSelectedTimestampAction: cleanSelectedTimestamp,
  closeBookingConversationModalAction: closeBookingConversationModal,
  cleanErrorStateAction: cleanErrorState,
  preBookDateAction: preBookDate,
  preBookWholeMonthDateAction: preBookWholeMonthDate,
  removeFromBookingConvoSelectedTimestampAction: removeFromBookingConvoSelectedTimestamp,
  removeFromPreBookAction: removeFromPreBook,
  removeMonthFromPreBookAction: removeMonthFromPreBook,
  storeMonthSessionsAvailabilityAction: storeMonthSessionsAvailability
})(BookingConvoClassModal);
