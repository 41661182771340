import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '../../../design-system/progress-bar/ProgressBar';

import styles from './sessions-alert.module.scss';

const SessionsAlert = ({ availableSessionsPerMonth, monthName }) => {
  const { availableRemaining, availableTotal, expiringRemaining, expiringTotal } = availableSessionsPerMonth;

  if (availableTotal === undefined || availableTotal > 1000) return null;
  const Available = (
    <div className={styles['alert-container']}>
      <p>
        Sessions selected in <strong>{monthName}</strong>
      </p>
      <ProgressBar percentage={false} progress={[availableTotal - availableRemaining, availableTotal]} color="#421B9B" />
    </div>
  );

  const Expiring = (
    <div className={styles['alert-container']}>
      <p>Sessions expiring this month</p>
      <ProgressBar percentage={false} progress={[expiringTotal - expiringRemaining, expiringTotal]} color="#421B9B" />
    </div>
  );

  if (!expiringTotal) return Available;

  return (
    <div className={styles.wrapper}>
      {Available}
      {Expiring}
    </div>
  );
};

SessionsAlert.propTypes = {
  availableSessionsPerMonth: PropTypes.object,
  monthName: PropTypes.string
};

export default SessionsAlert;
