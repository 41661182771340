import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Message from './Message';

import { clearHelpdesk, createTicket, listUserTickets } from '../../../../actions/helpdesk';
import styles from './SupportMessage.module.scss';
import Button from '../../../design-system/button/Button';
import CardLoader from '../../../design-system/loading/card/CardLoader';
import { askDetails, createTicketIntro, createdSuccessfully, doYouWantToCreate, introduction, listPreviousTickets, noPreviousTickets } from './flow';
import { actions } from '../../../../domains/helpdesk/chat';

const SupportMessage = ({
  clearHelpdeskAction,
  createTicketAction,
  isCreatingTicket,
  isListingUserTickets,
  isListingUserTicketsReady,
  listUserTicketsAction,
  ticketCreated,
  user,
  userTickets
}) => {
  const [renderedMessages, setRenderedMessages] = useState([]);
  const [action, setAction] = useState('');
  const [selectedSupportType, setSelectedSupportType] = useState('');
  const [description, setDescription] = useState('');
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    clearHelpdeskAction();
    listUserTicketsAction(user?.email);

    return () => {
      setRenderedMessages([]);
      clearHelpdeskAction();
    };
  }, []);

  const pushMessagesToStack = ({ messages, nextStep, timespan }) => {
    if (!messages?.length) return;
    messages.forEach((message, index) =>
      setTimeout(() => {
        setRenderedMessages((prevState) => [...prevState, <Message key={prevState.length + 1} message={message} />]);
        if (nextStep) nextStep();
      }, (index + 1) * timespan)
    );
  };

  const sendRequest = async () => {
    const formData = new FormData();
    formData.append('status', 2);
    formData.append('priority', 1);
    formData.append('subject', `TEST - ${user?.first_name} ${user?.last_name}:${selectedSupportType}@${new Date().toISOString()}`);
    formData.append('description', description);
    formData.append('type', selectedSupportType);
    formData.append('email', user?.email);
    attachments.forEach((att) => formData.append('attachments[]', att));

    createTicketAction(formData);
  };

  useEffect(() => {
    pushMessagesToStack({ messages: introduction({ user }), timespan: 500 });
  }, []);

  useEffect(() => {
    if (!isListingUserTicketsReady) return;
    if (!userTickets?.length) pushMessagesToStack({ messages: noPreviousTickets(), timespan: 500 });
    if (userTickets?.length) pushMessagesToStack({ messages: listPreviousTickets({ userTickets }), timespan: 500 });
    pushMessagesToStack({ messages: doYouWantToCreate({ setAction, user }), timespan: 1000 });
  }, [isListingUserTicketsReady]);

  useEffect(() => {
    if (action === actions.newRequest) pushMessagesToStack({ messages: createTicketIntro({ setSelectedSupportType, user }), timespan: 500 });
  }, [action]);

  useEffect(() => {
    if (action === actions.newRequest)
      pushMessagesToStack({ messages: askDetails({ selectedSupportType, setAttachments, setDescription }), timespan: 500 });
  }, [selectedSupportType]);

  useEffect(() => {
    if (!ticketCreated?.id) return;
    pushMessagesToStack({ messages: createdSuccessfully({ ticketCreated }), timespan: 500 });
  }, [ticketCreated?.id]);

  if (isListingUserTickets && !isListingUserTicketsReady)
    return (
      <div className={styles.containerWrapper}>
        <div className={styles.loadingWrapper}>
          <div className={styles.alignRight}>
            <CardLoader rows={4} rowsColor="gray" />
          </div>
          <div className={styles.alignLeft}>
            <CardLoader rows={4} rowsColor="gray" />
          </div>
          <div className={styles.alignRight}>
            <CardLoader rows={4} rowsColor="gray" />
          </div>
        </div>
      </div>
    );

  return (
    <>
      {renderedMessages}
      {description && !ticketCreated?.id && (
        <div className={styles['button-wrapper']}>
          <Button outfit="greenButton" onClick={sendRequest} disabled={ticketCreated?.id} loading={isCreatingTicket}>
            Send
          </Button>
        </div>
      )}
    </>
  );
};

SupportMessage.propTypes = {
  clearHelpdeskAction: PropTypes.func.isRequired,
  createTicketAction: PropTypes.func.isRequired,
  isCreatingTicket: PropTypes.bool.isRequired,
  isListingUserTickets: PropTypes.bool.isRequired,
  isListingUserTicketsReady: PropTypes.bool.isRequired,
  listUserTicketsAction: PropTypes.func.isRequired,
  ticketCreated: PropTypes.object,
  user: PropTypes.object,
  userTickets: PropTypes.array
};

const mapStateToProps = (state) => ({
  isCreatingTicket: state.helpdesk.isCreatingTicket,
  isListingUserTickets: state.helpdesk.isListingUserTickets,
  isListingUserTicketsReady: state.helpdesk.isListingUserTicketsReady,
  ticketCreated: state.helpdesk.ticketCreated,
  user: state.user,
  userTickets: state.helpdesk.userTickets
});

export default connect(mapStateToProps, {
  clearHelpdeskAction: clearHelpdesk,
  createTicketAction: createTicket,
  listUserTicketsAction: listUserTickets
})(SupportMessage);
