import React from 'react';
import PropTypes from 'prop-types';

import mixpanelHelper from '../../../../utils/mixpanel-helper';

import styles from './Assessment.module.scss';
import { BOOKING_STATES } from '../../../../common/bookingStates';
import { ASSIGNMENT_STATES } from '../../../../common/assignmentStates';
import ProgressLineSection from './ProgressLineSection';
import AssessmentScore from '../../../assessment-score/AssessmentScore';

const Assessment = ({ first, index, isEnabled, isOptional, isSelected, last, onSelectUnit, unit, isOverdue, dueDate, isCompleted }) => {
  const { title: unitTitle, score } = unit;

  const getStatus = (type) => {
    if (!isEnabled) return BOOKING_STATES['locked'];

    if (type === 'written_assignment') {
      if (unit.written_assignment_result.status === ASSIGNMENT_STATES['completed']) {
        if (unit.written_assignment_result.score > 0) {
          return `Your score: ${unit.written_assignment_result.score}`;
        } else {
          return 'Completed';
        }
      } else {
        return unit.written_assignment_result.status;
      }
    } else if (type === 'speaking_assignment') {
      if (unit.speaking_assignment_result.status === ASSIGNMENT_STATES['completed']) {
        if (unit.speaking_assignment_result.score > 0) {
          return `Your score: ${unit.speaking_assignment_result.score}`;
        } else {
          return 'Completed';
        }
      } else {
        return unit.speaking_assignment_result.status;
      }
    } else {
      if (score) return `Your score: ${score}`;
      return 'You still need to finish this assessment';
    }
  };

  const getAssessmentScore = () => {
    if (isCompleted) {
      return unit.overall_score;
    } else {
      return null;
    }
  };

  return (
    <div className={styles['lesson-wrapper']} data-testid="assessment-wrapper">
      <ProgressLineSection isOptional={isOptional} isDone={Boolean(score)} isFirst={first} isLast={last} isCurrent={isEnabled} />
      <AssessmentScore score={getAssessmentScore()} isBlocked={!isEnabled} isSelected={isSelected} />
      <button
        className={[
          styles.container,
          isEnabled ? styles['container--past'] : styles['container--inactive'],
          isSelected ? styles['container--selected'] : undefined
        ].join(' ')}
        data-testid={`${unitTitle}-button`}
        onClick={() => {
          if (!isEnabled) return;
          mixpanelHelper.track('Click', { course_unit: unitTitle });
          onSelectUnit(index, unit);
        }}>
        <div className={styles['lesson']}>
          <p id="dont-translate" className={styles['lesson__title']}>
            {unitTitle}{' '}
            {isEnabled && !isCompleted && <span className={isOverdue ? styles['lesson__overdue'] : styles['lesson__duedate']}>{dueDate}</span>}
          </p>
          <span className={styles['lesson__subdata']}>Assessment | {getStatus()}</span>
          {unit.written_assignment && <span className={styles['lesson__subdata']}>Written Assignment | {getStatus('written_assignment')}</span>}
          {unit.speaking_assignment && <span className={styles['lesson__subdata']}>Speaking Assignment | {getStatus('speaking_assignment')}</span>}
        </div>
      </button>
    </div>
  );
};

Assessment.propTypes = {
  first: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  isOptional: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  last: PropTypes.bool.isRequired,
  isOverdue: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  onSelectUnit: PropTypes.func.isRequired,
  dueDate: PropTypes.string.isRequired,
  unit: PropTypes.shape({
    title: PropTypes.string.isRequired,
    score: PropTypes.number,
    written_assignment: PropTypes.string,
    written_assignment_result: PropTypes.object,
    speaking_assignment: PropTypes.string,
    speaking_assignment_result: PropTypes.object,
    overall_score: PropTypes.number
  })
};

export default Assessment;
