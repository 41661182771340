import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import styles from '../../MenuItem.module.scss';
import { UserTypes } from '../../../../domains/user/user-types';

import { ReactComponent as CoachAreaIcon } from '../../../../assets/icons/icon_coach-area-menu.svg';

const CoachAreaButton = ({ handleMenuLinkClick, user }) => {
  if (user?.user_type !== UserTypes.tutor) return;

  return (
    <li className={styles['link-wrapper']} data-testid="coach-area-button">
      <NavLink
        to={'/resources'}
        onClick={() => handleMenuLinkClick('coach_area')}
        activeClassName={styles.isActive}
        className={styles.menuItem}
        data-testid="coach-area-link">
        <div className={styles['button-wrapper']}>
          <div className={styles['background']}>
            <CoachAreaIcon />
          </div>
          <span>Coach Area</span>
        </div>
      </NavLink>
    </li>
  );
};

CoachAreaButton.propTypes = {
  handleMenuLinkClick: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default CoachAreaButton;
