import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import * as moment from 'moment-timezone';

import 'rc-time-picker/assets/index.css';
import appStyles from '../../style/containers/App.module.scss';
import styles from '../../style/components/calendar/TimeOffForm.module.scss';
import { getMultipleErrorMessage } from '../../utils/error-helpers';
import Mixpanel from '../../utils/mixpanel-helper.js';
import Button from '../design-system/button/Button';
import ErrorToast from '../design-system/toast/errorToast/ErrorToast';

class PontualAvailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endTime: props.end ? props.end.add(1, 'minute') : null,
      errors: [],
      date: props.start || null,
      startTimePicked: !!props.start,
      endTimePicked: !!props.end,
      isLoading: false
    };
  }

  onConfirm = () => {
    this.setState({ isLoading: true });
    const date = this.state.date.format('YYYY-MM-DD');
    const from = this.state.date.format('HH:mm');
    const to = this.state.endTime.format('HH:mm');

    const { availabilityId, token } = this.props;
    const isRecurring = false;
    if (availabilityId) {
      this.props.updateAvailability(token, date, from, to, availabilityId, isRecurring, this.errorCallback, this.availUpdateSuccess);
    } else {
      this.props.createNewAvailability(token, date, from, to, isRecurring, this.errorCallback, this.newAvailSuccess);
    }
  };

  onDelete = () => {
    this.setState({ isLoading: true });
    const { token, availabilityId } = this.props;
    this.props.deleteAvailability(token, availabilityId, this.errorCallback, this.deleteSuccessCallback);
  };

  getEndTimeOptions = (date) => {
    const endOfDay = date.clone().endOf('day');
    const nextTimeOption = date.clone().add(30, 'minute');

    const endTimes = [nextTimeOption];
    // eslint-disable-next-line
    for (let i = 0; i <= 95; i++) {
      endTimes.push(endTimes[endTimes.length - 1].clone().add(30, 'minute'));
      if (endTimes[endTimes.length - 1].isSameOrAfter(endOfDay)) {
        break;
      }
    }
    endTimes.pop();
    return endTimes;
  };

  errorCallback = (response) => {
    this.setState({ isLoading: false });
    this.fail(response.detail);
  };

  newAvailSuccess = () => {
    Mixpanel.track('Successfully added a new occasional availability');
    this.setState({ isLoading: false });
    this.props.handleCloseModal();
  };

  availUpdateSuccess = () => {
    this.setState({ isLoading: false });
    Mixpanel.track('Successfully updated a occasional availability');
  };

  deleteSuccessCallback = () => {
    this.props.handleCloseModal();
  };

  handleError = (error) =>
    this.setState({
      errors: (error.response && error.response.data) || [],
      isLoading: false
    });

  fail = (message) => this.setState({ errors: [message], isLoading: false });

  renderErrors = () => {
    const errState = this.state.errors;
    const errMsgs = getMultipleErrorMessage(errState);

    if (!errMsgs.length) return;

    return (
      <div>
        {errMsgs.map((msg, index) => (
          <ErrorToast key={index} errorMsg={msg} show />
        ))}
      </div>
    );
  };

  render() {
    window.state = this.state;
    const { availabilityId, title } = this.props;
    const { endTime, startTimePicked, endTimePicked, isLoading } = this.state;
    return (
      <div className={isLoading ? appStyles.loadingModal : null}>
        <div className={styles.container}>
          <h4 className={styles.heading}>{title}</h4>
          <div className={styles.inputsContainer}>
            <div>
              <span className={styles.calendarLabel}>Day:</span>
              <DatePicker
                placeholderText="Pick a day"
                ref={(node) => {
                  this.dayCalendarRef = node;
                }}
                minDate={moment(new Date())}
                maxDate={moment(new Date()).add(3, 'M')}
                selected={this.state.date}
                onChange={(date) => {
                  this.setState({
                    date
                  });
                }}
                className={styles.dateInput}
                calendarClassName={[styles.dateInput, styles.popupCalendar]}
                dateFormat="LL"
                fixedHeight
                locale="en-gb"
              />
            </div>
            <div>
              <span className={styles.calendarLabel}>From:</span>
              <DatePicker
                placeholderText="Select a time"
                selected={this.state.date}
                onChange={(date) => {
                  const endTimes = this.getEndTimeOptions(date);
                  this.setState({
                    date,
                    endTime: endTimes[0],
                    endTimeOptions: endTimes,
                    startTimePicked: true
                  });
                }}
                className={styles.timeInput}
                showTimeSelect
                showTimeSelectOnly
                timeCaption="Time"
                dateFormat="h:mm a"
                fixedHeight
                timeIntervals={30}
                locale="en-gb"
                disabled={!this.state.date}
              />
            </div>
            <div>
              <span className={styles.calendarLabel}>To:</span>
              <DatePicker
                selected={endTime}
                onChange={(date) => {
                  this.setState({
                    endTime: date,
                    endTimePicked: true
                  });
                }}
                className={styles.timeInput}
                showTimeSelect
                showTimeSelectOnly
                timeCaption="Time"
                dateFormat="h:mm a"
                fixedHeight
                includeTimes={this.state.endTimeOptions}
                timeIntervals={30}
                locale="en-gb"
                disabled={!endTime}
              />
            </div>
          </div>

          {this.renderErrors()}

          <div className={styles.buttonsWrapper}>
            <Button outfit="greenButton" onClick={this.onConfirm} disabled={!startTimePicked && !endTimePicked}>
              Confirm
            </Button>

            {availabilityId && (
              <Button className={styles.button} bsStyle="danger" onClick={this.onDelete}>
                Delete
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PontualAvailForm.propTypes = {
  createNewAvailability: PropTypes.func,
  end: PropTypes.object,
  closeModal: PropTypes.func,
  availabilityId: PropTypes.number,
  start: PropTypes.object,
  title: PropTypes.string,
  token: PropTypes.string.isRequired,
  deleteAvailability: PropTypes.func.isRequired,
  updateAvailability: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired
};

PontualAvailForm.defaultProps = {
  createNewAvailability: () => {},
  end: null,
  closeModal: () => {},
  availabilityId: null,
  start: null,
  title: 'Add occasional avaibility'
};

export default PontualAvailForm;
