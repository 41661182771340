import { request } from './common';
import { CREATE_INVOICE, GET_INVOICES, GET_INVOICES_ERROR } from './types';

function createSuccess(response) {
  return {
    type: CREATE_INVOICE,
    payload: response
  };
}

function getSuccess(response) {
  return {
    type: GET_INVOICES,
    payload: response
  };
}

function getFail() {
  return {
    type: GET_INVOICES_ERROR,
    payload: []
  };
}

export function createInvoice(token, data, fail, success) {
  const options = {
    actionSuccess: createSuccess,
    actionFail: getFail,
    data,
    fail,
    success
  };

  return request('post', 'invoices', token, options);
}

export function getInvoices(token, params, fail, success) {
  const options = {
    actionSuccess: getSuccess,
    fail,
    params,
    success
  };

  return request('get', 'invoices', token, options);
}
