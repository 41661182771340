import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Grid, Row, Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { capitalize } from '../utils/string-helpers';
import TutorMatchProfile from '../components/tutor-match/TutorMatchProfile';
import Mixpanel from '../utils/mixpanel-helper.js';
import styles from '../style/containers/TutorMatch.module.scss';
import BookingForm from '../components/tutor-booking-page/BookingForm';
import { getUpcomingAppointmentsForStudent } from '../actions/appointments';
import getOrderInfo from '../actions/order-info';
import { refreshUser } from '../actions/user';
import ErrorAlert from '../components/uikit/ErrorAlert';
import appStyles from '../style/containers/App.module.scss';
import ChargeUserModal from '../utils/charge-user-modal';
import { isTokenExpired } from '../actions/common';
import { getTutorPreview } from '../actions/tutor-profile';

import { getBookingInfoForTutorForTimespan } from '../actions/bookable';

const ORDER_SOURCE = 'tutor_profile__top_up';

const CheckTutorProfile = ({
  tutor,
  productType,
  expiredToken,
  user,
  bookable,
  selectedLanguage,
  bookingFrequency,
  getTutorPreviewAction,
  getBookingInfoForTutorForTimespanAction
}) => {
  const [justProfile, setJustProfile] = useState(false);
  const [showBooking, setShowBooking] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [product, setProduct] = useState({});
  const [amount, setAmount] = useState(0);
  const [cost, setCost] = useState(0);
  const [currency, setCurrency] = useState('');
  const [trainingType, setTrainingType] = useState('');
  const [deliveryType, setDeliveryType] = useState('');
  const [remainingCredits, setRemamingCredits] = useState('');
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [showBuyCredits, setShowBuyCredits] = useState(false);
  const [hideBooking, setHideBooking] = useState(false);

  const bookingContainerRef = React.createRef();
  const chargeUserModal = React.createRef();

  const history = useHistory();
  const match = useRouteMatch();

  const getTutorBookingInfo = () => {
    if (!selectedLanguage) return;

    const currentLanguage = selectedLanguage.language;
    const languageMatch = tutor.languages ? tutor.languages.filter((x) => x.language === currentLanguage) : [];
    if (languageMatch && languageMatch[0]) {
      const newDate = new Date();
      const nextMonth = new Date(newDate.getTime());
      nextMonth.setMonth(nextMonth.getMonth() + 1);

      getBookingInfoForTutorForTimespanAction({
        token: user.token,
        tutorId: tutor.id,
        productTypeId: productType.productTypeId,
        selectedLanguageId: languageMatch[0].id,
        from: newDate,
        to: nextMonth
      });
    }
  };

  const trackTutorInfo = () => {
    if (match.params.fromQuick) {
      Mixpanel.track('[QuickBooking] Visited tutor page', {
        tutor: `${tutor.first_name} ${tutor.last_name}`
      });
    } else {
      Mixpanel.track('[AppointmentCard] Visited tutor page', {
        tutor: `${tutor.first_name} ${tutor.last_name}`
      });
    }
  };

  const retrieveTutorInfo = () => {
    if (match?.url?.includes('tutor-profile')) setJustProfile(true);
    if (tutor && 'id' in tutor && match.params.tutor_id === tutor.id.toString()) {
      if (user.user_type === 'student') {
        getTutorBookingInfo();
        trackTutorInfo();
      }
    } else {
      getTutorPreviewAction(user.token, match.params.tutor_id).then(() => {
        if (user.user_type === 'student') {
          getTutorBookingInfo();
          trackTutorInfo();
        }
      });
    }
  };

  useEffect(() => {
    retrieveTutorInfo();
    Mixpanel.identify(user.id);
  }, []);

  const showError = (receivedError) => {
    setError(true);
    setErrorMsg(receivedError);
  };

  const closeBuyModal = () => {
    setIsOpen(false);
  };

  const join = (paymentId, data, inputErrorMsg, confirm) => {
    const student = data.user;
    const currentProduct = productType;
    let params;
    if (confirm) {
      params = {
        student,
        payment_intent_id: paymentId,
        appt_type: currentProduct,
        amount: data.amount,
        cost: data.cost,
        currency: data.currency,
        source: ORDER_SOURCE
      };
    } else {
      params = {
        student,
        payment_method_id: paymentId,
        appt_type: currentProduct,
        amount: data.amount,
        cost: data.cost,
        currency: data.currency,
        source: ORDER_SOURCE
      };
    }
    const headers = {};
    if (student && student.token) {
      headers.Authorization = `Token ${student.token}`;
    }

    axios
      .post('/api/repurchase/', params, { headers })
      .then((response) => {
        if (response.data.requires_action) {
          chargeUserModal.current.handleAction(response);
        } else {
          const currentAmout = parseFloat(data.amount).toFixed(0);
          const description = `${currentAmout} x ${capitalize(currentProduct.trainingType)} (${capitalize(currentProduct.deliveryType)})`;
          toast.success(`You succesfully re-purchased ${description}`);
          getOrderInfo(student.token, showError);
          setRemamingCredits(currentAmout);
          closeBuyModal();
          Mixpanel.track('Purchase Completed', { product: description });
          Mixpanel.people.track_charge(data.cost);
        }
      })
      .catch((err) => {
        if (!expiredToken(err)) {
          if (inputErrorMsg.length === 0) {
            toast.error(`${err.response.data.detail} If you need assistance, please contact us at support@chatterbox.io`);
            Mixpanel.track('Purchase Failed', {
              error: err.message,
              responseData: err.response.data.detail
            });
          }
        }
      });
  };

  const handleResult = (paymentId, inputAmount, inputCost, inpytCurrency, inputErrorMsg, confirm = false) => {
    const data = {
      inputAmount,
      inputCost,
      inpytCurrency,
      user
    };
    if (inputErrorMsg.length === 0) {
      join(paymentId, data, inputErrorMsg, confirm);
    }
  };

  const openPaymentModal = () => (
    <ChargeUserModal
      ref={chargeUserModal}
      handleResult={handleResult}
      product={product}
      amount={amount}
      cost={cost}
      currency={currency}
      trainingType={trainingType}
      deliveryType={deliveryType}
      closeBuyModal={closeBuyModal}
    />
  );

  const toogleBooking = () => {
    setShowBooking((prevState) => !prevState);
  };

  const meetMoreTutors = () => {
    history.push('/tutor-list/');
  };

  const finishBooking = (resourceURL = false) => {
    getOrderInfo(user.token, showError);
    getUpcomingAppointmentsForStudent(user.token, user.id, showError);
    refreshUser(user.token);
    if (resourceURL) {
      history.push(resourceURL);
    } else {
      history.push('/');
    }
    window.scrollTo(0, 0);
  };

  const scrollToBooking = () => {
    try {
      window.scrollTo(0, bookingContainerRef.current.offsetTop);
      return true;
    } catch {
      return false;
    }
  };

  let languageMatch = [];

  if (tutor && tutor.id && selectedLanguage) {
    languageMatch = tutor.languages ? tutor.languages.filter((x) => x.language === selectedLanguage.language) : [];
  }

  return (
    <div className={styles.containerWrapper}>
      {tutor && tutor.id ? (
        <TutorMatchProfile
          tutor={{ name: tutor.first_name, id: tutor.id }}
          bio={tutor.bio}
          languages={tutor.languages}
          sessionTypes={tutor.session_types}
          location={tutor.country}
          name={tutor.first_name}
          surName={tutor.last_name}
          photoURL={tutor.profile_picture}
          onStartLearning={toogleBooking}
          rating={tutor.calculated_rating}
          reviews={tutor.reviews}
          seniority={tutor.seniority}
          hoursTutored={tutor.hours_tutored}
          userType="student"
          history={history}
          user={user}
          topReviews={tutor.top_reviews}
          calendarMatrix={tutor.availability_matrix}
          professionalInterests={tutor.professional_interests}
          textOtherProfessional={tutor.text_other_professional}
          personalInterests={tutor.personal_interests}
          textOtherPersonal={tutor.text_other_personal}
          checkProfile
          scrollToBooking={scrollToBooking}
          showBooking={!justProfile}
          inReview={tutor.in_review}
          handleExpiredToken={expiredToken}
        />
      ) : (
        <div className={[appStyles.loader, styles.centerLoader].join(' ')} />
      )}
      {error && (
        <Grid className={styles.errorContainer}>
          <Row>
            <Col xs={12}>
              <ErrorAlert errorMsg={errorMsg} />
            </Col>
          </Row>
        </Grid>
      )}
      {isOpen && openPaymentModal()}
      {showBooking && (
        <BookingForm
          productType={productType}
          creditsLeft={remainingCredits || productType.remainingCredits}
          bookableLessonNumbers={bookable.next_lesson === null ? null : [bookable.next_lesson]}
          nextLessons={bookable.next_lessons === null ? [] : bookable.next_lessons}
          bookableDatesAndTimesISO={bookable.datetimes}
          courseId={bookable.course_id}
          deliveryType={deliveryType}
          finishBooking={finishBooking.bind(this)}
          meetingPlaceName=""
          student={user}
          trainingType={trainingType}
          tutorId={tutor.id}
          tutorProfile={tutor}
          language={languageMatch[0]}
          isGroupSession={productType.isGroupSession}
          purchase={(inputAmount, inputCost, inputCurrency) => {
            setIsOpen((prevState) => !prevState);
            setProduct(productType);
            setAmount(inputAmount);
            setCost(inputCost);
            setCurrency(inputCurrency);
            setTrainingType(product.trainingType);
            setDeliveryType(product.deliveryType);
            setShowBuyCredits(false);
            setHideBooking(false);
          }}
          prices={productType.prices}
          bookingContainerRef={bookingContainerRef}
          invitations={productType.invitations}
          userEmail={user.email}
          history={history}
          showBuyCredits={showBuyCredits}
          hideBooking={hideBooking}
          bookingFrequency={bookingFrequency}
        />
      )}
      {!justProfile && tutor?.id && user.user_type === 'student' && !user.is_banco_unico_special_rules && (
        <>
          <h2 className={styles.centeredHeader}>Do you want to meet more coaches?</h2>
          <div className={styles.buttonWrapper}>
            <Button onClick={meetMoreTutors} className={styles.anotherTutorButton}>
              Meet more coaches
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  tutor: state.tutorProfile,
  productType: state.selectedProductType,
  user: state.user,
  bookable: state.bookable.available,
  selectedLanguage: state.courses?.selectedCourseDetails?.courseDetails?.language,
  bookingFrequency: state.bookingFrequency
});

CheckTutorProfile.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
    location: PropTypes.object
  }).isRequired,
  bookable: PropTypes.bool.isRequired,
  productType: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired,
  getBookingInfoForTutorForTimespanAction: PropTypes.func.isRequired,
  expiredToken: PropTypes.func.isRequired,
  getTutorPreviewAction: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.object.isRequired,
  bookingFrequency: PropTypes.any.isRequired,
  tutor: PropTypes.object.isRequired
};

export default connect(mapStateToProps, (dispatch) => ({
  getBookingInfoForTutorForTimespanAction: ({ token, tutorId, productTypeId, lagnuageId, from, to }) =>
    dispatch(
      getBookingInfoForTutorForTimespan({
        token,
        tutorId,
        productTypeId,
        lagnuageId,
        from,
        to
      })
    ),

  refreshUser: (token) => dispatch(refreshUser(token)),
  getOrderInfo: (token, showErr) => dispatch(getOrderInfo(token, showErr)),
  getUpcomingAppointmentsForStudent: (token, userId, showErr) => dispatch(getUpcomingAppointmentsForStudent(token, userId, showErr)),
  getTutorPreviewAction: (token, tutorId, errorCallback) => dispatch(getTutorPreview(token, tutorId, errorCallback)),
  expiredToken: (err) => {
    isTokenExpired(dispatch, err);
  },
  dispatch
}))(CheckTutorProfile);
