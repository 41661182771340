import React, { Component } from 'react';
import { CardElement } from 'react-stripe-elements';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import styles from '../style/utils/charge-user-modal.module.scss';

export default class _ChargeUserCard extends Component {
  state = {
    errorMessage: ''
  };

  createOptions = () => ({
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#c23d4b'
      }
    }
  });

  handleAction = (response) => {
    const { amount, cost, currency } = this.props;
    if (this.props.stripe) {
      this.props.stripe.handleCardAction(response.data.payment_intent_client_secret).then((result) => {
        if (result.error) {
          if (result.error.code === 'payment_intent_authentication_failure') {
            toast.error('Authentication has failed. Please try again.');
          }
        } else {
          this.props.handleResult(result.paymentIntent.id, amount, cost, currency, this.state.errorMessage, true);
        }
      });
    }
  };

  handleChange = ({ error }) => {
    this.setState({ errorMessage: '' });
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  handleSubmit = (evt) => {
    const { amount, cost, currency } = this.props;
    evt.preventDefault();
    if (this.props.stripe) {
      this.props.stripe.createPaymentMethod('card').then((result) => {
        if (result.error) {
          this.setState({ errorMessage: result.error.message });
        } else {
          this.props.handleResult(result.paymentMethod.id, amount, cost, currency, this.state.errorMessage);
        }
      });
    }
  };

  render() {
    const { cost, closeBuyModal } = this.props;
    return (
      <div
        className={styles.modalArea}
        role="button"
        tabIndex="0"
        onKeyDown={() => {}}
        onClick={() => {
          closeBuyModal();
        }}>
        <div
          className={styles.cardContainer}
          role="button"
          tabIndex="0"
          onKeyDown={() => {}}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <div className={styles.titleContainer}>
            <h2>Complete your purchase</h2>
          </div>
          <div className={styles.contentContainer}>
            <img src="static/images/payment/payment_methods.png" alt="visa" />
            <p>Payment amount</p>
            <p className={styles.cost}>£{cost}</p>
            <form onSubmit={this.handleSubmit.bind(this)}>
              <label>
                Card details
                <CardElement className={styles.StripeElement} onChange={this.handleChange} {...this.createOptions()} />
              </label>
              <div className={styles.error} role="alert">
                {this.state.errorMessage}
              </div>
              <button>Pay £{cost}</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

_ChargeUserCard.propTypes = {
  deliveryType: PropTypes.string.isRequired,
  closeBuyModal: PropTypes.func.isRequired,
  trainingType: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  cost: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired,
  handleResult: PropTypes.func.isRequired,
  stripe: PropTypes.shape({
    createPaymentMethod: PropTypes.func,
    handleCardAction: PropTypes.func
  }).isRequired
};

_ChargeUserCard.defaultProps = {};
