import { TutorProfileActionTypes } from '../actions/tutor-profile';
import { GET_TUTOR_PROFILE, GET_TUTOR_PROFILE_ERROR, USER_LOGOUT } from '../actions/types';

const initialState = {
  errorGettingTutorProfile: false,
  isGettingTutorProfile: false,
  tutorProfile: {}
};

export default function tutorProfileReducer(state = initialState, action) {
  switch (action.type) {
    case TutorProfileActionTypes.GET_TUTOR_PROFILE:
      return { ...state, isGettingTutorProfile: true };
    case TutorProfileActionTypes.GET_TUTOR_PROFILE_SUCCESS:
      return { ...state, tutorProfile: action.payload, isGettingTutorProfile: false, errorGettingTutorProfile: false };
    case TutorProfileActionTypes.GET_TUTOR_PROFILE_FAIL:
      return { ...state, tutorProfile: {}, isGettingTutorProfile: false, errorGettingTutorProfile: action.payload };
    case GET_TUTOR_PROFILE:
      return action.payload.data;
    case GET_TUTOR_PROFILE_ERROR:
      return {};
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
}
