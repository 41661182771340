import React from 'react';
import PropTypes from 'prop-types';

import ExpandableCard from './components/ExpandableCard';

import styles from './Information.module.scss';

const Information = ({ componentRef }) => {
  return (
    <div className={styles['information-wrapper']} ref={componentRef}>
      <h2>Information</h2>
      <ExpandableCard
        title="What is the Introductory Session?"
        response={
          <div className={styles['response']}>
            It&apos;s a 15-minute meeting for you to know your coach. There is nothing for you to prepare so we&apos;d recommend getting this booked
            in as soon as possible.
          </div>
        }
      />
      <ExpandableCard
        title="What are Live Practices?"
        response={
          <div className={styles['response']}>
            <p>Live Practices are one-on-one sessions that take place over video call with a native language speaker, your coach.</p>
            <p>This is where you put your language skills into practice via activities based on real-life situations.</p>
          </div>
        }
      />
      <ExpandableCard
        title="What are 'Mornings', 'Afternoons', 'Evenings?"
        response={
          <div className={styles['response']}>
            <p>
              These are the rough times that coaches are available. Their exact schedules are visible when you go to book a session with them. Have a
              think about the time that works best for you but there is always an opportunity to switch.
            </p>
            <p>
              Don&apos;t worry - Chatterbox coaches are located all over the world so there should always be an incredible coach ready to practise
              when you are.
            </p>
          </div>
        }
      />
      <ExpandableCard
        title="Can I change coach in the future?"
        response={
          <p>
            Yes! Whilst we recommend sticking with the same coach once you&apos;ve found one that suits you, you do have the option to switch coaches.
          </p>
        }
      />
      <ExpandableCard
        title="What do I need to do to prepare before my Meet Your Coach session?"
        response={
          <div className={styles['response']}>
            <p>
              You&apos;ll first meet your coach for a 15-minute chat. There is nothing for you to prepare so we&apos;d recommend getting this booked
              in as soon as possible.
            </p>
            <p>
              After that Live Practice sessions last around 30 minutes. They are all about putting into practice what you have learnt in self-study
              with a native speaker of the language. We recommend that you do some self-study before each Live Practice.
            </p>
          </div>
        }
      />
    </div>
  );
};

Information.propTypes = {
  componentRef: PropTypes.object
};

export default Information;
