import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../design-system/button/Button';
import { actions } from '../../../../domains/helpdesk/chat';

import styles from './SelectAction.module.scss';

const SelectAction = ({ setAction }) => {
  const [_action, _setAction] = useState('');

  const handleClick = (tag) => {
    _setAction(tag);
    setAction(tag);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles['buttons-container']}>
        <Button
          outfit={_action && _action === actions.newRequest ? 'lightGrayButtonWithoutVisualDisable' : 'greenButtonWithoutVisualDisable'}
          disabled={Boolean(_action)}
          onClick={() => handleClick(actions.newRequest)}>
          Create new request
        </Button>
      </div>
    </div>
  );
};

SelectAction.propTypes = {
  action: PropTypes.string,
  setAction: PropTypes.func.isRequired
};

export default SelectAction;
