import instance from '..';

export default class ChatAPI {
  static async getChatToken({ token, userId }) {
    return instance.get(`/api/users/${userId}/chat_token/`, {
      headers: { Authorization: `Token ${token}` }
    });
  }
  static async sendMessage({ token, channelId, message }) {
    return instance.post(`/api/users/${channelId}/add_message/`, message, {
      headers: { Authorization: `Token ${token}` }
    });
  }
  static async patchLanguageHelperDuration(token, data) {
    return instance.patch('/api/add_language_helper_session_duration/', data, {
      headers: { Authorization: `Token ${token}` }
    });
  }
  static async getChatsToJoin(token) {
    return instance.get('/api/chats_to_join/', {
      headers: { Authorization: `Token ${token}` }
    });
  }
  static async getConversationUsers({ token, conversationUniqueName }) {
    return instance.get(`/api/users/${conversationUniqueName}/chat_users`, {
      headers: { Authorization: `Token ${token}` }
    });
  }
  static async removeChats({ token, data }) {
    return instance.patch('/api/chats_to_remove/', data, {
      headers: { Authorization: `Token ${token}` }
    });
  }
}
