import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from '../Row.module.scss';

import { convertStringsOfDateAndTimeToDate, convertToLocaleAndFormat } from '../../../utils/time-helpers';

import { videoPlatformsKeys } from '../../../domains/user/video-platforms';
import { capitalize } from '../../../utils/string-helpers';
import { getCourseLevelFromName } from '../../../domains/course/course';
import { appointmentStatus } from '../../../domains/appointments/status';

const Row = ({ appointment, timezone, user }) => {
  const getApptDate = () => {
    const apptDate = getApptFullDate();
    return <p>{convertToLocaleAndFormat(apptDate, timezone, 'yyyy-MM-dd')}</p>;
  };

  const getApptFullDate = () => {
    return convertStringsOfDateAndTimeToDate(appointment?.date, appointment?.start_time);
  };

  const getTimezoned = () => {
    const apptDate = getApptFullDate();
    return convertToLocaleAndFormat(apptDate, timezone, 'HH:mm');
  };

  const getApptDuration = () => {
    return <p>{appointment?.duration} min</p>;
  };

  const getStudentPreferredPlatform = () => {
    if (!appointment?.students?.length) return '';
    return appointment.students?.[0]?.preferred_class;
  };

  const getPreferredPlatform = () => {
    const preferredPlatform = getStudentPreferredPlatform(appointment);
    return {
      preferredPlatform,
      link: user[videoPlatformsKeys[preferredPlatform]]
    };
  };

  const getPlatformName = () => {
    const { preferredPlatform } = getPreferredPlatform();

    return <p className={styles['status-blocked']}>{capitalize(preferredPlatform)}</p>;
  };

  const getStudentFullName = () => {
    return `${appointment?.students?.[0]?.first_name} ${appointment?.students?.[0]?.last_name}`;
  };

  const removeVersionFromCourseName = (courseName) => {
    // Remove " 2.0" from the end of the string using replace
    let cleanedCourseName = courseName.replace(' 2.0', '');

    // Trim any trailing spaces from the string
    cleanedCourseName = cleanedCourseName.trim();

    return cleanedCourseName;
  };

  const getNameToBeDisplayed = (appt) => {
    if (!appt) return '';
    if ((appt?.appointment_type_name?.toLowerCase() || '').includes('introductory class')) return 'Meet Your Coach';
    if ((appt?.appointment_type_name?.toLowerCase() || '').includes('conversation'))
      return `${removeVersionFromCourseName(appointment?.course_name)} - Convo Practice`;
    return `${getCourseLevelFromName(appointment?.course_name)}: Lesson ${appointment?.lesson_number}`;
  };

  const getResourceLink = () => {
    if (appointment?.status === appointmentStatus.canceledLate) return <p className={styles['status-cancelled']}>Cancelled Late</p>;
    const nameDisplayed = getNameToBeDisplayed(appointment);

    if (appointment?.resource_url) {
      if (appointment?.resource_url.startsWith('http')) {
        return (
          <a href={`${appointment?.resource_url}`} target="_blank" rel="noreferrer noopener">
            {nameDisplayed}
          </a>
        );
      } else {
        return (
          <Link to={`${appointment?.resource_url}`} target="_blank" rel="noreferrer noopener">
            {nameDisplayed}
          </Link>
        );
      }
    }

    return (
      <Link to={`/resources/${appointment?.course_id}/${appointment?.lesson_number}`} target="_blank" rel="noreferrer noopener">
        {nameDisplayed}
      </Link>
    );
  };

  return (
    <tr className={styles.row}>
      <td>
        <p>{getApptDate()}</p>
      </td>
      <td>
        <p>{getTimezoned()}</p>
      </td>
      <td>
        <p>{getApptDuration()}</p>
      </td>
      <td>
        <p>{getPlatformName()}</p>
      </td>
      <td>
        <p>{getStudentFullName()}</p>
      </td>
      <td>{getResourceLink()}</td>
    </tr>
  );
};

Row.propTypes = {
  appointment: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  user: PropTypes.object
};

export default Row;
