import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { cleanErrorState, bookIntroductoryClass, cleanPreviousState, setOpenBookingIntroClassModal } from '../../../../actions/new-booking';

import SessionsManagementModal from '../SessionsManagementModal';
import BookingSummaryItem from '../BookingSummaryItem';
import ErrorToast from '../../../design-system/toast/errorToast/ErrorToast';
import mixpanel from '../../../../utils/mixpanel-helper';
import { getDateFromIsoString, getTimeFromIsoString } from '../../../../utils/time-helpers';
import { getIntroClass } from '../../../../actions/sessions';
import useInitRecording from '../../../../hooks/useInitRecording';
import { selectProductType } from '../../../../reducers/order-info';
import { CLASS_TYPES } from '../../Enum';

const IntroductoryLessonBookingModal = ({
  activeOrders,
  bookIntroductoryClassAction,
  cleanPreviousStateAction,
  cleanErrorStateAction,
  enableCoachChangeWhenUnavailable,
  errorMessage,
  getIntroClassAction,
  hasErrorOnBookingIntroClass,
  isLoadingAvailability,
  productType,
  selectedLanguage,
  setOpenBookingIntroClassModalAction,
  isCreatingIntroClassAppointment,
  selectedTutor,
  selectedTimestamp,
  timezone,
  user
}) => {
  const introSession = {};
  const [endRecordingWholePage] = useInitRecording((timeSpent) =>
    mixpanel.lessonAction('Booked MYC', {
      timeSpent,
      type: 'myc'
    })
  );

  const history = useHistory();

  const redirect = (url) => {
    history.push(url);
  };

  const handleCloseModal = () => {
    cleanPreviousStateAction();
  };

  const handleAppointmentCreation = () => {
    const appointmentData = {
      intro_class: true,
      language: selectedLanguage?.language,
      start_time: getTimeFromIsoString(selectedTimestamp),
      date: getDateFromIsoString(selectedTimestamp),
      tutor_id: selectedTutor.id,
      time_zone: timezone
    };

    bookIntroductoryClassAction({
      token: user.token,
      appointmentData,
      successCallback: () => {
        endRecordingWholePage();
        if (!activeOrders[0]?.order_appointment_types) return;
        const { language_id: languageId } = activeOrders[0].order_appointment_types[0];
        if (languageId) getIntroClassAction({ token: user.token, languageId });
        redirect('/study-area');
        setOpenBookingIntroClassModalAction(false);
      }
    });
  };

  return (
    <>
      <SessionsManagementModal
        calendarSubtitle=""
        disabledActionButton={!selectedTimestamp}
        enableCoachChangeWhenUnavailable={enableCoachChangeWhenUnavailable}
        futureSchedule={[introSession]}
        handleExecution={handleAppointmentCreation}
        isExecuting={isCreatingIntroClassAppointment}
        isLoading={isLoadingAvailability}
        type={CLASS_TYPES.myc}
        modalTitle="Book your Meet Your Coach session"
        modalSubtitle={<span>Get to know your coach in a 15-minute call. You can only have one Meet Your Coach session during your course.</span>}
        onCloseModal={() => {
          handleCloseModal();
          mixpanel.closedModal('Introductory Lesson Booking');
        }}
        productType={productType}
        renderAppointmentItem={(lesson, index) => (
          <BookingSummaryItem active introClass key={index} lesson={lesson} timezone={timezone} finalTimestamp={selectedTimestamp} />
        )}
        selectedTimestamp={selectedTimestamp}
        shouldDetectTimezone
        shouldShowLegend={false}
        testId="booking-intro-class"
      />
      <ErrorToast errorMsg={errorMessage} show={hasErrorOnBookingIntroClass} onClose={cleanErrorStateAction} />
    </>
  );
};

const mapStateToProps = (state) => ({
  activeOrders: state.orderInfo?.active_orders,
  errorMessage: state.newBooking.bookingLessons.error,
  hasErrorOnBookingIntroClass: state.newBooking.bookingLessons.hasErrorOnBookingIntroClass,
  isCreatingIntroClassAppointment: state.newBooking.bookingLessons.isCreatingIntroClassAppointment,
  isLoadingAvailability: state.bookable.available.isLoadingAvailability,
  productType: selectProductType(state.orderInfo, CLASS_TYPES.tuition),
  selectedLanguage: state.selectedLanguage,
  selectedTimestamp: state.bookable.available.calendar?.selectedTimestamp,
  selectedTutor: state.selectedTutor.selectedTutor,
  timezone: state.time.timezone,
  user: state.user
});

IntroductoryLessonBookingModal.propTypes = {
  activeOrders: PropTypes.array,
  enableCoachChangeWhenUnavailable: PropTypes.bool,
  cleanPreviousStateAction: PropTypes.func.isRequired,
  cleanErrorStateAction: PropTypes.func.isRequired,
  bookIntroductoryClassAction: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  getIntroClassAction: PropTypes.func.isRequired,
  hasErrorOnBookingIntroClass: PropTypes.bool.isRequired,
  isCreatingIntroClassAppointment: PropTypes.bool,
  isLoadingAvailability: PropTypes.bool,
  productType: PropTypes.object.isRequired,
  redirectTo: PropTypes.string,
  selectedLanguage: PropTypes.object,
  selectedTimestamp: PropTypes.string,
  selectedTutor: PropTypes.object.isRequired,
  setOpenBookingIntroClassModalAction: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  user: PropTypes.object
};

IntroductoryLessonBookingModal.defaultProps = {
  isCreatingIntroClassAppointment: false,
  redirectTo: null
};

export default connect(mapStateToProps, {
  bookIntroductoryClassAction: bookIntroductoryClass,
  cleanErrorStateAction: cleanErrorState,
  cleanPreviousStateAction: cleanPreviousState,
  getIntroClassAction: getIntroClass,
  setOpenBookingIntroClassModalAction: setOpenBookingIntroClassModal
})(IntroductoryLessonBookingModal);
