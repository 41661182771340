import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from '../../style/containers/StudyArea.module.scss';
import WeeklyCourseSchedule from './WeeklyCourseSchedule/WeeklyCourseSchedule';
import CourseSelector from './CourseSelector/CourseSelector';
import LoadingSpinner from '../design-system/loading/spinner/LoadingSpinner';
import TabSelector from './TabSelector/TabSelector';
import CourseDetailsMobile from './CourseDetails/CourseDetailsMobile';
import { setUGWalkthroughSeen } from '../../actions/user';
import PlacementTest from './PlacementTest/PlacementTest';

const NewStudyAreaMobile = ({ hasFinishedPlacementTest, isGettingEnrolledCourses, isRetrievingPlacementTest }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const onUnitSelection = () => setSelectedTabIndex(2);
  const sendToPlacementTest = () => setSelectedTabIndex(1);
  const sendToLessonsTab = () => {
    setSelectedTabIndex(2);
  };

  const TABS_BEFORE_PLACEMENT_TEST = Object.freeze({
    summary: <WeeklyCourseSchedule sendToPlacementTest={sendToPlacementTest} />,
    'placement test': <PlacementTest />
  });

  const TABS_AFTER_PLACEMENT_TEST = Object.freeze({
    summary: <WeeklyCourseSchedule sendToLessonsTab={sendToLessonsTab} isMobile />,
    course: <CourseDetailsMobile tab="course" onUnitSelection={onUnitSelection} />,
    lessons: <CourseDetailsMobile tab="lessons" />
  });

  const [availableTabs, setAvailableTabs] = useState(TABS_AFTER_PLACEMENT_TEST);

  useEffect(() => {
    if (!hasFinishedPlacementTest) {
      setAvailableTabs(TABS_BEFORE_PLACEMENT_TEST);
    } else {
      setAvailableTabs(TABS_AFTER_PLACEMENT_TEST);
    }
  }, [hasFinishedPlacementTest]);

  const getTabName = () => Object.keys(availableTabs)[selectedTabIndex];

  return (
    <div className={styles.pageContainer}>
      <CourseSelector />
      <TabSelector
        isLoading={isGettingEnrolledCourses && isRetrievingPlacementTest}
        onTabSelection={setSelectedTabIndex}
        selectedTab={selectedTabIndex}
        tabs={Object.keys(availableTabs)}
      />
      {isGettingEnrolledCourses && isRetrievingPlacementTest ? (
        <div className={styles.loadingWrapper}>
          <LoadingSpinner />
        </div>
      ) : (
        availableTabs[getTabName(selectedTabIndex)]
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  hasFinishedPlacementTest: state.newOnboarding.placementTest.hasFinishedPlacementTest,
  isGettingEnrolledCourses: state.courses.enrolledCourses.isGettingEnrolledCourses,
  isRetrievingPlacementTest: state.newOnboarding.placementTest.isRetrievingPlacementTest,
  previousOnboarding: state.newOnboarding.previousOnboarding.previousOnboarding,
  selectedCourse: state.courses?.selectedCourseDetails?.courseDetails,
  student: state.user
});

NewStudyAreaMobile.propTypes = {
  hasFinishedPlacementTest: PropTypes.bool,
  isGettingEnrolledCourses: PropTypes.bool.isRequired,
  isRetrievingPlacementTest: PropTypes.bool.isRequired,
  selectedCourse: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired
};

export default connect(mapStateToProps, {
  setUGWalkthroughSeenAction: setUGWalkthroughSeen
})(NewStudyAreaMobile);
