import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { isMobileOnly } from 'react-device-detect';
import { Glyphicon } from 'react-bootstrap';

import Modal from '../components/design-system/modal/Modal';

import styles from '../style/containers/StudyAreaAssignment.module.scss';
import appStyles from '../style/containers/App.module.scss';
import { setCurrentCeregoAssignment } from '../actions/assignments';
import { getLessonsAssignmentsLiveProgress } from '../actions/lessons';
import Button from '../components/design-system/button/Button';

const UNIQUE_STRING = 'ChatterboxIframe';

class StudyAreaAssignment extends Component {
  constructor() {
    super();
    this.iframeRef = React.createRef();
  }

  state = {
    isLoading: true,
    ceregoLoadModuleMessage: false,
    selectedResource: {},
    sideBarOpen: false,
    showContent: false,
    showTooltip: false,
    showBrainFreezeModal: true,
    showCeregoBanner: isMobileOnly
  };

  componentDidMount() {
    const visitedStudyAreaAssignment = sessionStorage.getItem('visitedStudyAreaAssignment');
    this.showTooltip(visitedStudyAreaAssignment === null);
    this.listenToCeregoMessages();
    this.shouldShowBrainFreezeModal();

    const { assignment } = this.props;

    if (assignment !== null) {
      const selectedLessonIndex = this.findSelectedLessonIndex();
      this.initializePage(selectedLessonIndex, assignment);

      // Add the iframe with a unique name
      const iframe = this.iframeRef.current;
      iframe.contentWindow.name = UNIQUE_STRING;

      this.openLtiIframe(assignment);
    } else {
      this.stopLoading();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.getCeregoResourceProgress(prevState, this.state);
    this.updateShouldShowBrainFreezeModal(prevProps, this.props);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.listenToCeregoMessages);
  }

  listenToCeregoMessages() {
    window.addEventListener('message', (evt) => {
      const { data } = evt;
      if (data?.messageType === 'load-module') this.setState({ ceregoLoadModuleMessage: true });
    });
  }

  getCeregoResourceProgress(prevState, currentState) {
    if (!prevState.ceregoLoadModuleMessage && currentState.ceregoLoadModuleMessage) {
      this.props.getLessonsAssignmentsLiveProgressAction({
        token: this.props.user.token,
        lessonId: this.getSelectedLesson()?.id,
        callback: () => this.setState({ ceregoLoadModuleMessage: false }),
        errorCallback: () => this.setState({ ceregoLoadModuleMessage: false })
      });
    }
  }

  onSelectResource(index, resIndex) {
    const { courseDetails } = this.props;
    const { selectedLessonIndex } = this.state;
    const lesson = selectedLessonIndex !== -1 ? courseDetails.lessons[selectedLessonIndex] : undefined;
    const selectedResource = lesson ? lesson.resources.filter((r) => r.type === 'cerego')[resIndex] : undefined;

    this.setState({
      selectedResource
    });

    if (selectedResource.type === 'cerego') {
      this.openLtiIframe(selectedResource);
      this.props.setCurrentCeregoAssignmentAction(selectedResource);
    } else if (lesson) {
      const open = window.open(lesson.url, lesson.type !== 'assessment' ? '_blank' : '_self');
      open.focus();
    }

    this.toggleSideBar();
  }

  onSelectUnit(u) {
    this.setState({ selectedLessonIndex: u });
  }

  showTooltip(showTooltip) {
    this.setState({ showTooltip });
  }

  findSelectedLessonIndex() {
    const { courseDetails, assignment } = this.props;
    if (courseDetails && courseDetails.lessons) {
      const selectedLessonIndex = courseDetails.lessons.findIndex((l) => {
        const selectedResource = l.resources.findIndex((r) => r.id === assignment.id);
        return selectedResource !== -1;
      });
      return selectedLessonIndex;
    }
    return -1;
  }

  getSelectedLesson() {
    const { courseDetails } = this.props;
    const { selectedLessonIndex } = this.state;
    return courseDetails?.lessons.filter((_, index) => selectedLessonIndex === index)[0] || {};
  }

  stopLoading() {
    this.setState({ isLoading: false });
  }

  toggleSideBar() {
    if (!this.state.sideBarOpen) {
      setTimeout(() => {
        this.setState({
          showContent: true
        });
      }, 150);
    } else {
      this.setState({
        showContent: false
      });
    }
    this.setState({
      sideBarOpen: !this.state.sideBarOpen,
      showTooltip: false
    });
    sessionStorage.setItem('visitedStudyAreaAssignment', true);
  }

  initializePage(selectedLessonIndex, assignment) {
    this.setState({
      selectedLessonIndex,
      selectedResource: assignment
    });
  }

  openLtiIframe(assignment) {
    const context = {
      assignment_id: assignment.id
    };

    const { user } = this.props;
    const headers = { Authorization: `Token ${user.token}` };
    axios.post(`/api/users/${user.id}/sign_lti_call/`, context, { headers }).then((response) => {
      const preppedRequest = response.data.prepped_request;
      const requestData = response.data.request_data;

      // Build the form
      const form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('action', preppedRequest.url);
      form.setAttribute('target', UNIQUE_STRING);
      form.setAttribute('enctype', 'application/x-www-form-urlencoded');

      // add the parameters to the form
      requestData.forEach((entry) => {
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', entry[0]);
        input.setAttribute('value', entry[1]);
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
      this.stopLoading();
    });
  }

  closeCeregoBanner() {
    this.setState({ showCeregoBanner: false });
  }

  shouldShowBrainFreezeModal() {
    this.setState({ showBrainFreezeModal: Boolean(this.props?.assignment?.seeNextAt) });
  }

  updateShouldShowBrainFreezeModal(prevProps, currentProps) {
    if (!prevProps.assignment?.seeNextAt && currentProps.assignment?.seeNextAt) {
      this.shouldShowBrainFreezeModal();
    }
  }

  closeBrainFreezeModal() {
    this.setState({ showBrainFreezeModal: false });
  }

  render() {
    const { isLoading, sideBarOpen, selectedLessonIndex, showCeregoBanner } = this.state;
    const { user, courseDetails } = this.props;
    const { selectedResource } = this.state;
    const noAssignment = selectedResource === null;
    const lessons = courseDetails ? courseDetails.lessons : [];
    return (
      <>
        {showCeregoBanner && (
          <div className={styles.ceregoBanner}>
            <span className={styles.ceregoCopy}>
              For a mobile-optimized learning experience <a href="http://onelink.to/9d3wef">download the Cerego app</a>.
            </span>
            <span
              className={styles.closeButton}
              onClick={this.closeCeregoBanner.bind(this)}
              onKeyDown={this.closeCeregoBanner.bind(this)}
              role="button"
              tabIndex={0}>
              <Glyphicon glyph="remove" />
            </span>
          </div>
        )}
        <div className={[styles.sideBar, sideBarOpen ? styles.sideBarOpen : styles.sideBarClosed].join(' ')}>
          <div className={styles.iconWrapper} onClick={this.toggleSideBar.bind(this)} onKeyPress={() => {}} role="button" tabIndex="0">
            <div className={[styles.menuIcon, this.state.sideBarOpen ? styles.open : undefined].join(' ')}>
              <span />
              <span />
              <span />
              <span />
            </div>
            {this.state.showContent && <span className={styles.closeMenu}>Close menu</span>}
          </div>
          {this.state.showContent && (
            <>
              <div className={[styles.lessonsWrapper, this.state.showContent ? styles.visible : styles.invisible].join(' ')}>
                {lessons
                  ? lessons.map((l, index) => (
                      <>
                        <div
                          className={[
                            styles.lessonNameWrapper,
                            selectedLessonIndex === index ? styles.lessonNameWrapperSelected : undefined,
                            l.number > courseDetails.next_lesson_to_study ? styles.lessonNameWrapperDisabled : undefined
                          ].join(' ')}
                          onClick={l.number <= courseDetails.next_lesson_to_study ? this.onSelectUnit.bind(this, index) : () => {}}
                          onKeyPress={l.number <= courseDetails.next_lesson_to_study ? this.onSelectUnit.bind(this, index) : () => {}}
                          role="button"
                          tabIndex="0">
                          <div className={styles.lessonIcon} />
                          {l.type.toLowerCase() === 'lesson' && <>Lesson {l.number}:</>} {l.name ? l.name : l.title}
                        </div>
                        {selectedLessonIndex === index &&
                          l.resources
                            .filter((r) => {
                              if (user.user_type === 'student') {
                                return r.user_type === user.user_type;
                              }
                              return true;
                            })
                            .map((r, resIndex) => (
                              <>
                                {r?.type === 'cerego' && (
                                  <div
                                    className={[
                                      styles.resourceNameWrapper,
                                      r?.id === selectedResource?.id ? styles.resourceSelected : undefined
                                    ].join(' ')}
                                    onClick={this.onSelectResource.bind(this, index, resIndex)}
                                    onKeyPress={() => {}}
                                    role="button"
                                    tabIndex="0">
                                    {r?.id === selectedResource?.id && <div className={styles.lessonIcon} />}
                                    {r?.id !== selectedResource?.id && <div className={styles.iconPlaceholder} />}
                                    {`${l?.number?.toString()}.${(resIndex + 1).toString()}: ${r?.name}`}
                                  </div>
                                )}
                              </>
                            ))}
                        {selectedLessonIndex === index && <div className={styles.resourceBottomPadding} />}
                      </>
                    ))
                  : undefined}
              </div>
            </>
          )}
          <div className={[styles.sidebarPadding, sideBarOpen ? styles.paddingOpen : styles.paddingClosed].join(' ')} />
        </div>
        {this.state.showTooltip && (
          <div
            className={[styles.tooltip, this.state.showTooltip ? undefined : styles.hideTooltip].join(' ')}
            onMouseEnter={() => {
              this.setState({ showTooltip: false });
              sessionStorage.setItem('visitedStudyAreaAssignment', true);
            }}>
            View all assignments
          </div>
        )}
        <div className={styles.iframeWrapper}>
          {isLoading && <div className={[appStyles.loader, styles.iframeLoader].join(' ')} />}
          {this.state.showBrainFreezeModal && (
            <Modal
              onClose={this.closeBrainFreezeModal.bind(this)}
              content={
                <div className={styles.modalWrapper}>
                  <h3>Why do I need to take a break?</h3>
                  <p>
                    🧠 Well done, you have reached the <span className={styles.italic}>brain freeze</span>! Cerego encourages you to take a break and
                    come back to review the assignment another day. This helps the concepts stay in your <strong>long-term memory</strong>!
                  </p>
                  <p>
                    💡 You have <strong>two options</strong> here:
                    <li>
                      - Click <span className={styles.italic}>study another assignment </span>to move on to the next assignment in this lesson;
                    </li>
                    <li>
                      - Click <span className={styles.italic}>I want to keep learning this assignment</span>, then review anyway to continue studying
                      this assignment.
                    </li>
                  </p>
                  <p>
                    ✍ You can continue to review this assignment now as many times as you want, but be aware that your{' '}
                    <strong>progress will not increase</strong>. To reach 100%, you need to come back and review it on a different day!
                  </p>
                  <Button outfit="greenButton" onClick={this.closeBrainFreezeModal.bind(this)}>
                    Ok!
                  </Button>
                </div>
              }
            />
          )}
          {noAssignment && (
            <p className={styles.noAssignmentCopy}>
              No assignment has been selected, please go back to the
              <a href="/study-area">Study Area</a>
              and select which assigment you would like to complete.
            </p>
          )}
          {!noAssignment && <iframe ref={this.iframeRef} style={{ width: '100%', height: isLoading ? '0' : '100vh' }} title="Cerego iframe" />}
        </div>
      </>
    );
  }
}

StudyAreaAssignment.propTypes = {
  user: PropTypes.object.isRequired,
  assignment: PropTypes.object.isRequired,
  courseDetails: PropTypes.object.isRequired,
  getLessonsAssignmentsLiveProgressAction: PropTypes.func.isRequired,
  setCurrentCeregoAssignmentAction: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user,
    assignment: state.currentCeregoAssignment,
    courseDetails: state.courses?.selectedCourseDetails?.courseDetails
  };
}

export default connect(mapStateToProps, {
  getLessonsAssignmentsLiveProgressAction: getLessonsAssignmentsLiveProgress,
  setCurrentCeregoAssignmentAction: setCurrentCeregoAssignment
})(StudyAreaAssignment);
