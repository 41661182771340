import NewBookingAPI from '../services/new-booking';
import { actionCreatorsFactory, asyncActionType, createActionTypes } from '../utils/action-helpers';
import { getSessionsStatus } from './sessions';
import { CLEAN_PREVIOUS_STATE } from './types';
import { sendSentryError } from '../setup/sentry';

export const NewBookingActionTypes = createActionTypes('NewBooking', [
  asyncActionType('CREATING_NEW_APPOINTMENT'),
  asyncActionType('RESCHEDULING_APPOINTMENT'),
  asyncActionType('CANCELING_APPOINTMENT'),
  asyncActionType('BOOK_INTRO_CLASS'),
  asyncActionType('BOOK_CONVERSATION_CLASS'),
  asyncActionType('RESCHEDULE_INTRO_CLASS'),
  asyncActionType('CANCEL_INTRO_CLASS'),
  asyncActionType('BOOK_CONVERSATION'),
  asyncActionType('RESCHEDULE_CONVERSATION'),
  asyncActionType('CANCEL_CONVERSATION'),
  'OPEN_BOOKING_MODAL',
  'CLOSE_BOOKING_MODAL',
  'OPEN_RESCHEDULING_MODAL',
  'CLOSE_RESCHEDULING_MODAL',
  'CLEAN_RESCHEDULING_MODAL',
  'OPEN_CANCELING_MODAL',
  'CLOSE_CANCELING_MODAL',
  'OPEN_BOOKING_INTRO_CLASS_MODAL',
  'OPEN_BOOKING_CONVERSATION_MODAL',
  'CLOSE_BOOKING_CONVERSATION_MODAL',
  'PRE_BOOK_RESCHEDULE_DATE',
  'CLEAN_SUCCESS',
  'SET_BULKING_STATE',
  'SET_SHOW_OPTIONS',
  'SET_SELECTED_ACTION',
  'SET_IS_FIRST_BOOKING',
  'ADD_TO_BOOKING_SELECTED_TIMESTAMP',
  'REMOVE_FROM_BOOKING_SELECTED_TIMESTAMP',
  'ADD_TO_BOOKING_CONVO_SELECTED_TIMESTAMP',
  'REMOVE_FROM_BOOKING_CONVO_SELECTED_TIMESTAMP',
  'ADD_TO_RESCHEDULING_LIST',
  'ADD_TO_CANCELING_LIST',
  'CLEAR_ERRORS'
]);

export const openBookingModal = () => ({
  type: NewBookingActionTypes.OPEN_BOOKING_MODAL
});

export const closeBookingModal = () => ({
  type: NewBookingActionTypes.CLOSE_BOOKING_MODAL
});

export const setOpenReschedulingModal = () => ({
  type: NewBookingActionTypes.OPEN_RESCHEDULING_MODAL
});

export const closeReschedulingModal = () => ({
  type: NewBookingActionTypes.CLOSE_RESCHEDULING_MODAL
});

export const cleanReschedulingModal = () => ({
  type: NewBookingActionTypes.CLEAN_RESCHEDULING_MODAL
});

export const setOpenCancelingModal = () => ({
  type: NewBookingActionTypes.OPEN_CANCELING_MODAL
});

export const closeCancelingModal = () => ({
  type: NewBookingActionTypes.CLOSE_CANCELING_MODAL
});

export const addToBookingSelectedTimestamp = (timestamp) => ({
  type: NewBookingActionTypes.ADD_TO_BOOKING_SELECTED_TIMESTAMP,
  payload: timestamp
});

export const removeFromBookingSelectedTimestamp = (timestamp) => ({
  type: NewBookingActionTypes.REMOVE_FROM_BOOKING_SELECTED_TIMESTAMP,
  payload: timestamp
});

export const addToBookingConvoSelectedTimestamp = (timestamp) => ({
  type: NewBookingActionTypes.ADD_TO_BOOKING_CONVO_SELECTED_TIMESTAMP,
  payload: timestamp
});

export const removeFromBookingConvoSelectedTimestamp = (timestamp) => ({
  type: NewBookingActionTypes.REMOVE_FROM_BOOKING_CONVO_SELECTED_TIMESTAMP,
  payload: timestamp
});

export const cleanPreviousState = () => ({
  type: CLEAN_PREVIOUS_STATE
});

export const cleanErrorState = () => ({
  type: NewBookingActionTypes.CLEAR_ERRORS
});

const extractErrorMessage = (error, defaultMessage) => {
  if (!error.response) return defaultMessage;

  const isInternalError = String(error.response.status).startsWith('5');
  if (!error.response.data || isInternalError) return defaultMessage;

  return error.response.data[0] || defaultMessage;
};

const createAppointmentAndGetUpdatedStudentsBookingActions = actionCreatorsFactory(
  [
    NewBookingActionTypes.CREATING_NEW_APPOINTMENT,
    NewBookingActionTypes.CREATING_NEW_APPOINTMENT_SUCCESS,
    NewBookingActionTypes.CREATING_NEW_APPOINTMENT_FAIL
  ],
  'createAppointmentAndUpdatedBooking'
);

export const createAppointmentAndGetUpdatedStudentsBooking = (studentToken, courseId, data, successCallback) => async (dispatch) => {
  try {
    dispatch(cleanErrorState());
    dispatch(createAppointmentAndGetUpdatedStudentsBookingActions.createAppointmentAndUpdatedBooking());
    await NewBookingAPI.createStudentBooking(studentToken, data);
    dispatch(getSessionsStatus(studentToken, courseId));
    dispatch(setBulkingState(false));
    dispatch(setShowOptions(false));
    dispatch(setSelectedAction(''));
    dispatch(setIsFirstBooking(data));
    dispatch(createAppointmentAndGetUpdatedStudentsBookingActions.createAppointmentAndUpdatedBookingSuccess());
    if (successCallback) successCallback();
  } catch (err) {
    sendSentryError({ err, context: 'createAppointmentAndGetUpdatedStudentsBooking' });
    const errorMessage = extractErrorMessage(err, 'Something went wrong while booking. Please, try again later.');
    dispatch(createAppointmentAndGetUpdatedStudentsBookingActions.createAppointmentAndUpdatedBookingFail(errorMessage));
  }
};

const createAppointmentAndRedirectToAnotherPageActions = actionCreatorsFactory(
  [
    NewBookingActionTypes.CREATING_NEW_APPOINTMENT,
    NewBookingActionTypes.CREATING_NEW_APPOINTMENT_SUCCESS,
    NewBookingActionTypes.CREATING_NEW_APPOINTMENT_FAIL
  ],
  'createAppointmentAndRedirect'
);

export const createAppointmentAndRedirectToAnotherPage =
  ({ studentToken, data, redirectFunction, successCallback }) =>
  async (dispatch) => {
    try {
      dispatch(cleanErrorState());
      dispatch(createAppointmentAndRedirectToAnotherPageActions.createAppointmentAndRedirect());
      await NewBookingAPI.createStudentBooking(studentToken, data);
      if (successCallback) successCallback();
      dispatch(setBulkingState(false));
      dispatch(setShowOptions(false));
      dispatch(setSelectedAction(''));
      redirectFunction();
      dispatch(createAppointmentAndRedirectToAnotherPageActions.createAppointmentAndRedirectSucess());
    } catch (err) {
      sendSentryError({ err, context: 'createAppointmentAndRedirectToAnotherPage' });
      const errorMessage = extractErrorMessage(err, 'Something went wrong while booking. Please, try again later.');
      dispatch(createAppointmentAndRedirectToAnotherPageActions.createAppointmentAndRedirectFail(errorMessage));
    }
  };

const bookConversationClassActions = actionCreatorsFactory(
  [
    NewBookingActionTypes.BOOK_CONVERSATION_CLASS,
    NewBookingActionTypes.BOOK_CONVERSATION_CLASS_SUCCESS,
    NewBookingActionTypes.BOOK_CONVERSATION_CLASS_FAIL
  ],
  'bookConversationClass'
);

export const bookConversationClass = ({ appointmentData, courseId, successCallback, token }) => {
  return async (dispatch) => {
    try {
      dispatch(cleanErrorState());
      dispatch(bookConversationClassActions.bookConversationClass());
      await NewBookingAPI.createStudentBooking(token, appointmentData);
      dispatch(bookConversationClassActions.bookConversationClassSuccess());
      dispatch(getSessionsStatus(token, courseId));
      dispatch(setBulkingState(false));
      dispatch(setShowOptions(false));
      dispatch(setSelectedAction(''));
      if (successCallback) successCallback();
    } catch (err) {
      sendSentryError({ err, context: 'bookConversationClass' });
      const errorMessage = extractErrorMessage(err, 'Something went wrong while booking your Conversation session.');
      dispatch(bookConversationClassActions.bookConversationClassFail(errorMessage));
    }
  };
};

const bookIntroClassActions = actionCreatorsFactory(
  [NewBookingActionTypes.BOOK_INTRO_CLASS, NewBookingActionTypes.BOOK_INTRO_CLASS_SUCCESS, NewBookingActionTypes.BOOK_INTRO_CLASS_FAIL],
  'bookIntroClass'
);

export const bookIntroductoryClass = ({ appointmentData, successCallback, token }) => {
  return async (dispatch) => {
    try {
      dispatch(cleanErrorState());
      dispatch(bookIntroClassActions.bookIntroClass());
      await NewBookingAPI.createStudentBooking(token, appointmentData);
      dispatch(bookIntroClassActions.bookIntroClassSuccess());
      if (successCallback) successCallback();
    } catch (err) {
      sendSentryError({ err, context: 'bookIntroductoryClass' });
      const errorMessage = extractErrorMessage(err, 'Something went wrong while booking your Meet Your Coach session.');
      dispatch(bookIntroClassActions.bookIntroClassFail(errorMessage));
    }
  };
};

export const addToReschedulingList = (payload) => ({
  type: NewBookingActionTypes.ADD_TO_RESCHEDULING_LIST,
  payload
});

const rescheduleBulkAppointmentsAndUpdateActions = actionCreatorsFactory(
  [
    NewBookingActionTypes.RESCHEDULING_APPOINTMENT,
    NewBookingActionTypes.RESCHEDULING_APPOINTMENT_SUCCESS,
    NewBookingActionTypes.RESCHEDULING_APPOINTMENT_FAIL
  ],
  'rescheduleBulkAndUpdate'
);

export const rescheduleBulkAppointmentsAndGetUpdatedStudentsBooking =
  (studentToken, courseId, appointmentsArray, successCallback) => async (dispatch) => {
    try {
      dispatch(rescheduleBulkAppointmentsAndUpdateActions.rescheduleBulkAndUpdate());
      await NewBookingAPI.updateMultipleStudentBooking(studentToken, appointmentsArray);
      dispatch(setOpenReschedulingModal(false));
      dispatch(getSessionsStatus(studentToken, courseId));
      dispatch(setBulkingState(false));
      dispatch(setShowOptions(false));
      dispatch(setSelectedAction(''));
      dispatch(rescheduleBulkAppointmentsAndUpdateActions.rescheduleBulkAndUpdateSuccess());
      if (successCallback) successCallback();
    } catch (err) {
      sendSentryError({ err, context: 'rescheduleBulkAppointmentsAndGetUpdatedStudentsBooking' });
      const errorMessage = extractErrorMessage(err, 'Something went wrong while rescheduling.');
      dispatch(rescheduleBulkAppointmentsAndUpdateActions.rescheduleBulkAndUpdateFail(errorMessage));
    }
  };

const rescheduleIntroClassActions = actionCreatorsFactory(
  [
    NewBookingActionTypes.RESCHEDULE_INTRO_CLASS,
    NewBookingActionTypes.RESCHEDULE_INTRO_CLASS_SUCCESS,
    NewBookingActionTypes.RESCHEDULE_INTRO_CLASS_FAIL
  ],
  'rescheduleIntroClass'
);

export const rescheduleIntroductoryClass = (studentToken, appointmentsArray, successCallback) => {
  return async (dispatch) => {
    try {
      dispatch(cleanErrorState());
      dispatch(rescheduleIntroClassActions.rescheduleIntroClass());
      await NewBookingAPI.updateStudentBooking(studentToken, appointmentsArray);
      dispatch(rescheduleIntroClassActions.rescheduleIntroClassSuccess());
      if (successCallback) successCallback();
    } catch (err) {
      sendSentryError({ err, context: 'rescheduleIntroductoryClass' });
      const errorMessage = extractErrorMessage(err, 'Something went wrong while booking your Meet Your Coach session.');
      dispatch(rescheduleIntroClassActions.rescheduleIntroClassFail(errorMessage));
    }
  };
};

export const addToCancelingList = (payload) => ({
  type: NewBookingActionTypes.ADD_TO_CANCELING_LIST,
  payload
});

const cancelAppointmentsActions = actionCreatorsFactory(
  [
    NewBookingActionTypes.CANCELING_APPOINTMENT,
    NewBookingActionTypes.CANCELING_APPOINTMENT_SUCCESS,
    NewBookingActionTypes.CANCELING_APPOINTMENT_FAIL
  ],
  'cancelAppointments'
);

export const cancelMultipleBookingsAndGetUpdatedStudentsBooking =
  ({ studentToken, courseId, appointmentsIds, successCallback, cancellationMotive, callback }) =>
  async (dispatch) => {
    try {
      dispatch(cancelAppointmentsActions.cancelAppointments());
      await NewBookingAPI.cancelMultipleBookings(studentToken, appointmentsIds, cancellationMotive);
      dispatch(getSessionsStatus(studentToken, courseId, callback));
      dispatch(setBulkingState(false));
      dispatch(setShowOptions(false));
      dispatch(setSelectedAction(''));
      dispatch(cancelAppointmentsActions.cancelAppointmentsSuccess());
      if (successCallback) successCallback();
    } catch (err) {
      sendSentryError({ err, context: 'cancelMultipleBookingsAndGetUpdatedStudentsBooking' });
      const errorMessage = extractErrorMessage(err, 'Something went wrong while cancelling.');
      dispatch(cancelAppointmentsActions.cancelAppointmentsFail(errorMessage));
    }
  };

const cancelIntroClassActions = actionCreatorsFactory(
  [NewBookingActionTypes.CANCEL_INTRO_CLASS, NewBookingActionTypes.CANCEL_INTRO_CLASS_SUCCESS, NewBookingActionTypes.CANCEL_INTRO_CLASS_FAIL],
  'cancelIntroClass'
);

export const cancelIntroductoryClass =
  ({ studentToken, appointmentsIds, cancellationMotive, successCallback }) =>
  async (dispatch) => {
    try {
      dispatch(cleanErrorState());
      dispatch(cancelIntroClassActions.cancelIntroClass());
      await NewBookingAPI.cancelMultipleBookings(studentToken, appointmentsIds, cancellationMotive);
      dispatch(cancelIntroClassActions.cancelIntroClassSuccess());
      if (successCallback) successCallback();
    } catch (err) {
      sendSentryError({ err, context: 'cancelIntroductoryClass' });
      const errorMessage = extractErrorMessage(err, 'Something went wrong while cancelling.');
      dispatch(cancelIntroClassActions.cancelIntroClassFail(errorMessage));
    }
  };

export const setOpenBookingIntroClassModal = (payload) => ({
  type: NewBookingActionTypes.OPEN_BOOKING_INTRO_CLASS_MODAL,
  payload
});

const bookConversationActions = actionCreatorsFactory(
  [NewBookingActionTypes.BOOK_CONVERSATION, NewBookingActionTypes.BOOK_CONVERSATION_SUCCESS, NewBookingActionTypes.BOOK_CONVERSATION_FAIL],
  'bookConversation'
);

export const bookConversation = ({ appointmentData, successCallback, token }) => {
  return async (dispatch) => {
    try {
      dispatch(cleanErrorState());
      dispatch(bookConversationActions.bookIntroClass());
      await NewBookingAPI.createStudentBooking(token, appointmentData);
      dispatch(bookConversationActions.bookIntroClassSuccess());
      if (successCallback) successCallback();
    } catch (err) {
      sendSentryError({ err, context: 'bookConversation' });
      const errorMessage = extractErrorMessage(err, 'Something went wrong while booking your Meet Your Coach session.');
      dispatch(bookConversationActions.bookIntroClassFail(errorMessage));
    }
  };
};

const rescheduleConversationActions = actionCreatorsFactory(
  [
    NewBookingActionTypes.RESCHEDULE_CONVERSATION,
    NewBookingActionTypes.RESCHEDULE_CONVERSATION_SUCCESS,
    NewBookingActionTypes.RESCHEDULE_CONVERSATION_FAIL
  ],
  'rescheduleConversation'
);

export const rescheduleConversation = (studentToken, appointmentsArray, successCallback) => {
  return async (dispatch) => {
    try {
      dispatch(cleanErrorState());
      dispatch(rescheduleConversationActions.rescheduleIntroClass());
      await NewBookingAPI.updateMultipleStudentBooking(studentToken, appointmentsArray);
      dispatch(rescheduleConversationActions.rescheduleIntroClassSuccess());
      if (successCallback) successCallback();
    } catch (err) {
      sendSentryError({ err, context: 'rescheduleConversation' });
      const errorMessage = extractErrorMessage(err, 'Something went wrong while booking your Meet Your Coach session.');
      dispatch(rescheduleConversationActions.rescheduleIntroClassFail(errorMessage));
    }
  };
};

const cancelConversationActions = actionCreatorsFactory(
  [NewBookingActionTypes.CANCEL_CONVERSATION, NewBookingActionTypes.CANCEL_CONVERSATION_SUCCESS, NewBookingActionTypes.CANCEL_CONVERSATION_FAIL],
  'cancelConversation'
);

export const cancelConversation =
  ({ studentToken, appointmentsIds, cancellationMotive, successCallback }) =>
  async (dispatch) => {
    try {
      dispatch(cleanErrorState());
      dispatch(cancelConversationActions.cancelIntroClass());
      await NewBookingAPI.cancelMultipleBookings(studentToken, appointmentsIds, cancellationMotive);
      dispatch(cancelConversationActions.cancelIntroClassSuccess());
      if (successCallback) successCallback();
    } catch (err) {
      sendSentryError({ err, context: 'cancelConversation' });
      const errorMessage = extractErrorMessage(err, 'Something went wrong while cancelling.');
      dispatch(cancelConversationActions.cancelIntroClassFail(errorMessage));
    }
  };

export const setOpenBookingConversationModal = () => ({
  type: NewBookingActionTypes.OPEN_BOOKING_CONVERSATION_MODAL
});

export const closeBookingConversationModal = () => ({
  type: NewBookingActionTypes.CLOSE_BOOKING_CONVERSATION_MODAL
});

export const cleanSuccess = () => ({
  type: NewBookingActionTypes.CLEAN_SUCCESS
});

export const setBulkingState = (payload) => ({
  type: NewBookingActionTypes.SET_BULKING_STATE,
  payload
});

export const setShowOptions = (payload) => ({
  type: NewBookingActionTypes.SET_SHOW_OPTIONS,
  payload
});

export const setSelectedAction = (payload) => ({
  type: NewBookingActionTypes.SET_SELECTED_ACTION,
  payload
});

export const setIsFirstBooking = ({ lesson_number: lessonNumber }) => ({
  type: NewBookingActionTypes.SET_IS_FIRST_BOOKING,
  payload: lessonNumber === 1
});
