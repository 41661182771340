import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import styles from '../../style/containers/StudyArea.module.scss';
import WeeklyCourseSchedule from './WeeklyCourseSchedule/WeeklyCourseSchedule';
import CourseSelector from './CourseSelector/CourseSelector';
import CourseDetails from './CourseDetails/CourseDetails';
import PlacementTest from './PlacementTest/PlacementTest';
import LoadingSpinner from '../../components/design-system/loading/spinner/LoadingSpinner';

const NewStudyAreaWeb = ({ hasFinishedPlacementTest, isGettingEnrolledCourses, isRetrievingPlacementTest }) => {
  const courseDetailsRef = useRef(null);
  const { unitIndex } = useParams();

  useEffect(() => {
    if (unitIndex) handleScrollWhenLessonFromUrl();
  }, [unitIndex, courseDetailsRef.current]);

  const handleScrollWhenLessonFromUrl = () => {
    if (!courseDetailsRef.current) return;
    courseDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className={styles.pageContainer}>
      <CourseSelector />
      <WeeklyCourseSchedule courseDetailsRef={courseDetailsRef} />
      {isGettingEnrolledCourses && isRetrievingPlacementTest ? (
        <div className={styles.loadingWrapper}>
          <LoadingSpinner />
        </div>
      ) : hasFinishedPlacementTest ? (
        <CourseDetails componentRef={courseDetailsRef} />
      ) : (
        <PlacementTest />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  hasFinishedPlacementTest: state.newOnboarding.placementTest.hasFinishedPlacementTest,
  isGettingEnrolledCourses: state.courses.enrolledCourses.isGettingEnrolledCourses,
  isRetrievingPlacementTest: state.newOnboarding.placementTest.isRetrievingPlacementTest,
  previousOnboarding: state.newOnboarding.previousOnboarding.previousOnboarding,
  selectedCourse: state.courses?.selectedCourseDetails?.courseDetails,
  student: state.user
});

NewStudyAreaWeb.propTypes = {
  hasFinishedPlacementTest: PropTypes.bool,
  isGettingEnrolledCourses: PropTypes.bool.isRequired,
  isRetrievingPlacementTest: PropTypes.bool.isRequired,
  selectedCourse: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(NewStudyAreaWeb);
