import React from 'react';
import PropTypes from 'prop-types';
import styles from './HighlightSpan.module.scss';

const HighlightSpan = ({ children }) => {
  const highlightClass = [styles['text--highlight'], styles['text--bold']].join(' ');

  return <span className={highlightClass}>{children}</span>;
};

HighlightSpan.propTypes = {
  children: PropTypes.string.isRequired
};

export default HighlightSpan;
