import React from 'react';
import PropTypes from 'prop-types';

import IconCheck from '../../assets/icons/icon_check.svg';

import styles from '../../style/components/uikit/Timetable.module.scss';
import { DAYS, PERIOD_OF_THE_DAY } from '../availability/helpers';
import { getCityTimezone } from '../../utils/time-helpers';

const Timetable = ({ disabled, calendarMatrix, onSelectTimeSlot, timezone, timetableRef }) => {
  const isDisabled = disabled === undefined ? false : disabled;
  return (
    <div className={styles.timeTableContainer} ref={timetableRef}>
      {timezone && <p className={styles.timezone}>Timezone: {getCityTimezone(timezone)}</p>}
      <div className={styles.timeTableWrapper}>
        <div className={styles.timeTableColumn}>
          <div className={[styles.timeTableCell, styles.timeTableEmpty].join(' ')} />
          {PERIOD_OF_THE_DAY.map((period) => (
            <div
              key={period}
              className={[styles.timeTableCell, styles.timeTableHeader, isDisabled ? styles.timeTableHeaderDisabled : undefined].join(' ')}>
              {period}
            </div>
          ))}
        </div>
        {DAYS.map((day, indexDay) => (
          <div key={day} className={styles.timeTableColumn}>
            <div className={[styles.timeTableCell, styles.timeTableHeader, isDisabled ? styles.timeTableHeaderDisabled : undefined].join(' ')}>
              {day}
            </div>
            {PERIOD_OF_THE_DAY.map((period, indexPeriod) => {
              let selected = false;
              if (calendarMatrix) {
                selected = calendarMatrix[indexPeriod][indexDay];
              }
              return (
                <div
                  key={`${day}${period}`}
                  className={[
                    styles.timeTableCell,
                    selected ? styles.timeTableNormalSelected : styles.timeTableNormal,
                    isDisabled && selected ? styles.timeTableNormalSelectedDisabled : undefined
                  ].join(' ')}>
                  <button onClick={onSelectTimeSlot.bind(null, indexDay, indexPeriod)} className={styles.calendarButton} disabled={isDisabled}>
                    <div className={selected ? styles.calendarIconSelected : styles.calendarIcon}>
                      <img src={IconCheck} alt="check" />
                    </div>
                  </button>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

Timetable.propTypes = {
  disabled: PropTypes.bool,
  calendarMatrix: PropTypes.array.isRequired,
  onSelectTimeSlot: PropTypes.func,
  timetableRef: PropTypes.object,
  timezone: PropTypes.string
};

Timetable.defaultProps = {
  disabled: false,
  onSelectTimeSlot: () => {}
};

export default Timetable;
