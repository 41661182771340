import * as Sentry from '@sentry/browser';
import { adaptToJavascriptMonth, getRegularMonth, organizeDatesIntoYearsAndMonthsMap, setAvailableScheduleInterval } from '../utils/date-helpers';
import { isTokenExpired } from './common';
import BookableAPI from '../services/bookable';
import { actionCreatorsFactory, asyncActionType, createActionTypes } from '../utils/action-helpers';
import { getYear } from 'date-fns';
import { buildMonthAndYearStr, buildTimestampFromKey } from '../reducers/bookable';
import { calculateBookings } from '../components/new-booking/components/booking/domain/calculateBookings';

const sentryContext = Object.freeze({
  tags: {
    context: 'Bookable action'
  }
});

export const BookableActionTypes = createActionTypes('Bookable', [
  asyncActionType('GET_FUTURE_SCHEDULE'),
  asyncActionType('GET_BOOKABLE'),
  asyncActionType('UPDATE_BOOKABLE'),
  asyncActionType('GET_AVAILABLE_SESSIONS_FOR_THIS_PERIOD'),
  asyncActionType('UPDATE_AVAILABLE_SESSIONS_FOR_THIS_PERIOD'),
  'UPDATE_BOOKABLE',
  'RESET_BOOKABLE',
  'RESET_POSSIBLE_RESCHEDULE',
  'PRE_BOOK_DATE',
  'REMOVE_FROM_PRE_BOOK',
  'PRE_BOOK_WHOLE_MONTH',
  'PRE_BOOK_WHOLE_MONTH_AHEAD',
  'REMOVE_MONTH_FROM_PRE_BOOK',
  'SET_SELECTED_TIMESTAMP',
  'SET_SELECTED_DATE',
  'SET_CURRENT_DATE',
  'STORE_MONTH_SESSIONS_AVAILABILITY',
  'CLEAN_SELECTED_TIMESTAMP'
]);

export const setSelectedTimestamp = (timestamp) => ({
  type: BookableActionTypes.SET_SELECTED_TIMESTAMP,
  payload: timestamp
});

export const setSelectedDate = (date) => ({
  type: BookableActionTypes.SET_SELECTED_DATE,
  payload: date
});

export const cleanSelectedTimestamp = () => ({
  type: BookableActionTypes.CLEAN_SELECTED_TIMESTAMP
});

export const setCurrentDate = (date) => ({
  type: BookableActionTypes.SET_CURRENT_DATE,
  payload: date
});

export const preBookDate = (timestamp) => ({
  type: BookableActionTypes.PRE_BOOK_DATE,
  payload: timestamp
});

export const removeFromPreBook = (timestamp) => ({
  type: BookableActionTypes.REMOVE_FROM_PRE_BOOK,
  payload: timestamp
});

export const preBookWholeMonthDate = (date, successCallback) => {
  if (successCallback) successCallback();
  return {
    type: BookableActionTypes.PRE_BOOK_WHOLE_MONTH,
    payload: date
  };
};

export const preBookWholeMonthDateAhead = (date, successCallback) => {
  if (successCallback) successCallback();
  return {
    type: BookableActionTypes.PRE_BOOK_WHOLE_MONTH_AHEAD,
    payload: date
  };
};

export const removeMonthFromPreBook = (date) => ({
  type: BookableActionTypes.REMOVE_MONTH_FROM_PRE_BOOK,
  payload: date
});

export const storeMonthSessionsAvailability = (payload) => {
  return {
    type: BookableActionTypes.STORE_MONTH_SESSIONS_AVAILABILITY,
    payload
  };
};

export function resetBookingInfo() {
  return {
    type: BookableActionTypes.RESET_BOOKABLE,
    payload: {}
  };
}

const futureScheduleActions = actionCreatorsFactory(
  [BookableActionTypes.GET_FUTURE_SCHEDULE, BookableActionTypes.GET_FUTURE_SCHEDULE_SUCCESS, BookableActionTypes.GET_FUTURE_SCHEDULE_FAIL],
  'getFutureSchedule'
);

export function getFutureSchedule(token, productTypeId, tutorId, date, timeZone, courseId, callbackFinishedLoading) {
  return async (dispatch) => {
    try {
      dispatch(futureScheduleActions.getFutureSchedule());
      const params = {
        product_type_id: productTypeId,
        tutor_id: tutorId,
        appointment_date: date,
        time_zone: timeZone,
        course_id: courseId
      };
      const { data } = await BookableAPI.getPossibleFutureSchedule(token, params);
      dispatch(futureScheduleActions.getFutureScheduleSuccess(data));
      if (callbackFinishedLoading) callbackFinishedLoading();
    } catch (err) {
      Sentry.captureException(err, sentryContext);
      dispatch(futureScheduleActions.getFutureScheduleFail());
      if (callbackFinishedLoading) callbackFinishedLoading();
    }
  };
}

const updateBookableActions = actionCreatorsFactory(
  [BookableActionTypes.UPDATE_BOOKABLE, BookableActionTypes.UPDATE_BOOKABLE_SUCCESS, BookableActionTypes.UPDATE_BOOKABLE_FAIL],
  'updateBookable'
);

export function updateAvailableBookingsForTutorForTimespan({ timestamp, ignoreIds, token, tutorId, productTypeId, selectedLanguageId }) {
  return async (dispatch) => {
    try {
      dispatch(updateBookableActions.updateBookable());
      const { from, to } = setAvailableScheduleInterval(new Date(timestamp), 0, 12);
      const { data } = await BookableAPI.getBookable(token, { tutorId, productTypeId, selectedLanguageId, from, to, ignoreIds });
      if (typeof data !== 'object') throw new Error('Unexpected response format');
      const datetimesMap = organizeDatesIntoYearsAndMonthsMap(data.datetimes);
      dispatch(updateBookableActions.updateBookableSuccess({ ...data, to, datetimesMap }));
      dispatch(updateAvailableSessionsForThisPeriod({ from, to, token, productTypeId, ignoreIds }));
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        Sentry.captureException(err, sentryContext);
        dispatch(updateBookableActions.updateBookableFail());
      }
    }
  };
}

const getBookableActions = actionCreatorsFactory(
  [BookableActionTypes.GET_BOOKABLE, BookableActionTypes.GET_BOOKABLE_SUCCESS, BookableActionTypes.GET_BOOKABLE_FAIL],
  'getBookable'
);

export function getBookingInfoForTutorForTimespan({ token, tutorId, productTypeId, selectedLanguageId, from, to, ignoreIds }) {
  return async (dispatch) => {
    try {
      dispatch(getBookableActions.getBookable());
      const { data } = await BookableAPI.getBookable(token, { tutorId, productTypeId, selectedLanguageId, from, to, ignoreIds });
      if (typeof data !== 'object') throw new Error('Unexpected response format');
      const datetimesMap = organizeDatesIntoYearsAndMonthsMap(data.datetimes);
      const unfilteredDatetimesMap = organizeDatesIntoYearsAndMonthsMap(data.unfiltered_datetimes);
      console.log('unfilteredDatetimesMap', unfilteredDatetimesMap);
      dispatch(getBookableActions.getBookableSuccess({ ...data, to, datetimesMap, unfilteredDatetimesMap }));
      dispatch(getAvailableSessionsForThisPeriod({ from, to, token, productTypeId, ignoreIds }));
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        Sentry.captureException(err, sentryContext);
        dispatch(getBookableActions.getBookableFail());
      }
    }
  };
}

export default function getBookingInfoForTutor(token, tutorId, productTypeId, selectedLanguageId, errorCallback, successCallback, ignoreIds) {
  return async (dispatch) => {
    try {
      dispatch(getBookableActions.getBookable());
      const { data } = await BookableAPI.getBookable(token, { tutorId, productTypeId, selectedLanguageId, ignoreIds });
      if (typeof data !== 'object') throw Error('Unexpected response format');

      const availableDatetimesMap = organizeDatesIntoYearsAndMonthsMap(data.datetimes);
      dispatch(getBookableActions.getBookableSuccess({ ...data, availableDatetimesMap }));
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        Sentry.captureException(err, sentryContext);
        dispatch(getBookableActions.getBookableFail(err));
        if (errorCallback) {
          errorCallback();
        }
      }
    }
  };
}

const availableSessionsForThisPeriodActions = actionCreatorsFactory(
  [
    BookableActionTypes.GET_AVAILABLE_SESSIONS_FOR_THIS_PERIOD,
    BookableActionTypes.GET_AVAILABLE_SESSIONS_FOR_THIS_PERIOD_SUCCESS,
    BookableActionTypes.GET_AVAILABLE_SESSIONS_FOR_THIS_PERIOD_FAIL
  ],
  'getAvailableSessionsForThisPeriod'
);

const shouldBlock = (canBookThisMonth) => {
  if (typeof canBookThisMonth !== 'boolean') return false;
  return !canBookThisMonth;
};

const blockMonthWhenNecessary = (availableSessionsPerMonth) => (dispatch) => {
  if (!availableSessionsPerMonth) return;
  let lastTimestampCalled = '';
  let blockCalls = 0;
  const availableSessionsPerMonthKeys = Object.keys(availableSessionsPerMonth);
  availableSessionsPerMonthKeys.forEach((key) => {
    const ts = buildTimestampFromKey(key);
    lastTimestampCalled = ts;
    if (shouldBlock(availableSessionsPerMonth[key]?.canBookThisMonth)) {
      dispatch(preBookWholeMonthDateAhead(ts));
      blockCalls++;
    }
  });
  if (blockCalls === availableSessionsPerMonthKeys.length) return { blockedAll: true, lastTimestampCalled };
  return { blockedAll: false };
};

export const buildAvailableSessionsPerMonth = (sessionsPerMonth, prevState) => {
  if (!sessionsPerMonth) return {};
  const organized = organizeSessionsPerMonth(sessionsPerMonth, {});
  const sessionsMap = new Map(Object.entries(organized));
  const availableMap = new Map();
  sessionsMap.forEach((session, key) => {
    const value = calculateBookings({ selectedTimestamp: session.timestamp, selectedTimestamps: {}, sessionsPerMonth: organized });
    availableMap.set(key, value);
  });

  const prevStateMap = new Map(Object.entries(prevState));
  return Object.fromEntries(new Map([...prevStateMap, ...availableMap]));
};

const returnValueOrInfinite = (value) => {
  if (value === undefined || value === null) return Number.MAX_SAFE_INTEGER;
  return value;
};

const returnValueOrZero = (value) => {
  if (value === undefined || value === null) return 0;
  return value;
};

const adaptSessionsPerMonth = (session) => {
  const month = session?.month || getRegularMonth(new Date());
  return {
    month,
    year: session?.year || getYear(new Date()),
    availableSessionsLeft: returnValueOrInfinite(session?.available_sessions_left),
    availableSessionsTotal: returnValueOrInfinite(session?.total_available_sessions),
    expiringSessionsLeft: returnValueOrZero(session?.expiring_sessions_left),
    expiringSessionsTotal: returnValueOrZero(session?.total_expiring_sessions)
  };
};

export const organizeSessionsPerMonth = (payload, prevState) => {
  if (!payload) return {};

  const sessionsMap = new Map(Object.entries(prevState));
  payload.forEach((session) => {
    const adapted = adaptSessionsPerMonth(session);
    const ts = new Date(adapted?.year, adaptToJavascriptMonth(adapted?.month), 1).toISOString();
    const key = buildMonthAndYearStr(ts);
    sessionsMap.set(key, {
      timestamp: ts,
      expiringSessionsLeft: adapted?.expiringSessionsLeft,
      expiringSessionsTotal: adapted?.expiringSessionsTotal,
      availableSessionsLeft: adapted?.availableSessionsLeft,
      availableSessionsTotal: adapted?.availableSessionsTotal
    });
  });

  return Object.fromEntries(sessionsMap);
};

function getAvailableSessionsForThisPeriod({ from, to, token, productTypeId, ignoreIds }) {
  return async (dispatch) => {
    try {
      dispatch(availableSessionsForThisPeriodActions.getAvailableSessionsForThisPeriod());
      const { data } = await BookableAPI.getAvailableSessionsForThisPeriod(token, { productTypeId, from, to, ignoreIds });
      const sessionsPerMonth = organizeSessionsPerMonth(data, {});
      const availableSessionsPerMonth = buildAvailableSessionsPerMonth(data, {});
      dispatch(availableSessionsForThisPeriodActions.getAvailableSessionsForThisPeriodSuccess({ sessionsPerMonth, availableSessionsPerMonth, from }));
      blockMonthWhenNecessary(availableSessionsPerMonth)(dispatch);
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        Sentry.captureException(err, sentryContext);
        dispatch(availableSessionsForThisPeriodActions.getAvailableSessionsForThisPeriodFail());
      }
    }
  };
}

const updateAvailableSessionsForThisPeriodActions = actionCreatorsFactory(
  [
    BookableActionTypes.UPDATE_AVAILABLE_SESSIONS_FOR_THIS_PERIOD,
    BookableActionTypes.UPDATE_AVAILABLE_SESSIONS_FOR_THIS_PERIOD_SUCCESS,
    BookableActionTypes.UPDATE_AVAILABLE_SESSIONS_FOR_THIS_PERIOD_FAIL
  ],
  'updateAvailableSessionsForThisPeriod'
);

function updateAvailableSessionsForThisPeriod({ from, to, token, productTypeId, ignoreIds }) {
  return async (dispatch) => {
    try {
      dispatch(updateAvailableSessionsForThisPeriodActions.updateAvailableSessionsForThisPeriod());
      const { data } = await BookableAPI.getAvailableSessionsForThisPeriod(token, { productTypeId, from, to, ignoreIds });
      const sessionsPerMonth = organizeSessionsPerMonth(data, {});
      const availableSessionsPerMonth = buildAvailableSessionsPerMonth(data, {});
      dispatch(
        updateAvailableSessionsForThisPeriodActions.updateAvailableSessionsForThisPeriodSuccess({ sessionsPerMonth, from, availableSessionsPerMonth })
      );
      blockMonthWhenNecessary(availableSessionsPerMonth)(dispatch);
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        Sentry.captureException(err, sentryContext);
        dispatch(updateAvailableSessionsForThisPeriodActions.updateAvailableSessionsForThisPeriodFail());
      }
    }
  };
}
