import React from 'react';
import PropTypes from 'prop-types';

import styles from './NavigationArrows.module.scss';

import { ReactComponent as IconLeftArrow } from '../../../assets/icons/icon_left-arrow.svg';
import { ReactComponent as IconRightArrow } from '../../../assets/icons/icon_right-arrow.svg';

const NavigationArrows = ({ enableMoveBack, enableMoveForward, onBack, onForward }) => {
  return (
    <>
      <button className={styles['move-back']} disabled={!enableMoveBack} onClick={onBack}>
        <IconLeftArrow />
      </button>
      <button className={styles['move-forward']} disabled={!enableMoveForward} onClick={onForward}>
        <IconRightArrow />
      </button>
    </>
  );
};

NavigationArrows.propTypes = {
  enableMoveBack: PropTypes.bool,
  enableMoveForward: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onForward: PropTypes.func.isRequired
};

export default NavigationArrows;
