import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addMonths, getDate, isPast, isSameDay, isSameMinute, isSameMonth } from 'date-fns';

import styles from './Calendar.module.scss';

import { getDaysOfWeekTranslated, getMonthTranslated } from './TimeRelated.enum';
import { convertToLocaleAndFormat } from '../../../utils/time-helpers';
import { setCurrentDate, setSelectedDate, setSelectedTimestamp } from '../../../actions/bookable';
import LoadingSpinner from '../loading/spinner/LoadingSpinner';
import Button from '../button/Button';
import mixpanelHelper from '../../../utils/mixpanel-helper';

const Calendar = ({
  alreadyBooked,
  calendar,
  currentLang,
  daysAvailableThisMonth,
  disabled,
  disabledFinishButton,
  futureSchedule,
  isFinishing,
  isLoading,
  onFinish,
  onMonthChangeForward,
  renderAlert,
  renderAppointmentItem,
  selectedTimestamp,
  selectedTimestamps,
  setCurrentDateAction,
  setSelectedDateAction,
  setSelectedTimestampAction,
  timesAvailableThisDay,
  timezone
}) => {
  const sumMonthAndYear = (calendar) => Number(calendar?.month || 0) + Number(calendar?.year || 0);
  const monthPlusYearRef = useRef(sumMonthAndYear(calendar));

  useEffect(() => {
    if (selectedTimestamp) setCurrentDateAction(selectedTimestamp);
  }, [selectedTimestamp]);

  const updateRef = (calendar) => (monthPlusYearRef.current = sumMonthAndYear(calendar));

  useEffect(() => {
    if (sumMonthAndYear(calendar) > monthPlusYearRef.current) {
      if (onMonthChangeForward) onMonthChangeForward(calendar?.date);
    }
    updateRef(calendar);
  }, [calendar?.month]);

  const isDayAvailable = (day) => {
    if (isPast(new Date(day))) return false;
    return daysAvailableThisMonth.some((ts) => isSameDay(new Date(day), new Date(ts)));
  };

  const handleDaySelection = (newDate) => {
    if (!newDate || !isDayAvailable(newDate)) return;
    setSelectedDateAction(newDate);
    mixpanelHelper.track('Date Selected', {
      selected_timestamp: newDate
    });
  };

  const handleTimestampSelection = (timestamp) => {
    console.log('handleTimestampSelection');
    if (!timestamp || !isDayAvailable(timestamp)) return;
    setSelectedTimestampAction(timestamp);
    mixpanelHelper.track('Time Selected', {
      selected_timestamp: timestamp
    });
  };

  const handleMonthChange = (value) => {
    const newDate = addMonths(new Date(calendar?.date), value);
    setCurrentDateAction(newDate);
  };

  const isDayAlreadySelected = (day) => {
    return (selectedTimestamps || []).some((date) => isSameDay(new Date(date), new Date(day)));
  };

  const isDayPreviouslySelected = (day) => {
    return (alreadyBooked || []).some((date) => isSameDay(new Date(date), new Date(day)));
  };

  const isCurrentMonth = isSameMonth(new Date(), new Date(calendar?.date));

  return (
    <div className={styles.calendarAndClockWrapper}>
      <div className={styles.calendarAndTime}>
        <div className={[styles.calendar]}>
          <div className={styles.calendarHeader}>
            <button type="button" className={styles.arrow} onClick={handleMonthChange.bind(this, -1)} disabled={isCurrentMonth}>
              <div className={[styles.leftArrow, isCurrentMonth && styles.disabled].join(' ')} />
            </button>
            <div className={styles.monthAndYear}>
              <span id="dont-translate">{getMonthTranslated(currentLang)[calendar?.month]} </span>
              <span>{calendar?.year}</span>
            </div>
            <button type="button" className={styles.arrow} onClick={handleMonthChange.bind(this, 1)}>
              <div className={styles.rightArrow} />
            </button>
          </div>
          <div className={styles.calendarDays}>
            {calendar?.matrix?.map((arrayOfDays, index) => (
              <div className={styles.calendarColumn} key={index}>
                <div className={styles.weekDay} id="dont-translate">
                  {getDaysOfWeekTranslated(currentLang)[index].slice(0, 3).toUpperCase()}
                </div>
                {arrayOfDays.map((day, index) =>
                  day && getDate(new Date(day)) ? (
                    <button
                      className={[
                        styles.day,
                        isSameDay(new Date(day), new Date(selectedTimestamp)) && styles.selected,
                        isDayAlreadySelected(day) && styles['already-selected'],
                        isDayPreviouslySelected(day) && styles['previously-selected'],
                        (!isDayAvailable(day) || disabled) && styles.inactive
                      ].join(' ')}
                      type="button"
                      data-testid={isDayAvailable(day) && 'available-day'}
                      onClick={handleDaySelection.bind(this, day)}
                      key={index}>
                      {getDate(new Date(day))}
                    </button>
                  ) : (
                    <div className={styles.emptySpace} key={index}>
                      .
                    </div>
                  )
                )}
              </div>
            ))}
            {isLoading && (
              <div className={styles.loaderWrapper}>
                <div className={styles.loader} />
              </div>
            )}
          </div>
          {Boolean(renderAlert) && !isLoading && <div className={styles.alert}>{renderAlert()}</div>}
        </div>
        {!isLoading && Boolean(timesAvailableThisDay?.length) && (
          <div className={styles.timer}>
            <div className={styles.timerHeader}>
              <span>Time</span>
            </div>
            <div className={styles.availableTimes}>
              {timesAvailableThisDay.map((dateAndTime, index) => (
                <button
                  className={[
                    styles.timeSlots,
                    isSameMinute(new Date(dateAndTime), new Date(selectedTimestamp)) && styles.selected,
                    disabled && styles.inactive
                  ].join(' ')}
                  type="button"
                  id="dont-translate"
                  onClick={handleTimestampSelection.bind(this, dateAndTime)}
                  key={index}>
                  {convertToLocaleAndFormat(dateAndTime, timezone, 'hh:mm aa')}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className={styles.bookingSummary}>
        <h1>Booking Summary</h1>
        <div className={styles.bookingSummaryList}>
          {isLoading ? (
            <div className={styles.loadingContainer}>
              <LoadingSpinner />
            </div>
          ) : (
            <ul>{futureSchedule.map((lesson, index) => renderAppointmentItem(lesson, index))}</ul>
          )}
        </div>
        <div className={styles.buttonsWrapper}>
          <Button
            outfit="greenButton"
            disabled={isLoading || disabledFinishButton || !futureSchedule.length}
            loading={isFinishing}
            onClick={onFinish}
            id="rescheduling-modal-book">
            Book
          </Button>
        </div>
      </div>
    </div>
  );
};

Calendar.propTypes = {
  alreadyBooked: PropTypes.array,
  calendar: PropTypes.object.isRequired,
  currentLang: PropTypes.string.isRequired,
  daysAvailableThisMonth: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  disabledFinishButton: PropTypes.bool,
  futureSchedule: PropTypes.array,
  isFinishing: PropTypes.bool,
  isLoading: PropTypes.bool,
  onFinish: PropTypes.func.isRequired,
  onMonthChangeForward: PropTypes.func.isRequired,
  renderAlert: PropTypes.func,
  renderAppointmentItem: PropTypes.func.isRequired,
  selectedTimestamp: PropTypes.string.isRequired,
  selectedTimestamps: PropTypes.array,
  setCurrentDateAction: PropTypes.func.isRequired,
  setSelectedDateAction: PropTypes.func.isRequired,
  setSelectedTimestampAction: PropTypes.func.isRequired,
  shouldMoveToNextMonthWhenUnavailable: PropTypes.bool.isRequired,
  timesAvailableThisDay: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired
};

Calendar.defaultProps = {
  disabled: false,
  isLoading: false,
  selectedTimestamps: [],
  shouldMoveToNextMonthWhenUnavailable: false
};

const mapStateToProps = (state) => ({
  currentLang: state.appLanguage.currentLanguage,
  calendar: state.bookable.available.calendar,
  timezone: state.time.timezone
});

export default connect(mapStateToProps, {
  setCurrentDateAction: setCurrentDate,
  setSelectedDateAction: setSelectedDate,
  setSelectedTimestampAction: setSelectedTimestamp
})(Calendar);
