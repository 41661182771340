import instance from '..';

export default class LoginAPI {
  static async logIn(data) {
    return instance.post('/api/login/', data);
  }
  static async logOut(token) {
    return instance.post('/api/logout/', { headers: { Authorization: `Token ${token}` } });
  }
  static async signIn(data) {
    return instance.post('/rest-auth/password/reset/confirm/', data);
  }
  static async validatePasswordToken({ invitationToken, email }) {
    const params = { password_token: invitationToken, email };
    return instance.get('/api/validate_password_token/', { params });
  }
  static async createUserWithOrder({ cohort, email, invitationId, firstName, lastName, org }) {
    const body = { cohort, email, invitationId, firstName, lastName, org };
    return instance.post('/api/create_user_with_order/', body);
  }
  static async getSetPasswordToken({ email }) {
    return instance.post('/rest-auth/password/set/', { email });
  }
  static async getSSOProvider({ email }) {
    return instance.post('/api/login/sso/provider/', { email });
  }
  static async exchangeAccessToken({ accessToken }) {
    return instance.post('/api/login/sso/exchange-token/', { token: accessToken });
  }
}
