import { useEffect, useRef } from 'react';

const useCloseWhenClickOutside = ({ handleExit }) => {
  const ref = useRef(null);

  useEffect(() => {
    listenToClick();

    return () => document.removeEventListener('mousedown', closeWhenClickOutside);
  }, []);

  const listenToClick = () => {
    document.addEventListener('mousedown', closeWhenClickOutside);
  };

  const closeWhenClickOutside = (evt) => {
    if (!ref.current || ref.current.contains(evt.target)) return;
    handleExit();
  };

  return [ref];
};

export default useCloseWhenClickOutside;
