import React from 'react';
import PasswordFormField from '../components/design-system/password-field/PasswordFormField';
import PhoneFormField from '../components/design-system/phone-field/PhoneFormField';
import RegularFormField from '../components/design-system/regular-field/RegularFormField';
import DropdownField from '../components/design-system/dropdown-field/DropdownField';
import { AVAILABLE_LANGUAGES_MAPPING } from '../enums/languages';
export const EMAIL_REG_EXP = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i;

// Source:
// https://www.gov.uk/government/uploads/system/uploads/attachment_data/file/488478/Bulk_Data_Transfer_-_additional_validation_valid_from_12_November_2015.pdf
export const POSTCODE_PATTERN =
  '^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z' +
  '][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$';

export const POSTCODE_REG_EXP = new RegExp(POSTCODE_PATTERN);

export const renderField = (field) => {
  const { meta, input, label, type } = field;

  if (type === 'password')
    return (
      <PasswordFormField
        id={input.name}
        label={label}
        {...field.input}
        invalidMessage="Invalid password"
        initialValue={meta.initial}
        isValid={meta.submitFailed ? meta.touched && meta.valid : true}
      />
    );

  if (type === 'phone')
    return (
      <PhoneFormField
        id={input.name}
        label={label}
        {...field.input}
        invalidMessage="Invalid phone number"
        initialValue={meta.initial}
        isValid={meta.submitFailed ? meta.touched && meta.valid : true}
      />
    );

  if (type === 'language-dropdown') {
    const value = AVAILABLE_LANGUAGES_MAPPING.find((language) => language.value === field.input.value);
    return (
      <DropdownField
        id={input.name}
        label={label}
        {...field.input}
        onChange={(index) => {
          field.input.onChange(AVAILABLE_LANGUAGES_MAPPING[index].value);
        }}
        invalidMessage="Invalid language"
        initialValue={AVAILABLE_LANGUAGES_MAPPING.find((language) => language.value === meta.initial)}
        isValid={meta.submitFailed ? meta.touched && meta.valid : true}
        availableValues={AVAILABLE_LANGUAGES_MAPPING}
        value={value}
      />
    );
  }

  return (
    <RegularFormField
      id={input.name}
      label={label}
      type={type}
      {...field.input}
      invalidMessage="Invalid email"
      initialValue={meta.initial}
      isValid={meta.submitFailed ? meta.touched && meta.valid : true}
    />
  );
};
