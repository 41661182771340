import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MessageBox } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';

const PhotoMessage = ({ className, date, id, media, position, title, titleColor, type }) => {
  const [uri, setUri] = useState('');

  useEffect(() => {
    (async () => getPhotoUri(media))();
  }, [media]);

  const getPhotoUri = async (media) => {
    if (!media) return;
    const URI = await media.getContentTemporaryUrl();
    setUri(URI);
  };

  const onDownload = () => {
    window.open(uri, '_blank');
  };

  return (
    <MessageBox
      className={className}
      id={id}
      date={date}
      position={position}
      onClick={onDownload}
      data={{
        uri,
        alt: 'image',
        status: {
          download: true,
          click: true
        }
      }}
      title={title}
      titleColor={titleColor}
      type={type}
    />
  );
};

PhotoMessage.propTypes = {
  className: PropTypes.string,
  customRef: PropTypes.object,
  date: PropTypes.instanceOf(Date).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLastMessage: PropTypes.bool,
  media: PropTypes.object,
  position: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default PhotoMessage;
