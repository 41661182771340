import instance from '..';
import { convertArrayToQueryString, convertObjToQueryString } from '../../utils/url-helpers';

export default class BookableAPI {
  static async getPossibleFutureSchedule(token, params) {
    return instance.get('/api/find-reschedules/', {
      headers: { Authorization: `Token ${token}` },
      params
    });
  }

  static async getBookable(token, params) {
    const underscoredParams = {
      from: params?.from,
      product_type_id: params?.productTypeId,
      selected_language_id: params?.selectedLanguageId,
      to: params?.to,
      tutor_id: params?.tutorId
    };

    const stringfied = convertObjToQueryString(underscoredParams);
    const arrString = convertArrayToQueryString(params?.ignoreIds, 'appt_ignore_ids');

    return instance.get(`/api/tutors/${params.tutorId}/bookable/?${arrString ? stringfied + '&' + arrString : stringfied}`, {
      headers: { Authorization: `Token ${token}` }
    });
  }

  static async getAvailableSessionsForThisPeriod(token, params) {
    const underscoredParams = {
      from: params?.from,
      product_type_id: params?.productTypeId,
      to: params?.to
    };

    const stringfied = convertObjToQueryString(underscoredParams);
    const arrString = convertArrayToQueryString(params?.ignoreIds, 'appt_ignore_ids');

    return instance.get(`/api/license-limitations/?${arrString ? stringfied + '&' + arrString : stringfied}`, {
      headers: { Authorization: `Token ${token}` }
    });
  }
}
