import * as moment from 'moment-timezone';

import {
  CREATE_ABSENCE_PERIOD,
  DELETE_ABSENCE_PERIOD,
  GET_ABSENCE_PERIODS,
  GET_ABSENCE_PERIODS_ERROR,
  UPDATE_ABSENCE_PERIOD,
  USER_LOGOUT
} from '../actions/types';

function sort(absencePeriods) {
  const sortFunction = (period1, period2) => moment(period1.start) - moment(period2.start);
  return absencePeriods.sort(sortFunction);
}

export default function absencePeriodReducer(state = [], action) {
  let index;
  let newState;

  switch (action.type) {
    case CREATE_ABSENCE_PERIOD:
      newState = state.slice(0);
      newState.push(action.payload.data);
      return sort(newState);
    case DELETE_ABSENCE_PERIOD:
      index = state.findIndex((item) => item.id === action.payload);
      newState = state.slice(0);
      newState.splice(index, 1);
      return newState;
    case GET_ABSENCE_PERIODS:
      newState = action.payload.data.slice(0);
      return sort(newState);
    case GET_ABSENCE_PERIODS_ERROR:
      return [];
    case UPDATE_ABSENCE_PERIOD:
      index = state.findIndex((item) => item.id === action.payload.data.id);
      newState = state.slice(0);
      newState[index] = action.payload.data;
      return sort(newState);
    case USER_LOGOUT:
      return [];
    default:
      return state;
  }
}
