import { AppLanguageActionTypes } from '../actions/app-language';
import { ACCOUNT_UPDATE_FAILED, USER_LOGOUT } from '../actions/types';

const appLanguageInitialState = {
  currentLanguage: 'en',
  defaultLanguage: 'en',
  isSettingLanguage: false
};

function appLanguageReducer(state = appLanguageInitialState, action) {
  switch (action.type) {
    case AppLanguageActionTypes.SET_APP_LANGUAGE:
      return { ...state, currentLanguage: action.payload };
    case AppLanguageActionTypes.SET_APP_DEFAULT_LANGUAGE:
      return { ...state, defaultLanguage: action.payload };
    case AppLanguageActionTypes.COULDNT_SET_LANGUAGE:
      return state;
    case AppLanguageActionTypes.SET_USER_LANGUAGE:
      return { ...state, isSettingLanguage: true };
    case ACCOUNT_UPDATE_FAILED:
    case AppLanguageActionTypes.SET_USER_LANGUAGE_SUCCESS:
    case AppLanguageActionTypes.SET_USER_LANGUAGE_FAIL:
      return { ...state, isSettingLanguage: false };
    case USER_LOGOUT:
      return appLanguageInitialState;
    default:
      return state;
  }
}

export default appLanguageReducer;
