import moment from 'moment-timezone';

import { isTokenExpired } from './common';
import {
  CANCEL_APPOINTMENT,
  CANCEL_APPOINTMENT_ERROR,
  CREATE_APPOINTMENT,
  GET_APPOINTMENTS,
  GET_APPOINTMENTS_ERROR,
  GET_LAST_APPOINTMENT,
  GET_LAST_APPOINTMENT_ERROR,
  GET_APPOINTMENTS_COUNT,
  GET_APPOINTMENTS_COUNT_ERROR,
  RESET_APPOINTMENTS,
  GET_APPOINTMENT_TO_RATE,
  GET_APPOINTMENT_TO_RATE_ERROR,
  LOAD_MORE_APPOINTMENTS
} from './types';
import storageUtils from '../utils/storage-helpers';
import { actionCreatorsFactory, asyncActionType, createActionTypes } from '../utils/action-helpers';
import { sendSentryError } from '../setup/sentry';
import AppointmentsAPI from '../services/appointments';
import instance from '../services';
import { convertObjToQueryString } from '../utils/url-helpers';

export const AppointmentsActionTypes = createActionTypes('Appointments', [
  asyncActionType('GET_TUTOR_APPOINTMENTS'),
  asyncActionType('REGISTER_TUTOR_ATTENDANCE'),
  asyncActionType('UPDATE_APPOINTMENT'),
  asyncActionType('CHANGE_APPOINTMENT_STATUS'),
  'CLEAN_CHANGE_APPOINTMENT_STATUS'
]);

export const cleanChangeAppointmentStatus = () => ({
  type: AppointmentsActionTypes.CLEAN_CHANGE_APPOINTMENT_STATUS
});

export function resetAppointments() {
  return async (dispatch) => {
    dispatch({
      type: RESET_APPOINTMENTS
    });
  };
}

function cancelAppointmentError() {
  return {
    type: CANCEL_APPOINTMENT_ERROR,
    payload: {}
  };
}

export function cancelAppointmentSuccess(response) {
  return {
    type: CANCEL_APPOINTMENT,
    payload: response
  };
}

export function createAppointmentSuccess(response) {
  return {
    type: CREATE_APPOINTMENT,
    payload: response
  };
}

export function getAppointmentsSuccess(data, requestMoment = null, successCallback = undefined) {
  if (requestMoment) {
    const lastLogoutMoment = storageUtils.getLastLogoutMoment();

    if (requestMoment <= lastLogoutMoment) {
      return;
    }

    if (successCallback) {
      successCallback();
    }
  }

  return {
    type: GET_APPOINTMENTS,
    payload: data
  };
}

export function loadMoreAppointmentsSuccess(data, requestMoment = null, successCallback = undefined) {
  if (requestMoment) {
    const lastLogoutMoment = storageUtils.getLastLogoutMoment();

    if (requestMoment <= lastLogoutMoment) {
      return;
    }

    if (successCallback) {
      successCallback();
    }
  }

  return {
    type: LOAD_MORE_APPOINTMENTS,
    payload: data
  };
}

function getUpcomingAppointmentsCountSuccess(response) {
  return {
    type: GET_APPOINTMENTS_COUNT,
    payload: response.data
  };
}

function getUpcomingAppointmentsCountError() {
  return {
    type: GET_APPOINTMENTS_COUNT_ERROR,
    payload: undefined
  };
}

export function getAppointmentsError() {
  return {
    type: GET_APPOINTMENTS_ERROR,
    payload: {}
  };
}

function getLastAppointmentError() {
  return {
    type: GET_LAST_APPOINTMENT_ERROR,
    payload: {}
  };
}

function getLastAppointmentSuccess(response) {
  return {
    type: GET_LAST_APPOINTMENT,
    payload: response.data.length > 0 ? response.data[0] : null
  };
}

export function declineInvitation(token, appointmentId, errorCallback, successCallback) {
  return async (dispatch) => {
    const headers = { Authorization: `Token ${token}` };
    const context = {};

    try {
      await instance.post(`/api/invitations/${appointmentId}/cancel/`, context, { headers });
      if (successCallback) {
        successCallback();
      }
    } catch (error) {
      if (!isTokenExpired(dispatch, error)) {
        if (typeof errorCallback === 'function') {
          errorCallback(error);
        }
      }
    }
  };
}

export function cancelAppointment(token, appointmentId, errorCallback, successCallback, cancelationDetails = '') {
  return async (dispatch) => {
    const headers = { Authorization: `Token ${token}` };
    const context = cancelationDetails !== '' ? { cancelationDetails } : {};

    try {
      const response = await instance.post(`/api/appointments/${appointmentId}/cancel/`, context, {
        headers
      });

      dispatch(cancelAppointmentSuccess(response));

      if (successCallback) {
        successCallback();
      }
    } catch (error) {
      if (!isTokenExpired(dispatch, error)) {
        dispatch(cancelAppointmentError(error));

        if (errorCallback) {
          errorCallback(error);
        }
      }
    }
  };
}

export function createAppointment(token, data, errorCallback, successCallback) {
  return async (dispatch) => {
    const headers = { Authorization: `Token ${token}` };

    try {
      const response = await instance.post('/api/appointments/', data, {
        headers,
        timeout: 10 * 60 * 1000
      });

      dispatch(createAppointmentSuccess(response));

      if (successCallback) {
        successCallback(response);
      }
    } catch (error) {
      if (!isTokenExpired(dispatch, error)) {
        if (errorCallback) {
          errorCallback(error);
        }
      }
    }
  };
}

export function getAppointmentsForTutorLite(token, tutorId, errorCallback, startDate = undefined, endDate = undefined, successCallback = undefined) {
  return async (dispatch) => {
    try {
      const requestMoment = moment.utc();

      const params = { tutor_id: tutorId, lite: true };
      if (startDate && endDate) {
        params.start_date = startDate.toISOString();
        params.end_date = endDate.toISOString();
      }

      const response = await instance.get(`/api/appointments?${convertObjToQueryString(params)}`, {
        headers: { Authorization: `Token ${token}` }
      });

      dispatch(getAppointmentsSuccess(response?.data, requestMoment, successCallback));

      if (successCallback) {
        successCallback(response);
      }
    } catch (error) {
      dispatch(getAppointmentsError());
      if (!isTokenExpired(dispatch, error)) {
        if (errorCallback) {
          errorCallback();
        }
      }
    }
  };
}

const getTutorAppointmentsActions = actionCreatorsFactory(
  [
    AppointmentsActionTypes.GET_TUTOR_APPOINTMENTS,
    AppointmentsActionTypes.GET_TUTOR_APPOINTMENTS_SUCCESS,
    AppointmentsActionTypes.GET_TUTOR_APPOINTMENTS_FAIL
  ],
  'getTutorAppointments'
);

export function getTutorAppointments({ token, tutorId, startDate, endDate, successCallback }) {
  return async (dispatch) => {
    try {
      dispatch(getTutorAppointmentsActions.getTutorAppointments());

      const params = {
        tutorId,
        startDate,
        endDate
      };

      const { data } = await AppointmentsAPI.getTutorAppointments(token, params);

      dispatch(getTutorAppointmentsActions.getTutorAppointmentsSuccess(data));
      if (successCallback) successCallback();
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        sendSentryError({ err, context: 'getTutorAppointments' });
        dispatch(getTutorAppointmentsActions.getTutorAppointmentsFail(err));
      }
    }
  };
}

const registerTutorAttendanceActions = actionCreatorsFactory(
  [
    AppointmentsActionTypes.REGISTER_TUTOR_ATTENDANCE,
    AppointmentsActionTypes.REGISTER_TUTOR_ATTENDANCE_SUCCESS,
    AppointmentsActionTypes.REGISTER_TUTOR_ATTENDANCE_FAIL
  ],
  'registerTutorAttendance'
);

export function registerTutorAttendance({ token, appointmentId, successCallback }) {
  return async (dispatch) => {
    try {
      dispatch(registerTutorAttendanceActions.registerTutorAttendance());
      const { data } = await AppointmentsAPI.registerTutorAttendance(token, appointmentId);
      dispatch(registerTutorAttendanceActions.registerTutorAttendanceSuccess(data));
      if (successCallback) successCallback();
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        sendSentryError({ err, context: 'registerTutorAttendance' });
        dispatch(registerTutorAttendanceActions.registerTutorAttendanceFail(err));
      }
    }
  };
}

export function loadMoreAppointmentsForTutorLite(
  token,
  tutorId,
  errorCallback,
  startDate = undefined,
  endDate = undefined,
  successCallback = undefined
) {
  return async (dispatch) => {
    const requestMoment = moment.utc();

    const params = { tutor_id: tutorId, lite: true };
    if (startDate && endDate) {
      params.start_date = startDate.toISOString();
      params.end_date = endDate.toISOString();
    }
    const headers = { Authorization: `Token ${token}` };

    try {
      const response = await instance.get(`/api/appointments/?${convertObjToQueryString(params)}`, {
        headers,
        timeout: 10 * 60 * 1000
      });

      dispatch(loadMoreAppointmentsSuccess(response?.data, requestMoment, successCallback));

      if (successCallback) {
        successCallback(response);
      }
    } catch (error) {
      dispatch(getAppointmentsError());
      if (!isTokenExpired(dispatch, error)) {
        if (errorCallback) {
          errorCallback();
        }
      }
    }
  };
}

export function getAppointmentsForTutor(token, tutorId, errorCallback, startDate = undefined, endDate = undefined, successCallback = undefined) {
  return async (dispatch) => {
    const requestMoment = moment.utc();

    const params = { tutor_id: tutorId };
    if (startDate && endDate) {
      params.start_date = startDate.toISOString();
      params.end_date = endDate.toISOString();
    }
    const headers = { Authorization: `Token ${token}` };

    try {
      const response = await instance.get(`/api/appointments/?${convertObjToQueryString(params)}`, {
        headers,
        timeout: 10 * 60 * 1000
      });

      dispatch(getAppointmentsSuccess(response?.data, requestMoment, successCallback));

      if (successCallback) {
        successCallback(response);
      }
    } catch (error) {
      dispatch(getAppointmentsError());
      if (!isTokenExpired(dispatch, error)) {
        if (errorCallback) {
          errorCallback();
        }
      }
    }
  };
}

export function getInvoiceMonthAppointments(token, tutorId, errorCallback, successCallback) {
  return async (dispatch) => {
    const headers = { Authorization: `Token ${token}` };
    const params = { for_invoice: true, tutor_id: tutorId };

    try {
      const response = await instance.get('/api/appointments/', { headers, params });
      dispatch(getAppointmentsSuccess(response.data));

      if (typeof successCallback === 'function') {
        successCallback(response);
      }
    } catch (error) {
      if (!isTokenExpired(dispatch, error)) {
        dispatch(getAppointmentsError());

        if (typeof errorCallback === 'function') {
          errorCallback(error);
        }
      }
    }
  };
}

export function getLastAppointment(token, studentId, errorCallback) {
  return async (dispatch) => {
    try {
      const headers = { Authorization: `Token ${token}` };
      const response = await instance.get('/api/appointments/?is_last=1', { headers });

      let result;

      if (response.data.length > 0) {
        result = getLastAppointmentSuccess(response);
      } else {
        result = getLastAppointmentError();
      }

      dispatch(result);
    } catch (error) {
      const expiredTokenWasHandled = isTokenExpired(dispatch, error);

      if (!expiredTokenWasHandled) {
        const appointmentsError = getAppointmentsError();
        dispatch(appointmentsError);
        errorCallback();
      }
    }
  };
}

export function getUpcomingAppointmentsCount(token, studentId, errorCallback, successCallback) {
  return async (dispatch) => {
    instance
      .get(`/api/appointments/?student_id=${studentId}&upcoming=True&get_elements_count=True`, {
        headers: { Authorization: `Token ${token}` }
      })
      .then((response) => {
        dispatch(getUpcomingAppointmentsCountSuccess(response));
        if (successCallback) {
          successCallback();
        }
      })
      .catch((err) => {
        dispatch(getUpcomingAppointmentsCountError());
        if (!isTokenExpired(dispatch, err)) {
          errorCallback();
        }
      });
  };
}

export function getUpcomingAppointmentsForStudent(token, studentId, errorCallback, successCallback) {
  return async (dispatch) => {
    try {
      const response = await instance.get(`/api/appointments/?student_id=${studentId}&upcoming=True`, {
        headers: { Authorization: `Token ${token}` }
      });
      dispatch(getAppointmentsSuccess(response.data));
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        dispatch(getAppointmentsError());
        if (errorCallback) {
          errorCallback();
        }
      }
    }
  };
}

export function getAllAppointmentsForStudent(token, studentId, errorCallback, successCallback) {
  return async (dispatch) => {
    try {
      const response = await instance.get(`/api/appointments/?student_id=${studentId}&upcoming=False`, {
        headers: { Authorization: `Token ${token}` }
      });
      dispatch(getAppointmentsSuccess(response.data));
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        dispatch(getAppointmentsError());
        if (errorCallback) {
          errorCallback();
        }
      }
    }
  };
}

const updateAppointmentActions = actionCreatorsFactory(
  [AppointmentsActionTypes.UPDATE_APPOINTMENT, AppointmentsActionTypes.UPDATE_APPOINTMENT_SUCCESS, AppointmentsActionTypes.UPDATE_APPOINTMENT_FAIL],
  'updateAppointment'
);

export function updateAppointment({ token, appointmentId, payload, successCallback }) {
  return async (dispatch) => {
    try {
      dispatch(updateAppointmentActions.updateAppointment());
      const { data } = await AppointmentsAPI.updateAppointment(token, appointmentId, payload);
      dispatch(updateAppointmentActions.updateAppointmentSuccess(data));
      if (successCallback) successCallback();
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        sendSentryError({ err, context: 'updateAppointment' });
        dispatch(updateAppointmentActions.updateAppointmentFail(err));
      }
    }
  };
}

function getAppointmentToRateError() {
  return {
    type: GET_APPOINTMENT_TO_RATE_ERROR,
    payload: {}
  };
}

function getAppointmentToRateSuccess(response) {
  return {
    type: GET_APPOINTMENT_TO_RATE,
    payload: response.data
  };
}

export function getAppointmentToRate(token, appointmentId, errorCallback, successCallback = null) {
  return async (dispatch) => {
    try {
      const response = await instance.get(`/api/appointments/${appointmentId}/`, {
        headers: { Authorization: `Token ${token}` }
      });
      dispatch(getAppointmentToRateSuccess(response));
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        dispatch(getAppointmentToRateError());
        errorCallback();
      }
    }
  };
}

const changeAppointmentStatusActions = actionCreatorsFactory(
  [
    AppointmentsActionTypes.CHANGE_APPOINTMENT_STATUS,
    AppointmentsActionTypes.CHANGE_APPOINTMENT_STATUS_SUCCESS,
    AppointmentsActionTypes.CHANGE_APPOINTMENT_STATUS_FAIL
  ],
  'changeAppointmentStatus'
);

export function changeAppointmentStatus({ token, appointmentId, status, message, successCallback }) {
  return async (dispatch) => {
    try {
      dispatch(changeAppointmentStatusActions.changeAppointmentStatus());
      const { data } = await AppointmentsAPI.changeAppointmentStatus({ token, appointmentId, status, message });
      dispatch(changeAppointmentStatusActions.changeAppointmentStatusSuccess({ data, appointmentId }));
      if (successCallback) successCallback();
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        sendSentryError({ err, context: 'changeAppointmentStatus' });
        dispatch(changeAppointmentStatusActions.changeAppointmentStatusFail(err));
      }
    }
  };
}

export function markStudentsAsNoShow(token, appointmentId, studentsNoShow, errorCallback, successCallback = null) {
  return async (dispatch) => {
    try {
      await instance.put(
        `/api/appointments/${appointmentId}/mark-no-show/`,
        { students_no_show: studentsNoShow },
        { headers: { Authorization: `Token ${token}` } }
      );
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        if (errorCallback) {
          errorCallback();
        }
      }
    }
  };
}
