import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Navbar } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';

import styles from './index.module.scss';
import SupportMessage from '../Support/Message/index';
import CardLoader from '../../design-system/loading/card/CardLoader';
import {
  addMessage,
  cleanGettingMessagesError,
  cleanMessages,
  cleanSendingMessageError,
  getConversationParticipants,
  getMessagesFromTwilio,
  joinConversation,
  setSelectedConversation
} from '../../../actions/chat';
import InputMessage from './InputMessage';
import Message from './Message';
import AnimatedDots from '../../design-system/animated-dots/AnimatedDots';
import ErrorToast from '../../design-system/toast/errorToast/ErrorToast';
import { getTwilioGenericType } from '../../../utils/file-helpers';
import { ReactComponent as BackIcon } from '../../../assets/icons/icon_left-arrow.svg';
import { DEFAULT_MESSAGES } from '../messages.enum';
import { supportChatName } from '../../../domains/helpdesk/chat';

const Messages = ({
  addMessageAction,
  cleanGettingMessagesErrorAction,
  cleanMessagesAction,
  cleanSendingMessageErrorAction,
  conversationUser,
  errorGettingMessages,
  errorGettingParticipants,
  errorGettingUsers,
  errorSendingMessage,
  getConversationParticipantsAction,
  getMessagesFromTwilioAction,
  joinConversationAction,
  isLoadingConversations,
  isGettingParticipants,
  isLoadingMessages,
  isSupport,
  messages,
  SelectedConversation,
  selectedConversationData,
  setSelectedConversationAction,
  user,
  userTyping
}) => {
  const [currentMessages, setCurrentMessages] = useState([]);
  const containerRef = useRef(null);

  useEffect(() => {
    if (SelectedConversation?.sid) {
      getMessagesFromTwilioAction({ SelectedConversation, onEmpty });
      joinConversationAction(SelectedConversation);
      getConversationParticipantsAction({
        SelectedConversation,
        token: user?.token,
        conversationUniqueName: SelectedConversation?.channelState?.uniqueName
      });
    }
  }, [SelectedConversation?.sid]);

  useEffect(() => {
    return () => cleanMessagesAction();
  }, []);

  const listenToScroll = (el) => {
    if (!el) return;
    const mutationObserver = new MutationObserver((entries) => {
      for (let mutation of entries) {
        if (mutation.type === 'childList') {
          const addedNodesArr = mutation?.addedNodes;
          const lastAdded = addedNodesArr[addedNodesArr.length - 1];
          lastAdded.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
    mutationObserver.observe(el, { childList: true });
  };

  useEffect(() => {
    listenToScroll(containerRef?.current);

    // Automatically scroll to the bottom when the component loads
    if (containerRef?.current) {
      const messagesContainer = containerRef?.current;
      const lastMessage = messagesContainer?.lastChild;
      if (lastMessage) {
        lastMessage.scrollIntoView({ behavior: 'auto' });
      }
    }
  }, [containerRef, currentMessages]);

  const otherConversationUser = () => {
    if (!selectedConversationData?.currentParticipants?.length) return;
    return selectedConversationData?.currentParticipants.find((p) => p.email !== user.email);
  };

  const onEmpty = () => {
    const user = otherConversationUser();
    const greetingsMsg = DEFAULT_MESSAGES.firstTime(user?.first_name);
    addMessageAction({
      body: greetingsMsg,
      attributes: {
        systemMessage: true
      }
    });
  };

  useEffect(() => {
    setCurrentMessages(messages);
  }, [messages]);

  const handleBack = () => {
    setSelectedConversationAction({});
  };

  const setMessagePosition = (msg) => {
    const identity = conversationUser?.state?.identity;
    if (!identity) return;
    if (identity === msg?.author) return 'right';
    return 'left';
  };

  const buildMessages = (msgs) => {
    if (!msgs?.length) return null;
    return msgs.map((msg, index) => {
      const author = selectedConversationData?.users?.find((p) => String(p.twilio_identity) === String(msg?.state?.author));
      return (
        <Message
          key={msg?.sid}
          media={msg?.media}
          date={msg?.dateCreated}
          id={msg?.sid}
          index={index}
          isLoading={isGettingParticipants}
          isSystemMessage={msg?.attributes?.systemMessage || false}
          position={setMessagePosition(msg)}
          text={msg?.body}
          title={author?.first_name || msg?.author}
          titleColor="#000"
          type={getTwilioGenericType(msg?.media?.contentType)}
        />
      );
    });
  };

  if (isLoadingConversations || isLoadingMessages)
    return (
      <div className={styles.containerWrapper}>
        <div className={styles.loadingWrapper}>
          <div className={styles.alignRight}>
            <CardLoader rows={4} rowsColor="gray" />
          </div>
          <div className={styles.alignLeft}>
            <CardLoader rows={4} rowsColor="gray" />
          </div>
          <div className={styles.alignRight}>
            <CardLoader rows={4} rowsColor="gray" />
          </div>
        </div>
      </div>
    );

  return (
    <div className={styles.containerWrapper}>
      <Navbar
        className={styles.navBar}
        left={
          <div className={styles.title}>
            <button onClick={handleBack} className={styles.backButton}>
              <BackIcon />
            </button>
            <p>{isSupport ? supportChatName : SelectedConversation?.friendlyName}</p>
          </div>
        }
      />
      <div ref={containerRef} className={styles.messagesContainer}>
        {isSupport ? <SupportMessage /> : buildMessages(currentMessages)}
        {userTyping && (
          <p className={styles.typing}>
            {userTyping?.friendlyName || 'Otavio'} is typing
            <AnimatedDots />
          </p>
        )}
      </div>
      {isSupport ? null : <InputMessage disabled={!SelectedConversation?.sid} />}
      <ErrorToast errorMsg="Something went wrong while sending your message." onClose={cleanSendingMessageErrorAction} show={errorSendingMessage} />
      <ErrorToast
        errorMsg="Something went wrong while getting your messages."
        onClose={cleanGettingMessagesErrorAction}
        show={errorGettingMessages}
      />
      <ErrorToast
        errorMsg="Something went wrong while getting this conversation participants names."
        onClose={cleanGettingMessagesErrorAction}
        show={errorGettingParticipants}
      />
      <ErrorToast
        errorMsg="Something went wrong while getting this conversation users."
        onClose={cleanGettingMessagesErrorAction}
        show={errorGettingUsers}
      />
    </div>
  );
};

Messages.propTypes = {
  addMessageAction: PropTypes.func.isRequired,
  cleanGettingMessagesErrorAction: PropTypes.func.isRequired,
  cleanMessagesAction: PropTypes.func.isRequired,
  cleanSendingMessageErrorAction: PropTypes.func.isRequired,
  conversationUser: PropTypes.object.isRequired,
  errorGettingMessages: PropTypes.bool.isRequired,
  errorSendingMessage: PropTypes.bool.isRequired,
  errorGettingParticipants: PropTypes.bool.isRequired,
  errorGettingUsers: PropTypes.bool.isRequired,
  getConversationParticipantsAction: PropTypes.func.isRequired,
  getMessagesFromTwilioAction: PropTypes.func.isRequired,
  joinConversationAction: PropTypes.func.isRequired,
  isGettingParticipants: PropTypes.bool.isRequired,
  isLoadingConversations: PropTypes.bool.isRequired,
  isLoadingMessages: PropTypes.bool.isRequired,
  isSupport: PropTypes.bool,
  messages: PropTypes.array.isRequired,
  SelectedConversation: PropTypes.object.isRequired,
  selectedConversationData: PropTypes.object.isRequired,
  setSelectedConversationAction: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  userTyping: PropTypes.object
};

const mapStateToProps = (state) => ({
  conversationUser: state.chat.conversations?.user,
  errorGettingMessages: state.chat.messages?.errorGettingMessages,
  errorSendingMessage: state.chat.messages?.errorSendingMessage,
  errorGettingParticipants: state.chat.conversations?.errorGettingParticipants,
  errorGettingUsers: state.chat.conversations?.errorGettingUsers,
  isLoadingConversations: state.chat.conversations?.isLoadingConversations,
  isGettingParticipants: state.chat.conversations?.isGettingParticipants,
  isLoadingMessages: state.chat.messages?.isLoadingMessages,
  isSupport: state.chat?.conversations?.isSupport,
  messages: state.chat.messages?.messages,
  SelectedConversation: state.chat.conversations?.SelectedConversation,
  selectedConversationData: state.chat.conversations?.selectedConversationData,
  user: state.user,
  userTyping: state.chat.messages?.userTyping
});

export default connect(mapStateToProps, {
  addMessageAction: addMessage,
  cleanGettingMessagesErrorAction: cleanGettingMessagesError,
  cleanMessagesAction: cleanMessages,
  cleanSendingMessageErrorAction: cleanSendingMessageError,
  getConversationParticipantsAction: getConversationParticipants,
  getMessagesFromTwilioAction: getMessagesFromTwilio,
  joinConversationAction: joinConversation,
  setSelectedConversationAction: setSelectedConversation
})(Messages);
