import React from 'react';
import PropTypes from 'prop-types';

import styles from './SingleTextLoader.module.scss';

const SingleTextLoader = ({ rowColor }) => <div className={[styles.loading, styles[rowColor]].join(' ')} />;

SingleTextLoader.propTypes = {
  rowColor: PropTypes.string
};

SingleTextLoader.defaultProps = {
  rowColor: 'gray'
};

export default SingleTextLoader;
