import React from 'react';

import styles from './legend.module.scss';

const Legend = () => {
  return (
    <div className={styles.wrapper}>
      <div id={styles.existing}>
        <div />
        <p>Existing</p>
      </div>
      <div id={styles.selected}>
        <div />
        <p>Selected</p>
      </div>
      <div id={styles.current}>
        <div />
        <p>Current</p>
      </div>
    </div>
  );
};

export default Legend;
