import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import formStyles from '../../../style/utils/_form.module.scss';

const RegularFormField = ({ disabled, icon, id, initialValue, invalidMessage, isValid, label, type, onChange, value }) => {
  const [showError, setShowError] = useState(false);
  const fieldValue = value || initialValue || '';

  useEffect(() => {
    if (typeof isValid === 'boolean') showWhetherIsValid();
  }, [isValid]);

  const handleUserTyping = (evt) => {
    onChange(evt);
    clearValidationError();
  };

  const showWhetherIsValid = () => {
    if (!value) return setShowError(false);

    // setValid(isValid);
    setShowError(!isValid);
  };

  const clearValidationError = () => {
    // setValid(true);
    setShowError(false);
  };

  return (
    <div className={[formStyles['field-wrapper'], showError && formStyles.invalidField].join(' ')}>
      <label className={formStyles.label} htmlFor={id}>
        {label}
      </label>
      <div className={formStyles['field-wrapper__with-icon']}>
        <input id={id} type={type} onChange={handleUserTyping} value={fieldValue} onBlur={showWhetherIsValid} disabled={disabled} />
        {icon && <div className={[formStyles['icon'], !value && formStyles['disabled']].join(' ')}>{icon}</div>}
      </div>
      {showError && <p className={formStyles.invalidMessage}>{invalidMessage}</p>}
    </div>
  );
};

RegularFormField.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  id: PropTypes.string,
  initialValue: PropTypes.string,
  invalidMessage: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func,
  validators: PropTypes.array,
  value: PropTypes.string.isRequired
};

RegularFormField.defaultProps = {
  id: '',
  isValid: true,
  onChange: () => {},
  type: 'text'
};

export default RegularFormField;
