import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DropdownAsync from '../../../design-system/dropdown-async/DropdownAsync';
import { getTicketTypes } from '../../../../actions/helpdesk';

import styles from './SelectType.module.scss';

const SelectType = ({ getTicketTypesAction, isGettingTicketFields, selectedSupportType, setSelectedSupportType, ticketTypes }) => {
  return (
    <div className={styles.wrapper}>
      <DropdownAsync
        defaultValue={selectedSupportType}
        onChange={(value) => setSelectedSupportType(value)}
        options={ticketTypes}
        getOptions={getTicketTypesAction}
        isLoading={isGettingTicketFields}
      />
    </div>
  );
};

SelectType.propTypes = {
  getTicketTypesAction: PropTypes.func.isRequired,
  isGettingTicketFields: PropTypes.bool.isRequired,
  selectedSupportType: PropTypes.string,
  setSelectedSupportType: PropTypes.func.isRequired,
  ticketTypes: PropTypes.array
};

const mapStateToProps = (state) => ({
  isGettingTicketFields: state.helpdesk.isGettingTicketFields,
  ticketTypes: state.helpdesk.ticketTypes
});

export default connect(mapStateToProps, { getTicketTypesAction: getTicketTypes })(SelectType);
