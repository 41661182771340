import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../design-system/button/Button';
import CardLoader from '../../design-system/loading/card/CardLoader';

import { openBookingModal } from '../../../actions/new-booking';
import { setProductType } from '../../../actions/selected-product-type';
import { setSelectedLanguage } from '../../../actions/selected-language';

import { selectProductType } from '../../../reducers/order-info';

import styles from './GeneralCard.module.scss';
import { CLASS_TYPES } from '../Enum';
import { UserLicenceTypes } from '../../../domains/user/user-licence-types';

const LessonsCard = ({
  courseDetails,
  isGettingCourseDetails,
  isLoadingLessonsStatus,
  lessonsWithBookingStatus,
  licenceType,
  matchedTutor,
  productType,
  selectedTutor,
  openBookingModalAction,
  setProductTypeAction,
  setSelectedLanguageAction
}) => {
  const [isEverythingBooked, setIsEverythingBooked] = useState(false);
  const history = useHistory();

  const checkWhetherEverythingIsBooked = (lessonsWithBookingStatus) => {
    const { booked, happened } = lessonsWithBookingStatus;
    setIsEverythingBooked(booked?.length + happened?.length === courseDetails?.lessons?.length);
  };

  useEffect(() => {
    checkWhetherEverythingIsBooked(lessonsWithBookingStatus);
  }, [lessonsWithBookingStatus]);

  const handleBookLessons = () => {
    if (selectedTutor.id || matchedTutor.id) {
      openBookingModalAction();
    } else {
      setProductTypeAction(productType);
      setSelectedLanguageAction(courseDetails?.language);
      history.push('/find-tutor');
    }
  };

  const getSessionsRemaining = () => {
    if (licenceType === UserLicenceTypes.subscription) return;

    const left = lessonsWithBookingStatus?.notBooked?.length;

    if (left > 1)
      return (
        <p className={styles['missing-lessons--wrapper']}>
          <span id="dont-translate">{left}</span> Live Practices remaining
        </p>
      );
    return (
      <p className={styles['missing-lessons--wrapper']}>
        <span id="dont-translate">{left}</span> Live Practice remaining
      </p>
    );
  };

  if (isEverythingBooked)
    return (
      <div className={styles.cardWrapper} data-testid="lessons-card-all-booked">
        <div className={[styles.contentContainer, styles['all-booked']].join(' ')}>
          <div className={styles['profile-picture--wrapper']}>
            <img src={selectedTutor?.profile_picture} alt="" />
          </div>
          <div className={styles['card-data--wrapper']}>
            <h2>Well done!</h2>
            <p>All of your Live Practices are booked.</p>
          </div>
        </div>
      </div>
    );

  return (
    <div className={styles.cardWrapper} data-testid="lessons-card">
      {isLoadingLessonsStatus || isGettingCourseDetails ? (
        <div className={styles.loaderContainer}>
          <CardLoader rows={4} rowsColor="gray" />
        </div>
      ) : (
        <div className={styles.contentContainer}>
          <div className={styles['profile-picture--wrapper']}>
            <img src={selectedTutor?.profile_picture} alt="" data-testid="coach-picture" />
          </div>
          <div className={styles['card-data--wrapper']}>
            <Button
              onClick={handleBookLessons}
              outfit="greenOpaqueButton"
              disabled={!lessonsWithBookingStatus?.notBooked?.length}
              testId="book-live-session-button">
              <div className={styles['button--wrapper']}>
                <h2>
                  Book new <br />
                  Live Practice
                </h2>
              </div>
            </Button>
            {getSessionsRemaining()}
          </div>
        </div>
      )}
    </div>
  );
};

LessonsCard.propTypes = {
  courseDetails: PropTypes.object.isRequired,
  isGettingCourseDetails: PropTypes.bool.isRequired,
  isLoadingLessonsStatus: PropTypes.bool.isRequired,
  lessonsWithBookingStatus: PropTypes.object.isRequired,
  licenceType: PropTypes.string,
  matchedTutor: PropTypes.object.isRequired,
  productType: PropTypes.object.isRequired,
  selectedTutor: PropTypes.object.isRequired,
  openBookingModalAction: PropTypes.func.isRequired,
  setProductTypeAction: PropTypes.func.isRequired,
  setSelectedLanguageAction: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  courseDetails: state.courses?.selectedCourseDetails?.courseDetails,
  isGettingCourseDetails: state.courses.selectedCourseDetails.isGettingCourseDetails,
  isLoadingLessonsStatus: state.sessions.tuitionSessions.isLoadingLessonsStatus,
  lessonsWithBookingStatus: state.sessions.tuitionSessions.currentLessonsStatus,
  licenceType: state.user.licence_type,
  matchedTutor: state.selectedTutor.matchedTutor,
  productType: selectProductType(state.orderInfo, CLASS_TYPES.tuition),
  selectedTutor: state.selectedTutor.selectedTutor
});

LessonsCard.defaultProps = {
  isGettingCourseDetails: false
};

export default connect(mapStateToProps, {
  openBookingModalAction: openBookingModal,
  setProductTypeAction: setProductType,
  setSelectedLanguageAction: setSelectedLanguage
})(LessonsCard);
