import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import Mixpanel from '../../utils/mixpanel-helper.js';
import { hideOnboarding } from '../../actions/user';
import styles from '../../style/containers/Onboarding.module.scss';
import { getTutorInfo } from '../../actions/tutor-info';

class StepTutorFinal extends Component {
  componentDidMount() {
    const { user } = this.props;
    Mixpanel.identify(user.id);
    Mixpanel.track('Visited onboarding final step');
    this.props.getTutorInfo(this.props.user.token);
    this.props.hideOnboarding(this.props.user.token);
  }

  render() {
    const { tutorProfilePreview } = this.props;

    return (
      <div className={styles.stepWrapper}>
        <h1 className={styles.onboardingTitle}>Your Profile is complete!</h1>
        <h2 className={[styles.onboardingHeader, styles.onboardingText].join(' ')}>
          Thank you for taking the time to complete your profile. Do you want to see how it looks?
        </h2>

        <div className={styles.buttonContainer}>
          <Button
            className={styles.backToDashboardButton}
            onClick={() => {
              Mixpanel.track('Click', { position: 'FinalStep', button: 'tutor_see_profile' });
              tutorProfilePreview();
            }}>
            See my profile
          </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

StepTutorFinal.propTypes = {
  user: PropTypes.object.isRequired,
  hideOnboarding: PropTypes.func.isRequired,
  tutorProfilePreview: PropTypes.func.isRequired,
  getTutorInfo: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { hideOnboarding, getTutorInfo })(StepTutorFinal);
