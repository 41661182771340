import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../Alert';

const SuccessAlert = ({ actions, Icon, isVisible, onClose, text, title }) => (
  <Alert actions={actions} Icon={Icon} isVisible={isVisible} onClose={onClose} text={text} type="success" title={title} />
);
SuccessAlert.propTypes = {
  actions: PropTypes.array,
  Icon: PropTypes.element,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string
};

export default SuccessAlert;
