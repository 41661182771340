export const UNIT_COMPLETED_STATUS = Object.freeze({
  PAID_NO_SHOW: 'paid_no_show',
  INVOICED_NO_SHOW: 'invoiced_no_show',
  PAID: 'paid',
  ALL_NOSHOW: 'all_noshow',
  CANCELED_LATE: 'canceled_late',
  HAPPENED: 'happened',
  STUDENT_NOSHOW: 'student_noshow',
  INVOICED: 'invoiced',
  PAID_CANCELED_LATE: 'paid_canceled_late',
  INVOICED_CANCELED_LATE: 'invoiced_canceled_late',
  COMPLETED: 'completed'
});
