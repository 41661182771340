import React from 'react';
import PropTypes from 'prop-types';

import IconInfo from '../../../assets/icons/icon_info.svg';

import styles from './Tooltip.module.scss';

const Tooltip = ({ icon, tooltipLabel, backgroundColor, position }) => {
  return (
    <span className={[styles.tooltip, !tooltipLabel && styles['just-icon']].join(' ')}>
      <span className={[styles.icon]}>{icon}</span>
      {tooltipLabel && <span className={[styles.tooltip__label, styles[backgroundColor], styles[position]].join(' ')}>{tooltipLabel}</span>}
    </span>
  );
};

Tooltip.propTypes = {
  backgroundColor: PropTypes.string,
  icon: PropTypes.element,
  tooltipLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  position: PropTypes.oneOf(['top', 'top-right', 'left', 'right', 'bottom', 'bottom-right'])
};

Tooltip.defaultProps = {
  backgroundColor: 'transparent',
  icon: <img src={IconInfo} alt="tooltip" />,
  position: 'right'
};

export default Tooltip;
