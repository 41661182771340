import React from 'react';
import PropTypes from 'prop-types';
import { MessageBox } from 'react-chat-elements';
import { urlify } from '../../../../utils/chat-helpers';
import 'react-chat-elements/dist/main.css';

const TextMessage = ({ className, date, id, position, text, title, titleColor, type }) => {
  return (
    <MessageBox
      className={className}
      id={id}
      date={date}
      position={position}
      text={
        text
          ? text.split('\n').map((token) => (
              <>
                {urlify(token)}
                <br />
              </>
            ))
          : ''
      }
      title={title}
      titleColor={titleColor}
      type={type}
    />
  );
};

TextMessage.propTypes = {
  className: PropTypes.string,
  customRef: PropTypes.object,
  date: PropTypes.instanceOf(Date).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  position: PropTypes.string.isRequired,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default TextMessage;
