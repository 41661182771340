const PREFERRED_LANGUAGE = 'Arabic';
export const GROUP_SESSION = 'Group Session';
export const INDIVIDUAL_SESSION = 'Individual Session';

export default function getProductTypesFromOrders(orders, defaultProductTypes, considerLastLanguage = false) {
  const productTypes = [];
  if (orders) {
    orders.sort((a, b) => b.id - a.id);

    // For each order
    orders.map((order) => {
      const productTypesInOrder = [];

      // If the order has more than one language associated, show first the one
      // we want to give more priority to. Also make sure that appointment types
      // associated to group sessions appear last because they might cause problems
      // with the credit computation
      if (order.order_appointment_types.length > 1) {
        order.order_appointment_types.sort((a, b) => {
          if (a.is_for_group_sessions === b.is_for_group_sessions) {
            return 0;
          } else if (a.is_for_group_sessions) {
            return 1;
          }
          return -1;
        });
      }

      // For each appointment type associated to the order
      return order.order_appointment_types.forEach((productType) => {
        // Check if the product type has already been found in other orders
        const matchingProductType = productTypes.find(
          (alreadyFoundProductType) =>
            productType.training_type === alreadyFoundProductType.trainingType && productType.delivery_type === alreadyFoundProductType.deliveryType
        );

        // Read the number of credits associated with the order
        let credits = order.order_type === 'credit' ? parseFloat(order.remaining) / parseFloat(productType.cost) : parseFloat(order.remaining);

        if (order.is_expired) {
          credits = 0;
        }

        if (matchingProductType) {
          // This product type already exists in the local array of products
          // Keep track of the orders
          matchingProductType.orders.push(order);

          let matchingProductTypeInOrder = productTypesInOrder.find(
            (alreadyFoundProductType) =>
              productType.training_type === alreadyFoundProductType.trainingType && productType.delivery_type === alreadyFoundProductType.deliveryType
          );
          // Add the credits
          if (matchingProductTypeInOrder === undefined) {
            if (productType.is_for_group_sessions) {
              credits /= 2;
            }

            /* const multiplier = DURATIONS_MULTIPLIERS.find(dm => dm.duration === productType.duration);
            credits *= multiplier.multiplier; */

            matchingProductType.remainingCredits += credits;

            if (matchingProductType.organization === null && productType.organization) {
              matchingProductType.organization = productType.organization;
              matchingProductType.showCredits = productType.show_credits;
            }

            productTypesInOrder.push(matchingProductType);
          } else {
            // Check if need to add duration
            matchingProductTypeInOrder = productTypesInOrder.find(
              (alreadyFoundProductType) =>
                productType.training_type === alreadyFoundProductType.trainingType &&
                productType.delivery_type === alreadyFoundProductType.deliveryType &&
                productType.duration === alreadyFoundProductType.duration
            );
            if (matchingProductTypeInOrder === undefined) {
              // add the duration
              if (matchingProductType.durations) {
                if (!matchingProductType.durations.find((d) => d === productType.duration)) {
                  matchingProductType.durations.push(productType.duration);
                }
              }
            }

            // mark matchingProductType field groupSessionAvailable with the matchingProductType
            // value if the matchingProductType value is false
            if (!matchingProductType.groupSessionAvailable) {
              matchingProductType.groupSessionAvailable = productType.is_for_group_sessions;
            }

            if (productType.duration === 60 && !productType.is_for_group_sessions && !matchingProductType.fullIndividualClass) {
              matchingProductType.fullIndividualClass = true;
            }
          }
        } else {
          // This product type hasn't been found before, we need to create a new entry
          if (productType.is_for_group_sessions) {
            credits /= 2;
          }

          /* const multiplier = DURATIONS_MULTIPLIERS.find(dm => dm.duration === productType.duration);
          credits *= multiplier.multiplier; */

          // add the product type, including credits
          let fullIndividualClass = false;
          if (productType.duration === 60 && !productType.is_for_group_sessions) {
            fullIndividualClass = true;
          }
          const productTypeToAdd = {
            deliveryType: productType.delivery_type,
            id: productType.product_type,
            image: productType.product_type_img,
            isShared: order.is_shared,
            orders: [order],
            trainingType: productType.training_type,
            organization: productType.organization,
            cost: productType.cost,
            prices: productType.prices,
            orderType: order.order_type,
            remainingCredits: credits,
            isForGroupSessions: productType.is_for_group_sessions,
            showCredits: productType.show_credits,
            prioritaryDuration: productType.duration,
            fullIndividualClass
          };

          if (considerLastLanguage) {
            productTypeToAdd.lastLanguage = productType.language_name;
          }

          if (defaultProductTypes) {
            const matchingDefaultProducts = defaultProductTypes.filter(
              (defaultProductType) =>
                defaultProductType.training_type === productTypeToAdd.trainingType &&
                defaultProductType.delivery_type === productTypeToAdd.deliveryType
            );

            const languagesToAdd = [];
            const durations = [];
            /* let groupSessionAvailable = false; */
            matchingDefaultProducts.map((matchingDefaultProduct) =>
              matchingDefaultProduct.languages.forEach((language) => {
                if (languagesToAdd.find((l) => language.language === l.language) === undefined) {
                  languagesToAdd.push(language);
                }
              })
            );
            durations.push(productType.duration);

            /* matchingDefaultProducts.forEach((matchingDefaultProduct) => {
              // if (!durations.find(d => d === matchingDefaultProduct.duration)) {
              //   durations.push(matchingDefaultProduct.duration);
              // }
              // check if we need to make group session available
              groupSessionAvailable = groupSessionAvailable ||
                matchingDefaultProduct.is_for_group_sessions;
            }); */

            languagesToAdd.sort((a, b) => {
              if (a.language === PREFERRED_LANGUAGE) {
                return -1;
              } else if (b.language === PREFERRED_LANGUAGE) {
                return 1;
              } else if (a.language < b.language) {
                return -1;
              } else if (a.language > b.language) {
                return 1;
              }
              return 0;
            });
            productTypeToAdd.languages = languagesToAdd;
            productTypeToAdd.durations = durations;
            productTypeToAdd.groupSessionAvailable = productType.is_for_group_sessions;
            // TODO verify what these two lines were for
            // const langaugeIndex = productTypeToAdd.languages.findIndex((el => el.language === productType.language_name));
            // productTypeToAdd.languages.splice(0, 0, productTypeToAdd.languages.splice(langaugeIndex, 1)[0]);
          }
          productTypes.push(productTypeToAdd);
          productTypesInOrder.push(productTypeToAdd);
        }
      });
    });
  }
  return productTypes;
}
