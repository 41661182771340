import React, { useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { isSameMinute } from 'date-fns';
import PropTypes from 'prop-types';

import BookingSummaryItem from '../BookingSummaryItem';
import ErrorToast from '../../../design-system/toast/errorToast/ErrorToast';

import { closeReschedulingModal, rescheduleBulkAppointmentsAndGetUpdatedStudentsBooking, cleanErrorState } from '../../../../actions/new-booking';

import { convertStringsOfDateAndTimeToDate, getDateFromIsoString, getTimeFromIsoString } from '../../../../utils/time-helpers';
import SessionsManagementModal from '../SessionsManagementModal';
import { selectProductType } from '../../../../reducers/order-info';
import mixpanel from '../../../../utils/mixpanel-helper';
import {
  cleanSelectedTimestamp,
  preBookDate,
  preBookWholeMonthDate,
  removeFromPreBook,
  removeMonthFromPreBook,
  storeMonthSessionsAvailability
} from '../../../../actions/bookable';
import { calculateBookings } from '../booking/domain/calculateBookings';
import { getSessionsToRescheduleId } from '../../../../reducers/new-booking';
import { buildMonthAndYearStr } from '../../../../reducers/bookable';
import SessionsAlert from '../sessions-alert/sessions-alert';
import { getMonthTranslated } from '../../../design-system/calendar/TimeRelated.enum';
import { CLASS_TYPES } from '../../Enum';

const ReschedulingSessionsModal = ({
  availableSessionsThisMonth,
  calendar,
  cleanErrorStateAction,
  cleanSelectedTimestampAction,
  closeReschedulingModalAction,
  currentLang,
  errorMessage,
  hasErrorOnRescheduling,
  isLoadingFutureScheduleAvailability,
  isReschedulingAppointment,
  pendingToBeRescheduled,
  preBookDateAction,
  preBookWholeMonthDateAction,
  productType,
  removeFromPreBookAction,
  removeMonthFromPreBookAction,
  rescheduleBulkAppointmentsAndGetUpdatedStudentsBookingAction,
  reschedulingType,
  selectedCourseId,
  selectedTimestamp,
  sessionsPerMonth,
  storeMonthSessionsAvailabilityAction,
  student,
  timezone
}) => {
  const [activeAppointmentIndex, setActiveAppointmentIndex] = useState(0);
  const [currentTutor, setCurrentTutor] = useState({ id: null });
  const [newSchedule, setNewSchedule] = useState([]);
  const [updatedLesson, setUpdatedLesson] = useState(null);
  const ignoreIds = useSelector(getSessionsToRescheduleId);

  const handleTutorImplicitSelection = (lessonIndex) => {
    if (lessonIndex > pendingToBeRescheduled.length - 1) return;
    if (!pendingToBeRescheduled[lessonIndex]) return;
    const { appointment_object: appointment } = pendingToBeRescheduled[lessonIndex];
    if (appointment && appointment.tutor)
      setCurrentTutor((prevTutor) => {
        if (prevTutor?.id === appointment?.tutor?.id) return prevTutor;
        return appointment.tutor;
      });
  };

  useEffect(() => {
    handleTutorImplicitSelection(0);
  }, []);

  const defineNewAppointment = (appointmentIndex, timestamp) => {
    const newAppointment = {
      ...pendingToBeRescheduled[appointmentIndex],
      newDateAndTime: timestamp
    };
    setUpdatedLesson(newAppointment);
  };

  useEffect(() => {
    if (selectedTimestamp) defineNewAppointment(activeAppointmentIndex, selectedTimestamp);
  }, [activeAppointmentIndex, selectedTimestamp]);

  const handleSettingNewActiveLesson = (index) => {
    setActiveAppointmentIndex(index);
    handleTutorImplicitSelection(index);
  };

  const blockSchedule = (timestamp) => {
    preBookDateAction(timestamp);
  };

  const unblockSchedule = (timestamp) => {
    removeFromPreBookAction(timestamp);
  };

  const shouldBlockMonth = (response) => {
    const canBook = response?.canBookThisMonth;
    return typeof canBook === 'boolean' && !canBook;
  };

  const transformIntoMap = (timestamps) => {
    const map = new Map();
    timestamps.forEach((ts) => {
      const key = buildMonthAndYearStr(ts);
      const prev = map.get(key) || [];
      map.set(key, [...prev, ts]);
    });
    return Object.fromEntries(map);
  };

  const calculateSessions = (booked, timestamp) => {
    const selectedTimestamps = transformIntoMap(booked.map(({ newDateAndTime }) => newDateAndTime));
    const selectedTimestamp = timestamp || calendar?.date;
    const response = calculateBookings({
      selectedTimestamp,
      selectedTimestamps,
      sessionsPerMonth
    });

    storeMonthSessionsAvailabilityAction({ timestamp: selectedTimestamp, response });
    if (shouldBlockMonth(response)) {
      preBookWholeMonthDateAction(selectedTimestamp);
    }
  };

  const recalculateSessionsAfterExclusion = (booked, timestamp) => {
    const selectedTimestamps = transformIntoMap(booked.map(({ newDateAndTime }) => newDateAndTime));
    const selectedTimestamp = timestamp || calendar?.date;
    const response = calculateBookings({
      selectedTimestamp,
      selectedTimestamps,
      sessionsPerMonth
    });

    storeMonthSessionsAvailabilityAction({ timestamp: selectedTimestamp, response });
    if (!shouldBlockMonth(response)) {
      removeMonthFromPreBookAction(selectedTimestamp);
    }
  };

  const addNewScheduleToFinalList = () => {
    if (!updatedLesson) return;
    const { appointment_object: previousAppointment } = updatedLesson;
    const previousDate = convertStringsOfDateAndTimeToDate(previousAppointment.date, previousAppointment.start_time);
    if (isSameMinute(previousDate, new Date(updatedLesson.newDateAndTime))) return;
    setNewSchedule([...newSchedule, updatedLesson]);
    blockSchedule(updatedLesson.newDateAndTime);
    calculateSessions([...newSchedule, updatedLesson]);
    handleSettingNewActiveLesson(activeAppointmentIndex + 1);
    cleanSelectedTimestampAction();
  };

  const removeNewScheduleFromFinalList = (item) => {
    const filteredElement = newSchedule.find(({ appointment_object }) => appointment_object?.id === item.appointment_object.id);
    const filteredList = newSchedule.filter(({ appointment_object }) => appointment_object?.id !== item.appointment_object.id);
    setNewSchedule(filteredList);
    recalculateSessionsAfterExclusion(filteredList, filteredElement?.newDateAndTime);
    unblockSchedule(filteredElement.newDateAndTime);
    cleanSelectedTimestampAction();
  };

  const handleAppointmentsRescheduling = () => {
    const appointmentsData = newSchedule.map((lesson) => ({
      id: lesson.appointment_object.id,
      date: getDateFromIsoString(lesson.newDateAndTime),
      start_time: getTimeFromIsoString(lesson.newDateAndTime)
    }));

    rescheduleBulkAppointmentsAndGetUpdatedStudentsBookingAction(student.token, selectedCourseId, appointmentsData, () => {
      mixpanel.lessonAction('Rescheduled', {
        type: 'tuition or convo'
      });
    });
  };

  const getNewAppointmentTime = (currentLessonId) => {
    const futureAppointment = newSchedule.find(({ appointment_object }) => appointment_object.id === currentLessonId);
    if (!futureAppointment) return;
    return futureAppointment.newDateAndTime;
  };

  const getSelectedTimestamps = useMemo(() => {
    return newSchedule.map(({ newDateAndTime }) => newDateAndTime);
  }, [newSchedule]);

  const Alert = <SessionsAlert availableSessionsPerMonth={availableSessionsThisMonth} monthName={getMonthTranslated(currentLang)[calendar?.month]} />;

  const getModalTitle = () => (reschedulingType === CLASS_TYPES.tuition ? 'Rescheduling Live Practices' : 'Rescheduling Convo Practices');

  const getModalColor = () => (reschedulingType === CLASS_TYPES.tuition ? 'mint' : 'salmon');

  return (
    <>
      <SessionsManagementModal
        currentTutor={currentTutor}
        disabledCalendar={isReschedulingAppointment || newSchedule.length === pendingToBeRescheduled.length}
        disabledActionButton={newSchedule.length !== pendingToBeRescheduled.length}
        futureSchedule={pendingToBeRescheduled}
        handleExecution={handleAppointmentsRescheduling}
        ignoreIds={ignoreIds}
        isExecuting={isReschedulingAppointment}
        isLoading={isLoadingFutureScheduleAvailability}
        modalMainColor={getModalColor()}
        modalTitle={getModalTitle()}
        onCloseModal={() => closeReschedulingModalAction()}
        productType={productType}
        renderAlert={() => Alert}
        type={reschedulingType}
        renderAppointmentItem={(lesson, index) => (
          <BookingSummaryItem
            borderColor={getModalColor()}
            key={index}
            reschedule
            active={index === activeAppointmentIndex}
            setNewActiveAppointment={handleSettingNewActiveLesson.bind(this, index)}
            done={newSchedule.some(({ appointment_object }) => appointment_object?.id === lesson?.appointment_object?.id)}
            doneScheduling={addNewScheduleToFinalList}
            removeNewScheduleFromFinalList={removeNewScheduleFromFinalList}
            lesson={lesson}
            interimTimestamp={selectedTimestamp}
            finalTimestamp={getNewAppointmentTime(lesson?.appointment_object?.id)}
            testId="reschedule-session"
            timezone={timezone}
          />
        )}
        selectedTimestamp={selectedTimestamp}
        selectedTimestamps={getSelectedTimestamps}
        testId="rescheduling-tuition-session-modal"
      />
      <ErrorToast errorMsg={errorMessage} show={hasErrorOnRescheduling} onClose={cleanErrorStateAction} />
    </>
  );
};

const mapStateToProps = (state) => {
  const reschedulingType = state.newBooking.rescheduling.sessionType;
  return {
    availableSessionsThisMonth: state.bookable.available.availableSessionsThisMonth,
    calendar: state.bookable.available.calendar,
    currentLang: state.appLanguage.currentLanguage,
    errorMessage: state.newBooking.rescheduling.error,
    hasErrorOnRescheduling: state.newBooking.rescheduling.hasErrorOnRescheduling,
    isLoadingFutureScheduleAvailability: state.bookable.futureSchedule.isLoadingFutureSchedule,
    isReschedulingAppointment: state.newBooking.rescheduling.isReschedulingAppointment,
    pendingToBeRescheduled: state.newBooking.rescheduling.appointmentsToBeRescheduled,
    productType: selectProductType(state.orderInfo, reschedulingType),
    reschedulingType,
    selectedCourseId: state.courses?.selectedCourseDetails?.courseDetails?.id,
    selectedTimestamp: state.bookable.available.calendar?.selectedTimestamp,
    sessionsPerMonth: state.bookable.available.sessionsPerMonth,
    student: state.user,
    timezone: state.time.timezone
  };
};

ReschedulingSessionsModal.propTypes = {
  availableSessionsThisMonth: PropTypes.object.isRequired,
  calendar: PropTypes.object.isRequired,
  cleanErrorStateAction: PropTypes.func.isRequired,
  cleanSelectedTimestampAction: PropTypes.func.isRequired,
  closeReschedulingModalAction: PropTypes.func.isRequired,
  currentLang: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  hasErrorOnRescheduling: PropTypes.bool,
  isLoadingFutureScheduleAvailability: PropTypes.bool,
  isReschedulingAppointment: PropTypes.bool,
  pendingToBeRescheduled: PropTypes.array.isRequired,
  preBookDateAction: PropTypes.func.isRequired,
  preBookWholeMonthDateAction: PropTypes.func.isRequired,
  productType: PropTypes.object.isRequired,
  removeFromPreBookAction: PropTypes.func.isRequired,
  removeMonthFromPreBookAction: PropTypes.func.isRequired,
  rescheduleBulkAppointmentsAndGetUpdatedStudentsBookingAction: PropTypes.func.isRequired,
  reschedulingType: PropTypes.string,
  selectedCourseId: PropTypes.number.isRequired,
  selectedTimestamp: PropTypes.string,
  sessionsPerMonth: PropTypes.object,
  storeMonthSessionsAvailabilityAction: PropTypes.func.isRequired,
  student: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired
};

ReschedulingSessionsModal.defaultProps = {
  isReschedulingAppointment: false
};

export default connect(mapStateToProps, {
  cleanErrorStateAction: cleanErrorState,
  cleanSelectedTimestampAction: cleanSelectedTimestamp,
  closeReschedulingModalAction: closeReschedulingModal,
  preBookDateAction: preBookDate,
  preBookWholeMonthDateAction: preBookWholeMonthDate,
  removeFromPreBookAction: removeFromPreBook,
  removeMonthFromPreBookAction: removeMonthFromPreBook,
  rescheduleBulkAppointmentsAndGetUpdatedStudentsBookingAction: rescheduleBulkAppointmentsAndGetUpdatedStudentsBooking,
  storeMonthSessionsAvailabilityAction: storeMonthSessionsAvailability
})(ReschedulingSessionsModal);
