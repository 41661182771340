import React from 'react';
import PropTypes from 'prop-types';

import styles from './Link.module.scss';

const Link = ({ children, href, testId }) => (
  <a className={styles.link} href={href} target="_blank" rel="noopener noreferrer" data-testid={testId}>
    {children}
  </a>
);

Link.propTypes = {
  children: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  testId: PropTypes.string
};

export default Link;
