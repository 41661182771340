import * as moment from 'moment-timezone';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { Alert, Button, Form, FormGroup, FormControl, Glyphicon } from 'react-bootstrap';
import { sendChatCode } from '../actions/chat-codes';
import ErrorAlert from '../components/uikit/ErrorAlert';
import appStyles from '../style/containers/App.module.scss';
import styles from '../style/containers/ChatCode.module.scss';
import { getMoment } from '../utils/time-helpers';
import { getQueryParamValue } from '../utils/url-helpers';

const LATE_CHAT_CODE_SERVER_ERR_MSG =
  'It is too late for this chat code. You must enter chat codes within 30 days of a ' + 'class. Please contact us immediately.';

class ChatCode extends Component {
  state = {
    chatCode: '',
    errorMessages: [],
    isLoading: false,
    successMsg: null
  };

  handleChange = (event) => {
    this.setState({ chatCode: event.target.value, errorMessages: [], successMsg: null });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ isLoading: true, errorMessages: [], successMsg: null });

    const { token } = this.props;

    const input = event.target[0];

    const handleError = (error) => {
      const allAllowedErrMsgs = [
        'Invalid chat code – please contact us immediately if you think this chat code is ' + 'correct.',
        `No session was found with this chat code. Please contact us immediately if you think ` + 'this chat code is correct.',
        'This chat code was already entered',
        `You already sent an invoice for this session.`,
        `You were already paid for this session.`,
        `This session was cancelled.`,
        LATE_CHAT_CODE_SERVER_ERR_MSG
      ];

      if (error.response.data instanceof Array) {
        const allowedErrMsgs = [];

        error.response.data.forEach((msg) => {
          const msgIsForLateCode = msg.startsWith(LATE_CHAT_CODE_SERVER_ERR_MSG);

          if (msgIsForLateCode) {
            const lateCodeMsg = this.renderLateCodeMsg();
            allowedErrMsgs.push(lateCodeMsg);
            return;
          }

          const msgIsAllowed = allAllowedErrMsgs.some((allowedMsg) => msg.startsWith(allowedMsg));

          if (msgIsAllowed) {
            allowedErrMsgs.push(msg);
          }
        });

        if (allowedErrMsgs.length > 0) {
          return this.setState({
            errorMessages: allowedErrMsgs,
            isLoading: false,
            successMsg: null
          });
        }
      }

      this.setState({
        errorMessages: ['Sorry, something went wrong. Please try again, or email us at ' + 'hello@chatterbox.io if the problem persists.'],
        isLoading: false,
        successMsg: null
      });
    };

    const handleSuccess = (response) => {
      const apptStart = getMoment(response.data.date, response.data.start_time);
      const now = moment.utc();

      const daysDiff = now.diff(apptStart, 'days');

      let successMsg;
      if (daysDiff < 2) {
        successMsg = (
          <p>
            <Glyphicon glyph="ok" />
            &nbsp;&nbsp;Thank you for entering your Chat code on time. This session will be listed on your next invoice.
          </p>
        );
      } else {
        successMsg = (
          <p>
            <Glyphicon glyph="warning-sign" />
            &nbsp;&nbsp;Your chat code was successfully. This session will be listed on your next invoice. However it was entered late &ndash;
            remember to enter each chat code at the beginning of your sessions. This is important to confirm that the session happened.
          </p>
        );
      }

      this.setState({
        chatCode: '',
        errorMessages: [],
        isLoading: false,
        successMsg
      });
    };

    this.props.sendChatCode(token, input.value, handleError, handleSuccess);
  };

  renderLateCodeMsg = () => (
    <p>
      <Glyphicon glyph="warning-sign" />
      &nbsp;&nbsp;Your chat code was entered too late (more than 30 days). This session will <strong>not</strong> be listed on your next invoice.
      Please contact us if the session happened at hello@chatterbox.io
      <br />
      <span className="text-uppercase">Please note</span>: Each chat code at the beginning of your sessions. This is important to confirm that the
      session happened.
    </p>
  );

  render() {
    const { chatCode } = this.state;

    const lastPageWasInvoices = !!getQueryParamValue('invoice');

    return (
      <div className={styles.container}>
        <h1>Enter your chat code to start your class</h1>
        <p className={styles.betaWarning}>IMPORTANT: You only need to enter the chat code for in-person sessions (not for online sessions).</p>
        <p>
          <Glyphicon glyph="warning-sign" /> <span className="text-uppercase">Important</span>: Please enter your chat code at the beginning of each{' '}
          session in order to ensure getting paid by Chatterbox.
        </p>

        <div className={this.state.isLoading ? appStyles.loading : null}>
          <Form className={styles.form} onSubmit={this.handleSubmit}>
            <FormGroup controlId="chat-code">
              <FormControl id="chat-code" onChange={this.handleChange} required type="text" value={chatCode} />
            </FormGroup>

            <Button bsStyle="primary" disabled={chatCode.trim().length !== 4} type="submit">
              Submit
            </Button>
          </Form>

          {!!this.state.successMsg && <Alert bsStyle="success">{this.state.successMsg}</Alert>}
        </div>

        {this.state.errorMessages && <ErrorAlert errorMsg={this.state.errorMessages} />}

        {lastPageWasInvoices && (
          <div className="text-center">
            <Link to="/invoices">Back to Invoices</Link>
          </div>
        )}
      </div>
    );
  }
}

ChatCode.propTypes = {
  sendChatCode: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    token: state.user.token || ''
  };
}

export default connect(mapStateToProps, { sendChatCode })(ChatCode);
