import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { format, isPast } from 'date-fns';
import { Link } from 'react-router-dom';

import { getAppointmentsForTutorLite, resetAppointments, loadMoreAppointmentsForTutorLite } from '../actions/appointments';
import Button from '../components/design-system/button/Button';

import Header from '../components/uikit/Header';
import ErrorAlert from '../components/uikit/ErrorAlert';
import AppointmentTable from '../components/appointment-list/AppointmentTable';

import styles from '../style/containers/AppointmentList.module.scss';
import appStyles from '../style/containers/App.module.scss';
import { convertStringsOfDateAndTimeToDate, convertToLocaleAndFormat } from '../utils/time-helpers';

function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function subtractDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}

const DAYS_FUTURE_DEFAULT = 180;
const DAYS_PAST_DEFAULT = 30;
const LOAD_MORE_DELTA = 30;

class AppointmentList extends Component {
  state = {
    error: false,
    daysFuture: DAYS_FUTURE_DEFAULT,
    daysPast: DAYS_PAST_DEFAULT
  };

  UNSAFE_componentWillMount() {
    this.props.resetAppointments();
  }

  componentDidMount() {
    const { userId, token } = this.props;
    const today = new Date();
    const startDate = subtractDays(today, 30);
    const endDate = addDays(today, 180);

    this.props.getAppointmentsForTutorLite(token, userId, this.showError, startDate, endDate, this.finishLoading.bind(this));
  }

  finishLoading() {
    this.setState({
      isLoading: false
    });
  }

  loadMoreFuture() {
    const { userId, token } = this.props;
    const { daysFuture } = this.state;
    const today = new Date();
    const startDate = addDays(today, daysFuture + 1);
    const endDate = addDays(today, daysFuture + LOAD_MORE_DELTA);
    this.props.loadMoreAppointmentsForTutorLite(token, userId, this.showError, startDate, endDate, this.finishLoading.bind(this));
    this.setState({
      daysFuture: daysFuture + LOAD_MORE_DELTA,
      isLoading: true
    });
  }

  loadMorePast() {
    const { userId, token } = this.props;
    const { daysPast } = this.state;
    const today = new Date();
    const startDate = subtractDays(today, daysPast + LOAD_MORE_DELTA);
    const endDate = subtractDays(today, daysPast + 1);
    this.props.loadMoreAppointmentsForTutorLite(token, userId, this.showError, startDate, endDate, this.finishLoading.bind(this));
    this.setState({
      daysPast: daysPast + LOAD_MORE_DELTA,
      isLoading: true
    });
  }

  getTableData = () => {
    const { appointments, tutor, timezone } = this.props;
    return appointments.map((ap) => {
      const date = convertStringsOfDateAndTimeToDate(ap.date, ap.start_time);
      const timestamp = convertToLocaleAndFormat(date, timezone, 'yyyy-MM-dd (MMM dd), HH:mm');

      const status = ap.status.replace(/_/g, ' ').replace('canceled', 'cancelled').toUpperCase();
      const students = ap.students ? ap.students.map((student) => `${student.first_name} ${student.last_name}`) : [];
      const sampleStudent = ap.students ? (ap.students.length > 0 ? ap.students[0] : null) : null;

      return {
        appointmentType: ap.appointment_type_name,
        date: timestamp,
        isPast: isPast(date),
        status,
        students: students.join(', '),
        classroom: ap.id,
        time: format(date, 'HH:mm X'),
        trainingType: ap.training_type,
        lessonNumber: ap.lesson_number,
        courseId: ap.course_id,
        chat: ap.chat,
        preferred_classroom: sampleStudent ? sampleStudent.preferred_class : null,
        meetme_url: sampleStudent ? (sampleStudent.meetme_details ? sampleStudent.meetme_details.url : null) : null,
        skypeid: sampleStudent ? sampleStudent.skype_id : null,
        zoomid: tutor.zoom_id,
        webex_link: tutor.webex_link,
        teams: tutor.teams_url
      };
    });
  };

  getUnpaidAppointmentsCount = () => {
    const { appointments } = this.props;

    const unpaidStatuses = [
      'canceled_late',
      'happened',
      'invoiced',
      'invoiced_canceled_late',
      'invoiced_no_show',
      'student_noshow',
      'technical_error',
      'invoiced_technical_error'
    ];

    const unpaid = appointments.filter((ap) => unpaidStatuses.includes(ap.status));

    return unpaid.length;
  };

  showError = () => {
    this.setState({ error: true, isLoading: false });
  };

  sortAppointments = (data) => {
    return data.reduce(
      (acc, cv) => {
        const isApptDatePast = cv.isPast;
        if (isApptDatePast) return { ...acc, pastAppointments: [...acc.pastAppointments, cv] };
        return { ...acc, futureAppointments: [...acc.futureAppointments, cv] };
      },
      { pastAppointments: [], futureAppointments: [] }
    );
  };

  render() {
    const { error, isLoading } = this.state;
    const { token, timezone } = this.props;
    const data = this.getTableData();
    const sortedAppointments = this.sortAppointments(data);

    if (data.length > 0) {
      return (
        <div className={styles.container}>
          <Header text="Appointments" />
          {error && <ErrorAlert />}
          <div className={styles.instruction}>
            <p>
              Click on a column header to sort the table by that column. To view details of upcoming appointments, please go to your{' '}
              <Link to="/calendar">calendar</Link>. Your total number of unpaid appointments is <b>{this.getUnpaidAppointmentsCount()}</b>.
            </p>
          </div>
          {sortedAppointments.futureAppointments.length > 0 && (
            <div>
              <h4 className={styles.tableHeading}>
                Upcoming live practices{' '}
                <div className={styles.loadMoreButtonContainer}>
                  <Button outfit="transparentButton" type="button" onClick={this.loadMoreFuture.bind(this)} loading={isLoading} disabled={isLoading}>
                    Load More
                  </Button>
                </div>
              </h4>
              <div className={`${styles.tableContainer} ${styles.marginBottom}`}>
                <AppointmentTable
                  token={token}
                  data={sortedAppointments.futureAppointments}
                  timezone={timezone}
                  defaultSorting={false}
                  isLoading={isLoading}
                />
              </div>
            </div>
          )}
          <h4 className={styles.tableHeading}>
            Past Appointments
            <div className={styles.loadMoreButtonContainer}>
              <Button outfit="transparentButton" type="button" onClick={this.loadMorePast.bind(this)} loading={isLoading} disabled={isLoading}>
                Load More
              </Button>
            </div>
          </h4>
          <div className={styles.tableContainer}>
            <AppointmentTable
              token={token}
              loadMore={this.loadMorePast}
              data={sortedAppointments.pastAppointments}
              timezone={timezone}
              defaultSorting
              isLoading={isLoading}
            />
          </div>
        </div>
      );
    }
    return (
      <div className={styles.container}>
        <Header text="Appointments" />
        <div className={styles.loaderContainer}>
          <div className={[appStyles.loader, styles.loader].join(' ')} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appointments: state.appointments || [],
    token: state.user.token,
    tutor: state.user,
    userId: state.user.id,
    timezone: state.time.timezone
  };
}

AppointmentList.propTypes = {
  appointments: PropTypes.array.isRequired,
  getAppointmentsForTutorLite: PropTypes.func.isRequired,
  loadMoreAppointmentsForTutorLite: PropTypes.func.isRequired,
  resetAppointments: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  tutor: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired
};

export default connect(mapStateToProps, {
  getAppointmentsForTutorLite,
  loadMoreAppointmentsForTutorLite,
  resetAppointments
})(AppointmentList);
