import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { initChangeCoachProcess } from '../../../../../actions/change-coach';
import {
  cleanPreviousState,
  setBulkingState,
  setOpenCancelingModal,
  setOpenReschedulingModal,
  setSelectedAction,
  setShowOptions
} from '../../../../../actions/new-booking';
import useCloseWhenClickOutside from '../../../../../hooks/useCloseWhenClickOutside';
import Button from '../../../../design-system/button/Button';

import styles from './MyBookingsThreeDots.module.scss';
import { BULK_OPTIONS } from '../../../Enum';

const MyBookingsThreeDots = ({
  appointmentsToBeCanceledOrRescheduled,
  cleanPreviousBookingStateAction,
  initChangeCoachProcessAction,
  selectedAction,
  setBulkingStateAction,
  setOpenCancelingModalAction,
  setOpenReschedulingModalAction,
  setSelectedActionAction,
  setShowOptionsAction,
  showOptions
}) => {
  const availableActions = {
    'Cancel session(s)': (
      <Button
        nowrap
        outfit="cancelButton"
        testId={`my-bookings-three-dots-cancel-sessions`}
        disabled={!appointmentsToBeCanceledOrRescheduled.length}
        onClick={() => setOpenCancelingModalAction()}>
        {BULK_OPTIONS.cancel}
      </Button>
    ),
    Reschedule: (
      <Button
        disabled={!appointmentsToBeCanceledOrRescheduled.length}
        testId={`my-bookings-three-dots-reschedule-sessions`}
        onClick={() => setOpenReschedulingModalAction()}>
        {BULK_OPTIONS.reschedule}
      </Button>
    )
  };

  useEffect(() => {
    return () => setShowOptionsAction(false);
  }, []);

  const history = useHistory();
  const handleActionSelection = (action) => {
    setBulkingStateAction(true);
    setSelectedActionAction(action);
  };

  const handleCloseBulkManagement = () => {
    cleanPreviousBookingStateAction();
    setBulkingStateAction(false);
  };

  const handleExit = () => {
    setSelectedActionAction('');
    setShowOptionsAction(false);
    handleCloseBulkManagement();
  };

  const handleChangeCoach = () => {
    history.push('/pick-your-coach');
    initChangeCoachProcessAction(true);
  };

  const [wrapperRef] = useCloseWhenClickOutside({ handleExit });

  if (selectedAction)
    return (
      <div className={styles['selected-action--wrapper']}>
        {availableActions[selectedAction]}
        <Button outfit="secondaryButton" onClick={handleExit}>
          Exit
        </Button>
      </div>
    );

  if (showOptions)
    return (
      <div className={styles.threeDotsButtons} ref={wrapperRef} data-testid="my-bookings-three-dots-options">
        {Object.keys(availableActions).map((action) => (
          <button key={action} onClick={() => handleActionSelection(action)} data-testid={`my-bookings-three-dots-option-${action}`}>
            {action}
          </button>
        ))}
        <button onClick={handleChangeCoach} data-testid={`my-bookings-three-dots-option-change-coach`}>
          Change coach
        </button>
        <button onClick={handleExit}>Exit</button>
      </div>
    );

  return (
    <button className={styles.threeDots} onClick={() => setShowOptionsAction(true)} data-testid="my-bookings-three-dots">
      <div className={styles.oneDot} />
      <div className={styles.oneDot} />
      <div className={styles.oneDot} />
    </button>
  );
};

const mapStateToProps = (state) => ({
  selectedAction: state.newBooking.generic.selectedAction,
  showOptions: state.newBooking.generic.showOptions
});

MyBookingsThreeDots.propTypes = {
  appointmentsToBeCanceledOrRescheduled: PropTypes.array.isRequired,
  cleanPreviousBookingStateAction: PropTypes.func.isRequired,
  initChangeCoachProcessAction: PropTypes.func.isRequired,
  selectedAction: PropTypes.string.isRequired,
  setBulkingStateAction: PropTypes.func.isRequired,
  setOpenCancelingModalAction: PropTypes.func.isRequired,
  setOpenReschedulingModalAction: PropTypes.func.isRequired,
  setSelectedActionAction: PropTypes.func.isRequired,
  setShowOptionsAction: PropTypes.func.isRequired,
  showOptions: PropTypes.bool
};

MyBookingsThreeDots.defaultProps = {
  showOptions: false
};

export default connect(mapStateToProps, {
  cleanPreviousBookingStateAction: cleanPreviousState,
  initChangeCoachProcessAction: initChangeCoachProcess,
  setBulkingStateAction: setBulkingState,
  setSelectedActionAction: setSelectedAction,
  setShowOptionsAction: setShowOptions,
  setOpenCancelingModalAction: setOpenCancelingModal,
  setOpenReschedulingModalAction: setOpenReschedulingModal
})(MyBookingsThreeDots);
