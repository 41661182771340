import axios from 'axios';

import { isTokenExpired } from './common';
import {
  ACCOUNT_UPDATE_SUCCESS,
  ACCOUNT_UPDATE_FAILED,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_FAILED,
  GET_USER_SUCCESS,
  USER_LOGOUT,
  USER_HIDE_ONBOARDING,
  USER_HIDE_CEREGO_POPUP,
  HIDE_STUDY_AREA_ONBOARDING,
  SET_USER_BOOKED,
  USER_HIDE_TUITION_BANNER,
  SET_ONBOARDING_SUBMITTED,
  OVERWRITE_USER_DATA,
  REQUEST_ACCOUNT_UPDATE,
  REQUEST_UPDATE_PASSWORD,
  SET_UG_WALKTHROUGH_SEEN,
  RESET_LOGIN_ERROR,
  RESET_UPDATE_PASSWORD,
  RESET_ACCOUNT_UPDATE
} from './types';

import storageUtils from '../utils/storage-helpers';
import adaptUserData from '../components/account/adapter';
import UserAPI from '../services/user';
import { loginActions } from './new-login';
import { createActionTypes } from '../utils/action-helpers';
import { removeCSRFToken } from '../utils/cookie-helpers';
import { setAppLanguage } from './app-language';
import { setTimezone } from './time';
import { TwilioConversation } from '../services/chat/twilio';
import { UserTypes } from '../domains/user/user-types';
import mixpanelHelper from '../utils/mixpanel-helper';
import { setSentryUser } from '../setup/sentry';

export function resetLoginError() {
  return {
    type: RESET_LOGIN_ERROR
  };
}

const getUserSuccess = (data, token) => ({
  type: GET_USER_SUCCESS,
  payload: { ...data, token }
});

function getUserNextStep(data, token) {
  return (dispatch) => {
    if (!data) return;
    if (data?.preferred_language) dispatch(setAppLanguage(data.preferred_language));
    if (data?.time_zone_name) dispatch(setTimezone(data?.time_zone_name));
    dispatch(getUserSuccess(data, token));
  };
}

export function setUserBooked() {
  return {
    type: SET_USER_BOOKED
  };
}

function HideStudyAreaOnboardingSuccess(response, token) {
  const payload = { ...response.data, token };

  return {
    type: HIDE_STUDY_AREA_ONBOARDING,
    payload
  };
}

function HideOnboardingSuccess(response, token) {
  const payload = { ...response.data, token };

  return {
    type: USER_HIDE_ONBOARDING,
    payload
  };
}

function HideCeregoPopupSuccess(response, token) {
  const payload = { ...response.data, token };

  return {
    type: USER_HIDE_CEREGO_POPUP,
    payload
  };
}

function setUGWalkthroughSeenSuccess() {
  return {
    type: SET_UG_WALKTHROUGH_SEEN
  };
}

function getLogoutSuccess() {
  storageUtils.markLogout();

  return {
    type: USER_LOGOUT
  };
}

export function hideStudyAreaOnboarding(token) {
  return async (dispatch) => {
    const response = await axios.put(
      '/api/hide_study_area_onboarding/',
      {},
      {
        headers: { Authorization: `Token ${token}` }
      }
    );
    dispatch(HideStudyAreaOnboardingSuccess(response, token));
  };
}

export function hideOnboarding(token, successCallback, errorCallback) {
  return async (dispatch) => {
    let response;
    try {
      response = await axios.put(
        '/api/hide_onboarding/',
        {},
        {
          headers: { Authorization: `Token ${token}` }
        }
      );
      dispatch(HideOnboardingSuccess(response, token));
    } catch (error) {
      if (typeof errorCallback === 'function') {
        return errorCallback(error);
      }
    }

    if (typeof successCallback === 'function') {
      successCallback(response);
    }
  };
}

export function setUGWalkthroughSeen(token, student) {
  return (dispatch) => {
    axios
      .patch(`/api/users/${student.id}/`, student, {
        headers: { Authorization: `Token ${token}` }
      })
      .then(() => {
        dispatch(setUGWalkthroughSeenSuccess());
      });
  };
}

export function hideCeregoPopup(token, successCallback, errorCallback) {
  return async (dispatch) => {
    let response;
    try {
      response = await axios.put(
        '/api/hide_ceregopopup/',
        {},
        {
          headers: { Authorization: `Token ${token}` }
        }
      );
      dispatch(HideCeregoPopupSuccess(response, token));
    } catch (error) {
      if (typeof errorCallback === 'function') {
        return errorCallback(error);
      }
    }

    if (typeof successCallback === 'function') {
      successCallback(response);
    }
  };
}

export function getUserData({ token, getUserOrder, successCallback, errorCallback }) {
  return async (dispatch) => {
    try {
      const { data } = await UserAPI.getUserData(token);

      const userParams = {
        id: data?.userId,
        email: data?.email,
        company: data?.organization,
        courses: data?.courses,
        saasType: data?.saas_type,
        startDate: data?.created_datetime,
        learningReason: data?.learning_reason
      };

      mixpanelHelper.identifyUser(userParams);
      setSentryUser(data);

      TwilioConversation.destroyInstance();
      const twilioChat = TwilioConversation.getInstance({ ...data, token });
      await twilioChat.initiate();

      await dispatch(getUserNextStep(data, token));

      if (data?.user_type === UserTypes.student) getUserOrder();

      dispatch(loginActions.postLogInSuccess());

      if (successCallback) successCallback(data);
    } catch (error) {
      dispatch(loginActions.postLogInFail('Something went wrong while attempting to log in.'));
      if (!isTokenExpired(dispatch, error)) {
        if (errorCallback) return errorCallback();
      }
    }
  };
}

export function refreshUser(token, successCallback, errorCallback) {
  return async (dispatch) => {
    try {
      const { data } = await UserAPI.getUserData(token);
      dispatch(getUserNextStep(data, token));
      if (errorCallback && typeof successCallback === 'function') {
        successCallback(data);
      }
    } catch (error) {
      if (!isTokenExpired(dispatch, error)) {
        if (errorCallback && typeof errorCallback === 'function') {
          return errorCallback(error);
        }
      }
    }
  };
}

export function userLogout(token, callback) {
  return async (dispatch) => {
    storageUtils.markLogout();
    const twilioChat = TwilioConversation.getInstance();
    twilioChat.chatLogout();
    TwilioConversation.destroyInstance();
    removeCSRFToken();
    dispatch(getLogoutSuccess());

    await axios.post('/api/logout/', {
      headers: { Authorization: `Token ${token}` }
    });
    if (callback) {
      callback();
    }
  };
}

const requestUpdateAccount = () => ({
  type: REQUEST_ACCOUNT_UPDATE
});

const updateAccountSuccess = (response, token) => {
  const payload = { ...response?.data, token };

  if (response?.preferred_language) setAppLanguage(response.preferred_language);
  return {
    type: ACCOUNT_UPDATE_SUCCESS,
    payload
  };
};

const updateAccountFail = () => ({
  type: ACCOUNT_UPDATE_FAILED
});

export const resetUpdateAccount = () => ({
  type: RESET_ACCOUNT_UPDATE
});

export const updateAccount = (user, successCallback, errorCallback) => {
  const adaptedUser = adaptUserData(user);
  return async (dispatch) => {
    try {
      dispatch(requestUpdateAccount());
      const { data } = await UserAPI.updateAccountData(adaptedUser);
      dispatch(updateAccountSuccess(data, adaptedUser.token));
      dispatch(getUserNextStep(data, adaptedUser.token));
      if (successCallback) successCallback();
    } catch (error) {
      dispatch(updateAccountFail());
      if (!isTokenExpired(dispatch, error)) {
        if (errorCallback) errorCallback(error);
      }
    }
  };
};

const updateGraduationDateSuccess = (data) => {
  const payload = {
    needs_graduation_review: data.needs_review,
    graduation_date: data.graduation_date
  };
  return {
    type: OVERWRITE_USER_DATA,
    payload
  };
};

export const updateGraduationDate = (values, token, successCallback, errorCallback) => {
  const headers = {
    headers: { Authorization: `Token ${token}` }
  };
  return async (dispatch) => {
    const body = { ...values };

    let updateResponse;

    try {
      updateResponse = await axios.put('/api/set_graduation_date/', body, headers);
    } catch (error) {
      if (!isTokenExpired(dispatch, error)) {
        errorCallback(error);
      }
    }

    if (updateResponse && updateResponse.status === 200) {
      dispatch(updateGraduationDateSuccess(body));
    }
    if (successCallback) {
      successCallback();
    }
  };
};

const requestUpdatePassword = () => ({
  type: REQUEST_UPDATE_PASSWORD
});

const updatePasswordSuccess = () => {
  return {
    type: PASSWORD_UPDATE_SUCCESS
  };
};

const updatePasswordFail = () => {
  return {
    type: PASSWORD_UPDATE_FAILED
  };
};

export const resetPasswordUpdate = () => ({
  type: RESET_UPDATE_PASSWORD
});

export const updatePassword = (values, token, successCallback, errorCallback) => {
  return async (dispatch) => {
    const body = { ...values };

    try {
      dispatch(requestUpdatePassword());
      await UserAPI.updatePassword(token, body);
      dispatch(updatePasswordSuccess());
      if (successCallback) successCallback();
    } catch (error) {
      dispatch(updatePasswordFail());
      if (!isTokenExpired(dispatch, error)) {
        if (errorCallback) errorCallback(error);
      }
    }
  };
};

export function unsubscribeFromEmails(userid, errorCallback) {
  return async () => {
    try {
      await axios.get(`/api/unsubscribe/${userid}/`);
    } catch (err) {
      if (errorCallback) {
        errorCallback();
      }
    }
  };
}

function hideTuitionBannerSuccess(response, token) {
  const payload = { ...response.data, token };

  return {
    type: USER_HIDE_TUITION_BANNER,
    payload
  };
}

export function setOnboardingSubmitted() {
  return {
    type: SET_ONBOARDING_SUBMITTED
  };
}

export function overwriteUserData(data) {
  setAppLanguage(data.preferred_language);
  return {
    type: OVERWRITE_USER_DATA,
    payload: data
  };
}

export function hideTuitionBanner(token) {
  return async (dispatch) => {
    let response;
    try {
      response = await axios.put(
        '/api/hide_first_tuition_banner/',
        {},
        {
          headers: { Authorization: `Token ${token}` }
        }
      );
      dispatch(hideTuitionBannerSuccess(response, token));
    } catch (error) {
      isTokenExpired(dispatch, error);
    }
  };
}

export const UserActionTypes = createActionTypes('User', ['CLEAR_USER']);

export const clearUser = () => ({
  type: UserActionTypes.CLEAR_USER
});
