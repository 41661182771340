import axios from 'axios';

import { isTokenExpired } from './common';
import { GET_PRODUCT_TYPES, GET_PRODUCT_TYPES_ERROR } from './types';

function gerProductTypesSuccess(response) {
  return {
    type: GET_PRODUCT_TYPES,
    payload: response
  };
}

function gerProductTypesError() {
  return {
    type: GET_PRODUCT_TYPES_ERROR,
    payload: {}
  };
}

export default function getProductTypes(token, errorCallback) {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/product_types/', {
        headers: {
          Authorization: `Token ${token}`
        }
      });
      dispatch(gerProductTypesSuccess(response));
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        errorCallback();
        dispatch(gerProductTypesError());
      }
    }
  };
}
