import { getSnakeCaseFromCamelCase } from './string-helpers';

/** Copy an object with camel-case keys, and get it with snake-case keys instead. */
export function getSnakeCaseObject(object) {
  const keys = Object.keys(object);
  const newObject = {};

  keys.forEach((key) => {
    const newKey = getSnakeCaseFromCamelCase(key);
    newObject[newKey] = object[key];
  });

  return newObject;
}

export const objectHasContent = (obj) => {
  return Boolean(Object.keys(obj).length);
};
