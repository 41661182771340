import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { Dropdown, MenuItem, Button } from 'react-bootstrap';

import TutorCard from '../components/tutor-list/TutorCard.js';
import Mixpanel from '../utils/mixpanel-helper.js';
import getBookingInfoForTutor from '../actions/bookable';
import Timetable from '../components/uikit/Timetable.js';
import { setSelectedLanguage } from '../actions/selected-language.js';
import { setProductType } from '../actions/selected-product-type';
import getProductTypesFromOrders from '../utils/order-helpers';
import { capitalize } from '../utils/string-helpers';
import { isTokenExpired } from '../actions/common';

import styles from '../style/containers/TutorList.module.scss';
import appStyles from '../style/containers/App.module.scss';

import ShowMore from '../components/uikit/ShowMore';

const DEFAULT_LANGUAGE = 'Arabic';
const DEFAULT_LANGUAGE_ID = 2;
const DEFAULT_CALENDAR_MATRIX = [
  [false, false, false, false, false, false, false],
  [false, false, false, false, false, false, false],
  [false, false, false, false, false, false, false]
];

class TutorList extends Component {
  constructor(props) {
    super(props);

    this.timetableRef = React.createRef();
    this.availabilityRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.escFunction = this.escFunction.bind(this);
  }

  state = {
    tutors: [],
    isLoading: true,
    showAvailability: false,
    tutorsLimit: 5
  };

  UNSAFE_componentWillMount() {
    const { onboardingData, orderInfo, setLanguage, productTypes } = this.props;
    if (onboardingData === undefined || onboardingData === null || onboardingData.language === undefined) {
      let allOrders;
      if (orderInfo && orderInfo.active_orders && orderInfo.active_orders.length > 0) {
        allOrders = orderInfo.active_orders;
      } else {
        allOrders = orderInfo && orderInfo.all_orders !== undefined ? orderInfo.all_orders : undefined;
      }

      let preferredLanguage = DEFAULT_LANGUAGE;
      let preferredLanguageId = DEFAULT_LANGUAGE_ID;
      // eslint-disable-next-line no-unused-vars
      for (const i in allOrders) {
        if (allOrders[i]) {
          const ord = allOrders[i];
          if (ord.order_appointment_types.length === 1) {
            preferredLanguage = ord.order_appointment_types[0].language_name;
            preferredLanguageId = ord.order_appointment_types[0].language_id;
            break;
          }
        }
      }
      let availableLanguages = [];
      // eslint-disable-next-line no-unused-vars
      for (const pt in productTypes) {
        if (productTypes[pt]) {
          availableLanguages = availableLanguages.concat(productTypes[pt].languages);
        }
      }
      availableLanguages = availableLanguages.filter((item, pos) => availableLanguages.findIndex((al) => item.language === al.language) === pos);
      availableLanguages = availableLanguages.sort((a, b) => a.language.localeCompare(b.language, 'en', { sensitivity: 'base' }));

      const headers = { Authorization: `Token ${this.props.user.token}` };
      axios
        .get('/api/tutors-available', { headers })
        .then((response) => {
          const languageAvailabilities = response.data;
          availableLanguages = availableLanguages.filter((al) => {
            const lav = languageAvailabilities.find((el) => el.language === al.language);
            let availables = 0;
            lav.appointment_types.forEach((atav) => {
              availables += atav.tutors_available;
            });
            return availables > 0;
          });
          availableLanguages = availableLanguages.map((al) => {
            const lav = languageAvailabilities.find((el) => el.language === al.language);
            const availableObject = al;
            availableObject.availabilities = lav.appointment_types.filter((lavat) => lavat.tutors_available > 0);
            return availableObject;
          });
          this.setState({ availableLanguages });
        })
        .catch((err) => {
          this.props.expiredToken(err, false);
        });

      this.setState({
        languageId: preferredLanguageId,
        languageName: preferredLanguage
      });
      setLanguage({
        language: preferredLanguage,
        id: preferredLanguageId
      });
    } else {
      this.setState({
        languageId: this.props.onboardingData.language.id,
        languageName: this.props.onboardingData.language.language
      });
    }
  }

  componentDidMount() {
    const headers = { Authorization: `Token ${this.props.user.token}` };
    const { languageId } = this.state;
    this.setCalendarMatrix();
    axios
      .get(`/api/matching_tutor_list/${languageId}`, { headers })
      .then((response) => {
        this.setState({
          tutors: response.data,
          isLoading: false
        });

        const availableProductTypes = this.getAvailableProductTypes();
        if (availableProductTypes.length > 0) {
          if (this.props.productType) {
            const currentProductType = availableProductTypes.find((pt) => pt.id === this.props.productType.productTypeId);
            this.setState({ selectedLessonType: currentProductType });
          } else {
            this.onSelectLessonTypeDropdown(availableProductTypes, 0);
          }
        }
      })
      .catch((err) => {
        this.props.expiredToken(err, false);
      });
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keydown', this.escFunction, false);
  }

  onSelectLessonTypeDropdown(availableProductTypes, selectedLessonType) {
    this.setState({ selectedLessonType: availableProductTypes[selectedLessonType] });
  }

  onSelectLanguageDropdown(selectedLanguageIndex) {
    const { availableLanguages } = this.state;
    const { setLanguage } = this.props;
    const selectedLanguage = availableLanguages[selectedLanguageIndex];

    this.setState({
      languageId: selectedLanguage.id,
      languageName: selectedLanguage.language,
      isLoading: true,
      tutors: []
    });
    setLanguage({
      language: selectedLanguage.language,
      id: selectedLanguage.id
    });

    const headers = { Authorization: `Token ${this.props.user.token}` };
    axios
      .get(`/api/matching_tutor_list/${selectedLanguage.id}`, { headers })
      .then((response) => {
        this.setState({
          tutors: response.data,
          isLoading: false
        });

        const availableProductTypes = this.getAvailableProductTypes();
        if (availableProductTypes.length > 0) {
          this.onSelectLessonTypeDropdown(availableProductTypes, 0);
        }
      })
      .catch((err) => {
        this.props.expiredToken(err, false);
      });
  }

  onSelectTimeSlot(day, moment) {
    const { calendarMatrix } = this.state;
    calendarMatrix[moment][day] = !calendarMatrix[moment][day];
    this.setState({ calendarMatrix });
  }

  setCalendarMatrix() {
    const { onboardingData } = this.props;
    if (onboardingData === undefined || onboardingData === null || onboardingData.language === undefined) {
      this.setState({ calendarMatrix: DEFAULT_CALENDAR_MATRIX });
    } else {
      this.setState({ calendarMatrix: onboardingData.calendarMatrix });
    }
  }

  getAvailableProductTypes() {
    const { productTypes } = this.props;
    const { languageName, availableLanguages } = this.state;
    let availableProductTypes = productTypes
      ? productTypes.filter((pt) => {
          const hasLanguage = pt.languages.some((l) => l.language === languageName);
          const isDefault = pt.is_default;

          return hasLanguage && isDefault;
        })
      : [];

    const selectedLanguage = availableLanguages ? availableLanguages.find((l) => l.language === languageName) : undefined;

    if (selectedLanguage) {
      availableProductTypes = availableProductTypes.filter((apt) =>
        selectedLanguage.availabilities.some(
          (sla) => sla.appointment_type.toLowerCase().includes(apt.delivery_type) && sla.appointment_type.toLowerCase().includes(apt.training_type)
        )
      );
    }

    const availableProductTypesFiltered = availableProductTypes.filter((pt, pos) => {
      const findindex = availableProductTypes.findIndex(
        (findpt) => findpt.delivery_type === pt.delivery_type && findpt.training_type === pt.training_type
      );
      return findindex === pos;
    });

    return availableProductTypesFiltered.sort((a, b) => {
      const aString = `${a.training_type} ${a.delivery_type}`;
      const bString = `${b.training_type} ${b.delivery_type}`;
      return aString.localeCompare(bString, 'en', { sensitivity: 'base' });
    });
  }

  handleClickOutside(event) {
    if (this.timetableRef.current) {
      if (!this.timetableRef.current.contains(event.target)) {
        if ((this.availabilityRef.current && !this.availabilityRef.current.contains(event.target)) || this.availabilityRef.current === null) {
          this.setState({ showAvailability: false });
        }
      }
    }
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.setState({ showAvailability: false });
    }
  }

  toggleAvailability() {
    this.setState({ showAvailability: !this.state.showAvailability });
  }

  increaseTutorLimit = () => {
    this.setState({ tutorsLimit: this.state.tutorsLimit + 5 });
  };

  render() {
    const { tutors, isLoading, showAvailability, calendarMatrix, languageId, languageName, availableLanguages, selectedLessonType, tutorsLimit } =
      this.state;

    const { onboardingData } = this.props;
    const showLanguageFilter =
      availableLanguages !== undefined &&
      availableLanguages.length > 0 &&
      (onboardingData === undefined || onboardingData === null || onboardingData.language === undefined);
    const selectedLanguage = languageName;
    const availableProductTypes = this.getAvailableProductTypes();

    let filteredTutors = tutors;

    if (selectedLessonType) {
      filteredTutors = filteredTutors.filter((t) =>
        t.product_types.some((pt) => pt.delivery_type === selectedLessonType.delivery_type && pt.training_type === selectedLessonType.training_type)
      );
    }

    const matchedFilteredTutors = [];
    filteredTutors.forEach((t) => {
      const tutorAvailability = t.availability_matrix;
      let availabilityMatch = false;

      if (tutorAvailability !== undefined) {
        let shouldLookForAvailability = false;
        for (let i = 0; i < calendarMatrix.length; i += 1) {
          shouldLookForAvailability = shouldLookForAvailability || calendarMatrix[i].includes(true);
        }

        if (shouldLookForAvailability) {
          for (let i = 0; i < calendarMatrix.length; i += 1) {
            for (let j = 0; j < calendarMatrix[0].length; j += 1) {
              if (calendarMatrix[i][j] && tutorAvailability && tutorAvailability[i][j]) {
                availabilityMatch = true;
                break;
              }
            }
            if (availabilityMatch) {
              break;
            }
          }
        } else {
          availabilityMatch = true;
        }
      }

      const currentTutor = t;
      currentTutor.availabilityMatch = availabilityMatch;
      matchedFilteredTutors.push(currentTutor);
    });

    filteredTutors = matchedFilteredTutors;

    filteredTutors.sort((a, b) => {
      if (a.availabilityMatch && !b.availabilityMatch) {
        return -1;
      } else if (b.availabilityMatch && !a.availabilityMatch) {
        return 1;
      }
      return b.calculated_rating - a.calculated_rating;
    });

    return (
      <div className={styles.pageWrapper}>
        <div className={styles.header}>
          <h1>{`Meet our ${selectedLanguage} coaches`}</h1>
          <div className={styles.filterWrapper}>
            {showLanguageFilter && (
              <div className={styles.dropdownWrapper}>
                <p className={styles.dropdownLabel}>Language:</p>
                <Dropdown title="Extra small button" onSelect={this.onSelectLanguageDropdown.bind(this)}>
                  <Dropdown.Toggle
                    className={styles.filterButton}
                    onClick={() => {
                      Mixpanel.track('Click', {
                        position: 'Tutor List',
                        button: 'language_dropdown'
                      });
                    }}>
                    {selectedLanguage === undefined ? 'Choose a language' : selectedLanguage}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {availableLanguages.map((d, index) => (
                      <MenuItem eventKey={index.toString()} key={d.language}>
                        {d.language}
                      </MenuItem>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            <div className={styles.dropdownWrapper} />
            {showLanguageFilter && availableProductTypes.length > 1 && (
              <div className={styles.dropdownWrapper}>
                <p className={styles.dropdownLabel}>Type:</p>
                <Dropdown
                  title="Extra small button"
                  disabled={availableProductTypes.length <= 0}
                  onSelect={this.onSelectLessonTypeDropdown.bind(this, availableProductTypes)}>
                  <Dropdown.Toggle
                    className={styles.filterButton}
                    onClick={() => {
                      Mixpanel.track('Click', {
                        position: 'Tutor List',
                        button: 'lesson_type_dropdown'
                      });
                    }}>
                    {selectedLessonType === undefined
                      ? 'Choose a lesson type'
                      : `${capitalize(selectedLessonType.training_type)} ${selectedLessonType.delivery_type}`}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {availableProductTypes.map((d, index) => (
                      <MenuItem eventKey={index.toString()} key={`${d.training_type} ${d.delivery_type} ${d.duration} min`}>
                        {d.is_for_group_sessions && 'group '}
                        {`${d.training_type} ${d.delivery_type} ${d.duration} min`}
                      </MenuItem>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            {showLanguageFilter && availableProductTypes.length <= 1 && (
              <div className={styles.dropdownWrapper}>
                <p className={styles.dropdownLabel}>Type:</p>
                <div className={styles.fakeDropdown}>
                  {availableProductTypes.length <= 0
                    ? 'No session types'
                    : `${capitalize(availableProductTypes[0].training_type)} ${availableProductTypes[0].delivery_type}`}
                </div>
              </div>
            )}
            <div className={styles.dropdownWrapper} ref={this.availabilityRef}>
              <p className={styles.dropdownLabel}>Preferred schedule:</p>
              <Button className={styles.filterButton} onClick={this.toggleAvailability.bind(this)}>
                Availability <span className="caret" />
              </Button>
              {showAvailability && (
                <div ref={this.timetableRef} id="calendar" className={styles.timetableWrapper}>
                  <Timetable calendarMatrix={calendarMatrix} onSelectTimeSlot={this.onSelectTimeSlot.bind(this)} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.listWrapper}>
          {isLoading && <div className={[appStyles.loader, styles.loader].join(' ')} />}
          {filteredTutors.slice(0, tutorsLimit).map((t) => (
            <TutorCard
              key={`${t.first_name} ${t.last_name}`}
              name={`${t.first_name} ${t.last_name}`}
              language={languageName}
              languages={t.languages}
              lessonTypes={t.session_types}
              country={t.country ? t.country.country : ''}
              interests={(t.professional_interests ? t.professional_interests : [])
                .concat(t.personal_interests ? t.personal_interests : [])
                .map((int) => int.name)}
              rating={t.calculated_rating}
              availabilityMatching={t.availabilityMatch}
              reviews={t.reviews}
              bio={t.bio}
              onViewProfile={() => {
                let productTypeId;
                if (selectedLessonType !== undefined) {
                  productTypeId = selectedLessonType.id;
                  const { orderInfo } = this.props;

                  const productTypesWithCredits = orderInfo ? getProductTypesFromOrders(orderInfo.all_orders, this.props.productTypes) : [];

                  const matchingProductType = productTypesWithCredits.find((pt) => pt.id === productTypeId);

                  this.props.storeProductType(
                    selectedLessonType.delivery_type,
                    selectedLessonType.training_type,
                    matchingProductType ? matchingProductType.orderType : 'session_count',
                    productTypeId,
                    selectedLessonType.duration,
                    matchingProductType ? matchingProductType.remainingCredits : 0,
                    selectedLessonType.prices,
                    selectedLessonType.is_for_group_sessions
                  );
                } else {
                  productTypeId = this.props.productType.productTypeId;
                }

                this.props.queryTutorInfo(this.props.user.token, t.id, productTypeId, languageId);
                this.setState({ isLoading: false });
                this.props.history.push(`/coach/${t.id}`);
              }}
              imgUrl={t.profile_picture}
            />
          ))}
          {!isLoading && tutorsLimit < filteredTutors.length && (
            <ShowMore text={`Show more coaches`} action={this.increaseTutorLimit} direction="down" />
          )}
          {!isLoading && filteredTutors.length < 1 && (
            <p className={styles.noTutors}>Sorry we couldn&apos;t find any tutor this time. Please change the selected filter for more results.</p>
          )}
        </div>
      </div>
    );
  }
}

TutorList.propTypes = {};

function mapStateToProps(state) {
  return {
    user: state.user,
    productType: state.selectedProductType,
    onboardingData: state.onboardingMatch,
    orderInfo: state.orderInfo,
    productTypes: state.productTypes
  };
}
function mapDispatchToProps(dispatch) {
  return {
    queryTutorInfo: (token, tutorId, productTypeId, selectedLanguageId, errorCallback) =>
      dispatch(getBookingInfoForTutor(token, tutorId, productTypeId, selectedLanguageId, errorCallback)),
    setLanguage: (language) => dispatch(setSelectedLanguage(language)),
    expiredToken: (err, showErr) => isTokenExpired(dispatch, err, showErr),
    storeProductType: (deliveryType, trainingType, orderType, productTypeId, duration, remainingCredits, prices, isGroupSession) =>
      dispatch(
        setProductType({
          deliveryType,
          trainingType,
          orderType,
          productTypeId,
          duration,
          remainingCredits,
          prices,
          isGroupSession
        })
      )
  };
}

TutorList.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onboardingData: PropTypes.object,
  orderInfo: PropTypes.object,
  productType: PropTypes.object.isRequired,
  productTypes: PropTypes.array.isRequired,
  queryTutorInfo: PropTypes.func.isRequired,
  expiredToken: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
  storeProductType: PropTypes.func.isRequired
};

TutorList.defaultProps = {
  onboardingData: {},
  orderInfo: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(TutorList);
