import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, Form, FormControl, FormGroup } from 'react-bootstrap';

import Button from '../design-system/button/Button';
import { getDay } from './helpers';
import ErrorAlert from '../uikit/ErrorAlert.js';

import styles from '../../style/components/availability/AvailabilityForm.module.scss';

class AvailabilityForm extends Component {
  state = {
    error: false,
    from: this.props.from || '00:00',
    to: this.props.to || '00:00',
    isBigAvailability: false
  };

  onConfirmBigAvailability = () => {
    const { from, to } = this.state;
    const { availabilityId, date, errorCallback, token } = this.props;
    const isRecurring = true;
    if (availabilityId) {
      this.props.updateAvailability(token, date, from, to, availabilityId, isRecurring, errorCallback);
    } else {
      this.props.createNewAvailability(token, date, from, to, isRecurring, errorCallback);
    }

    this.props.handleCloseModal();
  };

  onConfirm = () => {
    const { from, to } = this.state;
    const slotIsValid = this.checkSlot(from, to);

    if (!slotIsValid) {
      return this.setState({ error: true });
    }

    const { availabilityId, date, errorCallback, token } = this.props;
    const isRecurring = true;
    if (availabilityId) {
      this.props.updateAvailability(token, date, from, to, availabilityId, isRecurring, errorCallback);
    } else {
      this.props.createNewAvailability(token, date, from, to, isRecurring, errorCallback);
    }
    this.props.handleCloseModal();
  };

  onDelete = () => {
    const { token, availabilityId, errorCallback } = this.props;
    this.props.deleteAvailability(token, availabilityId, errorCallback);
    this.props.handleCloseModal();
  };

  getMinute = (timeString, { isEnd = false } = {}) => {
    const [hourString, minuteString] = timeString.split(':');

    let hour = parseInt(hourString, 10);
    const minute = parseInt(minuteString, 10);

    if (hour === 0 && minute === 0 && isEnd) {
      hour = 24;
    }

    return hour * 60 + minute;
  };

  getAvailSize = (from, to) => (this.getMinute(to, { isEnd: true }) - this.getMinute(from)) / 60;

  checkSlot = (from, to) => this.getMinute(to, { isEnd: true }) - this.getMinute(from) >= 30;

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
      error: false
    });
  };

  goBackOnConfirm = () => this.setState({ isBigAvailability: false });

  render() {
    const { date, availabilityId } = this.props;
    const errMsg = 'Time slots must be at least 30 minutes.';
    return (
      <div className={styles.container}>
        <h4 className={styles.heading}>
          <b>{`${getDay(date)}`}</b>
        </h4>
        {/* <p className={styles.instruction}>Please set times in <b>HH:MM (24h)</b> format.</p> */}
        {!this.state.isBigAvailability ? (
          <>
            <Form className={styles.form} inline>
              <FormGroup controlId="from" className={styles.formGroup}>
                <ControlLabel className={styles.fromLabel}>From</ControlLabel>
                <FormControl required id="from" value={this.state.from} onChange={this.handleChange} className={styles.input} type="time" />
              </FormGroup>
              <FormGroup controlId="to" className={styles.formGroup}>
                <ControlLabel className={styles.toLabel}>To</ControlLabel>
                <FormControl required id="to" value={this.state.to} onChange={this.handleChange} className={styles.input} type="time" />
              </FormGroup>
            </Form>

            {this.state.error && <ErrorAlert errorMsg={errMsg} />}
            <div className={styles.buttonsWrapper}>
              <Button className={styles.button} outfit="greenButton" onClick={this.onConfirm}>
                Confirm Availability
              </Button>
              {availabilityId && (
                <Button className={styles.button} bsStyle="danger" onClick={this.onDelete}>
                  Delete
                </Button>
              )}
            </div>
          </>
        ) : (
          <>
            <p style={{ textAlign: 'left' }}>
              You are adding a very long availability – learners might book many Live Practices in a row and you won&apos;t be able to rest for hours.
              Remember to add break times!
            </p>
            <p style={{ textAlign: 'left' }}>
              We recommend adding 15 minutes after each 2 hours blocks of availability. For example: <br />
              ENTER: Monday available from 1pm-3pm <br />
              (break time 15 minutes) <br />
              ENTER: Monday available from 3:15pm - 5:15pm <br />
            </p>
            <br />
            <div>
              <Button className={styles.button} bsStyle="secondary" onClick={this.onConfirmBigAvailability}>
                Yes I want to add a long availability
              </Button>
              <Button className={styles.button} bsStyle="primary" onClick={this.goBackOnConfirm}>
                Go back and change this availability
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
}

AvailabilityForm.propTypes = {
  availabilityId: PropTypes.number,
  createNewAvailability: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date),
  deleteAvailability: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
  from: PropTypes.string,
  handleCloseModal: PropTypes.func,
  to: PropTypes.string,
  token: PropTypes.string.isRequired,
  updateAvailability: PropTypes.func.isRequired
};

AvailabilityForm.defaultProps = {
  availabilityId: null,
  date: null,
  from: '',
  handleCloseModal: () => {},
  to: ''
};

export default AvailabilityForm;
