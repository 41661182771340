import LoginAPI from '../services/login';
import { actionCreatorsFactory, asyncActionType, createActionTypes } from '../utils/action-helpers';
import sendEvent from './events';
import { getUserData } from './user';
import storageUtils from '../utils/storage-helpers';
import { isTokenExpired } from './common';
import { postSignUpOnOnboarding, updateInitialInformation } from './new-onboarding';
import getOrderInfo from './order-info';
import { getErrorMessage, getMultipleErrorMessage } from '../utils/error-helpers';
import { sendSentryError } from '../setup/sentry';
import { UserTypes } from '../domains/user/user-types';

export const LoginActionTypes = createActionTypes('Login', [
  'RESET_SIGN_IN',
  'SET_SSO_REDIRECT_URL',
  asyncActionType('BEFORE_SIGN_IN'),
  asyncActionType('POST_LOG_IN'),
  asyncActionType('GET_SSO_PROVIDER'),
  asyncActionType('POST_SSO_SIGN_UP'),
  asyncActionType('POST_SSO_LOG_IN'),
  asyncActionType('POST_LOG_OUT'),
  asyncActionType('POST_SIGN_IN')
]);

export const resetSignIn = () => ({
  type: LoginActionTypes.RESET_SIGN_IN
});

export const setSSORedirectUrl = (payload) => ({
  type: LoginActionTypes.SET_SSO_REDIRECT_URL,
  payload
});

const signInActions = actionCreatorsFactory(
  [LoginActionTypes.POST_SIGN_IN, LoginActionTypes.POST_SIGN_IN_SUCCESS, LoginActionTypes.POST_SIGN_IN_FAIL],
  'postSignUp'
);

const friendlyRedirectsTo = (history, url, to) => {
  history.push(to ? to : url);
};

export const redirectAfterLogin = (user, history, to) => {
  const { user_type: userType } = user;
  switch (userType) {
    case UserTypes.languageHelper:
      friendlyRedirectsTo(history, '/lh-chat', to);
      break;
    case UserTypes.tutor:
      friendlyRedirectsTo(history, '/dashboard', to);
      break;
    default:
      friendlyRedirectsTo(history, '/study-area', to);
  }
};

const hasOrderAlreadyCreated = (errorMsg) => {
  if (!errorMsg) return false;
  const terms = ['activated', 'account'];
  return terms.every((term) => errorMsg.includes(term));
};

const createUserOrder = async ({ email, invitationToken }) => {
  try {
    const { data: userConciseProfile } = await LoginAPI.validatePasswordToken({
      email: email,
      invitationToken: invitationToken
    });
    return LoginAPI.createUserWithOrder({
      ...userConciseProfile,
      email: email
    });
  } catch (err) {
    sendSentryError({ err, context: 'createUserOrder' });
    if (!hasOrderAlreadyCreated(err.response?.data)) {
      throw new Error('Unable to validate user password and create order');
    }
    return new Promise((resolve) => resolve({ data: { email } }));
  }
};

export const postSignUp = ({ data: signInData, successCallbackAfterLogIn, securityParams }) => {
  return async (dispatch) => {
    try {
      dispatch(signInActions.postSignUp());
      const { data: user } = await createUserOrder(securityParams);
      const { data: setPasswordToken } = await LoginAPI.getSetPasswordToken({ email: user.email });
      dispatch(
        updateInitialInformation({
          uid: setPasswordToken?.uid,
          setPasswordToken: setPasswordToken?.token
        })
      );
      await LoginAPI.signIn({
        ...signInData,
        uid: setPasswordToken?.uid,
        token: setPasswordToken?.token
      });
      dispatch(postSignUpOnOnboarding(securityParams));
      dispatch(signInActions.postSignUpSuccess());
      dispatch(
        postLogIn({
          data: {
            email: signInData.email,
            password: signInData.new_password2
          },
          successCallback: successCallbackAfterLogIn
        })
      );
    } catch (err) {
      sendSentryError({ err, context: 'postSignUp' });
      const error = getMultipleErrorMessage(err.response?.data);
      dispatch(signInActions.postSignUpFail(error));
    }
  };
};

export const getSSOProviderActions = actionCreatorsFactory(
  [LoginActionTypes.GET_SSO_PROVIDER, LoginActionTypes.GET_SSO_PROVIDER_SUCCESS, LoginActionTypes.GET_SSO_PROVIDER_FAIL],
  'getSSOProvider'
);

export const getSSOProvider = ({ email, successCallback, errorCallback }) => {
  return async (dispatch) => {
    try {
      dispatch(getSSOProviderActions.getSSOProvider());
      const { data } = await LoginAPI.getSSOProvider({ email });
      dispatch(getSSOProviderActions.getSSOProviderSuccess(data.sso_identity_provider));
      if (successCallback) successCallback({ provider: data.sso_identity_provider });
    } catch (err) {
      sendSentryError({ err, context: 'getSSOProvider' });
      const msg = err && err.response && err.response.data && err.response.data.error_message;
      let finalMsg = msg;
      if (err.response.status === 403) {
        finalMsg = msg || 'Something went wrong while getting your provider.';
        dispatch(getSSOProviderActions.getSSOProviderFail(finalMsg));
      } else {
        finalMsg = msg || "We coudn't find your corporate credentials. Please confirm that your company have this feature available.";
        dispatch(getSSOProviderActions.getSSOProviderFail(finalMsg));
      }

      if (errorCallback) return errorCallback(finalMsg);
    }
  };
};

export const ssoSignUpActions = actionCreatorsFactory(
  [
    LoginActionTypes.POST_SSO_SIGN_UP,
    LoginActionTypes.POST_SSO_SIGN_UP_SUCCESS,
    LoginActionTypes.POST_SSO_SIGN_UP_FAIL,
    LoginActionTypes.CLEAR_POST_LOG_IN_ERROR
  ],
  'postSsoSignUp'
);

export const postSsoSignUp = ({ onboardSecurityParams, accessToken, successCallback }) => {
  return async (dispatch) => {
    try {
      dispatch(ssoSignUpActions.postSsoSignUp());
      await createUserOrder(onboardSecurityParams);
      const { data } = await LoginAPI.exchangeAccessToken({ accessToken });
      dispatch(sendEvent(data.key, { eventType: 'login' }));
      dispatch(
        getUserData({
          token: data.key,
          getUserOrder: () => dispatch(getOrderInfo(data.key)),
          successCallback
        })
      );
    } catch (err) {
      sendSentryError({ err, context: 'postSsoSignUp' });
      const errorMessage =
        (err && err.response && err.response.data && err.response.data.error_message) || 'Something went wrong while attempting to log in.';
      dispatch(ssoSignUpActions.postSsoSignUpFail(errorMessage));
    }
  };
};

export const loginActions = actionCreatorsFactory(
  [LoginActionTypes.POST_LOG_IN, LoginActionTypes.POST_LOG_IN_SUCCESS, LoginActionTypes.POST_LOG_IN_FAIL, LoginActionTypes.CLEAR_POST_LOG_IN_ERROR],
  'postLogIn'
);

export const postLogIn = ({ data: logInData, successCallback, errorCallback }) => {
  return async (dispatch) => {
    try {
      dispatch(loginActions.postLogIn());
      const requestBody = { ...logInData, username: logInData.email };
      const { data } = await LoginAPI.logIn(requestBody);
      dispatch(sendEvent(data.key, { eventType: 'login' }));
      dispatch(
        getUserData({
          token: data.key,
          getUserOrder: () => dispatch(getOrderInfo(data.key)),
          successCallback
        })
      );
    } catch (err) {
      const errorMessage = getErrorMessage(err?.response?.data) || 'Something went wrong while attempting to log in.';
      dispatch(loginActions.postLogInFail(errorMessage));
      sendSentryError({ err, context: 'postLogIn' });
      if (errorCallback) return errorCallback(errorMessage);
    }
  };
};

export const ssoLoginActions = actionCreatorsFactory(
  [
    LoginActionTypes.POST_SSO_LOG_IN,
    LoginActionTypes.POST_SSO_LOG_IN_SUCCESS,
    LoginActionTypes.POST_SSO_LOG_IN_FAIL,
    LoginActionTypes.CLEAR_POST_LOG_IN_ERROR
  ],
  'postSsoLogIn'
);

export const postSsoLogIn = ({ accessToken, successCallback }) => {
  return async (dispatch) => {
    try {
      dispatch(ssoLoginActions.postSsoLogIn());
      const { data } = await LoginAPI.exchangeAccessToken({ accessToken });

      dispatch(sendEvent(data.key, { eventType: 'login' }));
      dispatch(
        getUserData({
          token: data.key,
          getUserOrder: () => dispatch(getOrderInfo(data.key)),
          successCallback
        })
      );
    } catch (err) {
      sendSentryError({ err, context: 'postSsoLogIn' });
      const errorMessage =
        (err && err.response && err.response.data && err.response.data.error_message) || 'Something went wrong while attempting to log in.';
      dispatch(ssoLoginActions.postSsoLogInFail(errorMessage));
    }
  };
};

export function postLogInImpersonate({ token, successCallback, errorCallback }) {
  return async (dispatch) => {
    try {
      dispatch(loginActions.postLogIn());
      storageUtils.markLogout();
      dispatch(sendEvent(token, { eventType: 'login_impersonate' }));
      dispatch(
        getUserData({
          token,
          getUserOrder: () => dispatch(getOrderInfo(token)),
          successCallback
        })
      );
    } catch (err) {
      sendSentryError({ err, context: 'postLogInImpersonate' });
      dispatch(loginActions.postLogInFail('Login failed. Pleasy try again.'));
      if (!isTokenExpired(dispatch, err)) {
        if (errorCallback) return errorCallback();
      }
    }
  };
}

export const clearLogInError = () => loginActions.clearPostLogInError();
