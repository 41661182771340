import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import BookingSummaryItem from '../BookingSummaryItem';
import ErrorToast from '../../../design-system/toast/errorToast/ErrorToast';

import {
  closeBookingModal,
  cleanErrorState,
  createAppointmentAndRedirectToAnotherPage,
  createAppointmentAndGetUpdatedStudentsBooking,
  addToBookingSelectedTimestamp,
  removeFromBookingSelectedTimestamp
} from '../../../../actions/new-booking';

import {
  cleanSelectedTimestamp,
  preBookDate,
  preBookWholeMonthDate,
  removeFromPreBook,
  removeMonthFromPreBook,
  storeMonthSessionsAvailability
} from '../../../../actions/bookable';
import SessionsManagementModal from '../SessionsManagementModal';
import { selectProductType } from '../../../../reducers/order-info';
import { getDateFromIsoString, getTimeFromIsoString } from '../../../../utils/time-helpers';
import { calculateBookings } from './domain/calculateBookings';
import SessionsAlert from '../sessions-alert/sessions-alert';
import { buildMonthAndYearStr } from '../../../../reducers/bookable';
import { getMonthTranslated } from '../../../design-system/calendar/TimeRelated.enum';
import { CLASS_TYPES } from '../../Enum';
import mixpanelHelper from '../../../../utils/mixpanel-helper';
import useInitRecording from '../../../../hooks/useInitRecording';

const BookingTuitionSessionModal = ({
  addToBookingSelectedTimestampAction,
  availableSessionsThisMonth,
  calendar,
  cleanErrorStateAction,
  cleanSelectedTimestampAction,
  createAppointmentAndGetUpdatedStudentsBookingAction,
  createAppointmentAndRedirectToAnotherPageAction,
  currentLang,
  errorMessage,
  hasErrorOnBooking,
  isLoadingFutureScheduleAvailability,
  isSchedulingAppointment,
  matchedTutor,
  pendingToBeScheduled,
  preBookDateAction,
  preBookWholeMonthDateAction,
  productType,
  redirectTo,
  removeFromPreBookAction,
  removeFromBookingSelectedTimestampAction,
  selectedCourse,
  selectedTutor,
  selectedTimestamp,
  sessionsPerMonth,
  storeMonthSessionsAvailabilityAction,
  closeBookingModalAction,
  removeMonthFromPreBookAction,
  student,
  timezone
}) => {
  const [activeAppointmentIndex, setActiveAppointmentIndex] = useState(0);
  const [newSchedule, setNewSchedule] = useState([]);
  const [updatedLesson, setUpdatedLesson] = useState(null);
  const [endRecordingWholePage] = useInitRecording((timeSpent) =>
    mixpanelHelper.lessonAction('Booked', {
      timeSpent,
      type: 'tuition'
    })
  );

  const history = useHistory();

  const defineNewAppointment = (appointmentIndex, newTimestamp) => {
    const newAppointment = {
      ...pendingToBeScheduled[appointmentIndex],
      newDateAndTime: newTimestamp
    };
    setUpdatedLesson(newAppointment);
  };

  useEffect(() => {
    if (selectedTimestamp) defineNewAppointment(activeAppointmentIndex, selectedTimestamp);
  }, [activeAppointmentIndex, selectedTimestamp]);

  const handleSettingNewActiveLesson = (index) => {
    setActiveAppointmentIndex(index);
  };

  const blockSchedule = (timestamp) => {
    preBookDateAction(timestamp);
  };

  const unblockSchedule = (timestamp) => {
    removeFromPreBookAction(timestamp);
  };

  const addNewScheduleToFinalList = () => {
    if (!updatedLesson?.newDateAndTime) return;
    setNewSchedule([...newSchedule, updatedLesson]);
    addToBookingSelectedTimestampAction(updatedLesson.newDateAndTime);
    blockSchedule(updatedLesson.newDateAndTime);
    calculateSessions([...newSchedule, updatedLesson]);
    handleSettingNewActiveLesson(activeAppointmentIndex + 1);
    cleanSelectedTimestampAction();
  };

  const removeNewScheduleFromFinalList = (item) => {
    const filteredElement = newSchedule.find((lesson) => lesson?.id === item?.id);
    const filteredList = newSchedule.filter((lesson) => lesson?.id !== item?.id);
    setNewSchedule(filteredList);
    removeFromBookingSelectedTimestampAction(filteredElement.newDateAndTime);
    recalculateSessionsAfterExclusion(filteredList, filteredElement?.newDateAndTime);
    unblockSchedule(filteredElement.newDateAndTime);
    cleanSelectedTimestampAction();
  };

  const redirect = (url) => {
    history.push(url);
  };

  const shouldBlockMonth = (response) => {
    const canBook = response?.canBookThisMonth;
    return typeof canBook === 'boolean' && !canBook;
  };

  const transformIntoMap = (timestamps) => {
    const map = new Map();
    timestamps.forEach((ts) => {
      const key = buildMonthAndYearStr(ts);
      const prev = map.get(key) || [];
      map.set(key, [...prev, ts]);
    });
    return Object.fromEntries(map);
  };

  const calculateSessions = (booked, timestamp) => {
    const selectedTimestamps = transformIntoMap(booked.map(({ newDateAndTime }) => newDateAndTime));
    const selectedTimestamp = timestamp || calendar?.date;
    const response = calculateBookings({
      selectedTimestamp,
      selectedTimestamps,
      sessionsPerMonth
    });

    storeMonthSessionsAvailabilityAction({ timestamp: selectedTimestamp, response });
    if (shouldBlockMonth(response)) {
      preBookWholeMonthDateAction(selectedTimestamp);
    }
  };

  const recalculateSessionsAfterExclusion = (booked, timestamp) => {
    const selectedTimestamps = transformIntoMap(booked.map(({ newDateAndTime }) => newDateAndTime));
    const selectedTimestamp = timestamp || calendar?.date;
    const response = calculateBookings({
      selectedTimestamp,
      selectedTimestamps,
      sessionsPerMonth
    });

    storeMonthSessionsAvailabilityAction({ timestamp: selectedTimestamp, response });
    if (!shouldBlockMonth(response)) {
      removeMonthFromPreBookAction(selectedTimestamp);
    }
  };

  const executeAppointmentCreation = (appointmentData) => {
    if (redirectTo) {
      createAppointmentAndRedirectToAnotherPageAction({
        studentToken: student.token,
        data: appointmentData,
        successCallback: () => {
          endRecordingWholePage();
        },
        redirectFunction: redirect.bind(this, redirectTo)
      });
    } else {
      createAppointmentAndGetUpdatedStudentsBookingAction(student.token, selectedCourse?.id, appointmentData, () => {
        endRecordingWholePage();
      });
    }
  };

  const handleAppointmentCreation = () => {
    const sorted = newSchedule.sort((a, b) => a?.number - b?.number);
    const firstLessonToBeScheduled = sorted[0];
    const appointmentData = {
      auto_accept: student.is_staff,
      courseId: selectedCourse?.id,
      date: getDateFromIsoString(firstLessonToBeScheduled.newDateAndTime),
      duration: productType.duration,
      is_for_group_sessions: false, // TO-DO: missing group session
      is_student_beta_tester: student.is_beta_tester,
      language: selectedCourse?.language.language,
      lesson_number: firstLessonToBeScheduled?.number,
      phone_number: '', // TO-DO: is it always an empty string?
      product_type_id: productType.productTypeId,
      start_time: getTimeFromIsoString(firstLessonToBeScheduled.newDateAndTime),
      status: 'booked',
      student_comment: '', // TO-DO: is it always an empty string?
      time_zone: timezone,
      tutor_id: selectedTutor.id || matchedTutor.id
    };

    const timestampsToSchedule = sorted
      .slice(1)
      .map(({ newDateAndTime }) => newDateAndTime)
      .filter((el) => el);

    if (timestampsToSchedule.length) {
      const datesAndTimes = timestampsToSchedule.map((timestamp) => {
        const date = getDateFromIsoString(timestamp);
        const startTime = getTimeFromIsoString(timestamp);
        return { date, start_time: startTime };
      });
      appointmentData.recurringReschedules = datesAndTimes;
      appointmentData.recurring_reschedule = true;
    }
    executeAppointmentCreation(appointmentData);
  };

  const getNewAppointmentTime = (currentLessonId) => {
    const futureAppointment = newSchedule.find((l) => l?.id === currentLessonId);
    if (!futureAppointment) return;
    return futureAppointment.newDateAndTime;
  };

  const getSelectedTimestamps = useMemo(() => {
    return newSchedule.map(({ newDateAndTime }) => newDateAndTime);
  }, [newSchedule]);

  const Alert = <SessionsAlert availableSessionsPerMonth={availableSessionsThisMonth} monthName={getMonthTranslated(currentLang)[calendar?.month]} />;

  const isThisBookingDone = (lesson) => {
    return newSchedule.some((l) => l?.id === lesson?.id);
  };

  const isPrevBookingDone = (lesson) => {
    if (!lesson) return true;
    return newSchedule.some((l) => l?.id === lesson?.id);
  };

  return (
    <>
      <SessionsManagementModal
        disabledActionButton={!newSchedule.length}
        enableAlertOnLeaving
        futureSchedule={pendingToBeScheduled}
        handleExecution={handleAppointmentCreation}
        isExecuting={isSchedulingAppointment}
        isLoading={isLoadingFutureScheduleAvailability}
        modalTitle="Booking Live Practice"
        onCloseModal={() => closeBookingModalAction()}
        type={CLASS_TYPES.tuition}
        productType={productType}
        renderAlert={() => Alert}
        renderAppointmentItem={(lesson, index) => (
          <BookingSummaryItem
            key={index}
            active={index === activeAppointmentIndex}
            setNewActiveAppointment={handleSettingNewActiveLesson.bind(this, index)}
            disabled={!isThisBookingDone(lesson) && !isPrevBookingDone(pendingToBeScheduled[index - 1])}
            done={isThisBookingDone(lesson)}
            doneScheduling={addNewScheduleToFinalList}
            removeNewScheduleFromFinalList={removeNewScheduleFromFinalList}
            lesson={lesson}
            interimTimestamp={selectedTimestamp}
            finalTimestamp={getNewAppointmentTime(lesson?.id)}
            testId="schedule-session"
            timezone={timezone}
          />
        )}
        selectedTimestamp={selectedTimestamp}
        selectedTimestamps={getSelectedTimestamps}
        testId="booking-tuition-session-modal"
      />
      <ErrorToast errorMsg={errorMessage} show={hasErrorOnBooking} onClose={cleanErrorStateAction} />
    </>
  );
};

const mapStateToProps = (state) => ({
  availableSessionsPerMonth: state.bookable.available.availableSessionsPerMonth,
  availableSessionsThisMonth: state.bookable.available.availableSessionsThisMonth,
  calendar: state.bookable.available.calendar,
  currentLang: state.appLanguage.currentLanguage,
  errorMessage: state.newBooking.bookingLessons.error,
  hasErrorOnBooking: state.newBooking.bookingLessons.hasErrorOnBooking,
  isLoadingFutureScheduleAvailability: state.bookable.futureSchedule.isLoadingFutureSchedule,
  isSchedulingAppointment: state.newBooking.bookingLessons.isCreatingAppointment,
  matchedTutor: state.selectedTutor.matchedTutor,
  pendingToBeScheduled: state.newBooking.bookingLessons.appointmentsToBeBooked,
  productType: selectProductType(state.orderInfo, CLASS_TYPES.tuition),
  selectedCourse: state.courses?.selectedCourseDetails?.courseDetails,
  selectedTutor: state.selectedTutor.selectedTutor,
  selectedTimestamp: state.bookable.available.calendar?.selectedTimestamp,
  sessionsPerMonth: state.bookable.available.sessionsPerMonth,
  student: state.user,
  timezone: state.time.timezone
});

BookingTuitionSessionModal.propTypes = {
  addToBookingSelectedTimestampAction: PropTypes.func.isRequired,
  availableSessionsThisMonth: PropTypes.object.isRequired,
  calendar: PropTypes.object.isRequired,
  cleanErrorStateAction: PropTypes.func.isRequired,
  cleanSelectedTimestampAction: PropTypes.func.isRequired,
  createAppointmentAndGetUpdatedStudentsBookingAction: PropTypes.func.isRequired,
  createAppointmentAndRedirectToAnotherPageAction: PropTypes.func.isRequired,
  currentLang: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  hasErrorOnBooking: PropTypes.bool,
  isLoadingFutureScheduleAvailability: PropTypes.bool,
  isSchedulingAppointment: PropTypes.bool,
  matchedTutor: PropTypes.object,
  pendingToBeScheduled: PropTypes.array.isRequired,
  preBookDateAction: PropTypes.func.isRequired,
  preBookWholeMonthDateAction: PropTypes.func.isRequired,
  productType: PropTypes.object.isRequired,
  redirectTo: PropTypes.string,
  removeFromPreBookAction: PropTypes.func.isRequired,
  removeFromBookingSelectedTimestampAction: PropTypes.func.isRequired,
  selectedCourse: PropTypes.object.isRequired,
  selectedTutor: PropTypes.object,
  sessionsPerMonth: PropTypes.object,
  selectedTimestamp: PropTypes.string,
  storeMonthSessionsAvailabilityAction: PropTypes.func.isRequired,
  closeBookingModalAction: PropTypes.func.isRequired,
  student: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  removeMonthFromPreBookAction: PropTypes.func.isRequired
};

BookingTuitionSessionModal.defaultProps = {
  isSchedulingAppointment: false,
  redirectTo: null
};

export default connect(mapStateToProps, {
  addToBookingSelectedTimestampAction: addToBookingSelectedTimestamp,
  createAppointmentAndGetUpdatedStudentsBookingAction: createAppointmentAndGetUpdatedStudentsBooking,
  createAppointmentAndRedirectToAnotherPageAction: createAppointmentAndRedirectToAnotherPage,
  cleanErrorStateAction: cleanErrorState,
  cleanSelectedTimestampAction: cleanSelectedTimestamp,
  preBookDateAction: preBookDate,
  preBookWholeMonthDateAction: preBookWholeMonthDate,
  removeFromPreBookAction: removeFromPreBook,
  removeFromBookingSelectedTimestampAction: removeFromBookingSelectedTimestamp,
  removeMonthFromPreBookAction: removeMonthFromPreBook,
  storeMonthSessionsAvailabilityAction: storeMonthSessionsAvailability,
  closeBookingModalAction: closeBookingModal
})(BookingTuitionSessionModal);
