import EventsAPI from '../services/events';

import { SEND_EVENT_SUCCESS, SEND_EVENT_FAIL } from './types';

function sendEventSuccess() {
  return {
    type: SEND_EVENT_SUCCESS
  };
}

function sendEventFail() {
  return {
    type: SEND_EVENT_FAIL
  };
}

export default function sendEvent(token, data) {
  return async (dispatch) => {
    try {
      const { eventType, appointmentId } = data;
      await EventsAPI.sendEvent(token, eventType, appointmentId);
      dispatch(sendEventSuccess());
    } catch (err) {
      dispatch(sendEventFail());
    }
  };
}
