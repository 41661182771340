import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './Steps.module.scss';
import { capitalize } from '../../utils/string-helpers';
import NavigationArrows from '../design-system/navigation-arrows/NavigationArrows';

const Steps = ({ currentStep, enabledSteps, steps, selectStep }) => {
  const [enableMoveBack, setEnableMoveBack] = useState(false);
  const [enableMoveForward, setEnableMoveForward] = useState(false);

  useEffect(() => {
    const nextStep = currentStep + 1;
    setEnableMoveBack(currentStep > 0);
    setEnableMoveForward(isStepEnabled(nextStep));
  }, [currentStep]);

  const isStepEnabled = (index) => enabledSteps.some((stepIndex) => stepIndex === index);

  const isCurrentStep = (index) => currentStep === index;

  const moveForward = () => selectStep(currentStep + 1);

  const moveBack = () => selectStep(currentStep - 1);

  const handleUserClick = (stepIndex) => {
    if (isStepEnabled(stepIndex)) selectStep(stepIndex);
  };

  return (
    <div className={styles['steps-wrapper']}>
      <div className={styles['navigation-wrapper']}>
        <NavigationArrows enableMoveBack={enableMoveBack} enableMoveForward={enableMoveForward} onBack={moveBack} onForward={moveForward} />
      </div>
      {steps.map((step, index) => (
        <div
          role="button"
          key={index}
          onClick={() => handleUserClick(index)}
          className={[styles['button-wrapper'], isStepEnabled(index) && styles['enabled'], isCurrentStep(index) && styles['current']].join(' ')}>
          <div className={styles['step-dot']} />
          <p className={styles['step-label']}>{capitalize(step)}</p>
        </div>
      ))}
    </div>
  );
};

Steps.propTypes = {
  currentStep: PropTypes.number.isRequired,
  enabledSteps: PropTypes.array.isRequired,
  steps: PropTypes.array.isRequired,
  selectStep: PropTypes.func.isRequired
};

export default Steps;
