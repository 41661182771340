export const CLASS_TYPES = Object.freeze({
  conversation: 'conversation',
  tuition: 'tuition',
  myc: 'meet_your_coach'
});

export const BULK_OPTIONS = Object.freeze({
  reschedule: 'Reschedule',
  cancel: 'Cancel session(s)'
});

export const BOOKING_URL = '/bookings';
