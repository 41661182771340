import {
  CANCEL_APPOINTMENT,
  CANCEL_APPOINTMENT_ERROR,
  CREATE_APPOINTMENT,
  GET_APPOINTMENTS,
  GET_APPOINTMENTS_ERROR,
  USER_LOGOUT,
  RESET_APPOINTMENTS,
  LOAD_MORE_APPOINTMENTS
} from '../actions/types';
import { AppointmentsActionTypes } from '../actions/appointments';
import { convertStringsOfDateAndTimeToDate } from '../utils/time-helpers';
import { isBefore } from 'date-fns';

export const selectAppointments = (state) => state.appointments;

const sortByDate = (appts) => {
  return appts.sort((appt1, appt2) => {
    const apptDate1 = convertStringsOfDateAndTimeToDate(appt1?.date, appt1?.start_time);
    const apptDate2 = convertStringsOfDateAndTimeToDate(appt2?.date, appt2?.start_time);
    return isBefore(apptDate2, apptDate1) ? 1 : -1;
  });
};

const mergeAppointments = (existingAppointments, newAppointments) => {
  const appointmentsMap = {};

  // Map existing appointments by ID
  existingAppointments.forEach((appt) => {
    appointmentsMap[appt.id] = appt;
  });

  // Update or add new appointments from the newAppointments array
  newAppointments.forEach((appt) => {
    appointmentsMap[appt.id] = appt;
  });

  // Convert the map back to an array
  return Object.values(appointmentsMap);
};

const initialState = {
  errorGettingTutorAppointments: false,
  isGettingTutorAppointments: false,
  tutorAppointments: [],
  isRegisteringTutorAttendance: false,
  isRegisteringTutorAttendanceSuccess: false,
  errorRegisteringTutorAttendance: false,
  isUpdatingAppointment: false,
  errorUpdatingAppointment: false,
  isChangingAppointmentStatus: false,
  isChangingAppointmentStatusSuccess: false,
  errorChangingAppointmentStatus: false
};

export default function appointmentsReducer(state = initialState, action) {
  switch (action.type) {
    case AppointmentsActionTypes.GET_TUTOR_APPOINTMENTS:
      return { ...state, isGettingTutorAppointments: true, errorGettingTutorAppointments: false };

    case AppointmentsActionTypes.GET_TUTOR_APPOINTMENTS_SUCCESS: {
      const updatedAppointments = mergeAppointments(state.tutorAppointments, action.payload);
      return {
        ...state,
        tutorAppointments: sortByDate(updatedAppointments),
        isGettingTutorAppointments: false,
        errorGettingTutorAppointments: false
      };
    }

    case AppointmentsActionTypes.GET_TUTOR_APPOINTMENTS_FAIL:
      return {
        ...state,
        isGettingTutorAppointments: false,
        errorGettingTutorAppointments: action.payload
      };

    case AppointmentsActionTypes.REGISTER_TUTOR_ATTENDANCE:
      return {
        ...state,
        isRegisteringTutorAttendance: true,
        errorRegisteringTutorAttendance: false,
        isRegisteringTutorAttendanceSuccess: false
      };

    case AppointmentsActionTypes.REGISTER_TUTOR_ATTENDANCE_SUCCESS:
      return {
        ...state,
        isRegisteringTutorAttendance: false,
        errorRegisteringTutorAttendance: false,
        isRegisteringTutorAttendanceSuccess: true
      };

    case AppointmentsActionTypes.REGISTER_TUTOR_ATTENDANCE_FAIL:
      return {
        ...state,
        isRegisteringTutorAttendance: false,
        errorRegisteringTutorAttendance: action.payload,
        isRegisteringTutorAttendanceSuccess: false
      };

    case CANCEL_APPOINTMENT:
      return {
        ...state,
        tutorAppointments: state.tutorAppointments.filter((el) => el.id !== action.payload.data.id)
      };

    case CANCEL_APPOINTMENT_ERROR:
      return state;

    case CREATE_APPOINTMENT: {
      const updatedAppointments = mergeAppointments(state.tutorAppointments, [action.payload.data]);
      return {
        ...state,
        tutorAppointments: sortByDate(updatedAppointments)
      };
    }

    case GET_APPOINTMENTS: {
      const updatedAppointments = mergeAppointments(state.tutorAppointments, action.payload);
      return {
        ...state,
        tutorAppointments: sortByDate(updatedAppointments)
      };
    }

    case RESET_APPOINTMENTS:
      return {
        ...state,
        tutorAppointments: []
      };

    case GET_APPOINTMENTS_ERROR:
      return {
        ...state,
        tutorAppointments: []
      };

    case AppointmentsActionTypes.UPDATE_APPOINTMENT:
      return {
        ...state,
        isUpdatingAppointment: true,
        errorUpdatingAppointment: false
      };

    case AppointmentsActionTypes.UPDATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isUpdatingAppointment: false,
        errorUpdatingAppointment: false
      };

    case AppointmentsActionTypes.UPDATE_APPOINTMENT_FAIL:
      return {
        ...state,
        isUpdatingAppointment: false,
        errorUpdatingAppointment: action.payload
      };

    case AppointmentsActionTypes.CHANGE_APPOINTMENT_STATUS:
      return {
        ...state,
        isChangingAppointmentStatus: true,
        isChangingAppointmentStatusSuccess: false,
        errorChangingAppointmentStatus: false
      };

    case AppointmentsActionTypes.CHANGE_APPOINTMENT_STATUS_SUCCESS:
      return {
        ...state,
        tutorAppointments: state.tutorAppointments.map((appointment) => {
          if (appointment.id === action.payload.appointmentId) {
            return {
              ...appointment,
              outcome_assigned: true
            };
          }
          return appointment;
        }),
        isChangingAppointmentStatus: false,
        isChangingAppointmentStatusSuccess: true,
        errorChangingAppointmentStatus: false
      };

    case AppointmentsActionTypes.CHANGE_APPOINTMENT_STATUS_FAIL:
      return {
        ...state,
        isChangingAppointmentStatus: false,
        isChangingAppointmentStatusSuccess: false,
        errorChangingAppointmentStatus: action.payload
      };

    case AppointmentsActionTypes.CLEAN_CHANGE_APPOINTMENT_STATUS:
      return {
        ...state,
        isChangingAppointmentStatus: false,
        isChangingAppointmentStatusSuccess: false,
        errorChangingAppointmentStatus: false
      };

    case LOAD_MORE_APPOINTMENTS: {
      const updatedAppointments = mergeAppointments(state.tutorAppointments, action.payload);
      return {
        ...state,
        tutorAppointments: sortByDate(updatedAppointments)
      };
    }

    case USER_LOGOUT:
      return {
        ...state,
        tutorAppointments: []
      };

    default:
      return {
        ...state,
        isRegisteringTutorAttendanceSuccess: false
      };
  }
}
