import { GET_PRODUCT_TYPES, GET_PRODUCT_TYPES_ERROR, USER_LOGOUT } from '../actions/types';

export default function ProductTypesReducer(state = null, action) {
  switch (action.type) {
    case GET_PRODUCT_TYPES:
      return action.payload.data;
    case GET_PRODUCT_TYPES_ERROR:
      return null;
    case USER_LOGOUT:
      return null;
    default:
      return state;
  }
}
