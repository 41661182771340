import { SelectedTutorActionTypes } from '../actions/selected-tutor';

const selectedTutorInitialState = {
  isLoadingGetMatchedtutor: false,
  matchedTutor: { id: null, name: null },
  selectedTutor: { id: null, name: null },
  hasErrorOnMatchedTutor: false,
  errorMatchedTutor: ''
};

export default function selectedLanguageReducer(state = selectedTutorInitialState, action) {
  switch (action.type) {
    case SelectedTutorActionTypes.GET_MATCHED_TUTOR:
      return { ...state, isLoadingGetMatchedtutor: true };
    case SelectedTutorActionTypes.GET_MATCHED_TUTOR_SUCCESS:
      return { ...state, matchedTutor: action.payload, isLoadingGetMatchedtutor: false };
    case SelectedTutorActionTypes.GET_MATCHED_TUTOR_FAIL:
      return {
        ...state,
        hasErrorOnMatchedTutor: true,
        errorMatchedTutor:
          'It seems that you don\t have a tutor associated with you right now. Go through the onboarding process again to keep scheduling your lessons.'
      };
    case SelectedTutorActionTypes.SET_MATCHED_TUTOR:
      return {
        ...state,
        matchedTutor: {
          id: action.payload.tutor.id,
          name: `${action.payload.tutor.first_name} ${action.payload.tutor.last_name}`
        },
        issue: action.payload.issue,
        tutorSecondChoice: action.payload.secondChoice,
        confetti: action.payload.confetti
      };
    case SelectedTutorActionTypes.SET_SELECTED_TUTOR:
      return { ...state, selectedTutor: action.payload };

    case SelectedTutorActionTypes.CLEAN_MATCHED_TUTOR_ERROR:
      return { ...state, hasErrorOnMatchedTutor: false, errorMatchedTutor: '' };
    case SelectedTutorActionTypes.CLEAN_MATCHED_TUTOR_ERROR_MESSAGE:
      return { ...state, errorMatchedTutor: '' };
    default:
      return state;
  }
}
