import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

import ChatPageMobile from '../components/chat/chat-mobile';
import ChatPageWeb from '../components/chat/chat-web';

const LanguageHelperChat = () => {
  if (isMobile) return <ChatPageMobile />;

  return <ChatPageWeb />;
};

LanguageHelperChat.propTypes = {
  user: PropTypes.object
};

export default LanguageHelperChat;
