import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../style/components/NotFound.module.scss';

const NotFound = () => (
  <div className={styles.message_container}>
    <div className={styles.message}>
      <h1>Sorry, we couldn&#39;t find this page.</h1>
      <p>Please check you&#39;ve entered the right URL.</p>
      <p>
        If you need assistance, please contact us at <a href="mailto:support@chatterbox.io">support@chatterbox.io</a>.
      </p>
      <Link to="/">Go Back</Link>
    </div>
  </div>
);

export default NotFound;
