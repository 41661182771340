import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import styles from '../../MenuItem.module.scss';
import { ReactComponent as IconAccount } from '../../../../assets/icons/icon_account-menu.svg';

const AccountButton = ({ handleMenuLinkClick }) => {
  return (
    <li className={styles['link-wrapper']}>
      <NavLink
        to={'/account'}
        onClick={() => handleMenuLinkClick('account')}
        activeClassName={styles.isActive}
        className={styles.menuItem}
        data-testid="account-button-link">
        <div className={styles['button-wrapper']}>
          <div className={styles['background']}>
            <IconAccount />
          </div>
          <span>Account</span>
        </div>
      </NavLink>
    </li>
  );
};

AccountButton.propTypes = {
  handleMenuLinkClick: PropTypes.func.isRequired
};

export default AccountButton;
