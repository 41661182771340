import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Widget } from '@typeform/embed-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Mixpanel from '../../utils/mixpanel-helper.js';
import styles from '../../style/containers/Onboarding.module.scss';
import { getQueryParamValue } from '../../utils/url-helpers.js';
import useInitRecording from '../../hooks/useInitRecording';
import LoadingSpinner from '../design-system/loading/spinner/LoadingSpinner.js';

const PlacementTest = ({ nextFunction, questionId, setCourse, typeformCode, user, waitingListUrl }) => {
  const [typeformInitialized, setTypeformInitialized] = useState(false);
  const [typeformOptions, setTypeformOptions] = useState({});
  const [endRecording] = useInitRecording((endTime) => Mixpanel.recordElapsedTime('Onboarding Step Placement Test page', endTime));
  const trainingType = getQueryParamValue('training_type');

  if (trainingType !== undefined && trainingType.toLowerCase() === 'conversation') {
    nextFunction();
  }

  const formSubmited = () => {
    nextFunction();
    const headers = { Authorization: `Token ${user.token}` };
    setTimeout(() => {
      axios
        .post('/api/get_typeform_data/', { placement_test_id: typeformCode }, { headers })
        .then((response) => {
          setCourse(response.data);
        })
        .catch(() => {
          // Raven.captureException(e);
        });
    }, 15000);
  };

  const onQuestionChanged = (data) => {
    if (data && questionId && data.ref === questionId) {
      window.location.href = waitingListUrl;
    }
  };

  const initializeTypeform = () => {
    if (typeformCode && !typeformInitialized) {
      setTypeformOptions({
        hideHeaders: true,
        hideFooter: true,
        opacity: 100,
        autoClose: 0,
        onSubmit: formSubmited,
        hidden: {
          student_name: `${user.first_name} ${user.last_name}`,
          student_email: `${user.email}`
        },
        onQuestionChanged
      });
      setTypeformInitialized(true);
    }
  };

  useEffect(() => {
    initializeTypeform();
  }, [typeformCode]);

  useEffect(() => {
    Mixpanel.visitedPage('Onboarding Step Placement Test');
    initializeTypeform();

    return () => endRecording();
  }, []);

  return (
    <div style={{ width: '100%', height: '565px' }} className={styles.stepWrapper}>
      {typeformCode ? (
        <Widget id={typeformCode} className={styles.typeformContainer} {...typeformOptions} />
      ) : (
        <div className={styles.placementTestLoadingContainer}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

PlacementTest.propTypes = {
  user: PropTypes.object.isRequired,
  nextFunction: PropTypes.func.isRequired,
  setCourse: PropTypes.func.isRequired,
  typeformCode: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  waitingListUrl: PropTypes.string
};

PlacementTest.defaultProps = {
  waitingListUrl: 'https://chatterbox.io/waitinglist'
};

export default connect(mapStateToProps, {})(PlacementTest);
