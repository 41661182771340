import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component, user, backupComponent, ...rest }) => {
  const { token } = user;
  const path = rest?.location?.pathname;

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          React.cloneElement(component, { ...props })
        ) : backupComponent ? (
          backupComponent
        ) : (
          <Redirect to={{ pathname: '/', state: { to: path } }} />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.user
});

PrivateRoute.propTypes = {
  component: PropTypes.element,
  backupComponent: PropTypes.element,
  user: PropTypes.object.isRequired
};

PrivateRoute.defaultProps = {
  component: null
};

export default connect(mapStateToProps, null)(PrivateRoute);
