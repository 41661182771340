export function capitalize(string) {
  if (string) {
    const firstChar = string.charAt(0);
    return `${firstChar.toUpperCase()}${string.slice(1)}`;
  }
  return '';
}

export function getHumanFriendlyFromSnakeCase(string) {
  const newString = string.replace(/_/g, ' ');
  return capitalize(newString);
}

export function capitalizeEachWord(string) {
  if (!string) return '';
  return string
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');
}

export function getListString(array, { and = true, serialComma = true, spaces = true } = {}) {
  let str = '';

  if (array.length === 0) {
    return str;
  }

  if (array.length === 1) {
    return String(array[0]);
  }

  const arrayClone = array.slice(0);
  const lastItem = arrayClone.pop();

  let delimiter = ',';

  if (spaces) {
    delimiter = `${delimiter} `;
  }

  str = arrayClone.join(delimiter);

  if (serialComma && array.length > 2) {
    str = `${str},`;
  }

  if (spaces) {
    str = `${str} `;
  }

  if (and) {
    str = `${str}and `;
  }

  str = `${str}${lastItem}`;

  return str;
}

export function getSnakeCaseFromCamelCase(string) {
  return string.replace(/([A-Z])/g, (letter) => `_${letter.toLowerCase()}`);
}

export function validateEmail(email) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i.test(email);
}

export function cutWordsAndAddEllipsis(str, numberOfWords) {
  if (!str || !numberOfWords) return;
  if (str.length <= numberOfWords) return str;
  const arrayOfWords = str?.split(' ').slice(0, numberOfWords);
  const withEllipsis = [...arrayOfWords, '(...) '];
  return withEllipsis.join(' ');
}

export function cutStringAndAddEllipsis(str, numberOfLetters) {
  if (!str || !numberOfLetters) return;
  if (str.length <= numberOfLetters) return str;
  const arrayOfLetters = str?.split('').slice(0, numberOfLetters);
  const withEllipsis = [...arrayOfLetters, '...'];
  return withEllipsis.join('');
}

export const transformUnderscoreToCamelCase = (str) => {
  if (!str) return str;
  const splitted = str.split('_');
  return splitted
    .map((word, index) => {
      if (!index) return word;
      return capitalize(word);
    })
    .join('');
};

export const transformObjectUnderscoreToCamelCase = (obj) => {
  if (typeof obj !== 'object') return;
  return Object.keys(obj).reduce((acc, cv) => {
    return { ...acc, [transformUnderscoreToCamelCase(cv)]: obj[cv] };
  }, {});
};

export const convertBooleanStringToBoolean = (str) => {
  if (!str || str === 'false' || str === 'False') return false;
  return true;
};

export const convertBooleanString = (str) => {
  if (str === 'true') return true;
  return false;
};

export const getInitials = (name) => {
  const splitted = name.split(' ');
  return splitted.map((word) => word[0]).join('');
};

export function replaceUnderscoreWithSpace(string) {
  if (!string) return '';
  return string.replace(/_/g, ' ');
}

export const toBase64 = (str) => btoa(str);
