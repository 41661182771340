import React from 'react';
import PropTypes from 'prop-types';
import ReactStars from 'react-stars';
import Button from '../design-system/button/Button';
import styles from '../../style/components/tutor-list/TutorCard.module.scss';

const TutorCard = ({ name, languages, lessonTypes, country, interests, rating, availabilityMatching, reviews, bio, onViewProfile, imgUrl }) => (
  <div className={styles.tutorCardWrapper} onClick={onViewProfile} role="button" onKeyUp={onViewProfile} tabIndex={0}>
    <div className={styles.tutorCtaContainer}>
      <img className={styles.tutorProfilePic} src={imgUrl} alt="tutor profile" />
      <Button onClick={onViewProfile}>View Profile</Button>
    </div>
    <div className={styles.tutorInfoWrapper}>
      <div className={styles.tutorInfoTop}>
        <div className={styles.tutorInfoLeft}>
          <div className={[styles.tutorInfo1, styles.tutorName].join(' ')} onClick={onViewProfile} role="button" onKeyUp={onViewProfile} tabIndex={0}>
            <strong>{name}</strong>
          </div>
          <p className={styles.tutorInfo1}>
            <strong>SPEAKS:</strong>{' '}
            {`${languages.map((lg) => `${lg.language}`).join(', ')}${
              languages.some((l) => l.language.toLowerCase() === 'english') ? '' : ', English'
            }`}
          </p>
          {country !== undefined && country !== '' && (
            <>
              <p className={styles.tutorInfo1}>
                <strong>FROM:</strong> {country}
                &nbsp;&nbsp;&nbsp;
              </p>
              <p className={styles.tutorInfo1}>
                <strong>TEACHES:</strong> {lessonTypes.join(', ')}
              </p>
            </>
          )}
          {interests !== undefined && interests.length > 0 && (
            <p className={styles.tutorInfo1}>
              <strong>INTEREST{interests.length > 1 && 'S'}:</strong> {interests.join(', ')}
            </p>
          )}
        </div>
        <div className={styles.tutorInfoRight}>
          {!availabilityMatching && <div className={styles.tutorInfoTag}>Availability not matching</div>}
          {rating && reviews !== '0 reviews' && <ReactStars count={5} size={30} edit={false} value={rating} color1="#e7ce9b" color2="#ef9349" />}
          {rating && reviews !== '0 reviews' && <p className={styles.tutorInfo1}>{`${rating} (${reviews})`}</p>}
        </div>
      </div>
      <p className={styles.tutorBio}>{bio.length > 170 ? `${bio.slice(0, 170)}...` : bio}</p>
    </div>
  </div>
);

TutorCard.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  onViewProfile: PropTypes.func.isRequired,
  bio: PropTypes.string.isRequired,
  reviews: PropTypes.string.isRequired,
  availabilityMatching: PropTypes.bool.isRequired,
  rating: PropTypes.number.isRequired,
  interests: PropTypes.array.isRequired,
  languages: PropTypes.array.isRequired,
  country: PropTypes.string.isRequired,
  lessonTypes: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired
};

export default TutorCard;
