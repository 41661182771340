import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../design-system/button/Button';

import styles from '../../style/containers/TestPage.module.scss';

const TestIntro = ({ nextPage }) => (
  <>
    <h1>Chatterbox Technical Test</h1>
    <p className={styles.introParagraph}>
      Welcome! This page will guide you through a series of technical tests to ensure that the Chatterbox application is fully compatible with your
      system.
    </p>
    <div className={styles.buttonContainer}>
      <Button outfit="regularButton" className={styles.nextButton} onClick={nextPage}>
        Next
      </Button>
    </div>
  </>
);

TestIntro.propTypes = {
  nextPage: PropTypes.func.isRequired
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(TestIntro);
