import { CREATE_INVOICE, GET_INVOICES, GET_INVOICES_ERROR, USER_LOGOUT } from '../actions/types';

export default function invoicesReducer(state = [], action) {
  let newState;

  switch (action.type) {
    case CREATE_INVOICE:
      newState = state.slice(0);
      newState.unshift(action.payload.data);
      return newState;
    case GET_INVOICES:
      return action.payload.data;
    case GET_INVOICES_ERROR:
      return [];
    case USER_LOGOUT:
      return [];
    default:
      return state;
  }
}
