import React from 'react';
import PropTypes from 'prop-types';

import AvailabilityDay from './AvailabilityDay';

import styles from '../../style/components/availability/AvailabilityWeek.module.scss';

const renderItem = (date, timeRanges, token, renewDay, errorCallback, closeAlert) => (
  <AvailabilityDay
    availabilityBlocks={timeRanges}
    closeAlert={closeAlert}
    date={date}
    errorCallback={errorCallback}
    key={date}
    renewDay={renewDay}
    token={token}
  />
);

const AvailabilityWeek = (props) => {
  const setterItems = [];
  const { availabilities, closeAlert, errorCallback, renewDay, token } = props;

  if (availabilities) {
    // eslint-disable-next-line no-unused-vars
    for (const date in availabilities) {
      if (date) {
        const timeRanges = availabilities[date];
        const dateObject = new Date(date);
        const offset = dateObject.getTimezoneOffset() * 60000;
        const dateUtc = new Date(dateObject.getTime() + offset);
        setterItems.push(renderItem(dateUtc, timeRanges, token, renewDay, errorCallback, closeAlert));
      }
    }
  }

  return <div className={styles.container}>{setterItems}</div>;
};

AvailabilityWeek.propTypes = {
  availabilities: PropTypes.object.isRequired,
  closeAlert: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
  renewDay: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired
};

export default AvailabilityWeek;
