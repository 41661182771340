import { GET_AVAILABILTIES, DELETE_AVAILABILITY, NEW_AVAILABILITY, UPDATE_AVAILABILITY, USER_LOGOUT } from '../actions/types';

export default function availabilitiesReducer(state = [], action) {
  let data;
  let newState;
  let updateIndex;
  let existingDates;
  let newDates;
  switch (action.type) {
    case NEW_AVAILABILITY:
      data = action.payload.data;
      newState = state.slice(0);
      newState.push({
        id: data.id,
        date: data.start_date,
        from: data.start_time,
        to: data.end_time,
        recur_weekly: data.recur_weekly
      });
      return newState;
    case UPDATE_AVAILABILITY:
      newState = state.slice(0);
      data = action.payload.data;
      updateIndex = newState.findIndex((el) => el.id === data.id);
      newState[updateIndex] = {
        id: data.id,
        date: data.start_date,
        from: data.start_time,
        to: data.end_time,
        recur_weekly: data.recur_weekly
      };
      return newState;
    case DELETE_AVAILABILITY:
      newState = state.slice(0);
      updateIndex = newState.findIndex((el) => el.id === action.payload);
      newState.splice(updateIndex, 1);
      return newState;
    case GET_AVAILABILTIES:
      data = action.payload.data;
      newState = state.slice(0);
      existingDates = state.map((x) => x.date);
      newDates = data.filter((x) => !existingDates.includes(x.date));
      return newState.concat(
        newDates.map((x) => ({
          id: x.id,
          date: x.date,
          from: x.from_time,
          to: x.to_time,
          recur_weekly: x.recur_weekly
        }))
      );
    case USER_LOGOUT:
      return [];
    default:
      return state;
  }
}
