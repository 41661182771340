import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from '../../style/containers/Onboarding.module.scss';
import Button from '../design-system/button/Button';
import HighlightSpan from '../design-system/highlight-span/HighlightSpan';
import mixpanel from '../../utils/mixpanel-helper';
import useInitRecording from '../../hooks/useInitRecording';

const StudentTutorMatching = ({ readyToShowTutorMatched, sendUserToTutorsPage }) => {
  const [endRecording] = useInitRecording((endTime) => mixpanel.recordElapsedTime('Onboarding Step Tutor Matching page', endTime));

  useEffect(() => {
    mixpanel.visitedPage('Onboarding Step Tutor Matching');

    return () => endRecording();
  }, []);

  const handleGoToTutorsPage = () => {
    sendUserToTutorsPage();
  };

  return (
    <div className={styles.stepWrapper}>
      <div className={styles['onboarding__title-wrapper']}>
        <h1 className={styles.onboardingTitle}>Who are the Chatterbox coaches?</h1>
        <h2 className={[styles.onboardingHeader, styles.onboardingText].join(' ')}>
          <span>
            Our coaches are all&nbsp;
            <HighlightSpan>superstars</HighlightSpan> who’ve fought harder than most and come out the other side.
          </span>
        </h2>
        <h2 className={[styles.onboardingHeader, styles.onboardingText].join(' ')}>
          <span>
            From&nbsp;
            <HighlightSpan>asylum seekers</HighlightSpan> and&nbsp;
            <HighlightSpan>refugees</HighlightSpan> to&nbsp;
            <HighlightSpan>full-time carers</HighlightSpan>,&nbsp;
            <HighlightSpan>parents</HighlightSpan>, to&nbsp;
            <HighlightSpan>people with a disability</HighlightSpan> or people who are&nbsp;
            <HighlightSpan>aged over 50</HighlightSpan>.
          </span>
        </h2>
        <h2 className={[styles.onboardingHeader, styles.onboardingText].join(' ')}>
          <span>
            All of our coaches are&nbsp;
            <HighlightSpan>highly educated</HighlightSpan> and at the&nbsp;
            <HighlightSpan>top of their professional game</HighlightSpan>, as well as being&nbsp;
            <HighlightSpan>native speakers</HighlightSpan> of the language they coach in.
          </span>
        </h2>
        <div className={styles.loadingContainer}>
          {readyToShowTutorMatched ? (
            <div className={styles.showTutorButtonContainer}>
              <Button outfit="mainButton" onClick={handleGoToTutorsPage}>
                {' '}
                Meet my coach{' '}
              </Button>
            </div>
          ) : (
            <div className={styles.loadingWrapper}>
              <div className={styles.loading} />
              <div className={styles.loadingText}>Ready to start making an impact?</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

StudentTutorMatching.propTypes = {
  readyToShowTutorMatched: PropTypes.bool.isRequired,
  sendUserToTutorsPage: PropTypes.func.isRequired
};

export default StudentTutorMatching;
