import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import generalStyles from '../GeneralStyles.module.scss';
import styles from './Container.module.scss';

import EmailSignUp from './components/EmailSignUp';
import SsoLogin from './components/SSO/SSO';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { convertBooleanStringToBoolean } from '../../../utils/string-helpers';

const SignUp = ({ moveToNextStep, selectedTutor, setManuallySetLogIn }) => {
  const params = useQueryParams();
  const preDefinedMethod = convertBooleanStringToBoolean(params['is_sso']) ? 'sso' : 'email';

  const availableMethods = {
    email: <EmailSignUp moveToNextStep={moveToNextStep} setManuallySetLogIn={setManuallySetLogIn} />,
    sso: <SsoLogin moveToNextStep={moveToNextStep} isSignup />
  };

  return (
    <div className={generalStyles['content-wrapper']}>
      <h1 className={generalStyles['title']} data-testid="signup-title">
        Almost there...
      </h1>
      <p className={generalStyles['subtitle']}>
        Sign up before booking with <span id="dont-translate">{selectedTutor?.first_name}</span>
      </p>
      <div className={styles['container-wrapper']}>{availableMethods[preDefinedMethod]}</div>
    </div>
  );
};

SignUp.propTypes = {
  moveToNextStep: PropTypes.func.isRequired,
  selectedTutor: PropTypes.object,
  setManuallySetLogIn: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  selectedTutor: state.selectedTutor.selectedTutor
});

export default connect(mapStateToProps)(SignUp);
