import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, ChatList } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';

// import Support from '../Support/Conversation';
import CardLoader from '../../design-system/loading/card/CardLoader';
import { UserTypes } from '../../../domains/user/user-types';
import { cleanConversation, getChatConversations, joinConversation, setIsSupport, setSelectedConversation } from '../../../actions/chat';

import styles from './index.module.scss';
import ChatterboxLogo from '../../../assets/logos/chatterbox-logo.png';

const TABS = Object.freeze({
  all: 'All',
  coach: 'Coach',
  languageTeacher: 'Language Helper'
});

const Conversations = ({
  cleanConversationAction,
  Conversations,
  getChatConversationsAction,
  isLoadingConversations,
  isMobile,
  // isSupport,
  joinConversationAction,
  setIsSupportAction,
  setSelectedConversationAction,
  user,
  chatId
}) => {
  const [activeButton, setActiveButton] = useState(TABS.all);
  const [selectedConversationIndex, setSelectedConversationIndex] = useState(isMobile ? null : 0);
  const [isDumbLoading, setIsDumbLoading] = useState(false);
  const [filteredConversations, setFilteredConversations] = useState([]);

  useEffect(() => {
    getChatConversationsAction(user);
    return () => (isMobile ? undefined : cleanConversationAction());
  }, []);

  // select the conversation based on chatId prop
  useEffect(() => {
    if (chatId && filteredConversations.length > 0) {
      const selectedConv = filteredConversations.findIndex((conv) => conv.channelState?.uniqueName === chatId);
      if (selectedConv) {
        handleConversationSelection(selectedConv);
      }
    }
  }, [chatId, filteredConversations]);

  const triggerDumbLoading = () => {
    setIsDumbLoading(true);
    setTimeout(() => {
      setIsDumbLoading(false);
    }, 400);
  };

  const setSelectedConversation = (selectedConversation) => {
    if (!selectedConversation) return;
    setSelectedConversationAction(selectedConversation);
  };

  const handleTabChange = (conversations) => {
    triggerDumbLoading();
    setFilteredConversations(conversations);

    if (!isMobile) {
      setSelectedConversationIndex(0);
      setSelectedConversation(conversations[0]);
    }
  };

  const filterConversations = () => {
    if (activeButton === TABS.all) handleTabChange(Conversations);

    if (activeButton === TABS.coach) {
      const onlyCoaches = Conversations.filter((c) => !c?.attributes?.courseLeaderChat);
      handleTabChange(onlyCoaches);
    }

    if (activeButton === TABS.languageTeacher) {
      const onlyLanguageHelpers = Conversations.filter((c) => c?.attributes?.courseLeaderChat);
      handleTabChange(onlyLanguageHelpers);
    }
  };

  useEffect(() => {
    filterConversations();
  }, [activeButton]);

  useEffect(() => {
    setFilteredConversations(Conversations);
    if (!isMobile) {
      setSelectedConversation(Conversations[selectedConversationIndex]);
    }
  }, [Conversations]);

  const getSubtitle = (conversation) => {
    const participants = conversation?.attributes?.participantNames;
    if (!participants?.length) return '';

    const substring = participants.reduce((names, p, index) => {
      if (p?.email === user?.email) return names;
      if (index === participants.length - 1) return names + p?.name;
      return names + p?.name + ', ';
    }, 'With ');

    if (!conversation?.attributes?.courseLeaderChat) return <p>{substring}</p>;
    return (
      <>
        <p>{substring}</p>
        <div className={styles.languageHelperTag}>Language Helper</div>
      </>
    );
  };

  const handleConversationSelection = (index) => {
    if (typeof index !== 'number') return;
    setSelectedConversationIndex(index);
    if (!filteredConversations[index]) return;
    setSelectedConversation(filteredConversations[index]);
    joinConversationAction(filteredConversations[index]);
    setIsSupportAction(false);
  };

  const adaptConversationToChannel = (conversations) => {
    if (!conversations) return [];
    return conversations.map((conversation, index) => {
      const unread = conversation.getUnreadMessagesCount();
      return {
        avatar: conversation.attributes?.image || ChatterboxLogo,
        alt: conversation.friendlyName,
        title: conversation.friendlyName,
        subtitle: getSubtitle(conversation),
        date: conversation?.lastMessage?.dateCreated || conversation?.dateUpdated,
        unread,
        object: conversation,
        index,
        className: [
          styles.conversationBox,
          index === selectedConversationIndex && styles.conversationBoxSelected,
          unread > 0 && styles.conversationBoxUnread
        ].join(' ')
      };
    });
  };

  // const openSupportChat = () => {
  //   setSelectedConversation({});
  //   setSelectedConversationIndex(null);
  //   setIsSupportAction(true);
  // };

  const Buttons = user.user_type === UserTypes.student && (
    <div className={styles.chatNav}>
      {Object.keys(TABS).map((tab) => (
        <Button
          key={tab}
          className={activeButton === TABS[tab] ? [styles.navButton, styles.selectedNavButton].join(' ') : styles.navButton}
          text={TABS[tab]}
          onClick={() => setActiveButton(TABS[tab])}
        />
      ))}
    </div>
  );

  if (isLoadingConversations || isDumbLoading)
    return (
      <div className={styles.containerWrapper}>
        {Buttons}
        <div className={styles.loadingWrapper}>
          {new Array(4).fill(1).map((v, index) => (
            <CardLoader key={index} rows={3} rowsColor="gray" />
          ))}
        </div>
      </div>
    );

  if (!Conversations || !Conversations.length)
    return (
      <div className={styles.containerWrapper}>
        <p className={styles.noChannelsMsg}>
          Something went wrong. Try to log out and log in again. If this error persists, please contact us at{' '}
          <a href="mailto:support@chatterbox.io">support@chatterbox.io</a>
        </p>
      </div>
    );

  return (
    <div className={styles.containerWrapper}>
      {Buttons}
      <ChatList
        className={styles.chatList}
        dataSource={adaptConversationToChannel(filteredConversations)}
        onClick={({ index }) => handleConversationSelection(index)}
      />
      {/* <Support active={isSupport} onClick={openSupportChat} /> */}
    </div>
  );
};

Conversations.propTypes = {
  cleanConversationAction: PropTypes.func.isRequired,
  Conversations: PropTypes.array.isRequired,
  getChatConversationsAction: PropTypes.func.isRequired,
  isLoadingConversations: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  isSupport: PropTypes.bool,
  joinConversationAction: PropTypes.func.isRequired,
  setIsSupportAction: PropTypes.func.isRequired,
  setSelectedConversationAction: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    user_type: PropTypes.string.isRequired
  }),
  chatId: PropTypes.string
};

const mapStateToProps = (state) => ({
  Conversations: state.chat?.conversations?.Conversations,
  isLoadingConversations: state.chat?.conversations?.isLoadingConversations,
  isSupport: state.chat?.conversations?.isSupport,
  user: state.user
});

export default connect(mapStateToProps, {
  cleanConversationAction: cleanConversation,
  getChatConversationsAction: getChatConversations,
  joinConversationAction: joinConversation,
  setIsSupportAction: setIsSupport,
  setSelectedConversationAction: setSelectedConversation
})(Conversations);
