import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PasswordFormField from '../../../../design-system/password-field/PasswordFormField';
import { isStrongPassword, isMatchPassword } from '../../../../../utils/validators';
import Button from '../../../../design-system/button/Button';

import styles from './EmailSignUp.module.scss';
import { postSignUp } from '../../../../../actions/new-login';
import ErrorToast from '../../../../design-system/toast/errorToast/ErrorToast';
import { setOpenBookingIntroClassModal } from '../../../../../actions/new-booking';
import ErrorMessage from '../../../../design-system/helper-messages/error-message/ErrorMessage';

const EmailSignUp = ({
  errorMessage,
  hasLoginError,
  hasPostSignUpError,
  hasUserAuthorizationError,
  initialInformation,
  isLoadingPostLogIn,
  isLoadingPostSignUp,
  loginErrorMsg,
  moveToNextStep,
  postSignUpAction,
  setManuallySetLogIn,
  setOpenBookingIntroClassModalAction,
  userAuthorizationErrorMessage
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    const { email } = initialInformation;
    setEmail(email);
  }, []);

  useEffect(() => {
    setIsFormDirty(true);
    validateForm();
  }, [password, confirmPassword]);

  const validateForm = () => {
    const isPasswordFormFieldValid = isStrongPassword(password);
    setIsPasswordValid(isPasswordFormFieldValid);

    const isConfirmPasswordFormFieldValid = isMatchPassword(password, confirmPassword);
    setIsConfirmPasswordValid(isConfirmPasswordFormFieldValid);

    setIsFormValid(isPasswordFormFieldValid && isConfirmPasswordFormFieldValid);
  };

  const handleUserSignIn = () => {
    postSignUpAction({
      data: {
        email,
        new_password1: confirmPassword,
        new_password2: confirmPassword,
        chatterbox_news_email: true
      },
      successCallbackAfterLogIn: () => {
        setOpenBookingIntroClassModalAction(true);
        moveToNextStep();
      },
      securityParams: {
        email,
        invitationToken: initialInformation?.invitationToken
      }
    });
  };

  return (
    <form className={styles['form-wrapper']}>
      <p>
        Your email: <span id="dont-translate">{email}</span>
      </p>
      <PasswordFormField
        id="password"
        invalidMessage="Invalid password. Your password must contain at least 8 characters and 1 uppercase letter."
        isValid={isPasswordValid}
        label="Password"
        value={password}
        onChange={(evt) => setPassword(evt.target.value)}
        // disabled={hasUserAuthorizationError}
      />
      <PasswordFormField
        id="confirm-password"
        invalidMessage="Your passwords do not match"
        isValid={isConfirmPasswordValid}
        label="Confirm your password"
        value={confirmPassword}
        onChange={(evt) => setConfirmPassword(evt.target.value)}
        // disabled={hasUserAuthorizationError}
      />
      <ErrorMessage errorMsg={loginErrorMsg} show={hasLoginError} />
      <ErrorMessage errorMsg={errorMessage} show={hasPostSignUpError} />
      <div className={styles['button-wrapper']}>
        <Button
          outfit="greenButton"
          loading={isLoadingPostSignUp || isLoadingPostLogIn}
          type="button"
          disabled={isFormDirty && !isFormValid}
          id="sign-up"
          onClick={handleUserSignIn}>
          SIGN UP
        </Button>
        <button className={styles['text-button']} type="button" id="already-created" onClick={() => setManuallySetLogIn(true)}>
          Or log in if you have already created your user
        </button>
      </div>
      <p className={styles['terms']}>
        By signing up, I consent to the processing of my personal data in accordance with the{' '}
        <a className={styles['link']} href="https://www.chatterbox.io/privacy-policy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        .
      </p>{' '}
      <ErrorToast errorMsg={userAuthorizationErrorMessage} show={hasUserAuthorizationError} />
    </form>
  );
};

EmailSignUp.propTypes = {
  errorMessage: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  hasLoginError: PropTypes.bool,
  hasPostSignUpError: PropTypes.bool.isRequired,
  hasUserAuthorizationError: PropTypes.bool.isRequired,
  initialInformation: PropTypes.object,
  isLoadingPostLogIn: PropTypes.bool.isRequired,
  isLoadingPostSignUp: PropTypes.bool.isRequired,
  loginErrorMsg: PropTypes.string,
  moveToNextStep: PropTypes.func.isRequired,
  postSignUpAction: PropTypes.func.isRequired,
  setManuallySetLogIn: PropTypes.func.isRequired,
  setOpenBookingIntroClassModalAction: PropTypes.func.isRequired,
  userAuthorizationErrorMessage: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  errorMessage: state.newLogin.signIn.afterUserInteraction.errorMessage,
  initialInformation: state.newOnboarding.initialInformation,
  isLoadingPostLogIn: state.newLogin.logIn.isLoadingPostLogIn,
  isLoadingPostSignUp: state.newLogin.signIn.afterUserInteraction.isLoadingPostSignUp,
  hasUserAuthorizationError: state.newLogin.signIn.beforeUserInteraction.hasUserAuthorizationError,
  hasPostSignUpError: state.newLogin.signIn.afterUserInteraction.hasPostSignUpError,
  hasLoginError: state.newLogin.logIn.hasPostLogInError,
  loginErrorMsg: state.newLogin.logIn.postLogInErrorMessage,
  userAuthorizationErrorMessage: state.newLogin.signIn.beforeUserInteraction.userAuthorizationErrorMessage
});

export default connect(mapStateToProps, {
  postSignUpAction: postSignUp,
  setOpenBookingIntroClassModalAction: setOpenBookingIntroClassModal
})(EmailSignUp);
