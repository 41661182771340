import React from 'react';
import PropTypes from 'prop-types';
import { useQueryParams } from '../../../hooks/useQueryParams';
import SsoLogin from './components/SSO/SSO';

import generalStyles from '../GeneralStyles.module.scss';
import styles from './Container.module.scss';

import EmailLoginForm from './components/EmailLogin';
import { convertBooleanStringToBoolean } from '../../../utils/string-helpers';

const LogIn = ({ moveToNextStep, setManuallySetLogIn }) => {
  const params = useQueryParams();
  const preDefinedMethod = convertBooleanStringToBoolean(params['is_sso']) ? 'sso' : 'email';

  const availableMethods = {
    email: <EmailLoginForm moveToNextStep={moveToNextStep} setManuallySetLogIn={setManuallySetLogIn} />,
    sso: <SsoLogin moveToNextStep={moveToNextStep} />
  };

  return (
    <div className={generalStyles['content-wrapper']}>
      <h1 className={generalStyles['title']} data-testid="login-title">
        Log in to your account
      </h1>
      <p className={generalStyles['subtitle']}>You&apos;ll also gain access to our AI powered self study content</p>
      <div className={styles['container-wrapper']}>{availableMethods[preDefinedMethod]}</div>
    </div>
  );
};

LogIn.propTypes = {
  moveToNextStep: PropTypes.func,
  setManuallySetLogIn: PropTypes.func.isRequired
};

export default LogIn;
