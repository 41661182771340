import axios from 'axios';
import { UPDATE_TUTOR_INFO } from './types';

export function updateTutorInfo(response) {
  return {
    type: UPDATE_TUTOR_INFO,
    payload: response
  };
}

export const getTutorInfo = (token) => async (dispatch) => {
  const headers = { headers: { Authorization: `Token ${token}` } };
  await axios.get('/api/tutor-info/', {}, headers).then((response) => {
    dispatch(updateTutorInfo(response));
  });
};
