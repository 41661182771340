import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../../../design-system/tooltip/Tooltip';
import CardWithHeader from '../../../../design-system/card/card-with-header/CardWithHeader';

import styles from './LivePracticeCard.module.scss';
import {
  convertStringsOfDateAndTimeToDate,
  convertToLocaleAndFormat,
  getDiffHoursBetweenNowAndDate,
  getDiffMinutesBetweenNowAndDate
} from '../../../../../utils/time-helpers';
import { useHistory } from 'react-router-dom';
import { isToday, isTomorrow } from 'date-fns';
import { connect } from 'react-redux';
import { setSelectedLanguage } from '../../../../../actions/selected-language';

const LivePracticeCard = ({
  appt,
  enrolledCourses,
  isGettingCourseDetails,
  isGettingEnrolledCourses,
  isSettingNextLessonToStudy,
  isTimeForExam,
  timezone,
  title,
  user
}) => {
  const [diff, setDiff] = useState(0);
  const [isApptTomorrow, setIsApptTomorrow] = useState(false);
  const [isApptToday, setIsApptToday] = useState(false);
  const [isTimeToClass, setIsTimeToClass] = useState(false);

  const getDifferenceInDays = (appt) => {
    if (!appt?.date || !appt?.start_time) return;

    const date = convertStringsOfDateAndTimeToDate(appt.date, appt.start_time);
    const diffHours = getDiffHoursBetweenNowAndDate(date);
    const diffMinutes = getDiffMinutesBetweenNowAndDate(date);

    if (diffHours > 24) {
      setDiff(Math.floor(diffHours / 24));
      if (isTomorrow(date)) setIsApptTomorrow(true);
    } else {
      if (isTomorrow(date)) setIsApptTomorrow(true);
      if (isToday(date)) setIsApptToday(true);
      if (Math.abs(diffMinutes) < 15) setIsTimeToClass(true);
    }
  };

  useEffect(() => {
    if (!appt || !Object.keys(appt).length) return;
    getDifferenceInDays(appt);
  }, [appt]);

  const history = useHistory();

  const handlePushToLivePractice = () => history.push('../bookings');

  if (!enrolledCourses.length) return null;

  if (isTimeForExam)
    return (
      <CardWithHeader
        title={title}
        isLoading={isGettingEnrolledCourses || isGettingCourseDetails || isSettingNextLessonToStudy}
        type="black"
        testId="regular-class-card"
        cardContent={
          <div className={styles['live-practice-card-only-text']}>
            <h3>Completed!</h3>
          </div>
        }
      />
    );

  if ((!appt || !Object.keys(appt).length) && !user?.is_banco_unico_special_rules)
    return (
      <CardWithHeader
        title={title}
        isLoading={isGettingEnrolledCourses || isGettingCourseDetails || isSettingNextLessonToStudy}
        type="black"
        onClick={handlePushToLivePractice}
        testId="regular-class-card"
        cardContent={
          <div className={styles['live-practice-card-only-text']} data-testid="book-next-session">
            <h2>Book</h2>
            <p>Your next Live Practice</p>
          </div>
        }
      />
    );

  if ((!appt || !Object.keys(appt).length) && user?.is_banco_unico_special_rules)
    return (
      <CardWithHeader
        title={title}
        isLoading={isGettingEnrolledCourses || isGettingCourseDetails || isSettingNextLessonToStudy}
        type="black"
        onClick={handlePushToLivePractice}
        testId="regular-class-card"
        cardContent={
          <div className={styles['live-practice-card-only-text']} data-testid="no-booking">
            <h3>No booking</h3>
          </div>
        }
      />
    );

  if (appt.status === 'happened')
    return (
      <CardWithHeader
        title={title}
        isLoading={isGettingEnrolledCourses || isGettingCourseDetails || isSettingNextLessonToStudy}
        type="black"
        onClick={handlePushToLivePractice}
        testId="regular-class-card"
        cardContent={
          <div className={styles['live-practice-card-only-text']} data-testid="book-next-session">
            <h2>Book</h2>
            <p>Your next Live Practice</p>
          </div>
        }
      />
    );

  if (isTimeToClass)
    return (
      <CardWithHeader
        title={title}
        isLoading={isGettingEnrolledCourses || isGettingCourseDetails || isSettingNextLessonToStudy}
        type="black"
        onClick={handlePushToLivePractice}
        testId="regular-class-card"
        cardContent={
          <div className={styles['live-practice-card-only-text']} data-testid="join-session">
            <h2>Join</h2>
            <p>{appt?.tutor?.first_name} now</p>
          </div>
        }
      />
    );

  if (isApptToday)
    return (
      <CardWithHeader
        title={title}
        isLoading={isGettingEnrolledCourses || isGettingCourseDetails || isSettingNextLessonToStudy}
        type="black"
        onClick={handlePushToLivePractice}
        testId="regular-class-card"
        cardContent={
          <div className={styles['live-practice-card']} data-testid="is-today">
            <div className={styles['coach-picture']}>
              <img src={appt?.tutor.profile_picture} alt="tutor" />
            </div>
            <div className={styles['date-wrapper']}>
              <h2>is today</h2>
              <p>
                {convertToLocaleAndFormat(convertStringsOfDateAndTimeToDate(appt.date, appt.start_time), timezone)}-
                {convertToLocaleAndFormat(convertStringsOfDateAndTimeToDate(appt.date, appt.end_time), timezone, 'hh:mm aa')}
              </p>
            </div>
            <div className={styles.tooltip}>
              <Tooltip tooltipLabel="You will be able to enter the Live Practice up to 15 minutes before it starts." backgroundColor="white" />
            </div>
          </div>
        }
      />
    );

  if (isApptTomorrow)
    return (
      <CardWithHeader
        title={title}
        isLoading={isGettingEnrolledCourses || isGettingCourseDetails || isSettingNextLessonToStudy}
        type="black"
        onClick={handlePushToLivePractice}
        testId="regular-class-card"
        cardContent={
          <div className={styles['live-practice-card']} data-testid="is-tomorrow">
            <div className={styles['coach-picture']}>
              <img src={appt?.tutor.profile_picture} alt="tutor" />
            </div>
            <div className={styles['date-wrapper']}>
              <h2>is tomorrow</h2>
              <p>
                {convertToLocaleAndFormat(convertStringsOfDateAndTimeToDate(appt.date, appt.start_time), timezone)}-
                {convertToLocaleAndFormat(convertStringsOfDateAndTimeToDate(appt.date, appt.end_time), timezone, 'hh:mm aa')}
              </p>
            </div>
            <div className={styles.tooltip}>
              <Tooltip tooltipLabel="You will be able to enter the Live Practice up to 15 minutes before it starts." backgroundColor="white" />
            </div>
          </div>
        }
      />
    );
  return (
    <CardWithHeader
      title={title}
      isLoading={isGettingEnrolledCourses || isGettingCourseDetails || isSettingNextLessonToStudy}
      type="black"
      onClick={handlePushToLivePractice}
      testId="regular-class-card"
      cardContent={
        <div className={styles['live-practice-card']} data-testid={`in-${diff}-days`}>
          <div className={styles['coach-picture']}>
            <img src={appt?.tutor?.profile_picture} alt="tutor" />
          </div>
          <div className={styles['date-wrapper']}>
            <div className={styles['count-wrapper']}>
              <h2>in </h2>
              <span id="dont-translate">{diff}</span>
              <h2>{diff > 1 ? ' days' : ' day'}</h2>{' '}
            </div>
            <p>
              {convertToLocaleAndFormat(convertStringsOfDateAndTimeToDate(appt.date, appt.start_time), timezone)}-
              {convertToLocaleAndFormat(convertStringsOfDateAndTimeToDate(appt.date, appt.end_time), timezone, 'hh:mm aa')}
            </p>
          </div>
          <div className={styles.tooltip}>
            <Tooltip tooltipLabel="You will be able to enter the Live Practice up to 15 minutes before it starts." backgroundColor="white" />
          </div>
        </div>
      }
    />
  );
};

LivePracticeCard.propTypes = {
  appt: PropTypes.object,
  enrolledCourses: PropTypes.array.isRequired,
  isGettingCourseDetails: PropTypes.bool,
  isGettingEnrolledCourses: PropTypes.bool,
  isSettingNextLessonToStudy: PropTypes.bool,
  isTimeForExam: PropTypes.bool,
  timezone: PropTypes.string,
  title: PropTypes.string,
  tutorProfilePicture: PropTypes.string,
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  enrolledCourses: state.courses.enrolledCourses.enrolledCourses,
  isGettingCourseDetails: state.courses.selectedCourseDetails.isGettingCourseDetails,
  isGettingEnrolledCourses: state.courses.enrolledCourses.isGettingEnrolledCourses,
  isSettingNextLessonToStudy: state.lessons.nextLessonToStudy.isSettingNextLessonToStudy,
  timezone: state.time.timezone,
  user: state.user
});

export default connect(mapStateToProps, {
  setSelectedLanguageAction: setSelectedLanguage
})(LivePracticeCard);
