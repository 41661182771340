import {
  add,
  addMinutes,
  eachDayOfInterval,
  endOfMonth,
  getDay,
  getYear,
  isAfter,
  isSameWeek,
  isWithinInterval,
  startOfMonth,
  sub,
  subMinutes
} from 'date-fns';
import { combineReducers } from 'redux';
import { BookableActionTypes } from '../actions/bookable';
import { USER_LOGOUT, CLEAN_PREVIOUS_STATE } from '../actions/types';
import { getDateAvailableSlotsFromTimestamp, getMonthAvailableDays, getRegularMonth, isTimestampAvailable } from '../utils/date-helpers';
import { NewBookingActionTypes } from '../actions/new-booking';
import { calculateBookings } from '../components/new-booking/components/booking/domain/calculateBookings';
import { getDaysOfWeekTranslated } from '../components/design-system/calendar/TimeRelated.enum';
import { convertDaysToMinutes } from '../utils/time-helpers';
import _ from 'lodash';

const ERROR_STATUS = Object.freeze({
  not_enough_credits: 'It seems that you do not have enough credits to book all your remaining lessons. Please contact us at courses@chatterbox.io',
  not_enough_time_left: 'It seems that you do not have enough time left to book your remaining lessons. Please contact us at courses@chatterbox.io'
});

const hasError = (futureSchedule) => {
  if (!futureSchedule?.reason) return false;
  if (ERROR_STATUS[futureSchedule.reason]) return ERROR_STATUS[futureSchedule.reason];
  return false;
};

const getDaysOfTheSelectedMonth = (date) => {
  if (!date) return [];
  return eachDayOfInterval({
    start: startOfMonth(new Date(date)),
    end: endOfMonth(new Date(date))
  });
};
const placeDaysIntoWeekDaysColumns = (daysOfTheSelectedMonth, date) => {
  const currentLang = window?.Weglot?.getCurrentLang() || 'en';
  const matrix = Object.keys(getDaysOfWeekTranslated(currentLang)).map(() => []);
  daysOfTheSelectedMonth.forEach((day) => {
    const dayOfTheWeek = getDay(day);
    matrix[dayOfTheWeek].push(day.toISOString());
  });

  return matrix.map((arrayOfDays) => (isSameWeek(new Date(arrayOfDays[0]), startOfMonth(new Date(date))) ? arrayOfDays : [null, ...arrayOfDays]));
};

const buildCalendarMatrix = (date) => {
  const daysOfTheSelectedMonth = getDaysOfTheSelectedMonth(date);
  const matrix = placeDaysIntoWeekDaysColumns(daysOfTheSelectedMonth, date);
  return matrix;
};

const needsExpirationWarning = (futureSchedule) => {
  return futureSchedule.reason === 'not_enough_time_left';
};

const filter = (weekFilteredMap, monthFilteredMap, datetimesMap) => {
  const availableMap = new Map();
  const weekMap = new Map(Object.entries(weekFilteredMap || {}));
  const monthMap = new Map(Object.entries(monthFilteredMap || {}));
  const sourceMap = new Map(Object.entries(datetimesMap));

  if (!weekMap.size && !monthMap.size) return Object.fromEntries(sourceMap);

  sourceMap.forEach((tsArray, key) => {
    const availableSet = new Set(availableMap.get(key) || []);
    const weekBlockSet = new Set(weekMap.get(key) || []);
    const monthBlockSet = new Set(monthMap.get(key) || []);
    tsArray.forEach((ts) => !weekBlockSet.has(ts) && !monthBlockSet.has(ts) && availableSet.add(ts));
    availableMap.set(key, Array.from(availableSet));
  });

  return Object.fromEntries(availableMap);
};

const getSortedValuesFromPseudoMap = (pseudoMap) => {
  const values = [];
  const map = new Map(Object.entries(pseudoMap));
  map.forEach((value) => values.push(value));
  return values.flat(1).sort();
};

const isWithin = (ts, tsRef, intervalInMinutes) => {
  return isWithinInterval(new Date(ts), {
    start: sub(new Date(tsRef), { minutes: intervalInMinutes }),
    end: add(new Date(tsRef), { minutes: intervalInMinutes })
  });
};

const impactedMonthsKeys = (ts, intervalInMinutes) => {
  const floorTs = subMinutes(new Date(ts), intervalInMinutes);
  const ceilingTs = addMinutes(new Date(ts), intervalInMinutes);
  const set = new Set();
  set.add(buildMonthAndYearStr(floorTs));
  set.add(buildMonthAndYearStr(ceilingTs));
  return Array.from(set);
};

const filterIntervalAfterTimestampSelection = (timestamp, weekFilteredMap, datetimesMap, intervalInMinutes) => {
  if (!timestamp)
    return {
      availableDatetimesMap: datetimesMap,
      weekFilteredTimestampsMap: weekFilteredMap
    };

  const sourceMap = new Map(Object.entries(datetimesMap));
  const alreadyWeekMap = new Map(Object.entries(weekFilteredMap));

  const keys = impactedMonthsKeys(timestamp, intervalInMinutes);
  keys.forEach((key) => {
    const monthAllTimestamps = sourceMap.get(key) || [];
    const weekAlreadyFilteredTimestampsSet = new Set(alreadyWeekMap.get(key) || []);

    monthAllTimestamps.forEach((ts) => {
      if (intervalInMinutes && isWithin(ts, timestamp, intervalInMinutes)) return weekAlreadyFilteredTimestampsSet.add(ts);
    });

    alreadyWeekMap.set(key, Array.from(weekAlreadyFilteredTimestampsSet));
  });

  return {
    weekFilteredTimestampsMap: Object.fromEntries(alreadyWeekMap)
  };
};

const removeFromFilterInterval = (timestamp, weekFilteredTimestampsMap, intervalInMinutes) => {
  const keys = impactedMonthsKeys(timestamp, intervalInMinutes);

  const alreadyWeekMap = new Map(Object.entries(weekFilteredTimestampsMap));

  keys.forEach((key) => {
    const weekAlreadyFilteredTimestamps = alreadyWeekMap.get(key) || [];
    const weekSet = new Set();

    weekAlreadyFilteredTimestamps.forEach((ts) => {
      if (!isWithin(ts, timestamp, intervalInMinutes)) return weekSet.add(ts);
    });

    alreadyWeekMap.set(key, Array.from(weekSet));
  });

  return { weekFilteredTimestampsMap: Object.fromEntries(alreadyWeekMap) };
};

const filterMonth = (timestamp, monthFilteredMap, datetimesMap) => {
  if (!timestamp)
    return {
      monthFilteredTimestampsMap: {}
    };

  const key = buildMonthAndYearStr(timestamp);
  const map = new Map(Object.entries(datetimesMap));
  const filtered = map.get(key) || [];
  map.delete(key);

  const monthFilteredTimestampsMap = new Map(Object.entries(monthFilteredMap) || {});
  const prevSet = new Set(monthFilteredTimestampsMap.get(key) || []);
  filtered.forEach((f) => prevSet.add(f));

  monthFilteredTimestampsMap.set(key, Array.from(prevSet));

  return {
    monthFilteredTimestampsMap: Object.fromEntries(monthFilteredTimestampsMap)
  };
};

const removeFromFilterMonth = (timestamp, monthFilteredTimestampMap) => {
  const key = buildMonthAndYearStr(timestamp);
  const alreadyMonthMap = new Map(Object.entries(monthFilteredTimestampMap || {}));
  alreadyMonthMap.delete(key);

  return { monthFilteredTimestampsMap: Object.fromEntries(alreadyMonthMap) };
};

export const buildMonthAndYearStr = (timestamp) => {
  if (!timestamp) return '';
  const month = String(getRegularMonth(new Date(timestamp))).padStart(2, 0);
  const year = getYear(new Date(timestamp));
  return `${year}-${month}`;
};

export const buildTimestampFromKey = (key) => {
  const [year, month] = key.split('-');
  const ts = new Date(`${year}-${month}-10`).toISOString();
  return ts;
};

const organizeAvailableSessionsPerMonth = (timestamp, payload, prevState) => {
  const key = buildMonthAndYearStr(timestamp);
  const map = new Map(Object.entries(prevState));
  map.set(key, payload);
  return Object.fromEntries(map);
};

export const buildInitialAvailableSessionsPerMonth = (sessionsPerMonth) => {
  if (!sessionsPerMonth) return {};
  const sessionsMap = new Map(Object.entries(sessionsPerMonth));
  const availableMap = new Map();
  sessionsMap.forEach((session, key) => {
    const value = calculateBookings({ selectedTimestamp: session.timestamp, selectedTimestamps: {}, sessionsPerMonth });
    availableMap.set(key, value);
  });
  return Object.fromEntries(availableMap);
};

const getSessionsAvailableForMonth = (timestamp, availableSessionsPerMonth) => {
  const key = buildMonthAndYearStr(timestamp);
  const map = new Map(Object.entries(availableSessionsPerMonth));
  return map.get(key) || {};
};

const findFirstAvailableTimestamp = (allAvailableMap) => {
  const map = new Map(Object.entries(allAvailableMap));
  let reallyAvailable = new Map();
  map.forEach((value, key) => {
    if (value.length) reallyAvailable.set(key, value);
  });

  const keys = Array.from(reallyAvailable.keys());
  const sorted = keys.sort();
  return map.get(sorted[0]) && map.get(sorted[0])[0];
};

const findNextAvailableTimestamp = (timestamp, allAvailableMap) => {
  const map = new Map(Object.entries(allAvailableMap));
  let reallyAvailableTs = [];
  map.forEach((value) => {
    if (value.length) reallyAvailableTs.push(...value);
  });
  let index = 0;
  while (index < reallyAvailableTs.length) {
    if (isAfter(new Date(reallyAvailableTs[index]), new Date(timestamp))) return reallyAvailableTs[index];
    index++;
  }
  return;
};

const mergeTwoObjects = (curr, prev) => {
  return { ...prev, ...curr };
};

const availableInitialState = {
  datetimes: [],
  datetimesMap: {},
  availableDatetimesMap: {},
  isAvailableDatetimesEmpty: false,
  coachHasAvailability: true,
  weekFilteredTimestampsMap: {},
  monthFilteredTimestampsMap: {},
  firstAvailableTimestamp: '',
  lastAvailableTimestamp: '',
  firstTimeGettigAvailability: false,
  updateDueMonthChange: false,
  isLoadingAvailability: false,
  sessionsPerMonth: {},
  availableSessionsPerMonth: {},
  error: false,
  errorGettingAvailableSessions: false,
  isLoadingBookable: false,
  availableSessionsThisMonth: {},
  daysAvailableThisMonth: [],
  timesAvailableThisDay: [],
  bookingFreezeDays: 3,
  calendar: {
    date: new Date().toISOString(),
    month: getRegularMonth(new Date()),
    year: getYear(new Date()),
    matrix: buildCalendarMatrix(new Date()),
    selectedTimestamp: ''
  }
};

function availableReducer(state = availableInitialState, action) {
  switch (action.type) {
    case NewBookingActionTypes.OPEN_BOOKING_MODAL:
    case NewBookingActionTypes.OPEN_RESCHEDULING_MODAL:
    case NewBookingActionTypes.OPEN_BOOKING_INTRO_CLASS_MODAL:
    case NewBookingActionTypes.OPEN_BOOKING_CONVERSATION_MODAL:
    case NewBookingActionTypes.OPEN_RESCHEDULING_CONVERSATION_MODAL:
    case BookableActionTypes.GET_BOOKABLE:
      return {
        ...state,
        isLoadingAvailability: true,
        error: false,
        updateDueMonthChange: false,
        isLoadingBookable: true
      };
    case BookableActionTypes.GET_BOOKABLE_SUCCESS: {
      const { weekFilteredTimestampsMap } = filterIntervalAfterTimestampSelection(
        null,
        state.weekFilteredTimestampsMap,
        action?.payload?.datetimesMap,
        convertDaysToMinutes(state.bookingFreezeDays)
      );
      const availableDatetimesMap = filter(weekFilteredTimestampsMap, state.monthFilteredTimestampsMap, action?.payload?.datetimesMap);

      return {
        ...state,
        ...action.payload,
        datetimes: action.payload?.datetimes,
        datetimesMap: action?.payload?.datetimesMap,
        availableDatetimesMap,
        weekFilteredTimestampsMap,
        lastAvailableTimestamp: action?.payload?.to,
        error: false,
        isAvailableDatetimesEmpty: _.isEmpty(availableDatetimesMap),
        coachHasAvailability: action?.payload?.has_availability,
        bookingFreezeDays: action?.payload?.booking_freeze || 3,
        firstAvailableTimestamp: getSortedValuesFromPseudoMap(action?.payload?.unfilteredDatetimesMap)[0],
        updateDueMonthChange: false,
        isLoadingAvailability: false,
        firstTimeGettigAvailability: true,
        isLoadingBookable: false,
        calendar: {
          ...state.calendar,
          date: action?.payload?.datetimes[0],
          month: getRegularMonth(new Date(action?.payload?.datetimes[0])),
          year: getYear(new Date(action?.payload?.datetimes[0])),
          matrix: buildCalendarMatrix(action?.payload?.datetimes[0])
        },
        daysAvailableThisMonth: getMonthAvailableDays(action?.payload?.datetimes[0], availableDatetimesMap)
      };
    }
    case BookableActionTypes.GET_BOOKABLE_FAIL:
      return {
        ...state,
        error: true,
        isLoadingAvailability: false,
        isLoadingBookable: false
      };
    case BookableActionTypes.UPDATE_BOOKABLE:
      return {
        ...state,
        isLoadingAvailability: true,
        error: false,
        updateDueMonthChange: false,
        isLoadingBookable: true
      };
    case BookableActionTypes.UPDATE_BOOKABLE_SUCCESS: {
      const allDatetimes = [...state.datetimes, action.payload?.datetimes];
      const allDatesMap = mergeTwoObjects(action?.payload?.datetimesMap, state.datetimesMap);
      const { weekFilteredTimestampsMap } = filterIntervalAfterTimestampSelection(
        null,
        state.weekFilteredTimestampsMap,
        allDatesMap,
        convertDaysToMinutes(state.bookingFreezeDays)
      );
      const availableDatetimesMap = filter(weekFilteredTimestampsMap, state.monthFilteredTimestampsMap, allDatesMap);

      return {
        ...state,
        error: false,
        datetimes: allDatetimes,
        datetimesMap: allDatesMap,
        availableDatetimesMap,
        weekFilteredTimestampsMap,
        lastAvailableTimestamp: action?.payload?.to,
        updateDueMonthChange: true,
        isLoadingBookable: false
      };
    }
    case BookableActionTypes.UPDATE_BOOKABLE_FAIL:
      return {
        ...state,
        error: true,
        isLoadingAvailability: false,
        isLoadingBookable: false
      };
    case BookableActionTypes.SET_CURRENT_DATE:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          date: action.payload,
          month: getRegularMonth(new Date(action.payload)),
          year: getYear(new Date(action.payload)),
          matrix: buildCalendarMatrix(action.payload)
        },
        availableSessionsThisMonth: getSessionsAvailableForMonth(action.payload, state.availableSessionsPerMonth),
        daysAvailableThisMonth: getMonthAvailableDays(action.payload, state.availableDatetimesMap)
      };
    case BookableActionTypes.SET_SELECTED_DATE: {
      const timesAvailable = getDateAvailableSlotsFromTimestamp(action.payload, state.availableDatetimesMap);
      return {
        ...state,
        calendar: {
          date: timesAvailable[0],
          month: getRegularMonth(new Date(timesAvailable[0])),
          year: getYear(new Date(timesAvailable[0])),
          matrix: buildCalendarMatrix(timesAvailable[0]),
          selectedTimestamp: timesAvailable[0]
        },
        timesAvailableThisDay: timesAvailable
      };
    }
    case BookableActionTypes.SET_SELECTED_TIMESTAMP: {
      const timesAvailable = getDateAvailableSlotsFromTimestamp(action.payload, state.availableDatetimesMap);
      const isAvailable = isTimestampAvailable(action.payload, timesAvailable);
      return {
        ...state,
        calendar: {
          date: action.payload,
          month: getRegularMonth(new Date(action.payload)),
          year: getYear(new Date(action.payload)),
          matrix: buildCalendarMatrix(action.payload),
          selectedTimestamp: isAvailable ? action.payload : ''
        },
        timesAvailableThisDay: timesAvailable
      };
    }
    case BookableActionTypes.GET_AVAILABLE_SESSIONS_FOR_THIS_PERIOD:
    case BookableActionTypes.UPDATE_AVAILABLE_SESSIONS_FOR_THIS_PERIOD:
      return {
        ...state,
        isLoadingAvailability: true,
        error: false,
        errorGettingAvailableSessions: false,
        updateDueMonthChange: false,
        isLoadingBookable: true
      };
    case BookableActionTypes.UPDATE_AVAILABLE_SESSIONS_FOR_THIS_PERIOD_SUCCESS: {
      const sessionsPerMonth = mergeTwoObjects(action.payload.sessionsPerMonth, state.sessionsPerMonth);
      const availableSessionsPerMonth = mergeTwoObjects(action.payload.availableSessionsPerMonth, state.availableSessionsPerMonth);

      return {
        ...state,
        error: false,
        errorGettingAvailableSessions: false,
        isLoadingAvailability: false,
        isLoadingBookable: false,
        sessionsPerMonth,
        availableSessionsPerMonth,
        availableSessionsThisMonth: getSessionsAvailableForMonth(state.calendar.date, availableSessionsPerMonth)
      };
    }
    case BookableActionTypes.GET_AVAILABLE_SESSIONS_FOR_THIS_PERIOD_SUCCESS: {
      return {
        ...state,
        error: false,
        errorGettingAvailableSessions: false,
        isLoadingAvailability: false,
        isLoadingBookable: false,
        sessionsPerMonth: action.payload.sessionsPerMonth,
        availableSessionsPerMonth: action.payload.availableSessionsPerMonth,
        availableSessionsThisMonth: getSessionsAvailableForMonth(state.calendar.date, action.payload.availableSessionsPerMonth)
      };
    }
    case BookableActionTypes.STORE_MONTH_SESSIONS_AVAILABILITY: {
      const availableSessionsPerMonth = organizeAvailableSessionsPerMonth(
        action.payload?.timestamp,
        action.payload?.response,
        state.availableSessionsPerMonth
      );
      return {
        ...state,
        availableSessionsPerMonth,
        availableSessionsThisMonth: getSessionsAvailableForMonth(action.payload?.timestamp, availableSessionsPerMonth)
      };
    }
    case BookableActionTypes.GET_AVAILABLE_SESSIONS_FOR_THIS_PERIOD_FAIL:
      return {
        ...state,
        error: true,
        errorGettingAvailableSessions: true,
        availableDatetimesMap: {},
        isLoadingAvailability: false,
        isLoadingBookable: false
      };
    case BookableActionTypes.UPDATE_AVAILABLE_SESSIONS_FOR_THIS_PERIOD_FAIL:
      return {
        ...state,
        error: true,
        errorGettingAvailableSessions: true,
        isLoadingAvailability: false,
        isLoadingBookable: false
      };
    case BookableActionTypes.PRE_BOOK_DATE: {
      const { weekFilteredTimestampsMap } = filterIntervalAfterTimestampSelection(
        action.payload,
        state.weekFilteredTimestampsMap,
        state.datetimesMap,
        convertDaysToMinutes(state.bookingFreezeDays)
      );
      const availableDatetimesMap = filter(weekFilteredTimestampsMap, state.monthFilteredTimestampsMap, state.datetimesMap);
      const nextTimestamp = findNextAvailableTimestamp(action.payload, availableDatetimesMap) || action.payload;
      return {
        ...state,
        availableDatetimesMap,
        weekFilteredTimestampsMap,
        updateDueMonthChange: false,
        calendar: {
          ...state.calendar,
          selectedTimestamp: '',
          date: nextTimestamp,
          month: getRegularMonth(new Date(nextTimestamp)),
          year: getYear(new Date(nextTimestamp)),
          matrix: buildCalendarMatrix(nextTimestamp)
        },
        daysAvailableThisMonth: getMonthAvailableDays(nextTimestamp, availableDatetimesMap)
      };
    }
    case BookableActionTypes.REMOVE_FROM_PRE_BOOK: {
      const { weekFilteredTimestampsMap } = removeFromFilterInterval(
        action.payload,
        state.weekFilteredTimestampsMap,
        convertDaysToMinutes(state.bookingFreezeDays)
      );
      const availableDatetimesMap = filter(weekFilteredTimestampsMap, state.monthFilteredTimestampsMap, state.datetimesMap);

      return {
        ...state,
        error: false,
        availableDatetimesMap,
        weekFilteredTimestampsMap,
        updateDueMonthChange: false,
        calendar: {
          selectedTimestamp: '',
          date: action.payload,
          month: getRegularMonth(new Date(action.payload)),
          year: getYear(new Date(action.payload)),
          matrix: buildCalendarMatrix(action.payload)
        },
        daysAvailableThisMonth: getMonthAvailableDays(action.payload, availableDatetimesMap),
        timesAvailableThisDay: getDateAvailableSlotsFromTimestamp(action.payload, availableDatetimesMap)
      };
    }
    case BookableActionTypes.PRE_BOOK_WHOLE_MONTH_AHEAD: {
      const { monthFilteredTimestampsMap } = filterMonth(action.payload, state.monthFilteredTimestampsMap, state.datetimesMap);
      const availableDatetimesMap = filter(state.weekFilteredTimestampsMap, monthFilteredTimestampsMap, state.datetimesMap);
      const nextTimestamp = findFirstAvailableTimestamp(availableDatetimesMap);

      return {
        ...state,
        availableDatetimesMap,
        monthFilteredTimestampsMap,
        updateDueMonthChange: false,
        date: nextTimestamp,
        calendar: {
          ...state.calendar,
          date: nextTimestamp,
          month: getRegularMonth(new Date(nextTimestamp)),
          year: getYear(new Date(nextTimestamp)),
          matrix: buildCalendarMatrix(nextTimestamp)
        },
        daysAvailableThisMonth: getMonthAvailableDays(nextTimestamp, availableDatetimesMap),
        availableSessionsThisMonth: getSessionsAvailableForMonth(nextTimestamp, state.availableSessionsPerMonth)
      };
    }
    case BookableActionTypes.PRE_BOOK_WHOLE_MONTH: {
      const { monthFilteredTimestampsMap } = filterMonth(action.payload, state.monthFilteredTimestampsMap, state.datetimesMap);
      const availableDatetimesMap = filter(state.weekFilteredTimestampsMap, monthFilteredTimestampsMap, state.datetimesMap);
      const nextTimestamp = findFirstAvailableTimestamp(availableDatetimesMap);

      return {
        ...state,
        availableDatetimesMap,
        monthFilteredTimestampsMap,
        updateDueMonthChange: false,
        calendar: {
          ...state.calendar,
          date: nextTimestamp,
          month: getRegularMonth(new Date(nextTimestamp)),
          year: getYear(new Date(nextTimestamp)),
          matrix: buildCalendarMatrix(nextTimestamp)
        },
        daysAvailableThisMonth: getMonthAvailableDays(nextTimestamp, availableDatetimesMap),
        availableSessionsThisMonth: getSessionsAvailableForMonth(nextTimestamp, state.availableSessionsPerMonth)
      };
    }
    case BookableActionTypes.REMOVE_MONTH_FROM_PRE_BOOK: {
      const { monthFilteredTimestampsMap } = removeFromFilterMonth(action.payload, state.monthFilteredTimestampsMap);
      const availableDatetimesMap = filter(state.weekFilteredTimestampsMap, monthFilteredTimestampsMap, state.datetimesMap);

      return {
        ...state,
        error: false,
        availableDatetimesMap,
        monthFilteredTimestampsMap,
        updateDueMonthChange: false
      };
    }
    case BookableActionTypes.CLEAN_SELECTED_TIMESTAMP:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          selectedTimestamp: ''
        },
        timesAvailableThisDay: []
      };
    case CLEAN_PREVIOUS_STATE:
    case NewBookingActionTypes.CLOSE_BOOKING_MODAL:
    case NewBookingActionTypes.CLOSE_RESCHEDULING_MODAL:
    case NewBookingActionTypes.CLOSE_RESCHEDULING_CONVERSATION_MODAL:
    case NewBookingActionTypes.RESCHEDULING_APPOINTMENT_SUCCESS:
    case NewBookingActionTypes.CREATING_NEW_APPOINTMENT_SUCCESS:
    case NewBookingActionTypes.CANCELING_APPOINTMENT_SUCCESS:
    case BookableActionTypes.RESET_BOOKABLE:
    case USER_LOGOUT:
      return availableInitialState;
    default:
      return state;
  }
}

const futureScheduleInitialState = {
  isLoadingFutureSchedule: false,
  futureSchedule: [],
  needsExpirationWarning: false,
  error: null
};

function futureScheduleReducer(state = futureScheduleInitialState, action) {
  switch (action.type) {
    case BookableActionTypes.GET_FUTURE_SCHEDULE:
      return { ...state, error: null, isLoadingFutureSchedule: true };
    case BookableActionTypes.GET_FUTURE_SCHEDULE_SUCCESS:
      return {
        ...state,
        error: hasError(action.payload),
        needsExpirationWarning: needsExpirationWarning(action.payload),
        futureSchedule: action.payload?.datetimes,
        isLoadingFutureSchedule: null
      };
    case BookableActionTypes.GET_FUTURE_SCHEDULE_FAIL:
      return {
        ...state,
        isLoadingFutureSchedule: false,
        futureSchedule: [],
        error: 'Something went wrong while attempting to load. Please reload this page.'
      };
    case USER_LOGOUT:
    case CLEAN_PREVIOUS_STATE:
      return futureScheduleInitialState;
    default:
      return state;
  }
}

export default combineReducers({
  available: availableReducer,
  futureSchedule: futureScheduleReducer
});
