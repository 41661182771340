import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';
import promise from 'redux-promise';
import reducers from './reducers';

const rootPersistConfig = {
  key: 'reduxPersist',
  storage: storage('persistDB'),
  blacklist: ['chat'],
  debug: false
};

const persistedReducer = persistReducer(rootPersistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }).concat(promise)
});

export const persistor = persistStore(store);
