import * as moment from 'moment-timezone';

const STORAGE_PREFIX = 'org.wearechatterbox.app:';

const CHAT_CODE_REMINDER_KEY = `${STORAGE_PREFIX}last-chat-code-reminder-timestamp`;
const LAST_LOGOUT_TIME_KEY = `${STORAGE_PREFIX}last-logout-time`;
const LAST_RATING_SKIP_APPOINTMENT_ID_KEY = `${STORAGE_PREFIX}last-rating-skip-appointment-id`;
const VERSION_KEY = `${STORAGE_PREFIX}version`;

const TIMESTAMP_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

const storageHelpers = {
  checkIfAppointmentRatingWasSkippedLast: (appointment) => {
    return String(appointment.id) === localStorage.getItem(LAST_RATING_SKIP_APPOINTMENT_ID_KEY);
  },
  checkIfChatCodeReminderWasShown: (dateMoment) => {
    const lastReminderTimestamp = localStorage.getItem(CHAT_CODE_REMINDER_KEY);
    const lastReminderMoment = moment.utc(lastReminderTimestamp);
    return dateMoment.isSame(lastReminderMoment, 'day');
  },
  getLastLogoutMoment: () => {
    const timestamp = localStorage.getItem(LAST_LOGOUT_TIME_KEY);
    return moment.utc(timestamp);
  },
  /** Remove all storage data if there were possible breaking changes to the app. */
  handleVersionChange: () => {
    try {
      const currentAppVersion = process.env.REACT_APP_VERSION;
      const lastLoadedAppVersion = localStorage.getItem(VERSION_KEY);

      if (currentAppVersion !== lastLoadedAppVersion) {
        localStorage.clear();
        localStorage.setItem(VERSION_KEY, currentAppVersion);
      }
    } catch (error) {
      // Ignore unexpected errors.
    }
  },
  markAsLastRatingSkipAppointment: (appointment) => {
    const apptId = String(appointment.id);
    localStorage.setItem(LAST_RATING_SKIP_APPOINTMENT_ID_KEY, apptId);
  },
  markChatCodeReminderAsShown: () => {
    const now = moment.utc();
    const timestamp = now.format(TIMESTAMP_FORMAT);
    localStorage.setItem(CHAT_CODE_REMINDER_KEY, timestamp);
  },
  markLogout: () => {
    const now = moment.utc();
    const timestamp = now.format(TIMESTAMP_FORMAT);
    localStorage.setItem(LAST_LOGOUT_TIME_KEY, timestamp);
  },
  storeDataInLocalStorage: (dataObj) => {
    Object.keys(dataObj).forEach((key) => localStorage.setItem(key, dataObj[key]));
  },
  getDataFromLocalStorage: (key) => {
    const rawData = localStorage.getItem(key);
    const data = rawData !== undefined ? JSON.parse(rawData) : null;
    return data;
  },
  storeDataInSessionStorage: (dataObj) => {
    Object.keys(dataObj).forEach((key) => sessionStorage.setItem(key, dataObj[key]));
  },
  getDataFromSessionStorage: (key) => {
    const rawData = sessionStorage.getItem(key);
    const data = rawData !== undefined ? JSON.parse(rawData) : null;
    return data;
  },
  clearLocalStorage: () => localStorage.clear()
};

export default storageHelpers;
