export const appointmentStatus = Object.freeze({
  booked: 'booked',
  happened: 'happened',
  canceled: 'canceled',
  canceledLate: 'canceled_late',
  technicalError: 'technical_error',
  studentNoShow: 'student_noshow',
  tutorNoShow: 'tutor_noshow',
  allNoShow: 'all_noshow'
});

export const pastAppointmentStatus = Object.freeze({
  happened: { value: 'happened', label: 'Happened' },
  studentAttended: { value: 'student_show', label: 'Learner attended' },
  studentNoShow: { value: 'student_noshow', label: 'Learner no-show' },
  technicalIssues: { value: 'technical_error', label: 'Technical issues' },
  canceledLate: { value: 'canceled_late', label: 'Canceled Late' }
});

export const tutorAttendanceStatus = Object.freeze({
  tutor_on_time: 'On time',
  tutor_late: 'Late',
  tutor_very_late: 'Very late',
  tutor_no_attendance: 'No attendance'
});

export const getPastAppointmentStatusLabelFromValue = (value) => {
  const findings = Object.values(pastAppointmentStatus).find((obj) => obj.value === value);
  return findings?.label || '';
};

export const isStatusValid = (value) => {
  const findings = Object.values(pastAppointmentStatus).find((obj) => obj.value === value);
  return Boolean(findings?.label);
};

export const parseHappenedToAttended = (status) => {
  return status === pastAppointmentStatus.happened.value ? pastAppointmentStatus.studentAttended.value : status;
};
