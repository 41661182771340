import React from 'react';
import PropTypes from 'prop-types';

import styles from './IconSelector.module.scss';
import LoadingSpinner from '../../../design-system/loading/spinner/LoadingSpinner';

const IconSelector = ({ alt, disabled, icon, id, isLoading, label, name, onClick }) => {
  const handleUserClick = () => {
    onClick(name);
  };

  return (
    <button className={styles['selector-wrapper']} onClick={handleUserClick} disabled={disabled} id={id}>
      {isLoading ? (
        <div className={styles['loading-container']}>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <img src={icon} alt={alt} />
          <p>{label}</p>
        </>
      )}
    </button>
  );
};

IconSelector.propTypes = {
  alt: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func
};

export default IconSelector;
