import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LanguageSwitcher from '.';
import formStyles from '../../style/utils/_form.module.scss';
import { MenuItem, Select } from '@mui/material';
import { styled } from '@mui/system';
import { UserTypes } from '../../domains/user/user-types';

const FormFieldLanguageSwitcher = ({ availableLang, currentLang, onChange, onLangSwitch, shouldUpdateUser, user, weglotRef }) => {
  const [selected, setSelected] = useState(currentLang);

  useEffect(() => {
    setSelected(currentLang);
  }, [currentLang]);

  const handleLangSwitch = (evt) => {
    if (shouldUpdateUser) {
      onLangSwitch(evt?.target?.value);
    } else {
      setSelected(evt?.target?.value);
      if (onChange) onChange(evt?.target?.value);
    }
  };

  const StyledSelect = styled(Select)({
    width: '100%',
    backgroundColor: 'white',
    height: '2.5rem',
    color: 'rgb(51, 51, 51)',
    fontFamily: 'Karla, sans-serif'
  });

  const StyledMenuItem = styled(MenuItem)({
    fontSize: '1rem',
    color: 'rgb(51, 51, 51)',
    fontFamily: 'Karla, sans-serif'
  });

  return (
    <div className={formStyles['field-wrapper']}>
      <label htmlFor="preferred-language" className={formStyles.label}>
        Your preferred language
      </label>
      <StyledSelect
        disabled={user?.user_type !== UserTypes.student}
        labelId="preferred-language"
        id="preferred-language"
        onChange={handleLangSwitch}
        value={selected}>
        {availableLang?.map((lang) => (
          <StyledMenuItem key={lang} id="dont-translate" value={lang}>
            {weglotRef?.current?.getLanguageName(lang) || lang}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </div>
  );
};

FormFieldLanguageSwitcher.propTypes = {
  availableLang: PropTypes.array.isRequired,
  currentLang: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onLangSwitch: PropTypes.func.isRequired,
  shouldUpdateUser: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  weglotRef: PropTypes.object
};

FormFieldLanguageSwitcher.defaultProps = {
  shouldUpdateUser: true
};

const FormFieldLanguageSwitcherPoweredUp = ({ onChange, shouldUpdateUser, user }) => (
  <LanguageSwitcher
    executor={<FormFieldLanguageSwitcher shouldUpdateUser={shouldUpdateUser} onChange={onChange} user={user} />}
    onChange={onChange}
  />
);

FormFieldLanguageSwitcherPoweredUp.propTypes = {
  onChange: PropTypes.func,
  shouldUpdateUser: PropTypes.bool,
  user: PropTypes.object.isRequired
};

export default FormFieldLanguageSwitcherPoweredUp;
