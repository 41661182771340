import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CardWithHeader from '../../../../design-system/card/card-with-header/CardWithHeader';

import { setOpenBookingIntroClassModal } from '../../../../../actions/new-booking';
import { setSelectedLanguage } from '../../../../../actions/selected-language';
import IntroClassCard from './IntroClassCard';
import LivePracticeCard from './LivePracticeCard';

const LivePracticeContainer = ({
  activeOrders,
  hasCreatedIntroClassAppointment,
  isGettingCourseDetails,
  isGettingEnrolledCourses,
  isGettingIntroClass,
  introClass,
  isTimeForExam,
  mustTakeIntroClass,
  nextSessionHappening,
  setSelectedLanguageAction
}) => {
  const [isIntroClass, setIsIntroClass] = useState(false);
  const [appt, setAppt] = useState({});

  useEffect(() => {
    if (activeOrders?.length) {
      //WIP
      const { language_id: languageId, language_name: language } = activeOrders[0]?.order_appointment_types[0] || {
        language_id: '',
        language_name: ''
      };
      setSelectedLanguageAction({ id: languageId, language });
    }
  }, [activeOrders, hasCreatedIntroClassAppointment]);

  useEffect(() => {
    if (nextSessionHappening?.date || !mustTakeIntroClass || introClass?.status === 'happened') {
      setIsIntroClass(false);
      setAppt(nextSessionHappening);
    } else {
      setIsIntroClass(true);
      setAppt(introClass);
    }
  }, [introClass, mustTakeIntroClass, nextSessionHappening]);

  if (isGettingIntroClass || isGettingEnrolledCourses || isGettingCourseDetails)
    return <CardWithHeader title="2. Live Practice" isLoading={true} type="black" cardContent={<div />} />;

  if (isIntroClass) return <IntroClassCard appt={appt} title="2. Meet your coach" />;

  return <LivePracticeCard appt={appt} title="2. Live Practice" isTimeForExam={isTimeForExam} />;
};

LivePracticeContainer.propTypes = {
  activeOrders: PropTypes.array,
  hasCreatedIntroClassAppointment: PropTypes.bool,
  isGettingCourseDetails: PropTypes.bool,
  isGettingEnrolledCourses: PropTypes.bool,
  isGettingIntroClass: PropTypes.bool,
  isSettingNextLessonToStudy: PropTypes.bool,
  isTimeForExam: PropTypes.bool,
  introClass: PropTypes.object.isRequired,
  mustTakeIntroClass: PropTypes.bool.isRequired,
  nextSessionHappening: PropTypes.object,
  setOpenBookingIntroClassModalAction: PropTypes.func.isRequired,
  setSelectedLanguageAction: PropTypes.func.isRequired,
  title: PropTypes.string,
  tutorProfilePicture: PropTypes.string,
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  activeOrders: state.orderInfo?.active_orders,
  hasCreatedIntroClassAppointment: state.newBooking.bookingLessons.hasCreatedIntroClassAppointment,
  isGettingCourseDetails: state.courses.selectedCourseDetails.isGettingCourseDetails,
  isGettingEnrolledCourses: state.courses.enrolledCourses.isGettingEnrolledCourses,
  isGettingIntroClass: state.sessions.introSession.isGettingIntroClass,
  isSettingNextLessonToStudy: state.lessons.nextLessonToStudy.isSettingNextLessonToStudy,
  introClass: state.sessions.introSession.introClass,
  mustTakeIntroClass: state.student.mustTakeIntroClass,
  nextSessionHappening: state.sessions.nextSessionHappening.nextSessionHappening?.appointment,
  user: state.user
});

export default connect(mapStateToProps, {
  setOpenBookingIntroClassModalAction: setOpenBookingIntroClassModal,
  setSelectedLanguageAction: setSelectedLanguage
})(LivePracticeContainer);
