import { SET_APPOINTMENT_TYPE, USER_LOGOUT } from '../actions/types';

export default function appointmentTypeReducer(state = {}, action) {
  switch (action.type) {
    case SET_APPOINTMENT_TYPE:
      return action.payload;
    case USER_LOGOUT:
      return {};
    case 'persist/REHYDRATE':
      return action?.payload?.appointmentType || {};
    default:
      return state;
  }
}
