import { GET_LOCATIONS, GET_LOCATIONS_ERROR, USER_LOGOUT } from '../actions/types';

export default function locationsReducer(state = [], action) {
  switch (action.type) {
    case GET_LOCATIONS:
      return action.payload.data;
    case GET_LOCATIONS_ERROR:
      return [];
    case USER_LOGOUT:
      return [];
    default:
      return state;
  }
}
