import { SET_BOOKING_FREQUENCY, USER_LOGOUT } from '../actions/types';

const DEFAULT = { datetimes: [], next_lesson: null };

export default function bookingFrequencyReducer(state = DEFAULT, action) {
  switch (action.type) {
    case SET_BOOKING_FREQUENCY:
      return action.payload.bookingFrequency;
    case USER_LOGOUT:
      return null;
    default:
      return state;
  }
}
