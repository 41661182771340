export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again, and contact us if the problem persists.';

export const getMultipleErrorMessage = (responseData, customErrorMessage) => {
  if (!responseData) customErrorMessage || DEFAULT_ERROR_MESSAGE;

  if (Array.isArray(responseData)) return responseData;

  if (typeof responseData === 'object') {
    const keys = Object.keys(responseData);
    if (!keys.length) return customErrorMessage || DEFAULT_ERROR_MESSAGE;
    return keys.map((key) => {
      if (typeof responseData[key] === 'string') return responseData[key];

      if (typeof responseData[key] === 'object') {
        const innerKeys = Object.keys(responseData[key]);
        return innerKeys.map((innerKey) => {
          return responseData[key][innerKey];
        });
      }

      return customErrorMessage || DEFAULT_ERROR_MESSAGE;
    });
  }

  return [responseData];
};

export const getErrorMessage = (responseData, customErrorMessage) => {
  if (!responseData) customErrorMessage || DEFAULT_ERROR_MESSAGE;

  if (typeof responseData === 'object') {
    const keys = Object.keys(responseData);
    if (!keys.length) return customErrorMessage || DEFAULT_ERROR_MESSAGE;
    return keys.map((key) => responseData[key]).flat(1);
  }

  return responseData;
};

export const getErrorStatus = (errorResponse) => {
  return Number(errorResponse?.status || 400);
};
