import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Col, Grid, Row } from 'react-bootstrap';
import Timetable from '../components/uikit/Timetable.js';

import { getTutorsWithLanguageAndProductType } from '../actions/tutors';
import TutorSummary from '../components/tutor-selector/TutorSummary';
import Mixpanel from '../utils/mixpanel-helper.js';
import Button from '../components/design-system/button/Button';

import appStyles from '../style/containers/App.module.scss';
import styles from '../style/containers/TutorSelector.module.scss';
import ErrorToast from '../components/design-system/toast/errorToast/ErrorToast.jsx';
import { INITIAL_MATRIX } from '../components/availability/helpers.js';

const TutorSelector = ({
  getTutorsWithLanguageAndProductTypeAction,
  isChangeCoachProcessActive,
  language,
  onboardingData,
  productType,
  tutors,
  user,
  isGettingTutors,
  hasGetTutorsError,
  getTutorsErrorMessage,
  timezone
}) => {
  const [showAvailability, setShowAvailability] = useState(false);
  const [calendarMatrix, setCalendarMatrix] = useState(INITIAL_MATRIX);
  const [tutorsList, setTutorsList] = useState([]);
  const history = useHistory();
  const timetableRef = useRef();

  useEffect(() => {
    Mixpanel.visitedPage('Tutor Selector');
    updateCalendarMatrix();
    getTutorsWithLanguageAndProductTypeAction({
      token: user.token,
      courses: user.courses,
      productTypeId: productType.productTypeId,
      languageId: language.id
    });
    document.addEventListener('mousedown', handleCloseOnClickOutsideTimeTable);

    return () => {
      document.removeEventListener('mousedown', handleCloseOnClickOutsideTimeTable);
    };
  }, []);

  useEffect(() => {
    if (tutors && tutors.length) sortTutorsBasedOnAvailability(tutors);
  }, [tutors]);

  useEffect(() => {
    if (calendarMatrix && calendarMatrix.length) {
      sortTutorsBasedOnAvailability();
    }
  }, [calendarMatrix]);

  const onSelectTimeSlot = (dayIndex, periodIndex) => {
    const newCalendarMatrix = _.cloneDeep(calendarMatrix);
    newCalendarMatrix[periodIndex][dayIndex] = !calendarMatrix[periodIndex][dayIndex];
    setCalendarMatrix(newCalendarMatrix);
  };

  const updateCalendarMatrix = () => {
    if (!onboardingData || !onboardingData.calendarMatrix) return setCalendarMatrix(INITIAL_MATRIX);
    setCalendarMatrix(onboardingData.calendarMatrix);
  };

  const toggleAvailability = () => {
    setShowAvailability((prevState) => !prevState);
  };

  const handleGoToThePreviousStep = () => {
    history.push('/bookings');
  };

  const handleCloseOnClickOutsideTimeTable = (evt) => {
    if (timetableRef.current && !timetableRef.current.contains(evt.target)) setShowAvailability(false);
  };

  const isEveryAvailabilityFalse = () => {
    return calendarMatrix.reduce((acc, cv) => {
      return (
        acc &&
        cv.reduce((acc, cv) => {
          return acc && !cv;
        }, true)
      );
    }, true);
  };

  const checkWhetherTutorIsAvailable = (tutor) => {
    if (!tutor || !tutor.availability_matrix || isEveryAvailabilityFalse()) return true;

    return calendarMatrix.reduce((acc, periodOfTheDay, periodIndex) => {
      return (
        acc ||
        periodOfTheDay.reduce((acc, dayOfTheWeekAvailability, dayIndex) => {
          return acc || (tutor.availability_matrix[periodIndex][dayIndex] && dayOfTheWeekAvailability);
        }, false)
      );
    }, false);
  };

  const sortTutorsBasedOnAvailability = () => {
    if (!tutors || !tutors.length) return;

    const sortedTutorsWithAvailabilityMatching = tutors.map((tutor) => {
      const isTutorAvailable = checkWhetherTutorIsAvailable(tutor);
      return { ...tutor, isTutorAvailable };
    });

    sortedTutorsWithAvailabilityMatching.sort((a, b) => {
      if (a.isTutorAvailable && !b.isTutorAvailable) return -1;
      if (a.isTutorAvailable && b.isTutorAvailable) {
        if (a.first_name > b.first_name) return 1;
        if (a.first_name < b.first_name) return -1;
      }
      return 1;
    });

    setTutorsList(sortedTutorsWithAvailabilityMatching);
  };

  const renderTutors = () => {
    if (!tutorsList || !tutorsList.length) return;

    return tutorsList.map((tutor) => {
      return (
        <TutorSummary
          languages={tutor.languages}
          history={history}
          id={tutor.id}
          key={tutor.id}
          location={tutor.location}
          name={`${tutor.first_name} ${tutor.last_name}`}
          photoUrl={tutor.photo}
          availabilityMatching={tutor.isTutorAvailable}
          country={tutor.country ? tutor.country : ''}
          interests={(tutor.professional_interests ? tutor.professional_interests : [])
            .concat(tutor.personal_interests ? tutor.personal_interests : [])
            .map((int) => int.name)}
          rating={tutor.calculated_rating}
          bio={tutor.bio || ''}
          reviews={tutor.reviews}
        />
      );
    });
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.header}>
          {isChangeCoachProcessActive ? (
            <>
              <button className={styles.modalBackButton} onClick={handleGoToThePreviousStep}>
                Back
              </button>
              <h1>Change your coach: Choose a new coach</h1>
              <div className={styles.changeCoachWarning}>
                <div>
                  <p>You can change your coach at any point in your learning journey.</p>
                  <p>Choose a new coach from this list and start booking Live Practices from their profile.</p>
                  <p>If you change your mind, use the Back button at the top to cancel.</p>
                </div>
              </div>
            </>
          ) : (
            <h1>Meet our {language.language} coaches</h1>
          )}
          <div className={styles.filterWrapper}>
            <div className={styles.dropdownWrapper}>
              <p className={styles.dropdownLabel}>Preferred schedule:</p>
              <Button className={styles.filterButton} onClick={toggleAvailability}>
                Availability <span className="caret" />
              </Button>
              {showAvailability && (
                <div ref={timetableRef} id="calendar" className={styles.timetableWrapper}>
                  <Timetable calendarMatrix={calendarMatrix} onSelectTimeSlot={onSelectTimeSlot} timezone={timezone} />
                </div>
              )}
            </div>
          </div>
        </div>
        {isGettingTutors && <div className={appStyles.loader} />}
        {!isGettingTutors && tutors && !!tutors.length ? (
          <div className={styles.tutorsContainer}>{renderTutors(tutors)}</div>
        ) : (
          <Grid>
            <Row>
              <Col xs="12" smOffset="1" sm="10" mdOffset="2" md="8" lgOffset="3" lg="6">
                <Alert bsStyle="warning">
                  <strong>Sorry!</strong> There are no tutors for this appointment type right now. Please let us know at{' '}
                  <a href="mailto:courses@chatterbox.io">courses@chatterbox.io</a>.
                </Alert>
              </Col>
            </Row>
          </Grid>
        )}

        <ErrorToast errorMsg={getTutorsErrorMessage} show={hasGetTutorsError} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  language: state.selectedLanguage,
  productType: state.selectedProductType,
  appointmentType: state.appointmentType,
  onboardingData: state.onboardingMatch,
  isChangeCoachProcessActive: state.changeCoach.isChangeCoachProcessActive,
  isGettingTutors: state.tutors.isGettingTutors,
  hasGetTutorsError: state.tutors.hasGetTutorsError,
  getTutorsErrorMessage: state.tutors.getTutorsErrorMessage,
  tutors: state.tutors.tutors,
  timezone: state.time.timezone
});

TutorSelector.propTypes = {
  user: PropTypes.object.isRequired,
  tutors: PropTypes.array,
  productType: PropTypes.object.isRequired,
  language: PropTypes.object.isRequired,
  getTutorsWithLanguageAndProductTypeAction: PropTypes.func.isRequired,
  appointmentType: PropTypes.object.isRequired,
  onboardingData: PropTypes.object,
  isChangeCoachProcessActive: PropTypes.bool.isRequired,
  isGettingTutors: PropTypes.bool,
  hasGetTutorsError: PropTypes.bool,
  getTutorsErrorMessage: PropTypes.string,
  timezone: PropTypes.string
};

TutorSelector.defaultProps = {
  tutors: [],
  onboardingData: {}
};

export default connect(mapStateToProps, {
  getTutorsWithLanguageAndProductTypeAction: getTutorsWithLanguageAndProductType
})(TutorSelector);
