import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../style/components/uikit/ProfilePhoto.module.scss';

import ProfilePlaceholder from '../../assets/people/profile-placeholder.png';

const ProfilePhoto = ({ className, photoURL }) => {
  const style = {
    backgroundImage: `url(${photoURL || ProfilePlaceholder})`
  };
  return (
    <div className={className}>
      <div className={styles.photo} style={style} />
    </div>
  );
};

ProfilePhoto.propTypes = {
  className: PropTypes.string,
  photoURL: PropTypes.string.isRequired
};

ProfilePhoto.defaultProps = {
  className: ''
};

export default ProfilePhoto;
