import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import * as moment from 'moment-timezone';

import PontualAvailForm from './PontualAvailForm';
import ButtonModal from '../uikit/ButtonModal';
import Mixpanel from '../../utils/mixpanel-helper.js';
import { DATE_DISPLAY_FORMAT, NAIVE_TIME_DISPLAY_FORMAT, TIME_DISPLAY_FORMAT } from '../../utils/time-helpers';

import { createNewAvailability, deleteAvailability, updateAvailability } from '../../actions/availabilities';

import styles from '../../style/components/calendar/PontualAvail.module.scss';

class PontualAvail extends Component {
  modal = null;

  subtractMinute = (timeString) => {
    const datetime = moment.utc(`2000-01-01T${timeString}`);
    datetime.subtract(1, 'minute');
    return datetime.format('HH:mm');
  };

  createNewAvailability = (token, date, firstMinute, endLimit, isRecurring, errorCallback, successCallback) => {
    Mixpanel.track('Click', { button: 'create_new_occasional_availability' });
    const lastMinute = this.subtractMinute(endLimit);
    return this.props.createNewAvailability(token, date, firstMinute, lastMinute, isRecurring, errorCallback, successCallback);
  };

  deleteAvailability = (token, availabilityId, errorCallback, successCallback) => {
    Mixpanel.track('Click', { button: 'delete_occasional_availabilty' });
    return this.props.deleteAvailability(token, availabilityId, errorCallback, successCallback);
  };

  updateAvailability = (token, date, firstMinute, endLimit, availabilityId, isRecurring, errorCallback, successCallback) => {
    const lastMinute = this.subtractMinute(endLimit);

    return this.props.updateAvailability(token, date, firstMinute, lastMinute, availabilityId, isRecurring, errorCallback, successCallback);
  };

  renderAvailabilitiePeriod = (availabilitie) => {
    // const { from, id, to, recur_weekly } = availabilitie;
    const start = moment.utc(`${availabilitie.date}T${availabilitie.from}`);

    const end = moment.utc(`${availabilitie.date}T${availabilitie.to}`);
    const startDateString = start.format('YYYY-MM-DD');
    const startTimeString = start.format('HH:mm');

    const endDateString = end.format('YYYY-MM-DD');
    const endTimeString = end.format('HH:mm');

    const startDayDisplay = start.format(DATE_DISPLAY_FORMAT);
    const endDayDisplay = end.format(DATE_DISPLAY_FORMAT);

    let startTimeDisplay;

    // Convert the inclusive end time to a non-inclusive one. (More info: https://goo.gl/RY1Voy)
    const endTimeDisplay = end.clone().add(1, 'minute').format(NAIVE_TIME_DISPLAY_FORMAT);

    const startUTCOffset = start.utcOffset();
    const endUTCOffset = end.utcOffset();

    if (startUTCOffset === endUTCOffset) {
      startTimeDisplay = start.format(NAIVE_TIME_DISPLAY_FORMAT);
    } else {
      startTimeDisplay = start.format(TIME_DISPLAY_FORMAT);
    }

    let availabilitiePeriodDisplay;

    if (startDateString === endDateString) {
      if (startTimeString === '00:00' && endTimeString === '23:59') {
        availabilitiePeriodDisplay = startDayDisplay;
      } else {
        availabilitiePeriodDisplay = `${startDayDisplay}: ${startTimeDisplay} – ${endTimeDisplay}`;
      }
    } else {
      let startDisplay;
      let endDisplay;

      if (startTimeString === '00:00') {
        startDisplay = startDayDisplay;
      } else {
        startDisplay = `${startDayDisplay} @ ${startTimeDisplay}`;
      }

      if (endTimeString === '23:59') {
        endDisplay = endDayDisplay;
      } else {
        endDisplay = `${endDayDisplay} @ ${endTimeDisplay}`;
      }

      availabilitiePeriodDisplay = `${startDisplay} ${endDisplay}`;
    }

    return (
      <ListGroupItem bsStyle="info" className={styles.absencePeriod} key={availabilitie.id}>
        {availabilitiePeriodDisplay}
        <span className="pull-right">
          <ButtonModal
            buttonStyle="greenButton"
            buttonText="Edit"
            ref={(modal) => {
              this.modal = modal;
            }}>
            <PontualAvailForm
              closeModal={() => {
                this.modal.close();
              }}
              end={end}
              availabilityId={availabilitie.id}
              start={start}
              title="Edit one time availability"
              token={this.props.token}
              tutor={this.props.tutor}
              deleteAvailability={this.deleteAvailability}
              updateAvailability={this.updateAvailability}
            />
          </ButtonModal>
        </span>
      </ListGroupItem>
    );
  };

  render = () => (
    <div className={styles.container}>
      <ListGroup>
        {this.props.nonRecurrAvailabilities.length > 0 && this.props.nonRecurrAvailabilities.map(this.renderAvailabilitiePeriod)}

        {this.props.nonRecurrAvailabilities.length < 1 && (
          <ListGroupItem className={styles.absencePeriod_default}>You have no one-off availability.</ListGroupItem>
        )}
      </ListGroup>

      <div className={styles.actions}>
        <ButtonModal
          buttonText="Add new"
          onClick={() => {
            Mixpanel.track('Click', { button: 'add_new_occasional_availability' });
          }}>
          <PontualAvailForm
            closeModal={() => {
              this.modal.close();
            }}
            token={this.props.token}
            tutor={this.props.tutor}
            timesForNewAvail={this.props.timesForNewAvail}
            createNewAvailability={this.createNewAvailability}
          />
        </ButtonModal>
      </div>
    </div>
  );
}

PontualAvail.propTypes = {
  nonRecurrAvailabilities: PropTypes.array.isRequired,
  token: PropTypes.string.isRequired,
  tutor: PropTypes.object.isRequired,
  timesForNewAvail: PropTypes.array.isRequired,
  createNewAvailability: PropTypes.func.isRequired,
  deleteAvailability: PropTypes.func.isRequired,
  updateAvailability: PropTypes.func.isRequired
};

export default connect(null, { createNewAvailability, deleteAvailability, updateAvailability })(PontualAvail);
