import queryString from 'qs';

export function getLoginPathWithRedirect() {
  const path = `${window.location.pathname}${window.location.search}`;
  const encodedPath = encodeURIComponent(path);
  return `/?next=${encodedPath}`;
}

export const getHashParamsObject = (hashUrl) => {
  const stringWithoutHash = hashUrl.replace('#', '');
  if (!stringWithoutHash) return stringWithoutHash;
  const hashArrayOfObjects = stringWithoutHash.split('&').map((props) => {
    const arr = props?.split('=');
    return { [arr[0]]: arr[1] };
  });

  return hashArrayOfObjects.reduce((acc, cv) => ({ ...acc, ...cv }), {});
};

export function getQueryParamValue(key, { encoded = false } = {}) {
  const params = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
    format: 'RFC3986'
  });
  let value = params[key];
  if (value) {
    value = value?.split(' ').join('+');
  }

  if (encoded && value !== undefined) {
    value = encodeURIComponent(value);
  }

  return value;
}

export const isInternalUser = (email) => email.includes('@chatterbox.io') || email.includes('@wearechatterbox.org') || email.includes('@example.com');

export const openInNewWindow = (url) => {
  if (!url) return;
  return window.open(url, '_blank');
};

export const convertArrayToQueryString = (arr, key) => {
  if (!arr) return '';
  return arr
    .map((el) => `${key}=${el}&`)
    .join('')
    .slice(0, -1);
};

export const convertObjToQueryString = (obj) => {
  if (!obj) return '';
  return Object.keys(obj)
    .map((key) => obj[key] && `${key}=${obj[key]}&`)
    .filter((el) => el)
    .join('')
    .slice(0, -1);
};

export const getSearchParam = (param) => {
  const params = new URL(document.location).searchParams;
  return params.get(param) || '';
};
