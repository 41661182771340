import ChatAPI from '../services/chat';
import { sendSentryError } from '../setup/sentry';
import { TwilioConversation } from '../services/chat/twilio';
import { actionCreatorsFactory, asyncActionType, createActionTypes } from '../utils/action-helpers';
import { isTokenExpired } from './common';

export const ChatActionTypes = createActionTypes('Chat', [
  asyncActionType('JOIN_CONVERSATION'),
  'ADD_MESSAGE',
  'USER_TYPING',
  asyncActionType('JOIN_ALL_CONVERSATIONS'),
  asyncActionType('GET_CHAT_CONVERSATIONS'),
  asyncActionType('GET_CONVERSATION_PARTICIPANTS'),
  asyncActionType('GET_USERS_FROM_PARTICIPANTS'),
  'SET_USER',
  'SET_SELECTED_CONVERSATION',
  asyncActionType('GET_MESSAGES'),
  'CLEAN_SENDING_MESSAGE_ERROR',
  'CLEAN_GETTING_MESSAGES_ERROR',
  asyncActionType('SEND_MESSAGE_TO_BACKEND'),
  asyncActionType('SEND_MESSAGE_TO_TWILIO'),
  asyncActionType('CHECK_CHAT_MESSAGES'),
  asyncActionType('GET_CHAT_TOKEN'),
  asyncActionType('SET_NEW_MSG_LISTENER'),
  asyncActionType('REMOVE_MSG_LISTENER'),
  'CLEAR_MESSAGES',
  'CLEAR_CONVERSATION',
  'SET_IS_SUPPORT'
]);

const getChatTokenActions = actionCreatorsFactory(
  [ChatActionTypes.GET_CHAT_TOKEN, ChatActionTypes.GET_CHAT_TOKEN_SUCCESS, ChatActionTypes.GET_CHAT_TOKEN_FAIL],
  'getChatToken'
);

export function getChatToken(token, userId, callback) {
  return async (dispatch) => {
    try {
      if (!token || !userId) return;
      dispatch(getChatTokenActions.getChatToken());
      const chatTokenResponse = await ChatAPI.getChatToken({ token, userId });
      if (callback && chatTokenResponse?.data) callback(chatTokenResponse.data);
      dispatch(getChatTokenActions.getChatTokenSuccess(chatTokenResponse.data));
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        sendSentryError({ err, context: 'getChatToken' });
        dispatch(getChatTokenActions.getChatTokenFail(err));
      }
    }
  };
}

export const addMessage = (message) => ({
  type: ChatActionTypes.ADD_MESSAGE,
  payload: message
});

export const cleanMessages = () => ({
  type: ChatActionTypes.CLEAR_MESSAGES
});

export const cleanConversation = () => ({
  type: ChatActionTypes.CLEAR_CONVERSATION
});

export const cleanSendingMessageError = () => ({
  type: ChatActionTypes.CLEAN_SENDING_MESSAGE_ERROR
});

export const cleanGettingMessagesError = () => ({
  type: ChatActionTypes.CLEAN_GETTING_MESSAGES_ERROR
});

const onUserTyping = (userTyping) => ({
  type: ChatActionTypes.USER_TYPING,
  payload: userTyping
});

const setConversationUser = (user) => ({
  type: ChatActionTypes.SET_USER,
  payload: user
});

const getUsersFromParticipantsActions = actionCreatorsFactory(
  [
    ChatActionTypes.GET_USERS_FROM_PARTICIPANTS,
    ChatActionTypes.GET_USERS_FROM_PARTICIPANTS_SUCCESS,
    ChatActionTypes.GET_USERS_FROM_PARTICIPANTS_FAIL
  ],
  'getUsersFromParticipants'
);

const getUserFromParticipants = ({ currentParticipants, users }) => {
  if (!currentParticipants?.length && !users?.length) return [];
  const allUsers = currentParticipants.map((p) => {
    return users.find((u) => String(u?.twilio_identity) === String(p?.state?.identity));
  });
  return allUsers.filter((u) => u);
};

async function getConversationUsers({ token, conversationUniqueName, currentParticipants }) {
  return async (dispatch) => {
    try {
      dispatch(getUsersFromParticipantsActions.getUsersFromParticipants());
      const { data } = await ChatAPI.getConversationUsers({ token, conversationUniqueName });
      dispatch(
        getUsersFromParticipantsActions.getUsersFromParticipantsSuccess(
          getUserFromParticipants({
            currentParticipants,
            users: data
          })
        )
      );
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        sendSentryError({ err, context: 'getConversationUsers' });
        dispatch(getUsersFromParticipantsActions.getUsersFromParticipantsFail("Couldn't retrieve users from this conversation."));
      }
    }
  };
}

const getConversationParticipantsActions = actionCreatorsFactory(
  [
    ChatActionTypes.GET_CONVERSATION_PARTICIPANTS,
    ChatActionTypes.GET_CONVERSATION_PARTICIPANTS_SUCCESS,
    ChatActionTypes.GET_CONVERSATION_PARTICIPANTS_FAIL
  ],
  'getConversationParticipants'
);

const getParticipants = (participantsMap) => {
  const participants = [];
  if (!participantsMap) return participants;
  participantsMap.forEach((value) => {
    participants.push(value);
  });

  return participants;
};

export function getConversationParticipants({ SelectedConversation, token, conversationUniqueName }) {
  return (dispatch) => {
    try {
      if (!SelectedConversation || !token || !conversationUniqueName) return;
      dispatch(getConversationParticipantsActions.getConversationParticipants());
      const currentParticipants = getParticipants(SelectedConversation?.participants);
      dispatch(getConversationParticipantsActions.getConversationParticipantsSuccess(currentParticipants));
      dispatch(getConversationUsers({ token, conversationUniqueName, currentParticipants }));
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        sendSentryError({ err, context: 'getConversationParticipants' });
        dispatch(getConversationParticipantsActions.getConversationParticipantsFail("Couldn't retrieve participants from this conversation."));
      }
    }
  };
}

const joinConversationActions = actionCreatorsFactory(
  [ChatActionTypes.JOIN_CONVERSATION, ChatActionTypes.JOIN_CONVERSATION_SUCCESS, ChatActionTypes.JOIN_CONVERSATION_FAIL],
  'joinConversation'
);

export function joinConversation(Conversation) {
  return async (dispatch) => {
    try {
      if (!Conversation) return;
      dispatch(joinConversationActions.joinConversation());
      if (!Conversation.on) return;
      Conversation.on('messageAdded', (msg) => dispatch(addMessage(msg)));
      Conversation.on('typingStarted', async (participant) => {
        if (!participant || !participant.getUser) return;
        const user = await participant.getUser();
        dispatch(onUserTyping(user));
      });
      Conversation.on('typingEnded', () => dispatch(onUserTyping(null)));
      dispatch(joinConversationActions.joinConversationSuccess());
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        sendSentryError({ err, context: 'joinConversation' });

        dispatch(joinConversationActions.joinConversationFail(err));
      }
    }
  };
}

const joinAllConversationsActions = actionCreatorsFactory(
  [ChatActionTypes.JOIN_ALL_CONVERSATIONS, ChatActionTypes.JOIN_ALL_CONVERSATIONS_SUCCESS, ChatActionTypes.JOIN_ALL_CONVERSATIONS_FAIL],
  'joinAllConversations'
);

export function joinAllConversations(user) {
  return async (dispatch) => {
    try {
      if (!user?.token) throw new Error('User is not setted.');
      const { data } = ChatAPI.getChatsToJoin(user.token);
      dispatch(joinAllConversationsActions.joinAllConversations());
      const twilioChat = TwilioConversation.getInstance(user);
      await twilioChat.joinChats(data?.chats_to_join, user);
      dispatch(joinAllConversationsActions.joinAllConversationsSuccess());
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        sendSentryError({ err, context: 'joinAllConversations' });
        dispatch(joinAllConversationsActions.joinAllConversationsFail(err));
      }
    }
  };
}

const setNewMessageListenerActions = actionCreatorsFactory(
  [ChatActionTypes.SET_NEW_MSG_LISTENER, ChatActionTypes.SET_NEW_MSG_LISTENER_SUCCESS, ChatActionTypes.SET_NEW_MSG_LISTENER_FAIL],
  'setNewMessageListener'
);

export function setNewMessageListener(user) {
  return async (dispatch) => {
    try {
      dispatch(setNewMessageListenerActions.setNewMessageListener());
      const twilioChat = TwilioConversation.getInstance(user);
      const client = await twilioChat.getClient();
      if (!client) throw new Error("Couldn't get Twilio client");
      client.on('messageAdded', () => {
        checkChatMessages(user);
      });
      dispatch(setNewMessageListenerActions.setNewMessageListenerSuccess());
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        sendSentryError({ err, context: 'setNewMessageListener' });
        dispatch(setNewMessageListenerActions.setNewMessageListenerFail());
      }
    }
  };
}

const removeMessageListenerActions = actionCreatorsFactory(
  [ChatActionTypes.REMOVE_MSG_LISTENER, ChatActionTypes.REMOVE_MSG_LISTENER_SUCCESS, ChatActionTypes.REMOVE_MSG_LISTENER_FAIL],
  'removeMessageListener'
);

export function removeMessageListener(user) {
  return async (dispatch) => {
    try {
      dispatch(removeMessageListenerActions.removeMessageListener());
      const twilioChat = TwilioConversation.getInstance(user);
      const client = await twilioChat.getClient();
      if (!client) throw new Error("Couldn't get Twilio client");
      client.removeAllListeners();
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        sendSentryError({ err, context: 'removeMessageListener' });
        dispatch(removeMessageListenerActions.removeMessageListenerFail());
      }
    }
  };
}

const loopThroughPaginator = async (paginator) => {
  const { items } = paginator;
  if (!items) return [];

  let subscribedItems = [...items];
  let next = paginator;

  while (next.hasNextPage) {
    next = await next.nextPage();
    subscribedItems = [...subscribedItems, ...next.items];
  }

  let prev = paginator;
  while (prev.hasPrevPage) {
    prev = await prev.prevPage();
    subscribedItems = [...subscribedItems, ...prev.items];
  }

  return subscribedItems;
};

const sortConversationsByDate = (conversations) => {
  return conversations.sort((a, b) => {
    const first = a?.lastMessage?.dateCreated || a.dateUpdated;
    const second = b?.lastMessage?.dateCreated || b.dateUpdated;
    return second - first;
  });
};

const sortDescendingByDate = (msgs) => {
  return msgs.sort((a, b) => {
    const first = a?.lastMessage?.dateCreated || a.dateUpdated;
    const second = b?.lastMessage?.dateCreated || b.dateUpdated;
    return first - second;
  });
};

const getChatConversationsActions = actionCreatorsFactory(
  [ChatActionTypes.GET_CHAT_CONVERSATIONS, ChatActionTypes.GET_CHAT_CONVERSATIONS_SUCCESS, ChatActionTypes.GET_CHAT_CONVERSATIONS_FAIL],
  'getChatConversations'
);

export function getChatConversations(user) {
  return async (dispatch) => {
    try {
      dispatch(getChatConversationsActions.getChatConversations());
      const twilioChat = TwilioConversation.getInstance(user);
      const client = await twilioChat.getClient();
      if (!client) throw new Error("Couldn't get Twilio client");
      dispatch(setConversationUser(client?.user));
      const paginator = await client.getSubscribedConversations({
        criteria: 'lastMessage',
        order: 'ascending'
      });
      const SubscribedConversations = await loopThroughPaginator(paginator);

      dispatch(getChatConversationsActions.getChatConversationsSuccess(sortConversationsByDate(SubscribedConversations)));
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        dispatch(getChatConversationsActions.getChatConversationsFail(err));
        sendSentryError({ err, context: 'getChatConversations' });
      }
    }
  };
}

export const setSelectedConversation = (Conversation) => ({
  type: ChatActionTypes.SET_SELECTED_CONVERSATION,
  payload: Conversation
});

const getMessagesActions = actionCreatorsFactory(
  [ChatActionTypes.GET_MESSAGES, ChatActionTypes.GET_MESSAGES_SUCCESS, ChatActionTypes.GET_MESSAGES_FAIL],
  'getMessages'
);

export function getMessagesFromTwilio({ SelectedConversation, onEmpty }) {
  return async (dispatch) => {
    try {
      if (!SelectedConversation || !SelectedConversation?.getMessages) return;
      dispatch(getMessagesActions.getMessages());
      const paginator = await SelectedConversation.getMessages();
      const subscribedMessages = await loopThroughPaginator(paginator);
      dispatch(getMessagesActions.getMessagesSuccess(sortDescendingByDate(subscribedMessages)));
      if (!subscribedMessages.length && onEmpty) onEmpty();
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        sendSentryError({ err, context: 'getMessagesFromTwilio' });
        dispatch(getMessagesActions.getMessagesFail(err));
      }
    }
  };
}

const sendMessageToBackendActions = actionCreatorsFactory(
  [ChatActionTypes.SEND_MESSAGE_TO_BACKEND, ChatActionTypes.SEND_MESSAGE_TO_BACKEND_SUCCESS, ChatActionTypes.SEND_MESSAGE_TO_BACKEND_FAIL],
  'sendMessage'
);

export function sendMessageToBackend({ token, channelId, message, callback }) {
  return async (dispatch) => {
    try {
      if (!token || !channelId || !message) throw new Error('Missing parameters');
      dispatch(sendMessageToBackendActions.sendMessage());
      await ChatAPI.sendMessage({ token, channelId, message });
      if (callback) callback();
      dispatch(sendMessageToBackendActions.sendMessageSuccess());
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        sendSentryError({ err, context: 'sendMessageToBackend' });
        dispatch(sendMessageToBackendActions.sendMessageFail(err));
      }
    }
  };
}

const sendMessageToTwilioActions = actionCreatorsFactory(
  [ChatActionTypes.SEND_MESSAGE_TO_TWILIO, ChatActionTypes.SEND_MESSAGE_TO_TWILIO_SUCCESS, ChatActionTypes.SEND_MESSAGE_TO_TWILIO_FAIL],
  'sendMessage'
);

export function sendMessageToTwilio({ SelectedConversation, message, messageAttributes, callback }) {
  return async (dispatch) => {
    try {
      if (!SelectedConversation || !message) throw new Error('Missing message');
      dispatch(sendMessageToTwilioActions.sendMessage());
      await SelectedConversation.sendMessage(message, messageAttributes);
      dispatch(sendMessageToTwilioActions.sendMessageSuccess());
      if (callback) callback();
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        sendSentryError({ err, context: 'sendMessageToTwilio' });
        dispatch(sendMessageToTwilioActions.sendMessageFail(err));
      }
    }
  };
}

const checkChatActions = actionCreatorsFactory(
  [ChatActionTypes.CHECK_CHAT_MESSAGES, ChatActionTypes.CHECK_CHAT_MESSAGES_SUCCESS, ChatActionTypes.CHECK_CHAT_MESSAGES_FAIL],
  'checkChatMessages'
);

export function checkChatMessages(user) {
  return async (dispatch) => {
    try {
      dispatch(checkChatActions.checkChatMessages());
      const twilioChat = TwilioConversation.getInstance(user);
      const client = await twilioChat.getClient();
      if (!client) throw new Error("Couldn't get Twilio client");
      const { items } = await client.getSubscribedConversations();

      if (!items?.length) {
        dispatch(
          checkChatActions.checkChatMessagesSuccess({
            unreadMessages: 0,
            unreadLangHelperChat: 0
          })
        );
      } else {
        const { unreadMessages, unreadLangHelperChat } = items.reduce(
          (acc, item) => {
            if (!item?.lastMessage) return acc;
            const lastIndex = item?.lastReadMessageIndex;
            if (typeof lastIndex !== 'number' && !lastIndex) return acc;

            const unreadMessages = item?.lastMessage.index - lastIndex;
            return {
              unreadMessages,
              unreadLangHelperChat: item?.attributes?.courseLeaderChat ? unreadMessages : 0
            };
          },
          { unreadMessages: 0, unreadLangHelperChat: 0 }
        );
        dispatch(
          checkChatActions.checkChatMessagesSuccess({
            unreadMessages,
            unreadLangHelperChat
          })
        );
      }
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        sendSentryError({ err, context: 'checkChatMessages' });
        dispatch(checkChatActions.checkChatMessagesFail());
      }
      if (err.message === 'chatTokenIssue') {
        this.props.logUserOut();
      }
    }
  };
}

export const setIsSupport = (payload) => ({
  type: ChatActionTypes.SET_IS_SUPPORT,
  payload
});
