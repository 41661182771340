import { GET_LANGUAGES, GET_LANGUAGES_ERROR, USER_LOGOUT } from '../actions/types';

export default function languagesReducer(state = null, action) {
  switch (action.type) {
    case GET_LANGUAGES:
      return action.payload.data;
    case GET_LANGUAGES_ERROR:
      return null;
    case USER_LOGOUT:
      return null;
    default:
      return state;
  }
}
