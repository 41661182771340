import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import UnitsList from '../UnitsList';
import styles from './CourseDetailsMobile.module.scss';
import LoadingSpinner from '../../design-system/loading/spinner/LoadingSpinner';
import { setSelectedUnit } from '../../../actions/lessons';
import CurrentUnit from './components/CurrentUnit';
import CurrentAssessment from './components/CurrentAssessment';

const CourseDetailsMobile = ({
  isGettingCourseDetails,
  isSettingSelectedUnit,
  onUnitSelection,
  selectedCourse,
  selectedUnit,
  setSelectedUnitAction,
  tab,
  user
}) => {
  const [selectedUnitIndex, setSelectedUnitIndex] = useState(0);
  const history = useHistory();

  const handleUnitSelection = (index, unit) => {
    if (typeof index !== 'number' || !unit) return;
    setSelectedUnitIndex(index);
    setSelectedUnitAction({ token: user.token, unit });
    onUnitSelection();
  };

  useEffect(() => {
    setSelectedUnitIndex(getSelectedUnitIndex());
  }, [selectedUnit]);

  const getSelectedUnitIndex = () => {
    if (!selectedCourse || selectedCourse.length) return 0;
    if (!Object.keys(selectedUnit).length) return 0;
    return selectedCourse?.units?.findIndex((unit) => unit?.name === selectedUnit?.name) || 0;
  };

  if (isGettingCourseDetails || !selectedCourse?.units?.length)
    return (
      <div className={styles['course-details-wrapper']}>
        <div className={styles.loadingWrapper}>
          <div className={styles.loader}>
            <LoadingSpinner />
          </div>
        </div>
      </div>
    );

  if (tab === 'course') return <UnitsList onSelectUnit={handleUnitSelection} selectedCourse={selectedCourse} selected={selectedUnitIndex} />;

  return (
    <div className={styles['course-details-wrapper']}>
      {selectedUnit.type === 'Assessment' ? (
        <CurrentAssessment
          disabled={
            (selectedCourse?.units[selectedUnitIndex - 1].type === 'Lesson' &&
              selectedCourse?.units[selectedUnitIndex - 1].booking_state !== 'happened') ||
            (selectedCourse?.units[selectedUnitIndex - 1].type === 'Assessment' &&
              selectedCourse?.units[selectedUnitIndex - 2].booking_state !== 'happened')
          }
          isLoading={isSettingSelectedUnit}
          selectedUnit={selectedUnit}
          status={selectedUnit.status}
          history={history}
        />
      ) : (
        <CurrentUnit
          history={history}
          isLoading={isSettingSelectedUnit}
          selectedUnit={selectedUnit}
          status={selectedUnit.progress < 1 ? 'in-progress' : 'completed'}
        />
      )}
    </div>
  );
};

CourseDetailsMobile.propTypes = {
  componentRef: PropTypes.object,
  isGettingCourseDetails: PropTypes.bool.isRequired,
  isGettingSelectedUnitProgress: PropTypes.bool.isRequired,
  isSettingSelectedUnit: PropTypes.bool.isRequired,
  onUnitSelection: PropTypes.func.isRequired,
  selectedCourse: PropTypes.object.isRequired,
  selectedUnit: PropTypes.object.isRequired,
  setSelectedUnitAction: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isGettingCourseDetails: state.courses.selectedCourseDetails.isGettingCourseDetails,
  isSettingSelectedUnit: state.selectedUnit.isSettingSelectedUnit,
  selectedCourse: state.courses?.selectedCourseDetails?.courseDetails,
  selectedUnit: state.selectedUnit.selectedUnit,
  user: state.user
});

export default connect(mapStateToProps, {
  setSelectedUnitAction: setSelectedUnit
})(CourseDetailsMobile);
