import { isAfter } from 'date-fns';
import { combineReducers } from 'redux';
import { CourseActionTypes } from '../actions/courses';
import { LessonsActionTypes } from '../actions/lessons';
import { USER_LOGOUT } from '../actions/types';
import { UNIT_TYPE } from '../enums/unit';
import { isUnitDone } from '../utils/appointment-helpers';
import { convertStringsOfDateAndTimeToDate } from '../utils/time-helpers';

const COMPLETED_STATUS = 'Completed';

export const isUnitEnabled = (selectedCourse, unit) => {
  if (!selectedCourse || !selectedCourse.next_lesson_to_study || !unit) return;

  const unitIndex = selectedCourse.units.findIndex((u) => u.id === unit.id);
  if (unitIndex === 0) return true;
  const prevUnits = selectedCourse.units.slice(0, unitIndex);
  return prevUnits.reduce((acc, u) => {
    if (u.isOptional) return acc && true;
    return acc && isUnitDone(u, u.type.toLowerCase() === UNIT_TYPE.lesson ? 'booking_state' : 'status');
  }, true);
};

const isTimeForExam = (selectedCourse) => {
  if (!selectedCourse || !selectedCourse.lessons || !selectedCourse.next_lesson_to_study) return false;
  const { lessons, next_lesson_to_study } = selectedCourse;
  return next_lesson_to_study > lessons.length;
};

const hasCompletedExam = (exam) => {
  return exam?.status === COMPLETED_STATUS;
};

const LIMIT_DATE = new Date('2020-10-26');

const getLastUnratedUnit = (units) => {
  if (!units?.length) return null;

  const sortedLastUnratedUnit = units
    .filter((unit) => {
      return unit?.appointment && unit?.booking_state === 'happened' && unit?.appointment.rating_value === null;
    })
    .sort((a, b) => {
      if (
        isAfter(
          LIMIT_DATE,
          convertStringsOfDateAndTimeToDate(a.appointment.start_date, a.appointment.start_time),
          LIMIT_DATE,
          convertStringsOfDateAndTimeToDate(b.appointment.start_date, b.appointment.start_time)
        )
      )
        return -1;
      return 1;
    });

  return sortedLastUnratedUnit[0] || null;
};

const updateUnitsProgress = (prevUnits, updatedProgress) => {
  if (!prevUnits || !prevUnits.length) return prevUnits;
  return prevUnits.map((unit) => {
    if (unit.type.toLowerCase() === UNIT_TYPE.lesson) {
      const newProgressObj = updatedProgress.find((p) => p.type.toLowerCase() === UNIT_TYPE.lesson && p.lesson_id === unit.id);
      if (newProgressObj) return { ...unit, progress: newProgressObj.progress };
      return unit;
    }
    if (unit.type.toLowerCase() === UNIT_TYPE.assessment) {
      const newScoreObj = updatedProgress.find((p) => p.type.toLowerCase() === UNIT_TYPE.assessment && p.assessment_id === unit.id);
      if (newScoreObj) return { ...unit, score: newScoreObj.score, dateCompleted: newScoreObj.date_completed };
      return unit;
    }
    return unit;
  });
};

const getOverallSelfStudyProgress = (updatedProgress) => {
  const { count, sumSelfStudyProgress } = updatedProgress.reduce(
    (acc, cv) => {
      if (cv.type.toLowerCase() !== UNIT_TYPE.lesson) return acc;
      return { ...acc, count: acc.count + 1, sumSelfStudyProgress: acc.sumSelfStudyProgress + cv.progress };
    },
    { count: 0, sumSelfStudyProgress: 0 }
  );

  return sumSelfStudyProgress / count;
};

const enrolledCoursesInitialState = {
  isGettingEnrolledCourses: true,
  hasErrorGettingEnrolledCourses: false,
  errorMessageGettingEnrolledCourses: '',
  enrolledCourses: []
};

const sortEnrolledCourseFirst = (courses) => {
  const sortedCourses = courses.sort((a, b) => {
    if (a.is_current_course) {
      return -1;
    } else if (b.is_current_course) {
      return 1;
    } else {
      return 0;
    }
  });
  return sortedCourses;
};

function enrolledCoursesReducer(state = enrolledCoursesInitialState, action) {
  switch (action.type) {
    case CourseActionTypes.GET_ENROLLED_COURSES:
      return {
        ...state,
        isGettingEnrolledCourses: true,
        hasErrorGettingEnrolledCourses: false,
        errorMessageGettingEnrolledCourses: ''
      };
    case CourseActionTypes.GET_ENROLLED_COURSES_SUCCESS:
      return {
        ...state,
        isGettingEnrolledCourses: false,
        enrolledCourses: sortEnrolledCourseFirst(action.payload),
        hasErrorGettingEnrolledCourses: false,
        errorMessageGettingEnrolledCourses: ''
      };
    case CourseActionTypes.GET_ENROLLED_COURSES_FAIL:
      return {
        ...state,
        isGettingEnrolledCourses: false,
        enrolledCourses: [],
        hasErrorGettingEnrolledCourses: true,
        errorMessageGettingEnrolledCourses: action.payload
      };
    case USER_LOGOUT:
      return enrolledCoursesInitialState;
    default:
      return state;
  }
}

const selectedCourseDetailsInitialState = {
  isGettingCourseDetails: false,
  hasErrorGettingCourseDetails: false,
  errorMessageGettingCourseDetails: '',
  courseDetails: {
    id: null,
    isTimeForExam: false,
    practiceExam: null,
    hasCompletedPracticeExam: false,
    finalExam: null,
    hasCompletedFinalExam: false,
    language: {}
  }
};

function selectedCourseDetailsReducer(state = selectedCourseDetailsInitialState, action) {
  switch (action.type) {
    case CourseActionTypes.SET_SELECTED_COURSE_ON_ONBOARDING:
      return { ...state, courseDetails: action.payload };
    case CourseActionTypes.SET_SELECTED_COURSE_BEFORE_PLACEMENT_TEST:
      return { ...state, courseDetails: action.payload };
    case CourseActionTypes.GET_SELECTED_COURSE_DETAILS:
      return {
        ...state,
        isGettingCourseDetails: true,
        hasErrorGettingCourseDetails: false,
        errorMessageGettingCourseDetails: ''
      };
    case CourseActionTypes.GET_SELECTED_COURSE_DETAILS_SUCCESS: {
      return {
        ...state,
        isGettingCourseDetails: false,
        courseDetails: {
          ...action.payload,
          isTimeForExam: isTimeForExam(action.payload),
          hasCompletedPracticeExam: hasCompletedExam(action.payload.practiceExam),
          hasCompletedFinalExam: hasCompletedExam(action.payload.finalExam),
          lastUnratedUnit: getLastUnratedUnit(action.payload?.units)
        },
        hasErrorGettingCourseDetails: false,
        errorMessageGettingCourseDetails: ''
      };
    }
    case CourseActionTypes.GET_SELECTED_COURSE_DETAILS_FAIL:
      return {
        ...state,
        isGettingCourseDetails: false,
        hasErrorGettingCourseDetails: true,
        errorMessageGettingCourseDetails: action.payload
      };
    case LessonsActionTypes.GET_UNITS_LIVE_PROGRESS_SUCCESS:
      return {
        ...state,
        courseDetails: {
          ...state.courseDetails,
          units: updateUnitsProgress(state.courseDetails?.units, action.payload)
        }
      };
    case USER_LOGOUT:
      return selectedCourseDetailsInitialState;
    default:
      return state;
  }
}

const overallCourseProgressInitialState = {
  studentStatus: null,
  isLoadingStudentStatus: false,
  overallSelfStudyProgress: 0,
  isLoadingOverralSelfStudyProgress: false,
  totalStudyTime: 0,
  isLoadingTotalStudyTime: false
};

function overallCourseProgressReducer(state = overallCourseProgressInitialState, action) {
  switch (action.type) {
    case CourseActionTypes.GET_STUDENT_COURSE_STATUS_AND_TIME:
      return {
        ...state,
        isLoadingStudentStatus: true,
        isLoadingTotalStudyTime: true
      };
    case CourseActionTypes.GET_STUDENT_COURSE_STATUS_AND_TIME_SUCCESS:
      return {
        ...state,
        studentStatus: action.payload.student_status,
        totalStudyTime: action.payload.total_study_time,
        isLoadingStudentStatus: false,
        isLoadingTotalStudyTime: false
      };
    case CourseActionTypes.GET_STUDENT_COURSE_STATUS_AND_TIME_FAIL:
      return {
        ...state,
        isLoadingStudentStatus: false,
        isLoadingTotalStudyTime: false
      };
    case LessonsActionTypes.GET_UNITS_LIVE_PROGRESS:
      return {
        ...state,
        isLoadingOverralSelfStudyProgress: true
      };
    case LessonsActionTypes.GET_UNITS_LIVE_PROGRESS_SUCCESS:
      return {
        ...state,
        overallSelfStudyProgress: getOverallSelfStudyProgress(action.payload) || 0,
        isLoadingOverralSelfStudyProgress: false
      };
    case LessonsActionTypes.GET_UNITS_LIVE_PROGRESS_FAIL:
      return {
        ...state,
        isLoadingOverralSelfStudyProgress: false
      };
    case USER_LOGOUT:
      return overallCourseProgressInitialState;
    default:
      return state;
  }
}

export default combineReducers({
  enrolledCourses: enrolledCoursesReducer,
  selectedCourseDetails: selectedCourseDetailsReducer,
  overallCourseProgress: overallCourseProgressReducer
});
