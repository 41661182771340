import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoadingSpinner.module.scss';
import { ReactComponent as Spinner } from '../../../../assets/icons/spinner.svg';

const LoadingSpinner = ({ darkMode }) => (
  <div className={[styles.loadingWrapper, darkMode && styles.darkMode].join(' ')} data-testid="loader">
    <Spinner />
  </div>
);

LoadingSpinner.propTypes = {
  darkMode: PropTypes.bool
};

LoadingSpinner.defaultProps = {
  darkMode: false
};

export default LoadingSpinner;
