import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './OnboardingAnimation.module.scss';

import HugeChatterboxLogo from '../../assets/logos/chatterbox-logo_huge.svg';
import AnimatedDots from '../design-system/animated-dots/AnimatedDots';

const OnboardingAnimation = ({ isInvalidToken, shouldSkipAnimation }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const timeoutRef = useRef(null);

  const stepsLabel = [
    'Learning with Chatterbox makes a difference to refugees and other marginalised professionals',
    'Combining AI-powered self-study with 1:1 Live Practice',
    'Maximise your progress with optional quizzes and assessments',
    'Revolutionising language learning'
  ];

  useEffect(() => {
    if (!isInvalidToken) return moveToNextStep();
    clearTimeout(timeoutRef.current);
  }, [isInvalidToken]);

  const calculateClass = () => {
    const percent = ((currentStep + 1) / stepsLabel.length) * 100;
    if (percent <= 25) return null;
    if (percent < 50) return 'one-quarter';
    if (percent < 75) return 'two-quarters';
    if (percent < 100) return 'three-quarters';
    return 'full';
  };

  function moveToNextStep() {
    timeoutRef.current = setTimeout(function () {
      if (isInvalidToken) return;
      setCurrentStep((prevStep) => prevStep + 1);
      moveToNextStep();
    }, 4000);
  }

  if (shouldSkipAnimation) return null;

  return (
    <div className={[styles['animation-wrapper'], isInvalidToken && styles.disabled].join(' ')}>
      <div className={styles['logo-wrapper']}>
        <img className={styles['chatter-logo']} src={HugeChatterboxLogo} alt="" />
      </div>
      <div className={styles['content-wrapper']}>
        <h1 className={styles['title']} id="welcome-to-chatterbox">
          Welcome to Chatterbox!
        </h1>
        <p className={styles['description']}>
          {isInvalidToken ? 'Something is wrong with your link :/' : stepsLabel[currentStep]}
          {isInvalidToken ? null : currentStep < stepsLabel.length - 1 && <AnimatedDots />}
        </p>
        <div className={[styles['progress-bar'], styles[calculateClass()]].join(' ')} />
      </div>
    </div>
  );
};

OnboardingAnimation.propTypes = {
  isInvalidToken: PropTypes.bool,
  shouldSkipAnimation: PropTypes.bool
};

export default OnboardingAnimation;
