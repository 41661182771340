import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CardLoader from '../../loading/card/CardLoader';

import styles from './SimpleCard.module.scss';

const SimpleCard = ({ cardContent, disabled, isLoading, onClick, options, type }) => {
  const [openOptions] = useState(false);

  return (
    <button className={[styles.cardWrapper, styles[type], disabled && styles.disabled].join(' ')}>
      <div role="button" className={[styles.content, !onClick && styles.clickless].join(' ')} onClick={onClick && !openOptions ? onClick : undefined}>
        {isLoading ? <CardLoader rows={3} rowsColor="gray" /> : cardContent}
        {openOptions && (
          <div className={styles['options-container']}>
            {options && <div className={styles.buttonsWrapper}>{options.map((option) => option)}</div>}
          </div>
        )}
      </div>
    </button>
  );
};

SimpleCard.propTypes = {
  cardContent: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  options: PropTypes.array,
  title: PropTypes.string,
  type: PropTypes.string
};

SimpleCard.defaultProps = {
  disabled: false,
  isLoading: false,
  type: 'default'
};

export default SimpleCard;
