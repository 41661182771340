import * as Sentry from '@sentry/browser';
import SessionsAPI from '../services/sessions';
import { actionCreatorsFactory, asyncActionType, createActionTypes } from '../utils/action-helpers.js';
import { CLASS_TYPES } from '../components/new-booking/Enum';
import { convertStringsOfDateAndTimeToDate } from '../utils/time-helpers';
import { isBefore } from 'date-fns';

const sentryContext = Object.freeze({
  tags: {
    context: 'Sessions action'
  }
});

export const SessionsActionTypes = createActionTypes('Sessions', [
  'SET_NEXT_SESSION_HAPPENING',
  asyncActionType('GET_SESSIONS_STATUS'),
  asyncActionType('GET_INTRO_CLASS')
]);

export const getSessionsStatusActions = actionCreatorsFactory(
  [SessionsActionTypes.GET_SESSIONS_STATUS, SessionsActionTypes.GET_SESSIONS_STATUS_SUCCESS, SessionsActionTypes.GET_SESSIONS_STATUS_FAIL],
  'getSessionsStatus'
);

export function getSessionsStatus(token, courseId, callback) {
  return async (dispatch) => {
    try {
      dispatch(getSessionsStatusActions.getSessionsStatus());
      const { data } = await SessionsAPI.getSessionsStatus(token, courseId);
      const tuitionSessionsStatus = selectSessionsWithStatus(data?.tuition_lessons, CLASS_TYPES.tuition);
      const conversationClassesStatus = selectSessionsWithStatus(data?.conversation_classes, CLASS_TYPES.conversation);
      dispatch(getSessionsStatusActions.getSessionsStatusSuccess({ ...data, tuitionSessionsStatus, conversationClassesStatus }));
      if (callback) callback(data);
    } catch (error) {
      Sentry.captureException(error, sentryContext);
      dispatch(getSessionsStatusActions.getSessionsStatusFail(error.message));
    }
  };
}

export const setNextSessionHappeningActions = actionCreatorsFactory([SessionsActionTypes.SET_NEXT_SESSION_HAPPENING], 'setNextSessionHappening');

export const getIntroClassActions = actionCreatorsFactory(
  [SessionsActionTypes.GET_INTRO_CLASS, SessionsActionTypes.GET_INTRO_CLASS_SUCCESS, SessionsActionTypes.GET_INTRO_CLASS_FAIL],
  'getIntroClass'
);

export const getIntroClass = ({ token, languageId }) => {
  return async (dispatch) => {
    try {
      dispatch(getIntroClassActions.getIntroClass());
      if (!token || !languageId) return;
      const { data } = await SessionsAPI.getIntroClass(token, languageId);
      dispatch(getIntroClassActions.getIntroClassSuccess(data));
    } catch (err) {
      const errorMsg = err.message || 'Something went wrong while getting your Meet Your Coach session appointment';
      dispatch(getIntroClassActions.getIntroClassFail(errorMsg));
    }
  };
};

export const selectSessionsWithStatus = (lessons, lessonType) => {
  const initialStatus = {
    notBooked: [],
    booked: [],
    happened: []
  };

  if (!lessons?.length) return initialStatus;
  return lessons
    .sort((firstEl, secondEl) => {
      const firstDate = convertStringsOfDateAndTimeToDate(firstEl?.appointment_object?.date, firstEl?.appointment_object?.start_time);
      const secondDate = convertStringsOfDateAndTimeToDate(secondEl?.appointment_object?.date, secondEl?.appointment_object?.start_time);
      return isBefore(firstDate, secondDate);
    })
    .reduce((acc, lesson) => {
      if (lesson?.booking_state === 'not-booked')
        return {
          ...acc,
          notBooked: [...acc.notBooked, { ...lesson, type: lessonType }]
        };

      if (lesson?.booking_state === 'booked')
        return {
          ...acc,
          booked: [...acc.booked, { ...lesson, type: lessonType }]
        };

      if (lesson?.booking_state === 'happened')
        return {
          ...acc,
          happened: [...acc.happened, { ...lesson, type: lessonType }]
        };

      return acc;
    }, initialStatus);
};
