import instance from '..';

export default class SessionsAPI {
  static async getSessionsStatus(token, courseId) {
    return instance.get(`/api/courses/${courseId}/lesson_status/`, {
      headers: { Authorization: `Token ${token}` }
    });
  }
  static async getIntroClass(token, languageId) {
    return instance.get('/api/intro_class', {
      headers: { Authorization: `Token ${token}` },
      params: { language: languageId }
    });
  }
}
