import axios from 'axios';
import { toast } from 'react-toastify';

import { isTokenExpired } from './common';

export default function rateAppointment(token, appointment, isPositive, selectedButton, extraText, errorCallback = null, successCallback = null) {
  return async (dispatch) => {
    const values = {
      is_positive: isPositive,
      selected_button: selectedButton,
      extra_text: extraText
    };
    try {
      const newAppointment = appointment;
      newAppointment.rating_value = isPositive ? 'Positive' : 'Negative';
      newAppointment.rating_selection = selectedButton;
      await axios.post(`/api/appointments/${appointment.id}/rate/`, values, {
        headers: { Authorization: `Token ${token}` }
      });
      if (successCallback) {
        successCallback(newAppointment);
      } else {
        toast.success('Thank you for rating your session!', {
          toastId: 'rating_success'
        });
      }
    } catch (err) {
      if (!isTokenExpired(dispatch, err) && errorCallback) {
        if (errorCallback) {
          errorCallback();
        } else {
          toast.error('There was a problem in storing your rating.', {
            toastId: 'error_rating'
          });
        }
      }
    }
  };
}
