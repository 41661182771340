import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Modal from '../design-system/modal/Modal';
import styles from './RatingModal.module.scss';
import IconThumbUp from '../../assets/icons/icon_thumb-up.svg';
import Button from '../design-system/button/Button';
import rateAppointment from '../../actions/ratings';
import { getAppointmentToRate } from '../../actions/appointments';
import { convertStringsOfDateAndTimeToDate, convertToLocaleAndFormat } from '../../utils/time-helpers';

export const CHOICES_POSITIVE = ['The activities', 'My coach was great', 'I am making progress', 'I was well prepared', 'Everything!'];

export const CHOICES_NEGATIVE = ['The activities', 'My coach', 'The tech', 'I was not prepared enough', 'My class did not happen'];

const RatingModal = ({
  appointmentToRateId,
  appointmentToRate,
  onCloseModal,
  rateAppointmentAction,
  getAppointmentToRateAction,
  lastUnratedUnit,
  setIsBlockedHowWasYourClass,
  student,
  timezone
}) => {
  const [isPositive, setIsPositive] = useState(null);
  const [text, setText] = useState('');
  const [selectedMotive, setSelectedMotive] = useState('');
  const hasAppointmentToRate = appointmentToRateId !== undefined && appointmentToRateId !== '';

  if (!lastUnratedUnit && !hasAppointmentToRate) return null;

  useEffect(() => {
    if (hasAppointmentToRate) {
      getAppointmentToRateAction(student.token, appointmentToRateId, onCloseModal);
    }
  }, [appointmentToRateId]);

  const handleUserTyping = (evt) => {
    if (!evt?.target) return;
    const { value } = evt.target;
    setText(value);
  };

  const sendReview = ({ isPositive, selectedMotive, text }) => {
    const appointmentToRateObject = hasAppointmentToRate ? appointmentToRate : lastUnratedUnit?.appointment;
    rateAppointmentAction(student.token, appointmentToRateObject, isPositive, selectedMotive, text);
    onCloseModal();
    setIsBlockedHowWasYourClass(true);
  };

  const handleSendReview = () => {
    sendReview({ isPositive, text, selectedMotive });
  };

  const renderContent = () => {
    if (isPositive)
      return (
        <div className={styles['modal-wrapper']}>
          <h2 className={styles['modal-title']}>We’re happy to hear that! What went particularly well?</h2>
          <p className={styles['modal-subtitle']}>This will only be visible to the internal Chatterbox team to improve your experience.</p>
          <div className={styles['choices-wrapper']}>
            {CHOICES_POSITIVE.map((choice, index) => (
              <button
                key={index}
                className={[styles['choice'], selectedMotive === choice && styles['selected']].join(' ')}
                onClick={() => setSelectedMotive(choice)}>
                {choice}
              </button>
            ))}
          </div>
          <textarea
            className={styles.textarea}
            rows={3}
            value={text}
            onChange={handleUserTyping}
            placeholder="In what way was your session positive?"
          />
          <div className={styles['modal-buttons-wrapper']}>
            <Button outfit="lightGrayButton" onClick={onCloseModal}>
              Close
            </Button>
            <Button onClick={handleSendReview} disabled={!selectedMotive}>
              Send
            </Button>
          </div>
        </div>
      );

    if (typeof isPositive === 'boolean' && !isPositive)
      return (
        <div className={styles['modal-wrapper']}>
          <h2 className={styles['modal-title']}>We’re sorry to hear that! What was the issue?</h2>
          <p className={styles['modal-subtitle']}>This will only be visible to the internal Chatterbox team to improve your experience.</p>
          <div className={styles['choices-wrapper']}>
            {CHOICES_NEGATIVE.map((choice, index) => (
              <button
                key={index}
                className={[styles['choice'], selectedMotive === choice && styles['selected']].join(' ')}
                onClick={() => setSelectedMotive(choice)}>
                {choice}
              </button>
            ))}
          </div>
          <textarea
            className={styles.textarea}
            rows={3}
            value={text}
            onChange={handleUserTyping}
            placeholder="In what way was your session negative?"
          />
          <div className={styles['modal-buttons-wrapper']}>
            <Button outfit="lightGrayButton" onClick={onCloseModal}>
              Close
            </Button>
            <Button onClick={handleSendReview} disabled={!selectedMotive}>
              Send
            </Button>
          </div>
        </div>
      );

    const buildModalTitle = () => {
      if (!lastUnratedUnit && !hasAppointmentToRate) return '';
      const appt = hasAppointmentToRate ? appointmentToRate : lastUnratedUnit?.appointment;
      if (appt?.tutor_name && appt?.date) {
        const appointmentName = hasAppointmentToRate ? appt?.lesson_name : lastUnratedUnit?.name;
        return `How was your Live Practice ("${appointmentName}") with ${appt?.tutor_name} on
        ${convertToLocaleAndFormat(convertStringsOfDateAndTimeToDate(appt?.date, appt?.start_time), timezone, 'do MMM')}?`;
      }
      return `How was your last Live Practice ("${lastUnratedUnit?.name}")?`;
    };

    return (
      <div className={styles['modal-wrapper']}>
        <h2 className={styles['modal-title']}>{buildModalTitle()}</h2>
        <p className={styles['modal-subtitle']}>Reflecting directly after a session will boost your learning.</p>
        <div className={styles['buttons-wrapper']}>
          <button className={styles['thumb-down']} onClick={() => setIsPositive(false)}>
            <img alt="thumb-up" src={IconThumbUp} />
          </button>
          <button className={styles['thumb-up']} onClick={() => setIsPositive(true)}>
            <img alt="thumb-up" src={IconThumbUp} />
          </button>
        </div>
      </div>
    );
  };

  return <Modal onClose={onCloseModal} content={renderContent()} />;
};

const mapStateToProps = (state) => {
  return {
    student: state.user,
    lastUnratedUnit: state.courses.selectedCourseDetails.courseDetails?.lastUnratedUnit,
    timezone: state.time.timezone,
    appointmentToRate: state.appointmentToRate
  };
};

RatingModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  rateAppointmentAction: PropTypes.func.isRequired,
  getAppointmentToRateAction: PropTypes.func.isRequired,
  lastUnratedUnit: PropTypes.object,
  setIsBlockedHowWasYourClass: PropTypes.bool,
  student: PropTypes.object,
  timezone: PropTypes.string,
  appointmentToRate: PropTypes.object,
  appointmentToRateId: PropTypes.string
};

export default connect(mapStateToProps, {
  rateAppointmentAction: rateAppointment,
  getAppointmentToRateAction: getAppointmentToRate
})(RatingModal);
