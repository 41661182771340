import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MessageBox } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';

const FileMessage = ({ className, date, id, media, position, title, titleColor, type }) => {
  const [uri, setUri] = useState('');

  useEffect(() => {
    (async () => getFileUri(media))();
  }, [media]);

  const getFileUri = async (media) => {
    if (!media) return;
    const URI = await media.getContentTemporaryUrl();
    setUri(URI);
  };

  const onDownload = () => {
    window.open(uri, '_blank');
  };

  return (
    <MessageBox
      className={className}
      id={id}
      date={date}
      data={uri}
      onClick={onDownload}
      position={position}
      text={media?.filename}
      title={title}
      titleColor={titleColor}
      type={type}
    />
  );
};

FileMessage.propTypes = {
  className: PropTypes.string,
  customRef: PropTypes.object,
  date: PropTypes.instanceOf(Date).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  media: PropTypes.object,
  position: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default FileMessage;
