import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown, MenuItem, Button } from 'react-bootstrap';

import Mixpanel from '../../utils/mixpanel-helper.js';
import styles from '../../style/containers/Onboarding.module.scss';

class StepLanguage extends Component {
  state = {
    selectDialects: [false],
    numberOfExtraLanguages: 0
  };

  componentDidMount() {
    const { user, selectedLanguages, languages } = this.props;
    Mixpanel.identify(user.id);
    Mixpanel.track('Visited onboarding step language');
    if (selectedLanguages[0]) {
      if (languages[selectedLanguages[0]].dialects !== undefined && languages[selectedLanguages[0]].dialects.length > 0) {
        this.setDialectSelect(0);
      }
    }
  }

  onSelectLanguageDropdown(languageIndex, index) {
    const { languages, onSelectLanguage, onSelectDialect } = this.props;
    const { selectDialects } = this.state;
    let selectDialect = false;

    onSelectLanguage(index, languageIndex);

    if (languages[index].dialects !== undefined && languages[index].dialects.length !== 0) {
      selectDialect = true;
    }

    onSelectDialect(-1, languageIndex);

    if (selectDialects[languageIndex] === undefined) {
      selectDialects.push(selectDialect);
    } else {
      selectDialects[languageIndex] = selectDialect;
    }

    this.setState({ selectDialects });
  }

  onSelectDialectDropdown(languageIndex, index) {
    const { onSelectDialect } = this.props;
    onSelectDialect(index, languageIndex);
  }

  setDialectSelect(index) {
    const { languages, selectedLanguages } = this.props;
    const { selectDialects } = this.state;
    let selectDialect = false;
    if (languages[selectedLanguages[index]].dialects !== undefined && languages[selectedLanguages[index]].dialects.length > 0) {
      selectDialect = true;
    }

    if (selectDialects[index] === undefined) {
      selectDialects.push(selectDialect);
    } else {
      selectDialects[index] = selectDialect;
    }

    this.setState({ selectDialects });
  }

  addExtraLanguage() {
    this.setState({ numberOfExtraLanguages: this.state.numberOfExtraLanguages + 1 });
  }

  renderExtraLanguages() {
    const renderExtraLanguages = [];

    for (let i = 1; i <= this.state.numberOfExtraLanguages; i += 1) {
      renderExtraLanguages.push(this.renderExtraLanguage(i));
    }

    return renderExtraLanguages;
  }

  renderExtraLanguage(index) {
    const { selectDialects } = this.state;
    const { languages, selectedLanguages, selectedDialects } = this.props;

    return (
      <div className={styles.extraLanguageWrapper}>
        <Dropdown title="Extra small button" onSelect={this.onSelectLanguageDropdown.bind(this, index)} className={styles.languageDropdownWrapper}>
          <Dropdown.Toggle
            className={styles.languageDropdown}
            onClick={() => {
              Mixpanel.track('Click', {
                position: 'StepLanguage',
                button: 'extra_language_dropdown'
              });
            }}>
            {selectedLanguages[index] === undefined ? 'Select an option' : languages[selectedLanguages[index]].language}
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.languageDropdown}>
            {languages.map((language, indexL) => (
              <MenuItem key={indexL} eventKey={indexL.toString()}>
                {language.language}
              </MenuItem>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {selectDialects[index] && (
          <div className={styles.dialectWrapper}>
            <Dropdown
              bsSize="xsmall"
              title="Extra small button"
              className={styles.languageDropdownWrapper}
              onSelect={this.onSelectDialectDropdown.bind(this, index)}>
              <Dropdown.Toggle
                className={styles.languageDropdown}
                onClick={() => {
                  Mixpanel.track('Click', {
                    position: 'StepLanguage',
                    button: 'extra_dialect_dropdown'
                  });
                }}>
                {selectedDialects[index] === undefined || selectedDialects[index] === -1
                  ? 'Select an option'
                  : languages[selectedLanguages[index]].dialects.sort((a, b) => {
                      if (a <= b) {
                        return -1;
                      }
                      return 1;
                    })[selectedDialects[index]]}
              </Dropdown.Toggle>
              <Dropdown.Menu className={styles.dropdownMenu}>
                {languages[selectedLanguages[index]].dialects
                  .sort((a, b) => {
                    if (a <= b) {
                      return -1;
                    }
                    return 1;
                  })
                  .map((dialect, indexD) => (
                    <MenuItem key={indexD} eventKey={indexD.toString()}>
                      {dialect}
                    </MenuItem>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { languages, nextFunction, backFunction, selectedLanguages, selectedDialects } = this.props;

    return (
      <div className={styles.stepWrapper}>
        <h1 className={styles.onboardingTitle}>Your language skills</h1>
        <h2 className={[styles.onboardingHeader, styles.onboardingText].join(' ')}>What is your native language?</h2>
        <Dropdown title="Extra small button" onSelect={this.onSelectLanguageDropdown.bind(this, 0)} className={styles.languageDropdownWrapper}>
          <Dropdown.Toggle
            className={styles.languageDropdown}
            onClick={() => {
              Mixpanel.track('Click', {
                position: 'StepLanguage',
                button: 'language_dropdown'
              });
            }}>
            {selectedLanguages[0] === undefined ? 'Select an option' : languages[selectedLanguages[0]].language}
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.languageDropdown}>
            {languages.map((language, index) => (
              <MenuItem key={index} eventKey={index.toString()}>
                {language.language} {language.native_language && `- ${language.native_language}`}
              </MenuItem>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        {this.state.selectDialects[0] && (
          <div className={styles.dialectWrapper}>
            <h2 className={styles.onboardingHeader}>Which dialect?</h2>
            <Dropdown
              bsSize="xsmall"
              title="Extra small button"
              className={styles.languageDropdownWrapper}
              onSelect={this.onSelectDialectDropdown.bind(this, 0)}>
              <Dropdown.Toggle
                className={styles.languageDropdown}
                onClick={() => {
                  Mixpanel.track('Click', {
                    position: 'StepLanguage',
                    button: 'language_dropdown'
                  });
                }}>
                {selectedDialects[0] === undefined || selectedDialects[0] === -1
                  ? 'Select an option'
                  : languages[selectedLanguages[0]].dialects.sort((a, b) => {
                      if (a <= b) {
                        return -1;
                      }
                      return 1;
                    })[selectedDialects[0]]}
              </Dropdown.Toggle>
              <Dropdown.Menu className={styles.dropdownMenu}>
                {languages[selectedLanguages[0]].dialects
                  .sort((a, b) => {
                    if (a <= b) {
                      return -1;
                    }
                    return 1;
                  })
                  .map((dialect, index) => (
                    <MenuItem key={index} eventKey={index.toString()}>
                      {dialect}
                    </MenuItem>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}

        {this.renderExtraLanguages()}

        <Button onClick={this.addExtraLanguage.bind(this)} className={styles.addLanguageButton}>
          + Add another language
        </Button>

        <div className={styles.buttonContainer}>
          <Button bsStyle="link" onClick={backFunction} className={styles.backButton}>
            Back
          </Button>
          <Button className={styles.nextButton} onClick={nextFunction} disabled={selectedLanguages[0] === undefined}>
            Next
          </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

StepLanguage.propTypes = {
  user: PropTypes.object.isRequired,
  selectedLanguages: PropTypes.array.isRequired,
  selectedDialects: PropTypes.array.isRequired,
  languages: PropTypes.array.isRequired,
  nextFunction: PropTypes.func.isRequired,
  backFunction: PropTypes.func.isRequired,
  onSelectLanguage: PropTypes.func.isRequired,
  onSelectDialect: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {})(StepLanguage);
