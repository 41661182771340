import React from 'react';
import PropTypes from 'prop-types';

import styles from './on-leaving-alert.module.scss';
import Modal from '../../../design-system/modal/Modal';
import Button from '../../../design-system/button/Button';

const OnLeavingAlert = ({ lessonsPreBooked, modalMainColor, onAccept, onDecline }) => {
  const handleClose = () => {
    onDecline();
  };

  return (
    <Modal
      noPadding
      completionProgress={100}
      onClose={handleClose}
      modalMainColor={modalMainColor}
      content={
        <div className={styles.modalWrapper}>
          <div className={styles.modalSection}>
            <h1>Do you really want to close?</h1>
            <p>
              You are about to leave <strong>without booking the {lessonsPreBooked} session(s)</strong> you selected.
            </p>
            <p>To finish booking your session(s), click Cancel, then Book.</p>
            <div className={styles.buttonsWrapper}>
              <Button outfit="secondaryButton" onClick={handleClose}>
                Cancel
              </Button>
              <Button outfit="greenButton" onClick={onAccept}>
                Close it anyway
              </Button>
            </div>
          </div>
        </div>
      }
    />
  );
};

OnLeavingAlert.propTypes = {
  lessonsPreBooked: PropTypes.string,
  modalMainColor: PropTypes.string,
  onAccept: PropTypes.func,
  onDecline: PropTypes.func
};

export default OnLeavingAlert;
