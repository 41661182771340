import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as InfoCircleIcon } from '../../../assets/icons/icon_info.svg'; // Adjust the path to your icon
import styles from '../Header.module.scss';

const Header = ({ columns, stickyColumnClass }) => {
  return (
    <tr className={styles.header}>
      {Object.keys(columns).map((col, index) => (
        <th
          key={`header-${index}`}
          className={`${styles.cell} ${index === 0 ? stickyColumnClass : ''}`} // Apply sticky class to the first column
        >
          <div className={styles.headerContent}>
            <span>{columns[col].label}</span>
            {columns[col].tooltipText && (
              <div className={styles.tooltipWrapper}>
                <InfoCircleIcon className={styles.infoIcon} />
                <div className={styles.tooltip}>{columns[col].tooltipText}</div>
              </div>
            )}
          </div>
        </th>
      ))}
    </tr>
  );
};

Header.propTypes = {
  columns: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      tooltipText: PropTypes.string
    })
  ).isRequired,
  stickyColumnClass: PropTypes.string // For applying the sticky column style
};

export default Header;
