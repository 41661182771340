import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Ticket from './ticket';

const ListTickets = ({ userTickets }) => {
  const [expanded, setExpanded] = useState('');
  const [onlyOpenAndPending, setOnlyOpenAndPending] = useState([]);

  useEffect(() => {
    const filtered = filterOnlyOpenAndPending(userTickets);
    setOnlyOpenAndPending(filtered);
  }, [userTickets]);

  const filterOnlyOpenAndPending = (tickets) => {
    return tickets.filter((t) => t?.status === 2 || t?.status === 3);
  };

  return (
    <div>
      {onlyOpenAndPending?.map((ut) => {
        return <Ticket expanded={expanded} setExpanded={setExpanded} key={ut?.id} id={ut?.id} subject={ut?.subject} status={ut?.status} />;
      })}
    </div>
  );
};

ListTickets.propTypes = {
  userTickets: PropTypes.array
};

export default ListTickets;
