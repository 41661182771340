import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as typeformEmbed from '@typeform/embed';

import styles from '../../style/containers/TestPage.module.scss';

let observer;

const TestTypeform = ({ nextPage }) => {
  const typeformCode = 'TqnhOz3O';

  observer = new MutationObserver(() => {
    const embedElement = document.getElementById('typeformContainer');
    if (embedElement) {
      if (observer) {
        observer.disconnect();
        observer = undefined;
      }

      typeformEmbed.createWidget(typeformCode, {
        hideHeaders: true,
        hideFooter: true,
        opacity: 100,
        autoClose: 0,
        onSubmit: nextPage,
        container: embedElement
      });
    }
  });
  observer.observe(document, { subtree: true, childList: true });

  return (
    <>
      <div id="typeformContainer" className={styles.typeformContainer} />
    </>
  );
};

TestTypeform.propTypes = {
  nextPage: PropTypes.func.isRequired
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(TestTypeform);
