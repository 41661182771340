import { combineReducers } from 'redux';
import { UNIT_COMPLETED_STATUS } from '../enums/lessons';
import { SessionsActionTypes } from '../actions/sessions';
import { USER_LOGOUT } from '../actions/types';
import { convertStringsOfDateAndTimeToDate } from '../utils/time-helpers';

const nextSessionHappeningInitialState = {
  errorMessageSettingNextLessonHappening: '',
  hasErrorSettingNextSessionHappening: false,
  nextSessionHappening: {}
};

function nextSessionHappeningReducer(state = nextSessionHappeningInitialState, action) {
  switch (action.type) {
    case SessionsActionTypes.SET_NEXT_SESSION_HAPPENING:
      return {
        ...state,
        nextSessionHappening: action.payload,
        hasErrorSettingNextSessionHappening: false
      };
    case USER_LOGOUT:
      return nextSessionHappeningInitialState;
    default:
      return state;
  }
}

const conversationSessionInitialState = {
  allowConversationClasses: false,
  error: null,
  currentLessonsStatus: {
    notBooked: [],
    booked: [],
    happened: []
  },
  conversationsLeft: 0,
  isLoadingLessonsStatus: false
};

function conversationSessionReducer(state = conversationSessionInitialState, action) {
  switch (action.type) {
    case SessionsActionTypes.GET_SESSIONS_STATUS:
      return { ...state, isLoadingLessonsStatus: true };
    case SessionsActionTypes.GET_SESSIONS_STATUS_SUCCESS: {
      return {
        ...state,
        allowConversationClasses: action.payload?.allow_conversation,
        currentLessonsStatus: { ...action.payload?.conversationClassesStatus },
        conversationsLeft: action.payload?.conversations_left,
        error: null,
        isLoadingLessonsStatus: false
      };
    }
    case SessionsActionTypes.GET_SESSIONS_STATUS_FAIL:
      return { ...state, error: action.payload, isLoadingLessonsStatus: false };
    default:
      return state;
  }
}

const simplifyIntroSessionStatus = (introClass) => {
  if (!introClass?.status) return introClass;
  if (Object.values(UNIT_COMPLETED_STATUS).includes(introClass.status)) return { ...introClass, status: UNIT_COMPLETED_STATUS.HAPPENED };
  return introClass;
};

export const selectBookedDates = (state) => {
  const booked = state?.sessions?.tuitionSessions?.currentLessonsStatus?.booked;
  if (!booked?.length) return [];
  return booked
    .map((b) => {
      return convertStringsOfDateAndTimeToDate(b?.appointment_object?.date, b?.appointment_object?.start_time);
    })
    .filter((b) => b);
};

const tuitionLessonsInitialState = {
  currentLessonsStatus: {
    notBooked: [],
    booked: [],
    happened: []
  },
  isLoadingLessonsStatus: false,
  error: null
};

function tuitionSessionsReducer(state = tuitionLessonsInitialState, action) {
  switch (action.type) {
    case SessionsActionTypes.GET_SESSIONS_STATUS:
      return { ...state, isLoadingLessonsStatus: true };
    case SessionsActionTypes.GET_SESSIONS_STATUS_SUCCESS: {
      const orderedNotBooked = action.payload?.tuitionSessionsStatus?.notBooked.sort((a, b) => {
        return a.number - b.number;
      });
      return {
        ...state,
        currentLessonsStatus: {
          ...action.payload?.tuitionSessionsStatus,
          notBooked: orderedNotBooked
        },
        error: null,
        isLoadingLessonsStatus: false
      };
    }
    case SessionsActionTypes.GET_SESSIONS_STATUS_FAIL:
      return { ...state, error: action.payload, isLoadingLessonsStatus: false };
    default:
      return state;
  }
}

const introClassInitialState = {
  introClass: {},
  isGettingIntroClass: false,
  hasErrorGettingIntroClass: false,
  errorGettingIntroClass: ''
};

function introSessionReducer(state = introClassInitialState, action) {
  switch (action.type) {
    case SessionsActionTypes.GET_INTRO_CLASS:
      return {
        ...state,
        isGettingIntroClass: true,
        hasErrorGettingIntroClass: false,
        errorGettingIntroClass: ''
      };
    case SessionsActionTypes.GET_INTRO_CLASS_SUCCESS:
      return {
        ...state,
        isGettingIntroClass: false,
        hasErrorGettingIntroClass: false,
        errorGettingIntroClass: '',
        introClass: simplifyIntroSessionStatus(action.payload)
      };
    case SessionsActionTypes.GET_INTRO_CLASS_FAIL:
      return {
        ...state,
        isGettingIntroClass: false,
        hasErrorGettingIntroClass: true,
        errorGettingIntroClass: action.payload,
        introClass: {}
      };

    case USER_LOGOUT:
      return introClassInitialState;
    default:
      return state;
  }
}

export default combineReducers({
  introSession: introSessionReducer,
  conversationSessions: conversationSessionReducer,
  tuitionSessions: tuitionSessionsReducer,
  nextSessionHappening: nextSessionHappeningReducer
});
