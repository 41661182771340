import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import styles from '../style/containers/TuitionExplanation.module.scss';
import ExplanationStep from '../components/tuition-explanation/ExplanationStep.js';

import LaptopMohammed from '../assets/banners/laptop_mohammad.png';
import LaptopCerego from '../assets/banners/laptop_cerego.png';
import LaptopVideocall from '../assets/banners/laptop_videocall.png';
import LaptopExpertChat from '../assets/banners/laptop_expert-chat.png';
import LaptopTest from '../assets/banners/laptop_test.png';

class TuitionExplanation extends Component {
  bookSessionClick() {
    this.props.history.push('/');
  }

  studyAreaClick() {
    this.props.history.push('/study-area/');
  }

  render() {
    return (
      <div className={styles.outerWrapper}>
        <h1 className={styles.headerContent}>How Chatterbox tuition courses work</h1>
        <p className={styles.headerContent}>
          <b>Chatterbox Tuition Courses</b> allow you to master a new language in record time.
        </p>
        <p className={styles.headerContent}>
          By using state-of-the-art technology and building powerful human connections, you will stay motivated to learn for longer and feel confident
          to apply your new language skills in the real world in weeks, not months.
        </p>
        <p className={styles.headerContent}>
          Our Tuition Courses are based on 3 elements: Independent study using <b>AI-powered course materials</b>, live video practice classes with
          native-speaking <b>coaches</b>, and 24h support from a <b>qualified teacher</b>.
        </p>
        <p className={styles.headerContent}>To start learning on our platform, follow these easy steps:</p>
        <div className={styles.spacer} />
        <ExplanationStep
          title={`Step 1 - Book Your session`}
          subtitle={`After finding your ideal coach during the signup process,
            immediately book your first session with them using the Booking page.
            Your coach is there to keep you accountable, to build your confidence, and to personalise your learning experience.`}
          bulletPoints={[
            `Booking multiple sessions saves you time - since you won't have to keep navigating back to the Booking page to schedule a session each time.`,
            `If you don’t book multiple sessions, you will need to keep navigating back to the booking page each week to schedule your next session.`,
            `IMPORTANT: Make sure to book each Live Practice at least 5 to 7 days in advance to
            ensure you have enough time to complete the required prior independent
            learning material (explained below).`
          ]}
          img={LaptopMohammed}
        />
        <ExplanationStep
          title="Step 2 - Study The Independent Learning Material"
          subtitle={`After booking a session, you will unlock 2-3 hours of learning materials to study independently before each
          Live Practice. You will find this in your study area.
          These online units will help you to memorise new vocabulary and grammar rules faster and for longer using Artificial Intelligence.
          Alongside exercises, you will find lesson summaries (to help you revise) and a document with activities to prepare for your session.`}
          bulletPoints={[
            `IMPORTANT: Learning materials will only become available in your study area after
            you book your session! This will help you stay on track to gain our
            language qualification.`,
            `You need to complete all the units of self-study before each session.`,
            `Studies show that 30 minutes of daily study is significantly better than studying
            for longer. Schedule a recurring daily appointment in your calendar to easily make
            time for this during the week.`
          ]}
          img={LaptopCerego}
          leftImg
          whiteBg
        />
        <ExplanationStep
          title={`Step 3 - Practise Your New Skills With Your coach!`}
          subtitle={`Then comes the exciting part - test your skills in a confidence-boosting session!
          Our amazing native-speaking $coaches will take the lead in these sessions with carefully prepared activities suited to your level.`}
          bulletPoints={[
            'Join the Video Classroom from your Booking page.',
            `Like your other meetings, it’s better to be early than late. You can join the classroom as much as 15 minutes before the session.`
          ]}
          img={LaptopVideocall}
        />
        <ExplanationStep
          title="Step 4 - Ask Your Personal Language Teacher For Help and Tips"
          subtitle={`If you get stuck during independent study or need further clarification after a session, you can always contact the Language Teacher*.
          Our Language Teachers are qualified language tutors who will assist you from the chat section of the platform.`}
          bulletPoints={[
            `Ask them questions about grammar, phrases, exercises, sessions, or anything else from the Chat Area.`,
            'The Language Teacher will get back to you within 24 hours during week-days.'
          ]}
          img={LaptopExpertChat}
          extra="* Please note that this service is currently only available for some languages."
          leftImg
          whiteBg
        />
        <ExplanationStep
          title="Step 5 - Test Your Knowledge"
          subtitle="After every 5 lessons, you will be able to take a mid-course test to evaluate how much you’ve learned."
          bulletPoints={[
            'Each mid-course test will tell you whether you have learnt enough or if you need more practice to qualify in the final test.',
            'The test is for your own benefit: we can help you better if we know your strengths and weaknesses!',
            `After 20 lessons, you will be able to tackle the big boss - our CEFR End of Level Test.
            This will assess and certify your language skills within a globally recognised framework
            and tell you whether you have moved up a new level in a language.`,
            'With our standard package, gaining a CEFR qualification takes 6 months.'
          ]}
          img={LaptopTest}
        />
        <div className={styles.footer}>
          <h2>
            <b>Ready to start?</b>
          </h2>
          <Button onClick={this.bookSessionClick.bind(this)} className={styles.nextButton}>
            Book your session
          </Button>
          <Button onClick={this.studyAreaClick.bind(this)} className={styles.otherButton}>
            Go to your Study Area
          </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

TuitionExplanation.propTypes = {
  history: PropTypes.object.isRequired
};

TuitionExplanation.defaultProps = {};

export default connect(mapStateToProps, {})(TuitionExplanation);
