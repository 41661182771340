import axios from 'axios';
import { TUTOR_STUDENTS_REQUEST, TUTOR_STUDENTS_SUCCESS, TUTOR_STUDENTS_FAILURE, RESET_TUTOR_STUDENTS } from './types';

// Action Types
export const fetchTutorStudentsRequest = () => ({
  type: TUTOR_STUDENTS_REQUEST
});

export const fetchTutorStudentsSuccess = (data) => ({
  type: TUTOR_STUDENTS_SUCCESS,
  payload: data
});

export const fetchTutorStudentsFailure = (error) => ({
  type: TUTOR_STUDENTS_FAILURE,
  payload: error
});

export const resetTutorStudents = () => ({
  type: RESET_TUTOR_STUDENTS
});

// Fetch Tutor Students
export const fetchTutorStudents =
  ({ token, tutorId }) =>
  async (dispatch) => {
    dispatch({ type: TUTOR_STUDENTS_REQUEST });
    try {
      const response = await axios.get(`/api/students/?tutor=${tutorId}`, {
        headers: { Authorization: `Token ${token}` }
      });

      // Combine first_name and last_name into learner_name
      const transformedData = response.data.map((student) => ({
        ...student,
        learner_name: `${student.first_name} ${student.last_name}`
      }));

      dispatch({ type: TUTOR_STUDENTS_SUCCESS, payload: transformedData });
    } catch (error) {
      dispatch({ type: TUTOR_STUDENTS_FAILURE, payload: error.message });
    }
  };
