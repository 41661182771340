import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Test from './Test';
import WelcomeToPlacementTest from './WelcomeToPlacementTest';

import styles from './PlacementTest.module.scss';
import { connect } from 'react-redux';
import LoadingSpinner from '../../design-system/loading/spinner/LoadingSpinner';
import ErrorToast from '../../design-system/toast/errorToast/ErrorToast';

const PlacementTest = ({ hasRetrievePlacementTestError, isRetrievingPlacementTest, retrievePlacementTestError }) => {
  const [start, setStart] = useState(false);

  const handleStart = () => {
    setStart(true);
  };

  return (
    <div className={styles['placement-test-wrapper']} data-testid="placement-test-wrapper">
      <ErrorToast errorMsg={retrievePlacementTestError} show={hasRetrievePlacementTestError} />
      {isRetrievingPlacementTest ? (
        <div className={styles.loadingWrapper}>
          <LoadingSpinner />
        </div>
      ) : start ? (
        <Test />
      ) : (
        <WelcomeToPlacementTest handleStart={handleStart} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  hasRetrievePlacementTestError: state.newOnboarding.placementTest.hasRetrievePlacementTestError,
  isRetrievingPlacementTest: state.newOnboarding.placementTest.isRetrievingPlacementTest,
  retrievePlacementTestError: state.newOnboarding.placementTest.retrievePlacementTestError
});

PlacementTest.propTypes = {
  hasRetrievePlacementTestError: PropTypes.bool.isRequired,
  isRetrievingPlacementTest: PropTypes.bool.isRequired,
  retrievePlacementTestError: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(PlacementTest);
