import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './SuccessToast.module.scss';

const SuccessToast = ({ msg, onClose, show, testId }) => {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    setShowToast(show);
  }, [show]);

  const handleClose = () => {
    if (onClose) onClose();
    setShowToast(false);
  };

  if (!showToast) return null;

  return (
    <div className={styles.successWrapper} data-testid={testId}>
      <div className={styles.messageWrapper}>
        <p data-testid={`${testId}-message`}>{msg}</p>
        <button onClick={handleClose}>X</button>
      </div>
    </div>
  );
};

SuccessToast.propTypes = {
  msg: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  testId: PropTypes.string
};

SuccessToast.defaultProps = {
  onClose: undefined,
  show: false
};

export default SuccessToast;
