import axios from 'axios';

import { isTokenExpired } from './common';
import { SEND_CHAT_CODE } from './types';

function sendChatCodeSuccess() {
  return { type: SEND_CHAT_CODE };
}

export function sendChatCode(token, code, errorCallback, successCallback) {
  const data = { chat_code: code };
  const headers = { Authorization: `Token ${token}` };

  return async (dispatch) => {
    try {
      const response = await axios.post('/api/chat_code/', data, { headers });
      dispatch(sendChatCodeSuccess());

      if (typeof successCallback === 'function') {
        successCallback(response);
      }
    } catch (error) {
      if (!isTokenExpired(dispatch, error)) {
        if (typeof errorCallback === 'function') {
          errorCallback(error);
        }
      }
    }
  };
}
