import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from '../../style/components/uikit/SelectButton.module.scss';

const propTypes = {
  buttonContent: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

const SelectButton = ({ buttonContent, selected, onSelect, disabled }) => {
  const [isActive, setIsActive] = useState(selected);

  useEffect(() => {
    setIsActive(selected);
  }, [selected]);

  const handleUserClick = () => {
    onSelect();
    setIsActive((prevState) => !prevState);
  };

  return (
    <button
      type="button"
      className={`${styles.selectButton} ${isActive && styles['selectButton--active']}`}
      onClick={handleUserClick}
      disabled={disabled}>
      {buttonContent}
    </button>
  );
};

SelectButton.propTypes = propTypes;

export default SelectButton;
