import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import Coaches from '../components/pick-your-coach/Coaches/Coaches';
import Information from '../components/pick-your-coach/Information/Information';
import ChangeCoachModal from '../components/new-booking/components/changingCoach/ChangeCoachModal';

import styles from '../style/containers/PickYourCoach.module.scss';

import { ReactComponent as IconLeftArrow } from '../assets/icons/icon_left-arrow.svg';

const PickYourCoach = ({ openChangeCoachModal }) => {
  const informationRef = useRef(null);
  const history = useHistory();

  return (
    <div className={styles.wrapper}>
      <button className={styles['back']} onClick={() => history.goBack()}>
        <IconLeftArrow /> <span>Back</span>
      </button>
      <Coaches informationRef={informationRef} />
      <Information componentRef={informationRef} />
      {openChangeCoachModal && <ChangeCoachModal redirectTo="/bookings" />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  openChangeCoachModal: state.changeCoach.openChangeCoachModal
});

PickYourCoach.propTypes = {
  openChangeCoachModal: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(PickYourCoach);
