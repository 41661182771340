import axios from 'axios';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { getQueryParamValue } from '../utils/url-helpers.js';

class ConfirmAttendance extends Component {
  UNSAFE_componentWillMount() {
    const { match, user } = this.props;

    // Retrieve attendance and appointemnt ID from URL
    const happened = getQueryParamValue('happened');
    const headers = { Authorization: `Token ${user.token}` };
    const context = {
      appointmentId: match.params.appointmentID,
      happened
    };
    // Store attendance
    axios
      .put('/api/confirm-attendance/', context, { headers })
      .then(() => {
        // Show a toast to inform users we successfully stored the answer
        toast.success('Thank you for letting us know!');
      })
      .catch((e) => {
        // Show a toast to inform users there was an error
        toast.error(e.message);
      });

    this.props.history.push('/');
  }

  render() {
    return <></>;
  }
}

ConfirmAttendance.propTypes = {
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

ConfirmAttendance.defaultProps = {};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps, {})(ConfirmAttendance);
