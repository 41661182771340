import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import platform from 'platform';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import styles from '../style/containers/StudyArea.module.scss';
import mixpanelHelper from '../utils/mixpanel-helper';
import { setUGWalkthroughSeen } from '../actions/user';
import { initChangeCoachProcess } from '../actions/change-coach';
import SuccessToast from '../components/design-system/toast/successToast/SuccessToast';
import NewStudyAreaWeb from '../components/study-area/ContainerWeb';
import NewStudyAreaMobile from '../components/study-area/ContainerMobile';
import { getOnboardingData } from '../actions/new-onboarding';
import { getEnrolledCourses } from '../actions/courses';
import { cleanSuccess } from '../actions/new-booking';

const NewStudyArea = ({
  cleanSuccessAction,
  getEnrolledCoursesAction,
  getOnboardingDataAction,
  hasCanceledIntroClassAppointment,
  hasCreatedIntroClassAppointment,
  hasRescheduledIntroClassAppointment,
  initChangeCoachProcessAction,
  isCreatingAppointmentSuccess,
  selectedCourse,
  setUGWalkthroughSeenAction,
  student,
  timezone
}) => {
  const { courseId, unitIndex } = useParams();
  useEffect(() => {
    scrollFromTop(0);
  }, []);

  const scrollFromTop = (positionFromTop) => {
    window.scrollTo({
      top: positionFromTop,
      behavior: 'smooth'
    });
  };

  const handleUserGuiding = () => {
    if (!student.show_ug_walkthrough) return;

    window.userGuiding.previewGuide(35919);
    const updatedStudent = { ...student };
    updatedStudent.show_ug_walkthrough = false;
    setUGWalkthroughSeenAction(student.token, updatedStudent);
  };

  const trackUserGuidingStudent = () => {
    if (!window?.userGuiding?.identify) return;
    if (!student) return;

    window.userGuiding.identify(student.id, {
      user_type: student.user_type,
      first_name: student.first_name,
      last_name: student.last_name,
      licence_type: student.licence_type,
      email: student.email,
      organization: student?.organization[0],
      language: selectedCourse?.language,
      language_level: selectedCourse?.name,
      next_lesson_number: selectedCourse?.next_lesson_happening,
      time_zone: timezone,
      browser: platform.name,
      os: platform.os.toString()
    });
  };

  const handleMixPanelStuff = () => {
    mixpanelHelper.identifyUser({ id: student.id, email: student.email });
    mixpanelHelper.visitedPage('Study Area');
  };

  useEffect(() => {
    handleMixPanelStuff();
    handleUserGuiding();
    trackUserGuidingStudent();
    initChangeCoachProcessAction(false);
    getOnboardingDataAction({ token: student.token });
    getEnrolledCoursesAction(student.token, courseId, unitIndex);
  }, []);

  return (
    <div>
      {isMobile ? (
        <div className={styles['container-mobile']}>
          <NewStudyAreaMobile data-testid="study-area-mobile" />
        </div>
      ) : (
        <div className={styles['container-web']}>
          <NewStudyAreaWeb data-testid="study-area-web" />
        </div>
      )}
      <SuccessToast
        show={isCreatingAppointmentSuccess}
        msg="All booked! You should receive an email shortly with your booking confirmation and further details."
        onClose={cleanSuccessAction}
        testId="all-booked-success-toast"
      />
      <SuccessToast
        show={hasCanceledIntroClassAppointment}
        msg="Your Meet Your Coach session was cancelled! You should receive an email shortly with further details."
        onClose={cleanSuccessAction}
        testId="intro-class-cancel-success-toast"
      />
      <SuccessToast
        show={hasCreatedIntroClassAppointment}
        msg="Your Meet Your Coach session was booked! You should receive an email shortly with your booking confirmation and further details."
        onClose={cleanSuccessAction}
        testId="intro-class-booked-success-toast"
      />
      <SuccessToast
        show={hasRescheduledIntroClassAppointment}
        msg="Your Meet Your Coach session was rescheduled! You should receive an email shortly with your reschedule confirmation and further details."
        onClose={cleanSuccessAction}
        testId="intro-class-rescheduled-success-toast"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  hasCanceledIntroClassAppointment: state.newBooking.canceling.hasCanceledIntroClassAppointment,
  hasCreatedIntroClassAppointment: state.newBooking.bookingLessons.hasCreatedIntroClassAppointment,
  hasRescheduledIntroClassAppointment: state.newBooking.rescheduling.hasRescheduledIntroClassAppointment,
  isCreatingAppointmentSuccess: state.newBooking.bookingLessons.isCreatingAppointmentSuccess,
  selectedCourse: state.courses?.selectedCourseDetails?.courseDetails,
  student: state.user,
  timezone: state.time.timezone
});

NewStudyArea.propTypes = {
  cleanSuccessAction: PropTypes.func.isRequired,
  getEnrolledCoursesAction: PropTypes.func.isRequired,
  getOnboardingDataAction: PropTypes.func.isRequired,
  hasCanceledIntroClassAppointment: PropTypes.bool,
  hasCreatedIntroClassAppointment: PropTypes.bool,
  hasRescheduledIntroClassAppointment: PropTypes.bool,
  initChangeCoachProcessAction: PropTypes.func.isRequired,
  isCreatingAppointmentSuccess: PropTypes.bool.isRequired,
  selectedCourse: PropTypes.object.isRequired,
  setUGWalkthroughSeenAction: PropTypes.func.isRequired,
  student: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired
};

export default connect(mapStateToProps, {
  cleanSuccessAction: cleanSuccess,
  getEnrolledCoursesAction: getEnrolledCourses,
  getOnboardingDataAction: getOnboardingData,
  setUGWalkthroughSeenAction: setUGWalkthroughSeen,
  initChangeCoachProcessAction: initChangeCoachProcess
})(NewStudyArea);
