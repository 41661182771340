import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import BookingSummaryItem from '../BookingSummaryItem';

import { cleanErrorState, rescheduleIntroductoryClass, cleanPreviousState, closeReschedulingModal } from '../../../../actions/new-booking';

import { getDateFromIsoString, getTimeFromIsoString } from '../../../../utils/time-helpers';
import SessionsManagementModal from '../SessionsManagementModal';
import { getIntroClass } from '../../../../actions/sessions';
import ErrorToast from '../../../design-system/toast/errorToast/ErrorToast';
import mixpanel from '../../../../utils/mixpanel-helper';
import { selectProductType } from '../../../../reducers/order-info';
import { CLASS_TYPES } from '../../Enum';

const IntroductoryLessonReschedulingModal = ({
  activeOrders,
  cleanPreviousStateAction,
  cleanErrorStateAction,
  closeReschedulingModalAction,
  errorMessage,
  getIntroClassAction,
  hasErrorOnReschedulingIntroClass,
  isLoadingFutureScheduleAvailability,
  isReschedulingIntroClassAppointment,
  pendingToBeRescheduled,
  productType,
  rescheduleIntroductoryClassAction,
  selectedTimestamp,
  student,
  timezone
}) => {
  const [currentTutor, setCurrentTutor] = useState({ id: null });
  const introSession = pendingToBeRescheduled[0];

  const handleTutorImplicitSelection = (lessonIndex) => {
    if (lessonIndex > pendingToBeRescheduled.length - 1) return;
    const { tutor } = pendingToBeRescheduled[lessonIndex];
    if (tutor) setCurrentTutor(tutor);
  };

  useEffect(() => {
    handleTutorImplicitSelection(0);
  }, []);

  const handleAppointmentsRescheduling = () => {
    const apptData = {
      id: introSession?.id,
      date: getDateFromIsoString(selectedTimestamp),
      start_time: getTimeFromIsoString(selectedTimestamp)
    };

    rescheduleIntroductoryClassAction(student.token, apptData, () => {
      if (!activeOrders[0]?.order_appointment_types) return;
      const { language_id: languageId } = activeOrders[0].order_appointment_types[0];
      if (languageId) getIntroClassAction({ token: student.token, languageId });
      mixpanel.lessonAction('Rescheduled', {
        type: 'intro'
      });
      closeReschedulingModalAction();
      cleanPreviousStateAction();
    });
  };

  const handleCloseModal = () => {
    cleanPreviousStateAction();
  };

  return (
    <>
      <SessionsManagementModal
        currentTutor={currentTutor}
        disabledCalendar={isReschedulingIntroClassAppointment || !pendingToBeRescheduled.length}
        futureSchedule={pendingToBeRescheduled}
        handleExecution={handleAppointmentsRescheduling}
        isExecuting={isReschedulingIntroClassAppointment}
        isLoading={isLoadingFutureScheduleAvailability}
        type={CLASS_TYPES.myc}
        modalTitle="Reschedule your Meet Your Coach session"
        modalSubtitle={
          <span>
            Get to know your coach in a 15-minute call. You can only have <strong>one Meet Your Coach session</strong> in your course.
          </span>
        }
        onCloseModal={handleCloseModal}
        productType={productType}
        renderAppointmentItem={(lesson, index) => (
          <BookingSummaryItem active introClass key={index} lesson={lesson} finalTimestamp={selectedTimestamp} timezone={timezone} />
        )}
        selectedTimestamp={selectedTimestamp}
        testId="rescheduling-intro-class"
      />
      <ErrorToast errorMsg={errorMessage} show={hasErrorOnReschedulingIntroClass} onClose={cleanErrorStateAction} />
    </>
  );
};

const mapStateToProps = (state) => ({
  activeOrders: state.orderInfo?.active_orders,
  errorMessage: state.newBooking.rescheduling.error,
  hasErrorOnReschedulingIntroClass: state.newBooking.rescheduling.hasErrorOnReschedulingIntroClass,
  isLoadingFutureScheduleAvailability: state.bookable.futureSchedule.isLoadingFutureSchedule,
  isReschedulingIntroClassAppointment: state.newBooking.rescheduling.isReschedulingIntroClassAppointment,
  pendingToBeRescheduled: state.newBooking.rescheduling.appointmentsToBeRescheduled,
  productType: selectProductType(state.orderInfo, CLASS_TYPES.tuition),
  selectedCourseId: state.courses?.selectedCourseDetails?.courseDetails?.id,
  selectedTimestamp: state.bookable.available.calendar?.selectedTimestamp,
  student: state.user,
  timezone: state.time.timezone
});

IntroductoryLessonReschedulingModal.propTypes = {
  activeOrders: PropTypes.array,
  cleanPreviousStateAction: PropTypes.func.isRequired,
  cleanErrorStateAction: PropTypes.func.isRequired,
  closeReschedulingModalAction: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  getIntroClassAction: PropTypes.func,
  hasErrorOnReschedulingIntroClass: PropTypes.bool,
  isLoadingFutureScheduleAvailability: PropTypes.bool,
  isReschedulingIntroClassAppointment: PropTypes.bool,
  pendingToBeRescheduled: PropTypes.array.isRequired,
  productType: PropTypes.object.isRequired,
  rescheduleIntroductoryClassAction: PropTypes.func.isRequired,
  selectedCourseId: PropTypes.number.isRequired,
  selectedTimestamp: PropTypes.string,
  student: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired
};

IntroductoryLessonReschedulingModal.defaultProps = {
  isReschedulingIntroClassAppointment: false
};

export default connect(mapStateToProps, {
  cleanPreviousStateAction: cleanPreviousState,
  cleanErrorStateAction: cleanErrorState,
  closeReschedulingModalAction: closeReschedulingModal,
  getIntroClassAction: getIntroClass,
  rescheduleIntroductoryClassAction: rescheduleIntroductoryClass
})(IntroductoryLessonReschedulingModal);
