import TimezoneAPI from '../services/timezone';
import { actionCreatorsFactory, asyncActionType, createActionTypes } from '../utils/action-helpers';
import { getBrowserTimeZone, TIMEZONES_MAP } from '../utils/time-helpers';

export const TimezoneActionTypes = createActionTypes('Timezone', ['SET_TIMEZONE', asyncActionType('GET_AVAILABLE_TIMEZONES')]);

export const getDefaultTimezone = (availableTimezones) => {
  const timezoneName = getBrowserTimeZone();
  if (availableTimezones?.includes(timezoneName)) return timezoneName;
  return TIMEZONES_MAP.get('UTC');
};

export const setTimezone = (timezone) => {
  return {
    type: TimezoneActionTypes.SET_TIMEZONE,
    payload: timezone
  };
};

export const getAvailableTimezonesActions = actionCreatorsFactory(
  [
    TimezoneActionTypes.GET_AVAILABLE_TIMEZONES,
    TimezoneActionTypes.GET_AVAILABLE_TIMEZONES_SUCCESS,
    TimezoneActionTypes.GET_AVAILABLE_TIMEZONES_FAIL
  ],
  'getAvailableTimezones'
);

export function getAvailableTimezones(token, callback) {
  return async (dispatch) => {
    try {
      dispatch(getAvailableTimezonesActions.getAvailableTimezones());
      const { data } = await TimezoneAPI.getTimezoneList(token);
      dispatch(getAvailableTimezonesActions.getAvailableTimezonesSuccess(data));
      if (callback) callback(data);
    } catch (error) {
      dispatch(getAvailableTimezonesActions.getAvailableTimezonesFail(error.message));
    }
  };
}
