import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import styles from './Dropzone.module.scss';

const Dropzone = ({ accept, children, onDropFile, ...rest }) => {
  const onDrop = useCallback((acceptedFiles) => {
    onDropFile(acceptedFiles);
  });

  const onError = useCallback((error) => {
    console.log(error);
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
    onError,
    noDrag: true,
    ...rest
  });

  return (
    <div {...getRootProps()} className={styles.wrapper}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

Dropzone.propTypes = {
  accept: PropTypes.object,
  children: PropTypes.element.isRequired,
  onDropFile: PropTypes.func.isRequired
};

Dropzone.defaultProps = {
  children: <p>Drag n drop some files here, or click to select files</p>,
  multiple: false
};

export default Dropzone;
