export const amplifyConfig = {
  options: {
    region: process.env.REACT_APP_AWS_AMPLIFY_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_AMPLIFY_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_COGNITO_USER_POOL_WEBCLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AWS_AMPLIFY_COGNITO_DOMAIN,
      scope: ['email', 'aws.cognito.signin.user.admin', 'openid'],
      redirectSignIn: process.env.REACT_APP_AWS_AMPLIFY_COGNITO_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_AWS_AMPLIFY_COGNITO_REDIRECT_SIGN_OUT,
      responseType: 'token'
    }
  }
};
