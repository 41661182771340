import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCoachCoursesLessons, resetLesson } from '../actions/lessons';
import LoadingSpinner from '../components/design-system/loading/spinner/LoadingSpinner';

import ErrorAlert from '../components/uikit/ErrorAlert';
import Card from '../components/uikit/Card';
import Mixpanel from '../utils/mixpanel-helper.js';

import styles from '../style/containers/Resources.module.scss';

const Resources = ({ error, getCoachCoursesLessonsAction, isLoading, lessons, user }) => {
  const baseUrl = user.user_type === 'tutor' ? 'resources' : 'study-area';

  useEffect(() => {
    getCoachCoursesLessonsAction(user.token);
    Mixpanel.identify(user.id);
    Mixpanel.track('Visited Lessons Page');
  }, []);

  if (isLoading)
    return (
      <div className={styles.header}>
        <h1>Resources</h1>
        <p>Here you can find all the resources you need to complete your tuition</p>
        <LoadingSpinner />
      </div>
    );

  if (lessons?.length === 0)
    return (
      <div className={styles.noLessons}>
        <p className="text-center text-muted">
          We could not find any resource. Please contact us at <a href="mailto:support@chatterbox.io">support@chatterbox.io</a>
        </p>
      </div>
    );

  return (
    <div>
      <div className={styles.content}>
        {error && <ErrorAlert />}
        {lessons?.map((lesson) => (
          <Card
            key={lesson?.name}
            buttonText="View resources"
            buttonURL={`/${baseUrl}/${lesson.course}/${lesson.number}`}
            className={styles.resourceCard}
            title={`${lesson.course_name}: Lesson ${lesson.number}`}
            onClick={() => {
              Mixpanel.track('Click', { button: 'view_resources' });
            }}>
            {lesson?.name && <h4 className={styles.name}>{lesson?.name}</h4>}
            {lesson?.description && <p>{lesson?.description}</p>}
          </Card>
        ))}
      </div>
    </div>
  );
};

Resources.propTypes = {
  error: PropTypes.bool,
  getCoachCoursesLessonsAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  lessons: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  error: state.lessons.allLessons.error,
  lessons: state.lessons.allLessons.lessons,
  isLoading: state.lessons.allLessons.isLoading,
  user: state.user
});

export default connect(mapStateToProps, {
  getCoachCoursesLessonsAction: getCoachCoursesLessons,
  resetLesson
})(Resources);
