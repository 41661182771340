import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Glyphicon, Button } from 'react-bootstrap';
import Autosuggest from 'react-autosuggest';

import Mixpanel from '../../utils/mixpanel-helper.js';
import styles from '../../style/containers/Onboarding.module.scss';

const NUMBER_OF_SUGGESTIONS = 10;

class StepCountry extends Component {
  state = {
    value: ''
  };

  componentDidMount() {
    const { user } = this.props;
    Mixpanel.identify(user.id);
    Mixpanel.track('Visited tutor onboarding step country');
  }

  getSuggestions(value) {
    const { selectedCountry } = this.props;
    const inputValue = value !== undefined ? value.trim().toLowerCase() : '';
    const inputLength = inputValue.length;
    let renderedItems = 0;

    return this.props.countries.filter((item) => {
      if (selectedCountry !== undefined && item.country === selectedCountry.country) {
        return false;
      }
      if (renderedItems < NUMBER_OF_SUGGESTIONS) {
        let renderItem;
        if (value === '' || inputLength === 0) {
          renderItem = true;
        } else {
          renderItem = item.country.toLowerCase().indexOf(value.toLowerCase()) > -1;
        }
        if (renderItem) {
          renderedItems += 1;
          return renderItem;
        }
      }
      return false;
    });
  }

  render() {
    const { user, nextFunction, selectedCountry } = this.props;
    const inputProps = {
      placeholder: 'Search for your country...',
      value: this.state.value !== undefined ? this.state.value.toString() : '',
      onChange: (e) => this.setState({ value: e.target.value })
    };

    return (
      <div className={styles.stepWrapper}>
        <h1>Hi {user.first_name}!</h1>
        <p className={styles.onboardingCopy}>
          Chatterbox is improving your profile: learners want to know more about you when they book their class. This is also important for them to
          find you more easily. So let’s build your new Chatterbox profile! Please answer a few questions, it will take no more than 5 minutes.
        </p>
        <h2 className={styles.onboardingHeader}>What is your Country of origin?</h2>
        <Autosuggest
          getSuggestionValue={(item) => item}
          onSuggestionSelected={(event, { suggestion }) => {
            this.setState({ value: suggestion.country });
            this.props.onSelectCountry(suggestion);
          }}
          renderSuggestion={(item, { isHighlighted }) => (
            <div
              className={styles.autocompleteSuggestionItem}
              style={isHighlighted ? { backgroundColor: '#DCDCDC' } : null}
              role="button"
              tabIndex="0">
              {item.country}
            </div>
          )}
          suggestions={this.state.values !== undefined ? this.state.values : []}
          onSuggestionsClearRequested={() => {
            this.setState({
              values: []
            });
          }}
          onSuggestionsFetchRequested={({ value }) => {
            if (typeof value === 'string') {
              this.setState(
                {
                  values: this.getSuggestions(value)
                },
                () => {
                  if (this.state.values.length === 1) {
                    const autoSelectedCountry = this.state.values[0].country;
                    const autoSelectedCountryObject = this.state.values[0];
                    this.setState({ value: autoSelectedCountry, values: [] }, () => {
                      this.props.onSelectCountry(autoSelectedCountryObject);
                    });
                  }
                }
              );
            }
          }}
          onSelect={(value) => {
            this.setState({ value });
          }}
          inputProps={inputProps}
          theme={{
            input: styles.autocompleteInput,
            container: styles.autocompleteContainer,
            suggestionsContainer: styles.autocompleteSuggestionsContainer,
            suggestionsContainerOpen: styles.autocompleteSuggestionsContainerOpen,
            suggestionsList: styles.autocompleteSuggestionsList,
            suggestion: styles.autocompleteSuggestion
          }}
          shouldRenderSuggestions={() => true}
        />

        <div className={styles.autocompleteTag}>
          {selectedCountry !== undefined && (
            <div className={styles.autocompleteTagWrap}>
              <p className={styles.autocompleteTagLabel}>{`Selected country: ${selectedCountry.country}`}</p>
              <Glyphicon
                onClick={() => {
                  this.props.onSelectCountry(undefined);
                  this.setState({ value: '' });
                }}
                className={styles.autocompleteTagCross}
                glyph="remove"
              />
            </div>
          )}
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.nextButton} onClick={nextFunction} disabled={selectedCountry === undefined}>
            Next
          </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

StepCountry.propTypes = {
  user: PropTypes.object.isRequired,
  selectedCountry: PropTypes.object,
  countries: PropTypes.array.isRequired,
  nextFunction: PropTypes.func.isRequired,
  onSelectCountry: PropTypes.func.isRequired
};

StepCountry.defaultProps = {
  selectedCountry: undefined
};

export default connect(mapStateToProps, {})(StepCountry);
