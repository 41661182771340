import React from 'react';
import PropTypes from 'prop-types';

import styles from './Tab.module.scss';

const Tab = ({ name, selected, onSelectTab }) => (
  <div className={styles.tabWrapper} onClick={onSelectTab} onKeyPress={onSelectTab} role="button" tabIndex="0">
    <span className={[styles.tabName, selected && styles.selected].join(' ')}>{name}</span>
  </div>
);

Tab.propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelectTab: PropTypes.func.isRequired
};

export default Tab;
