import { FreshdeskClient } from '../../setup/freshdesk';
import instance from '..';
import { toBase64 } from '../../utils/string-helpers';

class HelpdeskClient {
  _client;
  constructor(clientProvider) {
    this._client = clientProvider;
  }

  async getTicket(payload) {
    return await this._client.tickets.getTicket(payload, { include: 'conversations' });
  }

  async createTicket(payload) {
    return await this._client.tickets.createTicket(payload);
  }

  async createTicketWithAttachments(data) {
    const auth = `Basic ${toBase64(`${process.env.REACT_APP_FRESHDESK_API_KEY}:X`)}`;
    return instance.post('https://chatterboxlanguages.freshdesk.com/api/v2/tickets', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: auth
      }
    });
  }

  async listUserTickets(email) {
    const auth = `Basic ${toBase64(`${process.env.REACT_APP_FRESHDESK_API_KEY}:X`)}`;
    return instance.get(`https://chatterboxlanguages.freshdesk.com/api/v2/tickets?email=${encodeURIComponent(email)}`, {
      headers: {
        Authorization: auth
      }
    });
  }

  async getAllTicketFields() {
    return await this._client.ticketFields.getAllTicketFields();
  }
}

export const helpdeskClient = new HelpdeskClient(new FreshdeskClient());
