import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import Login from '../../containers/Login';
import { UserTypes } from '../../domains/user/user-types';

const MultipleUsersPrivateRoute = ({ studentComponent, tutorComponent, languageHelperComponent, user, ...rest }) => {
  const { token, user_type: userType } = user;

  if (!token) {
    return <Route {...rest} render={(props) => <Login {...props} />} />;
  }

  if (userType === UserTypes.student && studentComponent) {
    return <Route {...rest} render={(props) => (token ? React.cloneElement(studentComponent, { ...props }) : <Login />)} />;
  }

  if (userType === UserTypes.tutor && tutorComponent) {
    return <Route {...rest} render={(props) => (token ? React.cloneElement(tutorComponent, { ...props }) : <Login />)} />;
  }

  if (userType === UserTypes.languageHelper && languageHelperComponent) {
    return <Route {...rest} render={(props) => (token ? React.cloneElement(languageHelperComponent, { ...props }) : <Login />)} />;
  }
};

const mapStateToProps = (state) => ({
  user: state.user
});

MultipleUsersPrivateRoute.propTypes = {
  studentComponent: PropTypes.element,
  tutorComponent: PropTypes.element,
  languageHelperComponent: PropTypes.element,
  user: PropTypes.object.isRequired
};

MultipleUsersPrivateRoute.defaultProps = {
  studentComponent: null,
  tutorComponent: null,
  languageHelperComponent: null
};

export default connect(mapStateToProps, null)(MultipleUsersPrivateRoute);
