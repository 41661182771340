import React from 'react';
import PropTypes from 'prop-types';

import styles from './ProgressBar.module.scss';
import LoadingSpinner from '../loading/spinner/LoadingSpinner';

const ProgressBar = ({ isLoading, progress, reference, color, percentage }) => {
  return (
    <div className={styles.wrapper}>
      <div className={[styles.bar, isLoading && styles.loading].join(' ')}>
        <div
          className={styles.percentage}
          style={{
            width: percentage ? `${progress * 100}%` : `${(progress[0] / progress[1]) * 100}%`,
            backgroundColor: color ? color : undefined
          }}
        />
        {reference && <div className={styles.reference} style={{ left: `${reference * 100}%` }} />}
      </div>
      {isLoading ? (
        <div className={[styles['spinner--wrapper'], styles['progress-text']].join(' ')}>
          <LoadingSpinner />
        </div>
      ) : (
        <p id="dont-translate" className={styles['progress-text']}>
          {percentage ? `${(progress * 100).toFixed(1)}%` : `(${progress[0]}/${progress[1]})`}
        </p>
      )}
    </div>
  );
};

ProgressBar.propTypes = {
  isLoading: PropTypes.bool,
  progress: PropTypes.number,
  reference: PropTypes.number,
  color: PropTypes.string,
  percentage: PropTypes.bool
};

ProgressBar.defaultProps = {
  percentage: true
};

export default ProgressBar;
