import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import Mixpanel from '../../utils/mixpanel-helper.js';
import { hideOnboarding } from '../../actions/user';
import styles from '../../style/containers/Onboarding.module.scss';
import { getTutorInfo } from '../../actions/tutor-info';
import useInitRecording from '../../hooks/useInitRecording';

const StepStudentFinal = ({ getTutorInfoAction, hideOnboardingAction, navigateToDashboard, user }) => {
  const [endRecording] = useInitRecording((endTime) => Mixpanel.recordElapsedTime('Onboarding Step Student Final page', endTime));

  useEffect(() => {
    Mixpanel.visitedPage('Onboarding Step Student Final');
    getTutorInfoAction(user.token);
    hideOnboardingAction(user.token);

    return () => endRecording();
  }, []);

  return (
    <div className={styles.stepWrapper}>
      <h1 className={styles.onboardingTitle}>Thank you</h1>

      <h2 className={[styles.onboardingHeader, styles.onboardingText].join(' ')}>
        We will notify you when there will be tutors matching your profile.
      </h2>

      <div className={styles.buttonContainer}>
        <Button
          className={styles.backToDashboardButton}
          onClick={() => {
            navigateToDashboard();
          }}>
          Go back to dashboard
        </Button>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

StepStudentFinal.propTypes = {
  user: PropTypes.object.isRequired,
  hideOnboardingAction: PropTypes.func.isRequired,
  navigateToDashboard: PropTypes.func.isRequired,
  getTutorInfoAction: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {
  hideOnboardingAction: hideOnboarding,
  getTutorInfoAction: getTutorInfo
})(StepStudentFinal);
