import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './Alert.module.scss';
import CloseButton from '../button/close-button/CloseButton';

const Alert = ({ actions, Icon, isVisible, onClose, text, title, type }) => {
  const [_isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(isVisible);
  }, [isVisible]);

  const handleClose = () => {
    setIsVisible(false);
    if (onClose) onClose();
  };

  if (!_isVisible) return null;

  return (
    <div className={[styles.wrapper, styles[type]].join(' ')}>
      {Icon ? <div className={styles.iconWrapper}>{Icon}</div> : null}
      <div className={styles.content}>
        <div className={styles.text}>
          <h1>{title}</h1>
          <p>{text}</p>
        </div>
        {actions?.length
          ? actions.map(({ label, action }) => (
              <button key={label} onClick={action}>
                {label}
              </button>
            ))
          : null}
      </div>
      <div className={styles.close}>
        <CloseButton onClick={handleClose} />
      </div>
    </div>
  );
};

Alert.propTypes = {
  actions: PropTypes.array,
  Icon: PropTypes.element,
  isVisible: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string.isRequired
};

Alert.defaultProps = {
  isVisible: 'false',
  type: 'success'
};

export default Alert;
