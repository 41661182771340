import React from 'react';
import PropTypes from 'prop-types';

import styles from './Modal.module.scss';
import CloseButton from '../button/close-button/CloseButton';

const Modal = ({ content, completionProgress, modalRef, noPadding, onClose, modalMainColor }) => {
  const handleClose = (evt) => {
    evt.stopPropagation();
    onClose();
  };
  const completionProgressParser = {
    0: 'initial',
    50: 'middle',
    100: 'done'
  };

  return (
    <div className={[styles.modalWrapper, noPadding && styles.noPadding].join(' ')}>
      <div className={styles.background} onClick={handleClose} />
      <div className={styles.contentWrapper}>
        {onClose && (
          <div className={styles.closeButton}>
            <CloseButton onClick={handleClose} />
          </div>
        )}
        {completionProgress && (
          <div className={[styles.progressBar, styles[completionProgressParser[completionProgress]], styles[modalMainColor]].join(' ')} />
        )}
        <div className={styles.content} ref={modalRef}>
          {content}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  content: PropTypes.any.isRequired,
  completionProgress: PropTypes.number,
  modalRef: PropTypes.object,
  noPadding: PropTypes.bool,
  onClose: PropTypes.func,
  modalMainColor: PropTypes.string.isRequired
};

Modal.defaultProps = {
  modalMainColor: 'green'
};

export default Modal;
