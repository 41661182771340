import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../design-system/button/Button';

import styles from '../../style/containers/TestPage.module.scss';

const TestYouTube = ({ nextPage, prevPage, restart }) => (
  <>
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/FdMv9kgFN2U"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
    <p className={[styles.introParagraph, styles.youtubeParagraph].join(' ')}>
      Please try to press play. If the video starts playing, then click on the &ldquo;Next&ldquo; button below.
    </p>
    <div className={styles.buttonContainer}>
      <Button outfit="secondaryButton" className={styles.nextButton} onClick={prevPage}>
        Back
      </Button>
      <Button outfit="regularButton" className={styles.nextButton} onClick={nextPage}>
        Next
      </Button>
      <Button outfit="secondaryButton" className={styles.nextButton} onClick={restart}>
        Restart
      </Button>
    </div>
  </>
);

TestYouTube.propTypes = {
  nextPage: PropTypes.func.isRequired,
  prevPage: PropTypes.func.isRequired,
  restart: PropTypes.func.isRequired
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(TestYouTube);
