import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearLogInError, getSSOProvider, setSSORedirectUrl } from '../../../../../actions/new-login';
import useSso from '../../../../../hooks/useSso';
import { amplifyConfig } from '../../../../../setup/aws/amplify';
import AnimatedDots from '../../../../design-system/animated-dots/AnimatedDots';
import ErrorMessage from '../../../../design-system/helper-messages/error-message/ErrorMessage';
import { useLocation } from 'react-router-dom';

const SSOLogin = ({
  clearLogInErrorAction,
  getSSOProviderAction,
  hasLoginError,
  initialInformation,
  isSignup,
  loginErrorMsg,
  setSSORedirectUrlAction
}) => {
  const { signIn } = useSso(amplifyConfig);
  const location = useLocation();

  useEffect(() => {
    clearLogInErrorAction();
    friendlyCallSSOLogin();
  }, []);

  const friendlyCallSSOLogin = () => {
    setSSORedirectUrlAction(`${location.pathname}${location.search}&reset_user=false&skip_animation=true&${isSignup ? 'is_signup=true' : ''}`);
    setTimeout(() => {
      getSSOProviderAction({ email: initialInformation.email, successCallback: signIn });
    }, 2000);
  };

  const resetError = () => {
    clearLogInErrorAction();
    friendlyCallSSOLogin();
  };

  if (hasLoginError) return <ErrorMessage errorMsg={loginErrorMsg} onClose={resetError} show={hasLoginError} />;

  return (
    <p>
      {' '}
      Getting your CREDENTIALS <AnimatedDots />
    </p>
  );
};

SSOLogin.propTypes = {
  clearLogInErrorAction: PropTypes.func.isRequired,
  getSSOProviderAction: PropTypes.func.isRequired,
  hasLoginError: PropTypes.bool,
  initialInformation: PropTypes.object.isRequired,
  isSignup: PropTypes.bool,
  loginErrorMsg: PropTypes.string,
  setSSORedirectUrlAction: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  hasLoginError: state.newLogin.ssoLogIn.hasPostLogInError,
  initialInformation: state.newOnboarding.initialInformation,
  loginErrorMsg: state.newLogin.ssoLogIn.postLogInErrorMessage
});

export default connect(mapStateToProps, {
  clearLogInErrorAction: clearLogInError,
  getSSOProviderAction: getSSOProvider,
  setSSORedirectUrlAction: setSSORedirectUrl
})(SSOLogin);
