import React from 'react';
import PropTypes from 'prop-types';

import styles from './ProgressLineSection.module.scss';

const ProgressLineSection = ({ isCurrent, isDone, isFirst, isLast, isOptional }) => {
  return (
    <div className={styles.section}>
      <div
        data-testid="first-leg"
        className={[styles['first-leg'], (isDone || isCurrent) && styles['section-current'], isFirst && styles.first].join(' ')}
      />
      <div
        data-testid="second-leg"
        className={[styles['second-leg'], isLast && styles.last, isDone && styles['done'], isCurrent && isOptional && styles['optional']].join(' ')}
      />
    </div>
  );
};

ProgressLineSection.propTypes = {
  isCurrent: PropTypes.bool.isRequired,
  isDone: PropTypes.bool.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  isOptional: PropTypes.bool.isRequired
};

export default ProgressLineSection;
