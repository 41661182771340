import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import MyBookings from '../components/new-booking/MyBookings/MyBookings';
import Bookings from '../components/new-booking/Bookings/Bookings';

import styles from '../style/containers/NewBooking.module.scss';
import { connect } from 'react-redux';
import { getEnrolledCourses } from '../actions/courses';
import getOrderInfo from '../actions/order-info';

const NewBooking = ({ getEnrolledCoursesAction, getOrderInfoAction, orderInfo, selectedCourse, token }) => {
  useEffect(() => {
    if (!selectedCourse?.name) getEnrolledCoursesAction(token);
    if (!orderInfo?.all_orders) getOrderInfoAction(token);
  }, []);

  return (
    <div>
      <div className={styles.newBookingWrapper}>
        <div className={styles.bookings}>
          <Bookings />
        </div>
        <div className={styles.myBookings}>
          <MyBookings />
        </div>
      </div>
    </div>
  );
};

NewBooking.propTypes = {
  getEnrolledCoursesAction: PropTypes.func.isRequired,
  getOrderInfoAction: PropTypes.func.isRequired,
  orderInfo: PropTypes.object,
  selectedCourse: PropTypes.object,
  token: PropTypes.string
};

const mapStateToProps = (state) => ({
  selectedCourse: state.courses.selectedCourseDetails.courseDetails,
  orderInfo: state.orderInfo,
  token: state.user?.token
});

export default connect(mapStateToProps, {
  getEnrolledCoursesAction: getEnrolledCourses,
  getOrderInfoAction: getOrderInfo
})(NewBooking);
