import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import Mixpanel from '../../utils/mixpanel-helper.js';
import styles from '../../style/containers/Onboarding.module.scss';

class StepTutorBio extends Component {
  componentDidMount() {
    const { user } = this.props;
    Mixpanel.identify(user.id);
    Mixpanel.track('Visited onboarding step tutor bio');
  }

  render() {
    const { nextFunction, backFunction, textBio, onTextBioChange } = this.props;

    return (
      <div className={styles.stepWrapper}>
        <h1 className={styles.onboardingTitle}>Biography</h1>

        <h2 className={[styles.onboardingHeader, styles.onboardingText].join(' ')}>Please check that your biography is correct and updated.</h2>

        <div className={styles.motivationTextareaWrapper}>
          <textarea className={styles.motivationTextarea} rows={5} value={textBio} onChange={onTextBioChange} />
        </div>

        <div className={styles.buttonContainer}>
          <Button onClick={backFunction} bsStyle="link" className={styles.backButton}>
            Back
          </Button>
          <Button onClick={nextFunction} className={styles.nextButton}>
            Next
          </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

StepTutorBio.propTypes = {
  user: PropTypes.object.isRequired,
  backFunction: PropTypes.func.isRequired,
  nextFunction: PropTypes.func.isRequired,
  onTextBioChange: PropTypes.func.isRequired,
  textBio: PropTypes.string.isRequired
};

export default connect(mapStateToProps, {})(StepTutorBio);
