import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Mixpanel from '../../utils/mixpanel-helper.js';
import styles from '../../style/containers/Onboarding.module.scss';
import Timetable from '../uikit/Timetable';
import Button from '../design-system/button/Button';
import useInitRecording from '../../hooks/useInitRecording';
import IconInfoGray from '../../assets/icons/icon_info-gray.svg';
import CheckboxField from '../design-system/checkbox-field/CheckboxField.js';

const DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const DAY_MOMENTS = ['Morning', 'Afternoon', 'Evening'];

const StepCalendar = ({
  allDays,
  calendarMatrix,
  initializeCalendarSelectionMatrix,
  nextFunction,
  onSelectTimeSlot,
  selectAllDaysCalendarSelectionMatrix,
  timezone
}) => {
  const [endRecording] = useInitRecording((endTime) => Mixpanel.recordElapsedTime('Onboarding Step Calendar page', endTime));

  useEffect(() => {
    Mixpanel.visitedPage('Onboarding Step Calendar');
    if (calendarMatrix === undefined) {
      initializeCalendarSelectionMatrix(DAYS.length, DAY_MOMENTS.length);
    }

    return () => endRecording();
  }, []);

  const resetCalendar = () => {
    initializeCalendarSelectionMatrix(DAYS.length, DAY_MOMENTS.length);
  };

  const selectAllDaysCalendar = () => {
    selectAllDaysCalendarSelectionMatrix(DAYS.length, DAY_MOMENTS.length);
  };

  const hasSelected = () => {
    if (calendarMatrix) {
      const checkArray = calendarMatrix.map((line) => line.includes(true));
      return checkArray.includes(true);
    }
    return false;
  };

  if (calendarMatrix === undefined || calendarMatrix === null) {
    resetCalendar();
  }

  return (
    <div className={styles.stepWrapper}>
      <div className={styles['onboarding__title-wrapper']}>
        <h1 className={styles.onboardingTitle}>When works best?</h1>
        <h2 className={[styles.onboardingHeader, styles.onboardingText, styles['text--bold']].join(' ')}>
          Let us know what days and times work best for your Live Practices.
          <br />
          Then we’ll let you know which coaches are the best match!
        </h2>
        <p className={[styles.onboardingSubtitle, styles['text--italic']].join(' ')}>(You can edit later.)</p>
      </div>
      <div>
        <Timetable timezone={timezone} calendarMatrix={calendarMatrix} onSelectTimeSlot={onSelectTimeSlot} />
        <div className={styles.selectAll__wrapper}>
          <CheckboxField
            checked={allDays}
            label="Select all"
            onChange={() => {
              if (allDays) {
                resetCalendar();
                Mixpanel.track('Click', {
                  position: 'StepCalendar',
                  button: 'select_all_days'
                });
              } else {
                selectAllDaysCalendar();
              }
            }}
            icon={<img src={IconInfoGray} alt="information icon" />}
            tooltipLabel={'Select all if you&apos;re unsure. You can edit this later.'}
          />
          {/* <div className={styles.tooltip__wrapper}>
            <Glyphicon
              className={styles.infoIcon}
              glyph="info-sign"
            >
              <p className={styles.tooltipContent}>{'Select all if you\'re unsure. You can edit this later.'}</p>
            </Glyphicon>
          </div> */}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button onClick={nextFunction} size="l" disabled={!hasSelected()} outfit="mainButton">
          Next
        </Button>
      </div>
    </div>
  );
};

StepCalendar.propTypes = {
  initializeCalendarSelectionMatrix: PropTypes.func.isRequired,
  selectAllDaysCalendarSelectionMatrix: PropTypes.func.isRequired,
  calendarMatrix: PropTypes.array,
  nextFunction: PropTypes.func.isRequired,
  onSelectTimeSlot: PropTypes.func.isRequired,
  allDays: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired
};

StepCalendar.defaultProps = {
  calendarMatrix: undefined
};

export default StepCalendar;
