import { TimezoneActionTypes } from '../actions/time';
import { getBrowserTimeZone } from '../utils/time-helpers';

const autoSetUserTimezone = (availableTimezones, currentTimezone) => {
  const timezoneName = getBrowserTimeZone();
  if (availableTimezones.includes(timezoneName)) return timezoneName;
  return currentTimezone;
};

const initialState = {
  availableTimezones: [],
  isLoadingAvailableTimezones: false,
  hasErrorGettingAvailableTimezones: false,
  timezone: '',
  detectedTimezone: ''
};

const removeDuplicated = (list) => [...new Set(list)];

const sort = (list) => list.sort();

export default function timeReducer(state = initialState, action) {
  switch (action.type) {
    case TimezoneActionTypes.SET_TIMEZONE:
      return { ...state, timezone: action.payload || initialState.timezone };
    case TimezoneActionTypes.GET_AVAILABLE_TIMEZONES:
      return { ...state, isLoadingAvailableTimezones: true };
    case TimezoneActionTypes.GET_AVAILABLE_TIMEZONES_SUCCESS:
      return {
        ...state,
        isLoadingAvailableTimezones: false,
        availableTimezones: removeDuplicated(sort(action.payload)),
        detectedTimezone: autoSetUserTimezone(action.payload, state.timezone)
      };
    case TimezoneActionTypes.GET_AVAILABLE_TIMEZONES_FAIL:
      return { ...state, isLoadingAvailableTimezones: false, hasErrorGettingAvailableTimezones: true };
    default:
      return state;
  }
}
