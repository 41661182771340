import React, { useState } from 'react';
import LoginForm from '../components/account/LoginForm';
import SsoLoginForm from '../components/account/sso-login';
import LogoHeader from '../components/LogoHeader';

import styles from '../style/components/Login.module.scss';

const Login = () => {
  const [isSSO, setIsSSO] = useState(false);

  return (
    <div className={styles.login}>
      <LogoHeader />
      <div className={styles.loginWrapper}>
        <h1 className={styles.title}>Welcome back</h1>
        <div className={styles.loginForm}>{isSSO ? <SsoLoginForm setIsSSO={setIsSSO} /> : <LoginForm setIsSSO={setIsSSO} />}</div>
      </div>
    </div>
  );
};

export default Login;
