import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './ErrorToast.module.scss';
import mixpanel from '../../../../utils/mixpanel-helper';

const ErrorToast = ({ errorMsg, onClose, show }) => {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    setShowToast(show);
    if (show) postOnMixpanel();
  }, [show]);

  const handleClose = () => {
    if (onClose) onClose();
    setShowToast(false);
  };

  const postOnMixpanel = () => {
    if (errorMsg) mixpanel.postError(errorMsg);
  };

  if (!showToast) return null;

  return (
    <div className={styles.errorWrapper}>
      <div className={styles.messageWrapper}>
        <p>{errorMsg}</p>
        <button type="button" onClick={handleClose}>
          X
        </button>
      </div>
    </div>
  );
};

ErrorToast.propTypes = {
  errorMsg: PropTypes.string,
  onClose: PropTypes.func,
  show: PropTypes.bool
};

ErrorToast.defaultProps = {
  errorMsg: 'Something went wrong',
  onClose: undefined,
  show: false
};

export default ErrorToast;
