import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import IconCalendar from '../../../../assets/icons/icon_calendar.svg';
import FilterButton from '../../../design-system/filter-button/FilterButton';
import Timetable from '../../../uikit/Timetable';
import { INITIAL_MATRIX } from '../../../availability/helpers';

const CoachFilterByAvailability = ({ setUpdatedTutorsList, tutors }) => {
  const [filterMatrix, setFilterMatrix] = useState(INITIAL_MATRIX);
  const [userTimeZoneOffset, setUserTimeZoneOffset] = useState(0);
  const timetableRef = useRef(null);

  const handleCoachFiltering = (indexDay, indexPeriod) => {
    const matrixCopy = _.cloneDeep(filterMatrix);
    matrixCopy[indexPeriod][indexDay] = !matrixCopy[indexPeriod][indexDay];
    setFilterMatrix(matrixCopy);
  };

  const isEveryAvailabilityFalse = () => {
    return filterMatrix.reduce((acc, cv) => {
      return (
        acc &&
        cv.reduce((acc, cv) => {
          return acc && !cv;
        }, true)
      );
    }, true);
  };

  const getTimeZoneOffsetInHours = () => {
    // Get the user's time zone offset in minutes
    const timeZoneOffsetInMinutes = new Date().getTimezoneOffset();

    // Convert the offset to hours and invert the sign (because JS gives the offset in minutes ahead of UTC, not behind)
    let timeZoneOffsetInHours = -timeZoneOffsetInMinutes / 60;

    // Round the offset to the nearest whole number (e.g., round +5:30 to +5)
    timeZoneOffsetInHours = Math.round(timeZoneOffsetInHours);

    return timeZoneOffsetInHours;
  };

  const checkWhetherTutorIsAvailable = (tutor, matrix) => {
    if (!tutor || !tutor.availability_matrix || isEveryAvailabilityFalse()) return true;

    // Pick the correct availability matrix based on the user's time zone offset
    const tutorAvailabilityForTimeZone = tutor.availability_matrix[userTimeZoneOffset];

    if (!tutorAvailabilityForTimeZone) {
      console.warn(`No availability found for time zone offset: ${userTimeZoneOffset}`);
      return false; // or true depending on what you want to do if no matrix is found
    }

    return matrix.reduce((acc, periodOfTheDay, periodIndex) => {
      return (
        acc ||
        periodOfTheDay.reduce((acc, dayOfTheWeekAvailability, dayIndex) => {
          return acc || (tutorAvailabilityForTimeZone[periodIndex][dayIndex] && dayOfTheWeekAvailability);
        }, false)
      );
    }, false);
  };

  const sortTutorsBasedOnAvailability = (matrix) => {
    if (!tutors || !tutors.length) return;

    const sortedTutorsWithAvailabilityMatching = tutors
      .map((tutor) => {
        const isTutorAvailable = checkWhetherTutorIsAvailable(tutor, matrix);
        return { ...tutor, isTutorAvailable };
      })
      .sort((a, b) => {
        if (a.isTutorAvailable && !b.isTutorAvailable) return -1;
        if (a.isTutorAvailable && b.isTutorAvailable) {
          if (a.first_name > b.first_name) return 1;
          if (a.first_name < b.first_name) return -1;
        }
        return 1;
      });

    setUpdatedTutorsList(sortedTutorsWithAvailabilityMatching);
  };

  useEffect(() => {
    sortTutorsBasedOnAvailability(filterMatrix);
  }, [filterMatrix]);

  useEffect(() => {
    setUserTimeZoneOffset(getTimeZoneOffsetInHours());
  }, []);

  return (
    <FilterButton
      icon={IconCalendar}
      buttonText="Filter by availability"
      filterRef={timetableRef}
      filterComponent={<Timetable timetableRef={timetableRef} calendarMatrix={filterMatrix} onSelectTimeSlot={handleCoachFiltering} />}
    />
  );
};

CoachFilterByAvailability.propTypes = {
  setUpdatedTutorsList: PropTypes.func.isRequired,
  tutors: PropTypes.array
};

export default CoachFilterByAvailability;
