import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './ExpandableCard.module.scss';

const ExpandableCard = ({ response, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={[styles['card-wrapper'], isOpen && styles['is-open']].join(' ')} onClick={() => setIsOpen((prevState) => !prevState)}>
      <div className={styles['card-header']}>
        <p className={styles['card-title']}>{title}</p>
      </div>
      {isOpen && <div className={styles['card-response']}>{response}</div>}
    </div>
  );
};

ExpandableCard.propTypes = {
  response: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired
};

export default ExpandableCard;
