import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../../design-system/button/Button';

import styles from './WelcomeToPlacementTest.module.scss';

const WelcomeToPlacementTest = ({ placementTestInfo, handleStart, student }) => {
  return (
    <div className={styles['welcome-wrapper']}>
      <h1 className={styles['welcome-title']}>
        Welcome to Chatterbox {student?.first_name} {student?.last_name}!
      </h1>
      <p className={styles['welcome-subtitle']}>Let&apos;s start by finding out your {placementTestInfo?.language} level.</p>
      <Button onClick={handleStart} outfit="greenButton" id="start-placement-test">
        Let&apos;s find out
      </Button>
      <p className={styles['welcome-hint']}>Takes 1-2 mins</p>
    </div>
  );
};

const mapStateToProps = (state) => ({
  placementTestInfo: state.user.placement_test_info,
  student: state.user
});

WelcomeToPlacementTest.propTypes = {
  placementTestInfo: PropTypes.object.isRequired,
  handleStart: PropTypes.func.isRequired,
  student: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(WelcomeToPlacementTest);
