import * as Sentry from '@sentry/browser';
import NewBookingAPI from '../services/new-booking';
import { actionCreatorsFactory, asyncActionType, createActionTypes } from '../utils/action-helpers';

const sentryContext = Object.freeze({
  tags: {
    context: 'Tutor Profile action'
  }
});

export const SelectedTutorActionTypes = createActionTypes('SelectedTutor', [
  asyncActionType('GET_MATCHED_TUTOR'),
  'SET_MATCHED_TUTOR',
  'SET_SELECTED_TUTOR',
  'CLEAN_MATCHED_TUTOR_ERROR'
]);

const getMatchedTutorActions = actionCreatorsFactory(
  [SelectedTutorActionTypes.GET_MATCHED_TUTOR, SelectedTutorActionTypes.GET_MATCHED_TUTOR_SUCCESS, SelectedTutorActionTypes.GET_MATCHED_TUTOR_FAIL],
  'getMatchedTutor'
);

const cleanMatchedTutorError = actionCreatorsFactory([SelectedTutorActionTypes.CLEAN_MATCHED_TUTOR_ERROR], 'cleanError');

export const getMatchedTutor = (studentToken) => async (dispatch) => {
  try {
    dispatch(cleanMatchedTutorError.cleanError());
    dispatch(getMatchedTutorActions.getMatchedTutor());
    const { data: onboardingData } = await NewBookingAPI.getOnboardingResults(studentToken);
    if (!onboardingData.tutor) throw new Error('You must finish your onboarding before starting using the platform.');
    const { data: tutorData } = await NewBookingAPI.getTutorInfo(studentToken, onboardingData.tutor);

    dispatch(getMatchedTutorActions.getMatchedTutorSuccess({ ...tutorData, id: onboardingData.tutor }));
  } catch (error) {
    Sentry.captureException(error, sentryContext);
    dispatch(getMatchedTutorActions.getMatchedTutorFail());
  }
};

export const setMatchedTutor = (tutor, issue, secondChoice) => ({
  type: SelectedTutorActionTypes.SET_MATCHED_TUTOR,
  payload: {
    tutor,
    issue,
    secondChoice,
    confetti: true
  }
});

export const setSelectedTutor = (data) => ({
  type: SelectedTutorActionTypes.SET_SELECTED_TUTOR,
  payload: data
});
