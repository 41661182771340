import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../../../style/components/study-area/CourseTab.module.scss';

const CourseTab = ({ name, selected, onSelectTab }) => (
  <div className={styles.tabWrapper} onClick={onSelectTab} onKeyPress={onSelectTab} role="button" tabIndex="0">
    <span className={styles.tabName}>{name}</span>
    {selected && <div className={styles.underline} />}
  </div>
);

CourseTab.propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelectTab: PropTypes.func.isRequired
};

export default CourseTab;
