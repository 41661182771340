import { CourseActionTypes } from '../actions/courses';
import { LessonsActionTypes } from '../actions/lessons';
import { USER_LOGOUT } from '../actions/types';
import { ASSIGNMENT_STATES } from '../common/assignmentStates';

const initialSelectedUnitState = {
  errorMessageSettingSelectedUnit: '',
  hasErrorSettingSelectedUnit: false,
  isSettingSelectedUnit: true,
  isSettingNextLessonToStudy: true,
  selectedUnit: {},
  isGettingSelectedUnitProgress: true,
  hasErrorGettingSelectedUnitProgress: false,
  errorMessageGettingSelectedUnitProgress: ''
};

const updateResourcesWithProgress = (selectedUnit, progressArr) => {
  const resources = selectedUnit?.resources || [];
  const updatedResources = resources.map((resource) => {
    const [progressObj] = progressArr.filter((p) => p?.id === resource?.id);
    return {
      ...resource,
      seeNextAt: progressObj?.see_next_at || null,
      progress: Number((progressObj?.progress * 1).toFixed(3)) ?? 0
    };
  });

  return { ...selectedUnit, resources: updatedResources };
};

export default function selectedUnitReducer(state = initialSelectedUnitState, action) {
  switch (action.type) {
    case CourseActionTypes.GET_SELECTED_COURSE_DETAILS_FAIL:
      return {
        ...state,
        isSettingSelectedUnit: false,
        isSettingNextLessonToStudy: false,
        selectedUnit: {}
      };

    case LessonsActionTypes.SET_SELECTED_UNIT:
      return { ...state, isSettingSelectedUnit: true, hasErrorSettingSelectedUnit: false };
    case LessonsActionTypes.SET_SELECTED_UNIT_SUCCESS:
      return {
        ...state,
        isSettingSelectedUnit: false,
        selectedUnit: action.payload,
        hasErrorSettingSelectedUnit: false
      };

    case LessonsActionTypes.ADD_WRITING_DELIVERY_FILE_SUCCESS:
      return {
        ...state,
        selectedUnit: {
          ...state.selectedUnit,
          written_assignment_result: {
            ...state.selectedUnit.written_assignment_result,
            status: ASSIGNMENT_STATES['awaitingFeedback'],
            delivery_files: state.selectedUnit.written_assignment_result.delivery_files
              ? [...state.selectedUnit.written_assignment_result.delivery_files, action.payload]
              : [action.payload]
          }
        }
      };

    case LessonsActionTypes.REMOVE_WRITING_DELIVERY_FILE_SUCCESS: {
      const indexToRemove = state.selectedUnit.written_assignment_result.delivery_files.findIndex(
        (delivery_file) => delivery_file.id === action.payload
      );
      const filesLength = state.selectedUnit.written_assignment_result.delivery_files.length;
      return {
        ...state,
        selectedUnit: {
          ...state.selectedUnit,
          written_assignment_result: {
            ...state.selectedUnit.written_assignment_result,
            delivery_files: [
              ...state.selectedUnit.written_assignment_result.delivery_files.slice(0, indexToRemove),
              ...state.selectedUnit.written_assignment_result.delivery_files.slice(indexToRemove + 1)
            ],
            status: filesLength <= 1 ? ASSIGNMENT_STATES['pendingSubmission'] : ASSIGNMENT_STATES['awaitingFeedback']
          }
        }
      };
    }

    case LessonsActionTypes.ADD_SPEAKING_DELIVERY_FILE_SUCCESS:
      return {
        ...state,
        selectedUnit: {
          ...state.selectedUnit,
          speaking_assignment_result: {
            ...state.selectedUnit.speaking_assignment_result,
            status: ASSIGNMENT_STATES['awaitingFeedback'],
            delivery_files: state.selectedUnit.speaking_assignment_result.delivery_files
              ? [...state.selectedUnit.speaking_assignment_result.delivery_files, action.payload]
              : [action.payload]
          }
        }
      };

    case LessonsActionTypes.REMOVE_SPEAKING_DELIVERY_FILE_SUCCESS: {
      const indexToRemove = state.selectedUnit.speaking_assignment_result.delivery_files.findIndex(
        (delivery_file) => delivery_file.id === action.payload
      );
      const filesLength = state.selectedUnit.speaking_assignment_result.delivery_files.length;
      return {
        ...state,
        selectedUnit: {
          ...state.selectedUnit,
          speaking_assignment_result: {
            ...state.selectedUnit.speaking_assignment_result,
            delivery_files: [
              ...state.selectedUnit.speaking_assignment_result.delivery_files.slice(0, indexToRemove),
              ...state.selectedUnit.speaking_assignment_result.delivery_files.slice(indexToRemove + 1)
            ],
            status: filesLength <= 1 ? ASSIGNMENT_STATES['pendingSubmission'] : ASSIGNMENT_STATES['awaitingFeedback']
          }
        }
      };
    }

    case LessonsActionTypes.SET_SELECTED_UNIT_FAIL:
      return {
        ...state,
        isSettingSelectedUnit: false,
        isSettingNextLessonToStudy: false,
        selectedUnit: {},
        hasErrorSettingSelectedUnit: true,
        errorMessageSettingSelectedUnit: action.payload
      };

    case LessonsActionTypes.GET_LESSONS_ASSIGNMENTS_LIVE_PROGRESS:
      return {
        ...state,
        isGettingSelectedUnitProgress: true,
        hasErrorGettingSelectedUnitProgress: false
      };
    case LessonsActionTypes.GET_LESSONS_ASSIGNMENTS_LIVE_PROGRESS_SUCCESS:
      return {
        ...state,
        isGettingSelectedUnitProgress: false,
        selectedUnit: updateResourcesWithProgress(state.selectedUnit, action.payload),
        hasErrorGettingSelectedUnitProgress: false
      };
    case LessonsActionTypes.GET_LESSONS_ASSIGNMENTS_LIVE_PROGRESS_FAIL:
      return {
        ...state,
        isGettingSelectedUnitProgress: false,
        hasErrorGettingSelectedUnitProgress: true,
        errorMessageGettingSelectedUnitProgress: action.payload
      };
    case USER_LOGOUT:
      return initialSelectedUnitState;
    default:
      return state;
  }
}
