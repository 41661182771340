import instance from '..';
import { convertObjToQueryString } from '../../utils/url-helpers';

export default class AppointmentsAPI {
  static async getTutorAppointments(token, params) {
    const underscoredParams = {
      tutor_id: params?.tutorId,
      start_date: params?.startDate,
      end_date: params?.endDate
    };

    const stringfied = convertObjToQueryString(underscoredParams);

    return instance.get(`/api/appointments/?${stringfied}`, {
      headers: { Authorization: `Token ${token}` }
    });
  }
  static async registerTutorAttendance(token, appointmentId) {
    return instance.put(`/api/appointments/${appointmentId}/register-attendance/`, null, {
      headers: { Authorization: `Token ${token}` }
    });
  }
  static async updateAppointment({ token, appointmentId, payload }) {
    return instance.put(`/api/appointments/${appointmentId}`, payload, {
      headers: { Authorization: `Token ${token}` }
    });
  }
  static async changeAppointmentStatus({ token, appointmentId, status, message }) {
    return instance.put(
      `/api/appointments/${appointmentId}/mark-outcome/`,
      { status, message },
      {
        headers: { Authorization: `Token ${token}` }
      }
    );
  }
}
