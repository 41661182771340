import IconReading from '../../assets/icons/icon_reading.svg';
import IconListening from '../../assets/icons/icon_listening.svg';
import IconCulture from '../../assets/icons/icon_culture.svg';
import IconVideo from '../../assets/icons/icon_play.svg';
import IconPronunciation from '../../assets/icons/icon_pronunciation.svg';
import IconTest from '../../assets/icons/icon_test.svg';
import IconWritten from '../../assets/icons/written_assignment.png';
import IconSpeaking from '../../assets/icons/speech_bubble.png';

export const RESOURCE_TYPE = Object.freeze({
  ASSESSMENT: 'assessment',
  WRITTEN_ASSIGNMENT: 'written_assignment',
  SPEAKING_ASSIGNMENT: 'speaking_assignment',
  AUDIO: 'audio',
  CEREGO: 'cerego',
  CULTURE: 'culture',
  DOCUMENT: 'document',
  IMAGE: 'image',
  LISTENING: 'listening',
  PRONUNCIATION: 'pronunciation',
  READING: 'reading',
  WEBPAGE: 'webpage',
  VIDEO: 'video',
  FEEDBACK: 'feedback'
});

export const RESOURCE_TYPE_ICON = Object.freeze({
  [RESOURCE_TYPE.ASSESSMENT]: IconTest,
  [RESOURCE_TYPE.CEREGO]: IconReading,
  [RESOURCE_TYPE.DOCUMENT]: IconReading,
  [RESOURCE_TYPE.READING]: IconReading,
  [RESOURCE_TYPE.WEBPAGE]: IconReading,
  [RESOURCE_TYPE.LISTENING]: IconListening,
  [RESOURCE_TYPE.AUDIO]: IconListening,
  [RESOURCE_TYPE.CULTURE]: IconCulture,
  [RESOURCE_TYPE.IMAGE]: IconCulture,
  [RESOURCE_TYPE.VIDEO]: IconVideo,
  [RESOURCE_TYPE.PRONUNCIATION]: IconPronunciation,
  [RESOURCE_TYPE.WRITTEN_ASSIGNMENT]: IconWritten,
  [RESOURCE_TYPE.SPEAKING_ASSIGNMENT]: IconSpeaking,
  [RESOURCE_TYPE.FEEDBACK]: IconSpeaking
});
