import axios from 'axios';
import React from 'react';
import moment from 'moment-timezone';
import Mixpanel from './mixpanel-helper.js';
import styles from '../style/containers/ChatPage.module.scss';
import ChatterboxLogo from '../assets/logos/chatterbox-logo.png';
import { getCityTimezone } from './time-helpers.js';

const DATE_FORMAT = 'MMMM Do, YYYY';

function getSystemMessage(message, translation, date, twilioIndex) {
  let text = message;
  if (message.props && message.props.children && message.props.children.length > 0) {
    const messageContent = message.props.children[0];
    if (messageContent.includes('joined') || messageContent.includes('left')) {
      const twoDigitsHours = date.getHours();
      const twoDigitsMinutes = date.getMinutes();
      const twoDigitsSeconds = date.getSeconds();
      const twoDigitsMonth = date.getMonth();
      const twoDigitsDay = date.getDate();

      const timezone = `${date.getFullYear()}/${addZero(twoDigitsMonth, true)}/${addZero(twoDigitsDay)} - ${addZero(twoDigitsHours)}:${addZero(
        twoDigitsMinutes
      )}:${addZero(twoDigitsSeconds)}`;
      text = `${message.props.children[0].toString().replace('.', '')} at ${getCityTimezone(timezone)}`;
    }
  }
  return {
    type: 'system',
    text,
    translated_text: translation,
    className: styles.systemMessage,
    twilioIndex
  };
}

function addZero(i, isMonth = false) {
  let numberToChange = i;
  if (isMonth) {
    numberToChange += 1;
  }
  if (numberToChange < 10) {
    numberToChange = `0${numberToChange}`;
  }
  return numberToChange;
}

export function urlify(text) {
  const urlRegex = /((https?:\/\/|www\.)[^\s]+\.[^\s]+)/g;
  const regexExclude = /(https?:\/\/|www\.)$/g;
  const regexWww = /(www\.[^\s]+)/g;
  const splitText = text?.split(urlRegex) || [];
  const parsedText = splitText.map((t) => {
    if (regexExclude.test(t)) {
      return '';
    }
    if (urlRegex.test(t)) {
      let url = t;

      if (regexWww.test(t) && !t.includes('http') && !t.includes('https')) {
        url = `http://${t}`;
      }
      return (
        <a
          href={url}
          onClick={() => {
            Mixpanel.track('Clicked link in chat', {
              url: t,
              message: text
            });
          }}
          rel="noopener noreferrer"
          target="_blank"
          key={text}>
          {t}
        </a>
      );
    }
    return t;
  });
  return <p>{parsedText}</p>;
}

export function formatDate(date) {
  const dateObject = new Date(date);
  return moment(dateObject).format(DATE_FORMAT);
}

function downloadFile(uri) {
  const link = document.createElement('a');
  link.href = uri;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function avatarImage(letters) {
  let canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const size = 100;

  // Generate a random color every time function is called
  // eslint-disable-next-line
  const color = '#' + ((Math.random() * 0xffffff) << 0).toString(16);

  // Set canvas with & height
  canvas.width = size;
  canvas.height = size;

  // Select a font family to support different language characters
  // like Arial
  context.font = `${Math.round(canvas.width / 2)}px Arial`;
  context.textAlign = 'center';

  // Setup background and front color
  context.fillStyle = color;
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.fillStyle = '#FFF';
  context.fillText(letters, size / 2, size / 1.5);

  // Set image representation in default format (png)
  const dataURI = canvas.toDataURL();

  // Dispose canvas element
  canvas = null;

  return dataURI;
}

function makeClickable(text, path, redirectFunction) {
  return (
    <button className={styles.messageAuthor} onClick={() => redirectFunction(path)}>
      {text}
    </button>
  );
}

export async function getFormattedMessage(item, user, members, redirectFunction) {
  if (item.attributes.systemMessage) {
    let translatedText;
    try {
      const translationResponse = await axios.post(
        '/api/translate/',
        { text: item.body, twilio_sid: item.sid, target_lang: user.preferred_language },
        {
          headers: { Authorization: `Token ${user.token}` }
        }
      );

      translatedText = translationResponse.data.translated_text;
    } catch (e) {
      translatedText = '';
    }

    return {
      message: getSystemMessage(urlify(item.body), urlify(translatedText), item.dateUpdated, item.index)
    };
  }

  let author;
  if (members) {
    author = members.find((member) => item.author === member.twilioIdentity);
    if (author === undefined || author === null) {
      author = members.find((member) => item.author === member.email);
    }
    if (author === undefined || author === null) {
      author = members.find((member) => item.author === `${member.firstName} ${member.lastName}`);
    }
  }

  let authorName;
  let authorAvatar;
  let authorEmail;

  if (author !== undefined) {
    authorName = `${author.firstName} ${author.lastName} ${author.userType === 'tutor' ? '(coach)' : ''}`;
    authorAvatar = author.profilePicture !== undefined ? author.profilePicture : ChatterboxLogo;
    authorEmail = author.email;
  } else {
    authorName = item.author;
    authorAvatar = ChatterboxLogo;
    authorEmail = item.author;
  }

  const names = authorName?.split(' ') || [];
  let initials = '';
  names.forEach((name) => {
    if (name !== '' && name !== '(coach)') {
      initials += name.charAt(0);
    }
  });

  let newAvatar = false;
  if (authorAvatar === ChatterboxLogo) {
    authorAvatar = avatarImage(initials);
    newAvatar = true;
  }

  const messageObject = {
    message: {
      position: authorEmail === user.email ? 'right' : 'left',
      titleColor: '#000000',
      date: item.state.timestamp,
      avatar: authorAvatar,
      status: 'read',
      className: authorEmail === user.email ? styles.messageBoxCurrentUser : styles.messageBox,
      sid: item.sid,
      twilioIndex: item.index
    },
    updatedMember: {
      img: authorAvatar,
      newAvatar,
      email: author !== undefined ? author.email : undefined
    }
  };

  if (author && author.userType === 'tutor') {
    messageObject.message.title = makeClickable(authorName, `tutor-preview/${author.id}/`, redirectFunction);
  } else {
    messageObject.message.title = authorName;
  }

  if (item.type === 'text') {
    let translatedText;
    try {
      const translationResponse = await axios.post(
        '/api/translate/',
        { text: item.body, twilio_sid: item.sid, target_lang: user.preferred_language },
        {
          headers: { Authorization: `Token ${user.token}` }
        }
      );
      translatedText = translationResponse.data.translated_text;
    } catch (e) {
      translatedText = '';
    }

    messageObject.message.text = urlify(item.body);
    messageObject.message.translated_text = urlify(translatedText);
    messageObject.message.type = item.type;
  } else if (item.type === 'media') {
    const fileUrl = await item.media.getContentTemporaryUrl();
    messageObject.message.data = {
      uri: fileUrl,
      size: `${Math.round(item.media.state.size / 1000)} KB`
    };
    if (item.attributes.fileType === 'image') {
      messageObject.message.type = 'photo';
    } else {
      messageObject.message.type = 'file';
      messageObject.message.text = item.media.state.filename;
      messageObject.message.data.status = {
        download: false,
        click: false
      };
    }
    messageObject.message.onClick = () => {
      downloadFile(fileUrl);
      Mixpanel.track('Clicked on file in Chat', {
        url: fileUrl,
        name: item.media.state.filename,
        type: messageObject.message.type
      });
    };
  }
  return messageObject;
}

export function getSystemDefaultCopy(messageName, preferredLanguage, context) {
  if (messageName === 'introductory') {
    const { memberNames } = context;
    const systemMessageCopy = `This is the beginning of your conversation with ${memberNames}. You can send messages,
  images and files to prepare for your Live Practices and discuss your schedule. Happy learning!`;
    let systemMessageCopyTranslated = systemMessageCopy;
    if (preferredLanguage == 'it') {
      systemMessageCopyTranslated = `Questo è l'inizio della tua conversazione con ${memberNames}. Puoi inviare messaggi, immagini e file per preparare le tue lezioni e discutere il tuo programma. Buon studio!`;
    }
    return getSystemMessage(systemMessageCopy, systemMessageCopyTranslated);
  } else if (messageName === 'thanksForMessage') {
    const { langHelperFirstName } = context;
    const systemMessageCopy = `Thanks for your message. ${langHelperFirstName} will get back to you within 24hrs during weekdays.`;
    let systemMessageCopyTranslated = systemMessageCopy;
    if (preferredLanguage == 'it') {
      systemMessageCopyTranslated = `Grazie per il tuo messaggio. ${langHelperFirstName} ti ricontatterà entro 24 ore durante i giorni lavorativi.`;
    }
    return getSystemMessage(systemMessageCopy, systemMessageCopyTranslated);
  } else {
    return getSystemMessage('', '');
  }
}
