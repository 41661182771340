import { combineReducers } from 'redux';
import { NewOnboardingActionTypes } from '../actions/new-onboarding';

const initialInformationInitialState = {};

function initialInformationReducer(state = initialInformationInitialState, action) {
  switch (action.type) {
    case NewOnboardingActionTypes.STORE_DECODED_TOKEN:
      return { ...state, ...action.payload };
    case NewOnboardingActionTypes.UPDATE_INITIAL_INFORMATION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

const personalInterestInitialState = {
  isLoadingGetSelectedInterest: false,
  hasPostSelectedInterestError: false,
  isLoadingPostSelectedInterest: false,
  selectedInterest: ''
};

function personalInterestReducer(state = personalInterestInitialState, action) {
  switch (action.type) {
    case NewOnboardingActionTypes.POST_INTEREST:
      return { ...state, isLoadingPostSelectedInterest: true, hasPostSelectedInterestError: false };
    case NewOnboardingActionTypes.POST_INTEREST_SUCCESS:
      return {
        ...state,
        isLoadingPostSelectedInterest: false,
        hasPostSelectedInterestError: false,
        selectedInterest: action.payload
      };
    case NewOnboardingActionTypes.POST_INTEREST_FAIL:
      return { ...state, isLoadingPostSelectedInterest: false, hasPostSelectedInterestError: true };
    default:
      return state;
  }
}

const pickCoachInitialState = {
  hasGetSelectedCoachError: false,
  isLoadingGetSelectedCoach: false,
  hasPostSelectedCoachError: false,
  isLoadingPostSelectedCoach: false,
  selectedCoach: {}
};

function pickCoachReducer(state = pickCoachInitialState, action) {
  switch (action.type) {
    case NewOnboardingActionTypes.POST_SELECTED_COACH:
      return { ...state, isLoadingPostSelectedCoach: true, hasPostSelectedCoachError: false };
    case NewOnboardingActionTypes.POST_SELECTED_COACH_SUCCESS:
      return {
        ...state,
        isLoadingPostSelectedCoach: false,
        hasPostSelectedCoachError: false,
        selectedCoach: action.payload
      };
    case NewOnboardingActionTypes.POST_SELECTED_COACH_FAIL:
      return { ...state, isLoadingPostSelectedCoach: false, hasPostSelectedCoachError: true };
    default:
      return state;
  }
}

const placementTestInitialState = {
  hasRetrievePlacementTestError: false,
  retrievePlacementTestError: '',
  isRetrievingPlacementTest: false,
  placementTest: '',
  hasFinishedPlacementTest: true
};

function placementTestReducer(state = placementTestInitialState, action) {
  switch (action.type) {
    case NewOnboardingActionTypes.HAS_FINISHED_PLACEMENT_TEST:
      return { ...state, hasFinishedPlacementTest: action.payload };
    case NewOnboardingActionTypes.RETRIEVE_PLACEMENT_TEST_RESULTS:
      return { ...state, isRetrievingPlacementTest: true, hasRetrievePlacementTestError: false };
    case NewOnboardingActionTypes.RETRIEVE_PLACEMENT_TEST_RESULTS_SUCCESS:
      return {
        ...state,
        isRetrievingPlacementTest: false,
        hasRetrievePlacementTestError: false,
        placementTest: action.payload,
        hasFinishedPlacementTest: true
      };
    case NewOnboardingActionTypes.RETRIEVE_PLACEMENT_TEST_RESULTS_FAIL:
      return {
        ...state,
        isRetrievingPlacementTest: false,
        hasRetrievePlacementTestError: true,
        hasFinishedPlacementTest: true,
        retrievePlacementTestError: action.payload
      };
    default:
      return state;
  }
}

const previousOnboardingInitialState = {
  hasGetPreviousOnboardingError: false,
  isLoadingGetPreviousOnboarding: false,
  previousOnboarding: {}
};

function previousOnboardingReducer(state = previousOnboardingInitialState, action) {
  switch (action.type) {
    case NewOnboardingActionTypes.GET_ONBOARDING_DATA:
      return {
        ...state,
        isLoadingGetPreviousOnboarding: true,
        hasGetPreviousOnboardingError: false
      };
    case NewOnboardingActionTypes.GET_ONBOARDING_DATA_SUCCESS:
      return {
        ...state,
        isLoadingGetPreviousOnboarding: false,
        hasGetPreviousOnboardingError: false,
        previousOnboarding: action.payload
      };
    case NewOnboardingActionTypes.GET_ONBOARDING_DATA_FAIL:
      return {
        ...state,
        isLoadingGetPreviousOnboarding: false,
        hasGetPreviousOnboardingError: true
      };
    default:
      return state;
  }
}

export default combineReducers({
  initialInformation: initialInformationReducer,
  personalInterest: personalInterestReducer,
  pickCoach: pickCoachReducer,
  placementTest: placementTestReducer,
  previousOnboarding: previousOnboardingReducer
});
