import { supportChatName, supportMessageOwners, supportMessageTypes } from '../../../../domains/helpdesk/chat';
import InputSupportMessage from '../UserInteractions/input-message';
import ListTickets from '../UserInteractions/ListTickets';
import SelectAction from '../UserInteractions/select-action';
import SelectType from '../UserInteractions/select-type';

export const introduction = ({ user }) => [
  {
    title: supportChatName,
    content: `Hey ${user?.first_name}, welcome to the Support Helper chat!`,
    type: supportMessageTypes.text,
    owner: supportMessageOwners.chatterbox
  }
];

export const noPreviousTickets = () => [
  {
    title: supportChatName,
    content: `We checked and it seems that you haven't created any requests yet.`,
    type: supportMessageTypes.text,
    owner: supportMessageOwners.chatterbox
  }
];

export const listPreviousTickets = ({ userTickets }) => [
  {
    title: supportChatName,
    content: 'We checked and here is a list of your latest tickets opened:',
    type: supportMessageTypes.text,
    owner: supportMessageOwners.chatterbox
  },
  {
    title: supportChatName,
    component: <ListTickets userTickets={userTickets} />,
    type: supportMessageTypes.typing,
    owner: supportMessageOwners.chatterbox
  }
];

export const doYouWantToCreate = ({ setAction, user }) => [
  {
    title: supportChatName,
    content: 'Do you want to create a new Ticket?',
    type: supportMessageTypes.text,
    owner: supportMessageOwners.chatterbox
  },
  {
    title: `${user?.first_name}`,
    component: <SelectAction setAction={setAction} />,
    type: supportMessageTypes.selection,
    owner: supportMessageOwners.user
  }
];

export const createTicketIntro = ({ setSelectedSupportType, user }) => [
  {
    title: supportChatName,
    content:
      'First, we have a few broader categories to try to figure out what is happening. Please select one of the following topics in order to proceed:',
    type: supportMessageTypes.text,
    owner: supportMessageOwners.chatterbox
  },
  {
    title: user?.first_name,
    type: supportMessageTypes.selection,
    component: <SelectType setSelectedSupportType={setSelectedSupportType} />,
    owner: supportMessageOwners.user
  }
];

export const askDetails = ({ selectedSupportType, setAttachments, setDescription }) => [
  {
    title: supportChatName,
    content: `All right, so you need help with ${selectedSupportType}, right?`,
    type: supportMessageTypes.text,
    owner: supportMessageOwners.chatterbox
  },
  {
    title: supportChatName,
    content: 'Can you describe in details your issue, please?',
    type: supportMessageTypes.text,
    owner: supportMessageOwners.chatterbox
  },
  {
    type: supportMessageTypes.typing,
    component: <InputSupportMessage setDescription={setDescription} setAttachments={setAttachments} />,
    owner: supportMessageOwners.user
  }
];

export const createdSuccessfully = ({ ticketCreated }) => [
  {
    title: supportChatName,
    content: `Your ticket was successfully created! Ticket ID:${ticketCreated?.id} and SUBJECT:${ticketCreated?.subject}.`,
    type: supportMessageTypes.text,
    owner: supportMessageOwners.chatterbox
  },
  {
    title: supportChatName,
    content: 'An email was sent to you. You can check the status of your request through your e-mail or through this Support Helper chat.',
    type: supportMessageTypes.text,
    owner: supportMessageOwners.chatterbox
  },
  {
    title: supportChatName,
    content: 'Have a good one!',
    type: supportMessageTypes.text,
    owner: supportMessageOwners.chatterbox
  }
];
