import { NEXT_PAGE, PREVIOUS_PAGE, SET_PAGE, RESET_PAGE } from './types';

export function nextPage() {
  return async (dispatch) => {
    dispatch({
      type: NEXT_PAGE,
      payload: {}
    });
  };
}

export function prevPage() {
  return async (dispatch) => {
    dispatch({
      type: PREVIOUS_PAGE,
      payload: {}
    });
  };
}

export function resetPage() {
  return async (dispatch) => {
    dispatch({
      type: RESET_PAGE,
      payload: {}
    });
  };
}

export function setPage() {
  return async (dispatch) => {
    dispatch({
      type: SET_PAGE,
      payload: {}
    });
  };
}
