import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';

import styles from '../../style/components/invoices/InvoiceTable.module.scss';
import { capitalize, getHumanFriendlyFromSnakeCase } from '../../utils/string-helpers';
import { getMoment } from '../../utils/time-helpers';

const getAppointmentTypeDisplay = (appointment) => {
  let msg = `${capitalize(appointment.training_type)} — ${appointment.delivery_type} ${appointment.duration} min`;

  if (appointment.is_group_session) {
    msg = `${msg} (group)`;
  }

  return msg;
};

const InvoiceTable = ({ appointments, timeZone, withTotals }) => {
  const getFee = (appt) => parseFloat(appt.tutor_fee) || 0;
  const apptFees = appointments.map(getFee);

  // Rounding after each addition to avoid floating-point errors
  const add = (a, b) => Math.round((a + b) * 100) / 100;
  const apptTotalFees = apptFees.reduce(add, 0);

  return (
    <Table bordered className={styles.table}>
      <thead>
        <tr>
          <th className="text-center">Date</th>
          <th className="text-center">Time</th>
          <th className="text-center">Appointment Type</th>
          <th className="text-center">Status</th>
          <th className="text-center">Hourly Rate</th>
          <th className="text-center">Your Pay</th>
        </tr>
      </thead>

      <tbody>
        {appointments.map((appt) => {
          const apptMoment = getMoment(appt.date, appt.start_time, timeZone);

          return (
            <tr key={appt.id}>
              <td>{apptMoment.format('D MMMM')}</td>
              <td className="text-center">{appt.start_time}</td>
              <td>{getAppointmentTypeDisplay(appt)}</td>
              <td>{getHumanFriendlyFromSnakeCase(appt.status)}</td>
              <td className="text-right">£{(parseFloat(appt.hourly_rate) || 0).toFixed(2)}</td>
              <td className="text-right">£{(parseFloat(appt.tutor_fee) || 0).toFixed(2)}</td>
            </tr>
          );
        })}

        {appointments.length < 1 && (
          <tr>
            <td className="active text-muted" colSpan="6">
              No invoiceable appointments
            </td>
          </tr>
        )}

        {withTotals && withTotals !== 'false' && (
          <tr className={styles.lastRow}>
            <td colSpan="4">Total Live Practices: {appointments.length}</td>
            <td className={`${styles.totalPayLabel} text-right`}>Total pay:</td>
            <td className={`${styles.totalPayAmount} text-right`}>£{apptTotalFees.toFixed(2)}</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

InvoiceTable.propTypes = {
  appointments: PropTypes.array,
  timeZone: PropTypes.string.isRequired,
  withTotals: PropTypes.string
};

InvoiceTable.defaultProps = {
  appointments: [],
  withTotals: 'false'
};

export default InvoiceTable;
