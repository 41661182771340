import React from 'react';
import PropTypes from 'prop-types';
import { supportMessageTypes } from '../../../../domains/helpdesk/chat';

import styles from './SupportMessage.module.scss';

const Message = ({ message }) => {
  let msg;
  if (message?.type === supportMessageTypes.text)
    msg = (
      <p className={styles[`${message?.type}__${message?.owner}`]}>
        <span>{message?.title}</span>
        {message?.content}
      </p>
    );

  if (message?.type === supportMessageTypes.selection)
    msg = (
      <div className={styles[`${message?.type}__${message?.owner}`]}>
        <span>{message?.title}</span>
        {message?.component}
      </div>
    );

  if (message?.type === supportMessageTypes.typing)
    msg = (
      <div className={styles[`${message?.type}__${message?.owner}`]}>
        <span>{message?.title}</span>
        {message?.component}
      </div>
    );

  return <div className={[styles.messageContainer, styles[message?.owner]].join(' ')}>{msg}</div>;
};

Message.propTypes = {
  message: PropTypes.shape({
    content: PropTypes.string,
    owner: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    component: PropTypes.element
  })
};

export default Message;
