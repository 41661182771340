import React, { Component } from 'react';
import jstz from 'jstimezonedetect';
import axios from 'axios';
import { format } from 'date-fns';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Alert, ControlLabel, Form, FormControl, FormGroup, Checkbox } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import { createAppointment } from '../../actions/appointments';
import submitBookingFeedback from '../../actions/booking-feedback';
import { getBookingInfoForTutorForTimespan, resetBookingInfo, getFutureSchedule } from '../../actions/bookable';
import styles from '../../style/components/tutor-booking-page/BookingForm.module.scss';
import appStyles from '../../style/containers/App.module.scss';
import formStyles from '../../style/utils/_form.module.scss';
import Card from '../uikit/Card';
import EmailListEntry from '../uikit/EmailListEntry';
import ErrorAlert from '../uikit/ErrorAlert';
import ErrorAlertBrc from '../uikit/BrcErrorAlert';
import Button from '../design-system/button/Button';

import { getAppointmentName } from '../../utils/appointment-helpers';
import { DEFAULT_ERROR_MESSAGE } from '../../utils/error-helpers';
import { getTimeZoneChoices, getDiffDaysBetweenNowAndDate } from '../../utils/time-helpers';
import ButtonModal from '../uikit/ButtonModal';
import PurchaseCreditsModal from '../uikit/PurchaseCreditsModal';
import { capitalize } from '../../utils/string-helpers';
import Mixpanel from '../../utils/mixpanel-helper.js';
import Calendar from '../design-system/calendar/Calendar';
import { TwilioConversation } from '../../services/chat/twilio';

const DEAFAULT_TIME_ZONE = 'Europe/London';
const BOOKING_ROW_HEIGHT = 22;
const BOOKING_HEADER_HEIGHT = 27;

class BookingForm extends Component {
  constructor(props) {
    super();

    const guessTimeZone = props.timezone === undefined || props.timezone === DEAFAULT_TIME_ZONE;
    const tz = guessTimeZone ? jstz.determine().name() : props.timezone;

    const bookMultiple = props.productType.trainingType !== undefined ? !props.productType.trainingType.includes('training') : true;

    this.state = {
      accountUpdated: false,
      accountUpdatedError: false,
      appointmentCreatedError: false,
      appointmentCreatedErrorMessage: null,
      availableSchedule: {},
      bookableMoments: [],
      bookableMomentsFormatted: [],
      comment: '',
      date: null,
      invitees: [],
      isLoading: false,
      lessonNumber: this.getLessonNumber(props),
      notEnoughDataError: false,
      phoneNumber: props.student.phone_number,
      requiredPrompt: false,
      skypeId: props.student.skype_id,
      studentTimeZone: tz,
      timeOptions: [],
      isOpenSession: false,
      isAutoAccept: true,
      inviteOtherStudents: false,
      previousInvites: {},
      bookMultiple,
      firstRecurringRender: true,
      showBuyCredits: false,
      hideBooking: false,
      appointmentCreatedErrorBrc: false,
      dateMoments: [],
      isCalendarLoading: false,
      moreAvailabilities: false,
      dialectsOrLanguage: false,
      moreTutors: false,
      feedbackDetails: '',
      allowBookableUpdate: true,
      loadingReschedules: false
    };
    this.setTimeZone(tz, { isUpdate: false });
  }

  componentDidMount() {
    const { isGroupSession, invitations, user } = this.props;
    this.twilioChat = TwilioConversation.getInstance(user);
    if (isGroupSession && invitations) {
      const previousInvites = invitations;
      Object.keys(previousInvites).forEach((key) => {
        if (key === 'invitees') {
          const newkey = 'addresses';
          previousInvites[newkey] = previousInvites[key];
          delete previousInvites[key];
        }
      });
      if (previousInvites.addresses && previousInvites.addresses.length > 0) {
        this.setPreviousGroupSettings(previousInvites);
        this.handleChangeCheckbox('inviteOtherStudents', {
          target: { checked: true }
        });
      } else {
        this.setPreviousGroupSettings();
        this.handleChangeCheckbox('isOpenSession', {
          target: { checked: true }
        });
      }
    }
    const dateStrings = [];
    const dateMomentsArray = [];
    this.state.bookableMoments.forEach((bookableMoment, index) => {
      const dateString = bookableMoment.format('YYYY-MM-DD');
      const dateIsInArray = dateStrings.includes(dateString);

      if (!dateIsInArray) {
        dateStrings.push(dateString);
        dateMomentsArray.push(bookableMoment);
      }
      if (index === this.state.bookableMoments.length - 1) {
        this.setState({ dateMoments: dateMomentsArray });
      }
    });

    const newDate = new Date();
    const nextMonth = new Date(newDate.getTime());
    nextMonth.setMonth(nextMonth.getMonth() + 1);

    this.props.getBookingInfoForTutorForTimespan({
      token: this.props.student.token,
      tutorId: this.props.tutorId,
      productTypeId: this.props.productType.productTypeId,
      selectedLanguageId: this.props.language.id,
      from: newDate,
      to: nextMonth
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const timeZone = this.state.studentTimeZone;
    let bookableMoments = [];
    if (newProps.bookableDatesAndTimesISO) bookableMoments = newProps.bookableDatesAndTimesISO.map((s) => moment.tz(s, timeZone));

    const dateStrings = [];
    const dateMomentsArray = [];
    const availableSchedule = {};
    bookableMoments.forEach((bookableMoment) => {
      const dateString = bookableMoment.format('YYYY-MM-DD');
      const dateIsInArray = dateStrings.includes(dateString);

      const dateISO = bookableMoment.toISOString();
      const formattedDateString = format(new Date(dateISO), 'yyyy-LL-dd');
      if (availableSchedule[formattedDateString]) {
        availableSchedule[formattedDateString].push(dateISO);
      } else {
        availableSchedule[formattedDateString] = [dateISO];
      }

      if (!dateIsInArray) {
        dateStrings.push(dateString);
        dateMomentsArray.push(bookableMoment);
      }
    });

    const bookableMomentsFormatted = bookableMoments.map((m) => m.format('YYYY-MM-DD'));

    if (this.state.allowBookableUpdate === true) {
      this.setState(
        {
          allowBookableUpdate: false,
          availableSchedule,
          bookableMoments,
          bookableMomentsFormatted,
          dateMoments: dateMomentsArray,
          // date: bookableMoments.length > 0 ? bookableMoments[0] : undefined,
          lessonNumber: this.getLessonNumber(newProps),
          timeOptions: bookableMoments.length > 0 ? this.getTimeSlotsForDate(bookableMoments[0], bookableMoments, bookableMomentsFormatted) : []
        },
        () => {
          this.setTimeZone(timeZone, { isUpdate: false });
        }
      );
    }
  }

  componentWillUnmount() {
    this.props.resetBookingInfo();
  }

  onAccountUpdated = () => {
    this.setState({
      accountUpdated: true,
      accountUpdatedError: false
    });
  };

  onAccountUpdatedError = () => {
    this.setState({
      accountUpdated: false,
      accountUpdatedError: true
    });
  };

  onAppointmentCreated = (response) => {
    const { appointmentData } = this.state;
    const { tutorProfile } = this.props;
    Mixpanel.track('Booking Success', appointmentData);
    if (response && response.headers.booking_exception) {
      const successBookings = response.headers.success_bookings;
      if (response.headers.booking_exception === 'pastFeb') {
        const allBookings = response.headers.all_bookings;
        toast.error(`You booked ${successBookings} Live Practices successfully out of ${allBookings}.
        This program finishes February 5th, 2020.
        Please schedule all your Live Practices prior to that date.`);
      } else if (response.headers.booking_exception === 'notEnoughCreditsBRC') {
        toast.error(`You booked ${successBookings} Live Practices successfully. \
        Unfortunately this program has run out and all the free language Live Practices have been used. \
        If you'd like to book more Live Practices, please purchase credits in our store. \
        Thank you for participating! ~ The Chatterbox team`);
      }
    } else {
      toast.success('All booked! You should receive an email shortly with your booking confirmation and further details.');
    }

    this.twilioChat.getClient().then((client) => {
      // Create a Channel
      let channelName = '';
      if (response.data.is_group_session) {
        channelName = `${tutorProfile.first_name}`;
        response.data.students.forEach((student) => {
          channelName += `, ${student.first_name}`;
        });
      } else {
        channelName = `${tutorProfile.first_name}, ${this.props.student.first_name}`;
      }
      client
        .createChannel({
          uniqueName: response.data.chat,
          friendlyName: channelName,
          attributes: {
            image: tutorProfile.profile_picture,
            participantNames: [
              {
                name: tutorProfile.first_name,
                fullName: `${tutorProfile.first_name} ${tutorProfile.last_name}`,
                email: tutorProfile.email
              },
              {
                name: this.props.student.first_name,
                fullName: `${this.props.student.first_name} ${this.props.student.last_name}`,
                email: this.props.student.email
              }
            ],
            isGroupSession: response.data.is_group_session
          }
        })
        .then((channel) => {
          channel.join().then((joinedChannels) => {
            const date = new Date();
            const timezone = `${this.addZero(date.getUTCFullYear())}/${this.addZero(date.getUTCMonth(), true)}/${this.addZero(date.getUTCDate())}
           - ${this.addZero(date.getUTCHours())}:${this.addZero(date.getUTCMinutes())}:${this.addZero(date.getUTCSeconds())}`;
            joinedChannels.sendMessage(timezone, {
              systemMessage: true
            });
            const welcomeMessage = `This is the beginning of your conversation with ${response.data.tutor.first_name}. You can send messages, images and files to prepare for your Live Practices and discuss your
             schedule. Happy learning!`;
            joinedChannels.sendMessage(welcomeMessage, {
              systemMessage: true
            });
          });
        })
        .catch(() => {
          client.getChannelByUniqueName(response.data.chat.toString()).then((alreadyJoinedChannel) => {
            const dateMoment = moment.utc(`${response.data.date} ${response.data.start_time}`);
            dateMoment.tz(this.state.studentTimeZone);
            const welcomeMessage = `A session is booked on the ${dateMoment.format('MMMM Do, YYYY [at] H:mm')} (${this.state.studentTimeZone} time)`;
            alreadyJoinedChannel.sendMessage(welcomeMessage, {
              systemMessage: true
            });
          });
        });
    });
    if (response && response.data && response.data.language_helper) {
      const { student } = this.props;
      const languageHelper = response.data.language_helper;
      this.twilioChat.getClient().then((studentClient) => {
        studentClient.getSubscribedConversations().then(async (paginator) => {
          let channelId;
          for (let i = 0; i < paginator.items.length; i += 1) {
            const members = paginator.items[i].state.attributes.participantNames;
            const memeberFound = members.find((m) => m.email === languageHelper.email);
            if (memeberFound !== undefined && paginator.items[i].state.attributes.isGroupSession !== true) {
              channelId = paginator.items[i].uniqueName;
              break;
            }
          }

          if (channelId === undefined) {
            const languageHelperChannelName = `${languageHelper.first_name}, ${student.first_name}`;
            const searchedChannel = paginator.items.find((ch) => ch.state.friendlyName === languageHelperChannelName);
            if (searchedChannel === undefined) {
              const headers = { Authorization: `Token ${student.token}` };

              const data = {
                tutorId: languageHelper.id,
                studentId: student.id,
                isTutorChat: false,
                language: appointmentData.language
              };
              // Creates a chat in DB for tracking purposes
              const languageHelperResponse = await axios.post('/api/create_chat/', data, {
                headers
              });

              // Creates a chat in twillio cloud
              studentClient
                .createChannel({
                  uniqueName: languageHelperResponse.data.chat_id,
                  friendlyName: languageHelperChannelName,
                  attributes: {
                    image: languageHelper.profile_picture,
                    courseLeaderChat: true,
                    participantNames: [
                      {
                        name: languageHelper.first_name,
                        fullName: `${languageHelper.first_name} ${languageHelper.last_name}`,
                        email: languageHelper.email
                      },
                      {
                        name: student.first_name,
                        fullName: `${student.first_name} ${student.last_name}`,
                        email: student.email
                      }
                    ],
                    isGroupSession: true
                  }
                })
                .then((channel) => {
                  channel.join().then((joinedChannels) => {
                    const welcomeMessage = `This is the beginning of your conversation with your ${languageHelper.language} Language teacher ${languageHelper.first_name}. \
                      You can send messages here to get help with any language doubts or questions you have.`;
                    joinedChannels.sendMessage(welcomeMessage, {
                      systemMessage: true
                    });
                  });
                });
            }
          }
        });
      });
    }

    if (this.state.accountUpdated) {
      toast.success('Your contact info has been updated on your account.');
    }
    let resourceURL = null;
    if (appointmentData.lesson_number) {
      const courseId = appointmentData.courseId;
      resourceURL = `/study-area/${courseId}/`;
    }
    this.props.finishBooking(resourceURL);
  };

  onAppointmentCreatedError = (message) => {
    const { appointmentData } = this.state;

    const trackingData = { ...message, ...appointmentData };
    let showBuyCredits = false;
    let hideBooking = false;
    let showBrcError = false;
    if (message && Array.isArray(message)) {
      message.forEach((msg) => {
        if (msg.includes('credits below')) {
          showBuyCredits = true;
          hideBooking = true;
        } else if (msg.includes('Feb 5th')) {
          showBrcError = true;
        }
      });
    }
    Mixpanel.track('Booking Failed', trackingData);
    if (showBrcError) {
      this.setState({
        isLoading: false,
        appointmentCreatedErrorBrc: true,
        showBuyCredits,
        hideBooking
      });
    } else {
      this.setState({
        isLoading: false,
        appointmentCreatedError: true,
        appointmentCreatedErrorMessage: message,
        showBuyCredits,
        hideBooking
      });
    }
  };

  onDateChange = (event) => {
    const date = event.target.value;
    this.setState({ date, timeOptions: this.getTimeSlotsForDate(date) });
  };

  onLessonNumberChange = (event) => {
    const lessonNumber = event.target.value;
    this.setState({ lessonNumber });
  };

  onTimeZoneChange = (event) => {
    this.setTimeZone(event.target.value);
  };

  setPreviousGroupSettings = (previousInvites) => {
    if (previousInvites) {
      this.setState({ previousInvites, inviteOtherStudents: true });
    } else {
      this.setState({ isOpenSession: true });
    }
  };

  getLessonNumber = (props) => {
    if (props.bookableLessonNumbers) {
      if (props.productType.trainingType === 'tuition' || props.productType.trainingType === 'tuition training') {
        if (props.bookableLessonNumbers.length) {
          return props.bookableLessonNumbers[0];
        }
      }
    } else {
      return undefined;
    }
  };

  getTimeZonePairs = () => {
    const choices = getTimeZoneChoices();

    return choices.map((choice) => [choice.timeZone, choice.displayName]);
  };

  setTimeZone = (timeZone, { isUpdate = true } = {}) => {
    const bookableMoments = this.state.bookableMoments.map((m) => m.clone().tz(timeZone));
    const bookableMomentsFormatted = bookableMoments.map((m) => m.format('YYYY-MM-DD'));

    const stateUpdate = {
      bookableMoments,
      bookableMomentsFormatted,
      studentTimeZone: timeZone,
      timeOptions: this.getTimeSlotsForDate(this.state.date, bookableMoments, bookableMomentsFormatted)
    };

    if (isUpdate) {
      const forceUpdate = true;
      this.setState(stateUpdate, this.handleTimestampSelection.bind(this, this.state.date, forceUpdate));
    } else {
      // eslint-disable-next-line
      this.state = { ...this.state, ...stateUpdate };
    }
  };

  setDoneLoadingReschedules() {
    this.setState({
      loadingReschedules: false
    });
  }

  getTimeSlotsForDate = (date, bookableMoments, bookableMomentsFormatted) => {
    let moments = bookableMoments;
    let momentsFormatted = bookableMomentsFormatted;

    if (!moments) {
      moments = this.state.bookableMoments;
    }

    if (!momentsFormatted) {
      momentsFormatted = this.state.bookableMomentsFormatted;
    }

    if (date) {
      const formattedDate = date.format('YYYY-MM-DD');
      return moments.filter((_m, index) => momentsFormatted[index] === formattedDate);
    }
    return [];
  };

  addZero = (i, isMonth = false) => {
    let numberToChange = i;
    if (isMonth) {
      numberToChange += 1;
    }
    if (numberToChange < 10) {
      numberToChange = `0${numberToChange}`;
    }
    return numberToChange;
  };

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleChangeCheckbox = (checkboxId, event) => {
    if (checkboxId === 'inviteOtherStudents') {
      this.setState({
        [checkboxId]: event.target.checked,
        inviteOtherStudents: !this.state.inviteOtherStudents
      });
    } else if (checkboxId === 'bookOne') {
      this.setState({
        bookMultiple: !event.target.checked
      });
    } else {
      this.setState({
        [checkboxId]: event.target.checked
      });
    }
  };

  handleInviteeChange = (invitees) => {
    this.setState({ invitees });
  };

  finishSubmit = () => {
    const { productType, student, language, courseId, recurringReschedules } = this.props;
    const { bookMultiple, isAutoAccept } = this.state;
    const isBookMultiple = bookMultiple && recurringReschedules.length > 0;
    const momentString = this.state.date.format('YYYY-MM-DD HH:mm');

    const appointmentData = {
      product_type_id: productType && productType.productTypeId,
      date: moment.tz(momentString, this.state.studentTimeZone).utc().format('YYYY-MM-DD'),
      invitees: this.state.invitees.addresses,
      inviteeNames: this.state.invitees.names,
      lesson_number: this.state.lessonNumber,
      phone_number: this.state.phoneNumber,
      start_time: moment.tz(momentString, this.state.studentTimeZone).utc().format('HH:mm'),
      status: 'booked',
      student_comment: this.state.comment,
      time_zone: this.state.studentTimeZone,
      tutor_id: this.props.tutorId,
      language: language.language,
      is_for_group_sessions: this.props.isGroupSession,
      is_student_beta_tester: student.is_beta_tester,
      duration: productType.duration,
      recurring_reschedule: isBookMultiple,
      courseId,
      auto_accept: student.is_staff ? isAutoAccept : false
    };

    const errorCallback = (error) => {
      let errMsg = DEFAULT_ERROR_MESSAGE;

      if (error && error.response && error.response.data) {
        errMsg = error.response.data;
      }

      this.onAppointmentCreatedError(errMsg);
    };

    const successCallback = (response) => this.onAppointmentCreated(response);

    const recurringReschedulesSelected = [];
    if (isBookMultiple) {
      recurringReschedules.forEach((reschedule) => {
        const recurringMomentString = reschedule.format('YYYY-MM-DD HH:mm');
        const rescheduleDate = moment.tz(recurringMomentString, this.state.studentTimeZone).utc().format('YYYY-MM-DD');
        const rescheduleStartTime = moment.tz(recurringMomentString, this.state.studentTimeZone).utc().format('HH:mm');
        recurringReschedulesSelected.push({
          date: rescheduleDate,
          start_time: rescheduleStartTime
        });
      });
      appointmentData.recurringReschedules = recurringReschedulesSelected;
    }
    this.setState({ appointmentData });

    this.props.createAppointment(student.token, appointmentData, errorCallback, successCallback);
  };

  submit = () => {
    const { productType, isGroupSession } = this.props;

    if (!productType) {
      this.setState({
        notEnoughDataError: true
      });
    } else {
      const required = ['date'];

      if (this.props.deliveryType === 'in-person') {
        required.push('phoneNumber');
      }

      if (this.props.trainingType === 'tuition' || this.props.trainingType === 'tuition training') {
        required.push('lessonNumber');
      }

      if (isGroupSession) {
        if (!this.state.isOpenSession) {
          required.push('invitees');
        }
      }

      const filledRequired = required.filter((key) => {
        const value = this.state[key];

        if (Array.isArray(value)) {
          return value.length > 0;
        }

        return value && value !== '';
      });

      if (filledRequired.length < required.length) {
        this.setState({
          requiredPrompt: true
        });
      } else {
        this.setState({
          requiredPrompt: false,
          isLoading: true
        });

        this.finishSubmit();
      }
    }
  };

  handleTimestampSelection = (date, forceUpdate = false) => {
    const momentDate = moment.utc(date);
    const timeZone = this.state.studentTimeZone;

    const momentDateInTz = momentDate.tz(timeZone);

    let dateChanged = false;
    if (this.state.date === null || this.state.date === undefined) {
      dateChanged = true;
    } else if (momentDate && this.state.date) {
      dateChanged = !momentDate.isSame(this.state.date);
    }

    if (dateChanged || forceUpdate) {
      this.setState({
        loadingReschedules: true
      });
      this.props.getFutureSchedule(
        this.props.student.token,
        this.props.productType.productTypeId,
        this.props.tutorId,
        date,
        timeZone,
        this.props.courseId,
        this.setDoneLoadingReschedules.bind(this)
      );
    }

    this.setState({
      date: momentDateInTz
    });
  };

  handleFeedbackTextChange = (e) => {
    this.setState({ feedbackDetails: e.target.value });
  };

  isFeedbackFilled = () => {
    const { moreAvailabilities, dialectsOrLanguage, moreTutors, feedbackDetails } = this.state;
    return moreAvailabilities || dialectsOrLanguage || moreTutors || feedbackDetails.length > 0;
  };

  submitBookingFeedback = () => {
    const { moreAvailabilities, dialectsOrLanguage, moreTutors, feedbackDetails } = this.state;
    const { student, tutorProfile } = this.props;
    this.props.submitBookingFeedback(
      student.token,
      moreAvailabilities,
      dialectsOrLanguage,
      moreTutors,
      feedbackDetails,
      tutorProfile.email,
      student.email
    );
    toast.success('Feedback succesfully submitted. Thank you!');
  };

  showMultiBooking = (isGroupSession, creditsLeft) => {
    const { productType, nextLessons } = this.props;
    if (productType.trainingType.includes('training')) {
      return false;
    }
    if (((productType.duration === 30 || isGroupSession) && creditsLeft >= 1) || creditsLeft >= 2) {
      if (productType && (productType.trainingType === 'tuition' || productType.trainingType === 'tuition training')) {
        if (nextLessons && nextLessons.length > 1) {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  closeFeedbackModal = () => {
    this.modal.close();
    this.setState({
      moreAvailabilities: false,
      dialectsOrLanguage: false,
      moreTutors: false,
      feedbackDetails: ''
    });
  };

  handleMonthChange = (newDate) => {
    newDate.setDate(1);
    const nextMonth = new Date(newDate.getTime());
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    this.setState({ allowBookableUpdate: true });
    this.props.getBookingInfoForTutorForTimespan({
      token: this.props.student.token,
      tutorId: this.props.tutorId,
      productTypeId: this.props.productType.productTypeId,
      selectedLanguageId: this.props.language.id,
      from: newDate,
      to: nextMonth
    });
  };

  renderDateOptions = (invalidLessonNumber) => {
    const { isGroupSession, creditsLeft, productType, nextLessons, tutorProfile, recurringReschedules } = this.props;
    const { bookMultiple, loadingReschedules } = this.state;
    const dates = [this.state.date, ...recurringReschedules];
    const daysDiff = getDiffDaysBetweenNowAndDate(dates[0]);
    return (
      <>
        <div className={styles.selectDate}>
          <div className={styles.calendarWrapper}>
            <Calendar
              availableSchedule={this.state.availableSchedule}
              timeZone={this.state.studentTimeZone}
              handleTimestampSelection={this.handleTimestampSelection.bind(this)}
              onMonthChange={this.handleMonthChange}
              isLoading={this.props.isLoadingBookable}
            />
          </div>
          {daysDiff <= 5 && this.state.date && (productType.trainingType === 'tuition' || productType.trainingType === 'tuition training') && (
            <div className={styles.shortInterval}>
              <p>
                <span style={{ marginRight: '7px' }} role="img" aria-label="danger emoji">
                  ⚠️
                </span>
                Wait at least 5 days before your first Live Practice so you’ll have enough time to study all the lesson assignments (units).
              </p>
            </div>
          )}
        </div>
        {this.showMultiBooking(isGroupSession, creditsLeft) && (
          <>
            <>
              <Checkbox
                className={styles.checkbox}
                onChange={(event) => {
                  Mixpanel.track('Click', { button: 'book_only_one_class' });
                  this.handleChangeCheckbox('bookOne', event);
                }}
                checked={this.state.bookOne}
                disabled={invalidLessonNumber}>
                I only want to book one class
              </Checkbox>
              {!this.state.bookMultiple && (
                <p className={styles.informBookingCopy}>
                  <span style={{ marginRight: '5px' }} role="img" aria-label="danger emoji">
                    ⚠️
                  </span>
                  <strong>Consistency is key! We recommend that you schedule all 20 of your lessons.</strong>
                  <br />
                  You will be able to modify bookings up to 24hrs before the class.
                </p>
              )}
            </>
            {this.state.date && (
              <>
                <h2 className={styles.bookingSummaryTitle}>Booking summary</h2>
                {dates.length > 0 && (productType.trainingType === 'tuition' || productType.trainingType === 'tuition training') && bookMultiple && (
                  <div
                    className={styles.bookingSummaryWrapper}
                    style={{
                      height: `${dates.length * BOOKING_ROW_HEIGHT + BOOKING_HEADER_HEIGHT}px`
                    }}>
                    <table className={styles.bookingSummary}>
                      {!loadingReschedules && (
                        <tr>
                          <th>
                            <p>Lesson</p>
                          </th>
                          <th>
                            <p>Scheduled time</p>
                          </th>
                        </tr>
                      )}

                      {!loadingReschedules &&
                        dates.map((time, index) => (
                          <tr key={index}>
                            <td>{nextLessons[index]}</td>
                            <td>
                              {time.format('dddd').slice(0, 3).toUpperCase()} {time.format('Do')} {time.format('MMMM')}, {time.format('HH:mm')}
                            </td>
                          </tr>
                        ))}

                      {loadingReschedules && (
                        <div className={styles.loaderWrapper}>
                          <div className={appStyles.loader} />
                        </div>
                      )}
                    </table>
                  </div>
                )}
                {dates.length > 0 && productType.trainingType === 'conversation' && bookMultiple && (
                  <>
                    {dates.map((time, index) => (
                      <p key={index} className={styles.bookingLessonsInfo}>
                        {time.format('dddd')} {time.format('Do')} {time.format('MMMM')}, {time.format('HH:mm')}
                      </p>
                    ))}
                  </>
                )}
                {productType.trainingType === 'conversation' && !bookMultiple && (
                  <>
                    <p className={styles.bookingLessonsInfo}>
                      {dates[0].format('dddd')} {dates[0].format('Do')} {dates[0].format('MMMM')}, {dates[0].format('HH:mm')}
                    </p>
                  </>
                )}
                {dates.length > 0 && (productType.trainingType === 'tuition' || productType.trainingType === 'tuition training') && !bookMultiple && (
                  <p className={styles.bookingLessonsInfo}>
                    <strong>Lesson {nextLessons[0]}: </strong>
                    {dates[0].format('dddd')} {dates[0].format('Do')} {dates[0].format('MMMM')}, {dates[0].format('HH:mm')}
                  </p>
                )}
                {!loadingReschedules &&
                  bookMultiple &&
                  dates.length > 0 &&
                  (productType.trainingType === 'tuition' || productType.trainingType === 'tuition training') &&
                  nextLessons[dates.length - 1] < 20 && (
                    <p className={styles.informBookingCopy}>
                      <span style={{ marginRight: '5px' }} role="img" aria-label="danger emoji">
                        ⚠️
                      </span>{' '}
                      {tutorProfile.first_name} is only available on {dates[0].format('dddd')} at {dates[0].format('HH:mm')} until lesson{' '}
                      {nextLessons[dates.length - 1]}.<br />
                      For the rest, please find a different recurring slot.
                    </p>
                  )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  render() {
    const {
      productType,
      deliveryType,
      trainingType,
      isGroupSession,
      purchase,
      prices,
      creditsLeft,
      tutorProfile,
      language,
      userEmail,
      history,
      student
    } = this.props;

    const { showBuyCredits, hideBooking, isAutoAccept } = this.state;

    const appointmentName = getAppointmentName(
      language !== undefined ? language.language : '',
      trainingType,
      deliveryType,
      null,
      true,
      isGroupSession
    );

    const { previousInvites, inviteOtherStudents, isOpenSession } = this.state;

    const conversationCommentHelp = 'which topics would you like to talk about?*';
    const tuitionCommentHelp =
      'To help your tutor prepare, please tell them which lesson you&apos;ll be working on for this class, and anything else you&apos;d like them to know.';
    const commentHelp = deliveryType === 'in-person' ? tuitionCommentHelp : conversationCommentHelp;
    const languageAndCulturePlaceholder = 'Middle Eastern Culture, Introduction to Arabic Language, The benefits of speaking Arabic, Other...';
    const placeholderText =
      trainingType === 'language and culture' ? languageAndCulturePlaceholder : 'e.g. first lesson of the tuition course, business terminology, etc.';

    let invalidLessonNumber;
    if (productType && (productType.trainingType === 'tuition' || productType.trainingType === 'tuition training')) {
      if (this.props.bookableLessonNumbers === null && this.props.nextLessons && this.props.nextLessons.length === 0) {
        invalidLessonNumber = true;
      } else {
        invalidLessonNumber = this.props.bookableLessonNumbers && this.props.bookableLessonNumbers.length === 0;
      }
    }

    const timeZonePairs = this.getTimeZonePairs();
    const needsToInvite = !this.state.isOpenSession && this.state.invitees.length === 0;

    let incompleteErrorMessage = 'Please fill in all the required (*) fields.';
    if (isGroupSession && needsToInvite) {
      incompleteErrorMessage = `Please invite at least one friend or make the session open`;
    }

    const minCredits = isGroupSession || productType.duration === 30 ? 0.5 : 1;

    return (
      <div ref={this.props.bookingContainerRef} className={[styles.container, this.state.isLoading ? appStyles.loading : null].join(' ')}>
        <div className={styles.content}>
          {tutorProfile.first_name && <h2 className={styles.bookingSubTitle}>Book your session with {tutorProfile.first_name}</h2>}
          <h2 className={styles.apptName}>{appointmentName}</h2>
          {/* productType && productType.trainingType === 'tuition' && (
            <p className={styles.recommendationParagraph}>
              <strong>Recommendation:</strong> schedule for your class to take place in 5-7 days,
              as you will be prescribed 2-3 hours of online study to prepare for this live practice.
            </p>
          ) */}
          {isGroupSession && (
            <Card>
              <p>You can invite other learners, or allow other Chatterbox learners to join.</p>
              <p>If no learner joins 24h before the start of the class, it will automatically be cancelled and you will be notified by email.</p>
            </Card>
          )}
          {invalidLessonNumber && (
            <Alert className={formStyles.alert} bsStyle="danger">
              <p>
                You&#39;ve booked all of our lessons! Please contact us at <a href="mailto:courses@chatterbox.io">courses@chatterbox.io</a> about what
                to do with your remaining credit.
              </p>
            </Alert>
          )}
          <Form className={styles.bookingForm}>
            <FormGroup className={styles.shortInput} controlId="timeZone">
              <ControlLabel>Your time zone</ControlLabel>
              <FormControl
                disabled={invalidLessonNumber}
                className={invalidLessonNumber && styles.disabledInput}
                componentClass="select"
                defaultValue={this.state.studentTimeZone}
                onChange={this.onTimeZoneChange}
                required>
                {timeZonePairs.map((pair) => {
                  const [timeZone, displayName] = pair;
                  return (
                    <option key={timeZone} value={timeZone}>
                      {displayName}
                    </option>
                  );
                })}
              </FormControl>
            </FormGroup>
            <FormGroup className={styles.shortInput} controlId="date">
              <ControlLabel>
                Select a date and a time
                {this.state.bookMultiple ? ' for your first class' : ' for your class'}
              </ControlLabel>
              <ButtonModal
                buttonClass={[formStyles.btnLink, styles['button--grey-dark']].join(' ')}
                buttonStyle="link"
                withCloseButton={false}
                buttonText={window.innerWidth < 400 ? `My coach isn't available` : `My coach isn't available.`}
                ref={(modal) => {
                  this.modal = modal;
                }}
                onClick={() => {
                  Mixpanel.track('Click', { button: 'coach_not_available' });
                }}>
                <h1 className={styles.feedbackH1}>Tell us about your needs!</h1>
                <h2 style={{ fontWeight: 'bold' }}>I am looking for...</h2>
                <ul className={styles.feedbackList}>
                  <li>
                    <Checkbox
                      className={styles.checkbox}
                      onChange={(event) => {
                        Mixpanel.track('Click', { button: 'more_coaches' });
                        this.handleChangeCheckbox('moreTutors', event);
                      }}
                      checked={this.state.moreTutors}>
                      More coaches
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox
                      className={styles.checkbox}
                      onChange={(event) => {
                        Mixpanel.track('Click', { button: 'more_availabilities' });
                        this.handleChangeCheckbox('moreAvailabilities', event);
                      }}
                      checked={this.state.moreAvailabilities}>
                      More time slots
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox
                      className={styles.checkbox}
                      onChange={(event) => {
                        Mixpanel.track('Click', { button: 'diff_dialect_language' });
                        this.handleChangeCheckbox('dialectsOrLanguage', event);
                      }}
                      checked={this.state.dialectsOrLanguage}
                      disabled={invalidLessonNumber}>
                      A different language
                    </Checkbox>
                  </li>
                </ul>
                <h2 style={{ fontWeight: 'bold' }}>More details (optional)</h2>
                <FormControl
                  className={styles.feedbackText}
                  placeholder="e.g. what language, what time slot etc."
                  componentClass="textarea"
                  onChange={this.handleFeedbackTextChange}
                />
                <div className={styles.feedbackButtons}>
                  <Button
                    outfit="secondaryButton"
                    onClick={() => {
                      Mixpanel.track('Click', {
                        position: 'feedback_modal',
                        button: 'close_feedback_modal'
                      });
                      this.closeFeedbackModal();
                    }}>
                    Cancel
                  </Button>
                  <Button
                    outfit="regularButton"
                    disabled={!this.isFeedbackFilled()}
                    onClick={() => {
                      Mixpanel.track('Click', {
                        position: 'feedback_modal',
                        button: 'submit_feedback'
                      });
                      this.submitBookingFeedback();
                      this.closeFeedbackModal();
                    }}>
                    Submit
                  </Button>
                </div>
              </ButtonModal>
              {this.renderDateOptions(invalidLessonNumber)}
            </FormGroup>
            {deliveryType === 'in-person' && (
              <FormGroup className={styles.shortInput} controlId="phoneNumber">
                <ControlLabel>Your phone number:</ControlLabel>
                <option className={styles.option} value="" disabled>
                  We will only use this if we have any issues and we need to get in touch
                </option>
                <FormControl
                  disabled={invalidLessonNumber}
                  className={invalidLessonNumber && styles.disabledInput}
                  type="text"
                  value={this.state.phoneNumber}
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
            )}

            {(productType.trainingType === 'conversation' || productType.trainingType === 'language and culture') && (
              <FormGroup controlId="comment">
                <ControlLabel>{commentHelp}</ControlLabel>
                <FormControl placeholder={placeholderText} componentClass="textarea" onChange={this.handleChange} />
              </FormGroup>
            )}
          </Form>
          {deliveryType === 'in-person' && (
            <p className={styles.detailsParagraph}>
              <strong>
                {this.props.meetingPlaceName && <span>Your meeting place will be {this.props.meetingPlaceName}. </span>}
                <span>After you book, you should arrange an exact meeting spot with your tutor via email or phone.</span>
              </strong>
            </p>
          )}
          {isGroupSession && (
            <div>
              <FormGroup controlId="isOpenSession">
                <Checkbox
                  disabled={invalidLessonNumber}
                  onChange={(event) => {
                    this.handleChangeCheckbox('isOpenSession', event);
                  }}
                  checked={isOpenSession}>
                  Allow other learners to find and join this class
                </Checkbox>
              </FormGroup>

              {student.is_staff && (
                <FormGroup controlId="isOpenSession">
                  <Checkbox
                    disabled={invalidLessonNumber}
                    onChange={(event) => {
                      this.handleChangeCheckbox('isAutoAccept', event);
                    }}
                    checked={isAutoAccept}>
                    Auto accept invite for other learners
                  </Checkbox>
                </FormGroup>
              )}

              <FormGroup controlId="invitees">
                <Checkbox
                  disabled={invalidLessonNumber}
                  onChange={(event) => {
                    this.handleChangeCheckbox('inviteOtherStudents', event);
                  }}
                  checked={inviteOtherStudents}>
                  Invite other learners to join your group
                </Checkbox>
                {this.state.inviteOtherStudents && (
                  <div className={styles.inviteesContainer}>
                    <EmailListEntry
                      inputClass={styles.shortInput}
                      onChange={this.handleInviteeChange}
                      preDoneInvitations={previousInvites}
                      userEmail={userEmail}
                    />
                  </div>
                )}
              </FormGroup>
            </div>
          )}
          {this.state.appointmentCreatedError && <ErrorAlert errorMsg={this.state.appointmentCreatedErrorMessage || DEFAULT_ERROR_MESSAGE} />}
          {this.state.appointmentCreatedErrorBrc && <ErrorAlertBrc />}
          {this.state.accountUpdatedError && (
            <ErrorAlert
              errorMsg={
                <span>
                  Sorry, we could not update your account with your new contact info. Please go to your
                  <a href="/account">account</a> and try again.
                </span>
              }
            />
          )}
          {this.state.requiredPrompt && <ErrorAlert errorMsg={incompleteErrorMessage} />}
          {this.state.notEnoughDataError && (
            <ErrorAlert
              errorMsg={
                <span>
                  Sorry, your booking could not be completed because you have not selected a session type. Please go to your{' '}
                  <a href="/dashboard">dashboard</a> to select one.
                </span>
              }
            />
          )}
          {creditsLeft >= minCredits && !hideBooking && (
            <div>
              <div className={styles.buttonContainer}>
                <Button disabled={invalidLessonNumber} outfit="regularButton" onClick={this.submit}>
                  Confirm booking
                </Button>
              </div>
            </div>
          )}
          {(creditsLeft < minCredits || showBuyCredits) && (
            <div className={formStyles.disabledContainer}>
              <p className={formStyles.disabledMessage}>
                <ButtonModal
                  disabled={invalidLessonNumber}
                  buttonClass={formStyles.btnTopUp}
                  buttonText="Buy Credits"
                  onClick={() => {
                    if (productType.organization) {
                      Mixpanel.track('Click', { button: 'buy_credits_organization' });
                    } else {
                      Mixpanel.track('Click', { button: 'buy_credits' });
                    }
                  }}>
                  {productType.organization ? (
                    <>
                      <p>You have run out of credits. Please contact the administrator at {productType.organization} to ask for more credits.</p>
                      <p>You can also purchase credits individually in our store.</p>
                      <Button
                        style={{ marginTop: 10 }}
                        bsStyle="primary"
                        onClick={() => {
                          Mixpanel.track('Click', {
                            position: 'buy_credits_organization',
                            button: 'go_to_store'
                          });
                          history.push('/store');
                        }}>
                        Go to Store
                      </Button>
                    </>
                  ) : (
                    <PurchaseCreditsModal
                      purchase={purchase}
                      prices={prices}
                      deliveryType={capitalize(deliveryType)}
                      trainingType={capitalize(trainingType)}
                    />
                  )}
                </ButtonModal>
                {!productType.organization && (
                  <span className={formStyles.noCreditQuote}>You do not have enough credit for this class. Please purchase and then book.</span>
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

BookingForm.propTypes = {
  productType: PropTypes.object,
  bookableLessonNumbers: PropTypes.array.isRequired,
  createAppointment: PropTypes.func.isRequired,
  submitBookingFeedback: PropTypes.func.isRequired,
  deliveryType: PropTypes.string,
  finishBooking: PropTypes.func.isRequired,
  meetingPlaceName: PropTypes.string,
  student: PropTypes.object.isRequired,
  trainingType: PropTypes.string,
  tutorId: PropTypes.number.isRequired,
  tutorProfile: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  isGroupSession: PropTypes.bool.isRequired,
  prices: PropTypes.array,
  purchase: PropTypes.func.isRequired,
  creditsLeft: PropTypes.number.isRequired,
  invitations: PropTypes.object,
  bookingContainerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]).isRequired,
  userEmail: PropTypes.string,
  nextLessons: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  courseId: PropTypes.number.isRequired,
  resetBookingInfo: PropTypes.func.isRequired,
  getFutureSchedule: PropTypes.func.isRequired,
  getBookingInfoForTutorForTimespan: PropTypes.func.isRequired,
  recurringReschedules: PropTypes.array,
  isLoadingBookable: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

BookingForm.defaultProps = {
  prices: [],
  productType: null,
  deliveryType: '',
  meetingPlaceName: '',
  trainingType: '',
  invitations: {},
  userEmail: '',
  nextLessons: null,
  recurringReschedules: [],
  isLoadingBookable: false
};

function mapStateToProps(state) {
  return {
    recurringReschedules: state.reschedule,
    isLoadingBookable: state.bookable.available.isLoadingBookable,
    timezone: state.time.timezone
  };
}

export default connect(mapStateToProps, {
  createAppointment,
  submitBookingFeedback,
  getBookingInfoForTutorForTimespan,
  resetBookingInfo,
  getFutureSchedule
})(BookingForm);
