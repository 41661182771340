import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { getNextTwelveMonths, dateToIndex } from '../../utils/date-helpers.js';
import Modal from '../uikit/Modal';

import { updateGraduationDate } from '../../actions/user';
import { getDateDisplayNumeric } from '../../utils/time-helpers';

import styles from '../../style/components/account/GraduationDateForm.module.scss';
import formStyles from '../../style/utils/_form.module.scss';
import uiStyles from '../../style/components/uikit/ButtonModal.module.scss';

const GraduationDateForm = ({ initialValues, isStudent, token, updateGraduationDate, user }) => {
  const nextMonths = getNextTwelveMonths();
  const [selectedDateIndex, setSelectedDateIndex] = useState(null);
  const [textValue, setTextValue] = useState('');
  const [renderModal, setRenderModal] = useState(false);
  const [modalToSend, setModalToSend] = useState(true);
  const [modalError, setModalError] = useState(false);

  useEffect(() => {
    if (!initialValues) return;
    if (initialValues.graduation_date) {
      const dateIndex = dateToIndex(initialValues.graduation_date, nextMonths);
      setSelectedDateIndex(dateIndex);
    }
  }, [initialValues]);

  const getExpirationDate = () => {
    if (!user || !user.license_expiration_date) return null;

    const dateSplit = user.license_expiration_date.split('T');
    return getDateDisplayNumeric(dateSplit[0], dateSplit[1]);
  };

  const updateAccount = (needsReview = true) => {
    const graduationDate = new Date(nextMonths[selectedDateIndex]);
    const graduationString = `${graduationDate.getFullYear()}-${graduationDate.getMonth() + 1}-${graduationDate.getDate()}`;
    const data = {
      graduation_date: graduationString,
      graduation_reason: textValue,
      needs_review: needsReview
    };
    updateGraduationDate(data, token, submissionSuccess, submissionFailed);
  };

  const submissionSuccess = () => {
    if (!renderModal) {
      toast.success('Thank you for submitting your graduation date.', {
        toastId: 'success_graduation'
      });
    }
    setModalToSend(false);
  };

  const submissionFailed = () => {
    if (!renderModal) {
      toast.error('There was an issue in updating your graduation date. Please try again.', {
        toastId: 'error_graduation'
      });
    }
    setModalError(true);
  };

  const closeModal = () => {
    setRenderModal(false);
    setModalError(false);
    setModalToSend(true);
    setTextValue('');
  };

  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };

  return (
    <div className={styles.form}>
      {renderModal && (
        <Modal className={uiStyles.modal} onModalClose={closeModal}>
          <div className={styles.modalContent}>
            {!modalError && modalToSend && (
              <>
                <h2 className={styles.modalTitle}>Request a new date</h2>
                <p className={styles.modalSubtitle}>Please tell us why do you need to change your Graduation date?</p>
                <textarea value={textValue} placeholder="Write answer" onChange={handleTextChange} className={styles.modalTextarea} />
                <button onClick={updateAccount} className={styles.sendButton} disabled={textValue === undefined || textValue === ''}>
                  Send request
                </button>
              </>
            )}
            {!modalError && !modalToSend && (
              <>
                <h2 className={styles.modalTitle}>Request sent!</h2>
                <p className={styles.modalSubtitle}>
                  Check this page in 2 days to see the change. If we have any question, the Language Teacher will get in touch with you.
                </p>
                <button onClick={closeModal} className={styles.sendButton}>
                  Close
                </button>
              </>
            )}
            {modalError && (
              <>
                <h2 className={styles.modalTitle}>Request failed</h2>
                <p className={styles.modalSubtitle}>There was an issue in updating your graduation date. Please try again.</p>
                <button onClick={closeModal} className={styles.sendButton}>
                  Close
                </button>
              </>
            )}
          </div>
        </Modal>
      )}
      <div className={[formStyles.form, styles.form].join(' ')}>
        {isStudent && getExpirationDate() && (
          <>
            <h2>My Licence</h2>
            <div className={styles.preferencesOption}>
              Valid until: <span className={styles.validUntil}>{getExpirationDate()}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

GraduationDateForm.propTypes = {
  isStudent: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  user: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  updateGraduationDate: PropTypes.func.isRequired
};

GraduationDateForm.defaultProps = {
  initialValues: {}
};

export default connect(mapStateToProps, {
  updateGraduationDate
})(GraduationDateForm);
