import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Arrow from '../../../../design-system/arrow/Arrow';

import { freshdeskTicketsStatus } from '../../../../../domains/helpdesk/ticket';

import styles from './Ticket.module.scss';
import { connect } from 'react-redux';
import { getTicket } from '../../../../../actions/helpdesk';
import LoadingSpinner from '../../../../design-system/loading/spinner/LoadingSpinner';

const Ticket = ({
  errorGettingTicket,
  expanded,
  freshdeskRequesterId,
  getTicketAction,
  id,
  isGettingTicket,
  setExpanded,
  subject,
  status,
  ticketConversation
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const name = `Ticket-${id}`;

  useEffect(() => {
    setIsExpanded(expanded === name);
  }, [expanded]);

  const handleExpand = (expanded, id) => {
    if (expanded) {
      setExpanded(name);
      getTicketAction(id);
      return;
    }

    setExpanded('');
  };

  const getResponder = (userId) => {
    if (userId === freshdeskRequesterId) return 'You';
    return 'Support Specialist';
  };

  const buildDetails = ({ id, body }) => {
    const user = getResponder();
    return (
      <p className={styles.line} key={id}>
        <b>{user}</b>: {body}
      </p>
    );
  };

  const getTicketDetails = () => {
    if (!isExpanded) return;
    if (isGettingTicket) return <LoadingSpinner />;
    if (!errorGettingTicket) <p>Something went wrong! Please close and open the card again.</p>;
    if (!ticketConversation?.length) return <p className={styles.nothing}>Nothing here yet! :|</p>;
    return ticketConversation?.map((c) => buildDetails({ id: c?.id, body: c?.body_text, userId: c?.user_id }));
  };

  return (
    <Accordion expanded={isExpanded} onChange={(_, expanded) => handleExpand(expanded, id)}>
      <AccordionSummary expandIcon={<Arrow position="bottom" />} aria-controls="panel1a-content" id="panel1a-header">
        <div className={styles['ticket-wrapper']}>
          <p>
            <b>ID: </b>
            {id}
          </p>
          <p>
            <b>Subject: </b>
            {subject}
          </p>
          <p>
            <b>Status: </b>
            {freshdeskTicketsStatus[status]}
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.details}>
          <h2>Conversation history:</h2>
          {getTicketDetails()}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

Ticket.propTypes = {
  errorGettingTicket: PropTypes.string,
  expanded: PropTypes.string,
  freshdeskRequesterId: PropTypes.number,
  getTicketAction: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  isGettingTicket: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  subject: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  ticketConversation: PropTypes.array
};

const mapStateToProps = (state) => ({
  errorGettingTicket: state.helpdesk.errorGettingTicket,
  freshdeskRequesterId: state.helpdesk.ticket?.requester_id,
  isGettingTicket: state.helpdesk.isGettingTicket,
  ticketConversation: state.helpdesk.ticket?.conversations
});

export default connect(mapStateToProps, {
  getTicketAction: getTicket
})(Ticket);
