import { UNIT_COMPLETED_STATUS } from '../enums/lessons';
import { capitalize } from './string-helpers';

export const MAX_APPOINTMENT_STUDENTS = 3;

export function getAppointmentName(language, trainingType, deliveryType, dialect = null, withPipes = true, isGroupSession = false) {
  const dialectDisplay = dialect && dialect.length > 0 ? ` (${dialect})` : '';
  const divider = withPipes ? ' | ' : ' ';

  if (language && trainingType && deliveryType) {
    let text = `${language}${dialectDisplay}${divider}` + `${capitalize(trainingType)}${divider}` + `${capitalize(deliveryType)}`;

    if (isGroupSession) {
      text += ' (Group)';
    }

    return text;
  }

  return '';
}

export function getAppointmentLanguageName(language, dialect = null) {
  const dialectDisplay = dialect && dialect.length > 0 ? ` (${dialect})` : '';

  if (language) {
    const text = `${language}${dialectDisplay}`;

    return text;
  }

  return '';
}

export const isUnitDone = (unit, statusProperty = 'booking_state') => {
  return Boolean(Object.values(UNIT_COMPLETED_STATUS).includes(unit[statusProperty].toLowerCase()));
};
