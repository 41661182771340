import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import { ReactComponent as LadyError } from '../assets/people/error-lady.svg';

import styles from '../style/containers/ErrorBoundary.module.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    Sentry.captureException(error, { tags: { section: info?.componentStack } });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.wrapper}>
          <h1>
            Oops, an unexpected error has occurred. Please reload the page. If that doesn&apos;t work, please close this window and open a new one.
            <span>
              If the error persists, please contact us at <a href="mailto:support@chatterbox.io">support@chatterbox.io</a>
            </span>
          </h1>
          <LadyError />
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired
};

export default ErrorBoundary;
