import { HelpdeskActionTypes } from '../actions/helpdesk';

const helpdeskInitialState = {
  errorCreatingTicket: '',
  errorGettingTicket: '',
  errorGettingTicketFields: '',
  isCreatingTicket: false,
  isGettingTicket: false,
  isGettingTicketFields: false,
  isListingUserTickets: false,
  isListingUserTicketsReady: false,
  ticket: {},
  ticketCreated: {},
  ticketTypes: [],
  userTickets: []
};

export function HelpdeskReducer(state = helpdeskInitialState, action) {
  switch (action.type) {
    case HelpdeskActionTypes.GET_TICKET_TYPES:
      return { ...state, isGettingTicketFields: true, errorGettingTicketFields: '' };
    case HelpdeskActionTypes.GET_TICKET_TYPES_SUCCESS:
      return { ...state, ticketTypes: action.payload, isGettingTicketFields: false, errorGettingTicketFields: '' };
    case HelpdeskActionTypes.GET_TICKET_TYPES_FAIL:
      return { ...state, ticketTypes: [], errorGettingTicketFields: action.payload, isGettingTicketFields: false };
    case HelpdeskActionTypes.LIST_USER_TICKETS:
      return { ...state, isListingUserTickets: true, errorGettingTicketFields: '' };
    case HelpdeskActionTypes.LIST_USER_TICKETS_SUCCESS:
      return { ...state, userTickets: action.payload, errorGettingTicketFields: '', isListingUserTicketsReady: true, isListingUserTickets: false };
    case HelpdeskActionTypes.LIST_USER_TICKETS_FAIL:
      return { ...state, userTickets: [], errorGettingTicketFields: action.payload, isListingUserTicketsReady: true, isListingUserTickets: false };
    case HelpdeskActionTypes.CREATE_TICKET:
      return { ...state, isCreatingTicket: true, errorCreatingTicket: '' };
    case HelpdeskActionTypes.CREATE_TICKET_SUCCESS:
      return { ...state, isCreatingTicket: false, ticketCreated: action.payload, errorCreatingTicket: '' };
    case HelpdeskActionTypes.CREATE_TICKET_FAIL:
      return { ...state, isCreatingTicket: false, errorCreatingTicket: action.payload };
    case HelpdeskActionTypes.GET_TICKET:
      return { ...state, isGettingTicket: true, errorGettingTicket: '' };
    case HelpdeskActionTypes.GET_TICKET_SUCCESS:
      return { ...state, isGettingTicket: false, ticket: action.payload, errorGettingTicket: '' };
    case HelpdeskActionTypes.GET_TICKET_FAIL:
      return { ...state, isGettingTicket: false, ticket: {}, errorGettingTicket: action.payload };
    case HelpdeskActionTypes.CLEAR:
      return { ...state, ticket: {}, ticketCreated: {} };
    default:
      return state;
  }
}
