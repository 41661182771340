import Freshdesk from '@freshworks/freshdesk';

export class FreshdeskClient {
  _client;

  constructor() {
    this._client = new Freshdesk({ domain: process.env.REACT_APP_FRESHDESK_DOMAIN, api_key: process.env.REACT_APP_FRESHDESK_API_KEY });
    return this._client;
  }
}
