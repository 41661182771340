import axios from 'axios';
import Mixpanel from '../utils/mixpanel-helper.js';

import { isTokenExpired } from './common';
import { CREATE_ABSENCE_PERIOD, DELETE_ABSENCE_PERIOD, GET_ABSENCE_PERIODS, GET_ABSENCE_PERIODS_ERROR, UPDATE_ABSENCE_PERIOD } from './types';

function createAbsencePeriodSuccess(response) {
  return {
    type: CREATE_ABSENCE_PERIOD,
    payload: response
  };
}

function deleteAbsencePeriodSuccess(id) {
  return {
    type: DELETE_ABSENCE_PERIOD,
    payload: id
  };
}

function getAbsencePeriodsSuccess(response) {
  return {
    type: GET_ABSENCE_PERIODS,
    payload: response
  };
}

function getAbsencePeriodsError() {
  return {
    type: GET_ABSENCE_PERIODS_ERROR
  };
}

function updateAbsencePeriodSuccess(response) {
  return {
    type: UPDATE_ABSENCE_PERIOD,
    payload: response
  };
}

export function createAbsencePeriod(token, startString, endString, errorCallback, successCallback) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        '/api/absence-periods/',
        { end: endString, start: startString },
        { headers: { Authorization: `Token ${token}` } }
      );

      dispatch(createAbsencePeriodSuccess(response));

      if (successCallback) {
        Mixpanel.track('Timeoff successfully created');
        successCallback();
      }
    } catch (error) {
      Mixpanel.track('Error', { error: 'create_timeoff_error' });
      if (!isTokenExpired(dispatch, error)) {
        if (typeof errorCallback === 'function') {
          errorCallback(error);
        }
      }
    }
  };
}

export function deleteAbsencePeriod(token, id, errorCallback, successCallback) {
  return async (dispatch) => {
    try {
      await axios.delete(`/api/absence-periods/${id}/`, {
        headers: { Authorization: `Token ${token}` }
      });

      dispatch(deleteAbsencePeriodSuccess(id));

      if (successCallback) {
        Mixpanel.track('TimeOff successfully deleted');
        successCallback();
      }
    } catch (error) {
      Mixpanel.track('Error', { error: 'delete_timeoff_error' });
      if (!isTokenExpired(dispatch, error)) {
        if (typeof errorCallback === 'function') {
          errorCallback(error);
        }
      }
    }
  };
}

export function getAbsencePeriods(token, tutorId, errorCallback) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/api/absence-periods/?tutor_id=${tutorId}`, {
        headers: { Authorization: `Token ${token}` }
      });
      dispatch(getAbsencePeriodsSuccess(response));
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        dispatch(getAbsencePeriodsError());

        if (errorCallback) errorCallback("Couldn't retrieve your absence list");
      }
    }
  };
}

export function updateAbsencePeriod(token, id, startString, endString, errorCallback, successCallback) {
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        `/api/absence-periods/${id}/`,
        { end: endString, start: startString },
        { headers: { Authorization: `Token ${token}` } }
      );

      dispatch(updateAbsencePeriodSuccess(response));

      if (successCallback) {
        Mixpanel.track('Timeoff successfully updated');
        successCallback();
      }
    } catch (error) {
      Mixpanel.track('Error', { error: 'update_timeoff_error' });
      if (!isTokenExpired(dispatch, error)) {
        if (typeof errorCallback === 'function') {
          errorCallback(error);
        }
      }
    }
  };
}
