import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import NotFound from '../../components/NotFound';
import { UserTypes } from '../../domains/user/user-types';

const StudentExclusiveRoute = ({ component, user, userType, ...rest }) => {
  const { token } = user;
  return (
    <Route
      {...rest}
      render={(props) => (token ? userType === UserTypes.student ? React.cloneElement(component, { ...props }) : <NotFound /> : <Redirect to="/" />)}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.user
});

StudentExclusiveRoute.propTypes = {
  component: PropTypes.element.isRequired,
  userType: PropTypes.oneOf([UserTypes.student, UserTypes.tutor, UserTypes.languageHelper]).isRequired,
  user: PropTypes.object.isRequired
};

export default connect(mapStateToProps, null)(StudentExclusiveRoute);
