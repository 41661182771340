import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Modal from '../../../design-system/modal/Modal';
import Button from '../../../design-system/button/Button';

import styles from './VideoClassModal.module.scss';

const VideoClassModal = ({ preferredClass, tutor }) => {
  const [isCopied, setIsCopied] = useState(false);

  const modalContent = Object.freeze({
    meetme: (
      <div className={styles.modalContent}>
        <p>
          You have chosen <strong>MeetMe</strong> as the preferred classroom.
        </p>
        <p>
          From Skype: go to the Skype search bar and look for “Meet Me” &gt; then make a Skype Call (for audio only) or video call (for audio and
          video) &gt; use the keyboard (icon on the bottom right corner) to dial your conference ID and guest code.
        </p>
      </div>
    ),
    teams: (
      <div className={styles.modalContent} data-testid="teams-instructions-modal">
        <p>
          Your preferred classroom is <strong>Microsoft Teams</strong>.
        </p>
        <p>
          At the scheduled date / time, your Coach will send you a meeting link through Teams. Please ensure you have Teams open and notifications
          turned on at this time. If you do not receive this link, you will be able to message your Coach directly through Teams by searching for{' '}
          <b>{tutor.teams_email ? tutor.teams_email : tutor.email}</b>. <br />
          <br />
          This is <i>only</i> to be used for joining your Live Practices, any additional Coach communication must be directed through the Chatterbox
          Chat functionality.
        </p>
      </div>
    ),
    skype: (
      <div className={styles.modalContent}>
        <p>
          Your preferred classroom is <strong>Skype</strong>.
        </p>
        <p>
          Add your tutor on skype and start the class from there.
          <br />
          {tutor.skype_id && `Coach's Skype ID: ${tutor.skype_id}`}
        </p>
        <CopyToClipboard text={tutor.skype_id} onCopy={() => setIsCopied(true)}>
          <Button disabled={isCopied}>{isCopied ? 'Copied' : 'Copy  tutor&apos;s skype id to clipboard'}</Button>
        </CopyToClipboard>
      </div>
    )
  });

  return (
    <Modal
      completionProgress={0}
      loading={false}
      content={
        <div className={styles.videoClassModalWrapper} data-testid="video-class-modal">
          <div className={styles.modalTitle}>
            <h1>Follow the instructions below to join your class</h1>
          </div>
          {modalContent[preferredClass]}
        </div>
      }
    />
  );
};

VideoClassModal.propTypes = {
  preferredClass: PropTypes.string.isRequired,
  tutor: PropTypes.object.isRequired
};

export default VideoClassModal;
