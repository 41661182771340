import React from 'react';
import PropTypes from 'prop-types';

import IconBook from '../../../../assets/icons/icon_book.svg';
import mixpanelHelper from '../../../../utils/mixpanel-helper';

import styles from './Syllabus.module.scss';

const Syllabus = ({ link }) => {
  const handleClick = () => {
    mixpanelHelper.track('Clicked Syllabus');
  };

  return (
    <a href={link} target="_blank" rel="noreferrer" className={styles['syllabus-wrapper']} data-testid="syllabus" onClick={handleClick}>
      <img className={styles.icon} src={IconBook} alt="Syllabus" />
      <span>Syllabus</span>
    </a>
  );
};

Syllabus.propTypes = {
  link: PropTypes.string
};

export default Syllabus;
