import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import styles from '../../MenuItem.module.scss';
import { UserTypes } from '../../../../domains/user/user-types';

const BuyMoreCreditsButton = ({ handleMenuLinkClick, user }) => {
  if (user?.user_type !== UserTypes.student || !user?.is_individual_user) return;

  return (
    <li className={styles['link-wrapper']} data-testid="buy-more-credits-button">
      <NavLink
        to={'/store'}
        onClick={() => handleMenuLinkClick('store')}
        activeClassName={styles.isActive}
        className={styles.menuItem}
        data-testid="buy-more-credits-link">
        <span>Buy More Credits</span>
      </NavLink>
    </li>
  );
};

BuyMoreCreditsButton.propTypes = {
  handleMenuLinkClick: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default BuyMoreCreditsButton;
