import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import Mixpanel from '../../utils/mixpanel-helper.js';

import resourceStyles from '../../style/containers/TutorLessonResource.module.scss';

const VERBS_BY_RESOURCE_TYPE = {
  audio: 'Listen',
  document: 'View',
  image: 'View',
  video: 'Watch',
  webpage: 'Go',
  cerego: 'View Cerego Resource',
  assessment: 'Complete the test',
  book: 'Book now'
};

function isExternal(url) {
  // Based on this: https://stackoverflow.com/a/6238456/4424904

  const match = url.match(/^([^:/?#]+:)?(?:\/\/([^/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);

  if (typeof match[1] === 'string' && match[1].length > 0) {
    return true;
  }

  return false;
}

const TutorLessonResource = (props) => {
  const {
    name,
    type,
    url,
    status,
    isCeregoResource,
    openCeregoResource,
    history,
    resource,
    isRevisionUnit,
    lessonNumber,
    resourceIndex,
    userType,
    disabled
  } = props;

  const resourceName =
    lessonNumber !== undefined && resourceIndex !== undefined ? `${lessonNumber.toString()}.${resourceIndex.toString()}: ${name}` : name;
  const nameSuffix = userType === 'tutor' ? ' (coaching material)' : '';
  return (
    <div
      className={[resourceStyles.lessonResourceContainer, status === 'future' ? resourceStyles.disabledContainer : undefined].join(' ')}
      style={type === 'book' ? { marginTop: 10 } : {}}>
      <div className={resourceStyles.lessonResourceLeftContent}>
        <h2 className={status === 'future' && type !== 'book' ? resourceStyles.disabledText : undefined}>
          <b>{resourceName + nameSuffix}</b>
          <span className={resourceStyles.duration}>{resource && resource.duration ? ` ~ ${resource.duration} min` : ''}</span>
        </h2>
      </div>
      <div className={resourceStyles.buttonContainer}>
        {isExternal(url) && (
          <Button
            className={resourceStyles.resourceBtns}
            bsStyle="primary"
            onClick={() => {
              if (isRevisionUnit) {
                Mixpanel.track('Opened revision unit', { name });
              }
              if (isCeregoResource) {
                openCeregoResource(resource);
                Mixpanel.track('Click', { button: VERBS_BY_RESOURCE_TYPE[type] });
              } else {
                Mixpanel.track('Click', { button: VERBS_BY_RESOURCE_TYPE[type] });
                const cerego = window.open(url, type !== 'assessment' ? '_blank' : '_self');
                cerego.focus();
              }
            }}
            disabled={status === 'future' || disabled}>
            {VERBS_BY_RESOURCE_TYPE[type]}
          </Button>
        )}

        {!isExternal(url) && (
          <Button
            className={resourceStyles.resourceBtns}
            onClick={() => {
              if (isRevisionUnit) {
                Mixpanel.track('Opened revision unit', { name });
              }
              if (isCeregoResource) {
                openCeregoResource(resource);
                Mixpanel.track('Click', { button: VERBS_BY_RESOURCE_TYPE[type] });
              } else {
                Mixpanel.track('Click', { button: VERBS_BY_RESOURCE_TYPE[type] });
                history.push(url);
              }
            }}
            bsStyle="primary"
            disabled={disabled}>
            {VERBS_BY_RESOURCE_TYPE[type]}
          </Button>
        )}
      </div>
    </div>
  );
};

TutorLessonResource.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isCeregoResource: PropTypes.bool.isRequired,
  openCeregoResource: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  resource: PropTypes.object.isRequired,
  isRevisionUnit: PropTypes.bool,
  lessonNumber: PropTypes.number.isRequired,
  resourceIndex: PropTypes.number.isRequired,
  userType: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

TutorLessonResource.defaultProps = {
  /* progress: 0, */
  isRevisionUnit: false,
  disabled: false
};

export default TutorLessonResource;
