import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Calendar from '../../design-system/calendar/Calendar';
import Modal from '../../design-system/modal/Modal';

import styles from './SessionsManagementModal.module.scss';
import { setAvailableScheduleInterval } from '../../../utils/date-helpers';
import { getBookingInfoForTutorForTimespan, updateAvailableBookingsForTutorForTimespan } from '../../../actions/bookable';
import CoachUnavailableModal from './CoachUnavailableModal';
import OutOfLicenceModal from './OutOfLicenceModal';
import { addMonths, isAfter } from 'date-fns';
import DropdownAsync from '../../design-system/dropdown-async/DropdownAsync';
import { getAvailableTimezones } from '../../../actions/time';
import { updateAccount } from '../../../actions/user';
import SessionsUnavailableModal from './SessionsUnavailableModal';
// import CloseButton from '../../design-system/button/close-button/CloseButton';
import { TIMEZONES_MAP } from '../../../utils/time-helpers';
import ErrorMessage from '../../design-system/error-message';
import OnLeavingAlert from './on-leaving-alert/on-leaving-alert';
import { selectBookedDates } from '../../../reducers/sessions';
import Legend from './legend';
import mixpanelHelper from '../../../utils/mixpanel-helper';

const SessionsManagementModal = ({
  availableTimezones,
  calendarSubtitle,
  currentTutor,
  daysAvailableThisMonth,
  detectedTimezone,
  disabledActionButton,
  disabledCalendar,
  enableAlertOnLeaving,
  enableCoachChangeWhenUnavailable,
  errorGettingAvailableSessions,
  futureSchedule,
  getAvailableTimezonesAction,
  getTutorAvailableScheduleAction,
  handleExecution,
  ignoreIds,
  isAvailableDatetimesEmpty,
  coachHasAvailability,
  isExecuting,
  isLoading,
  isLoadingAvailableSchedule,
  isLoadingAvailableTimezones,
  language,
  lastAvailableTimestamp,
  matchedTutor,
  modalMainColor,
  modalSubtitle,
  modalTitle,
  onCloseModal,
  productType,
  renderAlert,
  renderAppointmentItem,
  selectedTimestamp,
  selectedTimestamps,
  selectedTutor,
  shouldDetectTimezone,
  student,
  shouldShowLegend,
  testId,
  timesAvailableThisDay,
  timezone,
  updateAccountAction,
  updateAvailableBookingsForTutorForTimespanAction,
  type
}) => {
  const [selectedTimezone, setSelectedTimezone] = useState(timezone);
  const [isUTC, setIsUTC] = useState(false);
  const [isDumbLoading, setIsDumbLoading] = useState(shouldDetectTimezone ? true : false);
  const [isAlertOnLeavingOpen, setIsAlertOnLeavingOpen] = useState(false);
  const bookedTimestamps = useSelector((state) => selectBookedDates(state));
  const tutor = currentTutor?.id ? currentTutor : selectedTutor?.id ? selectedTutor : matchedTutor;

  const isTimezoneUTC = (tz) => {
    return tz === TIMEZONES_MAP.get('UTC');
  };

  const autoDetectTimezone = () => {
    setTimeout(() => {
      setSelectedTimezone(detectedTimezone);
      setIsDumbLoading(false);
    }, 3000);
  };

  useEffect(() => {
    mixpanelHelper.openedModal('Sessions Management Modal', { type });
  }, []);

  useEffect(() => {
    shouldDetectTimezone && detectedTimezone && autoDetectTimezone();
  }, [detectedTimezone]);

  useEffect(() => {
    if (timezone) setSelectedTimezone(timezone);
  }, [timezone]);

  useEffect(() => {
    setIsUTC(isTimezoneUTC(selectedTimezone));
  }, [selectedTimezone]);

  const getTutorAvailableScheduleForSpecificMonthspan = (numberOfMonths = 1) => {
    const { from, to } = setAvailableScheduleInterval(new Date(), 0, numberOfMonths);
    getTutorAvailableScheduleAction({
      token: student.token,
      tutorId: tutor?.id,
      productTypeId: productType.productTypeId,
      selectedLanguageId: language.id,
      from,
      to,
      ignoreIds
    });
  };

  useEffect(() => {
    if (tutor?.id && productType?.productTypeId) getTutorAvailableScheduleForSpecificMonthspan(12);
  }, [tutor?.id, productType?.productTypeId]);

  const isOneMonthFromLastAvailable = (ts) => {
    const oneMonthFromDate = addMonths(new Date(ts), 1);
    return isAfter(oneMonthFromDate, new Date(lastAvailableTimestamp));
  };

  const updateTutorAvailableSchedule = (ts) => {
    if (isOneMonthFromLastAvailable(ts)) {
      updateAvailableBookingsForTutorForTimespanAction({
        timestamp: lastAvailableTimestamp,
        token: student.token,
        tutorId: tutor.id,
        productTypeId: productType.productTypeId,
        selectedLanguageId: language.id
      });
    }
  };

  const handleCloseModal = () => {
    mixpanelHelper.closedModal('Sessions Management Modal', { type });
    if (onCloseModal) {
      if (!enableAlertOnLeaving || !selectedTimestamps?.length) return onCloseModal();
      return setIsAlertOnLeavingOpen(true);
    }
  };

  const onAcceptClosing = () => {
    onCloseModal();
    setIsAlertOnLeavingOpen(false);
  };

  if (!isLoadingAvailableSchedule && errorGettingAvailableSessions) {
    return (
      <SessionsUnavailableModal
        modalTitle={modalTitle}
        modalMainColor={modalMainColor}
        onCloseModal={handleCloseModal}
        testId={testId}
        tutor={tutor}
      />
    );
  }

  if (!coachHasAvailability && !isLoadingAvailableSchedule && isAvailableDatetimesEmpty) {
    return (
      <CoachUnavailableModal
        disabledActionButton={disabledActionButton}
        enableCoachChangeWhenUnavailable={enableCoachChangeWhenUnavailable}
        modalTitle={modalTitle}
        modalMainColor={modalMainColor}
        onCloseModal={handleCloseModal}
        testId={testId}
        tutor={tutor}
      />
    );
  }

  if (coachHasAvailability && !isLoadingAvailableSchedule && isAvailableDatetimesEmpty) {
    return (
      <OutOfLicenceModal
        disabledActionButton={disabledActionButton}
        enableCoachChangeWhenUnavailable={enableCoachChangeWhenUnavailable}
        modalTitle={modalTitle}
        modalMainColor={modalMainColor}
        onCloseModal={handleCloseModal}
        testId={testId}
        tutor={tutor}
      />
    );
  }

  const handleTimezoneSelection = (tz) => {
    setSelectedTimezone(tz);
    updateAccountAction({ ...student, timezone: tz });
  };

  const onFinish = () => {
    handleExecution();
  };

  return (
    <>
      <Modal
        noPadding
        completionProgress={100}
        modalMainColor={modalMainColor}
        loading={isLoading}
        onClose={handleCloseModal}
        content={
          <div className={styles.modalWrapper} data-testid={testId}>
            <div className={[styles.modalTitle, styles[modalMainColor]].join(' ')}>
              <img src={tutor?.profile_picture || tutor?.photo} alt="" />
              <div className={styles['main-title']}>
                <h2 data-testid={`${testId}-title`}>
                  {modalTitle} with {tutor?.name || tutor?.first_name}
                </h2>
                <p data-testid={`${testId}-subtitle`}>{modalSubtitle}</p>
              </div>
            </div>
            <div className={[styles.modalSection, styles['timezone-wrapper']].join(' ')}>
              <h2>Select your timezone</h2>
              <div className={styles.dropdown}>
                <DropdownAsync
                  defaultValue={selectedTimezone}
                  options={availableTimezones}
                  onDefaultValue={handleTimezoneSelection}
                  onChange={handleTimezoneSelection}
                  getOptions={() => getAvailableTimezonesAction(student?.token)}
                  isLoading={isLoadingAvailableTimezones || isDumbLoading}
                />
                {isUTC && !isLoadingAvailableTimezones && (
                  <div className={styles.errorWrapper}>
                    <ErrorMessage copy="Please select your real timezone before booking your session." />
                  </div>
                )}
              </div>
            </div>
            <div className={[styles.modalSection, styles.withCalendar].join(' ')}>
              <h2>When do you want to meet?</h2>
              <p>{futureSchedule?.length > 1 ? calendarSubtitle : ''}</p>
              {shouldShowLegend ? <Legend /> : null}
              <div className={[styles.calendarStuff, renderAlert && styles['calendar-with-alerts']].join(' ')}>
                <Calendar
                  alreadyBooked={bookedTimestamps}
                  daysAvailableThisMonth={daysAvailableThisMonth}
                  disabled={disabledCalendar || isUTC}
                  disabledFinishButton={disabledActionButton || isUTC}
                  futureSchedule={futureSchedule}
                  isFinishing={isExecuting}
                  isLoading={isLoadingAvailableSchedule}
                  onFinish={onFinish}
                  renderAlert={renderAlert}
                  renderAppointmentItem={renderAppointmentItem}
                  selectedTimestamp={selectedTimestamp}
                  selectedTimestamps={selectedTimestamps}
                  timesAvailableThisDay={timesAvailableThisDay}
                  onMonthChangeForward={updateTutorAvailableSchedule}
                />
              </div>
              <p id={styles.timezoneMessage}>My timezone: {selectedTimezone}</p>
            </div>
          </div>
        }
      />
      {isAlertOnLeavingOpen && (
        <OnLeavingAlert
          lessonsPreBooked={selectedTimestamps?.length}
          modalMainColor={modalMainColor}
          onAccept={onAcceptClosing}
          onDecline={() => setIsAlertOnLeavingOpen(false)}
        />
      )}
    </>
  );
};

SessionsManagementModal.propTypes = {
  enableCoachChangeWhenUnavailable: PropTypes.bool,
  availableTimezones: PropTypes.array.isRequired,
  cleanPreviousStateAction: PropTypes.func.isRequired,
  calendarSubtitle: PropTypes.string,
  currentTutor: PropTypes.object,
  daysAvailableThisMonth: PropTypes.array.isRequired,
  detectedTimezone: PropTypes.string,
  disabledActionButton: PropTypes.bool,
  disabledCalendar: PropTypes.bool,
  enableAlertOnLeaving: PropTypes.bool,
  errorGettingAvailableSessions: PropTypes.bool,
  futureSchedule: PropTypes.array,
  getAvailableTimezonesAction: PropTypes.func.isRequired,
  getTutorAvailableScheduleAction: PropTypes.func.isRequired,
  handleExecution: PropTypes.func.isRequired,
  ignoreIds: PropTypes.array.isRequired,
  isAvailableDatetimesEmpty: PropTypes.bool.isRequired,
  coachHasAvailability: PropTypes.bool.isRequired,
  isExecuting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isLoadingAvailableSchedule: PropTypes.bool.isRequired,
  isLoadingAvailableTimezones: PropTypes.bool.isRequired,
  language: PropTypes.object.isRequired,
  lastAvailableTimestamp: PropTypes.string.isRequired,
  matchedTutor: PropTypes.object,
  modalMainColor: PropTypes.string,
  modalSubtitle: PropTypes.string,
  modalTitle: PropTypes.string,
  onCloseModal: PropTypes.func,
  productType: PropTypes.object.isRequired,
  renderAlert: PropTypes.func,
  renderAppointmentItem: PropTypes.func.isRequired,
  selectedTimestamp: PropTypes.string.isRequired,
  selectedTimestamps: PropTypes.array,
  selectedTutor: PropTypes.object,
  shouldDetectTimezone: PropTypes.bool,
  shouldShowLegend: PropTypes.bool,
  student: PropTypes.object.isRequired,
  timesAvailableThisDay: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  updateAccountAction: PropTypes.func.isRequired,
  updateAvailableBookingsForTutorForTimespanAction: PropTypes.func.isRequired,
  testId: PropTypes.string,
  type: PropTypes.string
};

SessionsManagementModal.defaultProps = {
  calendarSubtitle: 'Choose a regular day and time for each session to meet with your coach',
  enableCoachChangeWhenUnavailable: true,
  cleanPreviousStateAction: () => {},
  disabledActionButton: false,
  disabledCalendar: false,
  futureSchedule: [],
  ignoreIds: [],
  selectedTimestamp: '',
  shouldDetectTimezone: false,
  shouldShowLegend: true
};

const mapStateToProps = (state) => ({
  availableTimezones: state.time.availableTimezones,
  daysAvailableThisMonth: state.bookable.available.daysAvailableThisMonth,
  detectedTimezone: state.time.detectedTimezone,
  errorGettingAvailableSessions: state.bookable.available.errorGettingAvailableSessions,
  isAvailableDatetimesEmpty: state.bookable.available.isAvailableDatetimesEmpty,
  coachHasAvailability: state.bookable.available.coachHasAvailability,
  isLoadingAvailableSchedule: state.bookable.available.isLoadingBookable,
  isLoadingAvailableTimezones: state.time.isLoadingAvailableTimezones,
  language: state.selectedLanguage,
  lastAvailableTimestamp: state.bookable.available.lastAvailableTimestamp,
  matchedTutor: state.selectedTutor.matchedTutor,
  selectedTutor: state.selectedTutor.selectedTutor,
  student: state.user,
  timesAvailableThisDay: state.bookable.available.timesAvailableThisDay,
  timezone: state.time.timezone
});

export default connect(mapStateToProps, {
  getAvailableTimezonesAction: getAvailableTimezones,
  getTutorAvailableScheduleAction: getBookingInfoForTutorForTimespan,
  updateAccountAction: updateAccount,
  updateAvailableBookingsForTutorForTimespanAction: updateAvailableBookingsForTutorForTimespan
})(SessionsManagementModal);
