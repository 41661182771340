import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import styles from '../../MenuItem.module.scss';
import { ReactComponent as IconStudyArea } from '../../../../assets/icons/icon_study-area.svg';
import { UserTypes } from '../../../../domains/user/user-types';

const StudyAreaButton = ({ handleMenuLinkClick, user }) => {
  if (user?.user_type !== UserTypes.student) return;

  return (
    <li className={styles['link-wrapper']} data-testid="study-area-button">
      <NavLink
        to={'/study-area'}
        onClick={() => handleMenuLinkClick('study_area')}
        activeClassName={styles.isActive}
        className={styles.menuItem}
        data-testid="study-area-link">
        <div className={styles['button-wrapper']}>
          <div className={styles['background']}>
            <IconStudyArea />
          </div>
          <span>Study Area</span>
        </div>
      </NavLink>
    </li>
  );
};

StudyAreaButton.propTypes = {
  handleMenuLinkClick: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default StudyAreaButton;
