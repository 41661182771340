import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as moment from 'moment-timezone';

import ButtonModal from '../uikit/ButtonModal';
import AvailabilityForm from './AvailabilityForm';
import { getDay, orderByStartTime } from './helpers';
import styles from '../../style/components/availability/AvailabilityDay.module.scss';

import { createNewAvailability, deleteAvailability, updateAvailability } from '../../actions/availabilities';

class AvailabilityDay extends Component {
  addMinute = (timeString) => {
    const datetime = moment.utc(`2000-01-01T${timeString}`);
    datetime.add(1, 'minute');
    return datetime.format('HH:mm');
  };

  subtractMinute = (timeString) => {
    const datetime = moment.utc(`2000-01-01T${timeString}`);
    datetime.subtract(1, 'minute');
    return datetime.format('HH:mm');
  };

  createNewAvailabilityAndRenewDay = (token, date, firstMinute, endLimit, isRecurring, errorCallback) => {
    const lastMinute = this.subtractMinute(endLimit);
    this.props.renewDay(date, null, 'create', errorCallback);
    return this.props.createNewAvailability(token, date, firstMinute, lastMinute, isRecurring, errorCallback);
  };

  deleteAvailabilityAndRenewDay = (token, availabilityId, errorCallback) => {
    this.props.renewDay(this.props.date, availabilityId, 'delete', errorCallback);
    return this.props.deleteAvailability(token, availabilityId, errorCallback);
  };

  updateAvailabilityAndRenewDay = (token, date, firstMinute, endLimit, availabilityId, errorCallback) => {
    const lastMinute = this.subtractMinute(endLimit);
    this.props.renewDay(date, availabilityId, 'update', errorCallback);

    return this.props.updateAvailability(token, date, firstMinute, lastMinute, availabilityId, true, errorCallback);
  };

  renderUpdateButton = (index, availabilityBlock, availability) => {
    // eslint-disable-next-line
    const { from, id, to, recur_weekly } = availabilityBlock;

    // Get a string of the first minute *after* the time slot ends.
    const endLimit = this.addMinute(to);
    const { closeAlert, date, errorCallback, token } = availability;
    const buttonText = `${from} - ${endLimit}`;
    // eslint-disable-next-line
    if (recur_weekly) {
      return (
        <ButtonModal key={index} buttonStyle="greenButton" buttonText={buttonText} closeAlert={closeAlert}>
          <AvailabilityForm
            availabilityId={id}
            createNewAvailability={this.createNewAvailabilityAndRenewDay}
            date={date}
            deleteAvailability={this.deleteAvailabilityAndRenewDay}
            errorCallback={errorCallback}
            from={from}
            to={endLimit}
            token={token}
            updateAvailability={this.updateAvailabilityAndRenewDay}
          />
        </ButtonModal>
      );
    }
    return null;
  };

  render() {
    const { closeAlert, date, token, errorCallback } = this.props;
    const sortedAvailabilityBlocks = this.props.availabilityBlocks.sort(orderByStartTime);
    return (
      <div className={styles.container}>
        <div className={styles.heading}>{`${getDay(date)}`}</div>
        <div className={styles.buttonsContainer}>
          {sortedAvailabilityBlocks.map((block, index) => this.renderUpdateButton(index, block, this.props))}
          <ButtonModal buttonText="Add new" closeAlert={closeAlert}>
            <AvailabilityForm
              createNewAvailability={this.createNewAvailabilityAndRenewDay}
              date={date}
              deleteAvailability={this.deleteAvailabilityAndRenewDay}
              errorCallback={errorCallback}
              token={token}
              updateAvailability={this.updateAvailabilityAndRenewDay}
            />
          </ButtonModal>
        </div>
      </div>
    );
  }
}

AvailabilityDay.propTypes = {
  availabilityBlocks: PropTypes.array.isRequired,
  closeAlert: PropTypes.func.isRequired,
  createNewAvailability: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  deleteAvailability: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
  renewDay: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  updateAvailability: PropTypes.func.isRequired
};

export default connect(null, { createNewAvailability, deleteAvailability, updateAvailability })(AvailabilityDay);
