import React from 'react';
import PropTypes from 'prop-types';

import styles from './ErrorMessage.module.scss';

import { ReactComponent as IconInfoGray } from '../../../assets/icons/icon_info-gray.svg';

const ErrorMessage = ({ copy }) => {
  return (
    <div className={styles.error}>
      <IconInfoGray />
      <p>
        <strong>Error:</strong>
        {copy}
      </p>
    </div>
  );
};

ErrorMessage.propTypes = {
  copy: PropTypes.string.isRequired
};

export default ErrorMessage;
