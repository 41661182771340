import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from '../Table.module.scss';

import LoadingSpinner from '../../design-system/loading/spinner/LoadingSpinner';
import ErrorToast from '../../design-system/toast/errorToast/ErrorToast';

import { ReactComponent as IconLeftArrow } from '../../../assets/icons/icon_left-arrow.svg';
import { useHistory } from 'react-router-dom';

import ScrollableTable from './ScrollableTable';
import { fetchTutorStudents, resetTutorStudents } from '../../../actions/tutor-student';

const columns = {
  learner_name: { label: 'Learner Name' },
  current_course: { label: 'Course Name' },
  status: {
    label: 'Status',
    tooltipText:
      'Status is determined by the learner’s activity: Active: Last activity within 21 days; At Risk: Last activity between 21–42 days; Inactive: No activity for 42+ days.'
  },
  current_lesson: { label: 'Current Lesson' },
  self_study_completion: { label: 'Self-Study %', tooltipText: 'Completion percentage of the current lesson assignments.' },
  last_activity: { label: 'Last Activity' },
  license_expiration_date: { label: 'Licence Expiration' },
  next_class: { label: 'Next Booking' },
  booked_classes: { label: 'Future Bookings' },
  attended_classes: { label: 'Attended' },
  canceled_classes: { label: 'Canceled' },
  canceled_late_classes: { label: 'Canceled Late' },
  missed_classes: { label: 'Missed' },
  technical_error_classes: { label: 'Technical Error' },
  mid_course_score: { label: 'Mid-Course Test Score', tooltipText: 'Score of the last submitted mid-course assignment.' },
  time_zone_name: { label: 'Time Zone' }
};

// const TEST_DATA = [
//   {
//     learner_name: 'Christian Student',
//     status: 'Active',
//     current_course: 'A1 English',
//     current_lesson: 'Lesson 4',
//     self_study_completion: '50%',
//     last_activity: '28/11/2024',
//     license_expiration_date: '18/09/2025',
//     attended_classes: 3,
//     canceled_classes: 0,
//     canceled_late_classes: 0,
//     missed_classes: 0,
//     mid_course_score: null,
//     time_zone_name: 'America/New_York',
//     next_class: '27/12/2024',
//     booked_classes: 1,
//     technical_error_classes: 0
//   },
//   {
//     learner_name: 'Humair Student',
//     status: 'Active',
//     current_course: 'B1 English',
//     current_lesson: 'Lesson 15',
//     self_study_completion: '70%',
//     last_activity: '30/11/2024',
//     license_expiration_date: '12/10/2025',
//     attended_classes: 10,
//     canceled_classes: 6,
//     canceled_late_classes: 1,
//     missed_classes: 3,
//     mid_course_score: 18,
//     time_zone_name: 'Europe/Paris',
//     next_class: '12/12/2024',
//     booked_classes: 7,
//     technical_error_classes: 2
//   },
//   {
//     learner_name: 'Mursal Student',
//     status: 'At Risk',
//     current_course: 'A2 English',
//     current_lesson: 'Lesson 1',
//     self_study_completion: '35%',
//     last_activity: '01/11/2024',
//     license_expiration_date: '01/04/2025',
//     attended_classes: 0,
//     canceled_classes: 2,
//     canceled_late_classes: 0,
//     missed_classes: 0,
//     mid_course_score: null,
//     time_zone_name: 'Europe/Berlin',
//     next_class: '01/01/2025',
//     booked_classes: 3,
//     technical_error_classes: 0
//   },
//   {
//     learner_name: 'Nathan Student',
//     status: 'Inactive',
//     current_course: 'A1 English',
//     current_lesson: 'Lesson 7',
//     self_study_completion: '0%',
//     last_activity: '27/10/2024',
//     license_expiration_date: '23/10/2026',
//     attended_classes: 0,
//     canceled_classes: 0,
//     canceled_late_classes: 0,
//     missed_classes: 0,
//     mid_course_score: 15,
//     time_zone_name: 'Europe/London',
//     next_class: null,
//     booked_classes: 0,
//     technical_error_classes: 0
//   },
//   {
//     learner_name: 'Vanessa Student',
//     status: 'Active',
//     current_course: 'B2 English',
//     current_lesson: 'Lesson 8',
//     self_study_completion: '10%',
//     last_activity: '27/10/2024',
//     license_expiration_date: '02/04/2025',
//     attended_classes: 7,
//     canceled_classes: 0,
//     canceled_late_classes: 0,
//     missed_classes: 0,
//     mid_course_score: 17,
//     time_zone_name: 'Asia/Tokyo',
//     next_class: null,
//     booked_classes: 0,
//     technical_error_classes: 0
//   }
// ];

const TutorStudents = ({ errorGettingTutorStudents, fetchTutorStudents, resetTutorStudents, isGettingTutorStudents, tutorStudents, user }) => {
  const history = useHistory();

  useEffect(() => {
    if (user?.token && user?.id) {
      fetchTutorStudents({ token: user.token, tutorId: user.id });
    }
    return () => resetTutorStudents();
  }, [user, fetchTutorStudents, resetTutorStudents]);

  // Memoize the sorted array
  const sortedStudents = useMemo(() => {
    return tutorStudents ? [...tutorStudents].sort((a, b) => a.learner_name.localeCompare(b.learner_name)) : [];
  }, [tutorStudents]);

  return (
    <main className={styles.container}>
      <h1>
        All the <span>Learners</span> currently studying with you
      </h1>
      <h2>Students will no longer appear here if their license expires or they change Coach.</h2>
      <div className={styles['table-container']}>
        <button className={styles['back']} onClick={() => history.goBack()}>
          <IconLeftArrow /> <span>Back</span>
        </button>
        {isGettingTutorStudents ? (
          <div>
            <LoadingSpinner />
          </div>
        ) : (
          <ScrollableTable
            columns={columns}
            data={sortedStudents} // Dynamic Data
            scrollable={true} // Enable scrolling
          />
        )}
        <ErrorToast
          errorMsg="Something went wrong while getting the student list. Please refresh the page and try again."
          show={errorGettingTutorStudents}
        />
      </div>
    </main>
  );
};

TutorStudents.propTypes = {
  errorGettingTutorStudents: PropTypes.bool,
  isGettingTutorStudents: PropTypes.bool,
  fetchTutorStudents: PropTypes.func.isRequired,
  resetTutorStudents: PropTypes.func.isRequired,
  tutorStudents: PropTypes.array,
  user: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    isGettingTutorStudents: state.tutorStudents.isLoading,
    tutorStudents: state.tutorStudents.data,
    errorGettingTutorStudents: state.tutorStudents.error,
    user: state.user
  };
};

const mapDispatchToProps = {
  fetchTutorStudents,
  resetTutorStudents
};

export default connect(mapStateToProps, mapDispatchToProps)(TutorStudents);
