import * as Sentry from '@sentry/browser';
import { actionCreatorsFactory, asyncActionType, createActionTypes } from '../utils/action-helpers';
import { updateAccount } from './user';

export const AppLanguageActionTypes = createActionTypes('AppLanguage', [
  asyncActionType('SET_USER_LANGUAGE'),
  'SET_APP_LANGUAGE',
  'SET_AVAILABLE_LANGUAGES',
  'COULDNT_SET_LANGUAGE',
  'SET_APP_DEFAULT_LANGUAGE'
]);

export const setAppLanguage = (language) => {
  if (!language || !window?.Weglot?.switchTo)
    return {
      type: AppLanguageActionTypes.COULDNT_SET_LANGUAGE
    };

  window.Weglot?.switchTo(language);
  return {
    type: AppLanguageActionTypes.SET_APP_LANGUAGE,
    payload: language
  };
};

const setUserLanguageActions = actionCreatorsFactory(
  [AppLanguageActionTypes.SET_USER_LANGUAGE, AppLanguageActionTypes.SET_USER_LANGUAGE_SUCCESS, AppLanguageActionTypes.SET_USER_LANGUAGE_FAIL],
  'setUserLanguage'
);

export function setUserLanguage({ user, language }) {
  return async (dispatch) => {
    try {
      dispatch(setUserLanguageActions.setUserLanguage());
      dispatch(updateAccount({ ...user, preferredLanguage: language }, user?.token, () => dispatch(setUserLanguageActions.setUserLanguageSuccess())));
    } catch (err) {
      Sentry.captureException(err);
      dispatch(setUserLanguageActions.setUserLanguageFail());
    }
  };
}

export const setAppDefaultLanguage = (payload) => ({
  type: AppLanguageActionTypes.SET_APP_DEFAULT_LANGUAGE,
  payload
});
