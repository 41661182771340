import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ONBOARDING_STEPS } from '../../../enums/onboardingSteps';

import generalStyles from '../GeneralStyles.module.scss';
import styles from './AlreadyIn.module.scss';

const AlreadyIn = ({ moveToNextStep }) => {
  const friendlyMoveToNextStep = () => {
    setTimeout(() => {
      moveToNextStep(ONBOARDING_STEPS.selectCoach);
    }, 2000);
  };

  useEffect(() => {
    friendlyMoveToNextStep();
  }, []);

  return (
    <div className={[generalStyles['content-wrapper'], styles.alreadyInWrapper].join(' ')}>
      <h1 className={generalStyles['title']}>It seems that you are already logged in ...</h1>
      <p className={generalStyles['subtitle']}>You can move to the next step :)</p>
    </div>
  );
};

AlreadyIn.propTypes = {
  moveToNextStep: PropTypes.func.isRequired
};

export default AlreadyIn;
