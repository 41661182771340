import instance from '..';

export default class EventsAPI {
  static async sendEvent(token, eventType, appointmentId) {
    const eventPayload = {
      eventType,
      appt: appointmentId
    };
    return instance.post('/api/add_activity_event/', eventPayload, {
      headers: { Authorization: `Token ${token}` }
    });
  }
}
