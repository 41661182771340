import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import BookingSummaryItem from '../BookingSummaryItem';
import Button from '../../../design-system/button/Button';
import Modal from '../../../design-system/modal/Modal';

import {
  addToCancelingList,
  cancelIntroductoryClass,
  cleanPreviousState,
  cleanErrorState,
  setOpenCancelingModal
} from '../../../../actions/new-booking';
import mixpanel from '../../../../utils/mixpanel-helper';

import styles from './CancelingModal.module.scss';

import IconWarning from '../../../../assets/icons/icon_warning.svg';
import { getIntroClass } from '../../../../actions/sessions';
import ErrorToast from '../../../design-system/toast/errorToast/ErrorToast';

const IntroductoryLessonCancelingModal = ({
  activeOrders,
  addToCancelingListAction,
  appointmentsToBeCancelled,
  cancelIntroductoryClassAction,
  cleanPreviousStateAction,
  cleanErrorStateAction,
  errorMessage,
  getIntroClassAction,
  hasErrorOnCancelingIntroClass,
  isCancelingIntroClassAppointment,
  setOpenCancelingModalAction,
  student,
  timezone
}) => {
  const [cancellationMotive, setCancellationMotive] = useState('');

  const handleCloseModal = () => {
    cleanPreviousStateAction();
  };

  useEffect(() => {
    if (!appointmentsToBeCancelled.length) handleCloseModal();
  }, [appointmentsToBeCancelled.length]);

  const handleUserTyping = (evt) => {
    setCancellationMotive(evt.target.value);
  };

  const handleCancelingRemoval = (appointment) => {
    addToCancelingListAction(appointment);
  };

  const getAppointmentsToBeCancelled = () => {
    if (!appointmentsToBeCancelled.length) return;
    return appointmentsToBeCancelled.map((appt) => ({ id: appt.id, number: 'Intro Class' }));
  };

  const handleAppointmentsCanceling = () => {
    const apptData = getAppointmentsToBeCancelled();
    if (!apptData) return;
    mixpanel.lessonAction('Cancelled', {
      firstLessonNumber: apptData.number,
      totalLessons: 1,
      cancellationMotive,
      type: 'intro'
    });
    cancelIntroductoryClassAction({
      studentToken: student.token,
      appointmentsIds: apptData.map(({ id }) => id),
      cancellationMotive,
      successCallback: () => {
        if (!activeOrders[0]?.order_appointment_types) return;
        const { language_id: languageId } = activeOrders[0].order_appointment_types[0];
        if (languageId) getIntroClassAction({ token: student.token, languageId });
        setOpenCancelingModalAction(false);
      }
    });
  };

  const lateCancelingWarning = (
    <div className={styles.lateCancelingWarning}>
      <img src={IconWarning} alt="warning sign" />
      <p>Your Meet Your Coach session is in less than 24 hours. You can still cancel it, although you will still be charged.</p>
    </div>
  );

  return (
    <>
      <Modal
        loading={isCancelingIntroClassAppointment}
        content={
          <div className={styles.cancelingModalWrapper} data-testid="canceling-intro-class">
            <h1>Canceling</h1>
            <div className={styles.contentWrapper}>
              <div className={styles.cancelingSummary}>
                <p>You are about to cancel your Meet Your Coach session</p>
                <div className={styles.bookingSummaryList}>
                  <ul>
                    {appointmentsToBeCancelled.map((appt, index) => (
                      <BookingSummaryItem
                        cancel
                        introClass
                        key={index}
                        index={index + 1}
                        removeNewScheduleFromFinalList={handleCancelingRemoval}
                        lesson={appt}
                        onlyOneItem={appointmentsToBeCancelled.length === 1}
                        timezone={timezone}
                      />
                    ))}
                  </ul>
                  <textarea
                    className={styles.motivationTextarea}
                    rows={5}
                    value={cancellationMotive}
                    onChange={handleUserTyping}
                    placeholder="Tell your coach why are you cancelling?"
                  />
                </div>
              </div>
              {appointmentsToBeCancelled.length && appointmentsToBeCancelled[0].lateCanceling && lateCancelingWarning}
              <div className={styles.buttonsWrapper}>
                <Button outfit="secondaryButton" onClick={handleCloseModal} id="canceling-modal-close">
                  Close
                </Button>
                <Button
                  nowrap
                  outfit="cancelButton"
                  loading={isCancelingIntroClassAppointment}
                  onClick={handleAppointmentsCanceling}
                  disabled={!appointmentsToBeCancelled.length}
                  id="canceling-modal-cancel">
                  Cancel session(s)
                </Button>
              </div>
            </div>
          </div>
        }
      />
      <ErrorToast errorMsg={errorMessage} show={hasErrorOnCancelingIntroClass} onClose={cleanErrorStateAction} />
    </>
  );
};

const mapStateToProps = (state) => ({
  activeOrders: state.orderInfo?.active_orders,
  appointmentsToBeCancelled: state.newBooking.canceling.appointmentsToBeCanceled,
  errorMessage: state.newBooking.canceling.error,
  hasErrorOnCancelingIntroClass: state.newBooking.canceling.hasErrorOnCancelingIntroClass,
  isCancelingIntroClassAppointment: state.newBooking.canceling.isCancelingIntroClassAppointment,
  student: state.user,
  timezone: state.time.timezone
});

IntroductoryLessonCancelingModal.propTypes = {
  activeOrders: PropTypes.array,
  addToCancelingListAction: PropTypes.func.isRequired,
  appointmentsToBeCancelled: PropTypes.array.isRequired,
  cancelIntroductoryClassAction: PropTypes.func.isRequired,
  cleanPreviousStateAction: PropTypes.func.isRequired,
  cleanErrorStateAction: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  getIntroClassAction: PropTypes.func,
  hasErrorOnCancelingIntroClass: PropTypes.bool,
  isCancelingIntroClassAppointment: PropTypes.bool,
  setOpenCancelingModalAction: PropTypes.func,
  student: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired
};

IntroductoryLessonCancelingModal.defaultProps = {
  isCancelingIntroClassAppointment: false
};

export default connect(mapStateToProps, {
  addToCancelingListAction: addToCancelingList,
  cancelIntroductoryClassAction: cancelIntroductoryClass,
  cleanPreviousStateAction: cleanPreviousState,
  cleanErrorStateAction: cleanErrorState,
  getIntroClassAction: getIntroClass,
  setOpenCancelingModalAction: setOpenCancelingModal
})(IntroductoryLessonCancelingModal);
