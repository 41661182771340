import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../design-system/button/Button';

import styles from './Translation.module.scss';

const Translation = ({ isTranslated, setIsTranslated }) => {
  const handleClick = () => {
    setIsTranslated((prevState) => !prevState);
  };
  return (
    <div className={styles.translateButtonWrapper}>
      <p>
        {isTranslated
          ? 'All messages in this conversation have been translated into your preferred language. '
          : "You're viewing messages in the language they were written in."}
      </p>
      <Button outfit="link" onClick={handleClick}>
        {isTranslated ? 'See Original' : 'See Translation'}
      </Button>
    </div>
  );
};

Translation.propTypes = {
  isTranslated: PropTypes.bool.isRequired,
  setIsTranslated: PropTypes.func.isRequired
};

export default Translation;
