import axios from 'axios';
import { createActionTypes } from '../utils/action-helpers';

export const AssignmentsActionTypes = createActionTypes('Assignments', ['SET_CURRENT_CEREGO_ASSIGNMENT']);

export const setCurrentCeregoAssignment = (assignment, callback) => {
  if (callback) callback();
  return {
    type: AssignmentsActionTypes.SET_CURRENT_CEREGO_ASSIGNMENT,
    payload: assignment
  };
};

export function deleteAssignmentDeliveryFile(token, fileId, onSuccess, onError) {
  axios
    .delete(`/api/assessments/files/${fileId}/`, {
      headers: { Authorization: `Token ${token}`, 'content-type': 'multipart/form-data' }
    })
    .then(() => {
      if (onSuccess) {
        onSuccess(fileId);
      }
    })
    .catch(() => {
      if (onError) {
        onError();
      }
    });
}

export function uploadAssignmentFiles(token, file, assessmentId, assignmentType, onSuccess, onError) {
  const formData = new FormData();
  formData.append('submitted_file', file);
  formData.append('assignment_type', assignmentType);
  axios
    .patch(`/api/assessments/${assessmentId}/assignment_submission`, formData, {
      headers: { Authorization: `Token ${token}`, 'content-type': 'multipart/form-data' }
    })
    .then((response) => {
      if (onSuccess) {
        onSuccess(response.data);
      }
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.error;
      if (onError) {
        onError(errorMessage);
      }
    });
}
