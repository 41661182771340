import React from 'react';
import AnimatedDots from '../../design-system/animated-dots/AnimatedDots';

import styles from './Message.module.scss';

const SSOMessage = () => (
  <div className={styles.wrapper}>
    <span>
      Granting you access <AnimatedDots />
    </span>
  </div>
);

export default SSOMessage;
