import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../style/components/uikit/Header.module.scss';

const Header = ({ className, text }) => {
  const classNames = `${styles.header} ${className}`;
  return <h1 className={classNames}>{text}</h1>;
};

Header.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired
};

Header.defaultProps = {
  className: ''
};

export default Header;
