import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import TestIntro from '../components/test-page/TestIntro.js';
import TestTypeform from '../components/test-page/TestTypeform.js';
import TestYouTube from '../components/test-page/TestYouTube.js';
import TestCerego from '../components/test-page/TestCerego.js';
import TestChat from '../components/test-page/TestChat';
import TestFinished from '../components/test-page/TestFinished.js';
import { nextPage, prevPage, resetPage } from '../actions/test-page.js';
import styles from '../style/containers/TestPage.module.scss';

const TestPage = ({ currentPage, nextPageAction, prevPageAction, resetAction }) => {
  const history = useHistory();
  return (
    <div className={styles.testPageWrapper}>
      {currentPage === 0 && <TestIntro nextPage={nextPageAction} prevPage={prevPageAction} restart={resetAction} />}

      {currentPage === 1 && <TestTypeform nextPage={nextPageAction} prevPage={prevPageAction} restart={resetAction} />}

      {currentPage === 2 && <TestYouTube nextPage={nextPageAction} prevPage={prevPageAction} restart={resetAction} />}

      {currentPage === 3 && <TestChat nextPage={nextPageAction} prevPage={prevPageAction} restart={resetAction} />}

      {currentPage === 4 && <TestCerego nextPage={nextPageAction} prevPage={prevPageAction} restart={resetAction} />}

      {currentPage === 5 && (
        <TestFinished
          nextPage={() => {
            history.push('/');
          }}
          prevPage={prevPageAction}
          restart={resetAction}
        />
      )}
      <p className={styles.techHelp}>
        For any assistance with technical issues during the test, please reach out to us at{' '}
        <a href="mailto:support@chatterbox.io">support@chatterbox.io</a>.
      </p>
    </div>
  );
};

TestPage.propTypes = {
  nextPageAction: PropTypes.func.isRequired,
  prevPageAction: PropTypes.func.isRequired,
  resetAction: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired
};
const mapStateToProps = (state) => ({
  currentPage: state.testPage.currentPage
});

export default connect(mapStateToProps, {
  nextPageAction: nextPage,
  prevPageAction: prevPage,
  resetAction: resetPage
})(TestPage);
