import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import FormStatus from './FormStatus';
import { validate, minLength8, passwordMatch } from './validate';

import formStyles from '../../style/utils/_form.module.scss';
import styles from '../../style/components/account/AccountForm.module.scss';

import { resetPasswordUpdate, updatePassword } from '../../actions/user';

import { renderField } from '../../utils/form-helpers';
import Mixpanel from '../../utils/mixpanel-helper.js';
import Button from '../design-system/button/Button';
import SuccessToast from '../design-system/toast/successToast/SuccessToast';
import ErrorToast from '../design-system/toast/errorToast/ErrorToast';

const PasswordForm = ({
  dirty,
  handleSubmit,
  hasError,
  invalid,
  isLoading,
  isSso,
  isSucces,
  pristine,
  reset,
  resetPasswordUpdateAction,
  token,
  updatePasswordAction
}) => {
  const [formStatus, setFormStatus] = useState('');
  const [error, setError] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');

  const handleError = (error) => {
    if (error) {
      // checks whether the error is an object, in which case the error's message is displayed. Otherwise generic "Please try again..." is displayed
      const message = error?.response?.data?.new_password2
        ? error?.response?.data?.new_password2[0]
        : 'Please try again and contact support@chatterbox.io if the problem persists.';
      setError(error);
      setErrorMsg(message);
    }
  };

  const updatePassword = (values) => {
    if (values.new_password1 && values.new_password2) {
      updatePasswordAction(
        values,
        token,
        () => {
          Mixpanel.track('Password successfully updated');
          reset();
        },
        (err) => {
          handleError(err);
        }
      );
    } else {
      Mixpanel.track('Password update failed');
      setFormStatus('warning');
    }
  };

  if (isSso) return null;

  return (
    <form className={[formStyles.form, styles['password-form']].join(' ')} onSubmit={handleSubmit(updatePassword)}>
      <h2>Update password</h2>
      <FormStatus formType="Password" className={formStyles.alert} status={formStatus} error={error} message={errorMsg} />
      <Field label="New password" name="new_password1" component={renderField} validate={[minLength8]} type="password" />
      <Field label="Confirm new password" name="new_password2" component={renderField} validate={[passwordMatch]} type="password" />
      <SuccessToast msg="Your password was changed." onClose={resetPasswordUpdateAction} show={isSucces} />
      <ErrorToast
        errorMsg={errorMsg ? errorMsg : 'Something went wrong while changing your password.'}
        onClose={resetPasswordUpdateAction}
        show={hasError}
      />
      <Button type="submit" loading={isLoading} disabled={isLoading || pristine || (dirty && invalid)}>
        Save new password
      </Button>
    </form>
  );
};

PasswordForm.propTypes = {
  dirty: PropTypes.bool,
  invalid: PropTypes.bool,
  hasError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSucces: PropTypes.bool.isRequired,
  isSso: PropTypes.bool,
  pristine: PropTypes.bool,
  updatePasswordAction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  resetPasswordUpdateAction: PropTypes.func.isRequired
};

PasswordForm.defaultProps = {
  isLoading: false,
  isSucces: false,
  isSso: false,
  hasError: false
};

const mapStateToProps = (state) => ({
  hasError: state.user.hasErrorUpdatePassword,
  isLoading: state.user.isLoadingPassword,
  isSucces: state.user.isPasswordChangeSuccess,
  isSso: state.user.is_sso
});

export default reduxForm({
  validate,
  form: 'PasswordForm'
})(
  connect(mapStateToProps, {
    updatePasswordAction: updatePassword,
    resetPasswordUpdateAction: resetPasswordUpdate
  })(PasswordForm)
);
