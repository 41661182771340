import { GET_LAST_APPOINTMENT, GET_LAST_APPOINTMENT_ERROR } from '../actions/types';

export default function lastAppointmentReducer(state = null, action) {
  switch (action.type) {
    case GET_LAST_APPOINTMENT:
      return action.payload;
    case GET_LAST_APPOINTMENT_ERROR:
      return null;
    default:
      return state;
  }
}
