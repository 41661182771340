import CoursesAPI from '../services/course';
import { actionCreatorsFactory, asyncActionType, createActionTypes } from '../utils/action-helpers';
import { transformObjectUnderscoreToCamelCase } from '../utils/string-helpers';
import { isTokenExpired } from './common';
import { getUnitsLiveProgressAndScore, setNextLessonToStudy, setSelectedUnit } from './lessons';
import { setNextSessionHappeningActions } from './sessions';
import { setHasFinishedPlacementTest } from './new-onboarding';
import { ASSESSMENT_TYPES } from '../enums/assessments';
import { decodeJWTToken } from '../utils/jwt-helpers';

export const INTERVAL_BETWEEN_LESSONS_AND_ASSESSMENTS = 5;

export const CourseActionTypes = createActionTypes('Course', [
  asyncActionType('GET_ENROLLED_COURSES'),
  asyncActionType('GET_SELECTED_COURSE_DETAILS'),
  'SET_SELECTED_COURSE_ON_ONBOARDING',
  'SET_ENROLLED_COURSE_BEFORE_PLACEMENT_TEST',
  asyncActionType('GET_STUDENT_COURSE_STATUS_AND_TIME')
]);

export const setSelectedCourseOnOnboarding = (token) => {
  const decoded = decodeJWTToken(token);
  const { language, languageId } = transformObjectUnderscoreToCamelCase(decoded);
  return {
    type: CourseActionTypes.SET_SELECTED_COURSE_ON_ONBOARDING,
    payload: { language: { id: languageId, language } }
  };
};

export const getEnrolledCoursesActions = actionCreatorsFactory(
  [CourseActionTypes.GET_ENROLLED_COURSES, CourseActionTypes.GET_ENROLLED_COURSES_SUCCESS, CourseActionTypes.GET_ENROLLED_COURSES_FAIL],
  'getEnrolledCourses'
);

export const getEnrolledCourses = (token, predeterminedCourseId, predeterminedUnitId) => {
  return async (dispatch) => {
    try {
      dispatch(getEnrolledCoursesActions.getEnrolledCourses());
      dispatch(getSelectedCourseDetailsActions.getCourseDetails());
      const { data: enrolledCourses } = await CoursesAPI.getEnrolledCourses(token);
      dispatch(getEnrolledCoursesActions.getEnrolledCoursesSuccess(enrolledCourses));
      dispatch(setHasFinishedPlacementTest(Boolean(enrolledCourses.length)));
      if (predeterminedCourseId && enrolledCourses.some((course) => Number(course?.id) === Number(predeterminedCourseId))) {
        dispatch(getCourseDetails({ token, courseId: predeterminedCourseId, unitIndex: predeterminedUnitId }));
      } else {
        dispatch(getCourseDetails({ token, courseId: enrolledCourses[0]?.id }));
      }
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        const errMsg = err.message || 'Something went wrong while getting your enrolled courses';
        dispatch(getEnrolledCoursesActions.getEnrolledCoursesFail(errMsg));
      }
    }
  };
};

export const mergeLessonsAndAssessments = (selectedCourse) => {
  const lessons = selectedCourse?.lessons;
  const assessments = selectedCourse?.assessments && [...selectedCourse.assessments];

  if (!lessons?.length && !assessments?.length) return [];

  const preMerged = lessons.map((lesson, index) => {
    const isMultiple = index % INTERVAL_BETWEEN_LESSONS_AND_ASSESSMENTS === 0;
    if (isMultiple && index) return [assessments.splice(0, 1)[0], lesson];
    return lesson;
  });

  return [...preMerged.flat(1), ...assessments].filter((unit) => Boolean(unit));
};

export const setWhichUnitsAreOptional = (units) => units.map((unit) => ({ ...unit, isOptional: isMidCourseExam(unit) }));

const getCourseStatusAndTimeActions = actionCreatorsFactory(
  [
    CourseActionTypes.GET_STUDENT_COURSE_STATUS_AND_TIME,
    CourseActionTypes.GET_STUDENT_COURSE_STATUS_AND_TIME_SUCCESS,
    CourseActionTypes.GET_STUDENT_COURSE_STATUS_AND_TIME_FAIL
  ],
  'getCourseStatusAndTime'
);

const getCourseStatusAndTime = ({ token, courseId }) => {
  return async (dispatch) => {
    try {
      dispatch(getCourseStatusAndTimeActions.getCourseStatusAndTime());
      const { data: overallCourseStatus } = await CoursesAPI.getOverallCourseStatus({ token, courseId });

      if (overallCourseStatus) dispatch(getCourseStatusAndTimeActions.getCourseStatusAndTimeSuccess(overallCourseStatus));
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        const errMsg = err.message || 'Something went wrong while getting getting course current status';
        dispatch(getCourseStatusAndTimeActions.getCourseStatusAndTimeFail(errMsg));
      }
    }
  };
};

const getSelectedCourseDetailsActions = actionCreatorsFactory(
  [
    CourseActionTypes.GET_SELECTED_COURSE_DETAILS,
    CourseActionTypes.GET_SELECTED_COURSE_DETAILS_SUCCESS,
    CourseActionTypes.GET_SELECTED_COURSE_DETAILS_FAIL
  ],
  'getCourseDetails'
);

export const getCourseDetails = ({ token, courseId, unitIndex, successCallback }) => {
  return async (dispatch) => {
    try {
      if (!courseId) throw Error('courseId is missing');
      dispatch(getSelectedCourseDetailsActions.getCourseDetails());
      const { data: courseDetails } = await CoursesAPI.getCourseDetails({ token, courseId });
      dispatch(setNextLessonToStudy({ token, unit: getNextLessonToStudy(courseDetails) }));
      const merged = mergeLessonsAndAssessments(courseDetails);
      const units = setWhichUnitsAreOptional(merged);
      const practiceExam = getSpecificExam(courseDetails, ASSESSMENT_TYPES.PRACTICE);
      const finalExam = getSpecificExam(courseDetails, ASSESSMENT_TYPES.FINAL);

      if (unitIndex && units[unitIndex]) {
        dispatch(setSelectedUnit({ token, unit: units[unitIndex] }));
      } else {
        dispatch(setSelectedUnit({ token, unit: getNextLessonToStudy(courseDetails) }));
      }
      dispatch(setNextSessionHappeningActions.setNextSessionHappening(getNextSessionHappening(courseDetails)));
      dispatch(getSelectedCourseDetailsActions.getCourseDetailsSuccess({ ...courseDetails, practiceExam, finalExam, units }));

      dispatch(getUnitsLiveProgressAndScore({ token, courseId }));
      dispatch(getCourseStatusAndTime({ token, courseId }));

      if (successCallback) successCallback();
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        const errMsg = err.message || 'Something went wrong while getting the selected course details';
        dispatch(getSelectedCourseDetailsActions.getCourseDetailsFail(errMsg));
      }
    }
  };
};

const isMidCourseExam = (unit) => {
  if (!unit?.name && !unit?.title) return false;
  return (unit.name || unit.title).toLowerCase().includes(ASSESSMENT_TYPES.MID_COURSE);
};

const getSpecificExam = (selectedCourse, examName) => {
  const assessments = selectedCourse?.assessments;
  if (!assessments || !assessments.length) return null;
  return assessments.find((ass) => ass.title.toLowerCase().includes(examName));
};

const getNextLessonToStudy = (selectedCourse) => {
  if (!selectedCourse || !selectedCourse?.next_lesson_to_study || !selectedCourse?.lessons) return {};

  const nextLessonToStudy = selectedCourse.next_lesson_to_study;
  if (hasStudentFinishedCourse(selectedCourse))
    return {
      ...selectedCourse.lessons[selectedCourse.lessons.length - 1]
    };
  return selectedCourse.lessons.find((lesson) => Number(lesson.number) === Number(nextLessonToStudy));
};

const getNextSessionHappening = (selectedCourse) => {
  if (!selectedCourse || !selectedCourse.next_lesson_happening || !selectedCourse.lessons) return {};

  const nextSessionHappening = selectedCourse.next_lesson_happening;
  return selectedCourse.lessons.find((lesson) => Number(lesson.number) === Number(nextSessionHappening));
};

const hasStudentFinishedCourse = (selectedCourse) => {
  const nextLessonToStudy = selectedCourse.next_lesson_to_study;

  return nextLessonToStudy > selectedCourse.lessons.length;
};
