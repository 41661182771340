import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../design-system/button/Button';

import styles from '../../style/containers/TestPage.module.scss';

const TestIntro = ({ nextPage, prevPage, restart }) => (
  <>
    <h1>Chatterbox Technical Test</h1>
    <p className={styles.introParagraph}>Thank you for completing the test. We wish you a great learning experience on the Chatterbox app!</p>
    <div className={styles.buttonContainer}>
      <Button outfit="secondaryButton" className={styles.nextButton} onClick={prevPage}>
        Back
      </Button>
      <Button outfit="regularButton" className={styles.nextButton} onClick={nextPage}>
        Finish Test
      </Button>
      <Button outfit="secondaryButton" className={styles.nextButton} onClick={restart}>
        Restart
      </Button>
    </div>
  </>
);

TestIntro.propTypes = {
  nextPage: PropTypes.func.isRequired,
  prevPage: PropTypes.func.isRequired,
  restart: PropTypes.func.isRequired
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(TestIntro);
