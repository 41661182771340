import instance from '..';

export default class UserAPI {
  static async getUserData(token) {
    return instance.get('/api/user/', { headers: { Authorization: `Token ${token}` } });
  }
  static async updatePassword(token, data) {
    return instance.post('/rest-auth/password/change/', data, {
      headers: { Authorization: `Token ${token}` }
    });
  }
  static async updateAccountData(data) {
    if (data?.token) {
      return instance.put('/api/user/', data, { headers: { Authorization: `Token ${data.token}` } });
    }
  }
}
