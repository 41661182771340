import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Radio, FormGroup, Label } from 'react-bootstrap';

import styles from '../../style/components/uikit/PurchaseCreditsModal.module.scss';

const dict = {
  gbp: '£'
};

class PurchaseCreditsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProduct: 0
    };
  }

  handleChange = (event) => {
    this.setState({ selectedProduct: event.target.value });
  };

  render() {
    const { purchase, prices, deliveryType, trainingType } = this.props;

    return (
      <div className={styles.container}>
        <h4 className={styles.title}>
          {'Top up your credits for '}
          <Label className={styles.productName} bsStyle="info">
            {`${trainingType} | ${deliveryType}`}
          </Label>
        </h4>
        <FormGroup>
          {prices.map((p, i) => (
            <Row key={i} className={styles.priceRow}>
              <Col xs={1}>
                <Radio value={i} name="radioGroup" className={styles.radioButton} onChange={this.handleChange} defaultChecked={i === 0} />
              </Col>
              <Col xs={6}>{`${parseFloat(p.quantity, 10)} Credit${parseInt(p.quantity, 10) > 1 ? 's' : ''}`}</Col>
              <Col xs={5} className={styles.priceCell}>
                <strong>{`${dict[p.currency]}${p.price}`}</strong>
              </Col>
            </Row>
          ))}
        </FormGroup>
        <button
          className={[styles.purchaseBtn, 'btn btn-primary'].join(' ')}
          onClick={() => {
            const i = this.state.selectedProduct;
            const amount = prices[i].quantity;
            const cost = prices[i].price;
            const currency = prices[i].currency;
            purchase(amount, cost, currency, trainingType, deliveryType);
            this.props.handleCloseModal();
          }}>
          Complete purchase
        </button>
      </div>
    );
  }
}

PurchaseCreditsModal.propTypes = {
  purchase: PropTypes.func.isRequired,
  prices: PropTypes.array,
  deliveryType: PropTypes.string.isRequired,
  trainingType: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired
};

PurchaseCreditsModal.defaultProps = {
  prices: []
};

export default PurchaseCreditsModal;
