import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BigCalendar from 'react-big-calendar';
import moment from 'moment-timezone';
import { isMobileOnly } from 'react-device-detect';

import Modal from '../design-system/modal/Modal';
import CalendarToolbar from './CalendarToolbar';
import TutorAppointment from '../TutorAppointment';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from '../../style/components/calendar/Calendar.module.scss';

export const ABSENCE_PERIOD_EVENT = 'absence-period';
export const APPOINTMENT_EVENT = 'appointment';
export const AVAILABILITY_EVENT = 'availability';
export const BREAK_EVENT = 'break';

moment.locale('en');
BigCalendar.momentLocalizer(moment);

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      appointment: null
    };
  }

  getCustomToolbar = (toolbar) => (
    <CalendarToolbar
      date={toolbar.date}
      label={toolbar.label}
      messages={toolbar.messages}
      onNavigate={toolbar.onNavigate}
      onViewChange={toolbar.onViewChange}
      updateEvents={this.props.updateEvents}
      view={toolbar.view}
      views={toolbar.views}
    />
  );

  styleEvent = (event) => {
    let className;

    if (event.type === ABSENCE_PERIOD_EVENT) {
      className = styles.absencePeriod;
    } else if (event.type === APPOINTMENT_EVENT) {
      let status = event.status || '';

      // Remove any appointment status prefix (e.g. 'paid_').
      // eslint-disable-next-line no-unused-vars
      for (const prefix of ['invoiced_', 'paid_']) {
        const statusHasPrefix = status.startsWith(prefix);

        if (statusHasPrefix) {
          status = status.slice(prefix.length);
          break;
        }
      }

      // If the status is paid or invoiced, just consider it as happened.
      const happenedStatuses = ['invoiced', 'paid'];
      const statusIsHappened = happenedStatuses.indexOf(status) >= 0;
      status = statusIsHappened ? 'happened' : status;

      // If the status is `no_chat_code`, just used `booked`.
      status = status === 'no_chat_code' ? 'booked' : status;

      className = [styles.appointment, styles[`appointment-${status}`]].join(' ');
    } else if (event.type === AVAILABILITY_EVENT) {
      className = styles.availability;
    } else if (event.type === BREAK_EVENT) {
      className = styles.breakEvent;
    }

    return { className };
  };

  showAppointment = (event) => {
    if (event.type === APPOINTMENT_EVENT) {
      const appointment = this.props.appointments.filter((ap) => ap.id === event.id)[0];
      appointment.status = appointment.status.replace(/_/g, ' ').replace('canceled', 'cancelled');
      this.setState({
        appointment
      });
    }
  };

  hideAppointment = () => {
    this.setState({
      appointment: null
    });
  };

  renderAppointment = (appointment) => (
    <Modal
      onClose={this.hideAppointment}
      content={
        <TutorAppointment
          className={styles.modal}
          appointment={appointment}
          errorCallback={this.props.errorCallback}
          handleCloseModal={() => this.setState({ appointment: null })}
          successCallback={this.props.successCallback}
          token={this.props.token}
          tutor={this.props.tutor}
          history={this.props.history}
        />
      }
    />
  );

  render() {
    const defaultView = isMobileOnly ? 'day' : 'week';
    const { appointment } = this.state;

    return (
      <div className={styles.container}>
        <BigCalendar
          {...this.props}
          components={{ toolbar: this.getCustomToolbar }}
          defaultView={defaultView}
          eventPropGetter={this.styleEvent}
          events={this.props.events || []}
          onSelectEvent={(event) => this.showAppointment(event)}
          onNavigate={this.props.onNavigate}
          views={['week', 'day']}
        />
        {appointment && this.renderAppointment(appointment)}
      </div>
    );
  }
}

Calendar.propTypes = {
  appointments: PropTypes.array.isRequired,
  errorCallback: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  successCallback: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  tutor: PropTypes.object.isRequired,
  updateEvents: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default Calendar;
