import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setSelectedCourseOnOnboarding } from '../actions/courses';
import { resetSignIn } from '../actions/new-login';
import { getOnboardingData, storeDecodedToken } from '../actions/new-onboarding';
import Coaches from '../components/new-onboarding/Coaches/Coaches';
import OnboardingAnimation from '../components/new-onboarding/OnboardingAnimation';
import Connect from '../components/new-onboarding/Connect/Connect';
import Steps from '../components/new-onboarding/Steps';
import TellUsWhy from '../components/new-onboarding/TellUsWhy/TellUsWhy';

import styles from '../style/containers/NewOnboarding.module.scss';
import { clearUser } from '../actions/user';
import storageHelpers from '../utils/storage-helpers';
import { useQueryParams } from '../hooks/useQueryParams';
import LoadingSpinner from '../components/design-system/loading/spinner/LoadingSpinner';
import { convertBooleanStringToBoolean } from '../utils/string-helpers';
import { ONBOARDING_STEPS } from '../enums/onboardingSteps';
import { setAppLanguage, setUserLanguage } from '../actions/app-language';
import { hasWeglot } from '../utils/language-helpers';
import NavbarLanguageSwitcher from '../components/weglot-language-switcher/NavbarLanguageSwitcher';
import { decodeJWTToken } from '../utils/jwt-helpers';

const NewOnboarding = ({
  clearUserAction,
  currentLang,
  getOnboardingDataAction,
  isLoadingGetPreviousOnboarding,
  previousOnboarding,
  resetSignInAction,
  setAppLanguageAction,
  setSelectedCourseOnOnboardingAction,
  setUserLanguageAction,
  storeDecodedTokenAction,
  user
}) => {
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [enabledSteps, setEnabledSteps] = useState([0]);
  const [isHandlingPrevOnboarding, setIsHandlingPrevOnboarding] = useState(true);
  const history = useHistory();
  const params = useQueryParams();
  const invitationToken = params['token'];
  const email = params['email'];
  const shouldSkipAnimation = convertBooleanStringToBoolean(params['skip_animation']);
  const shouldResetUser = convertBooleanStringToBoolean(params['reset_user']) ?? true;

  const setLanguageFromToken = (token) => {
    const { preferred_language } = decodeJWTToken(token);
    if (preferred_language) setAppLanguageAction(preferred_language);
  };

  useEffect(() => {
    storageHelpers.clearLocalStorage();
    if (!invitationToken || !email) return setIsInvalidToken(true);
    storeDecodedTokenAction({ invitationToken, email });
    setSelectedCourseOnOnboardingAction(invitationToken);
    setLanguageFromToken(invitationToken);
    getOnboardingDataAction({ password_token: invitationToken, email });
  }, []);

  useEffect(() => {
    if (shouldResetUser) {
      clearUserAction();
      resetSignInAction();
    }
  }, [shouldResetUser]);

  useEffect(() => {
    if (isInvalidToken) toast.error('The link you are trying to access is incorrect. Please contact us at support@chatterbox.io.');
  }, [isInvalidToken]);

  const handleStepChange = (stepIndex) => {
    setCurrentStep(stepIndex);
  };

  const moveToNextStep = () => {
    handleStepChange(currentStep + 1);
    setEnabledSteps((prevState) => [...prevState, currentStep + 1]);
  };

  const jumpToStep = (step) => {
    handleStepChange(step);
    const arrayUntilStep = Array(step + 1)
      .fill(0)
      .map((smth, index) => index);
    setEnabledSteps(arrayUntilStep);
    setIsHandlingPrevOnboarding(false);
  };

  const handlePreviousOnboarding = () => {
    if (!previousOnboarding) return;
    if (!Object.keys(previousOnboarding).length) return;
    setIsHandlingPrevOnboarding(true);
    const {
      motivation,
      selected_coach: selectedCoach,
      has_created_password: hasCreatedPassword,
      first_class_booked: firstClassBooked
    } = previousOnboarding;

    if (firstClassBooked) {
      history.push('/');
      toast.error('It seems that you have already booked your Meet Your Coach session, that is why we redirected you to the login page.');
      return;
    }

    if (hasCreatedPassword) return jumpToStep(ONBOARDING_STEPS.connect);
    if (selectedCoach) return jumpToStep(ONBOARDING_STEPS.connect);
    if (motivation) return jumpToStep(ONBOARDING_STEPS.selectCoach);
  };

  useEffect(() => {
    if (!previousOnboarding || !Object.keys(previousOnboarding).length) return setIsHandlingPrevOnboarding(false);
    handlePreviousOnboarding();
  }, [previousOnboarding]);

  const setUserLanguageAfterLogin = (stepIndex) => {
    const stepName = Object.keys(steps)[stepIndex] || 'start';
    if (stepName !== 'book') return;
    setUserLanguageAction({ user, language: currentLang });
  };

  useEffect(() => {
    setUserLanguageAfterLogin(user);
  }, [currentStep]);

  const steps = {
    start: <TellUsWhy moveToNextStep={moveToNextStep} isInvalidToken={isInvalidToken} />,
    coaches: <Coaches moveToNextStep={moveToNextStep} />,
    connect: <Connect moveToNextStep={moveToNextStep} />,
    book: <Coaches moveToNextStep={moveToNextStep} />
  };

  const getStepComponent = (stepIndex) => {
    const stepName = Object.keys(steps)[stepIndex] || 'start';
    return steps[stepName];
  };

  if (shouldSkipAnimation && (isHandlingPrevOnboarding || isLoadingGetPreviousOnboarding))
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.wrapper}>
          <LoadingSpinner />
        </div>
      </div>
    );

  return (
    <div className={styles['new-onboarding-wrapper']}>
      <OnboardingAnimation isInvalidToken={isInvalidToken} shouldSkipAnimation={shouldSkipAnimation} />
      <div className={styles['new-onboarding-content']}>
        <div className={styles['steps-wrapper']}>
          <Steps currentStep={currentStep} enabledSteps={enabledSteps} selectStep={handleStepChange} steps={Object.keys(steps)} />
        </div>
        <div className={styles['content-wrapper']}>{getStepComponent(currentStep)}</div>
      </div>
      {hasWeglot && (
        <div className={styles.languageSelectorWrapper}>
          <NavbarLanguageSwitcher darkMode={true} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentLang: state.appLanguage.currentLanguage,
  isLoadingGetPreviousOnboarding: state.newOnboarding.previousOnboarding.isLoadingGetPreviousOnboarding,
  previousOnboarding: state.newOnboarding.previousOnboarding.previousOnboarding,
  user: state.user
});

NewOnboarding.propTypes = {
  clearUserAction: PropTypes.func.isRequired,
  currentLang: PropTypes.string.isRequired,
  getOnboardingDataAction: PropTypes.func.isRequired,
  isLoadingGetPreviousOnboarding: PropTypes.bool.isRequired,
  previousOnboarding: PropTypes.object,
  resetSignInAction: PropTypes.func.isRequired,
  setAppLanguageAction: PropTypes.func.isRequired,
  setSelectedCourseOnOnboardingAction: PropTypes.func.isRequired,
  setUserLanguageAction: PropTypes.func.isRequired,
  storeDecodedTokenAction: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

NewOnboarding.defaultProps = {
  previousOnboarding: {}
};

export default connect(mapStateToProps, {
  clearUserAction: clearUser,
  getOnboardingDataAction: getOnboardingData,
  resetSignInAction: resetSignIn,
  storeDecodedTokenAction: storeDecodedToken,
  setAppLanguageAction: setAppLanguage,
  setSelectedCourseOnOnboardingAction: setSelectedCourseOnOnboarding,
  setUserLanguageAction: setUserLanguage
})(NewOnboarding);
