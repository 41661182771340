import React from 'react';

import styles from './AnimatedDots.module.scss';

const AnimatedDots = () => {
  return (
    <span id="dont-translate" className={styles['animated-dots-wrapper']}>
      <span className={styles['first-dot']}>.</span>
      <span className={styles['second-dot']}>.</span>
      <span className={styles['third-dot']}>.</span>
    </span>
  );
};

export default AnimatedDots;
