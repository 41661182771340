import React from 'react';
import PropTypes from 'prop-types';

import styles from './UnitsList.module.scss';
import List from './components/List';

const UnitsList = ({ selected, onSelectUnit }) => (
  <div className={styles.wrapper}>
    <List selected={selected} onSelectUnit={onSelectUnit} />
  </div>
);

UnitsList.propTypes = {
  selected: PropTypes.number.isRequired,
  onSelectUnit: PropTypes.func.isRequired
};

export default UnitsList;
