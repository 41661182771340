import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './HomeButton.module.scss';
import { UserTypes } from '../../../../domains/user/user-types';

const HomeButton = ({ handleMenuLinkClick, token, user }) => {
  const renderLogo = () => {
    if (user.organization && user.organization[0].logo) {
      return <img className={styles.orgLogo} src={user.organization[0].logo} alt="organization logo" />;
    }

    return <div className={styles.logo} data-testid="chatterbox-logo-wrapper" />;
  };

  return (
    <div className={[styles.homeBtn, token && styles['logged-user']].join(' ')}>
      <Link
        to={user.user_type === UserTypes.languageHelper ? '/chat' : user.user_type === UserTypes.student ? '/study-area' : '/dashboard'}
        onClick={() => handleMenuLinkClick('chatterbox_logo')}
        data-testid="home-button">
        {renderLogo()}
      </Link>
    </div>
  );
};

HomeButton.propTypes = {
  handleMenuLinkClick: PropTypes.func.isRequired,
  token: PropTypes.string,
  user: PropTypes.object.isRequired
};

export default HomeButton;
