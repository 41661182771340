import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import Mixpanel from '../../utils/mixpanel-helper.js';
import { hideOnboarding } from '../../actions/user';
import styles from '../../style/containers/Onboarding.module.scss';
import { getTutorInfo } from '../../actions/tutor-info';
import useInitRecording from '../../hooks/useInitRecording';

const StepStudentExtra = ({
  getTutorInfoAction,
  hideOnboardingAction,
  issue,
  jumpToStep,
  nextStep,
  selectedDialect,
  selectedLanguage,
  selectedLevel,
  storeOnboardingNotification,
  user
}) => {
  const [endRecording] = useInitRecording((endTime) => Mixpanel.recordElapsedTime('Onboarding Issue page', endTime));

  useEffect(() => {
    Mixpanel.visitedPage('Onboarding Issue');
    Mixpanel.track('Onboarding issue', { issue });
    getTutorInfoAction(user.token);
    hideOnboardingAction(user.token);

    return () => endRecording();
  }, []);

  const getCopies = () => {
    let copy = '';
    let buttonCopy = '';
    switch (issue) {
      case 'no_tutor_language':
        copy =
          `Unfortunately, it looks like no tutor is available to teach ${selectedLanguage}.` + '\n Are you interested in studying another language?';
        buttonCopy = 'Change Language';
        break;
      case 'no_tutor_dialect':
        copy =
          `Unfortunately, it looks like no tutor is available to teach ${selectedLanguage}` +
          ` ${selectedDialect}.\n Are you flexible to study another dialect?`;
        buttonCopy = 'Change Dialect';
        break;
      case 'no_product_type': {
        let languageText = selectedLanguage;
        if (selectedDialect !== undefined) {
          languageText += ` ${selectedDialect}`;
        }
        copy =
          'Unfortunately, it looks like no tutor is available to teach ' +
          `the kind of lesson suggested for ${selectedLevel} in ${languageText}.` +
          '\n Are you flexible to try another lesson type?';
        buttonCopy = 'Change Lesson Type';
        break;
      }
      default:
        break;
    }

    return { copy, buttonCopy };
  };

  return (
    <div className={styles.stepWrapper}>
      <div className={styles['onboarding__title-wrapper']}>
        <h1 className={styles.onboardingTitle}>Are you flexible?</h1>
        <h2 className={[styles.onboardingHeader, styles.onboardingText].join(' ')}>{getCopies().copy}</h2>
      </div>

      <div className={styles.buttonContainerRow}>
        <Button
          className={styles.changeDialectButton}
          onClick={() => {
            Mixpanel.track('Click', { position: 'StepIssue', button: 'try_again' });
            jumpToStep(nextStep);
          }}>
          {getCopies().buttonCopy}
        </Button>
        <div className={styles.notifyMeWrapper}>
          <Button
            className={styles.notifyMeButton}
            onClick={() => {
              Mixpanel.track('Click', { position: 'StepIssue', button: 'ignore_onboarding' });
              storeOnboardingNotification();
            }}>
            No, I am not interested.
          </Button>
          <span className={styles.noInterestedText}>Notify me when this service is available.</span>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

StepStudentExtra.propTypes = {
  user: PropTypes.object.isRequired,
  hideOnboardingAction: PropTypes.func.isRequired,
  jumpToStep: PropTypes.func.isRequired,
  storeOnboardingNotification: PropTypes.func.isRequired,
  getTutorInfoAction: PropTypes.func.isRequired,
  issue: PropTypes.string,
  nextStep: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  selectedLevel: PropTypes.string.isRequired,
  selectedDialect: PropTypes.string
};

StepStudentExtra.defaultProps = {
  issue: undefined,
  selectedDialect: undefined
};

export default connect(mapStateToProps, {
  hideOnboardingAction: hideOnboarding,
  getTutorInfoAction: getTutorInfo
})(StepStudentExtra);
