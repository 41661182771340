import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LessonResource from '../../../resources/LessonResource';
import Dropzone from '../../../design-system/dropzone';

import styles from './CurrentUnit.module.scss';
import AssesmentFaqRelatedLinks from './AssessmentFaqRelatedLinks';
import TextLoader from '../../../design-system/loading/text/TextLoader';
import AssignmentDeliveryFile from '../../../resources/AssignmentDeliveryFile';
import { uploadAssignmentFiles, deleteAssignmentDeliveryFile } from '../../../../actions/assignments';
import { addWritingDeliveryFile, removeWritingDeliveryFile, addSpeakingDeliveryFile, removeSpeakingDeliveryFile } from '../../../../actions/lessons';
import LoadingSpinner from '../../../design-system/loading/spinner/LoadingSpinner';
import Tooltip from '../../../design-system/tooltip/Tooltip';

import { ASSIGNMENT_STATES } from '../../../../common/assignmentStates';

const MAX_FILE_SIZE = 10485760;

const CurrentAssessment = ({
  alreadyJoinedCourses,
  disabled,
  isLoading,
  selectedUnit,
  status,
  student,
  addWritingDeliveryFileAction,
  removeWritingDeliveryFileAction,
  addSpeakingDeliveryFileAction,
  removeSpeakingDeliveryFileAction
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessageSpeaking, setErrorMessageSpeaking] = useState(undefined);
  const [errorMessageWritten, setErrorMessageWritten] = useState(undefined);
  const getCeregoUrl = (resource, alreadyJoinedCourses) => {
    let urlToAttach = resource.url;
    if (alreadyJoinedCourses && alreadyJoinedCourses.length > 0) {
      alreadyJoinedCourses.forEach((joinedCourse) => {
        if (joinedCourse.course.attributes && urlToAttach.includes(joinedCourse.course.attributes.slug)) {
          urlToAttach = `https://cerego.com/learn/courses/${joinedCourse.course.id}`;
          return urlToAttach;
        }
      });
    }
    return urlToAttach;
  };

  const onDrop = (...args) => {
    setErrorMessageSpeaking(undefined);
    setErrorMessageWritten(undefined);
    const file = args[0][0];
    if (!file) {
      return;
    }
    const assignmentType = args[args.length - 1];
    setIsUploading(true);

    uploadAssignmentFiles(
      student.token,
      file,
      selectedUnit.id,
      assignmentType,
      (...params) => {
        if (assignmentType === 'written_assignment') {
          addWritingDeliveryFileAction(...params);
        } else if (assignmentType === 'speaking_assignment') {
          addSpeakingDeliveryFileAction(...params);
        }

        setIsUploading(false);
      },
      (errorMessage) => {
        if (assignmentType === 'written_assignment') {
          setErrorMessageWritten(errorMessage ? errorMessage : 'There has been an error in uploading the file, please try again.');
        } else if (assignmentType === 'speaking_assignment') {
          setErrorMessageSpeaking(errorMessage ? errorMessage : 'There has been an error in uploading the file, please try again.');
        }

        setIsUploading(false);
      }
    );
  };

  if (isLoading)
    return (
      <div className={styles.unitWrapper} id="unitWrapper">
        <div className={styles.loadingWrapper}>
          <TextLoader rows={8} rowsColor="gray" />
        </div>
      </div>
    );

  if (!Object.keys(selectedUnit).length) return;

  return (
    <div className={styles.unitWrapper} data-testid={`${selectedUnit.title}-details`}>
      <div className={styles.unitContent}>
        <div className={styles.titleWrapper}>
          <h1 id="dont-translate" className={[styles.title, status === 'future' ? styles.futureContent : undefined].join(' ')}>
            {selectedUnit ? selectedUnit.title : ''}
          </h1>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles['unit-specific-content']}>
            <p className={[styles.contentDescription, status === 'future' ? styles.futureContent : undefined].join(' ')}>
              {selectedUnit?.description.split('\n').map((str, index) => (
                <React.Fragment key={index}>
                  {str}
                  <br />
                </React.Fragment>
              ))}
            </p>
            <div className={styles['test-status']}>
              <p className={styles['live-class']}>Your progress:</p>
              <span>{disabled ? 'Test is unavailable' : 'Test unlocked'}</span>
            </div>
            <AssesmentFaqRelatedLinks title={selectedUnit.title} />
            {!disabled && (
              <div className={styles.assessments}>
                <LessonResource
                  name="Reading and Listening"
                  type="assessment"
                  url={selectedUnit.typeform_url}
                  status={status}
                  userIsBeta={student.is_beta_tester}
                  isCeregoResource={false}
                  disabled={disabled}
                  lessonNumber={selectedUnit.number}
                  courseName={selectedUnit.course_name}
                />
              </div>
            )}
            {Boolean(selectedUnit?.resources?.length) && (
              <>
                <p
                  className={[styles.resultsDescription, status === 'future' ? styles.futureContent : undefined].join(' ')}
                  data-testid="assessment-results-title">
                  Based on your assessment results, we recommend you review the following assignments:
                </p>
                <div className={styles.resourcesList}>
                  {selectedUnit.resources.map((resource, index) => {
                    if (resource.user_type === student.user_type) {
                      return (
                        <LessonResource
                          key={index}
                          name={resource?.name}
                          type={resource.type}
                          url={resource.type !== 'cerego' ? resource.url : getCeregoUrl(resource, alreadyJoinedCourses)}
                          userType={resource.user_type}
                          status={status}
                          resource={resource}
                          isRevisionUnit
                          disabled={disabled}
                          lessonNumber={selectedUnit.number}
                          courseName={selectedUnit.course_name}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              </>
            )}
            {!disabled && selectedUnit.written_assignment && (
              <div className={styles.assessments}>
                <LessonResource
                  name={`Written Assignment`}
                  type="written_assignment"
                  url={selectedUnit.written_assignment}
                  status={selectedUnit.written_assignment_result.status}
                  userIsBeta={student.is_beta_tester}
                  isCeregoResource={false}
                  disabled={disabled}
                  lessonNumber={selectedUnit.number}
                  courseName={selectedUnit.course_name}
                />
                {selectedUnit.written_assignment_result.status !== ASSIGNMENT_STATES['completed'] && (
                  <div className={styles.dropzoneWrapper}>
                    <Dropzone
                      multiple={false}
                      onDrop={(...args) => onDrop(...args, 'written_assignment')}
                      onDropRejected={(rejectedFile) => {
                        if (rejectedFile[0]['file'].size > MAX_FILE_SIZE) {
                          setErrorMessageWritten(`The selected file exceeds the maximum allowed size of ${Math.floor(MAX_FILE_SIZE / 10 ** 6)} MB`);
                        } else {
                          setErrorMessageWritten('There has been an error in uploading the file, please try again.');
                        }
                      }}
                      maxSize={MAX_FILE_SIZE}
                      accept={{
                        'image/jpeg': ['.jpg', '.jpeg'],
                        'image/png': ['.png'],
                        'application/msword': ['.doc'],
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                        'application/pdf': ['.pdf']
                      }}
                      disabled={isUploading}>
                      {!isUploading && (
                        <>
                          Click here to upload your file for submission.
                          <div className={styles.tooltipWrapper}>
                            <Tooltip tooltipLabel="Accepted formats: pdf, doc, docx, png, jpg," backgroundColor="white" />
                          </div>
                        </>
                      )}
                      {isUploading && (
                        <div className={styles.spinnerWrapper}>
                          <LoadingSpinner />
                        </div>
                      )}
                    </Dropzone>
                  </div>
                )}
                {errorMessageWritten && <div className={styles.dropzoneError}>{errorMessageWritten}</div>}
                {selectedUnit.written_assignment_result.delivery_files && selectedUnit.written_assignment_result.delivery_files.length > 0 && (
                  <>
                    <div className={styles.deliveryFilesHeader}>
                      <strong>Uploaded Files:</strong>
                    </div>
                    {selectedUnit.written_assignment_result.delivery_files.map((file) => (
                      <AssignmentDeliveryFile
                        name={file?.name}
                        key={file.id}
                        url={file.url}
                        allowDelete={selectedUnit.written_assignment_result.status !== ASSIGNMENT_STATES['completed']}
                        deleteAssignmentDeliveryFile={(callbackSuccess, callbackError) => {
                          deleteAssignmentDeliveryFile(
                            student.token,
                            file.id,
                            () => {
                              removeWritingDeliveryFileAction(file.id);
                              if (callbackSuccess) {
                                callbackSuccess();
                              }
                            },
                            () => {
                              if (callbackError) {
                                callbackError();
                              }
                            }
                          );
                        }}
                      />
                    ))}
                  </>
                )}
              </div>
            )}
            {Boolean(selectedUnit?.written_assignment_result?.feedback) && (
              <>
                <p
                  className={[styles.resultsDescription, status === 'future' ? styles.futureContent : undefined].join(' ')}
                  data-testid="assessment-results-title">
                  Your submission has been reviewed:
                </p>
                <LessonResource
                  name={
                    selectedUnit?.written_assignment_result?.score > 0
                      ? `Score: ${selectedUnit?.written_assignment_result?.score}`
                      : 'Personalised Feedback'
                  }
                  type={'feedback'}
                  url={selectedUnit?.written_assignment_result?.feedback}
                  userType={'student'}
                  status={status}
                  showFeedbackButtonText={selectedUnit?.written_assignment_result?.score > 0}
                />
              </>
            )}
            {Boolean(selectedUnit?.written_assignment_result?.resources_to_review?.length) && (
              <>
                <p
                  className={[styles.resultsDescription, status === 'future' ? styles.futureContent : undefined].join(' ')}
                  data-testid="assessment-results-title">
                  Based on your written assignment results, we recommend you review the following assignments:
                </p>
                <div className={styles.resourcesList}>
                  {selectedUnit.written_assignment_result.resources_to_review.map((resource, index) => {
                    if (resource.user_type === student.user_type) {
                      return (
                        <LessonResource
                          key={index}
                          name={resource?.name}
                          type={resource.type}
                          url={resource.type !== 'cerego' ? resource.url : getCeregoUrl(resource, alreadyJoinedCourses)}
                          userType={resource.user_type}
                          status={status}
                          resource={resource}
                          isRevisionUnit
                          disabled={disabled}
                          lessonNumber={selectedUnit.number}
                          courseName={selectedUnit.course_name}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              </>
            )}
            {!disabled && selectedUnit.speaking_assignment && (
              <div className={styles.assessments}>
                <LessonResource
                  name={`Speaking Assignment`}
                  type="speaking_assignment"
                  url={selectedUnit.speaking_assignment}
                  status={selectedUnit.speaking_assignment_result.status}
                  userIsBeta={student.is_beta_tester}
                  isCeregoResource={false}
                  disabled={disabled}
                  lessonNumber={selectedUnit.number}
                  courseName={selectedUnit.course_name}
                />
                {selectedUnit.speaking_assignment_result.status !== ASSIGNMENT_STATES['completed'] && (
                  <div className={styles.dropzoneWrapper}>
                    <Dropzone
                      multiple={false}
                      onDrop={(...args) => onDrop(...args, 'speaking_assignment')}
                      onDropRejected={(rejectedFile) => {
                        if (rejectedFile[0]['file'].size > MAX_FILE_SIZE) {
                          setErrorMessageSpeaking(`The selected file exceeds the maximum allowed size of ${Math.floor(MAX_FILE_SIZE / 10 ** 6)} MB`);
                        } else {
                          setErrorMessageSpeaking('There has been an error in uploading the file, please try again.');
                        }
                      }}
                      accept={{
                        'audio/wav': ['.wav'],
                        'audio/mpeg': ['.mp3'],
                        'audio/x-m4a': ['.m4a'],
                        'video/mp4': ['.mp4']
                      }}
                      maxSize={MAX_FILE_SIZE}
                      disabled={isUploading}>
                      {!isUploading && (
                        <>
                          Click here to upload your file for submission.
                          <div className={styles.tooltipWrapper}>
                            <Tooltip tooltipLabel="Accepted formats: mp3, wav, m4a, mp4" backgroundColor="white" />
                          </div>
                        </>
                      )}
                      {isUploading && (
                        <div className={styles.spinnerWrapper}>
                          <LoadingSpinner />
                        </div>
                      )}
                    </Dropzone>
                  </div>
                )}

                {errorMessageSpeaking && <div className={styles.dropzoneError}>{errorMessageSpeaking}</div>}
                {selectedUnit.speaking_assignment_result.delivery_files && (
                  <>
                    <div className={styles.deliveryFilesHeader}>
                      <strong>Uploaded Files:</strong>
                    </div>
                    {selectedUnit.speaking_assignment_result.delivery_files.map((file) => (
                      <AssignmentDeliveryFile
                        name={file?.name}
                        key={file.id}
                        url={file.url}
                        allowDelete={selectedUnit.written_assignment_result.status !== ASSIGNMENT_STATES['completed']}
                        deleteAssignmentDeliveryFile={(callbackSuccess, callbackError) => {
                          deleteAssignmentDeliveryFile(
                            student.token,
                            file.id,
                            () => {
                              removeSpeakingDeliveryFileAction(file.id);
                              if (callbackSuccess) {
                                callbackSuccess();
                              }
                            },
                            () => {
                              if (callbackError) {
                                callbackError();
                              }
                            }
                          );
                        }}
                      />
                    ))}
                  </>
                )}
              </div>
            )}
            {Boolean(selectedUnit?.speaking_assignment_result?.feedback) && (
              <>
                <p
                  className={[styles.resultsDescription, status === 'future' ? styles.futureContent : undefined].join(' ')}
                  data-testid="assessment-results-title">
                  Your submission has been reviewed:
                </p>
                <LessonResource
                  name={
                    selectedUnit?.speaking_assignment_result?.score > 0
                      ? `Score: ${selectedUnit?.speaking_assignment_result?.score}`
                      : 'Personalised Feedback'
                  }
                  type={'feedback'}
                  url={selectedUnit?.speaking_assignment_result?.feedback}
                  userType={'student'}
                  status={status}
                />
              </>
            )}
            {Boolean(selectedUnit?.speaking_assignment_result?.resources_to_review?.length) && (
              <>
                <p
                  className={[styles.resultsDescription, status === 'future' ? styles.futureContent : undefined].join(' ')}
                  data-testid="assessment-results-title">
                  Based on your speaking assignment results, we recommend you review the following assignments:
                </p>
                <div className={styles.resourcesList}>
                  {selectedUnit.speaking_assignment_result.resources_to_review.map((resource, index) => {
                    if (resource.user_type === student.user_type) {
                      return (
                        <LessonResource
                          key={index}
                          name={resource?.name}
                          type={resource.type}
                          url={resource.type !== 'cerego' ? resource.url : getCeregoUrl(resource, alreadyJoinedCourses)}
                          userType={resource.user_type}
                          status={status}
                          resource={resource}
                          isRevisionUnit
                          disabled={disabled}
                          lessonNumber={selectedUnit.number}
                          courseName={selectedUnit.course_name}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    student: state.user
  };
}

CurrentAssessment.propTypes = {
  alreadyJoinedCourses: PropTypes.array,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  selectedUnit: PropTypes.object,
  status: PropTypes.string,
  student: PropTypes.object.isRequired,
  addWritingDeliveryFileAction: PropTypes.func,
  removeWritingDeliveryFileAction: PropTypes.func,
  addSpeakingDeliveryFileAction: PropTypes.func,
  removeSpeakingDeliveryFileAction: PropTypes.func
};

CurrentAssessment.defaultProps = {
  alreadyJoinedCourses: [],
  disabled: false,
  isLoading: false,
  selectedUnit: undefined
};

export default connect(mapStateToProps, {
  addWritingDeliveryFileAction: addWritingDeliveryFile,
  removeWritingDeliveryFileAction: removeWritingDeliveryFile,
  addSpeakingDeliveryFileAction: addSpeakingDeliveryFile,
  removeSpeakingDeliveryFileAction: removeSpeakingDeliveryFile
})(CurrentAssessment);
