import React from 'react';
import PropTypes from 'prop-types';

import styles from './CheckboxField.module.scss';

const CheckboxField = ({ checked, icon, id, name, label, onChange, tooltipLabel }) => {
  const handleUserSelect = (evt) => {
    const { checked } = evt.target;
    onChange(checked);
  };

  return (
    <div className={[styles['field-wrapper']].join(' ')}>
      <input id={id} type="checkbox" onChange={handleUserSelect} checked={checked} name={name} />
      <label htmlFor={id} onChange={handleUserSelect}>
        {label}
      </label>
      {tooltipLabel && (
        <div className={styles.tooltip}>
          <div className={[styles.icon]}>{icon}</div>
          <div className={[styles.tooltip__label]}>{tooltipLabel}</div>
        </div>
      )}
    </div>
  );
};

CheckboxField.propTypes = {
  icon: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool.isRequired,
  tooltipLabel: PropTypes.string
};

CheckboxField.defaultProps = {
  icon: '',
  id: '',
  onChange: () => {}
};

export default CheckboxField;
