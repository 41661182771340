import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './CourseSelector.module.scss';
import CourseTab from './components/CourseTab';
import Dropdown from '../../design-system/dropdown/Dropdown';
import { connect } from 'react-redux';
import { getCourseDetails } from '../../../actions/courses';
import SingleTextLoader from '../../design-system/loading/text/SingleTextLoader';

const CourseSelector = ({ courses, getCourseDetailsAction, isGettingEnrolledCourses, isStudentEnrolledInACourse, user }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleCourseTabSelection = (index) => {
    if (!courses || !courses.length || !courses[index]) return;
    setSelectedTab(index);
    getCourseDetailsAction({ token: user.token, courseId: courses[index].id });
  };

  const adaptCoursesToDropdown = (c) => {
    if (!c?.length) return [];
    return c.map((course) => ({
      label: course?.name,
      value: course?.name
    }));
  };

  if (isGettingEnrolledCourses)
    return (
      <div className={styles.tabContainer} data-testid="loading-course-selector">
        <div className={styles.loadingWrapper}>
          <SingleTextLoader rows={1} rowColor="gray" />
        </div>
      </div>
    );

  if (!isGettingEnrolledCourses && !isStudentEnrolledInACourse) return;

  return (
    <div className={styles.tabContainer} data-testid="course-selector">
      <div className={styles['web-wrapper']}>
        {courses.map((c, index) => (
          <div key={c?.name}>
            <div className={styles['web-wrapper']} key={`${c?.name}-web`}>
              <CourseTab name={c?.name} selected={index === selectedTab} index={index} onSelectTab={() => handleCourseTabSelection(index)} />
            </div>
          </div>
        ))}
      </div>
      <div className={styles['mobile-wrapper']}>
        <Dropdown
          defaultValue={courses[selectedTab]?.name}
          onSelect={(course, index) => handleCourseTabSelection(index)}
          options={adaptCoursesToDropdown(courses)}
          showBorderBottom
        />
      </div>
    </div>
  );
};

CourseSelector.propTypes = {
  courses: PropTypes.array.isRequired,
  getCourseDetailsAction: PropTypes.func.isRequired,
  isGettingEnrolledCourses: PropTypes.bool.isRequired,
  isStudentEnrolledInACourse: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  courses: state.courses.enrolledCourses.enrolledCourses,
  isGettingEnrolledCourses: state.courses.enrolledCourses.isGettingEnrolledCourses,
  isStudentEnrolledInACourse: state.student.isStudentEnrolledInACourse,
  user: state.user
});

export default connect(mapStateToProps, {
  getCourseDetailsAction: getCourseDetails
})(CourseSelector);
