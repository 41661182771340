import { Client as ConversationClient } from '@twilio/conversations';
import { AccessManager } from 'twilio-common';
import ChatAPI from '.';
import mixpanelHelper from '../../utils/mixpanel-helper';
import { sendSentryError } from '../../setup/sentry';

const JOIN_CHATS_ERRORS = ['Member already exists', 'Not Found'];

export class TwilioConversation {
  instance;
  _accessManager;
  _conversationClient;
  _currentUser;
  _states = Object.freeze({
    initialized: 'initialized',
    denied: 'denied'
  });

  constructor(user) {
    this._currentUser = user;
  }

  static getInstance(user) {
    if (this.instance) return this.instance;

    this.instance = new TwilioConversation(user);
    return this.instance;
  }

  static destroyInstance = () => {
    this.instance = null;
  };

  async initiate() {
    await this.createAccessManager();
  }

  async getClient() {
    try {
      if (!this._accessManager) {
        await this.createAccessManager();
      }
      if (!this._conversationClient) {
        return await this.createClient();
      }
      return this._conversationClient;
    } catch (err) {
      sendSentryError({ err, context: 'TwilioConversation:createAccessManager' });
    }
  }

  async createAccessManager() {
    try {
      if (!this._currentUser?.token || !this._currentUser?.id) throw new Error('Missing token or user id');
      const { data: chatToken } = await ChatAPI.getChatToken({ token: this._currentUser.token, userId: this._currentUser.id });
      if (!chatToken) throw Error('createAccessManager: Chat token is empty');
      this.instantiateAccessManager(chatToken);
    } catch (err) {
      sendSentryError({ err, context: 'TwilioConversation:createAccessManager' });
    }
  }

  async getNewToken(userToken, userId) {
    try {
      const token = await ChatAPI.getChatToken(userToken, userId);
      if (!token) throw Error('getNewToken: Chat token is empty');
      this._accessManager = new AccessManager(token);
    } catch (err) {
      sendSentryError({ err, context: 'TwilioConversation:getNewToken' });
    }
  }

  instantiateAccessManager(token) {
    try {
      this._accessManager = new AccessManager(token);
      this._accessManager.on('tokenExpired', () => {
        // generate new token here and set it to the _accessManager
        this.getNewToken.call(this, this._currentUser.token, this._currentUser.id);
      });

      this._accessManager.on('tokenWillExpire', () => {
        // generate new token here and set it to the _accessManager
        this.getNewToken.call(this, this._currentUser.token, this._currentUser.id);
      });

      this._accessManager.on('tokenUpdated', async (am) => {
        // get new token from AccessManager and pass it to the library instance
        if (this._conversationClient && am?.token) {
          await this._conversationClient.updateToken.call(this, am.token);
        }

        if (!am?.token) sendSentryError({ err: new Error('Token is not being updated'), context: 'AccessManager:tokenUpdated' });

        if (!this._conversationClient) {
          await this.createClient.call(this);
        }
      });
    } catch (err) {
      sendSentryError({ err, context: 'TwilioConversation:instantiateAccessManager' });
      throw new Error('chatAccessManagerIssue');
    }
  }

  async createClient() {
    if (this._conversationClient) return this._conversationClient;

    try {
      if (!this._accessManager?.token) throw new Error('Missing token');
      const client = new ConversationClient(this._accessManager.token);
      if (!client) throw new Error('Something went wrong while creating Twilio Conversation client');
      return new Promise((resolve, reject) => {
        client.on('stateChanged', (state) => {
          if (state === this._states.initialized) {
            this._conversationClient = client;
            resolve(this._conversationClient);
          }

          if (state === this._states.denied) {
            reject('Something went wrong while creating Twilio Conversation client');
          }
        });
      });
    } catch (err) {
      sendSentryError({ err, context: 'TwilioConversation:createClient' });
      this._conversationClient = undefined;
      throw err;
    }
  }

  chatLogout() {
    this._accessManager = undefined;
    this._conversationClient = undefined;
  }

  async joinChats(chatsToJoin, user) {
    try {
      if (!chatsToJoin) return;
      const chatsToRemove = [];
      // eslint-disable-next-line no-unused-vars
      for (const chatId of chatsToJoin) {
        try {
          /* eslint-disable no-await-in-loop */
          const channel = await this._conversationClient.getChannelByUniqueName(chatId.toString());
          /* eslint-disable no-await-in-loop */
          await channel.join();
          chatsToRemove.push(chatId);
        } catch (err) {
          if (JOIN_CHATS_ERRORS.includes(err.message)) {
            chatsToRemove.push(chatId);
          }
        }
      }
      const data = { chatsToRemove };
      await ChatAPI.removeChats({ token: user.token, data });
    } catch (err) {
      sendSentryError({ err, context: 'TwilioConversation:joinChats' });
      mixpanelHelper.track('Error in joining chats', { error: err.message });
    }
  }
}
