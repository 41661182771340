export const supportChatName = 'Support Helper';

export const supportMessageTypes = Object.freeze({
  text: 'text',
  selection: 'selection',
  typing: 'typing'
});

export const supportMessageOwners = Object.freeze({
  chatterbox: 'Chatterbox',
  user: 'User'
});

export const actions = Object.freeze({
  newRequest: 'New Request',
  previousRequests: 'Previous Requests'
});
