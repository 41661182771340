import { CREATE_FIELD, CREATE_FORM, CHANGE_FIELD, RESET_FORM } from '../actions/types';

export default function formsReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_FORM: {
      const isFormAlreadyCreated = Boolean(state[action.payload.name]);
      if (isFormAlreadyCreated) return state;
      return { ...state, [action.payload.name]: {} };
    }
    case CREATE_FIELD: {
      const isFieldAlreadyCreated =
        state[action.payload.formName] && Object.prototype.hasOwnProperty.call(state[action.payload.formName], action.payload.fieldName);
      if (isFieldAlreadyCreated) return state;
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          [action.payload.fieldName]: ''
        }
      };
    }
    case CHANGE_FIELD: {
      const form = {
        ...state[action.payload.name],
        [action.payload.formName]: {
          ...state[action.payload.formName],
          [action.payload.fieldName]: action.payload.value
        }
      };
      return { ...state, ...form };
    }
    case RESET_FORM:
      return { ...state, [action.payload.name]: { ...action.payload.fields } };

    default:
      return state;
  }
}
