import { NEXT_PAGE, PREVIOUS_PAGE, SET_PAGE, RESET_PAGE } from '../actions/types';

export default function AnnouncementReducer(
  state = {
    currentPage: 0
  },
  action
) {
  let newState;

  switch (action.type) {
    case NEXT_PAGE:
      newState = {
        ...state,
        currentPage: state.currentPage + 1
      };
      return newState;
    case PREVIOUS_PAGE:
      newState = {
        ...state,
        currentPage: state.currentPage - 1
      };
      return newState;
    case RESET_PAGE:
      newState = {
        ...state,
        currentPage: 0
      };
      return newState;
    case SET_PAGE:
      newState = {
        ...state,
        currentPage: action.payload
      };
      return newState;
    default:
      return state;
  }
}
