import axios from 'axios';
import { userLogout } from './user';
import { getErrorStatus } from '../utils/error-helpers';

function handleExpiredToken(dispatch, token = '') {
  userLogout(token)(dispatch);
}

export const isTokenExpired = (dispatch, err, token = '') => {
  const errStatus = getErrorStatus(err?.response);
  if (errStatus === 401) {
    handleExpiredToken(dispatch, token);
    return true;
  }
  return false;
};

export function request(verb, url, token, options = {}) {
  const { actionFail, actionSuccess, data, fail, params, success } = options;

  return async (dispatch) => {
    const headers = { Authorization: `Token ${token}` };

    try {
      let response;

      if (verb === 'post') {
        response = await axios[verb](`/api/${url}/`, data, { headers, params });
      } else {
        response = await axios[verb](`/api/${url}/`, { headers, params });
      }

      if (typeof actionSuccess === 'function') {
        dispatch(actionSuccess(response));
      }

      if (typeof success === 'function') {
        success(response);
      }
    } catch (error) {
      if (typeof actionFail === 'function') {
        dispatch(actionFail(error));
      }

      if (typeof fail === 'function') {
        fail(error);
      }
    }
  };
}
