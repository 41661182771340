import { GET_CLASSROOM_INFO, USER_LOGOUT } from '../actions/types';

export default function classroomReducer(state = null, action) {
  switch (action.type) {
    case GET_CLASSROOM_INFO:
      return action.payload.data;
    case USER_LOGOUT:
      return null;
    default:
      return state;
  }
}
