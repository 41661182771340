import React from 'react';
import PropTypes from 'prop-types';

import styles from './AssessmentScore.module.scss';

const AssessmentScore = ({ isBlocked, isSelected, score }) => {
  return (
    <div className={isSelected ? styles['bigger-wrapper'] : styles['regular-wrapper']} data-testid="assessment-score">
      {isBlocked ? (
        <div className={styles['blocked-lesson']} data-testid="assessment-score-blocked" />
      ) : (
        <div className={styles['score-wrapper']}>{<p>{score || ''}</p>}</div>
      )}
    </div>
  );
};

AssessmentScore.propTypes = {
  isBlocked: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  score: PropTypes.number
};

export default AssessmentScore;
