import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../design-system/button/Button';
import CardLoader from '../../design-system/loading/card/CardLoader';

import { setOpenBookingConversationModal } from '../../../actions/new-booking';
import { setProductType } from '../../../actions/selected-product-type';
import { setSelectedLanguage } from '../../../actions/selected-language';

import { selectProductType } from '../../../reducers/order-info';

import styles from './GeneralCard.module.scss';
import { ReactComponent as IconCup } from '../../../assets/icons/icon_cup.svg';
import { CLASS_TYPES } from '../Enum';
import { UserLicenceTypes } from '../../../domains/user/user-licence-types';

const ConvoCard = ({
  allowConversationClasses,
  isGettingCourseDetails,
  isLoadingLessonsStatus,
  language,
  licenceType,
  conversationsLeft,
  matchedTutor,
  productType,
  selectedTutor,
  setOpenBookingConversationModalAction,
  setProductTypeAction,
  setSelectedLanguageAction
}) => {
  const history = useHistory();

  const handleBookConversation = () => {
    if (selectedTutor.id || matchedTutor.id) {
      setOpenBookingConversationModalAction();
    } else {
      setProductTypeAction(productType);
      setSelectedLanguageAction(language);
      history.push('/find-tutor');
    }
  };

  const getSessionsRemaining = () => {
    if (licenceType === UserLicenceTypes.subscription) return;

    if (conversationsLeft > 1)
      return (
        <p className={styles['missing-lessons--wrapper']}>
          <span id="dont-translate">{conversationsLeft}</span> Convo Practices remaining
        </p>
      );
    return (
      <p className={styles['missing-lessons--wrapper']}>
        <span id="dont-translate">{conversationsLeft}</span> Convo Practice remaining
      </p>
    );
  };

  if (!allowConversationClasses) return;

  return (
    <div className={styles.cardWrapper} data-testid="convo-card">
      {isLoadingLessonsStatus || isGettingCourseDetails ? (
        <div className={styles.loaderContainer}>
          <CardLoader rows={4} rowsColor="gray" />
        </div>
      ) : (
        <div className={styles.contentContainer}>
          <div className={styles['icon--wrapper']} data-testid="conversation-icon">
            <IconCup />
          </div>
          <div className={styles['card-data--wrapper']}>
            <Button onClick={handleBookConversation} outfit="salmonButton" disabled={!allowConversationClasses}>
              <div className={styles['button--wrapper']}>
                <h2>
                  Book your next <br />
                  Convo Practice
                </h2>
              </div>
            </Button>
            {getSessionsRemaining()}
          </div>
        </div>
      )}
    </div>
  );
};

ConvoCard.propTypes = {
  allowConversationClasses: PropTypes.bool.isRequired,
  isGettingCourseDetails: PropTypes.bool.isRequired,
  isLoadingLessonsStatus: PropTypes.bool.isRequired,
  language: PropTypes.object,
  licenceType: PropTypes.string,
  conversationsLeft: PropTypes.number,
  matchedTutor: PropTypes.object.isRequired,
  productType: PropTypes.object.isRequired,
  selectedTutor: PropTypes.object.isRequired,
  setOpenBookingConversationModalAction: PropTypes.func.isRequired,
  setProductTypeAction: PropTypes.func.isRequired,
  setSelectedLanguageAction: PropTypes.func.isRequired
};

ConvoCard.defaultProps = {
  isGettingCourseDetails: false
};

const mapStateToProps = (state) => ({
  allowConversationClasses: state.sessions.conversationSessions.allowConversationClasses,
  conversationsLeft: state.sessions.conversationSessions.conversationsLeft,
  isGettingCourseDetails: state.courses.selectedCourseDetails.isGettingCourseDetails,
  isLoadingLessonsStatus: state.sessions.tuitionSessions.isLoadingLessonsStatus,
  language: state.courses?.selectedCourseDetails?.courseDetails?.language,
  licenceType: state.user.licence_type,
  matchedTutor: state.selectedTutor.matchedTutor,
  productType: selectProductType(state.orderInfo, CLASS_TYPES.conversation),
  selectedTutor: state.selectedTutor.selectedTutor
});

export default connect(mapStateToProps, {
  setOpenBookingConversationModalAction: setOpenBookingConversationModal,
  setProductTypeAction: setProductType,
  setSelectedLanguageAction: setSelectedLanguage
})(ConvoCard);
