import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compareAsc } from 'date-fns';

import styles from './LessonAppointmentCards.module.scss';

import { CLASS_TYPES } from '../../../Enum';

import { ReactComponent as IconCup } from '../../../../../assets/icons/icon_cup.svg';
import { convertStringsOfDateAndTimeToDate } from '../../../../../utils/time-helpers';
import ConvoLessonAppointmentCard from '../ConvoLessonAppointmentCard';
import TuitionLessonAppointmentCard from '../TuitionLessonAppointmentCard';

const LessonAppointmentCards = ({ allowConversationClasses, conversationBookings, isLoading, selectedTutor, tuitionBookings }) => {
  const [orderedAppointmentsByDate, setOrderedAppointmentsByDate] = useState([]);

  const orderCardsByDate = (convoList, tuitionList) => {
    if (!tuitionList.length && !convoList.length) {
      setOrderedAppointmentsByDate([]);
      return;
    }
    const orderedList = [...convoList, ...tuitionList].sort(({ appointment_object: firstEl }, { appointment_object: secondEl }) => {
      const firstDateOcurr = convertStringsOfDateAndTimeToDate(firstEl?.date, firstEl?.start_time);
      const secondDateOcurr = convertStringsOfDateAndTimeToDate(secondEl?.date, secondEl?.start_time);
      return compareAsc(firstDateOcurr, secondDateOcurr);
    });
    setOrderedAppointmentsByDate(orderedList);
  };

  useEffect(() => {
    orderCardsByDate(conversationBookings, tuitionBookings);
  }, [conversationBookings, tuitionBookings]);

  if (isLoading)
    return (
      <div className={styles['cards-container']}>
        {[...new Array(7)].map((arr, index) => (
          <TuitionLessonAppointmentCard isLoading={isLoading} key={index} />
        ))}
      </div>
    );

  if (!orderedAppointmentsByDate.length)
    return (
      <div className={styles['empty-state']}>
        <div className={styles['lp-empty-state']}>
          <div className={styles['header--wrapper']}>
            <div className={styles['profile-photo--wrapper']}>
              <img src={selectedTutor?.profile_picture} alt="" />
            </div>
            <h2>No upcoming Live Practices yet</h2>
          </div>
          <div className={styles['text--wrapper']}>
            <h3>Live Practices are...</h3>
            <p>
              ...online meetings where you&apos;ll practise your <strong>speaking and pronunciation</strong> skills with a native Language Coach.
            </p>
          </div>
        </div>
        {allowConversationClasses && (
          <div className={styles['lp-empty-state']}>
            <div className={styles['header--wrapper']}>
              <div className={styles['profile-photo--wrapper']}>
                <IconCup />
              </div>
              <h2>No upcoming Convo Practices yet</h2>
            </div>
            <div className={styles['text--wrapper']}>
              <h3>Convo Practices are...</h3>
              <p>
                ...online meetings where you&apos;ll practise your <strong>speaking and pronunciation</strong> skills with a native Language Coach.
              </p>
            </div>
          </div>
        )}
      </div>
    );
  return (
    <div className={styles['cards-container']}>
      {orderedAppointmentsByDate.map((lesson) => {
        if (lesson?.type === CLASS_TYPES.conversation)
          return <ConvoLessonAppointmentCard lesson={lesson} isLoading={isLoading} key={lesson?.appointment_object?.id} />;

        return <TuitionLessonAppointmentCard lesson={lesson} isLoading={isLoading} key={lesson?.appointment_object?.id} />;
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  allowConversationClasses: state.sessions.conversationSessions.allowConversationClasses,
  selectedTutor: state.selectedTutor.selectedTutor
});

LessonAppointmentCards.propTypes = {
  allowConversationClasses: PropTypes.bool.isRequired,
  conversationBookings: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedTutor: PropTypes.object,
  tuitionBookings: PropTypes.array.isRequired
};

LessonAppointmentCards.defaultProps = {
  isLoading: false
};

export default connect(mapStateToProps)(LessonAppointmentCards);
