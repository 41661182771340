import axios from 'axios';
import LessonsAPI from '../services/lessons';
import { actionCreatorsFactory, asyncActionType, createActionTypes } from '../utils/action-helpers.js';
import { isTokenExpired } from './common.js';

import { GET_LESSON, GET_LESSON_ERROR, LOADING_LESSONS_STATUS, GET_COURSE_ASSIGNMENT_PROGRESS } from './types';
import { sendSentryError } from '../setup/sentry';

export const LessonsActionTypes = createActionTypes('Lessons', [
  asyncActionType('GET_COACH_COURSES_LESSONS'),
  asyncActionType('SET_NEXT_LESSON_TO_STUDY'),
  asyncActionType('SET_SELECTED_UNIT'),
  asyncActionType('GET_SELECTED_UNIT_PROGRESS'),
  asyncActionType('GET_UNITS_LIVE_PROGRESS'),
  asyncActionType('GET_LESSONS_ASSIGNMENTS_LIVE_PROGRESS'),
  asyncActionType('ADD_WRITING_DELIVERY_FILE_SUCCESS'),
  asyncActionType('REMOVE_WRITING_DELIVERY_FILE_SUCCESS'),
  asyncActionType('ADD_SPEAKING_DELIVERY_FILE_SUCCESS'),
  asyncActionType('REMOVE_SPEAKING_DELIVERY_FILE_SUCCESS')
]);

function getLessonSuccess(response) {
  response.data = JSON.parse(JSON.stringify(response.data));
  if (response.data) {
    const resources = response.data.resources.sort((a, b) =>
      a?.name?.localeCompare(b?.name, undefined, {
        numeric: true,
        sensitivity: 'base'
      })
    );

    response.data.resources = resources;
  }
  return {
    type: GET_LESSON,
    payload: response
  };
}

function getLessonError() {
  return {
    type: GET_LESSON_ERROR,
    payload: {}
  };
}

export function resetLesson() {
  return {
    type: GET_LESSON,
    payload: { data: [] }
  };
}

export function getLesson(token, course, lessonNumber, errorCallback) {
  return async (dispatch) => {
    try {
      const headers = { Authorization: `Token ${token}` };

      const response = await axios.get(`/api/courses/${course}/lessons/${lessonNumber}/`, {
        headers
      });

      dispatch(getLessonSuccess(response));
    } catch (err) {
      dispatch(getLessonError());

      if (typeof errorCallback === 'function') {
        errorCallback(err);
      }
    }
  };
}

export const getCoachCoursesLessonsActions = actionCreatorsFactory(
  [
    LessonsActionTypes.GET_COACH_COURSES_LESSONS,
    LessonsActionTypes.GET_COACH_COURSES_LESSONS_SUCCESS,
    LessonsActionTypes.GET_COACH_COURSES_LESSONS_FAIL
  ],
  'getCoachCoursesLessons'
);

const splitByCourseMap = (lessons) => {
  const map = new Map();
  lessons.forEach((lesson) => {
    const prev = map.get(lesson.course_name) || [];
    map.set(lesson.course_name, [...prev, lesson]);
  });

  return Object.fromEntries(map);
};

const sortLessons = (data) => {
  if (!data.length) return [];
  const splitted = splitByCourseMap(data);
  const keysSortedByCourse = Object.keys(splitted).sort();
  const fullySorted = keysSortedByCourse.map((key) => {
    return splitted[key].sort((a, b) => {
      if (a?.number > b?.number) return 1;
      return -1;
    });
  });
  return fullySorted.flat(1);
};

export function getCoachCoursesLessons(token, errorCallback) {
  return async (dispatch) => {
    try {
      dispatch(getCoachCoursesLessonsActions.getCoachCoursesLessons());
      const { data } = await LessonsAPI.getLessons(token);
      const sorted = sortLessons(data);
      dispatch(getCoachCoursesLessonsActions.getCoachCoursesLessonsSuccess(sorted));
    } catch (err) {
      dispatch(getCoachCoursesLessonsActions.getCoachCoursesLessonsFail());
      if (typeof errorCallback === 'function') errorCallback(err);
    }
  };
}

function getCourseAssignmentProgressSuccess(progress) {
  return {
    type: GET_COURSE_ASSIGNMENT_PROGRESS,
    payload: progress
  };
}

export function getResourcesCeregoProgress(student, unit, callback) {
  return (dispatch) => {
    if (unit === undefined) {
      callback();
    } else {
      const headers = { Authorization: `Token ${student.token}` };
      unit.resources.forEach((resource, index) => {
        axios
          .get(`/api/progress/resource/${resource.id}`, { headers })
          .then(({ data }) => {
            if (!Number.isNaN(data.progress) && data.progress !== undefined && data.progress !== null) {
              const assignmentProgressObject = {
                id: resource.id,
                progress: data?.progress ? Number((data.progress * 1).toFixed(3)) : 0,
                see_next_at: data.see_next_at
              };
              dispatch(getCourseAssignmentProgressSuccess(assignmentProgressObject));
            }

            if (index === unit.resources.length - 1) {
              callback();
            }
          })
          .catch((err) => {
            sendSentryError({ err, context: 'getResourcesCeregoProgress' });
            if (!isTokenExpired(dispatch, err)) {
              callback();
              // if (!ACCEPTABLE_ERRORS.includes(err)) {
              //   dispatch(getCoursesError());
              // }
            }
          });
      });
    }
  };
}

export const setNextLessonToStudyActions = actionCreatorsFactory(
  [
    LessonsActionTypes.SET_NEXT_LESSON_TO_STUDY,
    LessonsActionTypes.SET_NEXT_LESSON_TO_STUDY_SUCCESS,
    LessonsActionTypes.SET_NEXT_LESSON_TO_STUDY_FAIL
  ],
  'setNextLessonToStudy'
);

export const setNextLessonToStudy = ({ token, unit }) => {
  return async (dispatch) => {
    try {
      dispatch(setNextLessonToStudyActions.setNextLessonToStudy());
      if (!unit || !Object.keys(unit).length) throw new Error('Unit is empty');
      const unitId = unit.id;
      const { data } = await LessonsAPI.getUnitProgress({ token, unitId });
      dispatch(
        setNextLessonToStudyActions.setNextLessonToStudySuccess({
          ...unit,
          lastStudyTime: data['last-study-time'] || null,
          progress: data.progress ? Number((data.progress * 1).toFixed(3)) : 0
        })
      );
    } catch (err) {
      sendSentryError({ err, context: 'setNextLessonToStudy' });
      switch (err.message) {
        case 'Unit is empty': {
          dispatch(
            setNextLessonToStudyActions.setNextLessonToStudyFail({
              lessonContent: {},
              errorMessage: err.message
            })
          );
          break;
        }
        default: {
          const errorMsg = err.message || 'Something went wrong while setting your next lesson';
          dispatch(
            setNextLessonToStudyActions.setNextLessonToStudyFail({
              lessonContent: {
                ...unit,
                lastStudyTime: null,
                progress: 0
              },
              errorMessage: errorMsg
            })
          );
        }
      }
    }
  };
};

export const setSelectedUnitActions = actionCreatorsFactory(
  [LessonsActionTypes.SET_SELECTED_UNIT, LessonsActionTypes.SET_SELECTED_UNIT_SUCCESS, LessonsActionTypes.SET_SELECTED_UNIT_FAIL],
  'setSelectedUnit'
);

export const getSelectedUnitProgressActions = actionCreatorsFactory(
  [
    LessonsActionTypes.GET_SELECTED_UNIT_PROGRESS,
    LessonsActionTypes.GET_SELECTED_UNIT_PROGRESS_SUCCESS,
    LessonsActionTypes.GET_SELECTED_UNIT_PROGRESS_FAIL
  ],
  'getSelectedUnitProgress'
);

export const addWritingDeliveryFileActions = actionCreatorsFactory([LessonsActionTypes.ADD_WRITING_DELIVERY_FILE_SUCCESS], 'addWritingDeliveryFile');

export const addSpeakingDeliveryFileActions = actionCreatorsFactory(
  [LessonsActionTypes.ADD_SPEAKING_DELIVERY_FILE_SUCCESS],
  'addSpeakingDeliveryFile'
);

export const addWritingDeliveryFile = (file) => {
  return async (dispatch) => {
    dispatch(addWritingDeliveryFileActions.addWritingDeliveryFileSuccess(file));
  };
};

export const addSpeakingDeliveryFile = (file) => {
  return async (dispatch) => {
    dispatch(addSpeakingDeliveryFileActions.addSpeakingDeliveryFileSuccess(file));
  };
};

export const removeWritingDeliveryFileActions = actionCreatorsFactory(
  [LessonsActionTypes.REMOVE_WRITING_DELIVERY_FILE_SUCCESS],
  'removeWritingDeliveryFile'
);
export const removeSpeakingDeliveryFileActions = actionCreatorsFactory(
  [LessonsActionTypes.REMOVE_SPEAKING_DELIVERY_FILE_SUCCESS],
  'removeSpeakingDeliveryFile'
);

export const removeWritingDeliveryFile = (fileId) => {
  return async (dispatch) => {
    dispatch(removeWritingDeliveryFileActions.removeWritingDeliveryFileSuccess(fileId));
  };
};

export const removeSpeakingDeliveryFile = (fileId) => {
  return async (dispatch) => {
    dispatch(removeSpeakingDeliveryFileActions.removeSpeakingDeliveryFileSuccess(fileId));
  };
};

export const setSelectedUnit = ({ token, unit }) => {
  return async (dispatch) => {
    try {
      dispatch(setSelectedUnitActions.setSelectedUnit());
      dispatch(getSelectedUnitProgressActions.getSelectedUnitProgress());

      dispatch(setSelectedUnitActions.setSelectedUnitSuccess({ ...unit }));
      dispatch(getLessonsAssignmentsLiveProgress({ token, lessonId: unit.id }));
    } catch (err) {
      sendSentryError({ err, context: 'setSelectedUnit' });
      const errorMsg = err.message || 'Something went wrong while setting the unit';
      dispatch(setSelectedUnitActions.setSelectedUnitFail(errorMsg));
    }
  };
};

const setLoadingLessonsStatus = (status) => ({
  type: LOADING_LESSONS_STATUS,
  payload: status
});

export const getUnitsLiveProgressAndScoreActions = actionCreatorsFactory(
  [LessonsActionTypes.GET_UNITS_LIVE_PROGRESS, LessonsActionTypes.GET_UNITS_LIVE_PROGRESS_SUCCESS, LessonsActionTypes.GET_UNITS_LIVE_PROGRESS_FAIL],
  'getUnitsLiveProgressAndScore'
);

export function getUnitsLiveProgressAndScore({ token, courseId, callback }) {
  return async (dispatch) => {
    try {
      dispatch(getUnitsLiveProgressAndScoreActions.getUnitsLiveProgressAndScore());
      let { data } = await LessonsAPI.getUnitsLiveProgressAndScore({ token, courseId });
      if (!Object.keys(data).length) data = [];
      dispatch(getUnitsLiveProgressAndScoreActions.getUnitsLiveProgressAndScoreSuccess(data));
      dispatch(setLoadingLessonsStatus(false));
      if (callback) callback(data);
    } catch (err) {
      sendSentryError({ err, context: 'getUnitsLiveProgressAndScore' });
      dispatch(getUnitsLiveProgressAndScoreActions.getUnitsLiveProgressAndScoreFail(err.message));
    }
  };
}

export const getLessonsAssignmentsLiveProgressActions = actionCreatorsFactory(
  [
    LessonsActionTypes.GET_LESSONS_ASSIGNMENTS_LIVE_PROGRESS,
    LessonsActionTypes.GET_LESSONS_ASSIGNMENTS_LIVE_PROGRESS_SUCCESS,
    LessonsActionTypes.GET_LESSONS_ASSIGNMENTS_LIVE_PROGRESS_FAIL
  ],
  'getLessonsAssignmentsLiveProgress'
);

export function getLessonsAssignmentsLiveProgress({ token, lessonId, callback, errorCallback }) {
  return async (dispatch) => {
    try {
      dispatch(getLessonsAssignmentsLiveProgressActions.getLessonsAssignmentsLiveProgress());
      let { data } = await LessonsAPI.getLessonsAssignmentsLiveProgress({ token, lessonId });
      if (!Object.keys(data).length) data = [];
      dispatch(getLessonsAssignmentsLiveProgressActions.getLessonsAssignmentsLiveProgressSuccess(data));
      dispatch(setLoadingLessonsStatus(false));
      if (callback) callback(data);
    } catch (err) {
      sendSentryError({ err, context: 'getLessonsAssignmentsLiveProgress' });
      if (errorCallback) errorCallback();
      dispatch(getLessonsAssignmentsLiveProgressActions.getLessonsAssignmentsLiveProgressFail(err.message));
    }
  };
}
