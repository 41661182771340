import validator from 'validator';

export function isEmail(email) {
  return validator.isEmail(email);
}

export function is8CharsMin(str) {
  return validator.isLength(str, { min: 8 });
}

export function isStrongPassword(str) {
  const validatorResult = validator.isStrongPassword(str, {
    minLength: 8,
    minUppercase: 1,
    minNumbers: 0,
    minSymbols: 0
  });

  return validatorResult;
}

export function isMatchPassword(psw, confirmPsw) {
  return validator.equals(psw, confirmPsw);
}
