import { validateEmail } from '../../utils/string-helpers';

export const minLength = (min) => (value) => value && value.length < min ? `Must be at least ${min}` : undefined;

export const minLength8 = minLength(8);

export const minLength11 = minLength(11);

export const required = (value) => (value ? undefined : 'Required');

export const email = (value) => (value && !validateEmail(value) ? 'Invalid email address' : undefined);

export const validate = (values) => !!values;

export const lengthIs6 = (value) => (value && value.length !== 6 ? 'Conference ID should be a 6 digits code' : undefined);

export const lengthIs4 = (value) => (value && value.length !== 4 ? 'Guest Code should be a 4 digits code' : undefined);

export const url = (value) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !pattern.test(value) ? 'Not a valid URL' : undefined;
};

export const passwordMatch = (value, allValues) => (value !== allValues.new_password1 ? 'Passwords do not match' : undefined);

export const isMeetMeUrl = (value) => (value.includes('meetme.bnpparibas') ? undefined : 'Not a valid MeetMe URL');
