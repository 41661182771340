import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import * as moment from 'moment-timezone';

import TimeOffForm from './TimeOffForm';
import ButtonModal from '../uikit/ButtonModal';
import Mixpanel from '../../utils/mixpanel-helper.js';
import { DATE_DISPLAY_FORMAT, ENDPOINT_DATE_FORMAT, NAIVE_TIME_DISPLAY_FORMAT, TIME_DISPLAY_FORMAT } from '../../utils/time-helpers';

import styles from '../../style/components/calendar/TimeOff.module.scss';

class TimeOff extends Component {
  modal = null;

  renderAbsencePeriod = (absencePeriod) => {
    const start = moment.utc(absencePeriod.start);
    start.tz(this.props.timezone);

    const end = moment.utc(absencePeriod.end);
    end.tz(this.props.timezone);

    const startDateString = start.format(ENDPOINT_DATE_FORMAT);
    const startTimeString = start.format(NAIVE_TIME_DISPLAY_FORMAT);

    const endDateString = end.format(ENDPOINT_DATE_FORMAT);
    const endTimeString = end.format(NAIVE_TIME_DISPLAY_FORMAT);

    const startDayDisplay = start.format(DATE_DISPLAY_FORMAT);
    const endDayDisplay = end.format(DATE_DISPLAY_FORMAT);

    let startTimeDisplay;

    // Convert the inclusive end time to a non-inclusive one. (More info: https://goo.gl/RY1Voy)
    const endTimeDisplay = end.clone().add(1, 'minute').format(TIME_DISPLAY_FORMAT);

    const startUTCOffset = start.utcOffset();
    const endUTCOffset = end.utcOffset();

    if (startUTCOffset === endUTCOffset) {
      startTimeDisplay = start.format(NAIVE_TIME_DISPLAY_FORMAT);
    } else {
      startTimeDisplay = start.format(TIME_DISPLAY_FORMAT);
    }

    let absencePeriodDisplay;

    if (startDateString === endDateString) {
      if (startTimeString === '00:00' && endTimeString === '23:59') {
        absencePeriodDisplay = startDayDisplay;
      } else {
        absencePeriodDisplay = `${startDayDisplay}: ${startTimeDisplay} – ${endTimeDisplay}`;
      }
    } else {
      let startDisplay;
      let endDisplay;

      if (startTimeString === '00:00') {
        startDisplay = startDayDisplay;
      } else {
        startDisplay = `${startDayDisplay} @ ${startTimeDisplay}`;
      }

      if (endTimeString === '23:59') {
        endDisplay = endDayDisplay;
      } else {
        endDisplay = `${endDayDisplay} @ ${endTimeDisplay}`;
      }

      absencePeriodDisplay = `${startDisplay} – ${endDisplay}`;
    }

    return (
      <ListGroupItem bsStyle="danger" className={styles.absencePeriod} key={absencePeriod.id}>
        {absencePeriodDisplay}

        <span className="pull-right">
          <ButtonModal
            buttonStyle="greenButton"
            buttonText="Edit"
            ref={(modal) => {
              this.modal = modal;
            }}>
            <TimeOffForm
              closeModal={() => this.modal.close()}
              deleteAbsencePeriod={this.props.deleteAbsencePeriod}
              end={end}
              id={absencePeriod.id}
              start={start}
              title="Edit time off"
              token={this.props.token}
              tutor={this.props.tutor}
              updateAbsencePeriod={this.props.updateAbsencePeriod}
            />
          </ButtonModal>
        </span>
      </ListGroupItem>
    );
  };

  render = () => (
    <div className={styles.container}>
      <ListGroup>
        {this.props.absencePeriods.length > 0 && this.props.absencePeriods.map(this.renderAbsencePeriod)}

        {this.props.absencePeriods.length < 1 && (
          <ListGroupItem className={styles.absencePeriod_default}>You have no upcoming time off.</ListGroupItem>
        )}
      </ListGroup>

      <div className={styles.actions}>
        <ButtonModal
          buttonText="Add new"
          ref={(modal) => {
            this.modal = modal;
          }}
          onClick={() => {
            Mixpanel.track('Click', { button: 'add_new_timeoff' });
          }}>
          <TimeOffForm
            closeModal={() => this.modal.close()}
            createAbsencePeriod={this.props.createAbsencePeriod}
            token={this.props.token}
            tutor={this.props.tutor}
            timezone={this.props.timezone}
          />
        </ButtonModal>
      </div>
    </div>
  );
}

TimeOff.propTypes = {
  absencePeriods: PropTypes.array.isRequired,
  createAbsencePeriod: PropTypes.func.isRequired,
  deleteAbsencePeriod: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  tutor: PropTypes.object.isRequired,
  updateAbsencePeriod: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired
};

export default TimeOff;
