import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '../../design-system/button/Button';
import Modal from '../../design-system/modal/Modal';

import { initChangeCoachProcess, setOpenChangeCoachModal } from '../../../actions/change-coach';

import styles from './SessionsManagementModal.module.scss';

const CoachUnavailableModal = ({
  enableCoachChangeWhenUnavailable,
  initChangeCoachProcessAction,
  setOpenChangeCoachModalAction,
  modalTitle,
  onCloseModal,
  modalMainColor,
  testId,
  tutor
}) => {
  const history = useHistory();

  const handleChangeCoach = () => {
    history.push('/pick-your-coach');
    initChangeCoachProcessAction(true);
    setOpenChangeCoachModalAction(false);
  };

  const handleCloseModal = () => {
    if (onCloseModal) onCloseModal();
  };

  return (
    <Modal
      noPadding
      completionProgress={100}
      modalMainColor={modalMainColor}
      content={
        <div className={[styles.modalWrapper, styles.noPadding].join(' ')} data-testid={testId}>
          <div className={[styles.modalTitle, styles[modalMainColor]].join(' ')}>
            <img src={tutor?.profile_picture || tutor?.photo} alt="" />
            <h2 data-testid="booking-modal-title">
              {modalTitle} with {tutor?.name || tutor?.first_name}
            </h2>
          </div>
          <div className={styles.modalSection}>
            <p>
              Unfortunately {tutor?.first_name ? tutor?.first_name : 'your coach'} is currently unavailable to take Live Practices. Please choose
              another coach to continue booking your next session or contact us at <a href="mailto:courses@chatterbox.io">courses@chatterbox.io</a>.
            </p>
            <div className={styles.buttonsWrapper}>
              <Button outfit="secondaryButton" onClick={handleCloseModal} id="modal-close">
                Close
              </Button>
              {enableCoachChangeWhenUnavailable && (
                <Button outfit="greenButton" onClick={handleChangeCoach} id="change-coach-modal-button">
                  Change Coach
                </Button>
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};

CoachUnavailableModal.propTypes = {
  enableCoachChangeWhenUnavailable: PropTypes.bool,
  cleanPreviousStateAction: PropTypes.func.isRequired,
  initChangeCoachProcessAction: PropTypes.func.isRequired,
  setOpenChangeCoachModalAction: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  onCloseModal: PropTypes.func,
  modalMainColor: PropTypes.string,
  selectedTutor: PropTypes.object,
  testId: PropTypes.string,
  tutor: PropTypes.object
};

CoachUnavailableModal.defaultProps = {
  enableCoachChangeWhenUnavailable: true,
  cleanPreviousStateAction: () => {},
  disabledActionButton: false
};

const mapStateToProps = (state) => ({
  matchedTutor: state.selectedTutor.matchedTutor,
  selectedTutor: state.selectedTutor.selectedTutor
});

export default connect(mapStateToProps, {
  initChangeCoachProcessAction: initChangeCoachProcess,
  setOpenChangeCoachModalAction: setOpenChangeCoachModal
})(CoachUnavailableModal);
