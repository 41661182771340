import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import axios from 'axios';

import { renderField } from '../../utils/form-helpers';
import { validate, required, email } from './validate.js';
import styles from '../../style/components/account/PasswordResetRequestForm.module.scss';
import Mixpanel from '../../utils/mixpanel-helper.js';
import Button from '../design-system/button/Button';
import SuccessToast from '../design-system/toast/successToast/SuccessToast';
import ErrorToast from '../design-system/toast/errorToast/ErrorToast.jsx';
import { removeCSRFToken } from '../../utils/cookie-helpers';

function sendPasswordResetRequest(emailAddress, successCallback, errorCallback) {
  axios
    .post('/rest-auth/password/reset/', { email: emailAddress })
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error);
    });
}

class PasswordResetRequestForm extends Component {
  state = {
    error: null,
    isLoading: false,
    isSubmitted: false
  };

  componentDidMount() {
    removeCSRFToken();
  }

  requestPasswordReset = (formValues) => {
    this.setState({ isLoading: true });
    sendPasswordResetRequest(
      formValues.email,
      () => this.setState({ error: null, isLoading: false, isSubmitted: true }),
      (error) => {
        if (error && error.response && error.response.data && error.response.data.email) {
          this.setState({ error: error.response.data.email });
        } else if (error && error.response && error.response.data && error.response.data.detail) {
          this.setState({ error: error.response.data.detail });
        } else if (error) {
          this.setState({
            error: 'Something went wrong while reseting your password. Please try again.'
          });
        }
        this.setState({ isLoading: false });
      }
    );
    Mixpanel.track('Reset password', { email: formValues.email });
  };

  render() {
    const { handleSubmit } = this.props;

    let content;

    if (this.state.isSubmitted) {
      content = (
        <div>
          <SuccessToast
            msg={
              <>
                <p>Please check your email for a password reset link.</p>
                <p>
                  If you do not get a link, your account may have a different email address. Try{' '}
                  <a href="mailto:support@chatterbox.io">asking us for help</a>.
                </p>
              </>
            }
          />
          <Link to="/">Back to login</Link>
        </div>
      );
    } else {
      content = (
        <div className={styles.passworResetContent}>
          <p className={styles.resetPswCopy}>
            Please enter your email address below and we will send you a link to reset your password, so you can access again your Chatterbox account.
          </p>

          <ErrorToast errorMsg={this.state.error} onClose={() => this.setState({ error: null })} show={this.state.error} />

          <Field component={renderField} label="Email Address" name="email" type="email" validate={[required, email]} />

          <div className={styles.submitButton}>
            <Button type="submit">Submit</Button>
          </div>
        </div>
      );
    }

    return (
      <form className={styles.passworResetWrapper} onSubmit={this.state.isSubmitted ? null : handleSubmit(this.requestPasswordReset)}>
        <h2 className={styles.resetPswTitle}>Reset password</h2>
        {content}
      </form>
    );
  }
}

PasswordResetRequestForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  validate,
  form: 'PasswordResetRequestForm'
})(connect(null, { sendPasswordResetRequest })(PasswordResetRequestForm));
