import { GET_GROUP_SESSIONS, GET_GROUP_SESSIONS_ERROR, USER_LOGOUT } from '../actions/types';

export default function groupSessionsReducer(state = {}, action) {
  switch (action.type) {
    case GET_GROUP_SESSIONS:
      return action.payload.data;
    case GET_GROUP_SESSIONS_ERROR:
      return {};
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
}
