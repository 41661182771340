import {
  SET_ONBOARDING_CHOICES,
  SET_TUTOR_ONBOARDING_CHOICES,
  INITIALIZE_ONBOARDING_DATA,
  STORE_ONBOARDING_SELECTION,
  RESET_ONBOARDING
} from './types';

export function setOnboardingAnswers(data) {
  return {
    type: SET_ONBOARDING_CHOICES,
    payload: data
  };
}

export function setTutorOnboardingAnswers(data) {
  return {
    type: SET_TUTOR_ONBOARDING_CHOICES,
    payload: data
  };
}

export function initializeOnboardingData(data) {
  return {
    type: INITIALIZE_ONBOARDING_DATA,
    payload: data
  };
}

export function storeOnboardingSelection(data) {
  return {
    type: STORE_ONBOARDING_SELECTION,
    payload: data
  };
}

export function resetOnboarding() {
  return {
    type: RESET_ONBOARDING
  };
}
