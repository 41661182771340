import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Grid, Row } from 'react-bootstrap';

import TutorMatchProfile from '../components/tutor-match/TutorMatchProfile';
import Mixpanel from '../utils/mixpanel-helper.js';

import styles from '../style/containers/TutorMatch.module.scss';
import appStyles from '../style/containers/App.module.scss';

import BookingForm from '../components/tutor-booking-page/BookingForm';
import { getUpcomingAppointmentsForStudent } from '../actions/appointments';
import { getTutorInfo } from '../actions/tutor-info';
import getOrderInfo from '../actions/order-info';
import { refreshUser, hideOnboarding } from '../actions/user';
import { updateUserProfile } from '../actions/profile';
import ErrorAlert from '../components/uikit/ErrorAlert';

const DEFAULT_IS_GROUP_SESSION = false;
const DEFAULT_MEETING_PLACE = '';

class TutorProfilePreview extends Component {
  state = {
    showBooking: false
  };

  UNSAFE_componentWillMount() {
    this.props.getTutorInfo(this.props.user.token);
  }

  componentDidMount() {
    const { user } = this.props;
    if (user.show_onboarding) {
      this.props.hideOnboarding(this.props.user.token);
    }
    Mixpanel.identify(this.props.user.id);
    Mixpanel.track('[Tutor] Visited tutor page');
  }

  getTutorInfo = () => {
    this.props.getTutorInfo(this.props.user.token);
  };

  toogleBooking() {
    this.setState({ showBooking: !this.state.showBooking });
  }

  finishBooking() {
    const { user } = this.props;
    this.props.getOrderInfo(user.token, this.showError);
    this.props.getUpcomingAppointmentsForStudent(user.token, user.id, this.showError);
    this.props.refreshUser(user.token);
    this.props.history.push('/');
    window.scrollTo(0, 0);
  }

  showError(error) {
    this.setState({
      error: true,
      errorMsg: error
    });
  }

  render() {
    const { tutor, user, bookable, productType, language, history, bookingFrequency } = this.props;
    const { showBooking, isEditing, consent } = this.state;

    // default value
    const isGroupSession = DEFAULT_IS_GROUP_SESSION;
    const meetingPlace = DEFAULT_MEETING_PLACE;

    // Selected in onboarding
    const deliveryType = productType.deliveryType;
    const trainingType = productType.trainingType;
    let languageToFind = language;
    if (typeof language === 'object') {
      languageToFind = this.props.language.language;
    }
    const languageMatch = tutor && tutor.languages ? tutor.languages.filter((x) => x.language === languageToFind) : [];
    const prices = productType.prices;
    return (
      <>
        {!tutor && <div className={[appStyles.loader, appStyles.loaderCentralized].join(' ')} />}
        {tutor && (
          <div className={styles.containerWrapper}>
            <TutorMatchProfile
              tutor={{ name: tutor.first_name, id: tutor.id }}
              bio={tutor.bio}
              languages={tutor.languages}
              sessionTypes={tutor.session_types}
              location={tutor.country}
              name={tutor.first_name}
              surName={tutor.last_name}
              photoURL={tutor.profile_picture}
              onStartLearning={this.toogleBooking.bind(this)}
              rating={tutor.calculated_rating}
              reviews={tutor.reviews}
              seniority={tutor.seniority}
              hoursTutored={tutor.hours_tutored}
              userType={user.user_type}
              history={history}
              topReviews={tutor.top_reviews}
              calendarMatrix={tutor.availability_matrix}
              professionalInterests={tutor.professional_interests}
              textOtherProfessional={tutor.text_other_professional}
              personalInterests={tutor.personal_interests}
              textOtherPersonal={tutor.text_other_personal}
              toggleEditing={this.toggleEditing}
              toggleConsent={this.toggleConsent}
              isEditing={isEditing}
              consent={consent}
              onBioChange={this.onBioChange}
              saveChanges={this.saveChanges}
              token={user.token}
              user={user}
              userId={user.id}
              getTutorInfo={this.getTutorInfo}
              updateUserProfile={this.props.updateUserProfile}
              inReview={tutor.in_review}
            />
            {this.state.error && (
              <Grid className={styles.errorContainer}>
                <Row>
                  <Col xs={12}>
                    <ErrorAlert errorMsg={this.state.errorMsg} />
                  </Col>
                </Row>
              </Grid>
            )}
            {showBooking && (
              <BookingForm
                productType={productType}
                creditsLeft={this.state.remainingCredits ? this.state.remainingCredits : productType.remainingCredits}
                bookableLessonNumbers={bookable.next_lesson === null ? null : [bookable.next_lesson]}
                nextLessons={bookable.next_lessons === null ? [] : bookable.next_lessons}
                bookableMomentStrings={bookable.datetimes}
                bookableDatesAndTimesISO={bookable.datetimes}
                courseId={bookable.course_id}
                deliveryType={deliveryType}
                finishBooking={this.finishBooking.bind(this)}
                meetingPlaceName={meetingPlace ? meetingPlace.name : ''}
                student={user}
                trainingType={trainingType}
                tutorId={tutor.id}
                tutorProfile={tutor}
                language={languageMatch[0]}
                isGroupSession={isGroupSession}
                history={history}
                purchase={(amount, cost, currency) => {
                  this.buy(amount, cost, currency, trainingType, deliveryType);
                }}
                prices={prices}
                bookingFrequency={bookingFrequency}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    productType: state.selectedProductType,
    user: state.user,
    tutor: state.tutorInfo ? state.tutorInfo.profilePreview : undefined,
    bookable: state.bookable.available,
    language: state.selectedLanguage,
    bookingFrequency: state.bookingFrequency
  };
}

TutorProfilePreview.propTypes = {
  user: PropTypes.object.isRequired,
  tutor: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
    location: PropTypes.object
  }).isRequired,
  getOrderInfo: PropTypes.func.isRequired,
  getUpcomingAppointmentsForStudent: PropTypes.func.isRequired,
  refreshUser: PropTypes.func.isRequired,
  bookable: PropTypes.bool.isRequired,
  productType: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  hideOnboarding: PropTypes.func.isRequired,
  getTutorInfo: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  bookingFrequency: PropTypes.any.isRequired
};

export default connect(mapStateToProps, {
  getUpcomingAppointmentsForStudent,
  getOrderInfo,
  refreshUser,
  hideOnboarding,
  getTutorInfo,
  updateUserProfile
})(TutorProfilePreview);
