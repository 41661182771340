import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { isUnitEnabled } from '../../../../reducers/courses';
import { ASSIGNMENT_STATES } from '../../../../common/assignmentStates';
import Lesson from './Lesson';

import styles from './List.module.scss';
import Assessment from './Assessment';
import Syllabus from './Syllabus';

const DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };
const DUE_DATE_DELTA = 7;

function daysFromNow(date) {
  var now = new Date();
  var inputDate = new Date(date);
  var diffInMilliseconds = inputDate - now;
  var diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
  return diffInDays;
}

const List = ({ selectedCourse, selected, onSelectUnit, timezone, user }) => {
  return (
    <div className={styles.listWrapper} data-testid="list-wrapper">
      {selectedCourse?.syllabus_objectives_pdf && <Syllabus link={selectedCourse?.syllabus_objectives_pdf} />}
      {selectedCourse?.units &&
        selectedCourse.units.map((unit, index) => {
          if (!unit) return;
          const isEnabled = isUnitEnabled(selectedCourse, unit);
          const isSelected = selected === index;

          let dueDate;
          let isOverdue = false;
          if (unit.type.toLowerCase() === 'assessment' && index > 1) {
            const previousUnit = selectedCourse.units[index - 1];
            let dateObject;
            if (previousUnit.appointment) {
              dateObject = new Date(previousUnit.appointment.date);
              dateObject.setDate(dateObject.getDate() + DUE_DATE_DELTA);
            } else {
              dateObject = new Date(user.final_exam_due_date);
            }
            dueDate = dateObject.toLocaleDateString('en-US', DATE_OPTIONS);
            const daysToDueDate = daysFromNow(dateObject);
            if (daysToDueDate < 0) {
              isOverdue = true;
            }
          }

          return (
            <div key={index} data-testid={`unit-index:${index}`}>
              {unit.type.toLowerCase() === 'lesson' && (
                <Lesson
                  first={index === 0}
                  index={index}
                  isOptional={unit.isOptional}
                  isEnabled={isEnabled}
                  isSelected={isSelected}
                  last={index === selectedCourse.units.length - 1}
                  onSelectUnit={onSelectUnit}
                  nextLessonToStudyNumber={selectedCourse?.next_lesson_to_study}
                  unit={unit}
                  timezone={timezone}
                />
              )}
              {unit.type.toLowerCase() === 'assessment' && (
                <Assessment
                  first={index === 0}
                  index={index}
                  isOptional={unit.isOptional}
                  isEnabled={isEnabled}
                  isSelected={isSelected}
                  last={index === selectedCourse.units.length - 1}
                  onSelectUnit={onSelectUnit}
                  nextLessonToStudyNumber={selectedCourse?.next_lesson_to_study}
                  unit={unit}
                  isOverdue={isOverdue}
                  dueDate={dueDate}
                  isCompleted={unit.overall_status == ASSIGNMENT_STATES['completed']}
                />
              )}
            </div>
          );
        })}
    </div>
  );
};

List.propTypes = {
  selectedCourse: PropTypes.object.isRequired,
  selected: PropTypes.number.isRequired,
  onSelectUnit: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  selectedCourse: state.courses?.selectedCourseDetails?.courseDetails,
  timezone: state.time.timezone,
  user: state.user
});

export default connect(mapStateToProps)(List);
