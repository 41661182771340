import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './PhoneFormField.module.scss';
import PhoneInput from 'react-phone-input-2';

import formStyles from '../../../style/utils/_form.module.scss';

const PhoneFormField = ({ id, initialValue, label, onChange, validation, value }) => {
  const [valid, setValid] = useState(true);
  const fieldValue = value || initialValue || '';

  const handleUserTyping = (evt) => {
    onChange(evt);
    clearValidationError();
  };

  const checkValidation = (evt) => {
    const value = evt.target.value;
    if (!validation) return;

    const { validator } = validation;
    if (!validator) return;

    setValid(validator(value));
  };

  const clearValidationError = () => setValid(true);

  return (
    <div className={[styles['field-wrapper'], !valid && styles.invalidField].join(' ')}>
      <label className={formStyles.label} htmlFor={id}>
        {label}
      </label>
      <PhoneInput
        id={id}
        onChange={handleUserTyping}
        onBlur={(evt) => checkValidation(evt)}
        value={fieldValue}
        autoFormat
        defaultCountry="gb"
        disableAreaCodes
      />
      {!valid && <p className={styles.invalidMessage}>{validation.message}</p>}
    </div>
  );
};

PhoneFormField.propTypes = {
  id: PropTypes.string,
  initialValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func,
  validation: PropTypes.object,
  value: PropTypes.string.isRequired
};

PhoneFormField.defaultProps = {
  type: 'text',
  id: '',
  onChange: () => {}
};

export default PhoneFormField;
