import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Translation from './Translation';

import styles from './chat-web.module.scss';
import Conversations from './Conversations';
import Messages from './Messages';

const ChatPageWeb = ({ chatId }) => {
  const [isTranslated, setIsTranslated] = useState(false);

  return (
    <div className={styles.chatPageWrapper}>
      <div className={styles.mainContent}>
        <div className={styles.leftSide}>
          <Conversations chatId={chatId} />
        </div>
        <div className={styles.rightSide}>
          <Messages />
        </div>
      </div>
      <Translation isTranslated={isTranslated} setIsTranslated={setIsTranslated} />
    </div>
  );
};

ChatPageWeb.propTypes = {
  chatId: PropTypes.string
};

export default ChatPageWeb;
