import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Mixpanel from '../utils/mixpanel-helper.js';

import styles from '../components/navbar/Navbar.module.scss';
import HomeButton from '../components/navbar/components/home-button/HomeButton';
import DashboardButton from '../components/navbar/components/dashboard-button/DashboardButton.jsx';
import BookingsButton from '../components/navbar/components/bookings-button/BookingsButton.jsx';
import CoachAreaButton from '../components/navbar/components/coach-area-button/CoachAreaButton.jsx';
import StudyAreaButton from '../components/navbar/components/study-area-button/StudyAreaButton.jsx';
import BuyMoreCreditsButton from '../components/navbar/components/buy-more-credits-button/BuyMoreCreditsButton.jsx';
import ChatButton from '../components/navbar/components/chat-button/ChatButton.jsx';
import AccountButton from '../components/navbar/components/account-button/AccountButton.jsx';
import { checkChatMessages, removeMessageListener, setNewMessageListener } from '../actions/chat.js';
import HelpButton from '../components/navbar/components/help-button/HelpButton.jsx';
import NavbarLanguageSwitcher from '../components/weglot-language-switcher/NavbarLanguageSwitcher';
import CalendarButton from '../components/navbar/components/coach-calendar/CalendarButton.jsx';
import CoachInvoicesButton from '../components/navbar/components/coach-invoices/CoachInvoicesButton.jsx';

const Navbar = ({
  checkChatMessagesAction,
  introClass,
  mustTakeIntroClass,
  removeMessageListenerAction,
  selectedCourse,
  setNewMessageListenerAction,
  token,
  unreadMessages,
  user
}) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const mobileMenuRef = useRef();
  useEffect(() => {
    if (user) {
      checkChatMessagesAction(user);
      setNewMessageListenerAction(user);
    }

    return () => removeMessageListenerAction(user);
  }, [user]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideMobileMenu);
    return () => document.removeEventListener('mousedown', handleClickOutsideMobileMenu);
  }, []);

  const mixpanelTrack = (buttonName) => {
    Mixpanel.identify(user.id);
    Mixpanel.track('Click', { position: 'AppMenu', button: buttonName });
  };

  const handleClickOutsideMobileMenu = (evt) => {
    if (mobileMenuRef?.current && !mobileMenuRef?.current.contains(evt.target)) {
      setShowMobileMenu(false);
    }
  };

  const handleMenuLinkClick = (buttonName) => {
    mixpanelTrack(buttonName);
    setShowMobileMenu(false);
  };

  const handleOpenMobileMenu = () => {
    setShowMobileMenu((prevState) => !prevState);
  };

  return (
    <header className={[styles['navbar-container'], token && styles['logged-user']].join(' ')}>
      {token && (
        <nav className={styles['navbar-wrapper']} ref={mobileMenuRef} data-testid="navbar">
          <HomeButton token={token} user={user} handleMenuLinkClick={handleMenuLinkClick} />
          <button className={styles['mobile-hamburger']} onClick={handleOpenMobileMenu}>
            <div className={styles['mobile-hamburger-bar']} />
            <div className={styles['mobile-hamburger-bar']} />
            <div className={styles['mobile-hamburger-bar']} />
          </button>
          <ul className={[styles['links-wrapper'], showMobileMenu && styles['show-mobile-menu']].join(' ')}>
            <DashboardButton handleMenuLinkClick={handleMenuLinkClick} user={user} />
            <CalendarButton handleMenuLinkClick={handleMenuLinkClick} user={user} />
            <CoachAreaButton handleMenuLinkClick={handleMenuLinkClick} user={user} />
            <CoachInvoicesButton handleMenuLinkClick={handleMenuLinkClick} user={user} />
            <StudyAreaButton handleMenuLinkClick={handleMenuLinkClick} user={user} />
            <BookingsButton
              introClass={introClass}
              handleMenuLinkClick={handleMenuLinkClick}
              mustTakeIntroClass={mustTakeIntroClass}
              selectedCourse={selectedCourse}
              user={user}
            />
            <BuyMoreCreditsButton handleMenuLinkClick={handleMenuLinkClick} user={user} />
            <ChatButton handleMenuLinkClick={handleMenuLinkClick} unreadMessages={unreadMessages} />
            <HelpButton handleMenuLinkClick={handleMenuLinkClick} user={user} />
            <AccountButton handleMenuLinkClick={handleMenuLinkClick} />
            <NavbarLanguageSwitcher user={user} />
          </ul>
        </nav>
      )}
    </header>
  );
};

const mapStateToProps = (state) => ({
  introClass: state.sessions.introSession.introClass,
  mustTakeIntroClass: state.student.mustTakeIntroClass,
  selectedCourse: state.courses?.selectedCourseDetails?.courseDetails,
  unreadMessages: state.chat.unreadMessages,
  user: state.user,
  token: state.user.token
});

Navbar.propTypes = {
  checkChatMessagesAction: PropTypes.func.isRequired,
  introClass: PropTypes.shape({ status: PropTypes.string }),
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  mustTakeIntroClass: PropTypes.bool.isRequired,
  unreadMessages: PropTypes.number,
  removeMessageListenerAction: PropTypes.func.isRequired,
  selectedCourse: PropTypes.object,
  setNewMessageListenerAction: PropTypes.func.isRequired,
  token: PropTypes.string
};

Navbar.defaultProps = {
  unreadMessages: 0
};

export default withRouter(
  connect(mapStateToProps, {
    checkChatMessagesAction: checkChatMessages,
    removeMessageListenerAction: removeMessageListener,
    setNewMessageListenerAction: setNewMessageListener
  })(Navbar)
);
