import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import styles from '../../MenuItem.module.scss';
import { ReactComponent as IconChat } from '../../../../assets/icons/icon_chat-menu.svg';

const ChatButton = ({ handleMenuLinkClick, unreadMessages }) => {
  return (
    <li className={styles['link-wrapper']}>
      <NavLink
        to={'/chat'}
        onClick={() => handleMenuLinkClick('chat')}
        activeClassName={styles.isActive}
        className={[styles.menuItem, unreadMessages && styles.showBadge].join(' ')}
        data-testid="icon-chat-container">
        <div className={styles['button-wrapper']}>
          <div className={styles['background']}>
            <IconChat />
          </div>
          <span>Chat</span>
        </div>
      </NavLink>
    </li>
  );
};

ChatButton.propTypes = {
  handleMenuLinkClick: PropTypes.func.isRequired,
  unreadMessages: PropTypes.number
};

ChatButton.defaultProps = {
  unreadMessages: 0
};

export default ChatButton;
