import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'production' && window.location.href.includes('app.chatterbox.io')) {
  Sentry.init({
    dsn: 'https://8709edd5311b47b78830d8e7c504c5bf@sentry.io/786689',
    release: process.env.npm_package_version,
    integrations: [new Sentry.BrowserTracing()],
    environment: process.env.NODE_ENV
  });
}

class SentryTracing {
  _sentry;
  _tags;

  constructor(sentry) {
    this._sentry = sentry;
  }

  setUser(user) {
    this._sentry.setUser({
      email: user.email,
      id: user.id
    });
  }

  sendError({ err, context }) {
    this._sentry.captureException(err, { tags: { section: context } });
  }
}

const sentry = new SentryTracing(Sentry);

export const setSentryUser = (user) => sentry.setUser(user);
export const sendSentryError = ({ err, context }) => sentry.sendError({ err, context });
