import { createActionTypes } from '../utils/action-helpers';

export const ChangeCoachActionTypes = createActionTypes('ChangeCoach', [
  'SET_PROCESS_ACTIVE',
  'OPEN_CHANGE_COACH_MODAL',
  'CHANGE_COACH_MOTIVATION',
  'SET_SUCCESS'
]);

export const initChangeCoachProcess = (status) => ({
  type: ChangeCoachActionTypes.SET_PROCESS_ACTIVE,
  payload: status
});

export const setOpenChangeCoachModal = (open) => ({
  type: ChangeCoachActionTypes.OPEN_CHANGE_COACH_MODAL,
  payload: {
    openChangeCoachModal: open
  }
});

export const setChangeCoachMotivation = (motivation) => ({
  type: ChangeCoachActionTypes.CHANGE_COACH_MOTIVATION,
  payload: motivation
});

export const setChangeCoachSuccess = (success) => ({
  type: ChangeCoachActionTypes.SET_SUCCESS,
  payload: success
});
