import React from 'react';
import PropTypes from 'prop-types';
import Mixpanel from '../utils/mixpanel-helper.js';
import styles from '../style/components/Footer.module.scss';

const Footer = ({ user }) => (
  <footer className={styles.footer}>
    <a
      className={styles.link}
      href="https://chatterbox.io"
      rel="noopener noreferrer"
      target="_blank"
      onClick={() => {
        Mixpanel.track('Click', { link: 'chatterbox.io' });
      }}>
      chatterbox.io
    </a>
    <a
      className={styles.link}
      href="https://www.chatterbox.io/about-us"
      rel="noopener noreferrer"
      target="_blank"
      onClick={() => {
        Mixpanel.track('Click', { link: 'About_us' });
      }}>
      About us
    </a>
    {user && user.user_type === 'student' && (
      <a
        className={styles.link}
        href="https://chatterboxlanguages.freshdesk.com/support/home"
        rel="noopener noreferrer"
        target="_blank"
        onClick={() => {
          Mixpanel.track('Click', { link: 'FAQ' });
        }}>
        FAQ
      </a>
    )}
    <a
      className={styles.link}
      href="https://www.chatterbox.io/terms-cons"
      rel="noopener noreferrer"
      target="_blank"
      onClick={() => {
        Mixpanel.track('Click', { link: 'terms&conditions' });
      }}>
      Terms & Conditions
    </a>
    <a
      className={styles.link}
      href="https://www.chatterbox.io/cookie-policy"
      rel="noopener noreferrer"
      target="_blank"
      onClick={() => {
        Mixpanel.track('Click', { link: 'cookie' });
      }}>
      Cookie policy
    </a>
    <a
      className={styles.link}
      href="https://www.chatterbox.io/privacy-policy"
      rel="noopener noreferrer"
      target="_blank"
      onClick={() => {
        Mixpanel.track('Click', { link: 'privacy' });
      }}>
      Privacy policy
    </a>
  </footer>
);

Footer.propTypes = {
  user: PropTypes.object
};

export default Footer;
