import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Glyphicon } from 'react-bootstrap';

import styles from '../../style/components/uikit/Card.module.scss';

function isExternal(url) {
  // Based on this: https://stackoverflow.com/a/6238456/4424904

  const match = url.match(/^([^:/?#]+:)?(?:\/\/([^/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);

  if (typeof match[1] === 'string' && match[1].length > 0) {
    return true;
  }

  return false;
}

const Card = (props) => {
  const { buttonText, buttonURL, children, className, headerClassName, icon, title, onClick } = props;

  return (
    <div className={`${styles.card} ${className}`}>
      {(title || icon) && (
        <header className={`${styles.header} ${headerClassName}`}>
          <h3 className={styles.title}>
            {title}
            {icon && <Glyphicon className={`${styles.icon} pull-right`} glyph={icon} />}
          </h3>
        </header>
      )}

      <div className={styles.main}>
        {children && children.length && <div className={styles.content}>{children}</div>}

        {buttonText && buttonURL && (
          <div className={styles.buttons}>
            {isExternal(buttonURL) && (
              <Button
                onClick={() => {
                  onClick();
                }}
                bsStyle="primary"
                href={buttonURL}>
                {buttonText}
              </Button>
            )}

            {!isExternal(buttonURL) && (
              <Link to={buttonURL}>
                <Button
                  onClick={() => {
                    onClick();
                  }}
                  bsStyle="primary">
                  {buttonText}
                </Button>
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Card.propTypes = {
  buttonText: PropTypes.string,
  buttonURL: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func
};

Card.defaultProps = {
  buttonText: '',
  buttonURL: '',
  className: '',
  headerClassName: '',
  icon: '',
  title: '',
  onClick: () => {}
};

export default Card;
