import { intervalToDuration } from 'date-fns';

const timeToSeconds = Object.freeze({
  years: 31536000,
  months: 2628288,
  days: 86400,
  hours: 3600,
  minutes: 60,
  seconds: 1
});

const convertToSeconds = (dateObj) => {
  return Object.keys(dateObj).reduce((acc, cv) => acc + dateObj[cv] * timeToSeconds[cv], 0);
};

const useInitRecording = (recordFunction) => {
  const startTime = new Date();

  const finishRecording = (additionalData) => {
    const end = new Date();
    const timeSpent = intervalToDuration({ start: startTime, end });
    recordFunction(convertToSeconds(timeSpent), additionalData);
  };

  return [finishRecording];
};

export default useInitRecording;
