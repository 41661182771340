import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Clearfix, Col, Grid, Row, Glyphicon, Button } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import { getLesson } from '../actions/lessons';
import TutorLessonResource from '../components/resources/TutorLessonResource';
import ErrorAlert from '../components/uikit/ErrorAlert';
import { setCurrentCeregoAssignment } from '../actions/assignments';

import appStyles from '../style/containers/App.module.scss';
import uiStyles from '../style/components/uikit/ButtonModal.module.scss';
import styles from '../style/containers/LessonResources.module.scss';
import resourceStyles from '../style/containers/Resources.module.scss';
import Mixpanel from '../utils/mixpanel-helper.js';

import Modal from '../components/uikit/Modal';
import { hideCeregoPopup } from '../actions/user';

import CeregoLogo from '../assets/logos/cerego-logo.png';
import { UserTypes } from '../domains/user/user-types';

const MYC_COURSE_REGEX = /Meet Your Coach/i;

class LessonResources extends Component {
  constructor(props) {
    super();

    const { match, user } = props;
    const { course, lessonNumber } = match.params;
    Mixpanel.identify(user.id);
    Mixpanel.track('Visited Lesson Resources', {
      course,
      lesson_number: lessonNumber
    });

    if (!user.token) {
      props.history.push('/');
    }

    const showError = () => {
      this.state.error = true;
    };

    props.getLesson(user.token, course, lessonNumber, showError);
  }

  state = {
    error: false,
    allReadyJoinedCourses: [],
    showModal: false,
    ceregoUrl: '',
    urlIsExternal: false
  };

  getCeregoUrl = (resource) => {
    const { allReadyJoinedCourses } = this.state;
    let urlToAttach = resource.url;
    if (allReadyJoinedCourses.length > 0) {
      allReadyJoinedCourses.forEach((joinedCourse) => {
        if (joinedCourse.course.attributes && urlToAttach.includes(joinedCourse.course.attributes.slug)) {
          urlToAttach = urlToAttach.replace('https://cerego.com/join/chatterbox/', 'https://cerego.com/courses/');
        }
      });
    }
    return urlToAttach;
  };

  confirmCerego = () => {
    const { ceregoUrl, urlIsExternal } = this.state;
    const { token } = this.props.user;
    const { history } = this.props;
    this.props.hideCeregoPopup(token);
    if (urlIsExternal) {
      const cerego = window.open(ceregoUrl, '_blank');
      cerego.focus();
    } else {
      history.push(ceregoUrl);
    }
  };

  showCeregoPopup = (url, isExternal) => {
    this.setState({
      showModal: true,
      ceregoUrl: url,
      urlIsExternal: isExternal
    });
  };

  handleCloseModal = () => {
    const { token } = this.props.user;
    this.props.hideCeregoPopup(token);
  };

  showError = () => {
    this.setState({ error: true });
  };

  openCeregoResource = (resource) => {
    this.props.setCurrentCeregoAssignmentAction(resource).then(() => {
      this.props.history.push('/assignment');
    });
  };

  render() {
    const { lesson, user, history } = this.props;
    const { error, showModal } = this.state;

    const resources = lesson.resources;

    return (
      <div>
        {!error && (
          <div className={styles.header}>
            <Link
              onClick={() => {
                Mixpanel.track('Click', {
                  button: 'back_to_resources'
                });
              }}
              className={styles.goBackLink}
              to={user.user_type === UserTypes.tutor ? '/resources/' : '/study-area/'}>
              <Glyphicon style={{ fontSize: 13 }} glyph="chevron-left" />
              Back to resources
            </Link>
            <h1>{MYC_COURSE_REGEX.test(lesson.course_name) ? lesson.course_name : `${lesson.course_name}: Lesson ${lesson.number}`}</h1>
            <div className={styles.lessonInfoContainr}>
              <h2>{lesson.name}</h2>
              <p>{lesson.description}</p>
            </div>
          </div>
        )}

        {showModal && (
          <Modal
            className={uiStyles.modal}
            onModalClose={this.handleCloseModal}
            ref={(modal) => {
              this.modal = modal;
            }}>
            <img src={CeregoLogo} className={uiStyles.logo} alt="cerego-logo" />
            <p>
              Chatterbox’s independent learning takes place on our AI-powered learning app, Cerego. You will now be redirected to complete your
              independent learning there. Enjoy, and see you back here soon!
            </p>
            <Button onClick={this.confirmCerego} style={{ marginTop: 15 }} bsStyle="primary">
              Study in Cerego now
            </Button>
            <span className={uiStyles.extraInfo}>You will not see this window again</span>
          </Modal>
        )}

        <Grid className={styles.content}>
          <Row>
            <Col xs={12}>
              {error && <ErrorAlert />}

              {this.state.isLoading && <div className={appStyles.loader} />}
              {!error && resources && resources.length === 0 && (
                <Grid>
                  <Row>
                    <Col smOffset={1} sm={10} mdOffset={2} md={8} lgOffset={3} lg={6}>
                      <div className={resourceStyles.noResources}>
                        <p className="text-center text-muted">{'This course doesn&apos;t have any resource yet.'}</p>
                      </div>
                    </Col>
                  </Row>
                </Grid>
              )}
              {!error &&
                resources &&
                resources
                  .filter((r) => r.user_type === 'tutor')
                  .map((resource, index) => (
                    <span key={index}>
                      <Col xs={12} sm={12} md={12}>
                        <TutorLessonResource
                          name={resource.name}
                          type={resource.type}
                          url={resource.type !== 'cerego' ? resource.url : this.getCeregoUrl(resource)}
                          userType={resource.user_type}
                          progress={resource.progress !== undefined ? Math.round(resource.progress * 100) : undefined}
                          userIsBeta={user.is_beta_tester}
                          isCeregoResource={resource.type === 'cerego'}
                          shouldShowCeregoPopup={user.show_cerego_popup}
                          showCeregoPopup={this.showCeregoPopup}
                          history={history}
                          openCeregoResource={this.openCeregoResource}
                          resource={resource}
                        />
                      </Col>

                      {(index + 1) % 2 === 0 && <Clearfix visibleSmBlock />}

                      {(index + 1) % 3 === 0 && <Clearfix visibleMdBlock visibleLgBlock />}
                    </span>
                  ))}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

LessonResources.propTypes = {
  getLesson: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  lesson: PropTypes.shape({
    number: PropTypes.number.isRequired,
    description: PropTypes.string,
    language: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    resources: PropTypes.array.isRequired,
    course_name: PropTypes.string.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      lessonNumber: PropTypes.string,
      course: PropTypes.string
    })
  }).isRequired,
  user: PropTypes.object.isRequired,
  hideCeregoPopup: PropTypes.func.isRequired,
  setCurrentCeregoAssignmentAction: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    lesson: state.lesson,
    user: state.user
  };
}

export default connect(mapStateToProps, {
  getLesson,
  hideCeregoPopup,
  setCurrentCeregoAssignmentAction: setCurrentCeregoAssignment
})(LessonResources);
