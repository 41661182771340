import React from 'react';
import PropTypes from 'prop-types';

import Mixpanel from '../../utils/mixpanel-helper.js';
import ProgressBar from '../design-system/progress-bar/ProgressBar.jsx';
import CompletedCheck from '../uikit/CompletedCheck.js';
import resourceStyles from '../../style/containers/Resources.module.scss';
import Button from '../design-system/button/Button';

const VERBS_BY_RESOURCE_TYPE = {
  audio: 'Listen',
  document: 'View',
  image: 'View',
  video: 'Watch',
  webpage: 'Go',
  cerego: 'Start',
  cerego_started: 'continue',
  cerego_finished: 'review',
  assessment: 'Complete the test',
  book: 'Book now'
};

const LessonCeregoResource = (props) => {
  const {
    name,
    type,
    status,
    openCeregoResource,
    resource,
    isRevisionUnit,
    lessonNumber,
    resourceIndex,
    userType,
    disabled,
    progress,
    isLoadingProgress,
    testId
  } = props;

  const resourceName =
    lessonNumber !== undefined && resourceIndex !== undefined ? `${lessonNumber.toString()}.${resourceIndex.toString()}: ${name}` : name;
  const nameSuffix = userType === 'tutor' ? ' (coaching material)' : '';
  const finished = type === 'cerego' && progress >= 1;
  const started = type === 'cerego' && progress > 0 && progress < 1;
  let resourceType = type;
  if (started) {
    resourceType += '_started';
  } else if (finished) {
    resourceType += '_finished';
  }

  return (
    <div
      className={[
        resourceStyles.lessonCeregoResourceContainer,
        status === 'future' ? resourceStyles.disabledContainer : undefined,
        finished ? resourceStyles.finishedContainer : undefined,
        started ? resourceStyles.startedContainer : undefined
      ].join(' ')}
      data-testid={testId}
      style={type === 'book' ? { marginTop: 10 } : {}}>
      <div className={resourceStyles.lessonResourceLeftContent}>
        <h2 id="dont-translate" className={status === 'future' && type !== 'book' ? resourceStyles.disabledText : undefined}>
          <b>{resourceName + nameSuffix}</b>
        </h2>
        {type === 'cerego' && progress < 1 && (
          <div className={resourceStyles.progressBarWrapper}>
            <ProgressBar progress={progress} isLoading={isLoadingProgress} />
          </div>
        )}
        {type === 'cerego' && progress >= 1 && (
          <div className={resourceStyles.progressBarWrapper}>
            <CompletedCheck />
            <span className={resourceStyles.completedCopy}>Completed!</span>
          </div>
        )}
      </div>
      <div className={resourceStyles.buttonContainer}>
        <Button
          className={resourceStyles.resourceBtns}
          outfit="greenButton"
          bold
          onClick={() => {
            if (isRevisionUnit) {
              Mixpanel.track('Opened revision unit', { name });
            }
            openCeregoResource(resource);
            Mixpanel.track('Click', {
              button: VERBS_BY_RESOURCE_TYPE[resourceType], // left this to make it backward compatible
              resourceName: name,
              clickType: 'cerego resource',
              type,
              lessonNumber,
              status,
              finished,
              started
            });
          }}
          disabled={status === 'future' || disabled || isLoadingProgress}>
          {VERBS_BY_RESOURCE_TYPE[resourceType].toUpperCase()}
        </Button>
      </div>
    </div>
  );
};

LessonCeregoResource.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  status: PropTypes.string,
  openCeregoResource: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  resource: PropTypes.object.isRequired,
  isRevisionUnit: PropTypes.bool,
  lessonNumber: PropTypes.number.isRequired,
  resourceIndex: PropTypes.number.isRequired,
  userType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  progress: PropTypes.number,
  isLoadingProgress: PropTypes.bool,
  testId: PropTypes.string
};

LessonCeregoResource.defaultProps = {
  progress: undefined,
  isRevisionUnit: false,
  disabled: false,
  isLoadingProgress: false
};

export default LessonCeregoResource;
