import * as Sentry from '@sentry/browser';

import { isTokenExpired } from './common';
import TutorsAPI from '../services/tutors';
import { actionCreatorsFactory, asyncActionType, createActionTypes } from '../utils/action-helpers';

export const TutorsActionTypes = createActionTypes('Tutors', [asyncActionType('GET_TUTORS')]);

const getTutorsActions = actionCreatorsFactory(
  [TutorsActionTypes.GET_TUTORS, TutorsActionTypes.GET_TUTORS_SUCCESS, TutorsActionTypes.GET_TUTORS_FAIL],
  'getTutors'
);

export function getTutorsWithAppointmentType(token, appointmentTypeId, errorCallback) {
  return async (dispatch) => {
    try {
      dispatch(getTutorsActions.getTutors());
      const { data } = await TutorsAPI.getTutorsWithAppointmentType(token, appointmentTypeId);
      dispatch(getTutorsActions.getTutorsSuccess(data));
    } catch (err) {
      if (errorCallback) errorCallback();
      if (!isTokenExpired(dispatch, err)) {
        Sentry.captureException(err);
        dispatch(getTutorsActions.getTutorsFail());
      }
    }
  };
}

export function getTutorsWithLanguageAndProductTypeWithoutToken({ courses, productTypeId, languageId, errorCallback }, securityParams = {}) {
  return async (dispatch) => {
    try {
      dispatch(getTutorsActions.getTutors());
      const params = {
        courses,
        product_type: productTypeId,
        language: languageId
      };
      const { data } = await TutorsAPI.getTutorsWithoutToken(securityParams, params);
      dispatch(getTutorsActions.getTutorsSuccess(data));
    } catch (err) {
      if (errorCallback) errorCallback();
      const errorMessage = 'Something went wrong while getting tutors list. Please reload the page.';
      if (!isTokenExpired(dispatch, err)) {
        Sentry.captureException(err);
        dispatch(getTutorsActions.getTutorsFail(errorMessage));
      }
    }
  };
}

export function getTutorsWithLanguageAndProductType({ token, courses, productTypeId, languageId, errorCallback }) {
  return async (dispatch) => {
    try {
      // if (!productTypeId || !languageId) throw Error('Missing params');
      dispatch(getTutorsActions.getTutors());
      const params = {
        courses,
        product_type: productTypeId,
        language: languageId
      };
      const { data } = await TutorsAPI.getTutors(token, params);
      dispatch(getTutorsActions.getTutorsSuccess(data));
    } catch (err) {
      if (errorCallback) errorCallback();
      const errorMessage = 'Something went wrong while getting tutors list. Please reload the page.';
      if (!isTokenExpired(dispatch, err)) {
        Sentry.captureException(err);
        dispatch(getTutorsActions.getTutorsFail(errorMessage));
      }
    }
  };
}
