import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from '../../MenuItem.module.scss';
import { ReactComponent as IconChat } from '../../../../assets/icons/icon_chat-menu.svg';
import { NavLink } from 'react-router-dom';
import useCloseWhenClickOutside from '../../../../hooks/useCloseWhenClickOutside';
import { UserTypes } from '../../../../domains/user/user-types';

const HelpButton = ({ handleMenuLinkClick, user }) => {
  if (user?.user_type !== UserTypes.student) return;

  const [isOpen, setIsOpen] = useState(false);

  const handleExit = () => {
    setIsOpen(false);
  };

  const [wrapperRef] = useCloseWhenClickOutside({ handleExit });

  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleChatHelper = () => {
    handleMenuLinkClick('chat');
    setIsOpen(false);
  };

  return (
    <div className={[styles['reference'], isOpen && styles.isActive].join(' ')} ref={wrapperRef}>
      <li className={styles['link-wrapper']}>
        <button outfit="transparentButton" onClick={handleClick} className={styles.menuItem}>
          <div className={styles['button-wrapper']}>
            <div className={styles['background']}>
              <h1>?</h1>
            </div>
            <span>Help</span>
          </div>
        </button>
      </li>
      {isOpen && (
        <div className={styles['options-wrapper']}>
          <ul>
            <h3>Top FAQ questions</h3>
            <li>
              <a
                href="https://chatterboxlanguages.freshdesk.com/support/solutions/articles/103000154426-why-do-i-have-to-review-assignments-"
                target="_blank"
                rel="noreferrer">
                Why do I have to review assignments?
              </a>
            </li>
            <li>
              <a
                href="https://chatterboxlanguages.freshdesk.com/support/solutions/articles/103000154428-why-don-t-i-have-access-to-all-of-the-lessons-in-the-study-area-"
                target="_blank"
                rel="noreferrer">
                Why don’t I have access to all of the lessons in the Study Area?
              </a>
            </li>
            <li>
              <a
                href="https://chatterboxlanguages.freshdesk.com/support/solutions/articles/103000154429-what-are-the-assignments-"
                target="_blank"
                rel="noreferrer">
                What are the assignments?
              </a>
            </li>
            <li>
              <a
                href="https://chatterboxlanguages.freshdesk.com/support/solutions/articles/103000154430-what-is-cerego-"
                target="_blank"
                rel="noreferrer">
                What is Cerego?
              </a>
            </li>
            <h3>Talk to a human</h3>
            <li>
              <NavLink to={'/chat'} onClick={handleChatHelper} className={styles.menuItem} data-testid="chat-button-link">
                <div className={styles['chat-wrapper']}>
                  <span>Get in touch with Language Helper</span>
                  <div className={styles['background']}>
                    <IconChat />
                  </div>
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

HelpButton.propTypes = {
  handleMenuLinkClick: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default HelpButton;
