import React from 'react';
import PropTypes from 'prop-types';
import { SystemMessage } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';

import styles from './Message.module.scss';
import { MESSAGE_TYPES } from '../../messages.enum';
import TextMessage from './TextMessage';
import PhotoMessage from './PhotoMessage';
import FileMessage from './FileMessage';
import CardLoader from '../../../design-system/loading/card/CardLoader';

const Message = ({ date, id, isLoading, isSystemMessage, media, position, text, title, titleColor, type }) => {
  if (isSystemMessage)
    return (
      <div className={styles.systemMessageContainer}>
        <SystemMessage className={styles.systemMessage} text={text} />
      </div>
    );

  if (isLoading)
    return (
      <div className={position === 'right' ? styles.loadingMessage__right : styles.loadingMessage__left}>
        <div className={[styles.loadingMessage].join(' ')}>
          <CardLoader rows={3} rowsColor="gray" />
        </div>
      </div>
    );

  if (type === MESSAGE_TYPES.file)
    return (
      <div className={styles.messageContainer}>
        <FileMessage
          className={[styles.message, styles.file, position === 'right' ? styles.message__right : styles.message__left].join(' ')}
          id={id}
          date={date}
          media={media}
          position={position}
          title={title}
          titleColor={titleColor}
          type={type}
        />
      </div>
    );

  if (type === MESSAGE_TYPES.photo)
    return (
      <div className={styles.messageContainer}>
        <PhotoMessage
          className={[styles.message, styles.photo, position === 'right' ? styles.message__right : styles.message__left].join(' ')}
          id={id}
          date={date}
          media={media}
          position={position}
          title={title}
          titleColor={titleColor}
          type={type}
        />
      </div>
    );

  return (
    <div className={styles.messageContainer}>
      <TextMessage
        className={[styles.message, position === 'right' ? styles.message__right : styles.message__left].join(' ')}
        id={id}
        date={date}
        position={position}
        text={text}
        title={title}
        titleColor={titleColor}
        type={type}
      />
    </div>
  );
};

Message.propTypes = {
  customRef: PropTypes.object,
  data: PropTypes.object,
  date: PropTypes.instanceOf(Date).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isScrollEnabled: PropTypes.bool,
  isLastMessage: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSystemMessage: PropTypes.bool.isRequired,
  media: PropTypes.object,
  oldestMessageRef: PropTypes.object,
  position: PropTypes.string.isRequired,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

Message.defaultProps = {
  isSystemMessage: false
};

export default Message;
