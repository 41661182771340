import React from 'react';
import PropTypes from 'prop-types';

import Tip from './Tip';
import Modal from '../../../design-system/modal/Modal';
import Button from '../../../design-system/button/Button';

import styles from './DescriptionModal.module.scss';
import useCloseWhenClickOutside from '../../../../hooks/useCloseWhenClickOutside';

export const AVAILABLE_COLORS = ['dark_gray', 'light_blue', 'light_yellow', 'light_red', 'light_gray', 'light_purple'];

export const getRandomClass = () => AVAILABLE_COLORS[Math.floor(Math.random() * AVAILABLE_COLORS.length + 1)];

const DescriptionModal = ({ availability, isModalOpen, onBook, onCloseModal, tutor }) => {
  const [modalRef] = useCloseWhenClickOutside({ handleExit: onCloseModal });

  if (!isModalOpen) return null;

  return (
    <Modal
      isLoading={false}
      modalRef={modalRef}
      onClose={onCloseModal}
      content={
        <div className={styles['description-modal-wrapper']}>
          <div className={[styles['coach-presentation'], styles[getRandomClass()]].join(' ')}>
            {tutor?.photo && <img src={tutor?.photo} alt={tutor?.first_name} />}
          </div>
          <div className={styles['modal-header']}>
            <h1 className={styles['coach-name']}>{`${tutor?.first_name} ${tutor?.last_name}`}</h1>
            <div className={styles['coach-availability']}>
              {Boolean(availability.length) && availability.map((av, index) => <Tip key={index} label={av} />)}
            </div>
          </div>
          <div className={styles['modal-info']}>
            <p className={styles['info']}>
              <strong>Originally from: </strong>
              {tutor?.country}
            </p>
            <p className={styles['info']}>
              <strong>Interests: </strong>
              {tutor?.personal_interests && tutor.personal_interests.map((interest) => interest?.name || '').join(', ')}
            </p>
            <p className={styles['info']}>
              <strong>Background: </strong>
              {tutor?.professional_interests && tutor.professional_interests.map((interest) => interest?.name || '').join(', ')}
            </p>
          </div>
          <div className={styles['modal-description']}>
            <div className={styles['text-wrapper']}>
              <span>
                {tutor?.bio}
                {/* <button onClick={onCloseModal} className={styles["pink-close-button"]}>
                Close
              </button> */}
              </span>
            </div>
          </div>
          <div className={styles['book-button']}>
            <Button bold outfit="greenButton" onClick={() => onBook(tutor)}>
              BOOK
            </Button>
          </div>
        </div>
      }
    />
  );
};

DescriptionModal.propTypes = {
  availability: PropTypes.array.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onBook: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  tutor: PropTypes.object.isRequired
};

export default DescriptionModal;
