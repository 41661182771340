import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../design-system/button/Button';
import Modal from '../../design-system/modal/Modal';

import styles from './SessionsManagementModal.module.scss';

const SessionsUnavailableModal = ({ modalTitle, onCloseModal, modalMainColor, testId, tutor }) => {
  const handleCloseModal = () => {
    if (onCloseModal) onCloseModal();
  };

  return (
    <Modal
      noPadding
      completionProgress={100}
      modalMainColor={modalMainColor}
      content={
        <div className={[styles.modalWrapper, styles.noPadding].join(' ')} data-testid={testId}>
          <div className={[styles.modalTitle, styles[modalMainColor]].join(' ')}>
            <img src={tutor?.profile_picture || tutor?.photo} alt="" />
            <h2 data-testid="booking-modal-title">
              {modalTitle} with {tutor?.name || tutor?.first_name}
            </h2>
          </div>
          <div className={styles.modalSection}>
            <p>
              It seems that you do not have any sessions available. Please contact us at{' '}
              <a href="mailto:courses@chatterbox.io">courses@chatterbox.io</a>.
            </p>
            <div className={styles.buttonsWrapper}>
              <Button outfit="secondaryButton" onClick={handleCloseModal} id="modal-close">
                Close
              </Button>
            </div>
          </div>
        </div>
      }
    />
  );
};

SessionsUnavailableModal.propTypes = {
  cleanPreviousStateAction: PropTypes.func.isRequired,
  tutor: PropTypes.object,
  modalTitle: PropTypes.string,
  onCloseModal: PropTypes.func,
  modalMainColor: PropTypes.string,
  testId: PropTypes.string
};

const mapStateToProps = (state) => ({
  matchedTutor: state.selectedTutor.matchedTutor,
  selectedTutor: state.selectedTutor.selectedTutor
});

export default connect(mapStateToProps)(SessionsUnavailableModal);
