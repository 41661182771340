import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { postSsoLogIn, postSsoSignUp, redirectAfterLogin } from '../actions/new-login';
import { getHashParamsObject } from '../utils/url-helpers';
import SSOMessage from '../components/account/sso-login/Message';

const SSOHandler = ({ onboardingInitialInformation, postSsoLogInAction, postSsoSignUpAction, redirectUrl }) => {
  const { hash } = useLocation();
  const history = useHistory();
  const { access_token: ssoAccessToken } = getHashParamsObject(hash);

  const isSignup = () => redirectUrl.includes('is_signup=true');

  useEffect(() => {
    if (ssoAccessToken) {
      if (isSignup()) {
        postSsoSignUpAction({
          onboardSecurityParams: {
            email: onboardingInitialInformation.email,
            invitationToken: onboardingInitialInformation.invitationToken
          },
          accessToken: ssoAccessToken,
          successCallback: (user) => redirectAfterLogin(user, history)
        });
      } else {
        postSsoLogInAction({
          accessToken: ssoAccessToken,
          successCallback: (user) => redirectAfterLogin(user, history)
        });
      }
    }
  }, [ssoAccessToken]);

  return <SSOMessage />;
};

SSOHandler.propTypes = {
  onboardingInitialInformation: PropTypes.object,
  redirectUrl: PropTypes.string.isRequired,
  postSsoLogInAction: PropTypes.func.isRequired,
  postSsoSignUpAction: PropTypes.func.isRequired
};

SSOHandler.defaultProps = {
  redirectUrl: '/'
};

const mapStateToProps = (state) => ({
  onboardingInitialInformation: state.newOnboarding.initialInformation,
  redirectUrl: state.newLogin.ssoLogIn.redirectUrl
});

export default connect(mapStateToProps, {
  postSsoLogInAction: postSsoLogIn,
  postSsoSignUpAction: postSsoSignUp
})(SSOHandler);
