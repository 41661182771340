import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Checkbox, Col, ControlLabel, Form, FormGroup, FormControl, Row } from 'react-bootstrap';
import { Link, Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getUserProfile, updateUserProfile } from '../actions/profile';

import Header from '../components/uikit/Header';
import Mixpanel from '../utils/mixpanel-helper.js';

import styles from '../style/containers/UserProfile.module.scss';

import ProfilePlaceholder from '../assets/people/profile-placeholder.png';
import Dropzone from '../components/design-system/dropzone';
class UserProfile extends Component {
  state = {
    consent: false,
    serverBio: '',
    serverPhoto: null,
    updatedBio: '',
    updatedPhoto: null
  };

  componentDidMount() {
    this.props.getUserProfile(this.props.token, this.props.userId);
    Mixpanel.identify(this.props.userId);
    Mixpanel.track('Visited User Profile');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      serverBio: nextProps.bio,
      updatedBio: nextProps.bio
    });
  }

  onDrop = (files) => {
    const file = files.pop(0);
    this.setState({
      updatedPhoto: file
    });

    files.forEach((aFile) => {
      window.URL.revokeObjectURL(aFile.preview);
    });
  };

  onClick = () => {
    if (!this.state.consent) {
      return;
    }

    const formData = new FormData();
    const { updatedPhoto, updatedBio } = this.state;
    if (updatedPhoto) {
      window.URL.revokeObjectURL(updatedPhoto.preview);
      formData.append('photo', updatedPhoto);
    }

    if (updatedBio === '') {
      return toast.error('Couldnt update. You must enter a bio.');
    }

    formData.append('bio', updatedBio);

    this.props.updateUserProfile(
      this.props.token,
      this.props.userId,
      formData,
      () => {
        this.setState({
          serverBio: updatedBio,
          serverPhoto: updatedPhoto
        });

        toast.success('Successfully updated!');
      },
      () => {
        toast.error('Update failed.');
      }
    );
  };

  onMyProfileClick = () => {
    this.props.history.push('/tutor-profile-preview');
  };

  onChange = (e) => {
    this.setState({ updatedBio: e.target.value });
  };

  render() {
    const image = this.state.updatedPhoto ? this.state.updatedPhoto.preview : this.props.photo;
    return (
      <div>
        <Header text={`${this.props?.name}'s Profile`} />
        <div className={styles.container}>
          <Button bsStyle="primary" onClick={this.onMyProfileClick}>
            How Learners see my profile
          </Button>
          <Form style={{ marginTop: 25 }} horizontal>
            <FormGroup>
              <Col componentClass={ControlLabel} className={styles.label} sm={1}>
                Bio:
              </Col>
              <Col sm={12}>
                <FormControl
                  componentClass="textarea"
                  placeholder="Tell the learners a bit about yourself."
                  onChange={this.onChange}
                  className={styles.bio}
                  value={this.state.updatedBio}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col componentClass={ControlLabel} className={styles.label} sm={1}>
                Photo:
              </Col>
              <Col sm={12} className={styles.photoForm}>
                <div className={styles.photo} style={{ backgroundImage: `url(${image})` }} />
                <Dropzone className={[styles.dropZone, 'btn btn-info'].join(' ')} multiple={false} accept="image/*" onDrop={this.onDrop}>
                  Update photo
                </Dropzone>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col componentClass={ControlLabel} className={styles.label} sm={3}>
                Appointment types:
              </Col>
              <Col sm={12}>
                <p className={styles.explanation}>
                  These are the appointment types that learners will be able to book with you. If these look wrong or you want to make changes to
                  them, please contact us at community@chatterbox.io.
                </p>
                <ul>
                  {this.props.appointmentTypes.map((apt) => (
                    <li key={apt} className={styles.appointmentType}>
                      {apt}
                    </li>
                  ))}
                </ul>
              </Col>
            </FormGroup>

            <Checkbox checked={this.state.consent} onChange={() => this.setState({ consent: !this.state.consent })}>
              Media (photos, text, videos) uploaded here may be visible on your profile by other users of our service. By uploading this media you
              agree with our&nbsp;
              <a href="https://chatterbox.io/privacy-policy/" rel="noopener noreferrer" target="_blank">
                privacy policy
              </a>
              .
            </Checkbox>

            <FormGroup>
              <Col className={styles.buttonContainer} smOffset={4} sm={4}>
                <Button bsStyle="primary" disabled={!this.state.consent} onClick={this.onClick}>
                  Submit
                </Button>
              </Col>
            </FormGroup>
          </Form>

          <Row>
            <Col className="text-center" smOffset={4} sm={4}>
              <Link to="/dashboard/">Back to dashboard</Link>
            </Col>
          </Row>
        </div>

        <Prompt
          message={() => 'You have unsaved changes. Exit the page?'}
          when={this.state.updatedBio !== this.state.serverBio || this.state.updatedPhoto !== this.state.serverPhoto}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, profile } = state;
  return {
    token: user.token,
    name: user.first_name,
    userId: user.id,
    bio: profile.bio || '',
    photo: profile.photo || ProfilePlaceholder,
    appointmentTypes: profile.appointmentTypes || []
  };
}

UserProfile.propTypes = {
  name: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  appointmentTypes: PropTypes.array.isRequired,
  bio: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default connect(mapStateToProps, { getUserProfile, updateUserProfile })(UserProfile);
