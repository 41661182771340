import { CLEAR_USER_PROFILE, GET_USER_PROFILE, UPDATE_USER_PROFILE, USER_LOGOUT } from '../actions/types';

export default function profileReducer(state = {}, action) {
  switch (action.type) {
    case CLEAR_USER_PROFILE:
      return {};
    case GET_USER_PROFILE:
      return {
        bio: action.payload.data.bio,
        photo: action.payload.data.photo_url,
        appointmentTypes: action.payload.data.appointment_types,
        userId: action.payload.data.owner
      };
    case UPDATE_USER_PROFILE:
      return {
        bio: action.payload.data.bio,
        photo: action.payload.data.photo_url,
        userId: action.payload.data.owner,
        appointmentTypes: state.appointmentTypes
      };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
}
