import { helpdeskClient } from '../services/helpdesk';
import { actionCreatorsFactory, asyncActionType, createActionTypes } from '../utils/action-helpers';

export const HelpdeskActionTypes = createActionTypes('Helpdesk', [
  'CLEAR',
  asyncActionType('GET_TICKET'),
  asyncActionType('LIST_USER_TICKETS'),
  asyncActionType('GET_TICKET_TYPES'),
  asyncActionType('CREATE_TICKET')
]);

export const clearHelpdesk = () => ({
  type: HelpdeskActionTypes.CLEAR
});

const getTicketActions = actionCreatorsFactory(
  [HelpdeskActionTypes.GET_TICKET, HelpdeskActionTypes.GET_TICKET_SUCCESS, HelpdeskActionTypes.GET_TICKET_FAIL],
  'getTicket'
);

export function getTicket(payload) {
  return async (dispatch) => {
    try {
      dispatch(getTicketActions.getTicket());
      const { data } = await helpdeskClient.getTicket(payload);
      dispatch(getTicketActions.getTicketSuccess(data));
    } catch (err) {
      dispatch(getTicketActions.getTicketFail('Error getting ticket'));
    }
  };
}

const getTicketTypesActions = actionCreatorsFactory(
  [HelpdeskActionTypes.GET_TICKET_TYPES, HelpdeskActionTypes.GET_TICKET_TYPES_SUCCESS, HelpdeskActionTypes.GET_TICKET_TYPES_FAIL],
  'getTicketTypes'
);

export function getTicketTypes() {
  return async (dispatch) => {
    try {
      dispatch(getTicketTypesActions.getTicketTypes());
      const { data } = await helpdeskClient.getAllTicketFields();
      const typeOfRequestsField = data?.find((t) => t?.name === 'ticket_type') || {};
      const choices = typeOfRequestsField?.choices || [];
      dispatch(getTicketTypesActions.getTicketTypesSuccess(choices));
    } catch (err) {
      dispatch(getTicketTypesActions.getTicketTypesFail('Error getting ticket fields'));
    }
  };
}

const createTicketActions = actionCreatorsFactory(
  [HelpdeskActionTypes.CREATE_TICKET, HelpdeskActionTypes.CREATE_TICKET_SUCCESS, HelpdeskActionTypes.CREATE_TICKET_FAIL],
  'createTicket'
);

export function createTicket(payload) {
  return async (dispatch) => {
    try {
      dispatch(createTicketActions.createTicket());
      const { data } = await helpdeskClient.createTicketWithAttachments(payload);
      dispatch(createTicketActions.createTicketSuccess(data));
    } catch (err) {
      dispatch(createTicketActions.createTicketFail(err));
    }
  };
}

const listUserTicketsActions = actionCreatorsFactory(
  [HelpdeskActionTypes.LIST_USER_TICKETS, HelpdeskActionTypes.LIST_USER_TICKETS_SUCCESS, HelpdeskActionTypes.LIST_USER_TICKETS_FAIL],
  'listUserTickets'
);

export function listUserTickets(payload) {
  return async (dispatch) => {
    try {
      dispatch(listUserTicketsActions.listUserTickets());
      const { data } = await helpdeskClient.listUserTickets(payload);
      dispatch(listUserTicketsActions.listUserTicketsSuccess(data));
    } catch (err) {
      dispatch(listUserTicketsActions.listUserTicketsFail(err));
    }
  };
}
