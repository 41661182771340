import React from 'react';
import PropTypes from 'prop-types';
import { getInitials } from '../../../utils/string-helpers';

import styles from './Avatar.module.scss';

const Avatar = ({ src, userName }) => {
  return <div className={styles.wrapper}>{src ? <img src={src} alt="Avatar" /> : <p className={styles.initials}>{getInitials(userName)}</p>}</div>;
};

Avatar.propTypes = {
  src: PropTypes.string,
  userName: PropTypes.string
};

export default Avatar;
