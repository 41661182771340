import { GET_LESSON, GET_LESSON_ERROR, USER_LOGOUT } from '../actions/types';

export default function lessonReducer(state = {}, action) {
  switch (action.type) {
    case GET_LESSON:
      return action.payload.data;
    case GET_LESSON_ERROR:
      return {};
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
}
