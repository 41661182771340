export const ticketTypes = Object.freeze({
  courseSupport: 'Course Support',
  coachSupport: 'Coach Support',
  featureRequest: 'Feature Request',
  techSupport: 'Tech Support'
});

export const freshdeskTicketsStatus = Object.freeze({
  2: 'Open',
  3: 'Pending',
  4: 'Resolved',
  5: 'Closed'
});
