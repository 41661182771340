import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormGroup, FormControl } from 'react-bootstrap';

import styles from '../../style/components/invoices/SortCodeInput.module.scss';

class SortCodeInput extends Component {
  state = {
    field1: '',
    field2: '',
    field3: ''
  };

  componentDidMount() {
    this.setStateFields(this.props);
  }

  componentDidUpdate() {
    this.setStateFields(this.props);
  }

  setStateFields(props) {
    const { value } = props;
    const splitValue = value.split('-');

    const newState = {};
    let stateChanged = false;

    for (let index = 1; index <= splitValue.length && index <= 3; index += 1) {
      const fieldValue = splitValue[index - 1];
      const fieldKey = `field${index}`;

      if (this.state[fieldKey] !== fieldValue) {
        newState[fieldKey] = fieldValue;
        stateChanged = true;
      }
    }

    if (stateChanged) {
      this.setState(newState);
    }
  }

  getCode() {
    const { field1, field2, field3 } = this.state;
    return `${field1}-${field2}-${field3}`;
  }

  handleBlur(event) {
    let aFieldIsFocused = false;

    // eslint-disable-next-line no-unused-vars
    for (const index of [1, 2, 3]) {
      const node = document.getElementById(`sort-code-${index}`);

      if (document.activeElement === node) {
        aFieldIsFocused = true;
        break;
      }
    }

    if (!aFieldIsFocused) {
      this.props.onBlur(event);
    }
  }

  handleChange(event) {
    const { onChange } = this.props;

    const valueIsNumbers = /^\d?\d?$/.test(event.target.value);

    if (!valueIsNumbers || event.target.value.length > 2) {
      return;
    }

    const fieldId = event.target.id;
    const index = Number(fieldId[fieldId.length - 1]);

    this.setState({ [`field${index}`]: event.target.value }, () => {
      const newCode = this.getCode();
      onChange(newCode);
    });

    if (event.target.value.length === 2 && index < 3) {
      const nextFieldNode = document.getElementById(`sort-code-${index + 1}`);
      setTimeout(() => nextFieldNode.focus(), 1);
    }
  }

  renderField(index) {
    const handleBlur = (event) => this.handleBlur(event);
    const handleChange = (event) => this.handleChange(event);

    return (
      <FormControl
        className={styles.field}
        id={`sort-code-${index}`}
        max="99"
        maxLength="2"
        min="0"
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={this.props.onFocus}
        placeholder="XX"
        value={this.state[`field${index}`]}
      />
    );
  }

  render() {
    return (
      <FormGroup className={styles.fields}>
        {this.renderField(1)}
        <span className={styles.dash}> - </span>
        {this.renderField(2)}
        <span className={styles.dash}> - </span>
        {this.renderField(3)}
      </FormGroup>
    );
  }
}

SortCodeInput.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  value: PropTypes.string
};

SortCodeInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  value: ''
};

export default SortCodeInput;
