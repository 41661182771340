import { combineReducers } from 'redux';
import { LessonsActionTypes } from '../actions/lessons';
import { USER_LOGOUT } from '../actions/types';

const allLessonsInitialState = {
  lessons: [],
  isLoading: false,
  error: null
};

function allLessonsReducer(state = allLessonsInitialState, action) {
  switch (action.type) {
    case LessonsActionTypes.GET_COACH_COURSES_LESSONS:
      return { ...state, lessons: [], isLoading: true, error: false };
    case LessonsActionTypes.GET_COACH_COURSES_LESSONS_SUCCESS:
      return { ...state, lessons: action.payload, isLoading: false, error: false };
    case LessonsActionTypes.GET_COACH_COURSES_LESSONS_FAIL:
      return { ...state, lessons: [], isLoading: false, error: true };

    case USER_LOGOUT:
      return allLessonsInitialState;
    default:
      return state;
  }
}

const nextLessonsToStudyInitialState = {
  errorMessageSettingNextLessonToStudy: '',
  hasErrorSettingNextLessonToStudy: false,
  isSettingNextLessonToStudy: true,
  nextLessonToStudy: {}
};

function nextLessonToStudyReducer(state = nextLessonsToStudyInitialState, action) {
  switch (action.type) {
    case LessonsActionTypes.SET_NEXT_LESSON_TO_STUDY:
      return {
        ...state,
        isSettingNextLessonToStudy: true,
        hasErrorSettingNextLessonToStudy: false
      };
    case LessonsActionTypes.SET_NEXT_LESSON_TO_STUDY_SUCCESS:
      return {
        ...state,
        isSettingNextLessonToStudy: false,
        nextLessonToStudy: action.payload,
        hasErrorSettingNextLessonToStudy: false
      };
    case LessonsActionTypes.SET_NEXT_LESSON_TO_STUDY_FAIL:
      return {
        ...state,
        isSettingNextLessonToStudy: false,
        nextLessonToStudy: action.payload.lessonContent,
        hasErrorSettingNextLessonToStudy: true,
        errorMessageSettingNextLessonToStudy: action.payload.errorMessage
      };

    case USER_LOGOUT:
      return nextLessonsToStudyInitialState;
    default:
      return state;
  }
}

export default combineReducers({
  allLessons: allLessonsReducer,
  nextLessonToStudy: nextLessonToStudyReducer
});
