import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import Button from '../design-system/button/Button';
import styles from '../../style/containers/TestPage.module.scss';

const UNIQUE_STRING = 'ChatterboxIframe';
let iframeRef;
let observer;

const openLtiIframe = () => {
  axios.post('/api/sign_test_lti_call/', {}).then((response) => {
    const preppedRequest = response.data.prepped_request;
    const requestData = response.data.request_data;

    // Build the form
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', preppedRequest.url);
    form.setAttribute('target', UNIQUE_STRING);
    form.setAttribute('enctype', 'application/x-www-form-urlencoded');

    // add the parameters to the form
    requestData.forEach((entry) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', entry[0]);
      input.setAttribute('value', entry[1]);
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
  });
};

const TestCerego = ({ nextPage, prevPage, restart }) => {
  iframeRef = React.createRef();

  observer = new MutationObserver(() => {
    // Add the iframe with a unique name
    const iframe = iframeRef.current;

    if (iframe) {
      if (observer) {
        observer.disconnect();
        observer = undefined;
      }

      iframe.contentWindow.name = UNIQUE_STRING;
      openLtiIframe();
    }
  });
  observer.observe(document, { subtree: true, childList: true });

  return (
    <div className={styles.ceregoWrapper}>
      <iframe ref={iframeRef} title="Cerego iframe" />
      <p>Are you able to see the content of the iFrame above? If so, please continue to the next step.</p>
      <div className={styles.buttonContainer}>
        <Button outfit="secondaryButton" className={styles.nextButton} onClick={prevPage}>
          Back
        </Button>
        <Button outfit="regularButton" className={styles.nextButton} onClick={nextPage}>
          Next
        </Button>
        <Button outfit="secondaryButton" className={styles.nextButton} onClick={restart}>
          Restart
        </Button>
      </div>
    </div>
  );
};

TestCerego.propTypes = {
  nextPage: PropTypes.func.isRequired,
  prevPage: PropTypes.func.isRequired,
  restart: PropTypes.func.isRequired
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(TestCerego);
