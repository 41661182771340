import React from 'react';
import PropTypes from 'prop-types';

import generalStyles from '../GeneralStyles.module.scss';
import styles from './TellUsWhy.module.scss';

import IconHappyFace from '../../../assets/icons/icon_happy-face.svg';
import IconWorkSuitcase from '../../../assets/icons/icon_work-suitcase.svg';
import IconSelector from './components/IconSelector';
import { connect } from 'react-redux';
import { postPersonalInterest } from '../../../actions/new-onboarding';
// import ErrorToast from '../../design-system/toast/errorToast/ErrorToast';

const TellUsWhy = ({
  // hasPostSelectedInterestError,
  initialInformation,
  isInvalidToken,
  isLoadingPostSelectedInterest,
  moveToNextStep,
  postPersonalInterestAction
}) => {
  const handleSelection = (motivation) => {
    moveToNextStep();
    postPersonalInterestAction(
      {
        motivation
      },
      {
        email: initialInformation?.email,
        invitationToken: initialInformation?.invitationToken
      }
    );
  };

  return (
    <div className={generalStyles['content-wrapper']}>
      <h1 className={generalStyles['title']} id="tell-us-why-title">
        Let&apos;s tailor your {initialInformation?.language} experience, {initialInformation?.firstName}
      </h1>
      <p className={generalStyles['subtitle']}>Your answer will help your coach support your needs</p>
      <div className={styles['selectors-wrapper']}>
        <IconSelector
          alt="Personal reasons"
          disabled={isInvalidToken}
          icon={IconHappyFace}
          id="personal-reasons"
          isLoading={isLoadingPostSelectedInterest}
          label="I'm learning for personal reasons"
          onClick={() => handleSelection('personal')}
        />
        <IconSelector
          alt="Work reasons"
          disabled={isInvalidToken}
          icon={IconWorkSuitcase}
          id="work-reasons"
          isLoading={isLoadingPostSelectedInterest}
          label="I'm learning for work"
          onClick={() => handleSelection('professional')}
        />
      </div>
      {/* <ErrorToast errorMsg="We couldn't store your selection." show={hasPostSelectedInterestError} /> */}
    </div>
  );
};

TellUsWhy.propTypes = {
  hasPostSelectedInterestError: PropTypes.bool,
  isInvalidToken: PropTypes.bool,
  initialInformation: PropTypes.object.isRequired,
  isLoadingPostSelectedInterest: PropTypes.bool,
  moveToNextStep: PropTypes.func.isRequired,
  postPersonalInterestAction: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  hasPostSelectedInterestError: state.newOnboarding.personalInterest.hasPostSelectedInterestError,
  initialInformation: state.newOnboarding.initialInformation,
  isLoadingPostSelectedInterest: state.newOnboarding.personalInterest.isLoadingPostSelectedInterest
});

export default connect(mapStateToProps, {
  postPersonalInterestAction: postPersonalInterest
})(TellUsWhy);
