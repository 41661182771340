import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Button, Grid, Row } from 'react-bootstrap';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';

import { capitalize } from '../utils/string-helpers';
import TutorMatchProfile from '../components/tutor-match/TutorMatchProfile';
import Mixpanel from '../utils/mixpanel-helper.js';
import styles from '../style/containers/TutorMatch.module.scss';
import BookingForm from '../components/tutor-booking-page/BookingForm';
import { getUpcomingAppointmentsForStudent } from '../actions/appointments';
import getOrderInfo from '../actions/order-info';
import { refreshUser, hideOnboarding } from '../actions/user';
import ErrorAlert from '../components/uikit/ErrorAlert';
// import Particles from '../components/uikit/Confettis';
import ChargeUserModal from '../utils/charge-user-modal';
import { isTokenExpired } from '../actions/common';

const ORDER_SOURCE = 'tutor_match__top_up';
const DEFAULT_IS_GROUP_SESSION = false;
const DEFAULT_MEETING_PLACE = '';

class TutorMatch extends Component {
  constructor() {
    super();
    this.state = {
      showBooking: false,
      particles: [],
      isOpen: false,
      product: {},
      amount: 0,
      cost: 0,
      currency: '',
      trainingType: '',
      deliveryType: '',
      hideBooking: false
    };
    this.bookingContainerRef = React.createRef();
    this.chargeUserModal = React.createRef();
  }

  componentDidMount() {
    const { matchedTutor } = this.props;
    this.props.hideOnboarding(this.props.user.token);
    Mixpanel.identify(this.props.user.id);
    Mixpanel.track('[Match] Visited tutor page', {
      tutor: `${matchedTutor.first_name} ${matchedTutor.last_name}`
    });
    if (this.props.confetti) {
      this.confettis();
    }
  }

  handleResult = (paymentId, amount, cost, currency, errorMsg, confirm = false) => {
    const { user } = this.props;
    const data = {
      amount,
      cost,
      currency,
      user
    };
    if (errorMsg.length === 0) {
      this.join(paymentId, data, errorMsg, confirm);
    }
  };

  scrollToBooking = () => {
    try {
      window.scrollTo(0, this.bookingContainerRef.current.offsetTop);
      return true;
    } catch {
      return false;
    }
  };

  join = (paymentId, data, errorMsg, confirm) => {
    const student = data.user;
    const apt = this.props.productType;
    let params;

    if (confirm) {
      params = {
        student,
        appt_type: apt,
        payment_intent_id: paymentId,
        amount: data.amount,
        cost: data.cost,
        currency: data.currency,
        source: ORDER_SOURCE
      };
    } else {
      params = {
        student,
        appt_type: apt,
        payment_method_id: paymentId,
        amount: data.amount,
        cost: data.cost,
        currency: data.currency,
        source: ORDER_SOURCE
      };
    }

    const headers = {};
    if (student && student.token) {
      headers.Authorization = `Token ${student.token}`;
    }

    axios
      .post('/api/repurchase/', params, { headers })
      .then((response) => {
        if (response.data.requires_action) {
          this.chargeUserModal.current.handleAction(response);
        } else {
          const amount = parseFloat(data.amount).toFixed(0);
          const description = `${amount} x ${capitalize(apt.trainingType)} (${capitalize(apt.deliveryType)})`;
          toast.success(`You succesfully re-purchased ${description}`);
          this.props.getOrderInfo(student.token, this.showError);
          this.setState({ remainingCredits: amount });
          this.closeBuyModal();
          Mixpanel.track('Purchase Completed', { product: description });
          Mixpanel.people.track_charge(data.cost);
        }
      })
      .catch((error) => {
        if (errorMsg.length === 0) {
          toast.error(`${error.response.data.detail} If you need assistance, please contact us at support@chatterbox.io`);
          Mixpanel.track('Purchase Failed', {
            error: error.message,
            responseData: error.response.data.detail
          });
        }
      });
  };

  openPaymentModal = () => (
    <ChargeUserModal
      ref={this.chargeUserModal}
      handleResult={this.handleResult}
      product={this.state.product}
      amount={this.state.amount}
      cost={this.state.cost}
      currency={this.state.currency}
      trainingType={this.state.trainingType}
      deliveryType={this.state.deliveryType}
      closeBuyModal={this.closeBuyModal}
    />
  );

  closeBuyModal = () => {
    this.setState({ isOpen: false });
  };

  confettis() {
    const id = this.id;
    this.id += 0.5;

    this.setState({
      particles: [...this.state.particles, id]
    });
    setTimeout(() => {
      this.clean(id);
    }, 5000);
  }

  clean(id) {
    this.setState({
      particles: this.state.particles.filter((_id) => _id !== id)
    });
  }

  toogleBooking() {
    this.setState({ showBooking: !this.state.showBooking });
  }

  meetMoreTutors() {
    this.props.history.push('/tutor-list/');
  }

  finishBooking(resourceURL = false) {
    const { user } = this.props;
    this.props.getOrderInfo(user.token, this.showError);
    this.props.getUpcomingAppointmentsForStudent(user.token, user.id, this.showError);
    this.props.refreshUser(user.token);
    if (resourceURL) {
      this.props.history.push(resourceURL);
    } else {
      this.props.history.push('/');
    }
    window.scrollTo(0, 0);
  }

  showError(error) {
    this.setState({
      error: true,
      errorMsg: error
    });
  }

  showFirstTutor() {
    this.setState({
      showSecondTutor: false
    });
  }

  showSecondTutor() {
    this.setState({
      showSecondTutor: true
    });
  }

  render() {
    const { matchedTutor, user, bookable, productType, selectedLanguage, issue, tutorSecondChoice, match, bookingFrequency } = this.props;

    let tutor = matchedTutor;
    if (this.state.showSecondTutor) {
      tutor = tutorSecondChoice;
    }

    const {
      showBooking
      // particles
    } = this.state;
    // const { innerWidth } = window;

    // default value
    const isGroupSession = DEFAULT_IS_GROUP_SESSION;
    const meetingPlace = DEFAULT_MEETING_PLACE;

    // Selected in onboarding
    const deliveryType = productType.deliveryType;
    const trainingType = productType.trainingType;

    const languageMatch = tutor.languages ? tutor.languages.filter((x) => x.language === selectedLanguage.language) : [];

    const dialects = tutor.dialects ? tutor.dialects.filter((x) => x.language === selectedLanguage.language) : [];
    const prices = productType.prices;

    let copy = '';
    if (issue === 'no_available_tutor') {
      copy =
        'Unfortunately no tutor is available for any of the timings you requested.' +
        ' If you can be flexible with your schedule, here is a great match for you.';
    } else if (this.state.showSecondTutor) {
      copy = (
        <>
          <button className={styles.usefulCopyLink} onClick={this.showFirstTutor.bind(this)}>
            Take me back to the best match
          </button>
        </>
      );
    } else if (issue === 'better_match') {
      copy = (
        <>
          Statistics shows that learning with a like-minded tutor that shares your same interests facilitates your learning by 60%. Unfortunately the
          best match for you does not match any of the timings you requested. If you are not flexible with your schedule,&nbsp;
          <button className={styles.usefulCopyLink} onClick={this.showSecondTutor.bind(this)}>
            click here
          </button>
        </>
      );
    }

    return (
      <div className={styles.containerWrapper}>
        {/* {particles.map(id => (
          <Particles key={id} count={Math.floor(innerWidth / 20)} />
        ))} */}
        {copy !== '' && (
          <div className={styles.usefulCopyWrap}>
            <p className={styles.usefulCopy}>{copy}</p>
          </div>
        )}
        {this.state.isOpen && this.openPaymentModal()}
        <TutorMatchProfile
          tutor={{ name: tutor.first_name, id: tutor.id }}
          bio={tutor.bio}
          languages={tutor.languages}
          dialects={tutor.dialects}
          location={tutor.country}
          name={tutor.first_name}
          surName={tutor.last_name}
          photoURL={tutor.profile_picture}
          onStartLearning={this.toogleBooking.bind(this)}
          rating={tutor.calculated_rating}
          reviews={tutor.reviews}
          calendarMatrix={tutor.availability_matrix}
          userType={user.user_type}
          topReviews={tutor.top_reviews}
          professionalInterests={tutor.professional_interests}
          personalInterests={tutor.personal_interests}
          textOtherPersonal={tutor.text_other_personal}
          textOtherProfessional={tutor.text_other_professional}
          inReview={tutor.in_review}
          scrollToBooking={this.scrollToBooking}
          showBooking={showBooking}
          handleExpiredToken={this.props.tokenHandling}
          confetti={this.props.confetti}
          user={user}
        />
        {this.state.error && (
          <Grid className={styles.errorContainer}>
            <Row>
              <Col xs={12}>
                <ErrorAlert errorMsg={this.state.errorMsg} />
              </Col>
            </Row>
          </Grid>
        )}
        {showBooking && (
          <BookingForm
            productType={productType}
            creditsLeft={this.state.remainingCredits ? this.state.remainingCredits : productType.remainingCredits}
            bookableLessonNumbers={bookable.next_lesson === null ? null : [bookable.next_lesson]}
            nextLessons={bookable.next_lessons === null ? [] : bookable.next_lessons}
            bookableMomentStrings={bookable.datetimes}
            bookableDatesAndTimesISO={bookable.datetimes}
            courseId={bookable.course_id}
            deliveryType={deliveryType}
            dialects={dialects}
            finishBooking={this.finishBooking.bind(this)}
            meetingPlaceName={meetingPlace ? meetingPlace.name : ''}
            student={user}
            trainingType={trainingType}
            tutorId={tutor.id}
            tutorProfile={tutor}
            language={languageMatch[0]}
            isGroupSession={isGroupSession}
            history={this.props.history}
            purchase={(amount, cost, currency) => {
              this.setState({
                isOpen: !this.state.isOpen,
                product: productType,
                amount,
                cost,
                currency,
                trainingType,
                deliveryType,
                showBuyCredits: false,
                hideBooking: false
              });
            }}
            prices={prices}
            bookingContainerRef={this.bookingContainerRef}
            preChoosenDialect={match.params.dialect}
            allowProductTypeSelection
            showBuyCredits={this.state.showBuyCredits}
            hideBooking={this.state.hideBooking}
            bookingFrequency={bookingFrequency}
          />
        )}
        {!user.is_banco_unico_special_rules && (
          <>
            <h2 className={styles.centeredHeader}>Do you want to meet more coaches?</h2>
            <div className={styles.buttonWrapper}>
              <Button onClick={this.meetMoreTutors.bind(this)} className={styles.anotherTutorButton}>
                Meet more coaches
              </Button>
              <Button
                onClick={() => {
                  this.props.history.push('/onboarding');
                }}
                className={styles.anotherTutorButton}>
                Go back to the matching process
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    productType: state.selectedProductType,
    user: state.user,
    matchedTutor: state.selectedTutor.matchedTutor,
    issue: state.onboardingMatch.issue,
    tutorSecondChoice: state.onboardingMatch.tutorSecondChoice,
    confetti: state.onboardingMatch.confetti,
    bookable: state.bookable.available,
    selectedLanguage: state.selectedLanguage,
    bookingFrequency: state.bookingFrequency
  };
}

TutorMatch.propTypes = {
  user: PropTypes.object.isRequired,
  matchedTutor: PropTypes.object.isRequired,
  tutorSecondChoice: PropTypes.object.isRequired,
  issue: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
    location: PropTypes.object
  }).isRequired,
  getOrderInfo: PropTypes.func.isRequired,
  getUpcomingAppointmentsForStudent: PropTypes.func.isRequired,
  refreshUser: PropTypes.func.isRequired,
  bookable: PropTypes.bool.isRequired,
  confetti: PropTypes.bool.isRequired,
  productType: PropTypes.object.isRequired,
  hideOnboarding: PropTypes.func.isRequired,
  tokenHandling: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired,
  bookingFrequency: PropTypes.any.isRequired
};

TutorMatch.defaultProps = {
  issue: undefined
};

function tokenHandling(err) {
  return async (dispatch) => {
    if (!isTokenExpired(dispatch, err)) {
      Sentry.captureException(err);
    }
  };
}

export default connect(mapStateToProps, {
  getUpcomingAppointmentsForStudent,
  getOrderInfo,
  refreshUser,
  hideOnboarding,
  tokenHandling
})(TutorMatch);
