import { GET_APPOINTMENT_TO_RATE, GET_APPOINTMENT_TO_RATE_ERROR } from '../actions/types';

export default function appointmentToRate(state = null, action) {
  switch (action.type) {
    case GET_APPOINTMENT_TO_RATE:
      return action.payload;
    case GET_APPOINTMENT_TO_RATE_ERROR:
      return null;
    default:
      return state;
  }
}
