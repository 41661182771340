import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import axios from 'axios';

import { renderField } from '../../utils/form-helpers';
import { validate, minLength8, required } from './validate.js';
import { getQueryParamValue } from '../../utils/url-helpers.js';
import appStyles from '../../style/containers/App.module.scss';
import formStyles from '../../style/utils/_form.module.scss';

import Button from '../design-system/button/Button';
import Alert from '../design-system/alert/Alert';

function sendPasswordReset(newPassword1, newPassword2, firstName, lastName, uid, token, successCallback, errorCallback) {
  const context = {
    new_password1: newPassword1,
    new_password2: newPassword2,
    uid,
    token
  };

  if (firstName !== undefined && lastName !== undefined) {
    context.first_name = firstName;
    context.last_name = lastName;
  }

  axios
    .post('/rest-auth/password/reset/confirm/', context)
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error);
    });
}

class PasswordResetForm extends Component {
  constructor(props) {
    // Prevent users to reset password in staging, redirect them to production
    /* if (window.location.href.includes('staging')) {
      window.location.href = 'https://app.chatterbox.io/reset';
    } */

    super(props);

    let changeName = false;
    if (this.props.location && this.props.location.search) {
      changeName = getQueryParamValue('change-name') === '1';
    }

    this.state = {
      error: null,
      isLoading: true,
      isSubmitted: false,
      message: null,
      changeName,
      showPassword1: false,
      showPassword2: false
    };
  }

  componentDidMount() {
    const { token, uid } = this.props.match.params;
    this.checkToken(token, uid);
  }

  checkToken = (token, userIDBase64) => {
    axios
      .post('/reset/check-token/', { token, user_id_base64: userIDBase64 })
      .then((response) => {
        if (response.data.valid !== true) {
          this.setState({ error: true, message: 'Invalid value' });
        }
      })
      .catch(() => this.setState({ error: true }))
      .then(() => this.setState({ isLoading: false }));
  };

  showSuccessState = () => {
    this.setState({ error: null, isLoading: false, isSubmitted: true });
  };

  showErrorState = (error) => {
    if (error && error.response && error.response.data) {
      const errorObj = error.response.data?.new_password2;
      // checks whether the error object's first property is an array, in which case the error carries a message, which is then displayed. Otherwise generic "Please try again..." is displayed
      const message = errorObj ? errorObj[0] : 'Please try again and contact us if the problem persists.';
      this.setState({
        error: error.response.data,
        message
      });
    }
    this.setState({ isLoading: false });
  };

  resetPassword = (formValues) => {
    this.setState({ isLoading: true });

    sendPasswordReset(
      formValues.new_password1,
      formValues.new_password2,
      formValues.first_name,
      formValues.last_name,
      this.props.match.params.uid,
      this.props.match.params.token,
      this.showSuccessState,
      this.showErrorState
    );
  };

  changeModePassword1 = () => {
    this.setState({ showPassword1: !this.state.showPassword1 });
  };

  changeModePassword2 = () => {
    this.setState({ showPassword2: !this.state.showPassword2 });
  };

  render() {
    const { handleSubmit } = this.props;

    let content;

    if (this.state.isSubmitted) {
      toast.success('Success! Your password has been reset.');
      this.props.history.push('/');
    } else {
      content = (
        <div className={this.state.isLoading ? appStyles.loading : null}>
          <div style={{ marginBottom: '22px' }}>
            {this.state.error && this.state.message === 'Invalid value' && (
              <Alert
                className={formStyles.alert}
                bsStyle="danger"
                text={
                  <>
                    <strong>
                      Your reset link has expired. Make a new request to <Link to="/reset/">reset password</Link>.
                    </strong>
                    <br />
                    Contact us if the problem persists at <a href="mailto:support@chatterbox.io">support@chatterbox.io</a>.
                  </>
                }
                type="danger"
              />
            )}
            {this.state.error && this.state.message !== 'Invalid value' && (
              <Alert
                className={formStyles.alert}
                bsStyle="danger"
                text={
                  <>
                    <strong>Your new password could not be saved. {this.state.message}</strong> <br />
                    Contact us if the problem persists at <a href="mailto:support@chatterbox.io">support@chatterbox.io</a>.
                  </>
                }
                type="danger"
              />
            )}
          </div>
          <div>
            <div style={{ marginBottom: '22px' }}>
              {this.state.changeName && <p>Please enter your name and then your new password twice so we can verify you typed it in correctly.</p>}
              {!this.state.changeName && <p>Please enter your new password twice so we can verify you typed it in correctly.</p>}
            </div>
            {this.state.changeName && (
              <div>
                <Field label="First Name" name="first_name" type="text" component={renderField} validate={[required]} />
                <Field label="Last Name" name="last_name" type="text" component={renderField} validate={[required]} />
              </div>
            )}
            <div style={{ marginBottom: '22px' }}>
              <Field
                label="New Password"
                name="new_password1"
                component={renderField}
                validate={[minLength8]}
                showEye
                type={this.state.showPassword1 ? 'text' : 'password'}
                icon={this.state.showPassword1 ? 'eye-close' : 'eye-open'}
                changeMode={this.changeModePassword1}
              />
            </div>
            <div style={{ marginBottom: '22px' }}>
              <Field
                label="Confirm Password"
                name="new_password2"
                component={renderField}
                validate={[minLength8]}
                showEye
                type={this.state.showPassword2 ? 'text' : 'password'}
                icon={this.state.showPassword2 ? 'eye-close' : 'eye-open'}
                changeMode={this.changeModePassword2}
              />
            </div>
            <Button bsStyle="primary" type="submit">
              Submit
            </Button>
          </div>
        </div>
      );
    }

    return (
      <form
        style={{ marginLeft: '10vw', marginRight: '10vw' }}
        className={formStyles.form}
        onSubmit={this.state.isSubmitted ? null : handleSubmit(this.resetPassword)}>
        <h1>Create New Password</h1>
        {content}
      </form>
    );
  }
}

PasswordResetForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default reduxForm({
  validate,
  form: 'PasswordResetForm'
})(PasswordResetForm);
