import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../design-system/modal/Modal';
import Button from '../design-system/button/Button';

import styles from './ConfirmStatusChange.module.scss';

import { ReactComponent as CompleteTaskLady } from '../../assets/people/complete-task-lady.svg';
import { ReactComponent as TechnicalErrorLady } from '../../assets/people/technical-error-lady.svg';
import { ReactComponent as NoShowLady } from '../../assets/people/no-show-lady.svg';
import { pastAppointmentStatus } from '../../domains/appointments/status';

const ConfirmStatusChange = ({ isLoading, onConfirm, onClose, status }) => {
  const [technicalDescription, setTechnicalDescription] = useState('');

  if (status === pastAppointmentStatus.technicalIssues.value)
    return (
      <Modal
        className={styles.container}
        withCloseButton={false}
        onModalClose={onClose}
        content={
          <div className={styles.container}>
            <div className={styles['person-container']}>
              <TechnicalErrorLady />
            </div>
            <h1>Technical error?</h1>
            <p>
              Please give a short description of the issue here, and a member of the Chatterbox team will follow up! You will still be paid in full.
            </p>
            <p>
              <strong>Note: </strong>
              only use this page to report learner-side technical issues that prevented the session from taking place. If there were technical issues
              on your side, please contact us at community@chatterbox.io.
            </p>
            <textarea
              className={styles.textarea}
              onChange={(evt) => setTechnicalDescription(evt?.target?.value)}
              value={technicalDescription}
              rows={5}
            />
            <div className={styles.buttonsWrapper}>
              <Button outfit="transparentButton" onClick={onClose} className={styles.closeButton}>
                Cancel
              </Button>
              <Button
                disabled={!technicalDescription?.length}
                onClick={() => onConfirm(technicalDescription)}
                loading={isLoading}
                className={styles.confirmButton}>
                Confirm
              </Button>
            </div>
          </div>
        }
      />
    );

  if (status === pastAppointmentStatus.studentNoShow.value)
    return (
      <Modal
        className={styles.container}
        withCloseButton={false}
        onModalClose={onClose}
        content={
          <div className={styles.container}>
            <div className={styles['person-container']}>
              <NoShowLady />
            </div>
            <h1>Learner no-show?</h1>
            <p>
              Please click to confirm that your learner was absent, and the Chatterbox team will follow up with any issues. You will still be paid in
              full.
            </p>
            <div className={styles.buttonsWrapper}>
              <Button outfit="transparentButton" onClick={onClose} className={styles.closeButton}>
                Cancel
              </Button>
              <Button onClick={() => onConfirm()} loading={isLoading} className={styles.confirmButton}>
                Confirm
              </Button>
            </div>
          </div>
        }
      />
    );

  return (
    <Modal
      className={styles.container}
      withCloseButton={false}
      onModalClose={() => {
        this.setState({ renderTeamsModal: false });
      }}
      content={
        <div className={styles.container}>
          <div className={styles['person-container']}>
            <CompleteTaskLady />
          </div>
          <h1>Learner attended!</h1>
          <p>Please click to confirm that your learner attended all or the majority of the session.</p>
          <p>
            If you know that there were technical issues that prevented them from attending the majority of the session, please close and select
            ‘technical issues’.
          </p>
          <div className={styles.buttonsWrapper}>
            <Button outfit="transparentButton" onClick={onClose} className={styles.closeButton}>
              Cancel
            </Button>
            <Button onClick={() => onConfirm()} loading={isLoading} className={styles.confirmButton}>
              Confirm
            </Button>
          </div>
        </div>
      }
    />
  );
};

ConfirmStatusChange.propTypes = {
  isLoading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
};

export default ConfirmStatusChange;
