export const MESSAGE_TYPES = Object.freeze({
  text: 'text',
  photo: 'photo',
  file: 'file'
});

export const DEFAULT_MESSAGES = Object.freeze({
  firstTime: (name) => {
    if (!name)
      return 'This is the beginning of this conversation. You can send messages, images and files to prepare for your classes and discuss your schedule. Happy learning!';

    return `This is the beginning of your conversation with ${name}. You can send messages,
    images and files to prepare for your Live Practices and discuss your schedule. Happy learning!`;
  }
});
