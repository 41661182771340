import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CardWithHeader from '../../../design-system/card/card-with-header/CardWithHeader';
import ProgressBar from '../../../design-system/progress-bar/ProgressBar';

import styles from './SelfStudyCard.module.scss';
import Tooltip from '../../../design-system/tooltip/Tooltip';
import { setSelectedUnit } from '../../../../actions/lessons';
import mixpanelHelper from '../../../../utils/mixpanel-helper';
import IconBookGray from '../../../../assets/icons/icon_book-gray.svg';
import Button from '../../../design-system/button/Button';

const SelfStudyCard = ({
  courseDetailsRef,
  finalExam,
  hasCompletedFinalExam,
  hasCompletedPracticeExam,
  hasFinishedPlacementTest,
  isGettingCourseDetails,
  isGettingEnrolledCourses,
  isRetrievingPlacementTest,
  isSettingNextLessonToStudy,
  isTimeForExam,
  nextLessonToStudy,
  practiceExam,
  sendToPlacementTest,
  setSelectedUnitAction,
  sendToLessonsTab,
  user,
  isMobile
}) => {
  const handleClick = () => {
    if (!courseDetailsRef?.current?.scrollIntoView && !isMobile) return;

    if (sendToLessonsTab) sendToLessonsTab();
    courseDetailsRef?.current?.scrollIntoView({ behavior: 'smooth' });
    setSelectedUnitAction({ token: user.token, unit: nextLessonToStudy });
  };

  const handleClickWhenPracticeOrFinal = (assessment) => {
    if (!courseDetailsRef?.current?.scrollIntoView) return;
    courseDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
    setSelectedUnitAction({ token: user.token, unit: assessment });
  };

  if (!hasFinishedPlacementTest)
    return (
      <CardWithHeader
        title="1. Self Study"
        disabled
        type="black"
        isLoading={isGettingEnrolledCourses || isGettingCourseDetails || isRetrievingPlacementTest}
        cardContent={
          <div className={styles['blocked-self-study-card']}>
            <img src={IconBookGray} alt="test" />
            <h3>Take the placement test to unlock your lesson progress</h3>
            <Button bold outfit="greenButton" onClick={sendToPlacementTest}>
              PLACEMENT TEST
            </Button>
          </div>
        }
      />
    );

  if (isTimeForExam && !hasCompletedPracticeExam)
    return (
      <CardWithHeader
        title="1. Self Study"
        onClick={() => {
          handleClickWhenPracticeOrFinal(practiceExam);
          mixpanelHelper.track('Click', { button: 'take_practice_exam' });
        }}
        isLoading={isGettingEnrolledCourses || isGettingCourseDetails || isSettingNextLessonToStudy}
        type="black"
        testId="practice-exam-card"
        cardContent={
          <div className={styles['self-study-card-without-tooltip']}>
            <h3>Take the Practice Exam</h3>
          </div>
        }
      />
    );

  if (isTimeForExam && hasCompletedPracticeExam && !hasCompletedFinalExam)
    return (
      <CardWithHeader
        title="1. Self Study"
        onClick={() => {
          handleClickWhenPracticeOrFinal(finalExam);
          mixpanelHelper.track('Click', { button: 'take_final_exam' });
        }}
        isLoading={isGettingEnrolledCourses || isGettingCourseDetails || isSettingNextLessonToStudy}
        type="black"
        testId="final-exam-card"
        cardContent={
          <div className={styles['self-study-card-without-tooltip']}>
            <h3>Take the Final Exam</h3>
          </div>
        }
      />
    );

  if (isTimeForExam && hasCompletedPracticeExam && hasCompletedFinalExam)
    return (
      <CardWithHeader
        title="1. Self Study"
        isLoading={isGettingEnrolledCourses || isGettingCourseDetails || isSettingNextLessonToStudy}
        type="black"
        cardContent={
          <div className={styles['self-study-card-without-tooltip']}>
            <h3>Completed!</h3>
          </div>
        }
      />
    );
  return (
    <CardWithHeader
      title="1. Self Study"
      onClick={() => {
        handleClick();
        mixpanelHelper.track('Click', { button: 'study_lesson' });
      }}
      isLoading={isGettingEnrolledCourses || isGettingCourseDetails || isSettingNextLessonToStudy}
      type="black"
      cardContent={
        <div className={styles['self-study-card']}>
          <h2>
            Lesson <span id="dont-translate">{nextLessonToStudy?.number}</span>
          </h2>
          <p id="dont-translate">{nextLessonToStudy?.name}</p>
          <div className={styles['progress-bar--wrapper']}>
            <ProgressBar progress={nextLessonToStudy?.progress} reference={0.7} />
          </div>
          <div className={styles.tooltip}>
            <Tooltip tooltipLabel="Always try to finish at least 70% of your self-study before your Live Practice" backgroundColor="white" />
          </div>
        </div>
      }
    />
  );
};

SelfStudyCard.propTypes = {
  courseDetailsRef: PropTypes.object,
  finalExam: PropTypes.object,
  hasCompletedPracticeExam: PropTypes.bool,
  hasCompletedFinalExam: PropTypes.bool,
  hasFinishedPlacementTest: PropTypes.bool,
  isGettingCourseDetails: PropTypes.bool,
  isMobile: PropTypes.bool,
  isGettingEnrolledCourses: PropTypes.bool,
  isRetrievingPlacementTest: PropTypes.bool,
  isSettingNextLessonToStudy: PropTypes.bool,
  isTimeForExam: PropTypes.bool,
  nextLessonToStudy: PropTypes.object,
  practiceExam: PropTypes.object,
  sendToPlacementTest: PropTypes.func,
  sendToLessonsTab: PropTypes.func,
  setSelectedUnitAction: PropTypes.func.isRequired,
  user: PropTypes.object
};

SelfStudyCard.defaultProps = {
  courseDetailsRef: {}
};

const mapStateToProps = (state) => ({
  finalExam: state.courses.selectedCourseDetails.courseDetails.finalExam,
  isRetrievingPlacementTest: state.newOnboarding.placementTest.isRetrievingPlacementTest,
  nextLessonToStudy: state.lessons.nextLessonToStudy.nextLessonToStudy,
  hasFinishedPlacementTest: state.newOnboarding.placementTest.hasFinishedPlacementTest,
  isGettingCourseDetails: state.courses.selectedCourseDetails.isGettingCourseDetails,
  isGettingEnrolledCourses: state.courses.enrolledCourses.isGettingEnrolledCourses,
  isSettingNextLessonToStudy: state.lessons.nextLessonToStudy.isSettingNextLessonToStudy,
  practiceExam: state.courses.selectedCourseDetails.courseDetails.practiceExam,
  user: state.user
});

export default connect(mapStateToProps, {
  setSelectedUnitAction: setSelectedUnit
})(SelfStudyCard);
