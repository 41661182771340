import { isTokenExpired } from './common';
import { actionCreatorsFactory, asyncActionType, createActionTypes } from '../utils/action-helpers';
import OrdersAPI from '../services/orders';
import { getIntroClass } from './sessions';
import { sendSentryError } from '../setup/sentry';
import { loginActions } from './new-login';

export const OrdersActionTypes = createActionTypes('Orders', [asyncActionType('GET_ORDER'), asyncActionType('GET_ALL_ORDERS')]);

export const getAllOrdersActions = actionCreatorsFactory(
  [OrdersActionTypes.GET_ALL_ORDERS, OrdersActionTypes.GET_ALL_ORDERS_SUCCESS, OrdersActionTypes.GET_ALL_ORDERS_FAIL],
  'getAllOrders'
);

export const getOrderActions = actionCreatorsFactory(
  [OrdersActionTypes.GET_ORDER, OrdersActionTypes.GET_ORDER_SUCCESS, OrdersActionTypes.GET_ORDER_FAIL],
  'getOrder'
);

export default function getOrderInfo(token, errorCallback, successCallback) {
  return async (dispatch) => {
    try {
      dispatch(getAllOrdersActions.getAllOrders());
      dispatch(getOrderActions.getOrder());
      const { data: allOrders } = await OrdersAPI.getAllOrders(token);
      dispatch(getAllOrdersActions.getAllOrdersSuccess(allOrders));
      const { data: orders } = await OrdersAPI.getOrder(token);
      dispatch(getOrderActions.getOrderSuccess(orders));
      dispatch(getIntroClass({ token, languageId: orders?.active_orders[0]?.order_appointment_types[0]?.language_id }));
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      sendSentryError({ err, context: 'getOrderInfo' });
      dispatch(loginActions.postLogInFail('Something went wrong while attempting to log in.'));
      if (!isTokenExpired(dispatch, err)) {
        dispatch(getOrderActions.getOrderFail(err));
        if (errorCallback) errorCallback();
      }
    }
  };
}
