import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CoachCard from './components/CoachCard';
import IntroductoryLessonBookingModal from '../../new-booking/components/booking/IntroductoryLessonBookingModal';

import Information from './components/Information/Information';
import { postPickCoach } from '../../../actions/new-onboarding';

import { getTutorsWithLanguageAndProductTypeWithoutToken } from '../../../actions/tutors';
import { setOpenBookingIntroClassModal } from '../../../actions/new-booking';

import generalStyles from '../GeneralStyles.module.scss';
import styles from './Coaches.module.scss';
import { setSelectedTutor } from '../../../actions/selected-tutor';
import ErrorToast from '../../design-system/toast/errorToast/ErrorToast';
import CoachFilterByAvailability from './components/CoachFilterByAvailability';

const Coaches = ({
  getTutorsErrorMessage,
  getTutorsWithLanguageAndProductTypeWithoutTokenAction,
  hasGetTutorsError,
  hasPostSelectedCoachError,
  isGettingTutors,
  initialInformation,
  moveToNextStep,
  shouldOpenBookingIntroClassModal,
  postPickCoachAction,
  previousOnboarding,
  setOpenBookingIntroClassModalAction,
  selectedTutor,
  setSelectedTutorAction,
  tutors,
  user
}) => {
  const [tutorsList, setTutorsList] = useState([]);
  const informationRef = useRef(null);

  useEffect(() => {
    getTutorsWithLanguageAndProductTypeWithoutTokenAction(
      { languageId: initialInformation?.languageId },
      { email: initialInformation?.email, password_token: initialInformation?.invitationToken }
    );
  }, []);

  useEffect(() => {
    if (tutors.length) setTutorsList(tutors);
  }, [tutors]);

  useEffect(() => {
    if (selectedTutor?.id && selectedTutor?.name && user?.token) setOpenBookingIntroClassModalAction(true);
  }, [selectedTutor]);

  const setSelectedTutorBasedOnPreviousOnboarding = () => {
    if (selectedTutor?.id && selectedTutor?.name) return;
    if (!previousOnboarding?.selected_coach) return;

    const previouslySelectedTutor = tutors.find((tutor) => tutor.id === previousOnboarding.selected_coach);
    if (previouslySelectedTutor) setSelectedTutorAction(previouslySelectedTutor);
  };

  useEffect(() => {
    setSelectedTutorBasedOnPreviousOnboarding();
  }, [selectedTutor, previousOnboarding, tutors]);

  const handleBook = (coachData) => {
    const hasUserLoggedIn = Boolean(user?.token);

    if (hasUserLoggedIn) {
      setSelectedTutorAction(coachData);
      setOpenBookingIntroClassModalAction(true);
    } else {
      moveToNextStep();
      postPickCoachAction(
        {
          coachData
        },
        {
          email: initialInformation?.email,
          token: initialInformation?.invitationToken
        }
      );
    }
  };

  const handleScrollTo = (ref) => ref.current.scrollIntoView({ behavior: 'smooth' });

  return (
    <div className={generalStyles['content-wrapper']}>
      <h1 className={generalStyles['title']} id="coaches-title">
        Get started with our {initialInformation?.language} coaches
      </h1>
      <p className={generalStyles['subtitle']}>
        Choose a coach to book your{' '}
        <span role="button" onClick={() => handleScrollTo(informationRef)} className={styles['link']}>
          Meet Your Coach session
        </span>
      </p>
      <div className={styles.filter}>
        <CoachFilterByAvailability tutors={tutorsList} setUpdatedTutorsList={setTutorsList} />
      </div>
      <div className={styles['coach-cards-wrapper']}>
        {isGettingTutors || !tutorsList.length ? (
          <>
            <CoachCard isLoading={true} />
            <CoachCard isLoading={true} />
          </>
        ) : (
          tutorsList.map((tutor, index) => <CoachCard key={index} index={index} tutor={tutor} onBook={handleBook} />)
        )}
      </div>
      <ErrorToast errorMsg="We couldn't store your selection." show={hasPostSelectedCoachError} />
      <ErrorToast errorMsg={getTutorsErrorMessage} show={hasGetTutorsError} />
      <Information componentRef={informationRef} />
      {shouldOpenBookingIntroClassModal && <IntroductoryLessonBookingModal enableCoachChangeWhenUnavailable={false} />}
    </div>
  );
};

Coaches.propTypes = {
  getTutorsErrorMessage: PropTypes.string,
  getTutorsWithLanguageAndProductTypeWithoutTokenAction: PropTypes.func.isRequired,
  hasGetTutorsError: PropTypes.bool,
  hasPostSelectedCoachError: PropTypes.bool,
  isGettingTutors: PropTypes.bool,
  initialInformation: PropTypes.object,
  moveToNextStep: PropTypes.func.isRequired,
  shouldOpenBookingIntroClassModal: PropTypes.bool.isRequired,
  postPickCoachAction: PropTypes.func.isRequired,
  previousOnboarding: PropTypes.object,
  selectedTutor: PropTypes.object,
  setOpenBookingIntroClassModalAction: PropTypes.func.isRequired,
  setSelectedTutorAction: PropTypes.func.isRequired,
  tutors: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  getTutorsErrorMessage: state.tutors.getTutorsErrorMessage,
  hasGetTutorsError: state.tutors.hasGetTutorsError,
  hasPostSelectedCoachError: state.newOnboarding.pickCoach.hasPostSelectedCoachError,
  isGettingTutors: state.tutors.isGettingTutors,
  initialInformation: state.newOnboarding.initialInformation,
  isSignedIn: state.newLogin.signIn.afterUserInteraction.isSignedIn,
  shouldOpenBookingIntroClassModal: state.newBooking.bookingLessons.shouldOpenBookingIntroClassModal,
  previousOnboarding: state.newOnboarding.previousOnboarding.previousOnboarding,
  selectedTutor: state.selectedTutor.selectedTutor,
  tutors: state.tutors.tutors,
  user: state.user
});

export default connect(mapStateToProps, {
  getTutorsWithLanguageAndProductTypeWithoutTokenAction: getTutorsWithLanguageAndProductTypeWithoutToken,
  postPickCoachAction: postPickCoach,
  setOpenBookingIntroClassModalAction: setOpenBookingIntroClassModal,
  setSelectedTutorAction: setSelectedTutor
})(Coaches);
