import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './PasswordFormField.module.scss';

import IconEyeOpen from '../../../assets/icons/icon_eye-open.svg';
import IconEyeClose from '../../../assets/icons/icon_eye-close.svg';
import RegularFormField from '../regular-field/RegularFormField';

const PasswordFormField = ({ disabled, id, initialValue, invalidMessage, isValid, label, onChange, validators, value }) => {
  const [showPassword, setShowPassword] = useState(false);
  const fieldValue = value || initialValue || '';

  const handleShowPassword = () => {
    if (!fieldValue) return;
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className={styles['password-field-wrapper']}>
      <RegularFormField
        disabled={disabled}
        label={label}
        id={id}
        invalidMessage={invalidMessage}
        validators={validators}
        isValid={isValid}
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}
        value={fieldValue}
        icon={
          showPassword ? (
            <button type="button" onClick={handleShowPassword}>
              <img src={IconEyeClose} alt="Show password" />
            </button>
          ) : (
            <button type="button" onClick={handleShowPassword}>
              <img src={IconEyeOpen} alt="Hide password" />
            </button>
          )
        }
      />
    </div>
  );
};

PasswordFormField.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  initialValue: PropTypes.string,
  invalidMessage: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func,
  validation: PropTypes.object,
  validators: PropTypes.array,
  value: PropTypes.string.isRequired
};

PasswordFormField.defaultProps = {
  isValid: true,
  validators: []
};

export default PasswordFormField;
