import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Mixpanel from '../utils/mixpanel-helper.js';

import styles from '../style/containers/Cerego.module.scss';
import appStyles from '../style/containers/App.module.scss';

class Cerego extends Component {
  state = {
    isLoading: true,
    timeSpent: 0
  };

  componentDidMount() {
    setInterval(() => {
      this.setState({ timeSpent: this.state.timeSpent + 0.5 });
    }, 30000);
  }

  componentWillUnmount() {
    const { user } = this.props;
    Mixpanel.identify(user.id);
    Mixpanel.track('Time spent on Study Env', {
      hoursSpent: parseInt(this.state.timeSpent / 60, 10),
      minutesSpent: this.state.timeSpent
    });
  }

  render() {
    const { match } = this.props;
    const { isLoading } = this.state;
    const ceregoBaseUrl = match.params.allReadyJoined ? 'https://cerego.com/courses/' : 'https://cerego.com/join/chatterbox/';
    const iframeSrc = `${ceregoBaseUrl}${match.params.ceregoUrl}`;
    return (
      <div className={styles.iframeWrapper}>
        {isLoading && <div className={[appStyles.loader, styles.iframeLoader].join(' ')} />}
        <iframe
          title="cerego"
          src={iframeSrc}
          className={[styles.iframe, isLoading ? styles.iframeHidden : undefined].join(' ')}
          onLoad={() => this.setState({ isLoading: false })}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

Cerego.propTypes = {
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

Cerego.defaultProps = {};

export default connect(mapStateToProps, {})(Cerego);
