import React from 'react';
import { Alert } from 'react-bootstrap';

const ErrorAlertBrc = () => (
  <div>
    <Alert bsStyle="danger" key={1}>
      <p>
        Sorry the free Live Practices program ended on Feb 5th. Thank you for participating! If you&apos;d like to continue, you can purchase credits{' '}
        <a href="www.chatterbox.io/for-individuals">here</a>. Use LEARNLANGUAGES2020 promo code for 10% off.
      </p>
      <p>
        If you need assistance, please contact us at <a href="mailto:hello@chatterbox.io">hello@chatterbox.io</a>.
      </p>
    </Alert>
  </div>
);

ErrorAlertBrc.propTypes = {};

ErrorAlertBrc.defaultProps = {};

export default ErrorAlertBrc;
