import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import SignUp from './SignUp';
import LogIn from './LogIn';
import AlreadyIn from './AlreadyIn';

const Connect = ({ moveToNextStep, previousOnboarding, user }) => {
  const [manuallySetLogIn, setManuallySetLogIn] = useState(false);

  if (user?.token) return <AlreadyIn moveToNextStep={moveToNextStep} />;

  if (previousOnboarding?.has_created_password || manuallySetLogIn)
    return <LogIn moveToNextStep={moveToNextStep} setManuallySetLogIn={setManuallySetLogIn} />;

  return <SignUp moveToNextStep={moveToNextStep} setManuallySetLogIn={setManuallySetLogIn} />;
};

Connect.propTypes = {
  moveToNextStep: PropTypes.func,
  previousOnboarding: PropTypes.bool,
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  previousOnboarding: state.newOnboarding.previousOnboarding.previousOnboarding,
  user: state.user
});

export default connect(mapStateToProps)(Connect);
