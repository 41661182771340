import React from 'react';
import PropTypes from 'prop-types';

import styles from './BookingSummaryItem.module.scss';
import { convertStringsOfDateAndTimeToDate, convertToLocaleAndFormat } from '../../../utils/time-helpers';

import IconCheck from '../../../assets/icons/icon_check.svg';
import mixpanelHelper from '../../../utils/mixpanel-helper';

const BookingSummaryItem = ({
  active,
  borderColor,
  cancel,
  disabled,
  done,
  doneScheduling,
  finalTimestamp,
  interimTimestamp,
  introClass,
  lesson,
  onlyOneItem,
  removeNewScheduleFromFinalList,
  setNewActiveAppointment,
  testId,
  timezone
}) => {
  const handleDoneRescheduling = (evt) => {
    evt.stopPropagation();
    doneScheduling();
    mixpanelHelper.track('Clicked Confirm');
  };

  const handleReschedulingRemoval = (selectedLesson) => {
    removeNewScheduleFromFinalList(selectedLesson);
    mixpanelHelper.track('Removed Selected Booking');
  };

  if (cancel && introClass)
    return (
      <li className={[styles.lessonItem, styles.cancelLessonItem].join(' ')} key={lesson.id}>
        <div className={styles.cancelLessonInfo}>
          <span className={styles.lessonNumber}>Intro Class:</span>
          <span className={styles.lessonDate} id="dont-translate">
            {lesson?.date && convertToLocaleAndFormat(convertStringsOfDateAndTimeToDate(lesson?.date, lesson?.start_time), timezone)}
          </span>
        </div>
      </li>
    );

  if (cancel) {
    return (
      <li className={[styles.lessonItem, styles.cancelLessonItem].join(' ')} key={lesson.id}>
        <div className={styles.cancelLessonInfo}>
          {Boolean(lesson?.number) && (
            <span className={styles.lessonNumber}>
              Lesson <span id="dont-translate">{lesson.number}</span>:
            </span>
          )}
          <span className={styles.lessonDate} id="dont-translate">
            {lesson.appointment_object &&
              convertToLocaleAndFormat(
                convertStringsOfDateAndTimeToDate(lesson.appointment_object.date, lesson.appointment_object.start_time),
                timezone
              )}
          </span>
        </div>
        {!onlyOneItem && (
          <div className={styles.imageWrapper}>
            <button className={styles.cancelButton} onClick={() => handleReschedulingRemoval(lesson)}>
              Remove
            </button>
          </div>
        )}
      </li>
    );
  }

  const whatTimestampToShowForIntroSession = () => {
    if (finalTimestamp) return convertToLocaleAndFormat(new Date(finalTimestamp), timezone);
    return convertToLocaleAndFormat(convertStringsOfDateAndTimeToDate(lesson?.date, lesson?.start_time), timezone);
  };

  if (introClass) {
    return (
      <div className={[active && styles.active, disabled && styles.disabled, styles.item].join(' ')} disabled={disabled}>
        <li className={[styles.lessonItem, styles[borderColor]].join(' ')}>
          <span className={styles.lessonNumber}>MYC Session</span>
          <span className={styles.lessonDate} id="dont-translate">
            {whatTimestampToShowForIntroSession()}
          </span>
        </li>
      </div>
    );
  }

  const whatTimestampToShow = () => {
    if (finalTimestamp) return convertToLocaleAndFormat(new Date(finalTimestamp), timezone);
    if (active && interimTimestamp) return convertToLocaleAndFormat(new Date(interimTimestamp), timezone);
    return convertToLocaleAndFormat(
      convertStringsOfDateAndTimeToDate(lesson?.appointment_object?.date, lesson?.appointment_object?.start_time),
      timezone
    );
  };

  const whatButtonToShow = () => {
    if (done)
      return (
        <button className={styles.checkButton} onClick={() => handleReschedulingRemoval(lesson)}>
          <img src={IconCheck} alt="remove from final list" />
        </button>
      );
    return (
      <button
        disabled={!active || !interimTimestamp}
        className={styles.confirmButton}
        onClick={handleDoneRescheduling ? handleDoneRescheduling : undefined}
        data-testid={`${testId}-confirm`}>
        Confirm
      </button>
    );
  };

  return (
    <div
      className={[active && styles.active, disabled && styles.disabled, done && styles.done, styles.item].join(' ')}
      disabled={disabled}
      role="button"
      onClick={!disabled ? setNewActiveAppointment : undefined}>
      <li className={[styles.lessonItem, styles[borderColor]].join(' ')} key={lesson.id}>
        {lesson?.number ? (
          <span className={styles.lessonNumber}>
            Lesson <span id="dont-translate">{lesson.number}</span>:
          </span>
        ) : (
          <span className={styles.lessonNumber}>Convo:</span>
        )}
        <span className={styles.lessonDate} id="dont-translate">
          {whatTimestampToShow()}
        </span>
        {disabled ? null : <div className={styles.imageWrapper}>{whatButtonToShow()}</div>}
      </li>
    </div>
  );
};

BookingSummaryItem.propTypes = {
  active: PropTypes.bool,
  borderColor: PropTypes.string,
  cancel: PropTypes.bool,
  disabled: PropTypes.bool,
  done: PropTypes.bool,
  doneScheduling: PropTypes.func,
  finalTimestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  interimTimestamp: PropTypes.string,
  introClass: PropTypes.bool,
  lesson: PropTypes.object.isRequired,
  onlyOneItem: PropTypes.bool,
  removeNewScheduleFromFinalList: PropTypes.func,
  setNewActiveAppointment: PropTypes.func,
  testId: PropTypes.string,
  timezone: PropTypes.string.isRequired
};

BookingSummaryItem.defaultProps = {
  active: false,
  borderColor: 'mint',
  cancel: false,
  disabled: false,
  done: false,
  finalTimestamp: null,
  onlyOneItem: false
};

export default BookingSummaryItem;
