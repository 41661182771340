import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import mixpanelHelper from '../utils/mixpanel-helper';

import ChatPageMobile from '../components/chat/chat-mobile';
import ChatPageWeb from '../components/chat/chat-web';

const ChatPage = ({ user }) => {
  const publishToMixpanel = () => {
    mixpanelHelper.identify(user.id);
    mixpanelHelper.track('Visited Chat Page');
  };

  const { chatid } = useParams();

  useEffect(() => {
    publishToMixpanel();
  }, []);

  if (isMobile) return <ChatPageMobile chatId={chatid} />;

  return <ChatPageWeb chatId={chatid} />;
};

const mapStateToProps = (state) => ({
  user: state.user
});

ChatPage.propTypes = {
  user: PropTypes.object
};

export default connect(mapStateToProps)(ChatPage);
