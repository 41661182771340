import instance from '..';

export default class NewOnboardingAPI {
  static async getOnboardingResults(token) {
    return instance.get('/api/onboarding_result/', {
      headers: { Authorization: `Token ${token}` }
    });
  }
  static async getOnboardingData({ token, password_token, email }) {
    if (token) return instance.get('/api/onboarding/', { headers: { Authorization: `Token ${token}` } });

    return instance.get('/api/onboarding/', {
      params: { password_token, email }
    });
  }
  static async postOnboarding({ onboardingData }, { password_token, email }) {
    return instance.post('/api/store_onboarding/', onboardingData, {
      params: { password_token, email }
    });
  }
  static async retrievePlacementTestScore(token, data) {
    return instance.post('/api/get_typeform_data/', data, {
      headers: { Authorization: `Token ${token}` }
    });
  }
}
