import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RegularFormField from '../../../../design-system/regular-field/RegularFormField';
import PasswordFormField from '../../../../design-system/password-field/PasswordFormField';
import { isEmail, isStrongPassword } from '../../../../../utils/validators';
import Button from '../../../../design-system/button/Button';

import styles from './EmailLoginForm.module.scss';
import { postLogIn } from '../../../../../actions/new-login';
import ErrorMessage from '../../../../design-system/helper-messages/error-message/ErrorMessage';

const EmailLoginForm = ({
  hasLoginError,
  isLoadingPostLogIn,
  moveToNextStep,
  postLogInAction,
  loginErrorMsg,
  initialInformation,
  setManuallySetLogIn
}) => {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    setIsFormDirty(true);
    validateForm();
  }, [email, password]);

  useEffect(() => {
    if (initialInformation?.email) setEmail(initialInformation.email);
  }, [initialInformation]);

  const validateForm = () => {
    const isEmailValid = isEmail(email);
    setIsEmailValid(isEmailValid);

    const isPasswordFormFieldValid = isStrongPassword(password);
    setIsPasswordValid(isPasswordFormFieldValid);

    setIsFormValid(isEmailValid && isPasswordFormFieldValid);
  };

  const handleUserLogIn = () => {
    postLogInAction({
      data: {
        email,
        password
      },
      successCallback: () => {
        moveToNextStep();
      }
    });
  };

  return (
    <form className={styles['form-wrapper']}>
      {initialInformation?.email ? (
        <RegularFormField id="email dont-translate" label="Email" type="email" value={initialInformation?.email} disabled />
      ) : (
        <RegularFormField
          id="email"
          label="Email"
          invalidMessage="Invalid email."
          isValid={isEmailValid}
          type="email"
          value={email}
          onChange={(evt) => setEmail(evt.target.value)}
        />
      )}
      <PasswordFormField
        id="password"
        invalidMessage="Invalid password. Your password must contain at least 8 characters and 1 uppercase letter."
        isValid={isPasswordValid}
        label="Password"
        value={password}
        onChange={(evt) => setPassword(evt.target.value)}
      />
      <ErrorMessage errorMsg={loginErrorMsg} show={hasLoginError} />
      <Button
        outfit="greenButton"
        loading={isLoadingPostLogIn}
        type="button"
        disabled={isFormDirty && !isFormValid}
        onClick={handleUserLogIn}
        id="log-in">
        Log in
      </Button>
      <button
        outfit="transparentButton"
        className={styles['text-button']}
        type="button"
        id="already-created"
        onClick={() => setManuallySetLogIn(false)}>
        Or log in if you have already created your user
      </button>
    </form>
  );
};

EmailLoginForm.propTypes = {
  hasLoginError: PropTypes.bool,
  isLoadingPostLogIn: PropTypes.bool,
  moveToNextStep: PropTypes.func.isRequired,
  postLogInAction: PropTypes.func.isRequired,
  loginErrorMsg: PropTypes.string,
  initialInformation: PropTypes.object,
  selectedTutor: PropTypes.object,
  setManuallySetLogIn: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoadingPostLogIn: state.newLogin.logIn.isLoadingPostLogIn,
  hasLoginError: state.newLogin.logIn.hasPostLogInError,
  hasUserAuthorizationError: state.newLogin.signIn.beforeUserInteraction.hasUserAuthorizationError,
  initialInformation: state.newOnboarding.initialInformation,
  loginErrorMsg: state.newLogin.logIn.postLogInErrorMessage,
  selectedTutor: state.selectedTutor.selectedTutor,
  userAuthorizationErrorMessage: state.newLogin.signIn.beforeUserInteraction.userAuthorizationErrorMessage
});

export default connect(mapStateToProps, {
  postLogInAction: postLogIn
})(EmailLoginForm);
