import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../design-system/button/Button';
import Modal from '../../design-system/modal/Modal';

import { notifyOutOfLicenceRequest } from '../../../actions/out-of-licence';

import styles from './SessionsManagementModal.module.scss';

const OutOfLicenceModal = ({ student, modalTitle, onCloseModal, modalMainColor, testId, notifyOutOfLicenceRequestAction }) => {
  const handleContactLD = () => {
    notifyOutOfLicenceRequestAction(student.token);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    if (onCloseModal) onCloseModal();
  };

  return (
    <Modal
      noPadding
      completionProgress={100}
      modalMainColor={modalMainColor}
      content={
        <div className={[styles.modalWrapper, styles.noPadding].join(' ')} data-testid={testId}>
          <div className={[styles.modalTitle, styles[modalMainColor]].join(' ')}>
            <h2 data-testid="booking-modal-title">{modalTitle}</h2>
          </div>
          <div className={styles.modalSection}>
            <p>
              It looks like you have already scheduled all your available Live Practices before your licence expiry date!
              <br />
              <br />
            </p>
            <p>
              If you would like your licence renewed, please contact your Learning & Development representative, and also click the button below so we
              are aware.
              <br />
              <br />
            </p>
            <p>Please be aware that there is no guarantee, but it&apos;s important to register your interest.</p>
            <div className={styles.buttonsWrapper}>
              <Button outfit="greenButton" onClick={handleContactLD} id="modal-close">
                Yes, I will contact L&D
              </Button>
              <Button outfit="secondaryButton" onClick={handleCloseModal} id="change-coach-modal-button">
                No, I&apos;m not interested
              </Button>
            </div>
          </div>
        </div>
      }
    />
  );
};

OutOfLicenceModal.propTypes = {
  notifyOutOfLicenceRequestAction: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  onCloseModal: PropTypes.func,
  modalMainColor: PropTypes.string,
  selectedTutor: PropTypes.object,
  testId: PropTypes.string,
  student: PropTypes.object.isRequired
};

OutOfLicenceModal.defaultProps = {
  enableCoachChangeWhenUnavailable: true,
  cleanPreviousStateAction: () => {},
  disabledActionButton: false
};

const mapStateToProps = (state) => ({
  student: state.user
});

export default connect(mapStateToProps, {
  notifyOutOfLicenceRequestAction: notifyOutOfLicenceRequest
})(OutOfLicenceModal);
