import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from '../Table.module.scss';

import { getTutorAppointments, resetAppointments } from '../../../actions/appointments';
import LoadingSpinner from '../../design-system/loading/spinner/LoadingSpinner';
import { endOfDay, startOfDay, sub } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import ErrorToast from '../../design-system/toast/errorToast/ErrorToast';
import Row from './Row';
import Header from './Header';

import { ReactComponent as IconLeftArrow } from '../../../assets/icons/icon_left-arrow.svg';
import { useHistory } from 'react-router-dom';
import { appointmentStatus } from '../../../domains/appointments/status';

const columns = {
  date: 'Date',
  classTime: 'Session time',
  duration: 'Duration',
  joinClassroom: 'Platform used',
  students: 'Learner',
  classReport: 'Coach attendance',
  reportStudentOutcome: 'Learner attendance'
};

const PastAppointments = ({
  errorGettingTutorAppointments,
  getTutorAppointmentsAction,
  resetAppointmentsAction,
  isGettingTutorAppointments,
  tutorAppointments,
  user
}) => {
  const history = useHistory();

  useEffect(() => {
    resetAppointmentsAction();
    getTutorAppointments();
  }, []);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const getTutorAppointments = () => {
    getTutorAppointmentsAction({
      token: user?.token,
      tutorId: user?.id,
      startDate: zonedTimeToUtc(sub(startOfDay(new Date()), { weeks: 6 }), timezone).toISOString(),
      endDate: zonedTimeToUtc(sub(endOfDay(new Date()), { days: 1 }), timezone).toISOString()
    });
  };

  return (
    <main className={styles.container}>
      <h1>
        All appointments for the past <span>6 weeks</span>
      </h1>
      <h2>You can check the status, time and learner attendance for all your appointments.</h2>
      <div className={styles['table-container']}>
        <button className={styles['back']} onClick={() => history.goBack()}>
          <IconLeftArrow /> <span>Back</span>
        </button>
        {isGettingTutorAppointments ? (
          <div>
            <LoadingSpinner />
          </div>
        ) : (
          <table>
            <thead>
              <Header columns={columns} />
            </thead>
            <tbody>
              {tutorAppointments?.map((appt, index) => {
                return appt.status !== appointmentStatus.canceled ? <Row appointment={appt} key={index} timezone={timezone} user={user} /> : null;
              })}
            </tbody>
          </table>
        )}

        <ErrorToast
          errorMsg="Something went wrong while getting your appointments. Please refresh the page and try again."
          show={errorGettingTutorAppointments}
        />
      </div>
    </main>
  );
};

PastAppointments.propTypes = {
  errorGettingTutorAppointments: PropTypes.bool,
  isGettingTutorAppointments: PropTypes.bool,
  getTutorAppointmentsAction: PropTypes.func.isRequired,
  resetAppointmentsAction: PropTypes.func.isRequired,
  tutorAppointments: PropTypes.array,
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  errorGettingTutorAppointments: state.appointments.errorGettingTutorAppointments,
  isGettingTutorAppointments: state.appointments.isGettingTutorAppointments,
  tutorAppointments: state.appointments.tutorAppointments,
  user: state.user
});

export default connect(mapStateToProps, {
  getTutorAppointmentsAction: getTutorAppointments,
  resetAppointmentsAction: resetAppointments
})(PastAppointments);
