import axios from 'axios';
import * as Sentry from '@sentry/browser';

import { isTokenExpired } from './common';
import { GET_TUTOR_PROFILE, GET_TUTOR_PROFILE_ERROR } from './types';
import { actionCreatorsFactory, asyncActionType, createActionTypes } from '../utils/action-helpers';
import TutorsAPI from '../services/tutors';

export const TutorProfileActionTypes = createActionTypes('TutorProfile', [asyncActionType('GET_TUTOR_PROFILE')]);

const sentryContext = Object.freeze({
  tags: {
    context: 'Tutor Profile action'
  }
});

function getTutorProfileSuccess(response) {
  return {
    type: GET_TUTOR_PROFILE,
    payload: response
  };
}

function getTutorProfileError() {
  return {
    type: GET_TUTOR_PROFILE_ERROR,
    payload: {}
  };
}

export function resetTutorProfile() {
  return {
    type: GET_TUTOR_PROFILE,
    payload: { data: {} }
  };
}

const getTutorProfileActions = actionCreatorsFactory(
  [TutorProfileActionTypes.GET_TUTOR_PROFILE, TutorProfileActionTypes.GET_TUTOR_PROFILE_SUCCESS, TutorProfileActionTypes.GET_TUTOR_PROFILE_FAIL],
  'getTutorProfile'
);

export function getTutorProfile(token, tutorId, errorCallback) {
  return async (dispatch) => {
    try {
      dispatch(getTutorProfileActions.getTutorProfile());
      const { data } = await TutorsAPI.getTutorProfile({ token, tutorId });
      dispatch(getTutorProfileActions.getTutorProfileSuccess(data));
    } catch (err) {
      Sentry.captureException(err, sentryContext);
      if (!isTokenExpired(dispatch, err)) {
        if (errorCallback) errorCallback();
        dispatch(getTutorProfileActions.getTutorProfileFail(err));
      }
    }
  };
}

export function getTutorPreview(token, tutorId, errorCallback) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/api/tutor-preview/${tutorId}`, {
        headers: { Authorization: `Token ${token}` }
      });
      dispatch(getTutorProfileSuccess(response));
    } catch (err) {
      Sentry.captureException(err, sentryContext);
      if (!isTokenExpired(dispatch, err)) {
        if (errorCallback) {
          errorCallback();
        }
        dispatch(getTutorProfileError());
      }
    }
  };
}
