import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AccountForm from '../components/account/AccountForm';
import PasswordForm from '../components/account/PasswordForm';
import GraduationDateForm from '../components/account/GraduationDateForm';

import styles from '../style/containers/Account.module.scss';
import { userLogout } from '../actions/user';
import Mixpanel from '../utils/mixpanel-helper.js';

const Account = ({ token, user, history, passwordForm, userLogoutAction }) => {
  const logUserOut = () => {
    Mixpanel.track('Click', { button: 'logout' });
    userLogoutAction(token, () => {
      history.push('/');
    });
  };

  return (
    <div className={styles.wrapper}>
      <AccountForm logUserOut={logUserOut} />
      <GraduationDateForm
        initialValues={user}
        isStudent={user.user_type === 'student'}
        token={token}
        userLocationName={user.location_name || 'Unknown'}
        history={history}
      />
      <PasswordForm token={token} passwordForm={passwordForm} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    passwordForm: state.form.PasswordForm,
    token: state.user.token,
    user: state.user
  };
}

Account.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  passwordForm: PropTypes.object,
  timezone: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  userLogoutAction: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {
  userLogoutAction: userLogout
})(Account);
