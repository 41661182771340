import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import PropTypes from 'prop-types';

import styles from '../../style/components/uikit/ShowMore.module.scss';

const ShowMore = ({ text, direction, action }) => (
  <div className={styles.nextButton} onClick={action} onKeyPress={action} role="button" tabIndex="0">
    {text}
    <Glyphicon glyph={direction === 'up' ? 'menu-up' : 'menu-down'} className={styles.glyph} />
  </div>
);

ShowMore.propTypes = {
  text: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired
};

export default ShowMore;
