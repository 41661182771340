import React from 'react';
import PropTypes from 'prop-types';

import styles from './CloseButton.module.scss';

const CloseButton = ({ onClick }) => {
  return (
    <button className={styles.button} onClick={onClick}>
      X
    </button>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default CloseButton;
