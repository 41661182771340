import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import styles from '../../MenuItem.module.scss';
import { ReactComponent as IconBookings } from '../../../../assets/icons/icon_bookings-menu.svg';
import { BOOKING_URL } from '../../../new-booking/Enum';
import { UserTypes } from '../../../../domains/user/user-types';

const BookingsButton = ({ introClass, handleMenuLinkClick, mustTakeIntroClass, selectedCourse, user }) => {
  if ((mustTakeIntroClass && introClass?.status !== 'happened') || (!mustTakeIntroClass && !Object.keys(selectedCourse).length)) return;

  if (user?.user_type !== UserTypes.student) return;

  return (
    <li className={styles['link-wrapper']} data-testid="bookings-button">
      <NavLink
        to={BOOKING_URL}
        onClick={() => handleMenuLinkClick('dashboard')}
        activeClassName={styles.isActive}
        className={styles.menuItem}
        data-testid="bookings-link">
        <div className={styles['button-wrapper']}>
          <div className={styles['background']}>
            <IconBookings />
          </div>
          <span>Bookings</span>
        </div>
      </NavLink>
    </li>
  );
};

BookingsButton.propTypes = {
  introClass: PropTypes.shape({ status: PropTypes.string }),
  handleMenuLinkClick: PropTypes.func.isRequired,
  mustTakeIntroClass: PropTypes.bool.isRequired,
  selectedCourse: PropTypes.object,
  user: PropTypes.object
};

export default BookingsButton;
