import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CoachCard from './components/CoachCard';

import { getTutorsWithLanguageAndProductType } from '../../../actions/tutors';

import styles from './Coaches.module.scss';
import { setSelectedTutor } from '../../../actions/selected-tutor';
import ErrorToast from '../../design-system/toast/errorToast/ErrorToast';
import CoachFilterByAvailability from './components/CoachFilterByAvailability';
import { setOpenChangeCoachModal } from '../../../actions/change-coach';

const Coaches = ({
  getTutorsErrorMessage,
  getTutorsWithLanguageAndProductTypeAction,
  hasGetTutorsError,
  isGettingTutors,
  informationRef,
  setOpenChangeCoachModalAction,
  selectedCourse,
  setSelectedTutorAction,
  tutors,
  user
}) => {
  const [tutorsList, setTutorsList] = useState([]);

  useEffect(() => {
    getTutorsWithLanguageAndProductTypeAction({
      languageId: selectedCourse?.language?.id,
      token: user?.token
    });
  }, []);

  useEffect(() => {
    if (tutors.length) setTutorsList(tutors);
  }, [tutors]);

  const handleBook = (coachData) => {
    setSelectedTutorAction(coachData);
    setOpenChangeCoachModalAction(true);
  };

  const handleScrollTo = (ref) => ref.current.scrollIntoView({ behavior: 'smooth' });

  return (
    <div className={styles['content-wrapper']}>
      <h1 className={styles['title']}>Meet our {selectedCourse?.language?.language} coaches</h1>
      <p className={styles['subtitle']}>
        Available coaches for your{' '}
        <span role="button" onClick={() => handleScrollTo(informationRef)} className={styles['link']}>
          Live Practices
        </span>
      </p>
      <div className={styles.filter}>
        <CoachFilterByAvailability tutors={tutorsList} setUpdatedTutorsList={setTutorsList} />
      </div>
      <div className={styles['coach-cards-wrapper']}>
        {isGettingTutors || !tutorsList.length ? (
          <>
            <CoachCard isLoading={true} />
            <CoachCard isLoading={true} />
            <CoachCard isLoading={true} />
          </>
        ) : (
          tutorsList.map((tutor, index) => <CoachCard key={index} tutor={tutor} onBook={handleBook} />)
        )}
      </div>
      <ErrorToast errorMsg={getTutorsErrorMessage} show={hasGetTutorsError} />
    </div>
  );
};

Coaches.propTypes = {
  getTutorsErrorMessage: PropTypes.string,
  getTutorsWithLanguageAndProductTypeAction: PropTypes.func.isRequired,
  hasGetTutorsError: PropTypes.bool,
  isGettingTutors: PropTypes.bool,
  informationRef: PropTypes.object,
  previousOnboarding: PropTypes.object,
  selectedTutor: PropTypes.object,
  setOpenChangeCoachModalAction: PropTypes.func.isRequired,
  selectedCourse: PropTypes.object,
  setSelectedTutorAction: PropTypes.func.isRequired,
  tutors: PropTypes.array,
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  getTutorsErrorMessage: state.tutors.getTutorsErrorMessage,
  hasGetTutorsError: state.tutors.hasGetTutorsError,
  isGettingTutors: state.tutors.isGettingTutors,
  previousOnboarding: state.newOnboarding.previousOnboarding.previousOnboarding,
  selectedCourse: state.courses.selectedCourseDetails.courseDetails,
  selectedTutor: state.selectedTutor.selectedTutor,
  tutors: state.tutors.tutors,
  user: state.user
});

export default connect(mapStateToProps, {
  getTutorsWithLanguageAndProductTypeAction: getTutorsWithLanguageAndProductType,
  setOpenChangeCoachModalAction: setOpenChangeCoachModal,
  setSelectedTutorAction: setSelectedTutor
})(Coaches);
