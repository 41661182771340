import React from 'react';
import PropTypes from 'prop-types';

import styles from './TabSelector.module.scss';
import Tab from './components/Tab';
import LoadingSpinner from '../../design-system/loading/spinner/LoadingSpinner';

const TabSelector = ({ isLoading, onTabSelection, selectedTab, tabs }) => {
  const handleCourseTabSelection = (index) => {
    if (!tabs?.length || !tabs[index]) return;
    onTabSelection(index);
  };

  if (isLoading)
    return (
      <div className={styles.loadingContainer}>
        <LoadingSpinner />
      </div>
    );

  return (
    <div className={styles.tabsContainer}>
      {tabs.map((tab, index) => (
        <Tab key={tab} name={tab} selected={index === selectedTab} index={index} onSelectTab={() => handleCourseTabSelection(index)} />
      ))}
    </div>
  );
};

TabSelector.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onTabSelection: PropTypes.func,
  selectedTab: PropTypes.number.isRequired,
  tabs: PropTypes.array.isRequired
};

export default TabSelector;
