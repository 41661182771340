import instance from '..';

export default class LessonsAPI {
  static async getLessons(token) {
    return instance.get('/api/lessons/', { headers: { Authorization: `Token ${token}` } });
  }
  static async getUnitProgress({ token, unitId }) {
    return instance.get(`/api/progress/lesson/${unitId}`, {
      headers: { Authorization: `Token ${token}` }
    });
  }
  static async getUnitsLiveProgressAndScore({ token, courseId }) {
    return instance.get(`/api/progress/course/${courseId}`, {
      headers: { Authorization: `Token ${token}` }
    });
  }
  static async getLessonsAssignmentsLiveProgress({ token, lessonId }) {
    return instance.get(`/api/progress/lesson/${lessonId}/resources`, {
      headers: { Authorization: `Token ${token}` }
    });
  }
}
