import { useEffect, useMemo, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';

function useSso({ options }) {
  const [user, setUser] = useState(null);
  const [customState, setCustomState] = useState(null);

  const auth = useMemo(() => {
    Auth.configure(options);
    return Auth;
  });

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          setUser(data);
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'customOAuthState':
          setCustomState(data);
      }
    });

    Auth.currentAuthenticatedUser()
      .then((currentUser) => setUser(currentUser))
      .catch(() => {});

    return unsubscribe;
  }, []);

  const signIn = ({ provider }) => auth.federatedSignIn({ provider });
  const signOut = () => auth.signOut();

  return {
    user,
    customState,
    signIn,
    signOut
  };
}

export default useSso;
