import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';

import styles from '../../style/components/uikit/TypeInterest.module.scss';
import stylesOnboarding from '../../style/containers/Onboarding.module.scss';

const propTypes = {
  userInput: PropTypes.string.isRequired,
  instructionText: PropTypes.string.isRequired,
  allSuggestions: PropTypes.array.isRequired,
  onFilter: PropTypes.func.isRequired,
  onUserInput: PropTypes.func.isRequired
};

const TypeInterest = ({ allSuggestions, userInput, onUserInput, instructionText, onFilter }) => {
  const [input, setUserInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    setUserInput(userInput);
  }, [userInput]);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : allSuggestions.filter((sug) => sug.name.toLowerCase().slice(0, inputLength) === inputValue);
  };

  const getSuggestionValue = (suggestion) => suggestion.name;

  const renderSuggestion = () => null;

  const onChange = (event, { newValue }) => {
    onUserInput(newValue);
    if (!newValue.length) onFilter(allSuggestions);
  };

  const inputProps = {
    placeholder: instructionText,
    value: input,
    onChange
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    const suggestionsByAutoSuggest = getSuggestions(value);
    onFilter(suggestionsByAutoSuggest);
    setSuggestions(suggestionsByAutoSuggest);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions(allSuggestions);
  };

  return (
    <div className={styles.input__insertion}>
      <div className={styles.input__wrapper}>
        <Autosuggest
          suggestions={suggestions}
          getSuggestionValue={getSuggestionValue}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          // onSuggestionSelected={onSuggestionSelected}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={() => null}
          inputProps={inputProps}
          theme={{
            input: styles.autocompleteInput,
            container: styles.autocompleteContainer,
            suggestionsContainer: styles.autocompleteSuggestionsContainer,
            suggestionsContainerOpen: stylesOnboarding.autocompleteSuggestionsContainerOpen
          }}
        />
      </div>
    </div>
  );
};

TypeInterest.propTypes = propTypes;

export default TypeInterest;
