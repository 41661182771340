import { AssignmentsActionTypes } from '../actions/assignments';
import { LessonsActionTypes } from '../actions/lessons';
import { USER_LOGOUT } from '../actions/types';

const updateCurrentCeregoAssignment = (assignments, currentAssignment) => {
  if (!currentAssignment || !currentAssignment.id) return currentAssignment;

  const [updated] = assignments.filter((a) => a?.id === currentAssignment?.id);

  if (!updated) return currentAssignment;

  return {
    ...currentAssignment,
    seeNextAt: updated?.see_next_at || null,
    progress: Number((updated?.progress * 1).toFixed(3)) ?? 0
  };
};

export default function CurrentCeregoAssignmentReducer(state = null, action) {
  switch (action.type) {
    case AssignmentsActionTypes.SET_CURRENT_CEREGO_ASSIGNMENT:
      return action.payload;
    case LessonsActionTypes.GET_LESSONS_ASSIGNMENTS_LIVE_PROGRESS_SUCCESS:
      return updateCurrentCeregoAssignment(action.payload, state);
    case USER_LOGOUT:
      return null;
    default:
      return state;
  }
}
