import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, Glyphicon } from 'react-bootstrap';

import styles from '../../style/components/uikit/EmailListEntry.module.scss';

class EmailListEntry extends Component {
  state = {
    emails: { 0: '' },
    names: { 'name-0': '' }
  };

  componentDidMount() {
    const { emails, names } = this.state;
    const { preDoneInvitations, userEmail } = this.props;
    if (preDoneInvitations && preDoneInvitations.addresses) {
      // If a user was invited to an appointment and then
      // the user uses the quickingbooking
      // let's make sure that he is not included on the
      // invites list
      const currentUserBookingWasInvited = {
        wasInvited: false,
        index: 0
      };
      preDoneInvitations.addresses.forEach((email, index) => {
        if (email === userEmail) {
          currentUserBookingWasInvited.wasInvited = true;
          currentUserBookingWasInvited.index = index;
        } else {
          emails[index] = email;
          this.setState({ emails });
          this.sendEmails();
        }
      });
      preDoneInvitations.names.forEach((name, index) => {
        if (currentUserBookingWasInvited.wasInvited) {
          if (index !== currentUserBookingWasInvited.index) {
            names[`name-${index}`] = name;
            this.setState({ names });
            this.sendEmails();
          }
        } else {
          names[`name-${index}`] = name;
          this.setState({ names });
          this.sendEmails();
        }
      });
    }
  }

  getNewEmailKey = () => {
    const keys = Object.keys(this.state.emails);

    keys.sort((key1, key2) => key1 - key2);

    if (keys.length > 0) {
      let lastKey = keys[keys.length - 1];
      lastKey = parseInt(lastKey, 10);
      return lastKey + 1;
    }

    return 0;
  };

  addEmail = () => {
    const { emails, names } = this.state;
    const key = this.getNewEmailKey();
    emails[key] = '';
    names[`name-${key}`] = '';
    this.setState({ emails, names });
    return key;
  };

  handleInput = (event) => {
    const { emails } = this.state;
    const { attributes, value } = event.target;
    const keyAttribute = attributes['data-key'];
    const key = keyAttribute.value;
    emails[key] = value;
    this.setState({ emails });
    this.sendEmails();
  };

  handleNameInput = (event) => {
    const { names } = this.state;
    const { attributes, value } = event.target;
    const keyAttribute = attributes['data-key'];
    const key = keyAttribute.value;
    names[key] = value;
    this.setState({ names });
    this.sendEmails();
  };

  removeEmail = (key) => {
    const { emails } = this.state;
    delete emails[key];
    this.setState({ emails });
    this.sendEmails();
  };

  sendEmails = () => {
    let addresses = Object.values(this.state.emails);
    const names = Object.values(this.state.names);
    addresses = addresses.filter((address, index) => {
      if (address.length <= 0) {
        names.splice(index, 1);
      }
      return address.length > 0;
    });
    this.props.onChange({ addresses, names });
  };

  render() {
    const { inputClass } = this.props;
    const { emails, names } = this.state;

    const emailEntries = Object.entries(emails);
    const nameEntries = Object.entries(names);

    return (
      <div>
        {emailEntries.map((entry, index) => {
          const [key, value] = entry;
          const [nameKey, nameValue] = nameEntries[index];

          return (
            <div key={index} className={styles.row}>
              <FormControl
                autocomplete="off"
                className={`${styles.input} ${inputClass}`}
                data-key={key}
                key={key}
                onChange={this.handleInput}
                placeholder="friend@example.com"
                type="email"
                value={value}
              />

              <FormControl
                autocomplete="off"
                className={`${styles.input} ${inputClass}`}
                data-key={nameKey}
                key={nameKey}
                onChange={this.handleNameInput}
                placeholder="Your friend's name"
                type="text"
                value={nameValue}
              />

              {index !== 0 && (
                <Button bsStyle="link" className={styles.removeBtn} onClick={() => this.removeEmail(key)}>
                  <Glyphicon glyph="remove" />
                </Button>
              )}
            </div>
          );
        })}

        {emailEntries.length + this.props.invited < 5 && (
          <Button bsStyle="link" className={styles.addBtn} onClick={this.addEmail}>
            <Glyphicon glyph="plus-sign" /> Add another
          </Button>
        )}
      </div>
    );
  }
}

EmailListEntry.propTypes = {
  inputClass: PropTypes.string,
  onChange: PropTypes.func,
  invited: PropTypes.number,
  preDoneInvitations: PropTypes.object,
  userEmail: PropTypes.string.isRequired
};

EmailListEntry.defaultProps = {
  inputClass: '',
  onChange: () => {},
  invited: 0,
  preDoneInvitations: {}
};

export default EmailListEntry;
