import { ChangeCoachActionTypes } from '../actions/change-coach';

const initialState = {
  isChangeCoachProcessActive: false,
  openChangeCoachModal: false,
  motivation: '',
  isChangeCoachSuccess: false
};

const changeCoachReducer = (state = initialState, action) => {
  switch (action.type) {
    case ChangeCoachActionTypes.SET_PROCESS_ACTIVE:
      return { ...state, isChangeCoachProcessActive: action.payload };
    case ChangeCoachActionTypes.OPEN_CHANGE_COACH_MODAL:
      return { ...state, openChangeCoachModal: action.payload.openChangeCoachModal };
    case ChangeCoachActionTypes.CHANGE_COACH_MOTIVATION:
      return { ...state, motivation: action.payload };
    case ChangeCoachActionTypes.SET_SUCCESS:
      return { ...state, isChangeCoachSuccess: action.payload, openChangeCoachModal: false };
    default:
      return state;
  }
};

export default changeCoachReducer;
