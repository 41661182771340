import React from 'react';
import PropTypes from 'prop-types';
import styles from './Tag.module.scss';

const Tag = ({ children, color }) => {
  return <div className={[styles['tag-wrapper'], styles[color]].join(' ')}>{children}</div>;
};

Tag.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  color: PropTypes.string.isRequired
};

Tag.defaultProps = {
  color: 'grey'
};

export default Tag;
