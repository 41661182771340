import React from 'react';
import PropTypes from 'prop-types';
import Tag from '../../../design-system/tag/Tag';
import { CLASS_TYPES } from '../../Enum';

const AppointmentTag = ({ appointmentTag }) => {
  if (appointmentTag === CLASS_TYPES.tuition) return <Tag color="mint">Live Practice</Tag>;

  if (appointmentTag === CLASS_TYPES.conversation) return <Tag color="salmon">Convo Practice</Tag>;
};

AppointmentTag.propTypes = {
  appointmentTag: PropTypes.string
};

export default AppointmentTag;
