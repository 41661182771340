import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';

import moment from 'moment-timezone';

import styles from '../../style/components/calendar/CalendarToolbar.module.scss';

import { ReactComponent as IconLeftArrow } from '../../assets/icons/icon_left-arrow.svg';
import { ReactComponent as IconRightArrow } from '../../assets/icons/icon_right-arrow.svg';

class CalendarToolbar extends Component {
  navigate = (action) => {
    const { date, view, onNavigate } = this.props;
    onNavigate(action);
    // update events for the new view
    let targetDate = date;
    const diffDays = view === 'week' ? 6 : 1;
    if (action === 'PREV') {
      targetDate = moment(date).subtract(diffDays, 'days').toDate();
    } else if (action === 'NEXT') {
      targetDate = moment(date).add(diffDays, 'days').toDate();
    }
    this.props.updateEvents(targetDate);
  };

  view = (view) => {
    this.props.onViewChange(view);
  };

  viewNamesGroup(messages) {
    const viewNames = this.props.views;
    const view = this.props.view;

    if (viewNames.length > 1) {
      return viewNames.map((name) => (
        <button type="button" key={name} className={cn({ 'rbc-active': view === name })} onClick={this.view.bind(null, name)}>
          {messages[name]}
        </button>
      ));
    }
  }

  render() {
    const { messages, label } = this.props;

    return (
      <div>
        <div className={['rbc-toolbar-label', styles.heading, styles.mobileOnly].join(' ')}>{label}</div>
        <div className={['rbc-toolbar', styles.toolbar].join(' ')}>
          <div className={['rbc-btn-group', styles.navBlock].join(' ')}>
            <button type="button" onClick={this.navigate.bind(null, 'TODAY')}>
              {messages.today}
            </button>
            <button type="button" onClick={this.navigate.bind(null, 'PREV')}>
              <IconLeftArrow />
            </button>
            <button type="button" onClick={this.navigate.bind(null, 'NEXT')}>
              <IconRightArrow />
            </button>
          </div>

          <div className={['rbc-toolbar-label', styles.heading, styles.desktopOnly].join(' ')}>{label}</div>

          <div className={['rbc-btn-group', styles.navBlock, styles.viewNav].join(' ')}>{this.viewNamesGroup(messages)}</div>
        </div>
      </div>
    );
  }
}

CalendarToolbar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  label: PropTypes.node.isRequired,
  messages: PropTypes.object.isRequired,
  onNavigate: PropTypes.func.isRequired,
  onViewChange: PropTypes.func.isRequired,
  updateEvents: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default CalendarToolbar;
