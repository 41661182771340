import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../design-system/tooltip/Tooltip.jsx';

import Mixpanel from '../../utils/mixpanel-helper.js';
import resourceStyles from '../../style/containers/Resources.module.scss';
import { RESOURCE_TYPE_ICON } from './Enum.js';

import Button from '../design-system/button/Button.js';

const STATUS_COLOR_MAPPING = {
  Completed: '#40dba7',
  'Pending submission': '#ef9d46',
  'Awaiting feedback': '#efd546',
  'In-Progress': '#ef9d46'
};

const LessonResource = (props) => {
  const { name, type, url, status, userType, lessonNumber, showFeedbackButtonText } = props;

  const nameSuffix = userType === 'tutor' ? ' (coaching material)' : '';

  const isAssessmentLink = type === 'assessment' || type === 'written_assignment' || type === 'speaking_assignment';

  if (!url || !type) return;

  const handleMixpanelTracking = () => {
    window.open(url, '_blank');
    Mixpanel.track('Click', {
      button: name, // left this to make it backward compatible
      resourceName: name,
      clickType: isAssessmentLink ? 'assessment' : 'non-cerego resource',
      type,
      lessonNumber,
      status
    });
  };

  const getTooltip = () => {
    if (type == 'written_assignment' || type === 'speaking_assignment') {
      if (status !== 'Completed') {
        return (
          <span className={resourceStyles.tooltip}>
            <Tooltip
              tooltipLabel="You will receive a feedback on your assignment in 5 working days."
              backgroundColor="white"
              position="bottom-right"
            />
          </span>
        );
      }
    }
  };

  return (
    <div
      className={[resourceStyles.lessonResourceContainer, status === 'future' ? resourceStyles.disabledContainer : undefined].join(' ')}
      data-testid={name}>
      <div className={resourceStyles.titleStack}>
        <img className={resourceStyles['resource-icon']} src={RESOURCE_TYPE_ICON[type]} alt="" />
        <h2 id="dont-translate" className={status === 'future' && type !== 'book' ? resourceStyles.disabledText : resourceStyles.title}>
          {name + nameSuffix}
          {isAssessmentLink && <span style={{ color: STATUS_COLOR_MAPPING[status], display: 'flex', flexDirection: 'row' }}>&nbsp;{status}</span>}
          {getTooltip()}
        </h2>
      </div>

      <div className={resourceStyles.resourceBtnContainer}>
        <Button outfit="greenButton" bold onClick={handleMixpanelTracking}>
          <a href={url} target="_blank" rel="no_ref noreferrer" data-testid={`${name}-link`}>
            {type === 'feedback' && showFeedbackButtonText ? 'PERSONALISED FEEDBACK' : 'OPEN'}
          </a>
        </Button>
      </div>
    </div>
  );
};

LessonResource.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  status: PropTypes.string,
  userType: PropTypes.string,
  lessonNumber: PropTypes.number.isRequired,
  showFeedbackButtonText: PropTypes.bool
  // disabled: PropTypes.bool,
};

LessonResource.defaultProps = {
  // disabled: false,
};

export default LessonResource;
