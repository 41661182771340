import { buildMonthAndYearStr } from '../../../../../reducers/bookable';

const buildPrevAndCurrent = ({ selectedTimestamp, selectedTimestamps, sessionsPerMonth }) => {
  const map = new Map(Object.entries(selectedTimestamps || {}));
  const refMap = new Map(Object.entries(sessionsPerMonth || {}));
  const refKey = buildMonthAndYearStr(selectedTimestamp);
  const alreadyScheduledThisMonth = map.get(refKey) || [];
  const availableSessionsThisMonth = refMap.get(refKey) || {};

  return { alreadyScheduledThisMonth, availableSessionsThisMonth };
};

export function calculateBookings({ selectedTimestamp, selectedTimestamps, sessionsPerMonth }) {
  const { alreadyScheduledThisMonth, availableSessionsThisMonth } = buildPrevAndCurrent({ selectedTimestamp, selectedTimestamps, sessionsPerMonth });

  const expiringRemaining = availableSessionsThisMonth?.expiringSessionsLeft - alreadyScheduledThisMonth.length;
  let availableRemaining = availableSessionsThisMonth?.availableSessionsLeft;

  if (expiringRemaining < 0) availableRemaining = availableRemaining + expiringRemaining;

  const canBookThisMonth = availableRemaining > 0;

  return {
    canBookThisMonth,
    expiringRemaining: Math.max(0, expiringRemaining),
    expiringTotal: availableSessionsThisMonth?.expiringSessionsTotal,
    availableRemaining: Math.max(0, availableRemaining),
    availableTotal: availableSessionsThisMonth?.availableSessionsTotal
  };
}
