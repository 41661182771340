import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import './Dropdown.scss';

const Dropdown = ({ defaultValue, onSelect, options, showBorderBottom }) => {
  const [selected, setSelected] = useState(defaultValue);

  const handleChange = (event) => {
    setSelected(event.target.value);
    onSelect(event.target.value);
  };

  return (
    <FormControl>
      <Select
        labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        value={selected}
        variant="standard"
        onChange={handleChange}
        autoWidth
        label="Selector">
        {options?.map((option, index) => (
          <MenuItem key={index} value={option?.value}>
            {option?.label}
          </MenuItem>
        ))}
      </Select>
      {showBorderBottom && <div className="border-bottom" />}
    </FormControl>
  );
};

Dropdown.propTypes = {
  defaultValue: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  showBorderBottom: PropTypes.bool
};

Dropdown.defaultProps = {
  showBorderBottom: false
};

export default Dropdown;
