import { capitalize } from './string-helpers';

export const createActionTypes = (component, arrayOfActions) => {
  return arrayOfActions.flat(2).reduce((acc, cv) => ({ ...acc, [cv.toUpperCase()]: `${component}/${cv.toUpperCase()}` }), {});
};

export const actionCreatorsFactory = (actionTypes, mainFunctionName) => {
  return actionTypes.reduce((acc, cv) => {
    if (!cv) return acc;
    const createFunctionStructure = (type) => (payload) => ({ type, payload });
    if (cv.includes('_SUCCESS')) return { ...acc, [`${mainFunctionName}Success`]: createFunctionStructure(cv) };
    if (cv.includes('_FAIL')) return { ...acc, [`${mainFunctionName}Fail`]: createFunctionStructure(cv) };
    if (cv.includes('CLEAR_')) return { ...acc, [`clear${capitalize(mainFunctionName)}Error`]: createFunctionStructure(cv) };
    return { ...acc, [mainFunctionName]: createFunctionStructure(cv) };
  }, {});
};

export const asyncActionType = (actionType) => {
  return [
    actionType.toUpperCase(),
    `${actionType.toUpperCase()}_REQUEST`,
    `${actionType.toUpperCase()}_SUCCESS`,
    `${actionType.toUpperCase()}_FAIL`,
    `CLEAR_${actionType.toUpperCase()}_ERROR`
  ];
};
