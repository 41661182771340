import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import Translation from './Translation';

import styles from './chat-mobile.module.scss';
import Conversations from './Conversations';
import Messages from './Messages';

import { connect } from 'react-redux';

const ChatPageMobile = ({ SelectedConversation, chatId }) => {
  const [isTranslated, setIsTranslated] = useState(false);

  if (!Object.keys(SelectedConversation).length)
    return (
      <div className={styles.chatPageWrapper}>
        <div className={styles.mainContent}>
          <div className={styles.conversations}>
            <Conversations chatId={chatId} isMobile />
          </div>
        </div>
      </div>
    );

  return (
    <div className={styles.chatPageWrapper}>
      <div className={styles.mainContent}>
        <div className={styles.messages}>
          <Messages isMobile />
        </div>
      </div>
      <Translation isTranslated={isTranslated} setIsTranslated={setIsTranslated} />
    </div>
  );
};

ChatPageMobile.propTypes = {
  SelectedConversation: PropTypes.object,
  chatId: PropTypes.string
};

const mapStateToProps = (state) => ({
  SelectedConversation: state.chat.conversations?.SelectedConversation
});

export default connect(mapStateToProps)(ChatPageMobile);
