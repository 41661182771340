import React from 'react';
import PropTypes from 'prop-types';

import styles from './ExpandableDescription.module.scss';
import { cutWordsAndAddEllipsis } from '../../../../utils/string-helpers';

const ExpandableDescription = ({ openReadMore, readMore, tutor }) => {
  const DESCRIPTION_MAX_LENGTH = 30;

  const shortVersion = cutWordsAndAddEllipsis(tutor?.bio, DESCRIPTION_MAX_LENGTH);

  return (
    <div className={[styles['description-wrapper'], readMore && styles['full-height']].join(' ')}>
      <p className={styles['description']}>
        {shortVersion}
        <button onClick={openReadMore} className={styles['read-more']}>
          {' '}
          Read more
        </button>
      </p>
    </div>
  );
};

ExpandableDescription.propTypes = {
  openReadMore: PropTypes.func.isRequired,
  readMore: PropTypes.bool.isRequired,
  tutor: PropTypes.object.isRequired
};

export default ExpandableDescription;
