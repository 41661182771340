import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

import TuitionExplanation from '../containers/TuitionExplanation';
import TestPage from '../containers/TestPage';
import Login from '../containers/Login';
import PasswordResetForm from '../components/account/PasswordResetForm';
import NotFound from '../components/NotFound';
import Unsubscribed from '../components/Unsubscribed';
import Mixpanel from '../utils/mixpanel-helper.js';
import MultipleUsersPrivateRoute from './authentication/MultipleUsersPrivateRoute';
import TutorDashboard from '../containers/TutorDashboard';
import ChatPage from '../containers/ChatPage';
import LanguageHelperChat from '../containers/LanguageHelperChat';
import CheckTutorProfile from '../containers/CheckTutorProfile';
import PickYourCoach from '../containers/PickYourCoach';
import NewBooking from '../containers/NewBooking';
import Account from '../containers/Account';
import Onboarding from '../containers/Onboarding';
import TutorMatch from '../containers/TutorMatch';
import TutorList from '../containers/TutorList';
import TutorSelector from '../containers/TutorSelector';
import Store from '../containers/Store';
import NewStudyArea from '../containers/NewStudyArea';
import StudyAreaAssignment from '../containers/StudyAreaAssignment';
import Cerego from '../containers/Cerego';
import ConfirmAttendance from '../containers/ConfirmAttendance';
import PrivateRoute from './authentication/PrivateRoute';
import UserProfile from '../containers/UserProfile';
import Invoices from '../containers/Invoices';
import ChatCode from '../containers/ChatCode';
import TutorCalendar from '../containers/TutorCalendar';
import AppointmentList from '../containers/AppointmentList';
import StudentExclusiveRoute from './authentication/StudentExclusiveRoute';
import TutorExclusiveRoute from './authentication/TutorExclusiveRoute';
import LessonResources from '../containers/LessonResources';
import Resources from '../containers/Resources';
import TutorProfilePreview from '../containers/TutorProfilePreview';
import PasswordResetRequestForm from '../components/account/PasswordResetRequestForm';
import NewOnboarding from '../containers/NewOnboarding';
import SSOHandler from '../containers/SSOHandler';
import PastAppointments from '../components/tutor-dashboard/PastAppointments/PastAppointments';
import FutureAppointments from '../components/tutor-dashboard/FutureAppointments/FutureAppointments';
import TutorStudents from '../components/tutor-dashboard/TutorStudents/TutorStudents';

function trackEmailUserInviteClick(emailclick, userInvite, buttonPressed) {
  const context = {
    user_invite: userInvite,
    campaign_name: emailclick
  };
  axios.post('/api/track_email_click/', context, {});
  Mixpanel.track(`Clicked email ${emailclick}`, {
    buttonPressed
  });
}

function optOut(optOutCode, userId) {
  const params = {
    opt_out_code: optOutCode,
    userid: userId
  };
  axios.get('/api/opt-out', {
    params
  });
}

const Routes = (props) => {
  let params;

  // Detect IE
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const trident = ua.indexOf('Trident/');

  if (URLSearchParams !== undefined && msie === -1 && trident === -1) {
    params = new URLSearchParams(props.history.location.search);
    let buttonPressed;
    if (params.has('button')) {
      buttonPressed = params.get('button');
    }
    if (params.has('emailclick') && params.has('userid')) {
      Mixpanel.identify(params.get('userid'));
      Mixpanel.track(`Clicked email ${params.get('emailclick')}`, {
        buttonPressed
      });
    } else if (params.has('emailclick') && params.has('user_id')) {
      Mixpanel.identify(params.get('user_id'));
      Mixpanel.track(`Clicked email ${params.get('emailclick')}`, {
        buttonPressed
      });
    } else if (params.has('emailclick') && params.has('user_invite')) {
      trackEmailUserInviteClick(params.get('emailclick'), params.get('user_invite'), buttonPressed);
    }

    if (params.has('redirect')) {
      window.location.href = params.get('redirect');
    }

    if (params.has('opt-out') && params.has('userid')) {
      optOut(params.get('opt-out'), params.get('userid'));
    }
  } else {
    const string = props.history.location.search;
    const query = string.split('?');
    if (query[1] !== undefined) {
      const keyAndParams = query[1].split('&');
      params = {};
      keyAndParams.forEach((kp) => {
        const keyAndParam = kp.split('=');
        if (keyAndParam[0] !== undefined && keyAndParam[1] !== undefined) {
          params[keyAndParam[0]] = keyAndParam[1];
        }
      });
      if (params.emailclick && params.userid) {
        Mixpanel.identify(params.userid);
        Mixpanel.track(`Clicked email ${params.emailclick}`);
      } else if (params.emailclick && params.user_id) {
        Mixpanel.identify(params.user_id);
        Mixpanel.track(`Clicked email ${params.emailclick}`);
      } else if (params.emailclick && params.user_invite) {
        trackEmailUserInviteClick(params.emailclick, params.user_invite, params.button);
      }

      if (params.redirect) {
        window.location.href = params.redirect;
      }
    }
  }

  return (
    <Switch>
      <PrivateRoute exact path="/tutor-preview/:tutor_id/:dialect?/:fromQuick?/" component={<CheckTutorProfile />} />
      <PrivateRoute exact path="/tutor-profile/:tutor_id" component={<CheckTutorProfile />} />
      <PrivateRoute exact path="/pick-your-coach" component={<PickYourCoach />} />
      <PrivateRoute path="/(account|profile)" component={<Account history={props.history} />} />
      <PrivateRoute path="/onboarding" component={<Onboarding history={props.history} />} />
      <PrivateRoute path="/find-tutor" component={<TutorSelector history={props.history} />} />
      <PrivateRoute path="/chat/:chatid?" component={<ChatPage history={props.history} />} />
      <PrivateRoute path="/lh-chat/:chatid?" component={<LanguageHelperChat history={props.history} />} />
      <PrivateRoute path="/study-area/:courseId/:unitIndex" component={<NewStudyArea history={props.history} />} />
      <PrivateRoute path="/assignment" component={<StudyAreaAssignment history={props.history} />} />
      <PrivateRoute path="/cerego/:ceregoUrl/:allReadyJoined?" component={<Cerego />} />
      <PrivateRoute exact path="/reset" component={<Redirect to="/account" />} backupComponent={<PasswordResetRequestForm />} />
      <PrivateRoute path="/tutor-profile-preview/" component={<TutorProfilePreview history={props.history} />} />

      <MultipleUsersPrivateRoute
        exact
        path="/"
        userType={props.userType}
        studentComponent={<Redirect to="/study-area" />}
        tutorComponent={<Redirect to="/dashboard" />}
        languageHelperComponent={<Redirect to="/chat" />}
      />
      <MultipleUsersPrivateRoute
        userType={props.userType}
        path="/coach/:tutorId/:dialect?/"
        tutorComponent={<TutorDashboard />}
        studentComponent={<TutorMatch history={props.history} />}
      />
      <MultipleUsersPrivateRoute
        userType={props.userType}
        path="/tutor-list/"
        tutorComponent={<TutorDashboard />}
        studentComponent={<TutorList history={props.history} />}
      />
      <MultipleUsersPrivateRoute
        userType={props.userType}
        path="/(resources|study-area)/:course/:lessonNumber?"
        tutorComponent={<LessonResources history={props.history} />}
        studentComponent={<NewStudyArea history={props.history} />}
      />
      <MultipleUsersPrivateRoute
        userType={props.userType}
        path="/(resources|study-area)/"
        tutorComponent={<Resources />}
        studentComponent={<NewStudyArea history={props.history} />}
      />

      <MultipleUsersPrivateRoute
        userType={props.userType}
        path="/bookings"
        tutorComponent={<TutorDashboard />}
        studentComponent={<NewBooking history={props.history} />}
      />

      <StudentExclusiveRoute userType={props.userType} path="/store" component={<Store history={props.history} />} />
      <StudentExclusiveRoute
        userType={props.userType}
        path="/confirm-attendance/:appointmentID"
        component={<ConfirmAttendance history={props.history} />}
      />

      <TutorExclusiveRoute userType={props.userType} exact path="/dashboard/past-appointments" component={<PastAppointments />} />
      <TutorExclusiveRoute userType={props.userType} exact path="/dashboard/future-appointments" component={<FutureAppointments />} />
      <TutorExclusiveRoute userType={props.userType} exact path="/dashboard/learners" component={<TutorStudents />} />
      <TutorExclusiveRoute userType={props.userType} path="/(dashboard|booking)" component={<TutorDashboard />} />
      <TutorExclusiveRoute userType={props.userType} path="/profile" component={<UserProfile history={props.history} />} />
      <TutorExclusiveRoute userType={props.userType} path="/calendar/:calendarIsUpdated?" component={<TutorCalendar history={props.history} />} />
      <TutorExclusiveRoute userType={props.userType} path="/invoices" component={<Invoices />} />
      <TutorExclusiveRoute userType={props.userType} path="/chat-code" component={<ChatCode />} />
      <TutorExclusiveRoute userType={props.userType} path="/appointment-list" component={<AppointmentList />} />

      <Route path="/welcome" component={NewOnboarding} />
      <Route path="/sso-login" component={SSOHandler} />
      <Route exact path="/impersonate" render={() => <Login />} />
      <Route exact path="/technical-test" render={() => <TestPage />} />
      <Route path="/tuition-explanation" render={() => <TuitionExplanation />} />
      <Route path="/reset/:uid/:token" render={({ match }) => <PasswordResetForm match={match} history={props.history} />} />
      <Route path="/unsubscribe/:userID" component={Unsubscribed} />
      <Route component={NotFound} />
    </Switch>
  );
};

Routes.defaultProps = {
  userType: ''
};

Routes.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.object,
    params: PropTypes.object
  }).isRequired,
  userType: PropTypes.string
};

export default Routes;
