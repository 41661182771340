import React from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { getMultipleErrorMessage, DEFAULT_ERROR_MESSAGE } from '../../utils/error-helpers';

const ErrorAlert = ({ className, errorMsg }) => {
  const errMsgs = getMultipleErrorMessage(errorMsg);

  return (
    <div className={className}>
      {errMsgs.map((msg) => (
        <Alert bsStyle="danger" key={msg}>
          <p>{msg}</p>
          <p>
            If you need assistance, please contact us at <a href="mailto:support@chatterbox.io">support@chatterbox.io</a>.
          </p>
        </Alert>
      ))}
    </div>
  );
};

ErrorAlert.propTypes = {
  className: PropTypes.string,
  errorMsg: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object])
};

ErrorAlert.defaultProps = {
  className: '',
  errorMsg: DEFAULT_ERROR_MESSAGE
};

export default ErrorAlert;
