import { Buffer } from 'buffer';

export const splitJWTToken = (token) => token?.split('.');

export const decodeBase64Token = (token) => {
  const base64ToStr = Buffer.from(token, 'base64').toString();
  return JSON.parse(base64ToStr);
};

export const decodeJWTToken = (token) => {
  const [, userRelatedToken] = splitJWTToken(token);
  return decodeBase64Token(userRelatedToken);
};
