import { SET_BOOKING_FREQUENCY } from './types';

export default function setBookingFrequency(bookingFrequency) {
  return {
    type: SET_BOOKING_FREQUENCY,
    payload: {
      bookingFrequency
    }
  };
}
