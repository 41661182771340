import axios from 'axios';

import { isTokenExpired } from './common';
import { CLEAR_USER_PROFILE, GET_USER_PROFILE, UPDATE_USER_PROFILE } from './types';

function getProfileSuccess(response) {
  return {
    type: GET_USER_PROFILE,
    payload: response
  };
}

function getProfileError() {
  return {
    type: CLEAR_USER_PROFILE,
    payload: {}
  };
}

function updateProfileSuccess(response) {
  return {
    type: UPDATE_USER_PROFILE,
    payload: response
  };
}

export function getUserProfile(token, userId) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/api/profiles/${userId}/`, {
        headers: { Authorization: `Token ${token}` }
      });
      dispatch(getProfileSuccess(response));
    } catch (err) {
      if (!isTokenExpired(dispatch, err)) {
        dispatch(getProfileError());
      }
    }
  };
}

export const updateUserProfile = (token, userId, formData, successCallback, errorCallback) => async (dispatch) => {
  let response;
  const headers = { headers: { Authorization: `Token ${token}` } };
  try {
    response = await axios.put(`/api/profiles/${userId}/`, formData, headers);
    dispatch(updateProfileSuccess(response));
    successCallback();
  } catch (error) {
    if (!isTokenExpired(dispatch, error)) {
      errorCallback();
    }
  }
};
