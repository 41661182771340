import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import styles from './WeeklyCourseSchedule.module.scss';
import SelfStudyCard from './components/SelfStudyCard';
import LivePracticeCard from './components/LivePracticeCard';
import StudentProgressContainer from './components/StudentProgressContainer';
import IntroductoryLessonBookingModal from '../../new-booking/components/booking/IntroductoryLessonBookingModal';
import IntroductoryLessonCancelingModal from '../../new-booking/components/canceling/IntroductoryLessonCancelingModal';
import IntroductoryLessonReschedulingModal from '../../new-booking/components/rescheduling/IntroductoryLessonReschedulingModal';

const WeeklyCourseSchedule = ({
  courseDetailsRef,
  shouldOpenBookingIntroClassModal,
  shouldOpenCancelingModal,
  shouldOpenReschedulingModal,
  selectedCourse,
  sendToPlacementTest,
  sendToLessonsTab,
  isMobile
}) => {
  return (
    <div className={styles['header-wrapper']} data-testid="weekly-course-schedule">
      <div className={styles['content-wrapper']}>
        <h1 className={styles['title']}>
          My <span id="dont-translate">{selectedCourse?.name}</span> schedule
        </h1>
        <div className={styles['content']}>
          <SelfStudyCard
            courseDetailsRef={courseDetailsRef}
            hasCompletedFinalExam={selectedCourse?.hasCompletedFinalExam}
            hasCompletedPracticeExam={selectedCourse?.hasCompletedPracticeExam}
            isTimeForExam={selectedCourse?.isTimeForExam}
            sendToPlacementTest={sendToPlacementTest}
            sendToLessonsTab={sendToLessonsTab}
            isMobile={isMobile}
          />
          <LivePracticeCard isTimeForExam={selectedCourse?.isTimeForExam} title="2. Attend Live Practice" />
          <div className={styles['side-cards']}>
            <StudentProgressContainer />
          </div>
        </div>
      </div>
      {shouldOpenBookingIntroClassModal && <IntroductoryLessonBookingModal />}
      {shouldOpenCancelingModal && <IntroductoryLessonCancelingModal />}
      {shouldOpenReschedulingModal && <IntroductoryLessonReschedulingModal />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  shouldOpenBookingIntroClassModal: state.newBooking.bookingLessons.shouldOpenBookingIntroClassModal,
  shouldOpenCancelingModal: state.newBooking.canceling.shouldOpenCancelingModal,
  shouldOpenReschedulingModal: state.newBooking.rescheduling.shouldOpenReschedulingModal,
  selectedCourse: state.courses?.selectedCourseDetails?.courseDetails
});

WeeklyCourseSchedule.propTypes = {
  courseDetailsRef: PropTypes.object,
  isBlockedHowWasYourClass: PropTypes.bool,
  shouldOpenBookingIntroClassModal: PropTypes.bool,
  shouldOpenCancelingModal: PropTypes.bool,
  shouldOpenReschedulingModal: PropTypes.bool,
  isMobile: PropTypes.bool,
  selectedCourse: PropTypes.object,
  sendToPlacementTest: PropTypes.func,
  sendToLessonsTab: PropTypes.func
};

export default connect(mapStateToProps)(WeeklyCourseSchedule);
