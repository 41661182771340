import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import BookingSummaryItem from '../BookingSummaryItem';
import Button from '../../../design-system/button/Button';
import Modal from '../../../design-system/modal/Modal';

import {
  addToCancelingList,
  cancelMultipleBookingsAndGetUpdatedStudentsBooking,
  cleanPreviousState,
  cleanErrorState
} from '../../../../actions/new-booking';
import mixpanel from '../../../../utils/mixpanel-helper';

import styles from './CancelingModal.module.scss';

import IconWarning from '../../../../assets/icons/icon_warning.svg';

const CancelingModal = ({
  addToCancelingListAction,
  appointmentsToBeCancelled,
  cancelMultipleBookingsAndGetUpdatedStudentsBookingAction,
  cleanPreviousStateAction,
  cleanErrorStateAction,
  errorMessage,
  hasErrorOnCanceling,
  isCancelingAppointment,
  selectedCourseId,
  student,
  timezone
}) => {
  const [cancellationMotive, setCancellationMotive] = useState('');
  const [sortedAppointmentsToBeCancelled, setSortedAppointmentsToBeCancelled] = useState([]);

  const handleCloseModal = () => {
    cleanPreviousStateAction();
  };

  const sortLessons = (arrayOfLessons) => {
    if (!arrayOfLessons.length) return;
    const sorted = arrayOfLessons.sort((elOne, elTwo) => elOne.number - elTwo.number);
    setSortedAppointmentsToBeCancelled(sorted);
  };

  useEffect(() => {
    if (!appointmentsToBeCancelled.length) handleCloseModal();
    sortLessons(appointmentsToBeCancelled);
  }, [appointmentsToBeCancelled.length]);

  const handleUserTyping = (evt) => {
    setCancellationMotive(evt.target.value);
  };

  const handleCancelingRemoval = (appointment) => {
    addToCancelingListAction(appointment);
  };

  const getAppointmentsToBeCancelled = () =>
    appointmentsToBeCancelled.map(({ number, appointment_object: appt }) => ({
      id: appt.id,
      number
    }));

  const handleAppointmentsCanceling = () => {
    const apptData = getAppointmentsToBeCancelled();

    cancelMultipleBookingsAndGetUpdatedStudentsBookingAction({
      studentToken: student.token,
      courseId: selectedCourseId,
      appointmentsIds: apptData.map(({ id }) => id),
      cancellationMotive,
      successCallback: () => {
        mixpanel.lessonAction('Cancelled', {
          firstLessonNumber: apptData[0].number,
          totalLessons: apptData.length,
          cancellationMotive,
          type: 'tuition'
        });
      }
    });
  };

  const lateCancelingWarning = (
    <div className={styles.lateCancelingWarning}>
      <img src={IconWarning} alt="warning sign" />
      <p>Your live practice is in less than 24 hours. You can still cancel it, although you will still be charged.</p>
    </div>
  );

  return (
    <>
      <Modal
        loading={isCancelingAppointment}
        content={
          <div className={styles.cancelingModalWrapper} data-testid="canceling-tuition-session-modal">
            <h1>Canceling</h1>
            <div className={styles.contentWrapper}>
              <div className={styles.cancelingSummary}>
                <p>
                  You are about to cancel <span id="dont-translate">{sortedAppointmentsToBeCancelled.length}</span> Practice session(s)
                </p>
                <div className={styles.bookingSummaryList}>
                  <ul>
                    {sortedAppointmentsToBeCancelled.map((lesson, index) => (
                      <BookingSummaryItem
                        cancel
                        key={index}
                        index={index + 1}
                        removeNewScheduleFromFinalList={handleCancelingRemoval}
                        lesson={lesson}
                        onlyOneItem={sortedAppointmentsToBeCancelled.length === 1}
                        timezone={timezone}
                      />
                    ))}
                  </ul>
                  <textarea
                    className={styles.motivationTextarea}
                    rows={5}
                    value={cancellationMotive}
                    onChange={handleUserTyping}
                    placeholder="Tell your coach why are you cancelling?"
                  />
                </div>
              </div>
              {sortedAppointmentsToBeCancelled.length && sortedAppointmentsToBeCancelled[0].lateCanceling && lateCancelingWarning}
              <div className={styles.buttonsWrapper}>
                <Button outfit="secondaryButton" onClick={handleCloseModal} id="canceling-modal-close">
                  Close
                </Button>
                <Button
                  nowrap
                  outfit="cancelButton"
                  loading={isCancelingAppointment}
                  onClick={handleAppointmentsCanceling}
                  disabled={!sortedAppointmentsToBeCancelled.length}
                  id="canceling-modal-cancel">
                  Cancel session(s)
                </Button>
              </div>
            </div>
          </div>
        }
      />
      {hasErrorOnCanceling &&
        errorMessage &&
        toast.error(errorMessage, {
          closeOnClick: true,
          onClose: cleanErrorStateAction
        })}
    </>
  );
};

const mapStateToProps = (state) => ({
  appointmentsToBeCancelled: state.newBooking.canceling.appointmentsToBeCanceled,
  errorMessage: state.newBooking.canceling.error,
  hasErrorOnCanceling: state.newBooking.canceling.hasErrorOnCanceling,
  isCancelingAppointment: state.newBooking.canceling.isCancelingAppointment,
  selectedCourseId: state.courses?.selectedCourseDetails?.courseDetails?.id,
  student: state.user,
  timezone: state.time.timezone
});

CancelingModal.propTypes = {
  addToCancelingListAction: PropTypes.func.isRequired,
  appointmentsToBeCancelled: PropTypes.array.isRequired,
  cancelMultipleBookingsAndGetUpdatedStudentsBookingAction: PropTypes.func.isRequired,
  cleanPreviousStateAction: PropTypes.func.isRequired,
  cleanErrorStateAction: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  hasErrorOnCanceling: PropTypes.bool,
  isCancelingAppointment: PropTypes.bool,
  selectedCourseId: PropTypes.number.isRequired,
  student: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired
};

CancelingModal.defaultProps = {
  isCancelingAppointment: false
};

export default connect(mapStateToProps, {
  addToCancelingListAction: addToCancelingList,
  cancelMultipleBookingsAndGetUpdatedStudentsBookingAction: cancelMultipleBookingsAndGetUpdatedStudentsBooking,
  cleanPreviousStateAction: cleanPreviousState,
  cleanErrorStateAction: cleanErrorState
})(CancelingModal);
