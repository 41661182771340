import { SelectedProductTypeTypes } from '../actions/selected-product-type';
import { USER_LOGOUT, CACHED_LANGUAGES } from '../actions/types';

export default function selectedProductTypeReducer(state = {}, action) {
  switch (action.type) {
    case SelectedProductTypeTypes.SET_SELECTED_PRODUCT_TYPE:
      return action.payload;
    case USER_LOGOUT:
      return {};
    case CACHED_LANGUAGES:
      return { ...state, cachedLanguages: true };
    case 'persist/REHYDRATE':
      return action?.payload?.productType || {};
    default:
      return state;
  }
}
