import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import mixpanelHelper from '../../../../utils/mixpanel-helper';
import { convertStringsOfDateAndTimeToDate, convertToLocaleAndFormat } from '../../../../utils/time-helpers';

import { ReactComponent as IconWarning } from '../../../../assets/icons/icon_chat-warning.svg';
import SuccessAlert from '../../../design-system/alert/sucess/SuccessAlert';
import RatingModal from '../../RatingModal';
import storageHelpers from '../../../../utils/storage-helpers';

const SESSION_STORAGE_KEY = 'block-how-was-your-practice';

const HowWasYourPractice = ({ selectedCourse, timezone }) => {
  const [shouldShowCard, setShouldShowCard] = useState(true);
  const [showRagingModal, setShowRagingModal] = useState(false);
  const [isBlockedHowWasYourClass, setIsBlockedHowWasYourClass] = useState(false);

  useEffect(() => {
    checkIfShouldDisplay();
  }, [selectedCourse?.lastUnratedUnit]);

  const checkIfShouldDisplay = () => {
    const isBlockedPrev = storageHelpers.getDataFromSessionStorage(SESSION_STORAGE_KEY);
    if (isBlockedPrev) return setShouldShowCard(false);
    return setShouldShowCard(Boolean(selectedCourse?.lastUnratedUnit?.appointment));
  };

  const handleClick = () => {
    setShowRagingModal(true);
    mixpanelHelper.track('Click', {
      button: 'Last Class Rating',
      appointment_id: selectedCourse?.lastUnratedUnit?.appointment.id
    });
  };

  const handleClose = () => {
    storageHelpers.storeDataInSessionStorage({ [SESSION_STORAGE_KEY]: true });
  };

  return (
    <>
      <SuccessAlert
        actions={[{ label: 'Give feedback', action: handleClick }]}
        isVisible={Boolean(shouldShowCard && !isBlockedHowWasYourClass)}
        Icon={<IconWarning />}
        onClose={handleClose}
        text={`🙋 Tell us how your Practice with ${selectedCourse?.lastUnratedUnit?.appointment.tutor_name} on ${convertToLocaleAndFormat(
          convertStringsOfDateAndTimeToDate(
            selectedCourse?.lastUnratedUnit?.appointment.start_date,
            selectedCourse?.lastUnratedUnit?.appointment.start_time
          ),
          timezone,
          'do MMM'
        )} was.`}
        title="How was it?"
      />
      {showRagingModal && <RatingModal onCloseModal={() => setShowRagingModal(false)} setIsBlockedHowWasYourClass={setIsBlockedHowWasYourClass} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedCourse: state.courses.selectedCourseDetails?.courseDetails,
  timezone: state.time.timezone
});

HowWasYourPractice.propTypes = {
  handleShowRatingModal: PropTypes.func,
  selectedCourse: PropTypes.object,
  timezone: PropTypes.string
};

export default connect(mapStateToProps)(HowWasYourPractice);
