import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setAppDefaultLanguage, setAppLanguage, setUserLanguage } from '../../actions/app-language';

const LanguageSwitcher = ({
  executor,
  currentLang,
  defaultLang,
  isSettingLanguage,
  onChange,
  setAppDefaultLanguageAction,
  setAppLanguageAction,
  setUserLanguageAction,
  user
}) => {
  const weglotRef = useRef(window.Weglot);
  const availableLang = (weglotRef?.current?.options?.languages && weglotRef?.current?.options?.languages.map((lang) => lang?.language_to)) || [];

  availableLang.push(defaultLang);

  useEffect(() => {
    if (window.Weglot && !weglotRef.current) {
      weglotRef.current = window.Weglot;
    }
  }, [window.Weglot]);

  useEffect(() => {
    if (weglotRef?.current) {
      setAppDefaultLanguageAction(weglotRef.current?.options?.language_from);
    }
  }, [weglotRef.current]);

  const handleLangSwitch = (lang) => {
    if (!Object.keys(user).length) {
      setAppLanguageAction(lang);
    } else {
      setUserLanguageAction({ user, language: lang });
    }
    if (onChange) onChange(lang);
  };

  if (!weglotRef?.current?.initialize) return null;

  return React.cloneElement(executor, {
    availableLang,
    currentLang,
    isSettingLanguage: isSettingLanguage,
    onLangSwitch: handleLangSwitch,
    weglotRef
  });
};

const mapStateToProps = (state) => ({
  currentLang: state.appLanguage.currentLanguage,
  defaultLang: state.appLanguage.defaultLanguage,
  isSettingLanguage: state.appLanguage.isSettingLanguage,
  user: state?.user
});

LanguageSwitcher.propTypes = {
  executor: PropTypes.element.isRequired,
  currentLang: PropTypes.string.isRequired,
  defaultLang: PropTypes.string.isRequired,
  isSettingLanguage: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  setAppDefaultLanguageAction: PropTypes.func.isRequired,
  setAppLanguageAction: PropTypes.func.isRequired,
  setUserLanguageAction: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default connect(mapStateToProps, {
  setAppDefaultLanguageAction: setAppDefaultLanguage,
  setAppLanguageAction: setAppLanguage,
  setUserLanguageAction: setUserLanguage
})(LanguageSwitcher);
