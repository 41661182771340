import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LessonsCard from './LessonsCard';
import ConvoCard from './ConvoCard';

import styles from './Bookings.module.scss';
import BookingTuitionSessionModal from '../components/booking/BookingTuitionSessionModal';
import BookingConvoClassModal from '../components/booking/BookingConvoClassModal';
import SingleTextLoader from '../../design-system/loading/text/SingleTextLoader';

const Bookings = ({ isGettingCourseDetails, openBookingConversationModal, openBookingLessonsModal, selectedCourse }) => {
  return (
    <div className={styles['bookings-wrapper']} data-testid="bookings-header">
      {isGettingCourseDetails ? (
        <div className={styles['loading-wrapper']}>
          <SingleTextLoader rows={1} rowColor="gray" />
        </div>
      ) : (
        <h1 className={styles['bookings-title']}>
          {' '}
          Bookings for <span id="dont-translate">{selectedCourse?.name}</span>
        </h1>
      )}
      <div className={styles['cards-wrapper']}>
        <LessonsCard />
        <ConvoCard />
      </div>
      <div className={styles['links-wrapper']}>
        <button className={styles['link']}>
          <a
            href="https://chatterboxlanguages.freshdesk.com/support/solutions/articles/103000154448-what-are-live-practices-"
            rel="noopener noreferrer"
            target="_blank">
            What are Live Practices?
          </a>
        </button>
        <button className={styles['link']}>
          <a
            href="https://chatterboxlanguages.freshdesk.com/support/solutions/articles/103000154447-what-are-convo-practices-"
            rel="noopener noreferrer"
            target="_blank">
            What are Convo Practices?
          </a>
        </button>
        <button className={styles['link']}>
          <a
            href="https://chatterboxlanguages.freshdesk.com/support/solutions/articles/103000154454-our-cancellation-policy"
            rel="noopener noreferrer"
            target="_blank">
            Cancellation policy
          </a>
        </button>
        <button className={styles['link']}>
          <a
            href="https://chatterboxlanguages.freshdesk.com/support/solutions/articles/103000154445-how-do-i-change-my-coach-"
            rel="noopener noreferrer"
            target="_blank">
            Changing coach
          </a>
        </button>
        <button className={styles['link']}>
          <a
            href="https://chatterboxlanguages.freshdesk.com/support/solutions/articles/103000154446-how-do-i-enter-a-live-practice-"
            rel="noopener noreferrer"
            target="_blank">
            How do I enter a Live Practice?
          </a>
        </button>
      </div>
      {openBookingLessonsModal && <BookingTuitionSessionModal />}
      {openBookingConversationModal && <BookingConvoClassModal />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGettingCourseDetails: state.courses.selectedCourseDetails.isGettingCourseDetails,
  openBookingLessonsModal: state.newBooking.bookingLessons.openBookingModal,
  openBookingConversationModal: state.newBooking.bookingConversation.openBookingModal,
  selectedCourse: state.courses.selectedCourseDetails.courseDetails
});

Bookings.propTypes = {
  isGettingCourseDetails: PropTypes.bool.isRequired,
  openBookingConversationModal: PropTypes.bool,
  openBookingLessonsModal: PropTypes.bool,
  selectedCourse: PropTypes.object.isRequired
};

Bookings.defaultProps = {
  isGettingCourseDetails: false
};

export default connect(mapStateToProps)(Bookings);
