import React from 'react';
import PropTypes from 'prop-types';
import styles from '../Row.module.scss';

const Row = ({ data, columns, stickyColumnClass }) => {
  const getStatusClass = (status) => {
    switch (status.toLowerCase()) {
      case 'active':
        return styles['status-active']; // Green
      case 'at risk':
        return styles['status-at-risk']; // Amber
      case 'inactive':
        return styles['status-inactive']; // Red
      default:
        return ''; // No color for unknown statuses
    }
  };

  return (
    <tr className={styles.row}>
      {Object.keys(columns).map((key, index) => (
        <td key={index} className={`${styles.cell} ${index === 0 ? stickyColumnClass : ''} ${key === 'status' ? getStatusClass(data[key]) : ''}`}>
          {data[key] || '-'} {/* Fallback for empty values */}
        </td>
      ))}
    </tr>
  );
};

Row.propTypes = {
  data: PropTypes.object.isRequired, // Row data
  columns: PropTypes.object.isRequired, // Columns object to define table structure
  stickyColumnClass: PropTypes.string // For applying the sticky column style
};

export default Row;
