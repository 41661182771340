import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useCloseWhenClickOutside from '../../hooks/useCloseWhenClickOutside';
import LanguageSwitcher from '.';
import styles from './NavbarLanguageSwitcher.module.scss';
import LoadingSpinner from '../design-system/loading/spinner/LoadingSpinner';
import { UserTypes } from '../../domains/user/user-types';

const NavbarLanguageSwitcher = ({ availableLang, currentLang, darkMode, isSettingLanguage, onLangSwitch, user }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleExit = () => {
    setIsOpen(false);
  };

  const [wrapperRef] = useCloseWhenClickOutside({ handleExit });

  const handleLangSwitch = (lang) => {
    onLangSwitch(lang);
    setIsOpen(false);
  };

  if (user?.user_type !== UserTypes.student) return;

  if (isSettingLanguage)
    return (
      <div className={styles.wrapper} ref={wrapperRef}>
        <div className={[styles.currentLangWrapper, darkMode && styles.darkMode].join(' ')}>
          <div className={styles.loading}>
            <LoadingSpinner darkMode />
          </div>
        </div>
      </div>
    );

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <div className={[styles.currentLangWrapper, darkMode && styles.darkMode].join(' ')}>
        <button id="dont-translate" onClick={() => setIsOpen((prevState) => !prevState)}>
          <span className={styles.currentLang}>{currentLang}</span>
        </button>
      </div>
      {isOpen && (
        <div className={styles.listWrapper}>
          <ul>
            {availableLang?.map((lang) => {
              return (
                <li key={lang} className={currentLang === lang ? styles.currentLangInList : ''}>
                  <button id="dont-translate" onClick={() => handleLangSwitch(lang)}>
                    {lang}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

NavbarLanguageSwitcher.propTypes = {
  availableLang: PropTypes.array,
  currentLang: PropTypes.string,
  darkMode: PropTypes.bool,
  isSettingLanguage: PropTypes.bool,
  onLangSwitch: PropTypes.func,
  user: PropTypes.object.isRequired
};

NavbarLanguageSwitcher.defaultProps = {
  availableLang: ['en'],
  currentLang: 'en',
  isSettingLanguage: false,
  onLangSwitch: () => {}
};

const NavbarLanguageSwitcherPoweredUp = ({ darkMode, user }) => (
  <LanguageSwitcher executor={<NavbarLanguageSwitcher darkMode={darkMode} user={user} />} />
);

NavbarLanguageSwitcherPoweredUp.propTypes = {
  darkMode: PropTypes.bool,
  user: PropTypes.object.isRequired
};

export default NavbarLanguageSwitcherPoweredUp;
