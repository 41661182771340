import { GET_COURSE_DETAILS, GET_COURSE_DETAILS_ERROR, USER_LOGOUT, GET_COURSE_PROGRESS, GET_COURSE_ASSIGNMENT_PROGRESS } from '../actions/types';

function updateAssignmentProgress(state, payload) {
  const assignment = payload;
  const newCourse = state;

  if (newCourse.lessons === undefined) {
    return newCourse;
  }

  const newLessons = newCourse.lessons.map((lesson) => {
    const newResources = lesson.resources.map((resource) => {
      if (resource.id === assignment.id) {
        const newResource = resource;
        if (resource.progress === undefined) {
          newResource.progress = payload.progress;
        } else if (payload.progress && payload.progress > resource.progress) {
          newResource.progress = payload.progress;
        }

        newResource.see_next_at = payload.see_next_at;
        return newResource;
      }
      return resource;
    });
    const newLesson = lesson;
    newLesson.resources = newResources;
    return newLesson;
  });
  newCourse.lessons = newLessons;
  return newCourse;
}

function copyAllAssignmentsProgress(oldState, newState) {
  if (oldState.lessons === undefined) {
    return newState;
  }
  if (oldState.id !== newState.id) {
    return newState;
  }
  const newStateCopy = newState;
  const newLessons = newState.lessons.map((lesson) => {
    const oldLesson = oldState.lessons.find((oldL) => oldL.id === lesson.id);
    const newResources = lesson.resources.map((resource) => {
      const oldResource = oldLesson.resources.find((oldR) => oldR.id === resource.id);
      const newResource = resource;
      newResource.see_next_at = oldResource.see_next_at;
      newResource.progress = oldResource.progress;
      return newResource;
    });
    const newLesson = lesson;
    newLesson.resources = newResources;
    newLesson.progress = oldLesson.progress;
    return newLesson;
  });
  newStateCopy.lessons = newLessons;
  return newStateCopy;
}

function updateCourseProgress(state, payload) {
  const unitId = payload.id;
  const newCourse = state;
  const indexToChange = newCourse.lessons.findIndex((lesson) => lesson.id === unitId);
  if (indexToChange !== -1) {
    newCourse.lessons[indexToChange].progress = payload.progress;
    newCourse.lessons[indexToChange].lastStudyTime = payload.lastStudyTime;
  }
  return newCourse;
}

export default function courseDetailsReducer(state = {}, action) {
  switch (action.type) {
    case GET_COURSE_DETAILS:
      return copyAllAssignmentsProgress(state, action.payload.data);
    case GET_COURSE_DETAILS_ERROR:
      return {};

    case GET_COURSE_PROGRESS:
      return updateCourseProgress(state, action.payload);
    case GET_COURSE_ASSIGNMENT_PROGRESS:
      return updateAssignmentProgress(state, action.payload);

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
}
