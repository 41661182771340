import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import formStyles from '../../../style/utils/_form.module.scss';

const DropdownAsync = ({ defaultValue, disabled, getOptions, getOptionsLabel, isLoading, label, onChange, onDefaultValue, options }) => {
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [availableOptions, setAvailableOptions] = useState([]);

  const inputStyle = {
    '& input': {
      fontSize: '1rem',
      color: 'rgb(51, 51, 51)',
      fontFamily: 'Karla, sans-serif !important',
      padding: '0.5rem 0.75rem !important'
    },
    backgroundColor: 'white'
  };

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    setValue(defaultValue);
    if (onDefaultValue) onDefaultValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setAvailableOptions(options);
  }, [options]);

  const handleValueChange = (evt, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  const handleInputChange = (evt, newValue) => {
    setInputValue(newValue);
  };

  return (
    <div className={formStyles['field-wrapper']}>
      <label htmlFor="combo-box-demo" className={formStyles.label}>
        {label}
      </label>
      <Autocomplete
        disableClearable
        disabled={disabled || isLoading}
        size="small"
        id="combo-box-demo"
        defaultValue={defaultValue}
        value={value}
        onChange={handleValueChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        open={showOptions}
        onOpen={() => {
          setShowOptions(true);
        }}
        onClose={() => {
          setShowOptions(false);
        }}
        isOptionEqualToValue={(option, value) => option === value}
        getOptionLabel={(option) => getOptionsLabel(option)}
        options={availableOptions}
        sx={{ width: '100%' }}
        componentsProps={{
          popper: {
            sx: { zIndex: 5000 }
          }
        }}
        loading={isLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={inputStyle}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    </div>
  );
};

DropdownAsync.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
  getOptions: PropTypes.func.isRequired,
  getOptionsLabel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDefaultValue: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string
      })
    ])
  )
};

DropdownAsync.defaultProps = {
  getOptionsLabel: (option) => option
};

export default DropdownAsync;
