import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-image-uploader/dist/quill.imageUploader.min.css';

import styles from './InputMessage.module.scss';
import { createTicket } from '../../../../actions/helpdesk';

const InputMessage = ({ setAttachments, setDescription }) => {
  const [_description, _setDescription] = useState('');

  const handleChange = (value) => {
    setDescription(value);
    _setDescription(value);
  };
  const quillRef = useRef(null);

  const imageHandler = useCallback(() => {
    const editor = quillRef.current.getEditor();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.addEventListener('change', async () => {
      const file = input.files[0];
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', () => {
          editor.insertEmbed(editor.getSelection(), 'image', reader.result);
          setAttachments((prevState) => [...prevState, file]);
        });
      } catch (err) {
        console.log('upload err:', err);
      }
    });
  }, []);

  const TOOLBAR_CONTENTS = [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
    ['link', 'image'],
    ['clean']
  ];

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [...TOOLBAR_CONTENTS],
        handlers: {
          image: imageHandler
        }
      }
    }),
    [imageHandler]
  );

  return (
    <div className={styles.wrapper}>
      <ReactQuill
        ref={quillRef}
        className={styles.quill}
        value={_description}
        onChange={(value, delta, source, editor) => handleChange(value, delta, source, editor)}
        modules={modules}
        theme="snow"
      />
    </div>
  );
};

InputMessage.propTypes = {
  isCreatingTicket: PropTypes.bool,
  sendRequest: PropTypes.func.isRequired,
  setAttachments: PropTypes.func.isRequired,
  setDescription: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isCreatingTicket: state.helpdesk.isCreatingTicket
});

export default connect(mapStateToProps, { createTicketAction: createTicket })(InputMessage);
