import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import styles from '../../style/components/AutoSuggestTags.module.scss';

const AutoSuggestTags = ({ valueInput, onValueChange, getSuggestions, clearSuggestions, values, addTag, limitReached }) => {
  const inputProps = {
    placeholder: limitReached ? 'You reached the limit of tags' : 'Type here to add more tags.',
    value: valueInput !== undefined ? valueInput.toString() : '',
    onChange: (e) => onValueChange(e.target.value),
    disabled: limitReached
  };
  return (
    <Autosuggest
      getSuggestionValue={(item) => item.name}
      renderSuggestion={(item) =>
        !limitReached && (
          <div
            className={styles.autocompleteSuggestionItem}
            onClick={addTag.bind(this, item)}
            onKeyPress={addTag.bind(this, item)}
            role="button"
            tabIndex="0">
            {item.name}
          </div>
        )
      }
      suggestions={values !== undefined ? values : []}
      onSuggestionsClearRequested={() => {
        clearSuggestions();
      }}
      onSuggestionsFetchRequested={({ value }) => {
        getSuggestions(value);
      }}
      inputProps={inputProps}
      theme={{
        input: styles.autocompleteInput,
        container: styles.autocompleteContainer,
        suggestionsContainer: styles.autocompleteSuggestionsContainer,
        suggestionsContainerOpen: styles.autocompleteSuggestionsContainerOpen,
        suggestionsList: styles.autocompleteSuggestionsList,
        suggestion: styles.autocompleteSuggestion
      }}
      shouldRenderSuggestions={() => true}
      alwaysRenderSuggestions={false}
    />
  );
};

AutoSuggestTags.propTypes = {
  valueInput: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  clearSuggestions: PropTypes.func.isRequired,
  values: PropTypes.array,
  addTag: PropTypes.func.isRequired,
  limitReached: PropTypes.bool.isRequired
};

AutoSuggestTags.defaultProps = {
  valueInput: '',
  values: []
};

export default AutoSuggestTags;
