export const DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const PERIOD_OF_THE_DAY = ['Morning', 'Afternoon', 'Evening'];
export const INITIAL_MATRIX = PERIOD_OF_THE_DAY.map(() => new Array(DAYS.length).fill(false));

const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export function getDay(date) {
  return WEEKDAYS[date.getDay()];
}

export function orderByStartTime(a, b) {
  if (a.from < b.from) {
    return -1;
  }
  if (a.from > b.from) {
    return 1;
  }
  return 0;
}
