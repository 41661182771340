import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../design-system/button/Button';

import Modal from '../design-system/modal/Modal';

export default class ButtonModal extends Component {
  state = {
    showModal: false
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  close() {
    return this.handleCloseModal();
  }

  modal = null;

  handleCloseModal = () => {
    if (this.mounted) {
      this.setState({ showModal: false });
      this.props.onClose();
    }
  };

  handleOpenModal = () => {
    this.setState({ showModal: true });
    this.props.closeAlert();
    this.props.onClick();
  };

  render() {
    return (
      <div className={this.props.className}>
        <Button outfit={this.props.buttonStyle} disabled={this.props.disabled} onClick={this.handleOpenModal}>
          {this.props.buttonText}
        </Button>

        {this.state.showModal && (
          <Modal
            onClose={this.handleCloseModal}
            modalRef={(modal) => {
              this.modal = modal;
            }}
            content={React.Children.map(this.props.children, (child) => React.cloneElement(child, { handleCloseModal: this.handleCloseModal }))}
          />
        )}
      </div>
    );
  }
}

ButtonModal.propTypes = {
  buttonStyle: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeAlert: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  withCloseButton: PropTypes.bool
};

ButtonModal.defaultProps = {
  buttonStyle: undefined,
  className: '',
  closeAlert: () => {},
  onClick: () => {},
  disabled: false,
  loading: false,
  onClose: () => {},
  withCloseButton: true
};
