import { isAfter } from 'date-fns';
import { CourseActionTypes } from '../actions/courses';
import { GET_USER_SUCCESS, USER_LOGOUT } from '../actions/types';

const isStudentEnrolledInACourse = (payload) => {
  return Boolean(payload?.name);
};

const isMandatoryToTakeIntroClass = (user) => {
  if (!user?.date_joined) return false;
  return isAfter(new Date(user.date_joined), new Date('2022', '7', '17'));
};

const studentInitialState = {
  isStudentEnrolledInACourse: true,
  mustTakeIntroClass: false
};

export default function studentReducer(state = studentInitialState, action) {
  switch (action.type) {
    case GET_USER_SUCCESS:
      return {
        ...state,
        mustTakeIntroClass: isMandatoryToTakeIntroClass(action.payload)
      };
    case 'persist/REHYDRATE':
      return {
        ...state,
        mustTakeIntroClass: isMandatoryToTakeIntroClass(action?.payload?.user)
      };
    case CourseActionTypes.GET_SELECTED_COURSE_DETAILS_SUCCESS: {
      const isEnrolled = isStudentEnrolledInACourse(action.payload);
      return {
        ...state,
        isStudentEnrolledInACourse: isEnrolled
      };
    }
    case CourseActionTypes.GET_SELECTED_COURSE_DETAILS_FAIL:
      return {
        ...state,
        isStudentEnrolledInACourse: false
      };

    case USER_LOGOUT:
      return studentInitialState;
    default:
      return state;
  }
}
